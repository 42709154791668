import { useCallback, useState } from "react";
import PageLayout from "components/PageLayout";
import TopNavigatorLink from "components/common/TopNavigatorLink";
import Text from "components/common/Text";

import ParticipantsChatRoom from "components/ParticipantsChatRoom";
import { useAppDispatch, useAppShallowSelector } from "hooks/redux";
import {
  getCreateChatRoomChannelUserSelector,
  setCreateSelectedState,
  getCreateSelectedStateSelector,
} from "store/reducers/Chat/chatrooms";

const ChatRoomCreatePageAddParticipants = () => {
  const dispatch = useAppDispatch();

  const participansList = useAppShallowSelector(
    getCreateChatRoomChannelUserSelector
  );

  const selectedState = useAppShallowSelector(getCreateSelectedStateSelector);

  const setSelectedState = useCallback(
    (newState: UserId[]) => {
      dispatch(setCreateSelectedState(newState));
    },
    [dispatch]
  );

  return (
    <>
      <TopNavigatorLink to="..">Cancel</TopNavigatorLink>
      <Text className="top_navigation_title" fontWeight="w700">
        Choose people
      </Text>
      <TopNavigatorLink to="next" disabled={!selectedState.length}>
        Next
      </TopNavigatorLink>
      <PageLayout sideMenu={false}>
        {/* <PageLayout className="chat_room_create_page_container"> */}
        <ParticipantsChatRoom
          data={participansList}
          isCreate={true}
          selectedState={selectedState}
          changeSelectedState={setSelectedState}
          withCount={false}
          withSelectAction={false}
        />
      </PageLayout>
    </>
  );
};

export default ChatRoomCreatePageAddParticipants;
