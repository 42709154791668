import { CheckCircleOutline } from "@mui/icons-material";
import {
  Dialog,
  FormControl,
  FormControlLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from "@mui/material";
import { AttendeeFormDetailsReq } from "api/models/Hive/events";
import LineBreak from "components/LineBreak/LineBreak";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import AttendeePhone from "pages/Events/components/AttendeePhone";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getFormDetails, submitForm } from "store/async-actions/Forms/forms";
import {
  getCurrentFormSelector,
  getReducedFillFormSelector,
} from "store/reducers/Forms/forms";
import { getHiveSelector } from "store/reducers/HiveDetails/hiveDetails";

const FormView = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();

  const url = location.pathname;
  const secondSlashIndex = url.indexOf("/", url.indexOf("/") + 1);
  const thirdSlashIndex = url.indexOf("/", secondSlashIndex + 1);
  const formIdentifier = url.substring(
    secondSlashIndex + 1,
    thirdSlashIndex !== -1 ? thirdSlashIndex : url.length
  );

  useEffect(() => {
    dispatch(
      getFormDetails({
        formUuid: formIdentifier,
      })
    );
  }, [dispatch, formIdentifier]);

  const handleNext = () => {
    // if (step < formDetails.length) {
    //   if (step === 0 || formDetails[step - 1]) {
    //     if (step !== 0) {
    //       if (
    //         formDetails[step - 1].type === "radio" ||
    //         formDetails[step - 1].type === "dropdown"
    //       ) {
    //         if (
    //           formDetails[step - 1].options.length === 1 &&
    //           formDetails[step - 1].options[0] === "Other" &&
    //           formDetails[step - 1].isMandatory &&
    //           !filledForm[step - 1].fieldValue
    //         ) {
    //           toastError(`This is a required field`);
    //         }
    //       } else if (formDetails[step - 1].isMandatory) {
    //         if (
    //           formDetails[step - 1].isMandatory &&
    //           formDetails[step - 1].type === "phone" &&
    //           filledForm[step - 1].fieldValue.length < 12
    //         ) {
    //           toastError(`Please enter a valid mobile number`);
    //         } else if (!filledForm[step - 1].fieldValue) {
    //           toastError(`This is a required field`);
    //         } else {
    //           setStep((prevState) => prevState + 1);
    //         }
    //       } else {
    //         setStep((prevState) => prevState + 1);
    //       }
    //     } else {
    //       setStep((prevState) => prevState + 1);
    //     }
    //   }
    // } else {
    dispatch(
      submitForm({
        formUuid: currentForm?.formUuid || "",
        formList: filledForm,
      })
    );
    setDone(true);
    //   setStep(-1);
    // }
  };

  const [done, setDone] = useState(false);

  const handleDone = () => {
    window.open(currentForm?.downloadLink || "https://veehive.ai", "_self");
  };

  const handlePrev = () => {
    setStep((prevState) => prevState - 1);
  };

  const navigate = useNavigate();

  const handleClose = () => {
    navigate("/home");
  };

  const reducedForm = useAppSelector(getReducedFillFormSelector);

  const [filledForm, setFilledForm] =
    useState<AttendeeFormDetailsReq[]>(reducedForm);

  const currentForm = useAppSelector(getCurrentFormSelector);

  const [formDetails, setFormDetails] = useState(currentForm?.formList || []);

  useEffect(() => {
    currentForm && setFormDetails(currentForm?.formList);
    currentForm && setFilledForm(reducedForm);
  }, [dispatch, currentForm, reducedForm]);

  const handleChange = (idx: number, field: string, value: string) => {
    const updatedDetails = [...filledForm];
    updatedDetails[idx] = {
      ...updatedDetails[idx],
      fieldValue: value,
    };
    setFilledForm(updatedDetails);
  };

  const handleOptionChange = (e: string, idx: number, optionIdx: number) => {
    const updatedDetails = [...filledForm];
    updatedDetails[idx] = {
      ...updatedDetails[idx],
      options: [e],
    };
    setFilledForm(updatedDetails);
  };

  const [step, setStep] = useState(0);

  const hiveDetails = useAppSelector(getHiveSelector);

  return (
    <Dialog className="form_index" fullWidth maxWidth="xs" open={true}>
      <div className={`page_padding`}>
        {done ? (
          <div className="text-center flex-col items-center">
            <CheckCircleOutline />
            <h2 className="form_content_margins">Congratulations!</h2>{" "}
            <p className="form_content_margins">
              Your form has been submitted successfully. Thank you!
            </p>
            <LineBreak />
            <p onClick={handleDone} className=" primaryBtn form_btn">
              Done
            </p>
          </div>
        ) : (
          <>
            <h2 className="form_heading_bg">Fill in your details</h2>
            {!!formDetails &&
              formDetails.length > 0 &&
              !!filledForm &&
              filledForm.length > 0 &&
              formDetails.map((data, idx) => {
                return (
                  <div
                    className={`w-full ${
                      data.type === "radio" ||
                      data.type === "dropdown" ||
                      data.type === "longDescription" ||
                      data.fieldName === "Subject"
                        ? "col-span-2"
                        : "col-span-1"
                    }`}
                  >
                    <h4 className="">{data.fieldName}</h4>
                    <LineBreak />

                    {data.type === "radio" ? (
                      <FormControl key={step} className={` mid_z`} fullWidth>
                        <RadioGroup
                          aria-labelledby="demo-radio-buttons-group-label"
                          name="radio-buttons-group"
                        >
                          {data.options.map((option, optionIdx) => {
                            return (
                              <FormControlLabel
                                className="overflow_visible"
                                key={option}
                                value={option}
                                onChange={(e) =>
                                  handleOptionChange(option, idx, optionIdx)
                                }
                                control={<Radio />}
                                label={option}
                              />
                            );
                          })}
                        </RadioGroup>
                        {data &&
                          !!data.options &&
                          data.options.length === 1 &&
                          data.options[0] === "Other" && (
                            <TextField
                              fullWidth
                              id="standard-basic"
                              required={data.isMandatory}
                              variant="outlined"
                              name={data.fieldName}
                              value={filledForm[idx].fieldValue || ""}
                              onChange={(e) =>
                                handleChange(idx, e.target.name, e.target.value)
                              }
                            />
                          )}
                      </FormControl>
                    ) : data.type === "phone" ? (
                      <div key={step} className={`form_content_margins`}>
                        <AttendeePhone handleChange={handleChange} idx={idx} />
                      </div>
                    ) : data.type === "longDescription" ? (
                      <TextField
                        key={step}
                        className={``}
                        fullWidth
                        id="standard-basic"
                        // label={data.fieldName}
                        required={data.isMandatory}
                        name={data.fieldName}
                        value={filledForm[idx].fieldValue || ""}
                        multiline={true}
                        minRows={4}
                        maxRows={6}
                        onChange={(e) =>
                          handleChange(idx, e.target.name, e.target.value)
                        }
                      />
                    ) : data.type === "dropdown" ? (
                      <FormControl key={step} className={``} fullWidth>
                        <Select
                          aria-labelledby="demo-radio-buttons-group-label"
                          name="radio-buttons-group"
                          variant="standard"
                          // label={data.fieldName}
                        >
                          {data.options.map((option, optionIdx) => {
                            return (
                              <MenuItem
                                onClick={(e) =>
                                  handleOptionChange(option, idx, optionIdx)
                                }
                                key={option}
                                value={option}
                              >
                                {option}
                              </MenuItem>
                            );
                          })}
                        </Select>
                        {!!data.options &&
                          data.options.length === 1 &&
                          data.options[0] === "Other" && (
                            <TextField
                              fullWidth
                              id="standard-basic"
                              // label="Please Specify"
                              required={data.isMandatory}
                              variant="standard"
                              name={data.fieldName}
                              value={filledForm[idx].fieldValue}
                              onChange={(e) =>
                                handleChange(idx, e.target.name, e.target.value)
                              }
                            />
                          )}
                      </FormControl>
                    ) : (
                      <TextField
                        key={step}
                        className={``}
                        fullWidth
                        id="standard-basic"
                        // label={data.fieldName}
                        required={data.isMandatory}
                        variant="outlined"
                        name={data.fieldName}
                        value={filledForm[idx].fieldValue || ""}
                        onChange={(e) =>
                          handleChange(idx, e.target.name, e.target.value)
                        }
                      />
                    )}
                  </div>
                );
              })}

            <div
              onClick={handleNext}
              className="form_content_margins_btn primaryBtn form_btn"
            >
              Done
            </div>
          </>
        )}
      </div>
    </Dialog>
  );
};

export default FormView;

// {/* {step === 0 ? (
//   <>
//     <div onClick={handleClose} className="form_close pointer">
//       <CloseBlackSVG />
//     </div>

//     <h2 className="form_content_margins">Hello there!</h2>
//     <p className="form_content_margins">Please fill in your details.</p>
//     <div className="form_content_margins"></div>
//     <div onClick={handleNext} className="primaryBtn form_btn">
//       Let's go
//     </div>
//   </>
// ) : step !== -1 ? (
//   <div className="form_content">
//     <div onClick={handlePrev} className="pointer  form_backBtn go_back">
//       <BackBtnSVG />
//       <p>Back</p>
//     </div>
//     <div onClick={handleClose} className="form_close pointer">
//       <CloseBlackSVG />
//     </div>
//     <h2>
//       {step + "."} {formDetails[step - 1].fieldName}
//     </h2>
//     <LineBreak />
//     {!!formDetails &&
//       formDetails.length > 0 &&
//       !!filledForm &&
//       filledForm.length > 0 && (
//         <>
//           {formDetails[step - 1].type === "radio" ? (
//             <FormControl fullWidth>
//               <RadioGroup
//                 aria-labelledby="demo-radio-buttons-group-label"
//                 name="radio-buttons-group"
//               >
//                 {formDetails[step - 1].options.map(
//                   (option, optionIdx) => {
//                     return (
//                       <FormControlLabel
//                         key={option}
//                         value={option}
//                         onChange={(e) =>
//                           handleOptionChange(
//                             option,
//                             step - 1,
//                             optionIdx
//                           )
//                         }
//                         control={<Radio />}
//                         label={option}
//                       />
//                     );
//                   }
//                 )}
//               </RadioGroup>
//               {!!filledForm[step - 1] &&
//                 !!filledForm[step - 1].options &&
//                 filledForm[step - 1].options.length === 1 &&
//                 filledForm[step - 1].options[0] === "Other" && (
//                   <TextField
//                     fullWidth
//                     id="standard-basic"
//                     label="Please Specify"
//                     required={formDetails[step - 1].isMandatory}
//                     variant="standard"
//                     name={formDetails[step - 1].fieldName}
//                     value={filledForm[step - 1].fieldValue || ""}
//                     onChange={(e) =>
//                       handleChange(
//                         step - 1,
//                         e.target.name,
//                         e.target.value
//                       )
//                     }
//                   />
//                 )}
//             </FormControl>
//           ) : formDetails[step - 1].type === "phone" ? (
//             <div className="form_content_margins">
//               <AttendeePhone
//                 handleChange={handleChange}
//                 idx={step - 1}
//               />
//             </div>
//           ) : formDetails[step - 1].type === "longDescription" ? (
//             <TextField
//               fullWidth
//               id="standard-basic"
//               label={formDetails[step - 1].fieldName}
//               required={formDetails[step - 1].isMandatory}
//               name={formDetails[step - 1].fieldName}
//               value={filledForm[step - 1].fieldValue || ""}
//               multiline={true}
//               minRows={4}
//               onChange={(e) =>
//                 handleChange(step - 1, e.target.name, e.target.value)
//               }
//             />
//           ) : formDetails[step - 1].type === "dropdown" ? (
//             <FormControl fullWidth>
//               <Select
//                 aria-labelledby="demo-radio-buttons-group-label"
//                 name="radio-buttons-group"
//                 variant="standard"
//                 label={formDetails[step - 1].fieldName}
//               >
//                 {formDetails[step - 1].options.map(
//                   (option, optionIdx) => {
//                     return (
//                       <MenuItem
//                         onClick={(e) =>
//                           handleOptionChange(
//                             option,
//                             step - 1,
//                             optionIdx
//                           )
//                         }
//                         key={option}
//                         value={option}
//                       >
//                         {option}
//                       </MenuItem>
//                     );
//                   }
//                 )}
//               </Select>
//               {!!filledForm[step - 1].options &&
//                 filledForm[step - 1].options.length === 1 &&
//                 filledForm[step - 1].options[0] === "Other" && (
//                   <TextField
//                     fullWidth
//                     id="standard-basic"
//                     label="Please Specify"
//                     required={formDetails[step - 1].isMandatory}
//                     variant="standard"
//                     name={formDetails[step - 1].fieldName}
//                     value={filledForm[step - 1].fieldValue}
//                     onChange={(e) =>
//                       handleChange(
//                         step - 1,
//                         e.target.name,
//                         e.target.value
//                       )
//                     }
//                   />
//                 )}
//             </FormControl>
//           ) : (
//             <TextField
//               fullWidth
//               id="standard-basic"
//               label={formDetails[step - 1].fieldName}
//               required={formDetails[step - 1].isMandatory}
//               variant="standard"
//               name={formDetails[step - 1].fieldName}
//               value={filledForm[step - 1].fieldValue || ""}
//               onChange={(e) =>
//                 handleChange(step - 1, e.target.name, e.target.value)
//               }
//             />
//           )}
//         </>
//       )}
//     <div
//       onClick={handleNext}
//       className="form_content_margins_btn primaryBtn form_btn"
//     >
//       {step === formDetails.length ? "Finish" : "Next"}
//     </div>
//   </div>
// ) : (
//   <div className="text-center flex-col items-center">
//     <h2 className="form_content_margins">Congratulations!</h2>
//     <p className="form_content_margins">
//       Your form has been submitted successfully. Thank you!
//     </p>

//     <LineBreak />
//     <p onClick={handleDone} className=" primaryBtn form_btn">
//       Done
//     </p>
//   </div>
// )} */}
