import React from "react";

import Input from "components/common/Input";
import EditFieldButton from "components/EditFieldButton";
import ImageIcon from "components/common/svg/ImageIcon";
import Avatar from "components/common/Avatar";
import { useSettingsContext } from "../SettingsChatRoom";

const SettingsNameSections: React.FC = () => {
  const {
    isOwner,
    isCreate,
    changeNameValue,
    nameValue,
    changeLogo,
    logo,
    submitName,
  } = useSettingsContext();

  const handleChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
    changeNameValue(e.target.value);
  };

  const handleSubmitNewRoomName = () => {
    if (typeof submitName === "function") {
      submitName();
    }
  };

  const handleAvatarChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target?.files && e.target?.files[0];
    if (typeof changeLogo === "function" && file) {
      changeLogo(file);
    }
  };

  const editButton = isOwner ? (
    <EditFieldButton onClick={handleSubmitNewRoomName} />
  ) : undefined;

  const isEditable = isOwner || isCreate;

  return (
    <div className="settings_name_sections">
      <div className="logo_edit_container">
        <Avatar src={logo} className="logo_container" alt={nameValue}>
          <ImageIcon />
        </Avatar>
        {isEditable && (
          <label className="load_logo_input">
            <input
              type={"file"}
              style={{ display: "none" }}
              onChange={handleAvatarChange}
              accept="image/*"
            />
          </label>
        )}
      </div>
      <Input
        helperText="Please provide a group name and optional group icon"
        endAdornment={editButton}
        classNameContainer="setting_room_name_input_container"
        placeholder="Chatroom name"
        value={nameValue}
        onChange={handleChangeName}
        readOnly={!isEditable}
      />
    </div>
  );
};

export default SettingsNameSections;
