import Activities from "components/Activities";
import Footer from "components/Footer";
import IslandLayout from "components/IslandLayout";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { getChannelListSelector } from "store/reducers/ChannelDetails/channelList";
import {
  getHiveActivitiesSelector,
  lessHiveDetailsSelector,
} from "store/reducers/HiveDetails/hiveActivities";
import {
  getAdminMenuSelector,
  getFooterSelector,
  getUserMenuSelector,
} from "store/reducers/HiveDetails/mainMenu";
import ChannelItem from "./components/ChannelItem";
import MenuItem from "./components/MenuItem";
import React, { useState, useEffect } from "react";
import { getHiveActivities } from "store/async-actions/Hive/hivePage";
import { getHiveSelector } from "store/reducers/HiveDetails/hiveDetails";
import PublicHives from "components/PublicHives";
import LineBreak from "components/LineBreak";

const MainMenu = () => {
  const mainMenu = useAppSelector(getUserMenuSelector);
  const adminMenu = useAppSelector(getAdminMenuSelector);
  const channelList = useAppSelector(getChannelListSelector);
  const footer = useAppSelector(getFooterSelector);

  const hiveDetails = useAppSelector(getHiveSelector);

  const map_menu = {
    componentType: "admin_menu",
    componentCode: "Maps",
    componentName: "Maps",
    componentDescription: "",
    componentIcon: "https://images.veehive.ai/webApp/map_export.png",
    componentRoute: "/maps",
    accessType: "admin",
    showSideMenu: false,
  };

  return (
    <div className="main_menu_container">
      {mainMenu && mainMenu.length > 0 && (
        <IslandLayout>
          <div className="user_menu">
            {mainMenu.map((menuItem) => {
              return (
                <MenuItem key={menuItem.componentCode} menuItem={menuItem} />
              );
            })}
          </div>
        </IslandLayout>
      )}
      {adminMenu && adminMenu.length > 0 && (
        <IslandLayout>
          <div className="admin_menu">
            {adminMenu.map((adminItem) => {
              return (
                <MenuItem key={adminItem.componentCode} menuItem={adminItem} />
              );
            })}
            {adminMenu[0] && hiveDetails?.communityId === 309 && (
              <MenuItem key="maps" menuItem={map_menu} />
            )}
          </div>
        </IslandLayout>
      )}
      {channelList && channelList.length > 0 && (
        <IslandLayout>
          <div className="channels_menu">
            <h2 className="channel_heading">Channels</h2>
            {channelList.map((channelItem) => {
              return (
                <ChannelItem
                  key={channelItem.channelUuid}
                  channelItem={channelItem}
                />
              );
            })}
          </div>
        </IslandLayout>
      )}
      {hiveDetails && hiveDetails.showSuggested && (
        <IslandLayout>
          <PublicHives />
        </IslandLayout>
      )}
      {footer[0] && (
        <IslandLayout>
          <Footer />
        </IslandLayout>
      )}
    </div>
  );
};

export default MainMenu;
