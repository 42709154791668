import { useEffect, useState, useCallback } from "react";

import {
  useAppDispatch,
  useAppSelector,
  useAppShallowSelector,
} from "hooks/redux";
import { getChatMessagesWithUserMentions } from "store/async-actions/Chat/chat";
import {
  getChatListWithMentionSelector,
  ChatItem,
  clearChatListWithMentioning,
  getChatMessagesMentioningLenghtSelector,
} from "store/reducers/Chat/chat";

type ReturnType = {
  isShowSearchControls: boolean;
  chatListWithMention: ChatItem[];
  detectSearchSymbol: (message: string) => void;
  closeSearchControls: () => void;
  chatListWithMentionLength: number;
};

const regexpTrigger = /^\/me$/g;

const isNeedFound = (chatMessage: string): boolean => {
  return !!chatMessage.match(regexpTrigger);
};

export const useSearchMe = (
  chatRoomId: string,
  userId: UserId | null
): ReturnType => {
  const dispatch = useAppDispatch();
  const chatListWithMention = useAppShallowSelector(
    getChatListWithMentionSelector
  );
  const chatListWithMentionLength = useAppSelector(
    getChatMessagesMentioningLenghtSelector
  );
  const [isSearch, setIsSearch] = useState<boolean>(false);

  const detectSearchSymbol = useCallback((chatMessage: string) => {
    setIsSearch(isNeedFound(chatMessage));
  }, []);

  const closeSearchControls = useCallback(() => {
    setIsSearch(false);
  }, []);

  useEffect(() => {
    if (chatRoomId && userId && isSearch) {
      dispatch(getChatMessagesWithUserMentions({ chatRoomId, userId }));
    }
  }, [chatRoomId, dispatch, isSearch, userId]);

  useEffect(() => {
    if (!isSearch && chatListWithMention.length) {
      dispatch(clearChatListWithMentioning());
    }
  }, [chatListWithMention, isSearch, dispatch]);

  return {
    isShowSearchControls: isSearch,
    chatListWithMention,
    detectSearchSymbol,
    closeSearchControls,
    chatListWithMentionLength,
  };
};
