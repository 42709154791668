import Dialog from "@mui/material/Dialog";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import Loader from "components/common/Loader";
import IslandLayout from "components/IslandLayout";
import PageLayout from "components/PageLayout";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  addToCart,
  razorPayVerifyPayment,
} from "store/async-actions/Channels/payment";
import { getChannelList } from "store/async-actions/Hive/hivePage";
import { getActiveChannelSelector } from "store/reducers/ChannelDetails/channelList";
import {
  cartIsLoadingSelector,
  getCartListSelector,
  getRazorPayOrderSelector,
  setPaymentCharged,
  stripePaymentChargedSelector,
} from "store/reducers/ChannelDetails/payments";
import { getHiveSelector } from "store/reducers/HiveDetails/hiveDetails";
import { isMemberViewSelector } from "store/reducers/Profile/profile";
import CheckoutForm from "./components/StripePayment";

const BuyChannelContainer = () => {
  const dispatch = useAppDispatch();
  const hiveDetails = useAppSelector(getHiveSelector);
  const currentChannel = useAppSelector(getActiveChannelSelector);

  const cartIsLoading = useAppSelector(cartIsLoadingSelector);
  const createOrderResp = useAppSelector(getRazorPayOrderSelector);

  const cartList = useAppSelector(getCartListSelector);

  const [openStripe, setOpenStripe] = useState(false);

  useEffect(() => {
    hiveDetails &&
      currentChannel &&
      dispatch(
        addToCart([
          {
            cartItemName: currentChannel?.channelName,
            cartItemType: "CHANNEL",
            cartItemReferenceId: currentChannel?.channelId,
            quantity: 1,
            communityId: hiveDetails?.communityId,
          },
        ])
      );
  }, [hiveDetails, currentChannel, dispatch]);

  function loadScript(src: string) {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }

  async function displayRazorPay() {
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );
    if (!res) {
      alert("Razorpay SDK failed to load. Are you online?");
      return;
    }
    const options = {
      key: "rzp_test_j2sDfnlDqZgcNo",
      // key: "rzp_live_wHpMkB5GN12X7J",
      amount: createOrderResp!.orderAmount.toString(),
      currency: createOrderResp!.currency,
      name: hiveDetails?.communityName,
      description: "Buy Channel",
      order_id: createOrderResp!.orderId,
      handler: function (response: {
        razorpay_payment_id: string;
        razorpay_order_id: string;
        razorpay_signature: string;
      }) {
        console.log(response);
        dispatch(
          razorPayVerifyPayment({
            paymentId: response.razorpay_payment_id,
            orderId: response.razorpay_order_id,
            signature: response.razorpay_signature,
          })
        );
      },
      theme: {
        color: "#61dafb",
      },
    };

    const paymentObject = new (window as any).Razorpay(options);
    paymentObject.on(
      "payment.failed",
      function (response: {
        error: {
          code: any;
          description: any;
          source: any;
          step: any;
          reason: any;
          metadata: { order_id: any; payment_id: any };
        };
      }) {
        // alert(response.error.code);
        // alert(response.error.description);
        // alert(response.error.source);
        // alert(response.error.step);
        alert(response.error.reason);
        // alert(response.error.metadata.order_id);
        // alert(response.error.metadata.payment_id);
      }
    );
    paymentObject.open();
  }

  const handleCheckout = () => {
    if (localStorage.getItem("countryCode") === "IN") {
      displayRazorPay();
    } else {
      setOpenStripe(true);
    }
  };

  const stripePromise = loadStripe(
    "pk_live_51IxUMeClzMdxwhfoBWrSrixtZYeyTSuKXhUwDB1bWoNxs5G9IYvxKAKqyBRwOkNrQEw4xkslBYW7JaZHKqZcRDJH00UakEmHVL"
    // "pk_test_51IxUMeClzMdxwhfo9SLZCdor2BSdjvfIfDGcCXgHobTkLTutZD0GaCDYNR21b46mFTOIouIS28vqudkCh8j8bVXn000qz0Lk6t"
  );

  const paymentCharged = useAppSelector(stripePaymentChargedSelector);
  const navigate = useNavigate();
  const isMemberView = useAppSelector(isMemberViewSelector);

  useEffect(() => {
    if (paymentCharged) {
      navigate("/home");
      dispatch(setPaymentCharged(false));
      dispatch(
        getChannelList({
          hiveId: hiveDetails?.communityId!,
          isMemberView: isMemberView,
        })
      );
      alert("Payment Successful");
    }
  }, [paymentCharged]);

  return (
    <PageLayout sideMenu={true}>
      <IslandLayout>
        <div className="buy_channel_container">
          <h3>Purchase Channel</h3>
          <p>Please verify the details below for your purchase</p>
          {cartList && cartList.length && (
            <div className="cart_details_view">
              <h2>{cartList[cartList.length - 1].cartItemName}</h2>
              <h2>
                ${cartList[cartList.length - 1].cartItemAmount.toFixed(2)}
              </h2>
            </div>
          )}
          <div onClick={handleCheckout} className="checkoutBtn primaryBtn">
            Checkout
          </div>
          <Dialog
            className="popup_dialogue"
            onClose={() => setOpenStripe(false)}
            open={openStripe}
          >
            <Elements stripe={stripePromise}>
              <CheckoutForm setOpenStripe={setOpenStripe} />
            </Elements>
          </Dialog>
        </div>
      </IslandLayout>
      {cartIsLoading && <Loader />}
    </PageLayout>
  );
};

export default BuyChannelContainer;
