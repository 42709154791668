import { CircularProgress } from "@mui/material";
import { StoryItemModel } from "api/models/Story/story";
import DateSelector from "components/DateSelector";
import LineBreak from "components/LineBreak";
import LongText from "components/LongText/LongText";
import {
  CloseBlackSmSVG,
  NoOfViewsSVG,
  CopyLinkSVG,
  DeleteStorySVG,
  HappySVG,
  NeuralSVG,
  SadSVG,
  NoOfViewsWhiteSVG,
} from "components/SVG/SVG";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { toastInfo } from "hooks/useToastify";
import { storyCountMock } from "mock/Story/storyMock";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  getStoryReactions,
  getStoryViews,
} from "store/async-actions/Analytics/analyticsActions";
import {
  deleteStory,
  getDayWiseAnalytics,
  getStoryLocation,
  getStorySocialCount,
} from "store/async-actions/Story/storyActions";
import {
  getAnalyticsIsLoading,
  getFromDateSelector,
  getStoryViewsSelector,
  getToDateSelector,
  getTotalReactionSelector,
} from "store/reducers/Analytics/analytics";
import {
  getStoryDaywiseAnalyticsSelector,
  getStoryLocationsSelector,
  getStorySocialCountSelector,
  getUnknownCountSelector,
  getViewingStoryUuidSelector,
  setStoryUuid,
} from "store/reducers/Story/story";
import DaywiseDateSelector from "./DaywiseDateSelector";
import moment from "moment";

const SegmentAnalytics = () => {
  const storyUuid = useAppSelector(getViewingStoryUuidSelector);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const fromDate = useAppSelector(getFromDateSelector);
  const toDate = useAppSelector(getToDateSelector);

  useEffect(() => {
    dispatch(
      getStoryViews({
        storyUuid: storyUuid,
      })
    );

    dispatch(
      getStoryReactions({
        storyUuid: storyUuid,
      })
    );

    dispatch(
      getStoryLocation({
        storyUuid: storyUuid,
      })
    );

    dispatch(
      getStorySocialCount({
        storyUuid: storyUuid,
      })
    );
  }, [dispatch, storyUuid]);

  useEffect(() => {
    dispatch(
      getDayWiseAnalytics({
        storyUuid: storyUuid,
        fromDate: null,
        toDate: null,
      })
    );
  }, [dispatch, storyUuid]);

  const storyLocations = useAppSelector(getStoryLocationsSelector);
  const totalReactions = useAppSelector(getTotalReactionSelector);

  const storyViews = useAppSelector(getStoryViewsSelector);

  const [scrollUp, setScrollUp] = useState(false);

  const handleScroll = () => {
    setScrollUp(true);
  };

  const isLoading = useAppSelector(getAnalyticsIsLoading);

  const socialCount = useAppSelector(getStorySocialCountSelector);

  const unknownCount = useAppSelector(getUnknownCountSelector);

  const daywise = useAppSelector(getStoryDaywiseAnalyticsSelector);

  return (
    <div onScroll={handleScroll} className={`segment_analytics_wrapper`}>
      <div className="story_content_actions white_text">
        <div className="story_content_copy_link">
          <div className="no_of_views_wrapper">
            <NoOfViewsWhiteSVG />
            <p>{storyViews?.count}</p>
          </div>
        </div>
        <div className="w_full story_options_spacing">
          <hr></hr>
        </div>
        <div className="story_reactions_wrapper">
          <div className="story_reaction_wrapper text_xs">
            <HappySVG />
            <p>{totalReactions.happy}</p>
          </div>
          <div className="story_reaction_wrapper text_xs">
            <NeuralSVG />
            <p>{totalReactions.neutral}</p>
          </div>
          <div className="story_reaction_wrapper text_xs">
            <SadSVG />
            <p>{totalReactions.sad}</p>
          </div>
        </div>
        <div className="w_full story_options_spacing">
          <hr></hr>
        </div>
        <div className="story_metrics_container">
          <h3 className="">Statistics</h3>
          <div className="story_metrics_wrapper">
            <p>Views</p>
            <p>
              {isLoading ? (
                <CircularProgress size={20} color="inherit" />
              ) : (
                storyViews?.count
              )}
            </p>
          </div>
          <div className="story_metrics_wrapper">
            <p>Reactions</p>
            {isLoading ? (
              <CircularProgress size={20} color="inherit" />
            ) : (
              totalReactions.total
            )}{" "}
          </div>
          <div className="story_metrics_wrapper">
            <p>Interactions</p>
            {isLoading ? (
              <CircularProgress size={20} color="inherit" />
            ) : (
              storyViews?.countInteractions
            )}{" "}
          </div>
          {!!storyLocations && storyLocations.length > 0 && (
            <div className="w_full story_options_spacing">
              <hr></hr>
            </div>
          )}
          {!!storyLocations && storyLocations.length > 0 && (
            <h3 className="">Location</h3>
          )}
          {!!storyLocations &&
            storyLocations.length > 0 &&
            storyLocations.map((location) => {
              return (
                <div className="story_metrics_wrapper">
                  <LongText
                    title={
                      !!location.location && location.location.length > 1
                        ? location.location[0].toUpperCase() +
                          location.location.slice(1).toLowerCase()
                        : "Unknown"
                    }
                    cutoff={18}
                  />
                  {isLoading ? (
                    <CircularProgress size={20} color="inherit" />
                  ) : (
                    location.locationCount
                  )}{" "}
                </div>
              );
            })}
          {!!socialCount && socialCount.length > 0 && (
            <div className="w_full story_options_spacing">
              <hr></hr>
            </div>
          )}
          {!!socialCount && socialCount.length > 0 && (
            <h3 className="">Origin</h3>
          )}
          {!!socialCount &&
            socialCount.length > 0 &&
            socialCount.map((data, idx) => {
              if (data.origin !== "unknown") {
                return (
                  <div key={idx} className="story_metrics_wrapper">
                    <p>{data.origin}</p>
                    <p>{data.originCount}</p>
                  </div>
                );
              } else {
                return (
                  <div key={idx} className="story_metrics_wrapper">
                    <p>Unknown</p>
                    <p>{unknownCount}</p>
                  </div>
                );
              }
            })}
          {!!daywise.length && (
            <div className="w_full story_options_spacing">
              <hr></hr>
            </div>
          )}
          {!!daywise.length && <h3>Day Wise Analytics</h3>}
          {/* <DaywiseDateSelector /> */}
          {!!daywise && (
            <div className="day_wise_segment_viewer">
              {daywise.map((data, idx) => {
                return (
                  <div className="">
                    <div key={idx} className="story_metrics_wrapper">
                      <p>Date</p>

                      <p>
                        {moment.utc(data.interactionDate).local().format("LL")}
                      </p>
                    </div>
                    <div key={idx} className="story_metrics_wrapper">
                      <p>Views</p>
                      <p>{data.count}</p>
                    </div>
                    <div key={idx} className="story_metrics_wrapper">
                      <p>Interactions</p>
                      <p>{data.countInteractions}</p>
                    </div>
                    <LineBreak />
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SegmentAnalytics;
