import React from "react";
import { Outlet } from "react-router-dom";

const AnalyticsChannelsContainer = () => {
  return (
    <>
      <Outlet />
    </>
  );
};

export default AnalyticsChannelsContainer;
