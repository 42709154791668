import Switch from "@mui/joy/Switch";
import { StyledEngineProvider, CssVarsProvider } from "@mui/joy/styles";
import LineBreak from "components/LineBreak";
import React, { Dispatch, SetStateAction, useState } from "react";
import { ReactMultiEmail } from "react-multi-email";
import { setIsPaid } from "store/reducers/CreateChannel/createChannel";

interface Props {
  emails: string[];
  setEmails: any;
  sendNotif: boolean;
  setSendNotif: Dispatch<SetStateAction<boolean>>;
}

const FormEmailNotifications = ({
  emails,
  setEmails,
  sendNotif,
  setSendNotif,
}: Props) => {
  const toggleSendNotif = () => {
    setSendNotif((prevState) => !prevState);
  };

  return (
    <div>
      <LineBreak />
      <StyledEngineProvider injectFirst>
        <CssVarsProvider>
          <div className="switch_display">
            <Switch checked={sendNotif} onChange={toggleSendNotif} />
            <p>Send Email Notification for responses</p>
          </div>
        </CssVarsProvider>
      </StyledEngineProvider>
      <LineBreak />
      {sendNotif && (
        <>
          <h3>Enter Email ID's</h3>
          <p>
            Enter the emails you would like to receive the notifications in.
          </p>
          <div className="react_multi_email_container">
            <ReactMultiEmail
              placeholder="ex: john@example.com; jane@example.com;"
              className="text-sm"
              emails={emails}
              onChange={setEmails}
              getLabel={(
                email: string,
                index: number,
                removeEmail: (index: number) => void
              ) => {
                return (
                  <div
                    // data-tag
                    key={index}
                    className="multi_email_tag"
                  >
                    <p>{email}</p>
                    <span
                      data-tag-handle
                      onClick={() => {
                        removeEmail(index);
                      }}
                      className="cursor-pointer"
                    >
                      ×
                    </span>
                  </div>
                );
              }}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default FormEmailNotifications;
