import React, { memo } from "react";
import sanitizeHtml from "sanitize-html";

import Text from "components/common/Text";
import VoiceMessages from "./VoiceMessages";
import Attachment from "./Attachment";

import { AppColors } from "theme";
import { getInitialsOfPerson } from "utils";
import ReactPlayer from "react-player";
import LineBreak from "components/LineBreak/LineBreak";
import Linkify from "react-linkify";

interface ChatMessageProps {
  isOwner?: boolean;
  time?: string;
  message?: string;
  senderName?: string;
  type?: string;
  videoUrl?: string;
}

const ChatBotMessage: React.FC<ChatMessageProps> = ({
  isOwner = false,
  time,
  senderName,
  message = "",
  type = "text",
  videoUrl,
}) => {
  const classNameOwner = isOwner ? "owner_message" : "not_owner_message";

  const senderColor = AppColors[getInitialsOfPerson(senderName)];

  function linkify(inputText: string) {
    const urlPattern =
      /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;
    return inputText.split(urlPattern).map((text, index) => {
      if (index % 2 === 0) {
        // This part is not a URL
        return text;
      } else {
        // This part is a URL
        return (
          <a href={text} key={index} target="_blank" rel="noopener noreferrer">
            {text}
          </a>
        );
      }
    });
  }

  return (
    <div className={`chat_bot_message_container ${classNameOwner}`}>
      <Text className="chat_message_time" fontWeight="w400">
        {time}
      </Text>
      <div className="chat_message_text_container">
        {!isOwner && (
          <Text
            className="sender_name"
            fontWeight="w500"
            style={{
              color: senderColor,
            }}
          >
            {senderName}
          </Text>
        )}
        <span className="chat_message_text_wrap">
          {isOwner ? (
            <Text
              fontWeight="w400"
              className="chat_message_text"
              dangerouslySetInnerHTML={{
                __html: sanitizeHtml(message, {
                  allowedTags: ["span", "br", "a"],
                  allowedClasses: {
                    span: ["message_user_mention"],
                    a: ["link"],
                  },
                }),
              }}
            />
          ) : (
            <Linkify
              componentDecorator={(decoratedHref, decoratedText, key) => (
                <a
                  className="link clickable_chatbot_link"
                  target="blank"
                  href={decoratedHref}
                  key={key}
                >
                  {decoratedText}
                </a>
              )}
            >
              {message}
            </Linkify>
          )}
        </span>
        {!!videoUrl && (
          <ReactPlayer
            url={videoUrl}
            controls
            width="200px"
            height="333px"
            style={{
              overflow: "hidden",
              borderRadius: "15px",
              background: "black",
            }}
          />
        )}
      </div>
    </div>
  );
};

export default memo(ChatBotMessage);
