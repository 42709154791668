import AnalyticsCapsule from "components/AnalyticsCapsule";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { useEffect } from "react";
import {
  getCtaNameDetails,
  getMaybeCtaDetails,
  getNoCtaDetails,
  getPageNameDetails,
  getYesCtaDetails,
} from "store/async-actions/Analytics/analyticsActions";
import {
  getCtaPageSelector,
  getMaybeCtaCountSelector,
  getNoCtaCountSelector,
  getYesCtaCountSelector,
} from "store/reducers/Analytics/analytics";
import { getCurrentEventSelector } from "store/reducers/HiveDetails/events";

import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepButton from "@mui/material/StepButton";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import StepLabel from "@mui/material/StepLabel";

const EventCta = () => {
  const currentEvent = useAppSelector(getCurrentEventSelector);
  const dispatch = useAppDispatch();

  useEffect(() => {
    currentEvent &&
      dispatch(
        getYesCtaDetails({
          ctaName: "Yes",
          webPath: `events/${currentEvent?.eventIdentifier}`,
          mobilePath: `mobile/events/${currentEvent?.eventIdentifier}`,
        })
      );
    currentEvent &&
      dispatch(
        getNoCtaDetails({
          ctaName: "No",
          webPath: `events/${currentEvent?.eventIdentifier}`,
          mobilePath: `mobile/events/${currentEvent?.eventIdentifier}`,
        })
      );
    currentEvent &&
      dispatch(
        getMaybeCtaDetails({
          ctaName: "Maybe",
          webPath: `events/${currentEvent?.eventIdentifier}`,
          mobilePath: `mobile/events/${currentEvent?.eventIdentifier}`,
        })
      );
    currentEvent &&
      dispatch(
        getPageNameDetails({
          webPath: `events/${currentEvent?.eventIdentifier}`,
          mobilePath: `mobile/events/${currentEvent?.eventIdentifier}`,
        })
      );
  }, [currentEvent]);

  const yesCount = useAppSelector(getYesCtaCountSelector);
  const noCount = useAppSelector(getNoCtaCountSelector);
  const MaybeCount = useAppSelector(getMaybeCtaCountSelector);
  const pageCount = useAppSelector(getCtaPageSelector);

  //   return (
  //     <>
  //       <div className="event_cta_deets_container">
  //         <AnalyticsCapsule>
  //           <div className="headers_and_value">
  //             <h5>Total Page Visits</h5>
  //             <h2>{pageCount || 0}</h2>
  //           </div>
  //         </AnalyticsCapsule>
  //         <AnalyticsCapsule>
  //           <div className="headers_and_value">
  //             <h5>
  //               {currentEvent?.isPaidEvent
  //                 ? "Payment Page Visits"
  //                 : "No Of Yes Clicked"}
  //             </h5>
  //             <h2>{yesCount || 0}</h2>
  //           </div>
  //         </AnalyticsCapsule>
  //         <AnalyticsCapsule>
  //           <div className="headers_and_value">
  //             <h5>RSVP No</h5>
  //             <h2>{noCount || 0}</h2>
  //           </div>
  //         </AnalyticsCapsule>
  //         <AnalyticsCapsule>
  //           <div className="headers_and_value">
  //             <h5>RSVP Maybe</h5>
  //             <h2>{MaybeCount || 0}</h2>
  //           </div>
  //         </AnalyticsCapsule>
  //       </div>

  //     </>
  //   );
  // };

  const EventVisitsComp = () => {
    return <div className="event_cta_icon">{pageCount || 0}</div>;
  };

  const OtherRSVPVisitsComp = () => {
    return (
      <div className="event_cta_icon">{noCount + MaybeCount + yesCount}</div>
    );
  };

  const YesComp = () => {
    return <div className="event_cta_icon">{yesCount}</div>;
  };

  return (
    <Box sx={{ width: "100%", padding: "20px" }}>
      <Stepper alternativeLabel>
        <Step completed={true}>
          <StepLabel StepIconComponent={EventVisitsComp}>
            Event Visits
          </StepLabel>
        </Step>
        <Step completed={true}>
          <StepLabel StepIconComponent={OtherRSVPVisitsComp}>
            Responses
          </StepLabel>
        </Step>
        <Step completed={true}>
          <StepLabel StepIconComponent={YesComp}>Yes</StepLabel>
        </Step>
      </Stepper>
    </Box>
  );
};

export default EventCta;
