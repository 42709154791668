import {
  CircularProgress,
  Paper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
} from "@mui/material";
import { EventUserItem } from "api/models/Hive/events";
import BackButton from "components/BackButton/BackButton";
import IslandLayout from "components/IslandLayout/IslandLayout";
import LineBreak from "components/LineBreak/LineBreak";
import LongText from "components/LongText/LongText";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getEventsPeopleList } from "store/async-actions/Hive/eventsActions";
import channelList from "store/reducers/ChannelDetails/channelList";
import {
  getCurrentEventSelector,
  getEventIsLoadingSelector,
  getEventsExportDataSelector,
  getEventsPeopleSelector,
  setCurrentEventUser,
} from "store/reducers/HiveDetails/events";
import EventUserDetails from "./EventUserDetails";
import { CSVLink } from "react-csv";
import { CSVDownloadSVG, SearchBarSVG } from "components/SVG/SVG";
import EventPeopleKebab from "./EventPeopleKebab";

const EventPeopleList = () => {
  const dispatch = useAppDispatch();
  const currentEvent = useAppSelector(getCurrentEventSelector);

  const navigate = useNavigate();

  const [selectedTab, setSelectedTab] = useState(0);

  useEffect(() => {
    currentEvent &&
      dispatch(
        getEventsPeopleList({
          eventIdentifier: currentEvent?.eventIdentifier,
          registerType: selectedTab + 1,
        })
      );
  }, [dispatch, currentEvent]);

  useEffect(() => {
    !!!currentEvent && navigate("/events");
  }, [dispatch, currentEvent]);

  const users = useAppSelector(getEventsPeopleSelector);

  const exportData = useAppSelector(getEventsExportDataSelector);

  const eventPeopleTabs = ["Attending", "Not Attending", "Maybe", "Attended"];

  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleUserClick = (user: EventUserItem) => {
    dispatch(setCurrentEventUser(user));
    handleOpen();
  };

  const [searchPeople, setSearchPeople] = useState("");
  const latestSearchTerm = useRef<string>("");
  const debounceTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  const handleSearch = (searchValue: string) => {
    latestSearchTerm.current = searchValue; // Update the latest search term
    if (currentEvent) {
      dispatch(
        getEventsPeopleList({
          eventIdentifier: currentEvent.eventIdentifier,
          registerType: selectedTab + 1, // Assuming `selectedTab` is defined elsewhere in your component
          searchInput: searchValue,
        })
      );
    }
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
    currentEvent &&
      dispatch(
        getEventsPeopleList({
          eventIdentifier: currentEvent?.eventIdentifier,
          registerType: newValue + 1,
        })
      );
  };

  const isFetching = useAppSelector(getEventIsLoadingSelector);

  // const createCsvData = [
  //    users[0].eventForm.map((data, idx) => data.fieldName.toString()),

  //   users.map(
  //     (data, idx) => [data.userName, data.email, data.phone]
  //     // data.eventForm.map((form, idxx) => form.fieldValue),
  //   ),
  // ];

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearchPeople(value);
    if (debounceTimeoutRef.current) {
      clearTimeout(debounceTimeoutRef.current);
    }
    debounceTimeoutRef.current = setTimeout(() => handleSearch(value), 300); // 300ms debounce time
  };

  useEffect(() => {
    return () => {
      if (debounceTimeoutRef.current) {
        clearTimeout(debounceTimeoutRef.current);
      }
    };
  }, []);

  return (
    <IslandLayout>
      <div className="event_back_btn_table">
        <BackButton to={`/events/${currentEvent?.eventIdentifier}`} />
      </div>
      <div className="searchBar flex items-center gap-x-2">
        <SearchBarSVG />
        <input
          onChange={handleInputChange}
          value={searchPeople}
          className="searchbar_input"
          placeholder="Search here"
        />
      </div>
      <Tabs value={selectedTab} onChange={handleChange} aria-label="video tabs">
        {eventPeopleTabs.map((tab, idx) => {
          return (
            <Tab
              style={{
                width: "25%",
                height: "20px",
                fontFamily: "IBM Plex Sans Condensed",
                fontStyle: "normal",
                fontWeight: 600,
                fontSize: "14px",
                lineHeight: "140%",
                textAlign: "center",
                letterSpacing: "0.02em",
                color: "#1C1B1F",
              }}
              key={idx}
              label={<div className="vid_tab_display">{tab}</div>}
            />
          );
        })}
      </Tabs>
      <LineBreak />
      <div className="flex-end px-5">
        <CSVLink
          filename={`${currentEvent?.eventIdentifier || "event"}_user_data`}
          data={exportData}
        >
          <CSVDownloadSVG />
        </CSVLink>
      </div>
      <LineBreak />
      {isFetching ? (
        <div className="flex flex-col items-center justify-center py-10">
          <CircularProgress />
          <p>Fetching details...</p>
        </div>
      ) : (
        <>
          {!!users.length ? (
            <TableContainer component={Paper}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      {" "}
                      <p className="bold_heading_table">User Name</p>
                    </TableCell>
                    <TableCell align="center">
                      {" "}
                      <p className="bold_heading_table">Email</p>
                    </TableCell>
                    <TableCell align="center">
                      {" "}
                      <p className="bold_heading_table">Phone</p>
                    </TableCell>
                    <TableCell align="center">
                      {" "}
                      <p className="bold_heading_table">Action(s)</p>
                    </TableCell>
                  </TableRow>
                </TableHead>
                {users.map((row, idx) => (
                  <TableBody key={idx}>
                    <TableRow
                      className="table_cell_hover pointer"
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell
                        onClick={() => handleUserClick(row)}
                        component="th"
                        scope="row"
                      >
                        <div className="user_and_profile_table">
                          <img
                            key={idx}
                            src={
                              row.profilePhoto ||
                              "https://veehivestage-images.s3.us-east-2.amazonaws.com/profileImage/defaultAvatar.png"
                            }
                            className="event_attendee_profile_table"
                          />
                          {(
                            <LongText title={row.userName || "-"} cutoff={16} />
                          ) || "-"}
                        </div>
                      </TableCell>
                      <TableCell
                        onClick={() => handleUserClick(row)}
                        className=""
                        align="center"
                        component="th"
                        scope="row"
                      >
                        {<LongText title={row.email || "-"} cutoff={16} /> ||
                          "-"}
                      </TableCell>
                      <TableCell
                        onClick={() => handleUserClick(row)}
                        className=""
                        align="center"
                        component="th"
                        scope="row"
                      >
                        {<LongText title={row.phone || "-"} cutoff={16} /> ||
                          "-"}
                      </TableCell>

                      <TableCell
                        className=""
                        align="center"
                        component="th"
                        scope="row"
                      >
                        <EventPeopleKebab user={row} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                ))}
              </Table>
            </TableContainer>
          ) : (
            <h3 className="no_table_entries">
              {!!searchPeople && "No entries found"}
            </h3>
          )}
        </>
      )}
      {isOpen && <EventUserDetails isOpen={isOpen} onReject={handleClose} />}
    </IslandLayout>
  );
};

export default EventPeopleList;
