import { TextField } from "@mui/material";
import { AttendeeFormDetails } from "api/models/Hive/events";
import IslandLayout from "components/IslandLayout/IslandLayout";
import LineBreak from "components/LineBreak/LineBreak";
import PageLayout from "components/PageLayout/PageLayout";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import AttendeeForm from "pages/Events/components/AttendeeForm";
import React, { useState } from "react";
import FormInformation from "./FormInformation";
import { getHiveUuid } from "store/reducers/HiveDetails/hiveDetails";
import { createForm } from "store/async-actions/Forms/forms";
import BackButton from "components/BackButton/BackButton";
import { toastInfo } from "hooks/useToastify";
import { useNavigate } from "react-router-dom";
import FormEmailNotifications from "./components/FormEmailNotification";

const CreateForm = () => {
  const dispatch = useAppDispatch();

  const [name, setName] = useState("");
  const [link, setLink] = useState("");

  const [attendeeFormDetails, setAttendeeFormDetails] = useState<
    AttendeeFormDetails[]
  >([
    {
      fieldName: "Enter your name",
      isMandatory: true,
      options: [],
      type: "text",
    },
  ]);

  const hiveUuid = useAppSelector(getHiveUuid);
  const navigate = useNavigate();

  const [sendNotif, setSendNotif] = useState(false);

  const [emails, setEmails] = useState<string[]>([]);

  const handleCreate = () => {
    dispatch(
      createForm({
        organizationUuid: hiveUuid,
        formList: attendeeFormDetails,
        formName: name,
        downloadLink: link,
        notifyEmails: emails,
        emailEnabled: sendNotif,
      })
    );
    toastInfo("Created Form Successfully!");
    navigate("/forms");
  };

  return (
    <PageLayout sideMenu={true}>
      <IslandLayout>
        <div className="page_padding">
          <BackButton to="/forms" />
          <div className="backBtn_spacing">
            <h2>Bring your form to life</h2>
            <p>Create a form to engage with your users</p>
          </div>
          <LineBreak />
          <TextField
            className="half_width"
            id="standard-basic"
            label="Form Name"
            variant="standard"
            name="name"
            onChange={(e) => setName(e.target.value)}
            value={name}
            required
          />
          <LineBreak />
          <TextField
            className="half_width"
            id="standard-basic"
            label="Redirect Link"
            variant="standard"
            name="link"
            onChange={(e) => setLink(e.target.value)}
            value={link}
          />
          <LineBreak />
          <FormInformation
            attendeeFormDetails={attendeeFormDetails}
            setAttendeeFormDetails={setAttendeeFormDetails}
          />
          <FormEmailNotifications
            sendNotif={sendNotif}
            setSendNotif={setSendNotif}
            emails={emails}
            setEmails={setEmails}
          />
          <div onClick={handleCreate} className="primaryBtn nextBtn">
            Create
          </div>
        </div>
      </IslandLayout>
    </PageLayout>
  );
};

export default CreateForm;
