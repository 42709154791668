import IslandLayout from "components/IslandLayout";
import PageLayout from "components/PageLayout";
import React, { useEffect, useState } from "react";
import MapsImport from "./MapsImport";
import LineBreak from "components/LineBreak";
import { LocationData } from "api/models/Maps/maps";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import {
  getInstallationsIsFetchingSelector,
  getInstallationsSelector,
} from "store/reducers/Maps/maps";
import { getMaps, updateMaps } from "store/async-actions/Maps/maps";
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Dialog,
  CircularProgress,
} from "@mui/material";
import LongText from "components/LongText/LongText";
import channelList from "store/reducers/ChannelDetails/channelList";
import { CSVDownloadSVG } from "components/SVG/SVG";
import { CSVLink } from "react-csv";

const MapsPage = () => {
  const mapsInstallations = useAppSelector(getInstallationsSelector);

  const dispatch = useAppDispatch();

  const [installation, setInstallation] =
    useState<LocationData[]>(mapsInstallations);

  useEffect(() => {
    dispatch(getMaps(null));
  }, []);

  useEffect(() => {
    setInstallation(mapsInstallations);
  }, [mapsInstallations]);

  const handleSave = () => {
    dispatch(updateMaps(installation));
  };

  const formatDownload = [
    [
      "Company",
      "Title",
      "Group",
      "Latitude",
      "Logitude",
      "Capacity",
      "Product",
      "Application",
      "Year",
      "Method",
      "State",
      "Location",
      "Count",
    ],
    [
      "ACME",
      "ACME Building",
      "Buildings",
      "10.25",
      "20.45",
      "200",
      "Materials",
      "Building",
      "2023",
      "Construction",
      "TN",
      "Chennai",
      "1200",
    ],
  ];

  const isLoading = useAppSelector(getInstallationsIsFetchingSelector);

  return (
    <PageLayout sideMenu={true}>
      <IslandLayout>
        <div className="page_padding">
          <h2>Maps</h2>
          <LineBreak />
          <div className="title_and_limit">
            <MapsImport setInsallations={setInstallation} />
            <CSVLink
              className="flex items-center text-black"
              filename="map_format"
              data={formatDownload}
            >
              <CSVDownloadSVG /> <span>Format</span>
            </CSVLink>
          </div>
          <LineBreak />
          <div onClick={handleSave} className="secondaryBtn w-fit">
            Save
          </div>
          <LineBreak />
          {!!installation && installation[0] && (
            <TableContainer component={Paper}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      {" "}
                      <p className="bold_heading_table">Channel Name</p>
                    </TableCell>
                    <TableCell align="center">
                      {" "}
                      <p className="bold_heading_table">Type</p>
                    </TableCell>
                    <TableCell align="center">
                      {" "}
                      <p className="bold_heading_table">Members</p>
                    </TableCell>

                    <TableCell align="center">
                      {" "}
                      <p className="bold_heading_table">Videos</p>
                    </TableCell>
                    <TableCell align="center">
                      {" "}
                      <p className="bold_heading_table">Details</p>
                    </TableCell>
                  </TableRow>
                </TableHead>
                {installation.map((row, idx) => (
                  <TableBody key={idx}>
                    {
                      <TableRow
                        className="table_cell_hover"
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell
                          className="text-black"
                          component="th"
                          scope="row"
                        >
                          {row.company || row.title || "N/A"}
                        </TableCell>
                        <TableCell align="center" component="th" scope="row">
                          {row.location || "N/A"}
                        </TableCell>
                        <TableCell align="center" component="th" scope="row">
                          {row.product || "N/A"}
                        </TableCell>
                        <TableCell align="center" component="th" scope="row">
                          {row.state || "N/A"}
                        </TableCell>
                        <TableCell align="center" component="th" scope="row">
                          {row.year || "N/A"}
                        </TableCell>
                      </TableRow>
                    }
                  </TableBody>
                ))}
              </Table>
            </TableContainer>
          )}
        </div>
        <Dialog open={isLoading}>
          <div className="page_padding">
            <CircularProgress size={20} color="inherit" />
          </div>
        </Dialog>
      </IslandLayout>
    </PageLayout>
  );
};

export default MapsPage;
