import BackButton from "components/BackButton";
import LineBreak from "components/LineBreak";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  getStoryDetails,
  getStoryItem,
  getStorySegments,
  publishStory,
} from "store/async-actions/Story/storyActions";
import {
  getCurrentStoryIndexSelector,
  getStorySegmentsSelector,
  getStoryUuidSelector,
} from "store/reducers/Story/story";
import StoryActions from "./StoryActions";
import StoryDisplay from "./StoryDisplay";
import StoryNavDots from "./StoryNavDots";
import DragReplace from "components/DragReplace/DragReplace";

const PublishStory = () => {
  const storyUuid = useAppSelector(getStoryUuidSelector);

  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const storySegments = useAppSelector(getStorySegmentsSelector);

  const storyIdx = useAppSelector(getCurrentStoryIndexSelector);

  useEffect(() => {
    !!!storyUuid && navigate("..");
  }, [storyUuid]);

  useEffect(() => {
    dispatch(getStorySegments({ storyUuid: storyUuid }));
    dispatch(getStoryItem({ storyUuid: storyUuid }));
  }, [dispatch, storyUuid]);

  const handlePublish = () => {
    dispatch(
      publishStory({
        storyUuid: storyUuid,
      })
    );
    navigate("/story");
  };

  const location = useLocation();

  return (
    <div className="create_story_container">
      <LineBreak />
      <div className="backBtn_spacing">
        <BackButton to={location.state?.from || ".."} />
      </div>
      <div className="story_edit_container">
        <div onClick={handlePublish} className="story_publish_btn secondaryBtn">
          Publish
        </div>
        <StoryDisplay />
        <div className="story_nav_dots_container">
          {/* <StoryNavDots /> */}
          <DragReplace
            originalItems={storySegments}
            currentIdx={storyIdx}
            dispatch={dispatch}
          />
        </div>
        <StoryActions />
      </div>
      <LineBreak />
    </div>
  );
};

export default PublishStory;
