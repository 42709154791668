import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { BaseResponse } from "api/models/base";
import {
  ContextDetailsModel,
  HiveDetails,
  HiveGuidelinesModel,
  ProductLinks,
} from "api/models/Hive/hiveDetails";
import { MemberDetailItem } from "api/models/Hive/hiveMembers";
import { ResourcePreSignedModel } from "api/models/Hive/introVideo";
import { WritableDraft } from "immer/dist/internal";
import {
  editHiveDetails,
  getActivityUserDetails,
  getHiveContext,
  getHiveDetails,
  getHiveGuidelines,
  getHiveListUsers,
  getIntroPresignedUrl,
} from "store/async-actions/Hive/hivePage";
import { createTypedSelector } from "store/utils";

const initialState: {
  hiveDetails: HiveDetails | null;
  isFetching: boolean;
  hiveUuid: string;
  doesExist: boolean;
  isPrivate: boolean;
  hiveIsEditing: boolean;
  hiveEdited: boolean;
  presignedResp: ResourcePreSignedModel | undefined;
  resourceUploadUrl: string;
  urlLocation: string;
  showSuggested: boolean;
  showChatBot: boolean;
  hiveContext: string;
  chatbotPersona: string;
  hiveProducts: ProductLinks[];
  productImageUploaded: boolean;
  productImageFile: string;
  productBlob: File | Blob | null;
  currentProduct: ProductLinks | null;
  hiveListMembers: MemberDetailItem | undefined;
  fetchingMemberDetails: boolean;
  eventRegisterType: number;
  hiveGuidelines: HiveGuidelinesModel[];
} = {
  presignedResp: undefined,
  resourceUploadUrl: "",
  hiveIsEditing: false,
  hiveEdited: false,
  hiveDetails: null,
  isFetching: true,
  hiveUuid: "",
  doesExist: true,
  isPrivate: false,
  urlLocation: "",
  showSuggested: false,
  showChatBot: false,
  hiveContext: "",
  hiveProducts: [],
  productImageUploaded: false,
  productImageFile: "",
  productBlob: null,
  currentProduct: null,
  hiveListMembers: undefined,
  fetchingMemberDetails: false,
  eventRegisterType: -1,
  hiveGuidelines: [],
  chatbotPersona: "",
};

const hiveSlice = createSlice({
  name: "hive",
  initialState,
  reducers: {
    setIsPrivate: (state, action: PayloadAction<boolean>) => {
      state.isPrivate = action.payload;
    },
    setHiveEdited: (state, action: PayloadAction<boolean>) => {
      state.hiveEdited = action.payload;
    },
    setShowSuggested: (state, action: PayloadAction<boolean>) => {
      state.showSuggested = action.payload;
    },
    setShowChatBot: (state, action: PayloadAction<boolean>) => {
      state.showChatBot = action.payload;
    },
    setUrlLocation: (state, action: PayloadAction<string>) => {
      state.urlLocation = action.payload;
    },
    setHiveContext: (state, action: PayloadAction<string>) => {
      state.hiveContext = action.payload;
    },
    setChatbotPersona: (state, action: PayloadAction<string>) => {
      state.chatbotPersona = action.payload;
    },
    setProductImgFile: (state, action: PayloadAction<string>) => {
      state.productImageFile = action.payload;
      state.productImageUploaded = true;
    },
    setProductImageUploaded: (state, action: PayloadAction<boolean>) => {
      state.productImageUploaded = action.payload;
    },
    setProductBlob(state, action: PayloadAction<File | Blob | null>) {
      state.productBlob = action.payload;
    },
    setCurrentProduct(state, action: PayloadAction<ProductLinks | null>) {
      state.currentProduct = action.payload;
    },
    launchLogin: (state) => {},
    setHiveIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isFetching = action.payload;
    },
    setEventRegisterType: (state, action: PayloadAction<number>) => {
      state.eventRegisterType = action.payload;
    },
  },
  extraReducers: {
    // GET HIVE DETAILS
    [getHiveDetails.pending.type]: (state) => {
      state.isFetching = true;
    },
    [getHiveDetails.fulfilled.type]: (
      state,
      action: PayloadAction<BaseResponse<HiveDetails>>
    ) => {
      if (action.payload.responseInfo.httpCode === 200) {
        state.hiveDetails = action.payload.data;
        state.hiveUuid = action.payload.data.communityUuid;
        state.isPrivate = action.payload.data.marketPlace === "PRIVATE";
        state.showChatBot = action.payload.data.chatSupportEnabled;
        localStorage.setItem("subDomain", state.hiveDetails.communitySubDomain);
        state.showSuggested = action.payload.data.showSuggested;
        document.title = `${state.hiveDetails.communityName} | Powered by Veehive`;
        setFavicon(state);
        state.doesExist = true;
      } else if (action.payload.responseInfo.httpCode === 302) {
        launchLogin();
        state.doesExist = true;
      } else {
        state.doesExist = false;
      }
      state.isFetching = false;
    },
    [getHiveDetails.rejected.type]: (state) => {
      // to do
      // document.location.href = "/error";
      state.doesExist = false;
      state.isFetching = false;
    },

    // EDIT HIVE DETAILS
    [editHiveDetails.pending.type]: (state) => {
      state.hiveIsEditing = true;
      state.hiveEdited = false;
    },
    [editHiveDetails.fulfilled.type]: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.hiveIsEditing = false;
      state.hiveEdited = true;
    },
    [editHiveDetails.rejected.type]: (state) => {
      // to do
      state.hiveEdited = true;
      state.hiveIsEditing = false;
    },

    // Get Pre-Signed url for Resources
    [getIntroPresignedUrl.pending.type]: (state) => {
      state.hiveIsEditing = true;
    },
    [getIntroPresignedUrl.fulfilled.type]: (
      state,
      action: PayloadAction<ResourcePreSignedModel>
    ) => {
      state.presignedResp = action.payload;
      state.resourceUploadUrl = action.payload.sourceUrl;
      state.hiveIsEditing = false;
    },
    [getIntroPresignedUrl.rejected.type]: (state) => {
      // to do
      state.hiveIsEditing = false;
    },

    // Get Hive Context
    [getHiveContext.pending.type]: (state) => {},
    [getHiveContext.fulfilled.type]: (
      state,
      action: PayloadAction<ContextDetailsModel>
    ) => {
      state.chatbotPersona = action.payload.organization_persona;
      state.hiveContext = action.payload.organization_context;
      state.hiveProducts = action.payload.product_links;
    },
    [getHiveContext.rejected.type]: (state) => {
      // to do
    },

    // Members Details
    [getHiveListUsers.pending.type]: (state) => {
      state.fetchingMemberDetails = true;
    },
    [getHiveListUsers.fulfilled.type]: (
      state,
      action: PayloadAction<MemberDetailItem>
    ) => {
      state.fetchingMemberDetails = false;
      state.hiveListMembers = action.payload;
    },
    [getHiveListUsers.rejected.type]: (state) => {
      // to do
      state.fetchingMemberDetails = false;
    },

    // Members Details
    [getActivityUserDetails.pending.type]: (state) => {
      state.fetchingMemberDetails = true;
    },
    [getActivityUserDetails.fulfilled.type]: (
      state,
      action: PayloadAction<MemberDetailItem>
    ) => {
      state.fetchingMemberDetails = false;
      state.hiveListMembers = action.payload;
    },
    [getActivityUserDetails.rejected.type]: (state) => {
      // to do
      state.fetchingMemberDetails = false;
    },

    // Hive Guidelines
    [getHiveGuidelines.pending.type]: (state) => {
      state.fetchingMemberDetails = true;
    },
    [getHiveGuidelines.fulfilled.type]: (
      state,
      action: PayloadAction<HiveGuidelinesModel[]>
    ) => {
      state.fetchingMemberDetails = false;
      state.hiveGuidelines = action.payload;
    },
    [getHiveGuidelines.rejected.type]: (state) => {
      // to do
      state.fetchingMemberDetails = false;
    },
  },
});

export const setFavicon = (
  state: WritableDraft<{
    hiveDetails: HiveDetails | null;
    isFetching: boolean;
    hiveUuid: string;
    doesExist: boolean;
    isPrivate: boolean;
    hiveIsEditing: boolean;
    hiveEdited: boolean;
    presignedResp: ResourcePreSignedModel | undefined;
    resourceUploadUrl: string;
    urlLocation: string;
  }>
) => {
  const links = document.getElementsByTagName("link");

  for (let i = 0; i < links.length; i++) {
    const link = links[i];
    if (link.getAttribute("rel") === "icon") {
      link.setAttribute("href", state.hiveDetails?.communityWebLogo!);
    }
  }
};

export const checkPath = () => {
  const location = document.location.pathname;

  localStorage.setItem("path", location);
};

export const checkMobileLaunch = () => {
  const location = document.location.pathname;

  localStorage.setItem("path", location);

  if (
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    )
  ) {
    document.location.href = `${document.location.href}/mobile${
      localStorage.getItem("subDomain") === "wise" ||
      localStorage.getItem("subDomain") === "vee" ||
      localStorage.getItem("subDomain") === "mnm" ||
      localStorage.getItem("subDomain") === "guruji" ||
      localStorage.getItem("subDomain") === "dtt" ||
      localStorage.getItem("subDomain") === "wkff" ||
      localStorage.getItem("subDomain") === "tbs" ||
      localStorage.getItem("subDomain") === "te"
        ? ""
        : "/app"
    }${localStorage.getItem("path")}?login=${localStorage.getItem(
      "@jwtToken"
    )}`;
  }
};

export const launchLogin = () => {
  const redirect = process.env.REACT_APP_LOGIN_URL;

  const location = document.location.pathname;

  localStorage.setItem("path", location);

  if (
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    )
  ) {
    window.open(
      redirect +
        `?domain=${localStorage.getItem(
          "subDomain"
        )}&redirect=mobile&path=${localStorage.getItem("path")}&userType=${
          localStorage.getItem("userType") || ""
        }&register=${localStorage.getItem(
          "register"
        )}&atxd=${localStorage.getItem("atxd")}`,
      "_self"
    );
  } else {
    window.open(
      redirect +
        `?domain=${localStorage.getItem(
          "subDomain"
        )}&path=${localStorage.getItem("path")}&userType=${
          localStorage.getItem("userType") || ""
        }&register=${localStorage.getItem(
          "register"
        )}&atxd=${localStorage.getItem("atxd")}`,
      "_self"
    );
  }
};

export const {
  setIsPrivate,
  setHiveEdited,
  setUrlLocation,
  setShowSuggested,
  setShowChatBot,
  setHiveContext,
  setProductBlob,
  setProductImageUploaded,
  setProductImgFile,
  setCurrentProduct,
  setHiveIsLoading,
  setEventRegisterType,
  setChatbotPersona,
} = hiveSlice.actions;

export const getPresignedUrlRespSelector = createTypedSelector(
  (state) => state.hive.presignedResp
);

export const getMembersDetailsList = createTypedSelector(
  (state) => state.hive.hiveListMembers
);

export const getChatbotPersonaSelector = createTypedSelector(
  (state) => state.hive.chatbotPersona
);

export const getEventRegisterTypeSelector = createTypedSelector(
  (state) => state.hive.eventRegisterType
);

export const getCurrentProductSelector = createTypedSelector(
  (state) => state.hive.currentProduct
);

export const getProductBlobSelector = createTypedSelector(
  (state) => state.hive.productBlob
);

export const getProductUrlSelector = createTypedSelector(
  (state) => state.hive.productImageFile
);

export const getFetchingMemberDetailsSelector = createTypedSelector(
  (state) => state.hive.fetchingMemberDetails
);

export const getProductImageUploadedSelector = createTypedSelector(
  (state) => state.hive.productImageUploaded
);

export const getHiveProductsSelector = createTypedSelector(
  (state) => state.hive.hiveProducts
);

export const getHiveGuidelinesSelector = createTypedSelector(
  (state) => state.hive.hiveGuidelines
);

export const getShowChatBotSelector = createTypedSelector(
  (state) => state.hive.showChatBot
);

export const getShowSuggestedHiveSelector = createTypedSelector(
  (state) => state.hive.showSuggested
);

export const getIntroFileUrl = createTypedSelector(
  (state) => state.hive.resourceUploadUrl
);

export const getHiveIsPrivateSelector = createTypedSelector(
  (state) => state.hive.isPrivate
);

export const getHiveIsEditedSelector = createTypedSelector(
  (state) => state.hive.hiveEdited
);

export const getHiveContextSelector = createTypedSelector(
  (state) => state.hive.hiveContext
);

export const getHiveIsEditingSelector = createTypedSelector(
  (state) => state.hive.hiveIsEditing
);

export const getHiveUuid = createTypedSelector((state) => state.hive.hiveUuid);

export const getHiveSelector = createTypedSelector(
  (state) => state.hive.hiveDetails
);

export const getDoesExistSelector = createTypedSelector(
  (state) => state.hive.doesExist
);

export const hiveDetailsLoadingSelector = createTypedSelector(
  (state) => state.hive.isFetching
);

export default hiveSlice.reducer;
