import React, { useCallback, useState, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import { toastError } from "hooks/useToastify";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import {
  clearAiImages,
  getAiImagesIsUploadedSelector,
  getAiShowNext,
  getUploadedAiImagesSelector,
  setIsUploaded,
  setShowNext,
  uploadAiImage,
} from "store/reducers/Ai/ai";
import { UploadAiSVG } from "components/SVG/SVG";

interface Props {
  mandatory?: boolean;
}

const AiDropzone = ({ mandatory }: Props) => {
  const uploadedImages = useAppSelector(getUploadedAiImagesSelector);
  const isUploaded = useAppSelector(getAiImagesIsUploadedSelector);
  const showNext = useAppSelector(getAiShowNext);

  const dispatch = useAppDispatch();

  useEffect(() => {
    // dispatch(clearAiImages());
    // dispatch(setIsUploaded(false));
    // dispatch(setShowNext(false));
  }, [dispatch]);

  const [images, setImages] = useState<any[]>([]);
  const handleDrop = (acceptedFiles: File[]) => {
    setImages([]);
    dispatch(clearAiImages());
    dispatch(setIsUploaded(false));
    dispatch(setShowNext(false));
    setImages(acceptedFiles);
    acceptedFiles.map((file, idx) => {
      if (file.type === "image/png" || file.type === "image/jpeg") {
        if (file.size < 15000000) {
          dispatch(uploadAiImage(file));
          dispatch(setIsUploaded(true));
          dispatch(setShowNext(true));
        } else {
          toastError(
            `${file.name} exceeds the max size, please upload images less than 10 mb`
          );
          if (uploadedImages.length < 1) {
            dispatch(setIsUploaded(false));
            dispatch(setShowNext(false));
          }
        }
      } else {
        toastError(
          `Invalid file: ${file.name}, please pass in .jpg or .png format`
        );
        if (uploadedImages.length < 1) {
          dispatch(setIsUploaded(false));
          dispatch(setShowNext(false));
        }
      }
    });
  };

  const onDropAccepted = useCallback(handleDrop, []);
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    onDrop: onDropAccepted,
  });

  return (
    <div className="w_full pointer">
      <div
        {...getRootProps()}
        className={`upload_wrapper dropzone ${
          isDragActive ? "dropzone--isActive border-blue-600" : ""
        } ${isDragAccept ? "dropzone--isAccept border-blue-600" : ""} ${
          isDragReject ? "dropzone--isReject border-red-600" : ""
        }`}
      >
        <input accept="image/*" {...getInputProps()} />
        {isUploaded ? (
          <>
            <div className="dropped_ai_images">
              {uploadedImages.map((file, idx) => {
                return (
                  <img
                    key={idx}
                    src={URL.createObjectURL(file)}
                    alt=""
                    className="dropped_ai_image"
                  />
                );
              })}
            </div>
            <h4 className="tags ai_dz_padding_text">
              {uploadedImages.length} image(s) uploaded
            </h4>
          </>
        ) : (
          <div className="ai_dropzone_content">
            <UploadAiSVG />
            <div>
              <p className="">
                Select files to upload {!mandatory && "(optional)"}
              </p>
              <p className="text-sm text-gray-400">or drag and drop files</p>
              <div className="font-bold text-sm">
                <div>Maximum image size: 10 MB</div>
                <div>Format: jpg, png</div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AiDropzone;
