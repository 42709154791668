import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { useAppSelector } from "hooks/redux";
import React, { useState } from "react";
import { getChannelListSelector } from "store/reducers/ChannelDetails/channelList";

interface Props {
  channelUuid: string;
  setChannelUuid: any;
}

const ChannelSelect = ({ channelUuid, setChannelUuid }: Props) => {
  const channelList = useAppSelector(getChannelListSelector);

  const [channel, setChannel] = useState(channelUuid || "");

  const handleChange = (channel: SelectChangeEvent) => {
    setChannel(channel.target.value);
    setChannelUuid(channel.target.value);
  };

  return (
    <div className="">
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">Move to</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={channel}
          label="Move to"
          onChange={handleChange}
        >
          {channelList.map((channel, idx) => {
            if (channel.channelUuid) {
              return (
                <MenuItem key={idx} value={channel.channelUuid}>
                  {channel.channelName}
                </MenuItem>
              );
            }
          })}
        </Select>
      </FormControl>
    </div>
  );
};

export default ChannelSelect;
