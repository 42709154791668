import MainMenu from "components/MainMenu";
import { useAppSelector, useAppDispatch } from "hooks/redux";
import React from "react";
import {
  getBurgerOpenSelector,
  setHamburgerState,
} from "store/reducers/Hamburger/hamburger";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  height: "100%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  overflow: "scroll",
};

const HamburgerMenu = () => {
  const isOpen = useAppSelector(getBurgerOpenSelector);
  const dispatch = useAppDispatch();

  const handleToggle = () => {
    dispatch(setHamburgerState(!isOpen));
  };
  return (
    <div
      onClick={handleToggle}
      className={`hamburger_menu_container ${
        isOpen
          ? "hamburger_menu_container_open"
          : "hamburger_menu_container_close"
      } `}
    >
      <MainMenu />
    </div>
  );
};

export default HamburgerMenu;
