import {
  CircularProgress,
  Dialog,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Switch,
  TextField,
  Tooltip,
} from "@mui/material";
import { AttendeeFormDetails } from "api/models/Hive/events";
import { HiveGuidelinesModel } from "api/models/Hive/hiveDetails";
import ConfirmationModal from "components/Confirmation/Confirmation";
import LineBreak from "components/LineBreak/LineBreak";
import Loader from "components/Loader";
import PaymentLoader from "components/PaymentLoader/PaymentLoader";
import { CloseBlackSmSVG, PlusSignSVG } from "components/SVG/SVG";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { toastInfo } from "hooks/useToastify";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import {
  addHiveGuidelines,
  deleteHiveGuidelines,
  editHiveGuidelines,
  getHiveGuidelines,
} from "store/async-actions/Hive/hivePage";
import { getHiveUuid } from "store/reducers/HiveDetails/hiveDetails";

interface Props {
  guidelines: HiveGuidelinesModel[];
  setGuidelines: Dispatch<SetStateAction<HiveGuidelinesModel[] | []>>;
}

const EditGuidelines = ({ guidelines, setGuidelines }: Props) => {
  const dispatch = useAppDispatch();
  const hiveUuid = useAppSelector(getHiveUuid);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    dispatch(
      getHiveGuidelines({
        organizationUuid: hiveUuid,
      })
    );
  }, [hiveUuid, dispatch]);

  const addQuestion = () => {
    dispatch(addHiveGuidelines({ organizationUuid: hiveUuid }));
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
      dispatch(
        editHiveGuidelines({
          guidelinesList: guidelines,
          organizationUuid: hiveUuid,
        })
      );
    }, 1500);
  };

  const handleQuestionChange = (idx: number, value: any) => {
    const updatedDetails = [...guidelines];
    updatedDetails[idx] = {
      ...updatedDetails[idx],
      title: value,
    };
    setGuidelines(updatedDetails);
  };

  const handleAnswerChange = (idx: number, value: any) => {
    const updatedDetails = [...guidelines];
    updatedDetails[idx] = {
      ...updatedDetails[idx],
      answer: value,
    };
    setGuidelines(updatedDetails);
  };

  const handleDelete = (idx: number) => {
    dispatch(
      deleteHiveGuidelines({ organizationUuid: hiveUuid, guidelineId: idx })
    );

    setIsLoading(true);

    const updatedDetails = [...guidelines];
    updatedDetails.splice(idx, 1);
    setGuidelines(updatedDetails);
    setTimeout(() => {
      setIsLoading(false);
      dispatch(
        editHiveGuidelines({
          guidelinesList: guidelines,
          organizationUuid: hiveUuid,
        })
      );
      handleConfirmClose();
    }, 1500);
  };

  const [confirmIsOpen, setConfirmIsOpen] = useState(false);
  const [param, setParam] = useState(0);

  const handleConfirmOpen = (param: number) => {
    setConfirmIsOpen(true);
    setParam(param);
  };

  const handleConfirmClose = () => {
    setConfirmIsOpen(false);
  };

  return (
    <div className="attendee_form_container">
      <p>Edit or update your hive guidelines here or answer any FAQ's</p>
      <LineBreak />
      {!!guidelines &&
        guidelines.length > 0 &&
        guidelines.map((data, idx) => {
          return (
            <div className="attendee_detail_content_wrapper">
              <div
                onClick={() => handleConfirmOpen(data.id)}
                className="attendee_question_delete_svg pointer"
              >
                <CloseBlackSmSVG />
              </div>
              <>
                <TextField
                  className=""
                  id="standard-basic"
                  label="Question"
                  variant="standard"
                  value={data.title}
                  onChange={(e) => handleQuestionChange(idx, e.target.value)}
                />
                <TextField
                  className=""
                  id="standard-basic"
                  label="Answer"
                  variant="standard"
                  value={data.answer}
                  onChange={(e) => handleAnswerChange(idx, e.target.value)}
                  multiline
                  minRows={2}
                />
              </>
            </div>
          );
        })}
      <div className="add_event_question">
        <Tooltip title="Add Question" placement="top">
          <p onClick={addQuestion}>
            <PlusSignSVG />
          </p>
        </Tooltip>
      </div>
      <ConfirmationModal
        onReject={handleConfirmClose}
        onConfirm={handleDelete}
        headline="Delete Guideline"
        description="Are you sure you want to delete this guideline?"
        rejectMessage="No"
        confirmMessage="Yes"
        isOpen={confirmIsOpen}
        param={param}
      />
      {isLoading && (
        <Dialog open={isLoading}>
          <div className="page_padding">
            <CircularProgress size={50} color="inherit" />
          </div>
        </Dialog>
      )}
    </div>
  );
};

export default EditGuidelines;
