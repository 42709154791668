import AiFileDrop from "components/AiFileDrop";
import LineBreak from "components/LineBreak";
import React from "react";
import { useAppSelector } from "hooks/redux";
import { getGoogleRespSelector } from "store/reducers/ContentMgr/contentMgr";

const ContentGenerate = () => {
  const googleResponse = useAppSelector(getGoogleRespSelector);

  return (
    <div className="ttv_container">
      <h4 className="tags">Upload File</h4>
      <LineBreak />
      <AiFileDrop fileType="video" size={150} />
    </div>
  );
};

export default ContentGenerate;
