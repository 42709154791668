import { Dialog } from "@mui/material";
import { FormResponseItem } from "api/models/Forms/forms";
import LineBreak from "components/LineBreak";
import { CloseBlackSVG, CloseBlackSmSVG } from "components/SVG/SVG";
import React from "react";

interface Props {
  onClose: any;
  isOpen: boolean;
  formDetails: FormResponseItem[];
}

export const FormDetailsModal = ({ onClose, isOpen, formDetails }: Props) => {
  return (
    <Dialog onClose={onClose} open={isOpen}>
      {!!formDetails && formDetails.length > 0 && (
        <div className="formDetails_popup">
          <div
            onClick={onClose}
            className="view_story_close_btn cursor-pointer"
          >
            <CloseBlackSVG />
          </div>
          <LineBreak />
          <h3>Form Response</h3>
          <LineBreak />
          <div className="normal_20-p">
            {formDetails.map((data, idx) => {
              return (
                <div>
                  <h4 className="fs-large">{data.fieldName}</h4>
                  <LineBreak />
                  <p className="fs-medium">
                    Response:{" "}
                    {data.fieldValue || data.options[0] || "No Response"}
                  </p>
                  <LineBreak />
                </div>
              );
            })}
            <LineBreak />
          </div>
        </div>
      )}
    </Dialog>
  );
};
