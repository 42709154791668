import React from "react";
import MainLayout from "components/MainLayout";
import {
  createBrowserRouter,
  RouterProvider,
  Navigate,
} from "react-router-dom";
import HomePage from "pages/Home";
import FeedPage from "pages/Feed";
import NotificationPage from "pages/Notifications";
import ChannelPage from "pages/Channel/ChannelInfo/ChannelPage";
import ChannelContainer from "pages/Channel";
import UploadVideo from "pages/UploadVideo";
import ComingSoon from "pages/ComingSoon";
import ChannelAbout from "pages/Channel/ChannelInfo/ChannelAbout";
import ChannelMembers from "pages/Channel/ChannelInfo/ChannelMembers";
import VideoView from "pages/Channel/ChannelVideo";
import CreateChannel from "pages/Hive/CreateChannel";
import ChatRoomCreatePage from "pages/Chat/ChatRoomCreatePage";
import ChatRoomCreatePageAddParticipants from "pages/Chat/ChatRoomCreatePageAddParticipants";
import ChatRoomCreatePageSettings from "pages/Chat/ChatRoomCreatePageSettings";
import ChatRoomDetail from "pages/Chat/ChatRoomDetail";
import ChatRoomPage from "pages/Chat/ChatRoomPage";
import ChatRoomSettingsPage from "pages/Chat/ChatRoomSettingsPage";
import ChatsPagesContainer from "pages/Chat/ChatsPagesContainer";
import HiveMembers from "pages/Hive/Settings/components/HiveMembers";
import HiveSettings from "pages/Hive/Settings/HiveSettings";
import InviteMembers from "pages/Hive/Settings/components/InviteMembers";
import MembersList from "pages/Hive/Settings/components/MembersList";
import AddContact from "pages/Hive/Settings/components/AddContact";
import BuyChannelContainer from "pages/Channel/BuyChannel/BuyChannelContainer";
import Mobile from "pages/Mobile/Mobile";
import AI from "pages/AI";
import EditChannelSettings from "pages/Channel/ChannelInfo/EditChannelSettings";
import ChannelInvite from "pages/Channel/ChannelInfo/InviteMembers";
import HiveAbout from "pages/Hive/Settings/components/HiveAbout";
import HiveMedia from "pages/Hive/Settings/components/HiveMedia";
import Analytics from "pages/Analytics";
import AnalyticsOverview from "pages/Analytics/components/AnalyticsOverview";
import AnalyticsChannels from "pages/Analytics/components/AnalyticsChannels";
import AnalyticsSentiments from "pages/Analytics/components/AnalyticsSetiments";
import EditVideo from "pages/Channel/ChannelVideo/EditVideo/EditVideoView";
import AnalyticsOverviewContainer from "pages/Analytics/AnalyticsOverviewContainer";
import AnalyticsDetails from "pages/Analytics/components/AnalyticsDetails";
import AiHome from "pages/AI/components/AiHome";
import TextToVideo from "pages/AI/components/TextToVideo";
import FormToVideo from "pages/AI/components/FormToVideo";
import CreateTeaser from "pages/AI/components/Teaser";
import PdfToVideo from "pages/AI/components/PdfToVideo";
import Transpose from "pages/AI/components/Transpose";
import LongToShort from "pages/AI/components/LongToShort";
import CompileTopics from "pages/AI/components/CompileTopics";
import SpotlightUpload from "pages/Hive/Spotlight/SpotlightUpload";
import SpotlightEdit from "pages/Hive/Spotlight/SpotlightEdit";
import StoryContainer from "pages/Story/StoryContainer";
import CreateStoryContainer from "pages/Story/UploadStory";
import CreateStory from "pages/Story/UploadStory/CreateStory";
import PublishStory from "pages/Story/UploadStory/PublishStory";
import AnalyticsChannelsContainer from "pages/Analytics/components/AnalyticsChannelsContainer";
import AnalyticsChannelDetails from "pages/Analytics/components/ChannelDetails";
import ViewStoryContainer from "pages/Story/ViewStory/ViewStoryContainer";
import StoryStart from "pages/Story/ViewStory/components/StoryStart";
import StoryView from "pages/Story/ViewStory/components/StoryView";
import TTVNext from "pages/AI/components/TTVNext";
import AiVideoView from "pages/AI/components/AiVideoView";
import EditStory from "pages/Story/UploadStory/EditStory";
import PdfToStory from "pages/AI/components/PdfToStory";
import ContentMgrContainer from "pages/ContentMgr/ContentMgrContainer";
import ContentMgrPage from "pages/ContentMgr/components/ContentMgrPage";
import SpotlightMain from "pages/Hive/Spotlight/SpotlightMain";
import UserPage from "pages/User/UserPage";
import EventsContainer from "pages/Events/EventsContainer";
import Events from "pages/Events/Events";
import CreateEvent from "pages/Events/CreateEvent";
import EventInfo from "pages/Events/EventInfo";
import EventPage from "pages/Events/EventPage";
import EditEvent from "pages/Events/EditEvent";
import EventPeopleList from "pages/Events/components/PeopleList";
import AllChannelList from "pages/Hive/Settings/components/AllChannelList";
import PlansContainer from "pages/Plans/PlansContainer";
import PurchasePlanContainer from "pages/Plans/PurchasePlan";
import CancelDelay1 from "pages/Plans/components/CancelDelay1";
import CancelDelay2 from "pages/Plans/components/CancelDelay2";
import AttendeeRegistration from "pages/Events/components/AttendeeRegistration";
import EventCheckout from "pages/Events/components/EventCheckout";
import CampaignContainer from "pages/Campaign";
import CampaignStepper from "pages/Campaign/CampaignStepper";
import EditCampaign from "pages/Campaign/EditCampaign";
import FormsContainer from "pages/Forms/FormsContainer";
import CreateForm from "pages/Forms/CreateForm";
import FormView from "pages/Forms/FormView";
import EditForm from "pages/Forms/EditForm";
import GuidelinesContainer from "pages/Hive/Settings/components/Guidelines/GuidelinesContainer";
import ChatBotSettings from "pages/Hive/Settings/components/ChatBot/ChatBotSettings";
import LinkFeatures from "pages/LinkFeatures/LinkFeatures";
import CampaignToStory from "pages/LinkFeatures/components/CampaignToStory";
import AllLinkFeatures from "pages/LinkFeatures/components/AllLinkFeatures";
import ViewCampaign from "pages/Campaign/ViewCampaign";
import { ViewChatBotMessages } from "pages/Hive/Settings/components/ChatBot/ViewChatbotMessages";
import { FormResponses } from "pages/Forms/FormResponses";
import Redirect from "pages/Redirect";
import AnalyticsLinks from "pages/Analytics/components/AnalyticsLinks";
import FeedVideoView from "pages/Feed/FeedVideoView";
import Maintenance from "pages/Maintenance";
import EventGalleryPage from "pages/Events/GalleryPage";
import MapsPage from "pages/Maps/MapsPage";

export enum Routes {
  BASE = "/",
  HOME = "/home",
  MOBILE = "/mobile",
  SETTINGS = "/home/settings",
  SETTINGS_ABOUT = "/home/settings/about",
  SETTINGS_MEDIA = "/home/settings/media",
  SETTINGS_CHANNELS = "/home/settings/channels",
  SETTINGS_MEMBERS = "/home/settings/members",
  SETTINGS_INVITE_CHANNELS = "/home/settings/members/invite",
  SETTINGS_INVITE_CONTACT = "/home/settings/members/invite/contact",
  SETTINGS_GUIDELINES = "/home/settings/guidelines",
  SETTINGS_CHATBOT = "/home/settings/chatbot",
  SETTINGS_CHATBOT_MESSAGES = "/home/settings/chatbot/:uuid",
  CHANNEL = "/channels/:uuid",
  BUY_CHANNEL = "/channels/:uuid/buy",
  CHANNEL_VIDEO = "/channels/:uuid/videos/:videouuid",
  CHANNEL_EDIT_VIDEO = "/channels/:uuid/videos/:videouuid/edit",
  CHANNEL_ABOUT = "/channels/:uuid/about",
  CHANNEL_MEMBERS = "/channels/:uuid/members",
  CHANNEL_INVITE = "/channels/:uuid/members/invite",
  CHANNEL_SETTINGS = "/channels/:uuid/settings",
  CHATROOMS = "/chat",
  CHATROOM = "/chat/:roomId",
  CHATROOM_SETTINGS = "/chat/:roomId/settings",
  CHATROOMS_CREATE = "/chat/create",
  CHATROOMS_CREATE_NEXT = "/chat/create/next",
  NOTIFICATIONS = "/notifications",
  FEED = "/feed",
  FEED_VIDEO = "/feed/:videouuid",
  UPLOAD = "/upload",
  CREATE_CHANNEL = "/create/channel",
  ANALYTICS = "/analytics",
  ANALYTICS_OVERVIEW = "/analytics/overview",
  ANALYTICS_OVERVIEW_DETAILS = "/analytics/overview/details",
  ANALYTICS_CHANNELS = "/analytics/channels",
  ANALYTICS_CHANNELS_ALL = "/analytics/channels/all",
  ANALYTICS_CHANNELS_DETAILS = "/analytics/channels/:channelId",
  ANALYTICS_SENTIMENT = "/analytics/sentiments",
  ANALYTICS_LINKS = "/analytics/links",
  ANALYTICS_ORIGIN = "/analytics/links/origin",
  ANALYTICS_LOCATIONS = "/analytics/links/origin/location",
  AI = "/ai",
  AI_HOME = "/ai/home",
  AI_VIDEO_VIEW = "/ai/videoView",
  AI_TTV = "/ai/textToVideo",
  AI_TTV_CREATE = "/ai/textToVideo/create",
  AI_FTV = "/ai/formToVideo",
  AI_TEASER = "/ai/teaser",
  AI_PDF = "/ai/pdfToVideo",
  AI_PDF_STORY = "/ai/pdfToStory",
  AI_TRANSPOSE = "/ai/transpose",
  AI_LTS = "/ai/longToShort",
  AI_COMPILE = "/ai/compileTopics",
  SPOTLIGHT_BASE = "/spotlight",
  SPOTLIGHT_UPLOAD = "/spotlight/upload",
  SPOTLIGHT_EDIT = "/spotlight/edit/:videouuid",
  STORY_HOME = "/story",
  STORY_EDIT = "/story/edit",
  STORY_CREATE = "/story/create",
  STORY_UPLOAD = "/story/create/upload",
  STORY_PUBLISH = "/story/create/publish",
  STORY_VIEW_BASE = "/viewstory",
  STORY_VIEW_START = "/viewstory/start",
  STORY_VIEW = "/viewstory/story/:storyuuid",
  CONTENT_MGR = "/content",
  CONTENT_AUTO = "/content/automate",
  CONTENT_INTEGRATE = "/content/integrate",
  CONTENT_SCHEDULE = "/content/schedule",
  CONTENT_DISTRIBUTE = "/content/distribute",
  CONTENT_ANALYSIS = "/content/analysis",
  USER_PAGE = "/user/:profileId",
  ACTIVITY_USER_PAGE = "/activity/users/:profileId",
  EVENTS = "/events",
  EVENTS_HOME = "/events/home",
  EVENTS_CREATE = "/events/home/create",
  EVENT_INFO = "/events/info",
  EVENT = "/events/:slug",
  EVENT_GALLERY = "/events/:slug/gallery",
  EVENT_EDIT = "/events/:slug/edit",
  EVENT_PEOPLE_LIST = "/events/:slug/attendees",
  EVENT_USER_DETAILS = "/events/:slug/attendees/:username",
  EVENT_REGISTRATION = "/events/:slug/registration",
  EVENT_PAYMENT = "/events/:slug/payment",
  PLANS = "/plans",
  PLANS_CANCEL = "/plans/cancel",
  PLANS_CANCEL_CONFIRM = "/plans/cancel/confirm",
  BUY_PLAN = "/plans/purchase",
  CAMPAIGNS = "/campaigns",
  CREATE_CAMPAIGN = "/campaigns/create",
  VIEW_CAMPAIGN = "/campaigns/:uuid",
  EDIT_CAMPAIGN = "/campaigns/:uuid/edit",
  FORM = "/forms",
  CREATE_FORM = "/forms/create",
  VIEW_FORM = "/forms/:formuuid",
  VIEW_FORM_RESPONSES = "/forms/:formuuid/responses",
  EDIT_FORM = "/forms/:formuuid/edit",
  LINK_FEATURES = "/linkfeatures",
  LINK_FEATURES_ALL = "/linkfeatures/all",
  LINK_CTS = "/linkfeatures/campaign-to-story",
  MAPS = "/maps",
  REDIRECT_PAGE = "/redirect",
}

export enum AbsoluteRoutes {
  GO_BACK = "..",
  HOME = "home",
  FEED = "feed",
  NOTIFICATIONS = "notifications",
  CREATE_CHANNEL = "create/channel",
  ANALYTICS = "analytics",
  AI = "ai",
  UPLOAD = "upload",
  CHATROOMS = "chat",
  CHATROOM_SETTINGS = "settings",
  CHATROOMS_CREATE = "create",
  CHATROOMS_CREATE_NEXT = "next",
  PLANS = "plans",
  CAMPAIGNS = "campaigns",
  MAPS = "maps",
}

const router = createBrowserRouter([
  {
    path: Routes.BASE,
    element: <MainLayout />,
    errorElement: <Maintenance />,
    children: [
      {
        index: true,
        element: <Navigate to={Routes.FEED} replace={true} />,
      },
      {
        path: Routes.HOME,
        element: <HomePage />,
      },
      {
        path: Routes.MOBILE,
        element: <Mobile />,
      },
      {
        path: Routes.REDIRECT_PAGE,
        element: <Redirect />,
      },
      {
        path: Routes.SETTINGS,
        element: <HiveSettings />,
        children: [
          {
            index: true,
            element: <Navigate to={Routes.SETTINGS_ABOUT} replace={true} />,
          },
          {
            path: Routes.SETTINGS_ABOUT,
            element: <HiveAbout />,
          },
          {
            path: Routes.SETTINGS_MEDIA,
            element: <HiveMedia />,
          },
          {
            path: Routes.SETTINGS_CHANNELS,
            element: <AllChannelList />,
          },
          {
            path: Routes.SETTINGS_GUIDELINES,
            element: <GuidelinesContainer />,
          },
          {
            path: Routes.SETTINGS_CHATBOT,
            element: <ChatBotSettings />,
          },
          {
            path: Routes.SETTINGS_CHATBOT_MESSAGES,
            element: <ViewChatBotMessages />,
          },
          {
            path: Routes.SETTINGS_MEMBERS,
            element: <HiveMembers />,
            children: [
              {
                index: true,
                element: <MembersList />,
              },
              {
                path: Routes.SETTINGS_INVITE_CHANNELS,
                element: <InviteMembers />,
              },
              {
                path: Routes.SETTINGS_INVITE_CONTACT,
                element: <AddContact />,
              },
            ],
          },
        ],
      },
      {
        path: Routes.FEED,
        element: <FeedPage />,
      },
      {
        path: Routes.FEED_VIDEO,
        element: <FeedVideoView />,
      },
      {
        path: Routes.NOTIFICATIONS,
        element: <NotificationPage />,
      },
      {
        path: Routes.CHANNEL,
        element: <ChannelContainer />,
        children: [
          {
            path: Routes.CHANNEL,
            element: <ChannelPage />,
          },
          {
            path: Routes.CHANNEL_VIDEO,
            element: <VideoView />,
          },
          {
            path: Routes.CHANNEL_EDIT_VIDEO,
            element: <EditVideo />,
          },
          {
            path: Routes.BUY_CHANNEL,
            element: <BuyChannelContainer />,
          },
          {
            path: Routes.CHANNEL_ABOUT,
            element: <ChannelAbout />,
          },
          {
            path: Routes.CHANNEL_MEMBERS,
            element: <ChannelMembers />,
          },
          {
            path: Routes.CHANNEL_INVITE,
            element: <ChannelInvite />,
          },
          {
            path: Routes.CHANNEL_SETTINGS,
            element: <EditChannelSettings />,
          },
          //   ],
          // },
        ],
      },
      {
        path: Routes.UPLOAD,
        element: <UploadVideo />,
      },

      {
        path: Routes.CREATE_CHANNEL,
        element: <CreateChannel />,
      },
      {
        path: "*",
        element: <ComingSoon />,
      },

      {
        path: Routes.CHATROOMS,
        element: <ChatsPagesContainer />,
        children: [
          // {
          //   path: Routes.CHATROOMS,
          //   element: <ChatRoomsPage />,
          // },
          {
            path: Routes.CHATROOM,
            element: <ChatRoomDetail />,
            children: [
              {
                path: Routes.CHATROOM,
                element: <ChatRoomPage />,
              },
              {
                path: Routes.CHATROOM_SETTINGS,
                element: <ChatRoomSettingsPage />,
              },
            ],
          },
          {
            path: Routes.CHATROOMS_CREATE,
            element: <ChatRoomCreatePage />,
            children: [
              {
                path: Routes.CHATROOMS_CREATE,
                element: <ChatRoomCreatePageAddParticipants />,
              },
              {
                path: Routes.CHATROOMS_CREATE_NEXT,
                element: <ChatRoomCreatePageSettings />,
              },
            ],
          },
        ],
      },
      {
        path: Routes.ANALYTICS,
        element: <Analytics />,
        children: [
          {
            index: true,
            element: <Navigate to={Routes.ANALYTICS_OVERVIEW} replace={true} />,
          },
          {
            element: <AnalyticsOverviewContainer />,
            children: [
              {
                index: true,
                element: (
                  <Navigate to={Routes.ANALYTICS_OVERVIEW} replace={true} />
                ),
              },
              {
                path: Routes.ANALYTICS_OVERVIEW,
                element: <AnalyticsOverview />,
              },
              {
                path: Routes.ANALYTICS_OVERVIEW_DETAILS,
                element: <AnalyticsDetails />,
              },
            ],
          },
          {
            path: Routes.ANALYTICS_CHANNELS,
            element: <AnalyticsChannelsContainer />,
            children: [
              {
                index: true,
                element: (
                  <Navigate to={Routes.ANALYTICS_CHANNELS_ALL} replace={true} />
                ),
              },
              {
                path: Routes.ANALYTICS_CHANNELS_ALL,
                element: <AnalyticsChannels />,
              },
              {
                path: Routes.ANALYTICS_CHANNELS_DETAILS,
                element: <AnalyticsChannelDetails />,
              },
            ],
          },
          {
            path: Routes.ANALYTICS_SENTIMENT,
            element: <AnalyticsSentiments />,
          },
          {
            path: Routes.ANALYTICS_LINKS,
            element: <AnalyticsLinks />,
          },
        ],
      },
      {
        path: Routes.AI,
        element: <AI />,
        children: [
          {
            index: true,
            element: <Navigate to={Routes.AI_HOME} replace={true} />,
          },
          {
            path: Routes.AI_HOME,
            element: <AiHome />,
          },
          {
            path: Routes.AI_VIDEO_VIEW,
            element: <AiVideoView />,
          },
          {
            path: Routes.AI_TTV,
            element: <TextToVideo />,
          },
          {
            path: Routes.AI_TTV_CREATE,
            element: <TTVNext />,
          },
          {
            path: Routes.AI_FTV,
            element: <FormToVideo />,
          },
          {
            path: Routes.AI_TEASER,
            element: <CreateTeaser />,
          },
          {
            path: Routes.AI_PDF,
            element: <PdfToVideo />,
          },
          {
            path: Routes.AI_PDF_STORY,
            element: <PdfToStory />,
          },
          {
            path: Routes.AI_TRANSPOSE,
            element: <Transpose />,
          },
          {
            path: Routes.AI_LTS,
            element: <LongToShort />,
          },
          {
            path: Routes.AI_COMPILE,
            element: <CompileTopics />,
          },
        ],
      },
      {
        path: Routes.STORY_VIEW_BASE,
        element: <ViewStoryContainer />,
        children: [
          {
            index: true,
            element: <Navigate to={Routes.STORY_VIEW_START} replace={true} />,
          },
          {
            path: Routes.STORY_VIEW_START,
            element: <StoryStart />,
          },
          {
            path: Routes.STORY_VIEW,
            element: <StoryView />,
          },
        ],
      },
      { path: Routes.SPOTLIGHT_BASE, element: <SpotlightMain /> },
      { path: Routes.SPOTLIGHT_UPLOAD, element: <SpotlightUpload /> },
      { path: Routes.SPOTLIGHT_EDIT, element: <SpotlightEdit /> },
      { path: Routes.STORY_HOME, element: <StoryContainer /> },
      { path: Routes.STORY_EDIT, element: <EditStory /> },
      {
        path: Routes.STORY_CREATE,
        element: <CreateStoryContainer />,
        children: [
          {
            index: true,
            element: <Navigate to={Routes.STORY_UPLOAD} replace={true} />,
          },
          {
            path: Routes.STORY_UPLOAD,
            element: <CreateStory />,
          },
          {
            path: Routes.STORY_PUBLISH,
            element: <PublishStory />,
          },
        ],
      },
      {
        path: Routes.CONTENT_MGR,
        element: <ContentMgrContainer />,
        children: [
          {
            index: true,
            element: <Navigate to={Routes.CONTENT_AUTO} replace={true} />,
          },
          {
            path: Routes.CONTENT_AUTO,
            element: <ContentMgrPage />,
          },
        ],
      },
      {
        path: Routes.USER_PAGE,
        element: <UserPage />,
      },
      // {
      //   path: Routes.ACTIVITY_USER_PAGE,
      //   element: <ActivityUserPage />,
      // },
      {
        path: Routes.EVENTS,
        element: <EventsContainer />,
        children: [
          {
            index: true,
            element: <Navigate to={Routes.EVENTS_HOME} replace={true} />,
          },
          {
            path: Routes.EVENTS_HOME,
            element: <Events />,
          },
          {
            path: Routes.EVENTS_CREATE,
            element: <CreateEvent />,
          },
          {
            path: Routes.EVENT_INFO,
            element: <EventInfo />,
          },
          {
            path: Routes.EVENT,
            element: <EventPage />,
          },
          {
            path: Routes.EVENT_GALLERY,
            element: <EventGalleryPage />,
          },
          {
            path: Routes.EVENT_EDIT,
            element: <EditEvent />,
          },
          {
            path: Routes.EVENT_PEOPLE_LIST,
            element: <EventPeopleList />,
          },
          // {
          //   path: Routes.EVENT_USER_DETAILS,
          //   element: <EventUserDetails />,
          // },
          {
            path: Routes.EVENT_REGISTRATION,
            element: <AttendeeRegistration />,
          },
          {
            path: Routes.EVENT_PAYMENT,
            element: <EventCheckout />,
          },
        ],
      },
      {
        path: Routes.PLANS,
        element: <PlansContainer />,
      },
      {
        path: Routes.BUY_PLAN,
        element: <PurchasePlanContainer />,
      },
      {
        path: Routes.PLANS_CANCEL,
        element: <CancelDelay1 />,
      },
      {
        path: Routes.PLANS_CANCEL_CONFIRM,
        element: <CancelDelay2 />,
      },
      {
        path: Routes.CAMPAIGNS,
        element: <CampaignContainer />,
      },
      {
        path: Routes.CREATE_CAMPAIGN,
        element: <CampaignStepper />,
      },
      {
        path: Routes.VIEW_CAMPAIGN,
        element: <ViewCampaign />,
      },
      {
        path: Routes.EDIT_CAMPAIGN,
        element: <EditCampaign />,
      },
      {
        path: Routes.FORM,
        element: <FormsContainer />,
      },
      {
        path: Routes.CREATE_FORM,
        element: <CreateForm />,
      },
      {
        path: Routes.VIEW_FORM,
        element: <FormView />,
      },
      {
        path: Routes.VIEW_FORM_RESPONSES,
        element: <FormResponses />,
      },
      {
        path: Routes.EDIT_FORM,
        element: <EditForm />,
      },
      {
        path: Routes.LINK_FEATURES,
        element: <LinkFeatures />,
        children: [
          {
            index: true,
            element: <Navigate to={Routes.LINK_FEATURES_ALL} replace={true} />,
          },
          {
            path: Routes.LINK_FEATURES_ALL,
            element: <AllLinkFeatures />,
          },
          {
            path: Routes.LINK_CTS,
            element: <CampaignToStory />,
          },
        ],
      },
      {
        path: Routes.MAPS,
        element: <MapsPage />,
      },
      {
        path: "/error",
        element: <Maintenance />,
      },
    ],
  },
]);

const BaseRoute = () => {
  return <RouterProvider router={router} />;
};

export default BaseRoute;
