import LineBreak from "components/LineBreak";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import React, { useState } from "react";
import { getSocialLinksSelector } from "store/reducers/HiveDetails/hiveSettings";
import { HiveFormObject } from "./HiveAbout";
import { saveSocialLinks } from "store/async-actions/Hive/hivePage";
import { getHiveUuid } from "store/reducers/HiveDetails/hiveDetails";
import { GreenTickSVG, RedCrossSVG } from "components/SVG/SVG";

interface Props {
  editHiveForm: HiveFormObject;
  handleHiveForm: (e: React.ChangeEvent<any>, limit: number) => void;
}

interface EditLinksType {
  [key: string]: boolean | undefined;
  linkedinLink: boolean;
  facebookLink: boolean;
  twitterLink: boolean;
  instagramLink: boolean;
  whatsappLink: boolean;
}

const SocialLinkEdit = ({ editHiveForm, handleHiveForm }: Props) => {
  const socialResp = useAppSelector(getSocialLinksSelector);
  const dispatch = useAppDispatch();

  const hiveUuid = useAppSelector(getHiveUuid);

  const [editLink, setEditLink] = useState<EditLinksType>({
    linkedinLink: false,
    facebookLink: false,
    twitterLink: false,
    instagramLink: false,
    whatsappLink: false,
  });

  const handleSocialLinkSave = (code: string) => {
    if (hiveUuid) {
      dispatch(
        saveSocialLinks({
          organizationUuid: hiveUuid,
          componentCode: code,
          handleUrl: editHiveForm[code] || "",
        })
      );
      setEditLink((prevState) => ({
        ...prevState,
        [code]: !prevState[code],
      }));
    }
  };

  return (
    <div>
      <h4>On the web</h4>
      <LineBreak />
      {socialResp.map((data, idx) => {
        return (
          <div key={idx} className="settings_social_config_header">
            <div className="settings_social_config_icon">
              <img src={data.icon} className="social_icon" alt="" />
              <p>{data.componentName}</p>
            </div>
            {!!data.route ? (
              <div className="link_edit_save_wrapper social_link_edit_View_wrapper">
                <input
                  name={data.componentCode}
                  value={editHiveForm[data.componentCode]}
                  onChange={(e) => handleHiveForm(e, 500)}
                  className="social_link_edit_input"
                />
                <div className="sle_confirm_btns">
                  <div
                    className="pointer"
                    onClick={() => {
                      editHiveForm[data.componentCode] = "";
                      handleSocialLinkSave(data.componentCode);
                    }}
                  >
                    <RedCrossSVG />
                  </div>
                </div>
              </div>
            ) : (
              <>
                {editLink[data.componentCode] ? (
                  <div className="link_edit_save_wrapper social_link_edit_input_wrapper">
                    <input
                      name={data.componentCode}
                      value={editHiveForm[data.componentCode]}
                      onChange={(e) => handleHiveForm(e, 500)}
                      className="social_link_edit_input"
                      placeholder="Enter URL"
                    />
                    <div className="sle_confirm_btns">
                      <div
                        className="pointer"
                        onClick={() => handleSocialLinkSave(data.componentCode)}
                      >
                        <GreenTickSVG />
                      </div>
                      <div
                        className="pointer"
                        onClick={() => {
                          setEditLink((prevState) => ({
                            ...prevState,
                            [data.componentCode]:
                              !prevState[data.componentCode],
                          }));
                        }}
                      >
                        <RedCrossSVG />
                      </div>
                    </div>
                  </div>
                ) : (
                  <div
                    onClick={() =>
                      setEditLink((prevState) => ({
                        ...prevState,
                        [data.componentCode]: !prevState[data.componentCode],
                      }))
                    }
                    className="link"
                  >
                    Link
                  </div>
                )}
              </>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default SocialLinkEdit;
