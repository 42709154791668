import { OnlineSVG, ProfileSVG } from "components/SVG/SVG";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import React, { useEffect } from "react";
import { getOnlineMembers } from "store/async-actions/Hive/hivePage";
import { getOnlineMembersSelector } from "store/reducers/HiveDetails/hiveActivities";
import { getHiveUuid } from "store/reducers/HiveDetails/hiveDetails";

const OnlineMembers = () => {
  const dispatch = useAppDispatch();
  const hiveUuid = useAppSelector(getHiveUuid);

  const onlineMembers = useAppSelector(getOnlineMembersSelector);

  return (
    <div className="activities_container online_members_container scrollable-y">
      <div className="title_and_limit">
        <h4>Online Members</h4>
        <p className="green_text">{onlineMembers.length}</p>
      </div>
      {/* {onlineMembers.map((member, idx) => {
        return (
          <div className="online_members_wrapper" key={idx}>
            <div className="activity_wrapper">
              {member.profilePhoto ? (
                <img
                  src={member.profilePhoto || ""}
                  alt=""
                  className="activity_profile_photo"
                />
              ) : (
                <div className="default_profile_pic">
                  <ProfileSVG />
                </div>
              )}
              <div>
                <p className="text-sm">{member.userName}</p>
              </div>
            </div>
            <div className="online_status_wrapper">
              <OnlineSVG />
            </div>
          </div>
        );
      })} */}
    </div>
  );
};

export default OnlineMembers;
