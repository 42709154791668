import React from 'react'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import IconButton from '@mui/material/IconButton'

import Checkbox from 'components/common/Checkbox'
import { useParticipantsContext } from '../ParticipantsChatRoomContainer'

interface ParticipantItemControlProps {
  id: UserId
}

const ParticipantItemControl: React.FC<ParticipantItemControlProps> = ({
  id,
}) => {
  const {
    isOpenSelectAll,
    selectedState,
    setSelectedState,
    handleDeleteParticipant,
    isCreate,
  } = useParticipantsContext()

  const handleDeleteItem = () => {
    handleDeleteParticipant(id)
  }

  const handleSelectItem = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setSelectedState([...selectedState, id])
    } else {
      setSelectedState(selectedState.filter((i) => i !== id))
    }
  }

  return (
    <div className="participant_item_control">
      {isOpenSelectAll || isCreate ? (
        <Checkbox
          onChange={handleSelectItem}
          checked={selectedState.includes(id)}
        />
      ) : (
        <IconButton
          className="delete_participant_button"
          onClick={handleDeleteItem}
        >
          <CloseRoundedIcon fontSize="inherit" />
        </IconButton>
      )}
    </div>
  )
}

export default ParticipantItemControl
