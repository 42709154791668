import { CircularProgress, Dialog } from "@mui/material";
import AnalyticsCapsule from "components/AnalyticsCapsule";
import BackButton from "components/BackButton/BackButton";
import IslandLayout from "components/IslandLayout/IslandLayout";
import LineBreak from "components/LineBreak/LineBreak";
import LongText from "components/LongText/LongText";
import PageLayout from "components/PageLayout/PageLayout";
import { CloseBlackSVG, DefaultUserBigSVG } from "components/SVG/SVG";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import moment from "moment";
import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ReactTimeAgo from "react-time-ago";
import {
  getActivityUserDetails,
  getHiveListUsers,
} from "store/async-actions/Hive/hivePage";
import { getCurrentEventUserSelector } from "store/reducers/HiveDetails/events";
import { getClickedProfileIdSelector } from "store/reducers/HiveDetails/hiveActivities";
import {
  getFetchingMemberDetailsSelector,
  getHiveUuid,
  getMembersDetailsList,
} from "store/reducers/HiveDetails/hiveDetails";

interface Props {
  isOpen: boolean;
  onReject: any;
}

const EventUserDetails = ({ isOpen, onReject }: Props) => {
  const dispatch = useAppDispatch();

  const hiveUuid = useAppSelector(getHiveUuid);

  const profileId = useAppSelector(getClickedProfileIdSelector);

  const isLoading = useAppSelector(getFetchingMemberDetailsSelector);

  const navigate = useNavigate();

  const member = useAppSelector(getCurrentEventUserSelector);

  return (
    <Dialog onClose={onReject} open={isOpen}>
      <IslandLayout>
        {isLoading ? (
          <div>
            <CircularProgress size={30} color="inherit" />
          </div>
        ) : (
          <div className="user_page_container">
            <div
              onClick={onReject}
              className="pointer pointer_close_user_detail"
            >
              <CloseBlackSVG />
            </div>
            <LineBreak />
            <div className="user_page_contaienr">
              {member && member.profilePhoto ? (
                <img src={member.profilePhoto} className="default_logo" />
              ) : (
                <DefaultUserBigSVG />
              )}
              <div>
                <h3>{member?.userName || ""}</h3>
              </div>
            </div>
            <div className="user_data_container">
              <div className="h_n_v-user">
                <AnalyticsCapsule>
                  <div className="headers_and_value">
                    <h5>User Name</h5>
                    <h2>{member?.userName || ""}</h2>
                  </div>
                </AnalyticsCapsule>
              </div>

              {!!member?.email && (
                <div className="h_n_v-user">
                  <AnalyticsCapsule>
                    <div className="headers_and_value">
                      <h5>Email</h5>
                      <LongText
                        bold={true}
                        title={member?.email || ""}
                        cutoff={25}
                      ></LongText>
                    </div>
                  </AnalyticsCapsule>
                </div>
              )}
            </div>
            {!!member?.eventForm && member?.eventForm.length > 0 && (
              <>
                <LineBreak />
                <h2>Form Details</h2>
                <LineBreak />
                <div className=" normal_20-p">
                  {member?.eventForm.map((data, idx) => {
                    return (
                      <div>
                        <h4 className="fs-large">{data.fieldName}</h4>
                        <LineBreak />
                        <p className="fs-medium">
                          Response:{" "}
                          {data.fieldValue || data.options[0] || "No Response"}
                        </p>
                        <LineBreak />
                      </div>
                    );
                  })}
                  <LineBreak />
                </div>
              </>
            )}
            <LineBreak />
          </div>
        )}
      </IslandLayout>
    </Dialog>
  );
};

export default EventUserDetails;
