import BaseApi from "api/base";
import { FeedModel, FeedRequestModel } from "api/models/Feed/Feed";
import { AxiosInstance, AxiosPromise } from "axios";

import { BaseResponse } from "../../models/base";
import {
  AddCampaignEmailsReq,
  AddCampaignNosReq,
  CampaignModel,
  CampaignNumbersModel,
  CreateCampaignReq,
  EditSMSCampaignReq,
  UploadCampaignResourceModel,
  UploadCampaignResourceReq,
} from "api/models/Campaign/campaign";
import { ResourcePreSignedModel } from "api/models/Hive/introVideo";

export class CampaignApi extends BaseApi {
  createCampaign(data: CreateCampaignReq): AxiosPromise<BaseResponse<string>> {
    return this.request({
      url: `${this.url}/campaign/create`,
      method: "POST",
      data,
    });
  }

  editCampaign(data: EditSMSCampaignReq): AxiosPromise<BaseResponse<boolean>> {
    return this.request({
      url: `${this.url}/campaign/edit`,
      method: "POST",
      data,
    });
  }

  getEmailImagePresignedUrl(data: {
    type: string;
    communityId: number;
  }): AxiosPromise<BaseResponse<ResourcePreSignedModel>> {
    return this.request({
      url: `/content/admin/resources/common/pre/signed`,
      method: "POST",
      data,
    });
  }

  uploadCampaignResources(
    data: UploadCampaignResourceReq
  ): AxiosPromise<BaseResponse<UploadCampaignResourceModel>> {
    return this.request({
      url: `/webApp/campaign/upload/resources`,
      method: "POST",
      data,
    });
  }

  addCampaignNumbers(
    data: AddCampaignNosReq
  ): AxiosPromise<BaseResponse<boolean>> {
    return this.request({
      url: `/notification/campaign/save/sms`,
      method: "POST",
      data,
    });
  }

  addCampaignEmails(
    data: AddCampaignEmailsReq
  ): AxiosPromise<BaseResponse<boolean>> {
    return this.request({
      url: `/notification/campaign/save/email`,
      method: "POST",
      data,
    });
  }

  getCampaignsList(data: {
    organizationUuid: string;
  }): AxiosPromise<BaseResponse<CampaignModel[]>> {
    return this.request({
      url: `${this.url}/campaign/get/list`,
      method: "POST",
      data,
    });
  }

  getEmailCampaignData(data: {
    campaignUuid: string;
    organizationUuid: string;
  }): AxiosPromise<BaseResponse<EditSMSCampaignReq>> {
    return this.request({
      url: `${this.url}/campaign/get/email/data`,
      method: "POST",
      data,
    });
  }

  getCurrentCampaign(data: {
    campaignUuid: string;
  }): AxiosPromise<BaseResponse<CampaignModel>> {
    return this.request({
      url: `${this.url}/campaign/get`,
      method: "POST",
      data,
    });
  }

  deleteCampaign(data: {
    campaignUuid: string;
  }): AxiosPromise<BaseResponse<boolean>> {
    return this.request({
      url: `${this.url}/campaign/delete`,
      method: "POST",
      data,
    });
  }

  getCampaignNumbers(data: {
    campaignUuid: string;
  }): AxiosPromise<BaseResponse<CampaignNumbersModel[]>> {
    return this.request({
      url: `notification/campaign/get/sms`,
      method: "POST",
      data,
    });
  }

  sendSMSCampaign(data: {
    campaignUuid: string;
  }): AxiosPromise<BaseResponse<boolean>> {
    return this.request({
      url: `notification/campaign/send/sms`,
      method: "POST",
      data,
    });
  }

  getCampaignEmails(data: {
    campaignUuid: string;
  }): AxiosPromise<BaseResponse<CampaignNumbersModel[]>> {
    return this.request({
      url: `notification/campaign/get/email`,
      method: "POST",
      data,
    });
  }

  sendEmailCampaign(data: {
    organizationUuid: string;
    campaignUuid: string;
  }): AxiosPromise<BaseResponse<boolean>> {
    return this.request({
      url: `notification/campaign/send/email`,
      method: "POST",
      data,
    });
  }

  linkCampaignToStory(data: {
    campaignUuid: string;
    serviceType: string;
    serviceUuid: string;
    organizationUuid: string;
  }): AxiosPromise<BaseResponse<boolean>> {
    return this.request({
      url: `${this.url}/campaign/save/linked/service`,
      method: "POST",
      data,
    });
  }

  getLinkedCampaigns(data: {
    campaignUuid: string;
    organizationUuid: string;
  }): AxiosPromise<BaseResponse<boolean>> {
    return this.request({
      url: `${this.url}/campaign/get/list/linked/service`,
      method: "POST",
      data,
    });
  }
}

export default function campaignApi(request: AxiosInstance) {
  return new CampaignApi({
    request,
    url: `/webApp`,
  });
}
