import {
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField,
} from "@mui/material";
import IslandLayout from "components/IslandLayout/IslandLayout";
import LineBreak from "components/LineBreak/LineBreak";
import PageLayout from "components/PageLayout/PageLayout";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { cancelSubscription } from "store/async-actions/Subscriptions/subscriptions";
import { getHiveUuid } from "store/reducers/HiveDetails/hiveDetails";

const CancelDelay2 = () => {
  const reasons = [
    "Cost",
    "Difficult to use",
    "Using other product",
    "Not using it",
    "Other",
  ];

  const [selectedValue, setSelectedValue] = useState("");

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedValue((event.target as HTMLInputElement).value);
  };

  const [otherReason, setOtherReason] = useState("");

  const handleReasonsChange = (e: any) => {
    setOtherReason(e.target.value.slice(0, 180));
  };

  const router = useNavigate();

  const dispatch = useAppDispatch();

  const hiveUuid = useAppSelector(getHiveUuid);

  const handleCancel = () => {
    dispatch(
      cancelSubscription({
        organizationUuid: hiveUuid,
        notes: selectedValue === "Other" ? otherReason : selectedValue,
      })
    );
  };

  const handleKeep = () => {
    router("/plans");
  };

  const handleContact = () => {
    window.open("https://wa.me/17705255504", "_blank");
  };

  return (
    <PageLayout sideMenu={true}>
      <IslandLayout>
        <div className="ce_about_wrapper cancel_delay_wraper_spacing">
          <h2>Cancel Subscription</h2>
          <LineBreak />
          <h4>We would like to know the reason for your cancellation.</h4>
          <LineBreak />
          <FormControl>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              name="radio-buttons-group"
              onChange={handleChange}
              value={selectedValue}
            >
              {reasons.map((data, idx) => {
                return (
                  <FormControlLabel
                    key={idx}
                    value={data}
                    control={<Radio />}
                    label={data}
                  />
                );
              })}
            </RadioGroup>
          </FormControl>
          <LineBreak />
          {selectedValue === "Other" && (
            <>
              <TextField
                className=""
                id="standard-basic"
                label="Other Reason"
                placeholder="Please mention any other reasoning"
                variant="outlined"
                multiline
                minRows={4}
                name="Other Reason"
                onChange={handleReasonsChange}
                value={otherReason}
              />{" "}
              <LineBreak />
            </>
          )}
          <h4>We may have an offer that may suit you..</h4>
          <LineBreak />
          <p>
            We offer a wide range of pricing options and feature. Contact us
            today and let us help you find the perfect solution for your needs.
          </p>
          <LineBreak />
          <div className="cancel_plan_btns">
            {(selectedValue !== "Other" && !!selectedValue) || otherReason ? (
              <div onClick={handleCancel} className="secondaryBtn">
                Continue to cancel
              </div>
            ) : (
              <div className="disabledBtn">Continue to cancel</div>
            )}
            <div onClick={handleContact} className="primaryBtn">
              Contact Us
            </div>
            <div onClick={handleKeep} className="primaryBtn">
              Keep my subscription
            </div>
          </div>
        </div>
      </IslandLayout>
    </PageLayout>
  );
};

export default CancelDelay2;
