const Photo = ({ className = "" }: SvgIconProps) => {
  return (
    <svg
      width="18"
      height="17"
      fontSize="inherit"
      viewBox="0 0 18 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M1.5 6.28524C1.5 6.0225 1.5 5.89113 1.51096 5.78048C1.6167 4.71327 2.46095 3.86902 3.52816 3.76328C3.63881 3.75232 3.77727 3.75232 4.05419 3.75232C4.16089 3.75232 4.21424 3.75232 4.25954 3.74958C4.83796 3.71455 5.34446 3.34948 5.56061 2.81182C5.57754 2.76971 5.59336 2.72225 5.625 2.62732C5.65664 2.53239 5.67246 2.48493 5.68939 2.44282C5.90554 1.90516 6.41204 1.54009 6.99046 1.50506C7.03576 1.50232 7.08579 1.50232 7.18585 1.50232H10.8141C10.9142 1.50232 10.9642 1.50232 11.0095 1.50506C11.588 1.54009 12.0945 1.90516 12.3106 2.44282C12.3275 2.48493 12.3434 2.53239 12.375 2.62732C12.4066 2.72225 12.4225 2.76971 12.4394 2.81182C12.6555 3.34948 13.162 3.71455 13.7405 3.74958C13.7858 3.75232 13.8391 3.75232 13.9458 3.75232C14.2227 3.75232 14.3612 3.75232 14.4718 3.76328C15.539 3.86902 16.3833 4.71327 16.489 5.78048C16.5 5.89113 16.5 6.0225 16.5 6.28524V12.1523C16.5 13.4124 16.5 14.0425 16.2548 14.5238C16.039 14.9472 15.6948 15.2914 15.2715 15.5071C14.7902 15.7523 14.1601 15.7523 12.9 15.7523H5.1C3.83988 15.7523 3.20982 15.7523 2.72852 15.5071C2.30516 15.2914 1.96095 14.9472 1.74524 14.5238C1.5 14.0425 1.5 13.4124 1.5 12.1523V6.28524Z"
        stroke="black"
        strokeOpacity="0.7148"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 12.3773C10.6569 12.3773 12 11.0342 12 9.37732C12 7.72047 10.6569 6.37732 9 6.37732C7.34315 6.37732 6 7.72047 6 9.37732C6 11.0342 7.34315 12.3773 9 12.3773Z"
        stroke="black"
        strokeOpacity="0.7148"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Photo;
