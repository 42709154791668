import { CircularProgress, Dialog } from "@mui/material";
import AnalyticsCapsule from "components/AnalyticsCapsule";
import BackButton from "components/BackButton/BackButton";
import IslandLayout from "components/IslandLayout/IslandLayout";
import LineBreak from "components/LineBreak/LineBreak";
import LongText from "components/LongText/LongText";
import PageLayout from "components/PageLayout/PageLayout";
import { CloseBlackSVG, DefaultUserBigSVG } from "components/SVG/SVG";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import moment from "moment";
import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ReactTimeAgo from "react-time-ago";
import {
  getActivityUserDetails,
  getHiveListUsers,
} from "store/async-actions/Hive/hivePage";
import { getClickedProfileIdSelector } from "store/reducers/HiveDetails/hiveActivities";
import {
  getFetchingMemberDetailsSelector,
  getHiveUuid,
  getMembersDetailsList,
} from "store/reducers/HiveDetails/hiveDetails";

interface Props {
  isOpen: boolean;
  onReject: any;
}

const ActivityUserPage = ({ isOpen, onReject }: Props) => {
  const dispatch = useAppDispatch();

  const hiveUuid = useAppSelector(getHiveUuid);

  const profileId = useAppSelector(getClickedProfileIdSelector);

  const isLoading = useAppSelector(getFetchingMemberDetailsSelector);

  const navigate = useNavigate();

  useEffect(() => {
    if (profileId) {
      dispatch(
        getActivityUserDetails({
          profileId: profileId,
          organizationUuid: hiveUuid,
        })
      );
    } else {
      navigate("..");
    }
  }, [dispatch, profileId]);

  const member = useAppSelector(getMembersDetailsList);

  const location = useLocation();

  return (
    <Dialog onClose={onReject} open={isOpen}>
      <IslandLayout>
        {isLoading ? (
          <div>
            <CircularProgress size={30} color="inherit" />
          </div>
        ) : (
          <div className="user_page_container">
            <div
              onClick={onReject}
              className="pointer pointer_close_user_detail"
            >
              <CloseBlackSVG />
            </div>
            <LineBreak />
            <div className="user_page_contaienr">
              {member && member.profilePhoto ? (
                <img src={member.profilePhoto} className="default_logo" />
              ) : (
                <DefaultUserBigSVG />
              )}
              <div>
                {/* <h3>
                  {member?.firstName || ""} {member?.lastName || ""}
                </h3> */}
                <h3>{member?.userName || ""}</h3>
                {member && (
                  <div className="">
                    {member.lastLoginDate ? (
                      <h5 className="">
                        <ReactTimeAgo
                          date={
                            new Date(
                              moment
                                .utc(member?.lastLoginDate)
                                .local()
                                .format("LLL")
                            )
                          }
                          locale="en-GB"
                        />
                      </h5>
                    ) : (
                      <p>-</p>
                    )}{" "}
                  </div>
                )}
              </div>
            </div>
            <div className="user_data_container">
              <div className="h_n_v-user">
                <AnalyticsCapsule>
                  <div className="headers_and_value">
                    <h5>User Name</h5>
                    <h2>{member?.userName || ""}</h2>
                  </div>
                </AnalyticsCapsule>
              </div>
              {!!member?.firstName && (
                <div className="h_n_v-user">
                  <AnalyticsCapsule>
                    <div className="headers_and_value">
                      <h5>First Name</h5>
                      <h2>{member?.firstName || ""}</h2>
                    </div>
                  </AnalyticsCapsule>
                </div>
              )}
              {!!member?.lastName && (
                <div className="h_n_v-user">
                  <AnalyticsCapsule>
                    <div className="headers_and_value">
                      <h5>Last Name</h5>
                      <h2>{member?.lastName || ""}</h2>
                    </div>
                  </AnalyticsCapsule>
                </div>
              )}
              {!!member?.emailId && (
                <div className="h_n_v-user">
                  <AnalyticsCapsule>
                    <div className="headers_and_value">
                      <h5>Email</h5>
                      <LongText
                        bold={true}
                        title={member?.emailId || ""}
                        cutoff={25}
                      ></LongText>
                    </div>
                  </AnalyticsCapsule>
                </div>
              )}
              <div className="h_n_v-user">
                <AnalyticsCapsule>
                  {member && (
                    <div className="headers_and_value">
                      <h5>Last Active</h5>
                      {member.lastLoginDate ? (
                        <h2 className="">
                          <ReactTimeAgo
                            date={
                              new Date(
                                moment
                                  .utc(member?.lastLoginDate)
                                  .local()
                                  .format("LLL")
                              )
                            }
                            locale="en-GB"
                          />
                        </h2>
                      ) : (
                        <h2>-</h2>
                      )}{" "}
                    </div>
                  )}
                </AnalyticsCapsule>
              </div>

              {!!member?.ipCountry && (
                <div className="h_n_v-user">
                  <AnalyticsCapsule>
                    <div className="headers_and_value">
                      <h5>Country</h5>
                      <h2>{member?.ipCountry || ""}</h2>
                    </div>
                  </AnalyticsCapsule>
                </div>
              )}
            </div>
            <LineBreak />
          </div>
        )}
      </IslandLayout>
    </Dialog>
  );
};

export default ActivityUserPage;
