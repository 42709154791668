import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { CampaignModel } from "api/models/Campaign/campaign";
import { StoryItemModel } from "api/models/Story/story";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import React, { useState } from "react";
import {
  getActiveChannelUuidSelector,
  getChannelListSelector,
  setActiveChannelUuid,
} from "store/reducers/ChannelDetails/channelList";

interface Props {
  uuidList: CampaignModel[];
  setSelectedUuid: any;
  selectedUuid: string;
}

const CampaignUUIDDropdown = ({
  uuidList,
  setSelectedUuid,
  selectedUuid,
}: Props) => {
  const dispatch = useAppDispatch();

  const handleChange = (e: SelectChangeEvent) => {
    setSelectedUuid(e.target.value);
  };

  return (
    <div className="">
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">Uploading to</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={selectedUuid}
          label="Uploading to"
          onChange={handleChange}
        >
          {uuidList.map((object, idx) => {
            if (object.campaignUuid) {
              return (
                <MenuItem key={idx} value={object.campaignUuid}>
                  {object.campaignName}
                </MenuItem>
              );
            }
          })}
        </Select>
      </FormControl>
    </div>
  );
};

export default CampaignUUIDDropdown;
