import {
  CurrentPlanItem,
  PlanModel,
} from "api/models/Subscriptions/subscriptions";
import LineBreak from "components/LineBreak";
import { CheckmarkSVGsm, GreenTickSVG } from "components/SVG/SVG";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import moment from "moment";
import React from "react";
import { useNavigate } from "react-router-dom";
import {
  cancelSubscription,
  getPlanDetails,
} from "store/async-actions/Subscriptions/subscriptions";
import { getHiveSelector } from "store/reducers/HiveDetails/hiveDetails";
import {
  getAllPlansSelector,
  getCurrentPlanSelector,
} from "store/reducers/Subscriptions/subscriptions";

const AllPlansView = () => {
  const allPlans = useAppSelector(getAllPlansSelector);

  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const hiveDetails = useAppSelector(getHiveSelector);

  const handlePurchase = (plan: PlanModel) => {
    dispatch(
      getPlanDetails({
        planUuid: plan.planUuid,
      })
    );
    navigate("purchase");
  };

  const currentPlan = useAppSelector(getCurrentPlanSelector);

  const handleCancel = () => {
    navigate("/plans/cancel");
  };

  return (
    <>
      {allPlans.map((data, idx) => {
        return (
          <div
            onClick={() => {
              currentPlan?.planUuid !== data.planUuid && handlePurchase(data);
            }}
            key={idx}
            className="plan_wrapper"
          >
            <div>
              <div className="plan_item_header_texts">
                <h2 className="">{data.planName}</h2>
                {data.monthlyAmount > 0 && (
                  <p className="text-lg fw-1 ">
                    <b className="text-xl">${data.monthlyAmount}</b>/mo
                  </p>
                )}
              </div>
              <LineBreak />
              {data.planDetails.map((detail, idxx) => {
                return (
                  <div key={idxx} className="plan_content_item_wrapper">
                    <GreenTickSVG />
                    <p className="text-sm">
                      {" "}
                      {<span className="fw-2">{detail.volume}</span>}{" "}
                      {!!detail.unit && (
                        <span className="fw-2">{detail.unit}</span>
                      )}{" "}
                      {detail.item}
                      {/* {!!detail.description && ` (${detail.description})`} */}
                    </p>
                  </div>
                );
              })}
            </div>
            {!!currentPlan && (
              <div className="my-2">
                <div className="text-center text-sm">
                  {!!currentPlan &&
                    currentPlan.changeSubscriptionPlanId === data.planId && (
                      <div>
                        <b>Switching on: </b>
                        {moment
                          .utc(currentPlan.planEndDate)
                          .local()
                          .format("ll")}
                        <LineBreak />
                      </div>
                    )}
                </div>
                {currentPlan?.planUuid === data.planUuid ? (
                  <>
                    <div
                      onClick={handleCancel}
                      className="link flex justify-center items-center"
                    >
                      Cancel
                    </div>
                    <LineBreak />
                    <div className="secondaryBtn">Current Plan</div>
                  </>
                ) : currentPlan?.monthlyAmount < data.monthlyAmount ? (
                  <div className="primaryBtn">Upgrade</div>
                ) : (
                  <div className="secondaryBtn">Downgrade</div>
                )}
              </div>
            )}
          </div>
        );
      })}
    </>
  );
};
export default AllPlansView;
