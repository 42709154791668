import { configureStore } from "@reduxjs/toolkit";
import mainMenu from "./reducers/HiveDetails/mainMenu";
import navbar from "./reducers/HiveDetails/navbar";
import channels from "./reducers/ChannelDetails/channelList";
import hive from "./reducers/HiveDetails/hiveDetails";
import child from "./reducers/ChildComponents/childComponents";
import channelDetails from "./reducers/ChannelDetails/channelDetails";
import videos from "./reducers/Videos/videos";
import videoList from "./reducers/Videos/videoList";
import analytics from "./reducers/Analytics/analytics";
import feed from "./reducers/Feed/feed";
import profile from "./reducers/Profile/profile";
import hamburger from "./reducers/Hamburger/hamburger";
import activities from "./reducers/HiveDetails/hiveActivities";
import createChannel from "./reducers/CreateChannel/createChannel";
import chatrooms from "./reducers/Chat/chatrooms";
import chat from "./reducers/Chat/chat";
import content from "./reducers/Content/content";
import hiveSettings from "./reducers/HiveDetails/hiveSettings";
import payments from "./reducers/ChannelDetails/payments";
import ai from "./reducers/Ai/ai";
import story from "./reducers/Story/story";
import contentMgr from "./reducers/ContentMgr/contentMgr";
import notifications from "./reducers/Notifications/notifications";
import events from "./reducers/HiveDetails/events";
import subscriptions from "./reducers/Subscriptions/subscriptions";
import campaign from "./reducers/Campaign/campaign";
import forms from "./reducers/Forms/forms";
import maps from "./reducers/Maps/maps";

export const store = configureStore({
  reducer: {
    mainMenu,
    navbar,
    channels,
    hive,
    child,
    channelDetails,
    videos,
    videoList,
    analytics,
    feed,
    profile,
    hamburger,
    activities,
    createChannel,
    chatrooms,
    chat,
    content,
    hiveSettings,
    payments,
    ai,
    story,
    contentMgr,
    notifications,
    events,
    subscriptions,
    campaign,
    forms,
    maps,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
  devTools: process.env.REACT_APP_CUSTOM_NODE_ENV !== "production",
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
