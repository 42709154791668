import { ProductLinks } from "api/models/Hive/hiveDetails";
import LongText from "components/LongText/LongText";
import {
  DeleteStorySVG,
  EditIconSVG,
  GreenTickSVG,
  ProdImgSVG,
  ProdVidSVG,
  RedCrossSVG,
  StoryImgSVG,
  StoryVidSVG,
} from "components/SVG/SVG";
import StoryDropzone from "components/StoryDropzone";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { toastInfo } from "hooks/useToastify";
import { ChangeEvent, useState } from "react";
import {
  addHiveProduct,
  deleteHiveProduct,
  editHiveProduct,
  uploadHiveProduct,
} from "store/async-actions/Hive/hivePage";
import {
  getCurrentProductSelector,
  getHiveProductsSelector,
  getHiveUuid,
  setCurrentProduct,
} from "store/reducers/HiveDetails/hiveDetails";
import ProductNameModal from "components/ProductNameModel";
import ProductDropzone from "components/ProductDropzone/ProductDropzone";
import {
  getContentTypeSelector,
  setVideoFile,
} from "store/reducers/Videos/videos";

const HiveProducts = () => {
  const dispatch = useAppDispatch();

  const hiveProducts = useAppSelector(getHiveProductsSelector);

  const [hiveProductsList, setHiveProducts] = useState(hiveProducts);

  const handleCopy = (text: string) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        if (!!text) {
          toastInfo("Copied link to clipboard");
        } else {
          toastInfo(
            "This story isnt available at the moment, please try another one"
          );
        }
      })
      .catch((error) => {
        console.error("Error copying text: ", error);
      });
  };

  const hiveUuid = useAppSelector(getHiveUuid);

  const handleDelete = (product: ProductLinks) => {
    dispatch(
      deleteHiveProduct({
        productId: product.id,
        organizationUuid: hiveUuid,
      })
    );
  };

  const [openDrop, setOpenDrop] = useState(false);

  const [file, setFile] = useState("img");
  const [openAddName, setOpenAddName] = useState(false);

  const handleAdd = () => {
    setName("");
    setOpenAddName(true);
  };
  const handleClose = () => {
    setOpenAddName(false);
  };

  const [name, setName] = useState("");
  const [url, setUrl] = useState("");

  const handleConfirm = () => {
    dispatch(
      addHiveProduct({
        productName: name,
        productLink: null,
        organizationUuid: hiveUuid,
        productDesc: null,
        productUrl: null,
      })
    );
    handleClose();
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };

  const handleUrlChange = (e: ChangeEvent<HTMLInputElement>) => {
    setUrl(e.target.value);
  };

  const handleImageUpload = () => {
    dispatch(setVideoFile(null));
    setFile("img");
    setOpenDrop(true);
    setEditClicked(-1);
  };

  const handleVideoUpload = () => {
    dispatch(setVideoFile(null));
    setFile("video");
    setOpenDrop(true);
    setEditClicked(-1);
  };

  const currentProd = useAppSelector(getCurrentProductSelector);

  const contentType = useAppSelector(getContentTypeSelector);

  const uploadProduct = () => {
    currentProd &&
      dispatch(
        uploadHiveProduct({
          productId: currentProd?.id,
          organizationUuid: hiveUuid,
          contentType: contentType,
        })
      );
  };

  const setProductDetails = (product: ProductLinks) => {
    dispatch(setCurrentProduct(product));
  };

  const [editClicked, setEditClicked] = useState(-1);
  const [urlEditClicked, setUrlEditClicked] = useState(-1);

  const handleEdit = (e: number) => {
    handleUrlEdit(e);
    if (editClicked === e) {
      setEditClicked(-1);
    } else {
      setName(hiveProducts[e].product_name);
      setEditClicked(e);
    }
  };

  const handleUrlEdit = (e: number) => {
    if (urlEditClicked === e) {
      setUrlEditClicked(-1);
    } else {
      setUrl(hiveProducts[e].product_url || "");
      setUrlEditClicked(e);
    }
  };

  const handleEditName = () => {
    dispatch(
      editHiveProduct({
        productId: hiveProducts[editClicked].id,
        productName: name,
        organizationUuid: hiveUuid,
        productUrl: url,
        productDesc: null,
        productLink: null,
      })
    );
    setEditClicked(-1);
  };
  const handleEditUrl = () => {
    dispatch(
      editHiveProduct({
        productId: hiveProducts[editClicked].id,
        productName: name,
        organizationUuid: hiveUuid,
        productUrl: url,
        productDesc: null,
        productLink: null,
      })
    );
    setUrlEditClicked(-1);
  };

  const handleEditClose = () => {
    setEditClicked(-1);
  };

  const handleUrlEditClose = () => {
    setUrlEditClicked(-1);
  };

  return (
    <div>
      {openAddName && (
        <tr className="hive_products_table_item">
          <td className="hive_products_list_tem">
            <div className="link_edit_save_wrapper_product social_link_edit_input_wrapper_underline">
              <input
                name="userName"
                value={name}
                onChange={handleChange}
                className="social_link_edit_input"
                placeholder="Enter Product Name"
              />
              <div className="sle_confirm_btns">
                <div className="pointer" onClick={handleConfirm}>
                  <GreenTickSVG />
                </div>
                <div className="pointer" onClick={handleClose}>
                  <RedCrossSVG />
                </div>
              </div>
            </div>
          </td>
        </tr>
      )}
      {!!hiveProducts && hiveProducts.length > 0 ? (
        <div>
          <table className="hive_products_table">
            <thead className="hive_products_table_header">
              <tr className="hive_products_table_header_item">
                <th className="left-align hive_products_list_tem">
                  Product Name
                </th>
                <th className="center-align hive_products_list_tem">Link</th>
                <th className="center-align hive_products_list_tem">URL</th>
                <th className="hive_products_list_tem">Action(s)</th>{" "}
                {/* For the delete button */}
              </tr>
            </thead>
            <tbody className="hive_products_table_header">
              {hiveProducts.map((data, idx) => (
                <tr
                  onClick={() => setProductDetails(data)}
                  className="hive_products_table_item"
                  key={idx}
                >
                  <td className="left-align hive_products_list_tem">
                    {editClicked === idx ? (
                      <div className="link_edit_save_wrapper_product social_link_edit_input_wrapper_underline">
                        <input
                          name="userName"
                          value={name}
                          onChange={handleChange}
                          className="social_link_edit_input"
                          placeholder="Enter Product Name"
                        />
                        <div className="sle_confirm_btns">
                          <div className="pointer" onClick={handleEditName}>
                            <GreenTickSVG />
                          </div>
                          <div className="pointer" onClick={handleEditClose}>
                            <RedCrossSVG />
                          </div>
                        </div>
                      </div>
                    ) : (
                      <>
                        {data.product_name.length > 40
                          ? data.product_name.slice(0, 40) + "..."
                          : data.product_name}
                      </>
                    )}
                  </td>
                  {!!data.link && editClicked !== idx ? (
                    <td className="center-align hive_products_list_tem">
                      <p onClick={() => handleCopy(data.link)} className="link">
                        Copy Link
                      </p>
                    </td>
                  ) : (
                    <td className="center-align-media">
                      <div className="product_add_media">
                        {" "}
                        <div
                          className="pointer story_action_item"
                          onClick={handleImageUpload}
                        >
                          <div className="action_item_icon">
                            <ProdImgSVG />
                          </div>
                        </div>
                        <div
                          onClick={handleVideoUpload}
                          className="pointer story_action_item"
                        >
                          <div className="action_item_icon">
                            <ProdVidSVG />
                          </div>
                        </div>
                      </div>
                    </td>
                  )}
                  <td className="center-align hive_products_list_tem">
                    {!!data.product_url ? (
                      <>
                        {urlEditClicked === idx ? (
                          <div className="link_edit_save_wrapper_product social_link_edit_input_wrapper_underline">
                            <input
                              name="productUrl"
                              value={url}
                              onChange={handleUrlChange}
                              className="social_link_edit_input"
                              placeholder="Enter Product Url"
                            />
                            <div className="sle_confirm_btns">
                              <div className="pointer" onClick={handleEditUrl}>
                                <GreenTickSVG />
                              </div>
                              <div
                                className="pointer"
                                onClick={handleUrlEditClose}
                              >
                                <RedCrossSVG />
                              </div>
                            </div>
                          </div>
                        ) : (
                          <a
                            href={data.product_url || ""}
                            className="link"
                            target="_blank"
                          >
                            {data.product_url.length > 40
                              ? data.product_url.slice(0, 40) + "..."
                              : data.product_url}
                          </a>
                        )}
                      </>
                    ) : (
                      <>-</>
                    )}
                  </td>
                  <td className="center-align hive_products-delete hive_products_list_tem pointer">
                    <div className="pointer" onClick={() => handleDelete(data)}>
                      <DeleteStorySVG />
                    </div>
                    <div onClick={() => handleEdit(idx)} className="pointer">
                      <EditIconSVG />
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <h4
            onClick={handleAdd}
            className="pointer hive_products_table_header add_product_container"
          >
            Add Product
          </h4>
          {openDrop && (
            <ProductDropzone
              uploadProduct={uploadProduct}
              openDrop={openDrop}
              setOpenDrop={setOpenDrop}
              file={file}
            />
          )}
        </div>
      ) : (
        <div>
          <h4
            onClick={handleAdd}
            className="pointer hive_products_table_header add_product_container"
          >
            Add Product
          </h4>
        </div>
      )}
    </div>
  );
};

export default HiveProducts;
