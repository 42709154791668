import BaseApi from "api/base";
import { AxiosInstance, AxiosPromise } from "axios";

import { BaseResponse } from "../../models/base";
import {
  AttendeeFormDetails,
  AttendeeFormDetailsReq,
  EventPresignedResponse,
  EventPromoModel,
  EventPromoReq,
  EventUserItem,
  EventsContentUploadModel,
  EventsEditRequestModel,
  EventsListModel,
  EventsModel,
  EventsRequestModel,
  GalleryItem,
} from "api/models/Hive/events";
import {
  PresignedData,
  ResourcePreSignedModel,
} from "api/models/Hive/introVideo";
import {
  RazorPayEventOrder,
  RazorPaySubOrder,
} from "api/models/Channel/cartModel";
import { RazorPaySubVerifyReq } from "api/models/Subscriptions/subscriptions";

export class EventsApi extends BaseApi {
  createEvent(
    data: EventsRequestModel
  ): AxiosPromise<BaseResponse<EventsModel>> {
    return this.request({
      url: `${this.url}/create`,
      method: "POST",
      data,
    });
  }

  editEvent(
    data: EventsEditRequestModel
  ): AxiosPromise<BaseResponse<EventsModel>> {
    return this.request({
      url: `${this.url}/edit`,
      method: "POST",
      data,
    });
  }

  uploadEventVideo(
    data: EventsContentUploadModel
  ): AxiosPromise<BaseResponse<EventPresignedResponse>> {
    return this.request({
      url: `${this.url}/video/pre/signed`,
      method: "POST",
      data,
    });
  }

  getEventsList(data: {
    organizationUuid: string;
  }): AxiosPromise<BaseResponse<EventsListModel[]>> {
    return this.request({
      url: `${this.url}/get/list`,
      method: "POST",
      data,
    });
  }

  getEventDetails(data: {
    eventIdentifier: string;
  }): AxiosPromise<BaseResponse<EventsListModel[]>> {
    return this.request({
      url: `/webApp${
        localStorage.getItem("isLoggedIn") === "yes" ? "" : "/noAuth"
      }/events/get`,
      method: "POST",
      data,
    });
  }

  registerEvent(data: {
    eventUuid: string;
    registerType: number;
    formDetails?: AttendeeFormDetailsReq[];
  }): AxiosPromise<BaseResponse<boolean>> {
    return this.request({
      url: `${this.url}/register`,
      method: "POST",
      data,
    });
  }

  attendeeQrRegistration(data: {
    eventUuid: string;
    organizationUuid: string;
    profileId: string;
  }): AxiosPromise<BaseResponse<boolean>> {
    return this.request({
      url: `${this.url}/qr/attendee/details`,
      method: "POST",
      data,
    });
  }

  markAttended(data: {
    eventUuid: string;
    organizationUuid: string;
    profileId: string;
  }): AxiosPromise<BaseResponse<boolean>> {
    return this.request({
      url: `${this.url}/mark/attended`,
      method: "POST",
      data,
    });
  }

  deleteEvent(data: {
    eventUuid: string;
  }): AxiosPromise<BaseResponse<boolean>> {
    return this.request({
      url: `${this.url}/delete`,
      method: "POST",
      data,
    });
  }

  getEventOrgOwner(data: {
    organizationUuid: string;
  }): AxiosPromise<BaseResponse<EventUserItem>> {
    return this.request({
      url: `/webApp${
        localStorage.getItem("isLoggedIn") === "yes" ? "" : "/noAuth"
      }/events/org/owner`,
      method: "POST",
      data,
    });
  }

  getEventAttendeesList(data: {
    eventIdentifier: string;
  }): AxiosPromise<BaseResponse<EventUserItem[]>> {
    return this.request({
      url: `/webApp${
        localStorage.getItem("isLoggedIn") === "yes" ? "" : "/noAuth"
      }/events/attendees/get/list`,
      method: "POST",
      data,
    });
  }

  getEventPeopleList(data: {
    eventIdentifier: string;
    registerType: number;
    searchInput?: string;
  }): AxiosPromise<BaseResponse<EventUserItem[]>> {
    return this.request({
      url: `${this.url}/admin/attendees/get/list`,
      method: "POST",
      data,
    });
  }

  uploadGalleryEvent(data: {
    eventUuid: string;
    galleryUrl: string;
    eventThumbnail: string;
  }): AxiosPromise<BaseResponse<boolean>> {
    return this.request({
      url: `${this.url}/upload/gallery`,
      method: "POST",
      data,
    });
  }

  getGalleryEvent(data: {
    eventUuid: string;
  }): AxiosPromise<BaseResponse<GalleryItem[]>> {
    return this.request({
      url: `${this.url}/get/gallery`,
      method: "POST",
      data,
    });
  }

  removeGalleryEvent(data: {
    eventUuid: string;
    galleryId: number;
  }): AxiosPromise<BaseResponse<boolean>> {
    return this.request({
      url: `${this.url}/remove/gallery`,
      method: "POST",
      data,
    });
  }

  verifyEventPromo(
    data: EventPromoReq
  ): AxiosPromise<BaseResponse<EventPromoModel>> {
    return this.request({
      url: `${this.url}/verify/promo`,
      method: "POST",
      data,
    });
  }

  stripeChargeEvent(data: {
    eventUuid: string;
    token: string;
    formDetails: AttendeeFormDetailsReq[] | null;
    promoCode?: string;
  }): AxiosPromise<BaseResponse<boolean>> {
    return this.request({
      url: `/payment/stripe/events/charge`,
      method: "POST",
      data,
    });
  }

  razorPayChargeEvent(data: {
    eventUuid: string;
    promoCode: string;
  }): AxiosPromise<BaseResponse<RazorPayEventOrder>> {
    return this.request({
      url: `payment/razorpay/events/order/create`,
      method: "POST",
      data,
    });
  }

  razorPayVerifyEvent(data: {
    orderId: string;
    paymentId: string;
    signature: string;
    formDetails: AttendeeFormDetailsReq[] | null;
  }): AxiosPromise<BaseResponse<boolean>> {
    return this.request({
      url: `payment/razorpay/events/verify`,
      method: "POST",
      data,
    });
  }
}

export default function eventsApi(request: AxiosInstance) {
  return new EventsApi({
    request,
    url: `/webApp/events`,
  });
}
