import React, { useEffect } from "react";
import {
  getFromDateSelector,
  getToDateSelector,
  setAnalyticsTab,
} from "store/reducers/Analytics/analytics";
import MetricsContainer from "./MetricsContainer";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import {
  getHiveRevenue,
  getHiveRevenueGraph,
  getPurchaseChannelList,
} from "store/async-actions/Analytics/analyticsActions";
import { getHiveSelector } from "store/reducers/HiveDetails/hiveDetails";
import ChannelMetricsContainer from "./ChannelMetricsContainer";
import LineBreak from "components/LineBreak/LineBreak";
import RevenueGraph from "./RevenueGraph";
import DateSelector from "components/DateSelector/DateSelector";
import ChannelListTable from "./ChannelListTable";

const AnalyticsChannels = () => {
  const dispatch = useAppDispatch();

  const hiveDetails = useAppSelector(getHiveSelector);
  const fromDate = useAppSelector(getFromDateSelector);
  const toDate = useAppSelector(getToDateSelector);

  useEffect(() => {
    dispatch(setAnalyticsTab(1));

    if (hiveDetails) {
      dispatch(
        getHiveRevenue({
          organizationId: hiveDetails.communityId,
        })
      );
      dispatch(
        getHiveRevenueGraph({
          organizationId: hiveDetails.communityId,
          fromDate: fromDate,
          toDate: toDate,
        })
      );
      dispatch(
        getPurchaseChannelList({
          organizationId: hiveDetails.communityId,
        })
      );
    }

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, hiveDetails, fromDate, toDate]);

  return (
    <>
      {" "}
      <DateSelector />
      <ChannelMetricsContainer />
      <LineBreak />
      <RevenueGraph />
      <LineBreak />
      <ChannelListTable />
    </>
  );
};

export default AnalyticsChannels;
