import React, { useEffect } from "react";

import IslandLayout from "components/IslandLayout/IslandLayout";
import EventsCalendar from "./components/EventsCalendar";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { getChildSelector } from "store/reducers/ChildComponents/childComponents";
import Buttons from "components/Buttons/Buttons";
import {
  getHiveUuid,
  launchLogin,
} from "store/reducers/HiveDetails/hiveDetails";
import { getEventsList } from "store/async-actions/Hive/eventsActions";
import { setCurrentEvent } from "store/reducers/HiveDetails/events";

const Events = () => {
  const childComponents = useAppSelector(getChildSelector);
  const dispatch = useAppDispatch();

  const hiveUuid = useAppSelector(getHiveUuid);

  useEffect(() => {
    dispatch(
      getEventsList({
        organizationUuid: hiveUuid,
      })
    );
  }, [dispatch, hiveUuid]);

  const handleClick = () => {
    dispatch(setCurrentEvent(undefined));
  };

  useEffect(() => {
    localStorage.setItem("path", "/events");
    if (localStorage.getItem("isLoggedIn") !== "yes") launchLogin();
  }, [dispatch]);

  return (
    <IslandLayout>
      <div className="events_main_container">
        <div className="events_header">
          <h2>Your Events</h2>
          <div onClick={handleClick}>
            {!!childComponents.length &&
              childComponents.map((button, idx) => (
                <Buttons button={button}>{button.componentName}</Buttons>
              ))}
          </div>
        </div>
        <EventsCalendar />
      </div>
    </IslandLayout>
  );
};

export default Events;
