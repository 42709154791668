import { useEffect } from "react";

import { useAppDispatch, useAppShallowSelector } from "hooks/redux";
import { getChatRoomUsersMentions } from "store/async-actions/Chat/chat";
import { getMentionsUsersListSelector } from "store/reducers/Chat/chat";
import { UsersListForMentioning } from "store/reducers/Chat/chat";

type ReturnType = {
  usersList: UsersListForMentioning[];
};

export const useMentioningUsersList = (chatRoomId: string): ReturnType => {
  const dispatch = useAppDispatch();

  const usersList = useAppShallowSelector(getMentionsUsersListSelector);

  useEffect(() => {
    if (chatRoomId) {
      dispatch(getChatRoomUsersMentions({ chatRoomId }));
    }
  }, [chatRoomId, dispatch]);

  return {
    usersList,
  };
};
