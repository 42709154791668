import { CircularProgress } from "@mui/material";
import BackButton from "components/BackButton";
import ChannelTabs from "components/ChannelTabs";
import ImageDropzone from "components/ImageDropzone";
import IslandLayout from "components/IslandLayout";
import LineBreak from "components/LineBreak";
import PageLayout from "components/PageLayout";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { toastSuccess } from "hooks/useToastify";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  editChannelDetails,
  getChannelDetails,
} from "store/async-actions/Channels/channels";
import { getChannelList } from "store/async-actions/Hive/hivePage";
import {
  channelEditIsLoadingSelector,
  channelObjSelector,
  channelPropSelector,
  getChannelIsEditedSelector,
  setIsEdited,
} from "store/reducers/ChannelDetails/channelDetails";
import { setActiveTab } from "store/reducers/ChannelDetails/channelList";
import { getChildSelector } from "store/reducers/ChildComponents/childComponents";
import {
  getLogoFileUrlSelector,
  getMobileLogoFileUrlSelector,
  getUrlChangedSelector,
  setUrlChanged,
} from "store/reducers/CreateChannel/createChannel";
import { isMemberViewSelector } from "store/reducers/Profile/profile";

const ChannelAbout = () => {
  const channelObj = useAppSelector(channelObjSelector);
  const channelProps = useAppSelector(channelPropSelector);

  const mobileLogoUrl = useAppSelector(getMobileLogoFileUrlSelector);
  const webLogoUrl = useAppSelector(getLogoFileUrlSelector);
  const urlChanged = useAppSelector(getUrlChangedSelector);

  const [editChannelForm, setEditChannelForm] = useState({
    channelName: channelObj?.channelName || "",
    channelDescription: channelObj?.description || "",
  });

  const isEdited = useAppSelector(getChannelIsEditedSelector);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const isMemberView = useAppSelector(isMemberViewSelector);

  useEffect(() => {
    if (isEdited) {
      toastSuccess("Channel Details Saved");
      dispatch(
        getChannelList({
          hiveId: channelObj?.organization_id.id!,
          isMemberView: isMemberView,
        })
      );
      dispatch(getChannelDetails(channelObj?.channelUUID!));
      dispatch(setIsEdited(false));
    }
  }, [isEdited, dispatch]);

  useEffect(() => {
    dispatch(setActiveTab(0));
    dispatch(setUrlChanged(false));
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChannelForm = (e: React.ChangeEvent<any>, limit: number) => {
    setEditChannelForm((prevForm) => ({
      ...prevForm,
      [e.target.name]: e.target.value.slice(0, limit),
    }));
  };

  const handleSave = () => {
    dispatch(
      editChannelDetails({
        channelName: editChannelForm.channelName!,
        category: "CHANNEL",
        channelLogo: mobileLogoUrl,
        channelWebLogo: webLogoUrl,
        channelTier: "Invite",
        uploadFlag: false,
        amount: 0,
        description: editChannelForm.channelDescription!,
        maxVideoLength: 0,
        channelId: channelObj?.id!,
        channelNameFlag: true,
        categoryFlag: false,
        channelTierFlag: false,
        amountFlag: false,
        descriptionFlag: true,
        maxVideoLengthFlag: false,
        channelLogoFlag: urlChanged,
        channelWebLogoFlag: urlChanged,
        orderByDesc: false,
        adsRequired: false,
        ctaName: "",
        ctaNameFlag: false,
      })
    );
  };

  const removeChannelLogo = () => {
    dispatch(
      editChannelDetails({
        channelName: editChannelForm.channelName!,
        category: "CHANNEL",
        channelLogo: "",
        channelWebLogo: "",
        channelTier: "Invite",
        uploadFlag: false,
        amount: 0,
        description: editChannelForm.channelDescription!,
        maxVideoLength: 0,
        channelId: channelObj?.id!,
        channelNameFlag: false,
        categoryFlag: false,
        channelTierFlag: false,
        amountFlag: false,
        descriptionFlag: false,
        maxVideoLengthFlag: false,
        channelLogoFlag: true,
        channelWebLogoFlag: true,
        orderByDesc: false,
        adsRequired: false,
        ctaName: "",
        ctaNameFlag: false,
      })
    );
  };

  const isLoading = useAppSelector(channelEditIsLoadingSelector);

  return (
    <PageLayout sideMenu={true}>
      <IslandLayout>
        <div className="ce_about_wrapper">
          <BackButton />
          <ChannelTabs />
          {channelObj && (
            <div className="about_container">
              <LineBreak />
              <div className="w_full">
                <div className="title_and_limit">
                  <h4>Channel Name*</h4>
                  {editChannelForm.channelName && (
                    <div className="character_limit text-sm">
                      {editChannelForm.channelName.length}/18
                    </div>
                  )}
                </div>
                <LineBreak />
                {channelProps?.defaultChannel ? (
                  <input
                    readOnly
                    name="channelName"
                    value={editChannelForm.channelName}
                    onChange={(e) => handleChannelForm(e, 18)}
                    className="input_border text_padding input_width_full"
                    placeholder="Eg: Introduction"
                  />
                ) : (
                  <input
                    name="channelName"
                    value={editChannelForm.channelName}
                    onChange={(e) => handleChannelForm(e, 18)}
                    className="input_border text_padding input_width_full"
                    placeholder="Eg: Introduction"
                  />
                )}
              </div>
              <div>
                <div className="title_and_limit">
                  <h4>Channel Description</h4>
                  {editChannelForm.channelDescription && (
                    <div className="character_limit text-sm">
                      {editChannelForm.channelDescription.length}/280
                    </div>
                  )}
                </div>
                <LineBreak />
                <textarea
                  name="channelDescription"
                  value={editChannelForm.channelDescription.slice()}
                  onChange={(e) => handleChannelForm(e, 280)}
                  className="input_border text_padding input_width_full who_are_we_section_text"
                  placeholder="A few words about the channel"
                />
              </div>
              <div className="title_and_limit">
                <h4>Channel Logo</h4>
                <p onClick={removeChannelLogo} className="link text-sm">
                  Remove Image
                </p>
              </div>
              <IslandLayout>
                <ImageDropzone urlSent={channelProps?.webLogo} file="channel" />
              </IslandLayout>
            </div>
          )}
          <>
            {isLoading ? (
              <div className="nextBtn primaryBtn">
                <CircularProgress size={20} color="inherit" />
              </div>
            ) : (
              <>
                {editChannelForm.channelName &&
                editChannelForm.channelName.length > 3 ? (
                  <div onClick={handleSave} className="nextBtn primaryBtn">
                    Save
                  </div>
                ) : (
                  <div className="disabledBtn nextBtn">Save</div>
                )}
              </>
            )}
          </>
        </div>
      </IslandLayout>
    </PageLayout>
  );
};

export default ChannelAbout;
