import IslandLayout from "components/IslandLayout";
import PageLayout from "components/PageLayout";
import React from "react";
import { NavLink, Outlet } from "react-router-dom";
import ContentMgrTabs from "./components/ContentMgrTabs";
import { GoogleOAuthProvider } from "@react-oauth/google";
import LineBreak from "components/LineBreak/LineBreak";

const ContentMgrContainer = () => {
  return (
    <GoogleOAuthProvider clientId="694412510666-u7onv1nnc1lki6bscl2tt1k25e2bdoq4.apps.googleusercontent.com">
      <PageLayout sideMenu={true} rightMenu={false}>
        <IslandLayout>
          <div className="content_mgr_header">
            <h1>cBee</h1>
            <p>
              I am ContentBee, your Generative AI powered Digital Content
              Marketing Manager.
            </p>
            <LineBreak />
            <h3>
              Veehive studio has been renamed as cBee (Content Bee) and has been
              moved to{" "}
              <NavLink
                to={`https://cbee.ai/protected/dashboard/?login=${localStorage.getItem(
                  "@jwtToken"
                )}`}
                className="link"
              >
                cBee.ai
              </NavLink>
            </h3>

            <LineBreak />
          </div>
          {/* <div className="contentmgr_container">
            <ContentMgrTabs />
          </div> */}
          {/* <Outlet /> */}
        </IslandLayout>
      </PageLayout>
    </GoogleOAuthProvider>
  );
};

export default ContentMgrContainer;
