import HamburgerMenu from "components/Hamburger/HamburgerMenu";
import MainMenu from "components/MainMenu";
import { useAppSelector } from "hooks/redux";
import ChatRoomDetail from "pages/Chat/ChatRoomDetail";
import { ToastContainer } from "react-toastify";
import ChatRoomsPage from "pages/Chat/ChatRoomsPage";
import React, { useEffect, useRef } from "react";
import { getBurgerOpenSelector } from "store/reducers/Hamburger/hamburger";
import HiveConfig from "components/HiveConfig";
import { useChatMessages } from "pages/Chat/ChatRoomPage/useChatMessages";
import { Params, useParams } from "react-router-dom";
import DefaultChatRoom from "components/DefaultChatRoom";
import MobileLayout from "./MobileLayout";

interface Props {
  sideMenu: boolean;
  rightMenu?: boolean;
}

const PageLayout: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  sideMenu,
  rightMenu,
}) => {
  const isOpen = useAppSelector(getBurgerOpenSelector);

  const { roomId = "" } = useParams<Params<"roomId">>();

  const { chatList } = useChatMessages(roomId);

  const chatContainerRef = useRef<HTMLDivElement>(null);

  const scrollChatToBottom = () => {
    const container = chatContainerRef.current;
    if (container) {
      container.scrollTop = container.scrollHeight;
    }
  };

  useEffect(() => {
    // Scroll to the bottom of the chat container on initial load and when chatList changes
    scrollChatToBottom();
  }, [chatList]);

  return (
    <div className={`${sideMenu && "page_container"}`}>
      {/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      ) ? (
        <MobileLayout sideMenu={sideMenu}>{children}</MobileLayout>
      ) : (
        <>
          {sideMenu && (
            <div className="main_menu_wrapper">
              <MainMenu />
            </div>
          )}
          {isOpen ? (
            <HamburgerMenu />
          ) : (
            <div
              className={`${
                rightMenu ? "body_container" : "only_body_container"
              }`}
            >
              {sideMenu ? (
                children
              ) : (
                <div className="chats_body_container">
                  <div className="col-span-2">
                    <ChatRoomsPage />
                  </div>
                  <div className="col-span-3" ref={chatContainerRef}>
                    {roomId ? <ChatRoomDetail /> : <DefaultChatRoom />}
                  </div>
                </div>
              )}
            </div>
          )}
          {rightMenu && (
            <div className="main_menu_wrapper">
              <HiveConfig />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default PageLayout;
