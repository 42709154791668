import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { createTypedSelector } from "store/utils";
import { getNotifications } from "store/async-actions/Notifications/notifications";
import {
  NotificationItem,
  PaginationModel,
} from "api/models/Notifications/notifications";

const initialState: {
  isFetching: boolean;
  notifications: PaginationModel<NotificationItem> | undefined;
  pageNo: number;
} = {
  isFetching: false,
  notifications: undefined,
  pageNo: 0,
};

const notificationsSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    setNotificationPageNo: (state, action: PayloadAction<number>) => {
      state.pageNo = action.payload;
    },
  },
  extraReducers: {
    [getNotifications.pending.type]: (state) => {
      state.isFetching = true;
    },
    [getNotifications.fulfilled.type]: (
      state,
      action: PayloadAction<PaginationModel<NotificationItem>>
    ) => {
      state.notifications = action.payload;
      state.isFetching = false;
    },
    [getNotifications.rejected.type]: (state) => {
      state.isFetching = false;
    },
  },
});

export const getNotificationsSelector = createTypedSelector(
  (state) => state.notifications.notifications
);

export const getNotificationsPageNoSelector = createTypedSelector(
  (state) => state.notifications.pageNo
);

export const { setNotificationPageNo } = notificationsSlice.actions;

export default notificationsSlice.reducer;
