import BackButton from "components/BackButton/BackButton";
import LineBreak from "components/LineBreak/LineBreak";
import React, { useEffect } from "react";
import storyTemplate from "../../../assets/png/storyTemplate.png";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import {
  emptyStorySegments,
  getStoryCreatedSelector,
  getStoryDescSelector,
  getStoryIsFetchingSelector,
  getStoryItemSelector,
  getStoryTitleSelector,
  getStoryUuidSelector,
  setActionLink,
  setCurrentStoryIndex,
  setStoryCreated,
  setStoryDesc,
  setStoryTitle,
  setStoryUuid,
} from "store/reducers/Story/story";
import {
  createStory,
  editStory,
  getStoryItem,
} from "store/async-actions/Story/storyActions";
import {
  getHiveSelector,
  getHiveUuid,
} from "store/reducers/HiveDetails/hiveDetails";
import { CircularProgress } from "@mui/material";
import PageLayout from "components/PageLayout/PageLayout";
import IslandLayout from "components/IslandLayout/IslandLayout";
import ImageDropzone from "components/ImageDropzone/ImageDropzone";
import { getLogoFileUrlSelector } from "store/reducers/CreateChannel/createChannel";

const EditStory = () => {
  const title = useAppSelector(getStoryTitleSelector);
  const desc = useAppSelector(getStoryDescSelector);

  const hiveDetails = useAppSelector(getHiveSelector);

  const isFetching = useAppSelector(getStoryIsFetchingSelector);
  const isCreated = useAppSelector(getStoryCreatedSelector);

  const story = useAppSelector(getStoryItemSelector);

  const storyUuid = useAppSelector(getStoryUuidSelector);

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setStoryTitle(story?.storyTitle || ""));
    dispatch(setStoryDesc(story?.storyDescription || ""));
    dispatch(setStoryUuid(story?.storyUuid || ""));
    dispatch(setCurrentStoryIndex(0));
  }, [dispatch]);

  const thumbnail = useAppSelector(getLogoFileUrlSelector);
  const hiveUuid = useAppSelector(getHiveUuid);

  const handleEditStory = () => {
    if (story) {
      dispatch(
        editStory({
          storyUuid: story.storyUuid,
          organizationUuid: hiveUuid,
          thumbnail: thumbnail || null,
          title: title,
          description: desc,
        })
      );
      navigate("/story/create/publish", { state: { from: "/story/edit" } });
    }
  };

  const navigate = useNavigate();
  const location = useLocation();

  return (
    <PageLayout sideMenu={true}>
      <IslandLayout>
        <div className="edit_story_container">
          <LineBreak />
          <h2>Edit your story</h2>
          <LineBreak />
          <p>Make changes to your story.</p>
          <LineBreak />
          <div className="backBtn_spacing">
            <BackButton to="/story" />
          </div>
          <LineBreak />
          <div className="title_and_limit">
            <p className="tags text-sm font-bold">Story title</p>
            <div className="character_limit text-sm">{title.length}/60</div>
          </div>
          <LineBreak />
          <input
            value={title}
            onChange={(e) => {
              dispatch(setStoryTitle(e.target.value.slice(0, 60)));
            }}
            placeholder="Give a title for your story"
            className="input_border text_padding input_width_full"
          />
          <LineBreak />
          <div className="title_and_limit">
            <p className="tags text-sm font-bold">Story Description</p>
            <div className="character_limit text-sm">{desc.length}/240</div>
          </div>{" "}
          <LineBreak />
          <input
            value={desc}
            onChange={(e) => {
              dispatch(setStoryDesc(e.target.value.slice(0, 240)));
            }}
            placeholder="Give a description for your story"
            className="input_border text_padding input_width_full"
          />
          <LineBreak />
          <p className="tags text-sm font-bold">Story Thumbnail</p>
          <LineBreak />
          <ImageDropzone file="story" urlSent={story?.thumbnailUrl || ""} />
          <>
            {isFetching ? (
              <div className="story_template_nextBtn primaryBtn">
                <CircularProgress size={20} color="inherit" />
              </div>
            ) : (
              <div>
                {title.length > 0 && desc.length > 0 ? (
                  <div
                    onClick={handleEditStory}
                    className="primaryBtn story_template_nextBtn"
                  >
                    Continue
                  </div>
                ) : (
                  <div className="story_template_nextBtn disabledBtn">
                    Continue
                  </div>
                )}
              </div>
            )}
          </>
        </div>
      </IslandLayout>
    </PageLayout>
  );
};

export default EditStory;
