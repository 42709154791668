"use client";
import { LocationData } from "api/models/Maps/maps";
import LineBreak from "components/LineBreak";
import { useAppDispatch } from "hooks/redux";
import { toastSuccess } from "hooks/useToastify";
import Papa from "papaparse";
import React, {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import { updateMaps } from "store/async-actions/Maps/maps";

interface Props {
  setInsallations: Dispatch<SetStateAction<LocationData[]>>;
}

const MapsImport = ({ setInsallations }: Props) => {
  const toCaps = (text: string) => {
    return text[0].toUpperCase + text.slice(1);
  };

  const [csvErrorMessage, setCsvErrorMessage] = useState("");
  const [csvSuccessMessage, setCsvSuccessMessage] = useState("");
  const [importCount, setImportCount] = useState(0);

  useEffect(() => {
    setCsvErrorMessage("");
    setCsvSuccessMessage("");
    setImportCount(0);
  }, []);

  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useAppDispatch();

  function capitalizeWords(sentence: string): string {
    return sentence
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");
  }

  const csvImportHandler = (event: ChangeEvent<HTMLInputElement>) => {
    // Passing file data (event.target.files[0]) to parse using Papa.parse
    if (
      event.target.files &&
      event.target.files[0] &&
      event.target.files![0].type === "text/csv"
    ) {
      setIsLoading(true);
      setCsvErrorMessage("");
      Papa.parse(event.target.files![0], {
        header: true,
        skipEmptyLines: true,
        complete: function (results: any) {
          let data = results.data;
          const tempArray: LocationData[] = [];
          try {
            data.map((row: any, index: number) => {
              if (row.Latitude && row.Latitude !== "#VALUE!") {
                tempArray.push({
                  company: row.Company ? capitalizeWords(row.Company) : "",
                  longitude: row.Longitude || "",
                  latitude: row.Latitude || "",
                  group: row.Group || "",
                  title: row.Title ? capitalizeWords(row.Title) : "",
                  capacity: row.Capacity || "",
                  product: row.Product || "",
                  count: row.Count || "",
                  year: row.Year || "",
                  location: row.Location || "",
                  state: row.State || "",
                  application: row.Application || "",
                  method: row.Method || "",
                });

                setInsallations(tempArray);

                setCsvErrorMessage("");
                if (index < 1) {
                  setImportCount(importCount + data.length);
                }
              } else {
                // setCsvErrorMessage(
                //   `Make sure your .csv file has an logitude and latitude column`
                // );
              }
              setCsvSuccessMessage(
                `Added ${data.length} locations, Please proceed or add more.`
              );
            });
          } catch {
            // setCsvErrorMessage(
            //   `Make sure your .csv file has an "latitude" column header under which all location cordinates are given`
            // );
            setCsvSuccessMessage("");
          }
        },
      });
    } else {
      setCsvSuccessMessage("");
      setCsvErrorMessage(`INVALID FILE TYPE! Please upload .csv File`);
    }
    setIsLoading(false);
  };

  return (
    <div className="flex flex-col">
      {/* File Uploader */}
      <input
        id="fileElem"
        onChange={csvImportHandler}
        type="file"
        name="file"
        accept=".csv"
        className="invisible"
        // style={{ display: "block", margin: "10px auto" }}
      />
      <div className="import_csv_btn">
        <label className="primaryBtn half_width" htmlFor="fileElem">
          Import .csv file
        </label>
      </div>
      <LineBreak />
      <p>{csvErrorMessage}</p>
      <p>{csvSuccessMessage}</p>
    </div>
  );
};

export default MapsImport;
