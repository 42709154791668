import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import LineBreak from "components/LineBreak";
import PageNumbers from "components/PageNumbers";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import moment from "moment";
import { useLocation, useNavigate } from "react-router-dom";
import ReactTimeAgo from "react-time-ago";
import { getHiveMembers } from "store/async-actions/Hive/hivePage";
import { getHiveSelector } from "store/reducers/HiveDetails/hiveDetails";
import {
  hiveMembersSelector,
  totalMembersPagesSelector,
  getHiveMembersPageNo,
  setPageNo,
} from "store/reducers/HiveDetails/hiveSettings";
import { useEffect } from "react";
import {
  getActiveChannelUuidSelector,
  setActiveTab,
} from "store/reducers/ChannelDetails/channelList";
import ChannelTabs from "components/ChannelTabs";
import BackButton from "components/BackButton";
import PageLayout from "components/PageLayout";
import IslandLayout from "components/IslandLayout";
import MembersKebab from "pages/Hive/Settings/components/MembersKebab";
import { getActiveChannelIdSelector } from "store/reducers/ChannelDetails/channelList";
import { MembersItemModel } from "api/models/Hive/hiveMembers";
import { setClickedProfileId } from "store/reducers/HiveDetails/hiveActivities";

const MembersList = () => {
  const dispatch = useAppDispatch();

  const hiveMembers = useAppSelector(hiveMembersSelector);
  const hiveDetails = useAppSelector(getHiveSelector);
  const totalPages = useAppSelector(totalMembersPagesSelector);
  const currentPageNo = useAppSelector(getHiveMembersPageNo);

  const channelUuid = useAppSelector(getActiveChannelUuidSelector);
  const channelId = useAppSelector(getActiveChannelIdSelector);

  const handleNextPage = (pageNo: number) => {
    dispatch(
      getHiveMembers({
        hiveId: hiveDetails?.communityId!,
        pageNo: pageNo,
        contentLimit: 15,
        channelUuid: channelUuid!,
      })
    );
    dispatch(setPageNo(pageNo));
  };

  const navigate = useNavigate();

  useEffect(() => {
    dispatch(setPageNo(0));
    hiveDetails &&
      channelUuid &&
      dispatch(
        getHiveMembers({
          hiveId: hiveDetails?.communityId,
          pageNo: currentPageNo,
          contentLimit: 15,
          channelUuid: channelUuid!,
        })
      );
  }, [hiveDetails, dispatch, channelUuid]);

  const handleInvite = () => {
    navigate("invite");
  };

  useEffect(() => {
    dispatch(setActiveTab(1));

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const path = useLocation();

  const handleUserClick = (user: MembersItemModel) => {
    dispatch(setClickedProfileId(user.profileId));
    navigate(`/user/${user.profileId}`, { state: { from: path.pathname } });
  };

  return (
    <PageLayout sideMenu={true}>
      <IslandLayout>
        <div className="members_list_container">
          <BackButton />
          <ChannelTabs />
          {/* <LineBreak /> */}
          <div className="hive_members_head">
            <h3>Members</h3>
            <div className="flex">
              <div
                onClick={handleInvite}
                className="secondaryBtn button_with_logo"
              >
                <img
                  src="https://imagesdev.veehive.ai/webApp/webApp_upload.png"
                  className="smallLogo"
                />
                <p>Invite</p>
              </div>
            </div>
          </div>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="members table">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <p className="bold_heading_table">Username</p>
                  </TableCell>
                  <TableCell align="left">
                    <p className="bold_heading_table">Email</p>
                  </TableCell>
                  <TableCell align="left">
                    <p className="bold_heading_table">Type</p>
                  </TableCell>
                  <TableCell align="left">
                    <p className="bold_heading_table">Joined</p>
                  </TableCell>
                  <TableCell align="left">
                    <p className="bold_heading_table">Action(s)</p>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {!!hiveMembers &&
                  hiveMembers?.map((member, idx) => (
                    <TableRow
                      className="table_cell_hover"
                      key={idx}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell
                        onClick={() => handleUserClick(member)}
                        component="th"
                        scope="row"
                      >
                        {member.userName || "-"}
                      </TableCell>
                      <TableCell
                        onClick={() => handleUserClick(member)}
                        align="left"
                      >
                        {(member.userContactDetails &&
                        member.userContactDetails.length > 20
                          ? member.userContactDetails.slice(0, 20) + "..."
                          : member.userContactDetails) || "-"}
                      </TableCell>
                      <TableCell
                        onClick={() => handleUserClick(member)}
                        align="left"
                      >
                        {member.isSuperAdmin
                          ? "Owner"
                          : member.isModerator
                          ? "Admin"
                          : "User"}
                      </TableCell>
                      <TableCell
                        onClick={() => handleUserClick(member)}
                        align="left"
                      >
                        {" "}
                        {member.dateJoined !== "not joined" ? (
                          <ReactTimeAgo
                            date={
                              new Date(
                                moment
                                  .utc(
                                    member.dateJoined
                                      .replace(/\.\d+/g, "")
                                      .replace(" ", "T")
                                      .replace(/[-:]/g, "")
                                  )
                                  .local()
                                  .format("LLL")
                              )
                            }
                            locale="en-GB"
                          />
                        ) : (
                          "Not Joined"
                        )}
                      </TableCell>
                      <TableCell>
                        <MembersKebab
                          userName={member.userName}
                          userId={member.userId}
                          channelId={channelId}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <LineBreak />

          <PageNumbers
            handleChange={handleNextPage}
            totalPages={totalPages}
            initialPage={currentPageNo}
          />
        </div>
      </IslandLayout>
    </PageLayout>
  );
};

export default MembersList;
