import Buttons from "components/Buttons/Buttons";
import IslandLayout from "components/IslandLayout/IslandLayout";
import LineBreak from "components/LineBreak/LineBreak";
import LongText from "components/LongText/LongText";
import PageLayout from "components/PageLayout/PageLayout";
import { CloseBlackSVG, CopyLinkSVG, StoryKebabSVG } from "components/SVG/SVG";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { toastInfo } from "hooks/useToastify";
import React, { useEffect, useState } from "react";
import { getChildComponents } from "store/async-actions/Hive/hivePage";
import { getStories } from "store/async-actions/Story/storyActions";
import { getStoriesComponents } from "store/reducers/ChildComponents/childComponents";
import {
  getHiveSelector,
  getHiveUuid,
  launchLogin,
} from "store/reducers/HiveDetails/hiveDetails";
import { isMemberViewSelector } from "store/reducers/Profile/profile";
import { getAllStoriesSelector } from "store/reducers/Story/story";
import AllStoriesView from "./UploadStory/AllStoriesView";

const StoryContainer = () => {
  const dispatch = useAppDispatch();

  const stories = useAppSelector(getAllStoriesSelector);

  const hiveUuid = useAppSelector(getHiveUuid);
  const hive = useAppSelector(getHiveSelector);
  const isMember = useAppSelector(isMemberViewSelector);

  const [kebabClicked, setKebabClicked] = useState("");

  const storiesBtns = useAppSelector(getStoriesComponents);

  useEffect(() => {
    localStorage.getItem("isLoggedIn") !== "yes" && launchLogin();
  }, []);

  useEffect(() => {
    hive &&
      dispatch(
        getChildComponents({
          organizationId: hive?.communityId,
          isMemberView: isMember,
          channelUuid: null,
          parentComponentCode: "Stories",
        })
      );
  }, [dispatch, hive, isMember]);

  useEffect(() => {
    dispatch(
      getStories({
        organizationUuid: hiveUuid,
      })
    );
  }, [dispatch, hiveUuid]);

  return (
    <PageLayout sideMenu={true} rightMenu={false}>
      <IslandLayout>
        <div className="story_home_container">
          <div className="story_home_header">
            <div>
              <h2>Visual stories</h2>
              <LineBreak />
              <p>Create captivating stories and mobile-optimized videos.</p>
            </div>
            {!!storiesBtns &&
              storiesBtns.length > 0 &&
              storiesBtns.map((button) => {
                return (
                  <Buttons button={button}>{button.componentName}</Buttons>
                );
              })}
          </div>
          <div className="all_stories_templates_wrapper">
            {!!stories &&
              stories.length > 0 &&
              stories.map((story, idx) => {
                return (
                  <AllStoriesView
                    kebabClicked={kebabClicked}
                    setKebabClicked={setKebabClicked}
                    story={story}
                    idx={idx}
                  />
                );
              })}
          </div>
        </div>
      </IslandLayout>
    </PageLayout>
  );
};

export default StoryContainer;
