const File = ({ className = "" }: SvgIconProps) => {
  return (
    <svg
      //   width="14"
      //   height="18"
      width="14"
      height="18"
      fontSize="inherit"
      viewBox="0 0 14 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M8.5 8.25232H4M5.5 11.2523H4M10 5.25232H4M13 5.10232V12.9023C13 14.1624 13 14.7925 12.7548 15.2738C12.539 15.6972 12.1948 16.0414 11.7715 16.2571C11.2902 16.5023 10.6601 16.5023 9.4 16.5023H4.6C3.33988 16.5023 2.70982 16.5023 2.22852 16.2571C1.80516 16.0414 1.46095 15.6972 1.24524 15.2738C1 14.7925 1 14.1624 1 12.9023V5.10232C1 3.8422 1 3.21214 1.24524 2.73084C1.46095 2.30748 1.80516 1.96327 2.22852 1.74755C2.70982 1.50232 3.33988 1.50232 4.6 1.50232H9.4C10.6601 1.50232 11.2902 1.50232 11.7715 1.74755C12.1948 1.96327 12.539 2.30748 12.7548 2.73084C13 3.21214 13 3.8422 13 5.10232Z"
        stroke="black"
        strokeOpacity="0.7148"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default File;
