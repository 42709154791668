import React, { memo } from 'react'

import Collapse from '@mui/material/Collapse'
import TransitionGroup from 'react-transition-group/TransitionGroup'

import CountSizeParticipants from './components/CountSizeParticipants'
import ParticipantsItem from './components/ParticipantsItem'
import SelectAllActions from './components/SelectAllActions'
import { useParticipantsContext } from './ParticipantsChatRoomContainer'
import Input from 'components/common/Input'
import TopAvatarList from './components/TopAvatarList'

const ParticipantsChatRoom: React.FC = () => {
  const { withSelectAction, participansList, isOwner, isCreate, withCount } =
    useParticipantsContext()

  return (
    <div className="participants_chat_room_container">
      {(withCount || isOwner || withSelectAction) && (
        <div className="list_control_container">
          {withCount && (
            <CountSizeParticipants count={participansList.length} />
          )}
          {isOwner && withSelectAction && <SelectAllActions />}
        </div>
      )}
      {isCreate && (
        <div className="search_block">
          <TopAvatarList />
          <Input
            placeholder="Search"
            variant="ountlined"
            className="search_participants"
          />
        </div>
      )}
      <div className="participants_list_container">
        {
          <TransitionGroup>
            {participansList.map((p) => (
              <Collapse key={p.id} in={true}>
                <ParticipantsItem key={p.id} {...p} />
              </Collapse>
            ))}
          </TransitionGroup>
        }
      </div>
    </div>
  )
}

export default memo(ParticipantsChatRoom)
