import IslandLayout from "components/IslandLayout";
import PageLayout from "components/PageLayout";
import React from "react";
import { Outlet } from "react-router-dom";

const HiveSettings = () => {
  return (
    <PageLayout sideMenu={true} rightMenu={true}>
      <IslandLayout>
        <Outlet />
      </IslandLayout>
    </PageLayout>
  );
};

export default HiveSettings;
