import LineBreak from "components/LineBreak";
import React, { useState, useEffect, ChangeEvent } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import {
  getActiveChannelUuidSelector,
  getVideosCurrentPageSelector,
  setVideosTab,
  videosTabSelector,
} from "store/reducers/ChannelDetails/channelList";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import TokenInput from "react-customize-token-input";
import "pages/UploadVideo/components/small_token_input.scss";

import {
  getHiveSelector,
  getHiveUuid,
} from "store/reducers/HiveDetails/hiveDetails";
import { editVideoDetails } from "store/async-actions/Channels/channels";
import "./react-quill.scss";
import { currentVideoSelector } from "store/reducers/Videos/videoList";
import {
  getIsEditingVideoSelector,
  getVideoEditedSelector,
  setIsEdited,
  setIsVideoEdited,
} from "store/reducers/ChannelDetails/channelDetails";
import { toastSuccess } from "hooks/useToastify";
import Dialog from "@mui/material/Dialog";
import CircularProgress from "@mui/material/CircularProgress";
import {
  contentUploadRespSelector,
  getUrl,
  getYoutubeUrlSelector,
  isUploadingSelector,
  populateFormData,
  s3Uploaded,
  setContentUploadResp,
  setIsYTUpload,
  setVideoFile,
  setYoutubeUrl,
  thumbnailSelector,
  uploadFileSelector,
} from "store/reducers/Videos/videos";
import {
  getPresignedUrl,
  getVideoList,
  uploadToS3,
} from "store/async-actions/Videos/videosActions";
import { profileUserIdSelector } from "store/reducers/Profile/profile";
import ImageDropzone from "components/ImageDropzone";
import { getLogoFileUrlSelector } from "store/reducers/CreateChannel/createChannel";
import { getVideoTabs } from "store/reducers/ChildComponents/childComponents";
import ShoppableLinkEdit from "components/ShoppableLinkEdit";
import SpotDropzone from "components/SpotDropzone";
import ChannelDropdown from "components/ChannelDropdown";
import ChannelSelect from "components/ChannelSelect";

const EditUploadInfo = () => {
  const channelUuid = useAppSelector(getActiveChannelUuidSelector);
  const dispatch = useAppDispatch();

  const currentVideo = useAppSelector(currentVideoSelector);

  const navigate = useNavigate();

  const hiveUuid = useAppSelector(getHiveUuid);
  const isVideoEditing = useAppSelector(getIsEditingVideoSelector);
  const isVideoEdited = useAppSelector(getVideoEditedSelector);

  const uploadedVideoFile = useAppSelector(uploadFileSelector);
  const thumbnail = useAppSelector(thumbnailSelector);

  const s3Done = useAppSelector(s3Uploaded);
  const s3Url = useAppSelector(getUrl);
  const isUploading = useAppSelector(isUploadingSelector);
  const contentUploadResp = useAppSelector(contentUploadRespSelector);
  const userId = useAppSelector(profileUserIdSelector);

  const [changeThumbnail, setChangeThumbnail] = useState(false);

  const hiveDetails = useAppSelector(getHiveSelector);

  const thumbnailBase64 = useAppSelector(getLogoFileUrlSelector);
  const handleThumbnailChange = () => {
    setChangeThumbnail((prevState) => !prevState);
  };

  useEffect(() => {
    if (isVideoEdited) {
      dispatch(setIsVideoEdited(false));
      toastSuccess("Video Edited Successfully");
      navigate("..");
    }
  }, [isVideoEdited]);

  useEffect(() => {
    if (uploadedVideoFile !== null && channelUuid && currentVideo) {
      dispatch(
        getPresignedUrl({
          organisationID: hiveUuid,
          channelID: channelUuid,
          userId: userId.toString() || "11111",
          videoId: currentVideo?.videoUuid,
          thumbnail: thumbnail,
        })
      );
    }
  }, [uploadedVideoFile, dispatch]);

  const pageNo = useAppSelector(getVideosCurrentPageSelector);

  const videoTabs = useAppSelector(getVideoTabs);
  const currentVideoTab = useAppSelector(videosTabSelector);

  useEffect(() => {
    if (s3Done) {
      // dispatch(setVideoFile(null));
      dispatch(
        getVideoList({
          channelUuid: channelUuid!,
          page: pageNo,
          noOfRecords: 12,
          status:
            !!videoTabs &&
            videoTabs[currentVideoTab] &&
            videoTabs[currentVideoTab].componentName
              ? videoTabs[currentVideoTab].componentName === "Videos"
                ? "Ready"
                : videoTabs[currentVideoTab].componentName
              : "Ready",
        })
      );
      // dispatch(setContentUploadResp(undefined));
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [s3Done]);

  // useEffect(() => {
  //   if (s3Url) {
  //     const formData = new FormData();
  //     populateFormData(formData, contentUploadResp!, uploadedVideoFile!);
  //     dispatch(uploadToS3({ url: s3Url, formData: formData }));
  //   }
  //   //eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [s3Url]);

  const youtubeUrl = useAppSelector(getYoutubeUrlSelector);

  const handleYoutubeEdit = () => {
    dispatch(
      editVideoDetails({
        channelUUID: channelUuid!,
        videoId: currentVideo?.videoUuid!,
        tags: values.slice(0, 5)!,
        name: videoTitle!,
        description: null,
        attribute1: null,
        attribute2: null,
        attribute3: [data]!,
        attribute4: null,
        attribute5: null,
        thumbnail: changeThumbnail ? thumbnailBase64 : null,
        productUrl: youtubeUrl,
        communityId: hiveDetails?.communityId || 0,
        newChannelUuid: newChannelUuid,
      })
    );

    dispatch(setYoutubeUrl(""));
    dispatch(setIsYTUpload(0));
  };

  const handleVideoEdit = async () => {
    currentVideo?.channelType === "YOUTUBE"
      ? handleYoutubeEdit()
      : await dispatch(
          editVideoDetails({
            channelUUID: channelUuid!,
            videoId: currentVideo?.videoUuid!,
            tags: values.slice(0, 5)!,
            name: videoTitle!,
            description: null,
            attribute1: null,
            attribute2: null,
            attribute3: [data]!,
            attribute4: null,
            attribute5: null,
            thumbnail: changeThumbnail ? thumbnailBase64 : null,
            productUrl: productUrl,
            pitchDeckFile: base64Url,
            pitchDeckFileType: fileType,
            communityId: hiveDetails?.communityId || 0,
            newChannelUuid: newChannelUuid,
          })
        );
    setChangeThumbnail(false);
  };

  const [data, setData] = useState(
    !!currentVideo?.attribute3 ? currentVideo?.attribute3[0] : ""
  );

  function handleTextBox(event: string) {
    setData(event);
  }

  const [videoTitle, setVideoTitle] = useState(currentVideo?.videoTitle || "");
  const [titleRemaining, setTitleRemaining] = useState(20);

  const handleTitleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setVideoTitle(e.target.value.slice(0, 20));
    setTitleRemaining(20 - e.target.value.slice(0, 20).length);
  };

  const [productUrl, setProductUrl] = useState("");

  const [base64Url, setBase64Url] = useState(
    currentVideo?.horizontalPreviewImage || ""
  );

  useEffect(() => {
    currentVideo && setProductUrl(currentVideo?.horizontalVideoUrl);
  }, [currentVideo]);

  const handleUrlChange = (e: ChangeEvent<HTMLInputElement>) => {
    setProductUrl(e.target.value.slice(0, 180));
  };

  const [values, setValues] = useState(currentVideo?.tags || []);

  const [removeFile, setRemoveFile] = useState(false);
  const [removeUrl, setRemoveUrl] = useState(false);

  useEffect(() => {
    if (
      currentVideo?.horizontalPreviewImage ||
      currentVideo?.horizontalVideoUrl
    ) {
      setBase64Url(currentVideo?.horizontalPreviewImage);
      setProductUrl(currentVideo?.horizontalVideoUrl);
    }
  }, [currentVideo]);

  const handlePitchClick = (url: string) => {
    window.open(url, "_blank", "noreferrer");
  };

  const [fileType, setFileType] = useState("");

  const [newChannelUuid, setNewChannelUuid] = useState("");

  return (
    <div className="upload_info_container">
      <div>
        <div className="upload_info_margins">
          <h3>Edit Video</h3>
          <p className="text-sm">Make changes to the uploaded video</p>
        </div>
        <div className="thumbnail_edit_container">
          <div className="upload_info__content_spacing">
            <ChannelSelect
              channelUuid={channelUuid || ""}
              setChannelUuid={setNewChannelUuid}
            />
          </div>
          <h5 className="upload_headings">Cover photo</h5>
          {changeThumbnail ? (
            <ImageDropzone file="story" />
          ) : uploadedVideoFile && thumbnail ? (
            <img
              className="thumbnail_photo"
              src={`data:image/jpeg;base64,${thumbnail}`}
            />
          ) : (
            <img className="thumbnail_photo" src={currentVideo?.thumbnail} />
          )}
          <p onClick={handleThumbnailChange} className="link text-sm">
            {changeThumbnail ? "Cancel" : "Edit Thumbnail"}
          </p>
        </div>
        <h5 className="upload_headings">Title</h5>
        <div className="upload_info_margins upload_title_input">
          <input
            className={`${
              videoTitle!.length > 0
                ? "video_upload_title"
                : "video_title_empty"
            }`}
            value={videoTitle}
            onInput={handleTitleChange}
            placeholder="Untitled"
          />
          <div className="character_limit text-sm">
            {videoTitle && videoTitle.length}/20
          </div>
        </div>
        <div className="upload_info_margins">
          <div className="title_and_limit">
            <h4 className="upload_headings">Tags</h4>
            <div className="character_limit text-sm">*max 5 tags</div>
          </div>
          {/* <LineBreak /> */}
          <TokenInput
            separators={[" ", ",", ";"]}
            tokenValues={values!.slice(0, 5)}
            onTokenValuesChange={setValues}
            className=""
            placeholder={`Tags separated by commas`}
          />
        </div>
        <div className="text_box_container">
          <h4 className="upload_headings">Description</h4>
          <LineBreak />
          <ReactQuill
            className="text_box"
            theme="snow"
            value={data}
            onChange={(e) => handleTextBox(e)}
          />
        </div>

        <div className="upload_info_margins_pitch">
          <div className="title_and_limit">
            <h4 className="upload_headings">Attachment</h4>
            {!!currentVideo?.horizontalPreviewImage && (
              <>
                {!removeFile ? (
                  <p
                    className="link text_xs"
                    onClick={() => {
                      setBase64Url("");
                      setRemoveFile(true);
                    }}
                  >
                    Change
                  </p>
                ) : (
                  <p
                    className="link text_xs"
                    onClick={() => {
                      currentVideo?.horizontalPreviewImage &&
                        setBase64Url(currentVideo?.horizontalPreviewImage);
                      setRemoveFile(false);
                    }}
                  >
                    Cancel
                  </p>
                )}
              </>
            )}
          </div>
          <LineBreak />
          {!removeFile && currentVideo?.horizontalPreviewImage ? (
            <div
              onClick={() =>
                handlePitchClick(currentVideo?.horizontalPreviewImage)
              }
              className="view_pitch_deck_wrapper"
            >
              <img
                src="https://images.veehive.ai/webApp/forms.png"
                width="30px"
              />
              <h4>View Attachment</h4>
            </div>
          ) : (
            <SpotDropzone
              base64Url={base64Url}
              setBase64Url={setBase64Url}
              setFileType={setFileType}
            />
          )}
        </div>

        <LineBreak />
        {
          <ShoppableLinkEdit
            productUrl={productUrl}
            handleUrlChange={handleUrlChange}
          />
        }
      </div>
      <div className="button_rows-edit_video">
        <NavLink to=".." className="secondaryBtn half_width">
          Discard
        </NavLink>
        {videoTitle!.length > 1 ? (
          <div onClick={handleVideoEdit} className="primaryBtn half_width">
            Save
          </div>
        ) : (
          <div className="disabledBtn half_width">Save</div>
        )}
      </div>
      <Dialog open={isVideoEditing || isUploading}>
        <div className="loader_padding">
          <CircularProgress size={30} color="inherit" />
        </div>
      </Dialog>
    </div>
  );
};

export default EditUploadInfo;
