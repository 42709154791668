import { useEffect, useState, useCallback } from "react";
import { useParams, Params } from "react-router-dom";

import PageLayout from "components/PageLayout";
import BackButton from "components/BackButton";
import SettingsChatRoom from "components/SettingsChatRoom";
import ParticipantsChatRoom from "components/ParticipantsChatRoom";
import {
  getChatRoomsParticipantsSelector,
  clearChatRoomParticipant,
  getChatRoomNameSelector,
  getChatRoomLogoSelector,
  getChatRoomBioSelector,
  getOrganizationUuidSelector,
} from "store/reducers/Chat/chatrooms";
import {
  useAppDispatch,
  useAppSelector,
  useAppShallowSelector,
} from "hooks/redux";
import {
  removeParticipantFromChatRoom,
  updateChatRoomBio,
  updateChatRoomLogo,
  updateChatRoomName,
} from "store/async-actions/Chat/chatrooms";
// import { getIsModeratorChannelSelector } from "store/reducers/channels";

import { getChatRoomUsersList } from "store/async-actions/Chat/chatrooms";
import { getIsOwnerContentSelector } from "store/reducers/Content/content";

const ChatRoomSettingsPage = () => {
  const { roomId } = useParams<Params<"roomId">>();

  const dispatch = useAppDispatch();

  const participants = useAppShallowSelector(getChatRoomsParticipantsSelector);
  const chatRoomNameStore = useAppSelector(getChatRoomNameSelector);
  const chatRoomBioStore = useAppSelector(getChatRoomBioSelector);
  const chatRoomLogo = useAppSelector(getChatRoomLogoSelector);
  const organizationUuid = useAppSelector(getOrganizationUuidSelector);
  const isModerator = useAppSelector(getIsOwnerContentSelector);

  const [chatRoomName, setChatRoomName] = useState<string>(chatRoomNameStore);
  const [chatRoomBio, setChatRoomBio] = useState<string>(chatRoomBioStore);
  const [selectedState, setSelectedState] = useState<UserId[]>([]);

  const handleRemovePartisipants = useCallback(
    (ids: UserId[]) => {
      const userIds = ids.map((id) => ({ userId: id, isPinned: false }));

      dispatch(
        removeParticipantFromChatRoom({
          chatRoomId: roomId!,
          userIds: userIds,
        })
      );
    },
    [dispatch, roomId]
  );

  const handleSubmit = useCallback(() => {
    if (roomId) {
      dispatch(updateChatRoomBio({ chatRoomBio, chatRoomId: roomId! }));
    }
  }, [chatRoomBio, dispatch, roomId]);

  const handleSubmitNewRoomName = useCallback(() => {
    if (roomId) {
      dispatch(updateChatRoomName({ chatRoomId: roomId, chatRoomName }));
    }
  }, [chatRoomName, dispatch, roomId]);

  const handleChangeLogo = useCallback(
    (file: File) => {
      if (roomId) {
        const formData = new FormData();
        formData.append("chatRoomId", roomId);
        formData.append("organizationUuid", `${organizationUuid}`);
        formData.append("file", file);
        dispatch(
          updateChatRoomLogo({
            chatRoomId: roomId,
            formData: formData,
          })
        );
      }
    },
    [dispatch, organizationUuid, roomId]
  );

  useEffect(() => {
    if (chatRoomNameStore) {
      setChatRoomName(chatRoomNameStore);
    }
  }, [chatRoomNameStore]);

  useEffect(() => {
    if (chatRoomBioStore) {
      setChatRoomBio(chatRoomBioStore);
    }
  }, [chatRoomBioStore]);

  useEffect(() => {
    if (roomId) {
      dispatch(getChatRoomUsersList({ chatRoomId: roomId }));
    }
    return () => {
      dispatch(clearChatRoomParticipant());
    };
  }, [dispatch, roomId]);

  return (
    <>
      <BackButton />
      {/* <PageLayout sideMenu={false}> */}
      {/* <PageLayout className="chat_room_settings_page"> */}
      <SettingsChatRoom
        roomId={roomId!}
        // isOwner={true}
        isOwner={isModerator}
        isCreate={false}
        nameValue={chatRoomName}
        bioValue={chatRoomBio}
        logo={chatRoomLogo}
        changeNameValue={setChatRoomName}
        changeBioValue={setChatRoomBio}
        changeLogo={handleChangeLogo}
        submitBio={handleSubmit}
        submitName={handleSubmitNewRoomName}
      />
      <ParticipantsChatRoom
        data={participants}
        // isOwner={true}
        isOwner={isModerator}
        selectedState={selectedState}
        changeSelectedState={setSelectedState}
        deleteParticipanst={handleRemovePartisipants}
        withDeleteConfirmation={true}
      />
      {/* </PageLayout> */}
    </>
  );
};

export default ChatRoomSettingsPage;
