import React, { useEffect, useState } from "react";
import Switch from "@mui/joy/Switch";
import { StyledEngineProvider, CssVarsProvider } from "@mui/joy/styles";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import {
  channelEditIsLoadingSelector,
  channelObjSelector,
  channelPropSelector,
  getChannelAmountSelector,
  getChannelIsEditedSelector,
  getEditChannelIsAdsSelector,
  getEditChannelIsPaidSelector,
  getEditChannelIsPrivateSelector,
  getEditChannelVideoDurationSelector,
  setChannelAmount,
  setIsAds,
  setIsEdited,
  setIsPaid,
  setIsPrivate,
  setVideoDuration,
} from "store/reducers/ChannelDetails/channelDetails";
import PageLayout from "components/PageLayout";
import IslandLayout from "components/IslandLayout";
import BackButton from "components/BackButton";
import ChannelTabs from "components/ChannelTabs";
import { setActiveTab } from "store/reducers/ChannelDetails/channelList";
import {
  getChannelDetails,
  editChannelDetails,
} from "store/async-actions/Channels/channels";
import { getChannelList } from "store/async-actions/Hive/hivePage";
import { isMemberViewSelector } from "store/reducers/Profile/profile";
import {
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from "@mui/material";
import { toastInfo, toastSuccess } from "hooks/useToastify";
import LineBreak from "components/LineBreak";

const EditChannelSettings = () => {
  const dispatch = useAppDispatch();

  const channelObj = useAppSelector(channelObjSelector);
  const channelProps = useAppSelector(channelPropSelector);

  const amount = useAppSelector(getChannelAmountSelector);
  const isPaid = useAppSelector(getEditChannelIsPaidSelector);
  const isAds = useAppSelector(getEditChannelIsAdsSelector);
  const isPrivate = useAppSelector(getEditChannelIsPrivateSelector);
  const videoDuration = useAppSelector(getEditChannelVideoDurationSelector);

  useEffect(() => {
    dispatch(setActiveTab(2));

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isMemberView = useAppSelector(isMemberViewSelector);
  const isEdited = useAppSelector(getChannelIsEditedSelector);

  const isLoading = useAppSelector(channelEditIsLoadingSelector);

  useEffect(() => {
    if (isEdited) {
      toastSuccess("Channel Details Saved");
      dispatch(
        getChannelList({
          hiveId: channelObj?.organization_id.id!,
          isMemberView: isMemberView,
        })
      );
      dispatch(getChannelDetails(channelObj?.channelUUID!));
      dispatch(setIsEdited(false));
    }
  }, [isEdited, dispatch]);

  const handleSave = () => {
    dispatch(
      editChannelDetails({
        channelName: "",
        category: "CHANNEL",
        channelLogo: "",
        channelWebLogo: "",
        channelTier:
          isPrivate && +amount > 0
            ? "PRIVATE_PAID"
            : isPaid && +amount > 0
            ? "PAID"
            : isPrivate
            ? "INVITE"
            : "FREE",
        uploadFlag: false,
        amount: +amount,
        description: "",
        maxVideoLength: videoDuration,
        channelId: channelObj?.id!,
        channelNameFlag: false,
        categoryFlag: false,
        channelTierFlag: true,
        amountFlag: true,
        descriptionFlag: false,
        maxVideoLengthFlag: true,
        channelLogoFlag: false,
        channelWebLogoFlag: false,
        orderByDesc: channelProps?.orderby === "DESC",
        adsRequired: isAds,
        ctaName: linkTag === "custom" ? customTag : linkTag,
        ctaNameFlag: true,
      })
    );
  };

  const [linkTag, setLinkTag] = useState(channelProps?.ctaName || "");

  const handleLinkChange = (value: SelectChangeEvent) => {
    setLinkTag(value.target.value);
  };

  const [customTag, setCustomTag] = useState("");

  const handleCustomTag = (e: any) => {
    setCustomTag(e.target.value.slice(0, 30));
  };

  return (
    <PageLayout sideMenu={true}>
      <IslandLayout>
        <div className="ce_settings_container">
          <BackButton />
          <ChannelTabs />
          <StyledEngineProvider injectFirst>
            <CssVarsProvider>
              <div className="channel_duration_container">
                <p className="gradient">Video Duration Limit</p>
                <div className="video_limit_btns">
                  <button
                    onClick={() => dispatch(setVideoDuration(60))}
                    className={`${
                      videoDuration === 60 ? "primaryBtn" : "secondaryBtn"
                    }`}
                  >
                    1 min
                  </button>
                  <button
                    onClick={() => dispatch(setVideoDuration(180))}
                    className={`${
                      videoDuration === 180 ? "primaryBtn" : "secondaryBtn"
                    }`}
                  >
                    3 mins
                  </button>
                  <button
                    onClick={() => dispatch(setVideoDuration(300))}
                    className={`${
                      videoDuration === 300 ? "primaryBtn" : "secondaryBtn"
                    }`}
                  >
                    5 mins
                  </button>
                  <button
                    onClick={() => dispatch(setVideoDuration(600))}
                    className={`${
                      videoDuration === 600 ? "primaryBtn" : "secondaryBtn"
                    }`}
                  >
                    10 mins
                  </button>
                </div>
              </div>
              {!channelProps?.defaultChannel && (
                <div className="channel_setting_container">
                  <Switch
                    checked={isPrivate}
                    onChange={(event) =>
                      dispatch(setIsPrivate(event.target.checked))
                    }
                  />
                  <p>Set channel visibility to private</p>
                </div>
              )}
              <div className="channel_setting_container">
                <Switch
                  checked={isAds}
                  onChange={(event) => dispatch(setIsAds(event.target.checked))}
                />
                <p>Enable Advertisements</p>
              </div>
              {!channelProps?.defaultChannel && (
                <div className="channel_paid_container">
                  <div className="switch_display">
                    <Switch
                      checked={isPaid}
                      onChange={(event) =>
                        dispatch(setIsPaid(event.target.checked))
                      }
                    />
                    <p>Paid</p>
                  </div>
                  {isPaid && (
                    <div className="channel_amount_wrapper">
                      <input
                        className="outline-none"
                        placeholder="0.00"
                        value={amount}
                        onChange={(e) => {
                          if (+e.target.value > 999) {
                            toastInfo("Please enter amount less than 1000");
                          }
                          /^\d*(\.\d{0,2})?$/.test(e.target.value) &&
                            +e.target.value < 1000 &&
                            dispatch(setChannelAmount(e.target.value));
                        }}
                      />
                      <h5 className="">USD</h5>
                    </div>
                  )}
                </div>
              )}
            </CssVarsProvider>
          </StyledEngineProvider>
          <>
            <h4 className="cta_shop_margins">Shoppable Link CTA Name</h4>

            <LineBreak />
            <div className="link_flex_display">
              <FormControl className="half_width ">
                <InputLabel id="demo-simple-select-label">CTA Name</InputLabel>

                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={linkTag}
                  defaultValue="Buy Now"
                  label="CTA Name"
                  onChange={handleLinkChange}
                >
                  <MenuItem value="Buy Now">
                    <p>Buy Now</p>
                  </MenuItem>
                  <MenuItem value="View Product">
                    <p>View Product</p>
                  </MenuItem>
                  <MenuItem value="custom">
                    <p>Custom</p>
                  </MenuItem>
                  {/* <MenuItem value={linkTag}> */}
                </Select>
              </FormControl>
              {linkTag === "custom" && (
                <TextField
                  placeholder="Click Here"
                  className="half_width"
                  name="customTag"
                  onChange={handleCustomTag}
                  value={customTag}
                  id="outlined-basic"
                  label="Custom CTA Name"
                  variant="outlined"
                />
              )}
            </div>
          </>
          <>
            {isLoading ? (
              <div className="nextBtn primaryBtn">
                <CircularProgress size={20} color="inherit" />
              </div>
            ) : (
              <div onClick={handleSave} className="nextBtn primaryBtn">
                Save
              </div>
            )}
          </>
        </div>
      </IslandLayout>
    </PageLayout>
  );
};

export default EditChannelSettings;
