import IslandLayout from "components/IslandLayout";
import LineBreak from "components/LineBreak";
import PageLayout from "components/PageLayout";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getCampaignsList } from "store/async-actions/Campaign/campaign";
import {
  getCampaignsListSelector,
  setCampaignUuid,
} from "store/reducers/Campaign/campaign";
import { getHiveUuid } from "store/reducers/HiveDetails/hiveDetails";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import moment from "moment";
import ReactTimeAgo from "react-time-ago";
import CampaignKebab from "./components/CampaignKebab";

const CampaignContainer = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleNewCampaign = () => {
    dispatch(setCampaignUuid(""));
    navigate("create");
  };

  const hiveUuid = useAppSelector(getHiveUuid);

  useEffect(() => {
    dispatch(getCampaignsList({ organizationUuid: hiveUuid }));
  }, [dispatch, hiveUuid]);

  const campaignList = useAppSelector(getCampaignsListSelector);

  const handleViewCampaign = (campaignUuid: string) => {
    navigate(campaignUuid);
  };

  return (
    <PageLayout sideMenu={true} rightMenu={false}>
      <IslandLayout>
        <div className="story_home_container">
          <div className="story_home_header">
            <div>
              <h2>Campaigns</h2>
              <LineBreak />
              <p>
                Create campaigns to effectively reach and engage your target
                audience.
              </p>
            </div>
            <div onClick={handleNewCampaign} className="secondaryBtn">
              New Campaign
            </div>
          </div>
          <LineBreak />
          {!!campaignList[0] ? (
            <div className="">
              <h3>Your Campaigns</h3>
              <LineBreak />
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="members table">
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <p className="bold_heading_table">Campaign Name</p>
                      </TableCell>
                      <TableCell align="left">
                        <p className="bold_heading_table">Type</p>
                      </TableCell>
                      <TableCell align="left">
                        <p className="bold_heading_table">Message</p>
                      </TableCell>
                      <TableCell align="left">
                        <p className="bold_heading_table">Actions</p>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {!!campaignList &&
                      campaignList?.map((data, idx) => (
                        <TableRow
                          className="table_cell_hover"
                          key={idx}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell
                            onClick={() =>
                              handleViewCampaign(data.campaignUuid)
                            }
                            component="th"
                            scope="row"
                          >
                            {data.campaignName || "-"}
                          </TableCell>
                          <TableCell
                            onClick={() =>
                              handleViewCampaign(data.campaignUuid)
                            }
                            align="left"
                          >
                            {data.campaignType === "sms" ? "SMS" : "Email"}
                          </TableCell>
                          <TableCell
                            onClick={() =>
                              handleViewCampaign(data.campaignUuid)
                            }
                            align="left"
                          >
                            {moment.utc(data.createdDate).local().format("LL")}
                          </TableCell>
                          <TableCell align="left">
                            <CampaignKebab campaign={data} />
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          ) : (
            <h3>You currently dont have any campaigns</h3>
          )}
        </div>
      </IslandLayout>
    </PageLayout>
  );
};

export default CampaignContainer;
