import LinearProgress from "@mui/material/LinearProgress";
import BackButton from "components/BackButton";
import Collapsible from "components/Collapsible";
import DateSelector from "components/DateSelector";
import LineBreak from "components/LineBreak";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import React, { useEffect, useState } from "react";
import { getProductDetails } from "store/async-actions/Analytics/analyticsActions";
import {
  getFromDateSelector,
  getProductDetailsSelector,
  getProductsCountSelector,
  getReducedProductDetailsSelector,
  getToDateSelector,
  productListPageNo,
} from "store/reducers/Analytics/analytics";
import { getHiveUuid } from "store/reducers/HiveDetails/hiveDetails";
import UserActionsTable from "./UserActionsTable";
import UsersAnalyticsTable from "./UsersAnalyticsTable";

const checkProgress = (used: number): number => {
  return (used / 50000000000) * 100;
};

const AnalyticsDetails = () => {
  const dispatch = useAppDispatch();

  const productDetails = useAppSelector(getProductDetailsSelector);
  const hiveUuid = useAppSelector(getHiveUuid);

  const productDetailsList = useAppSelector(getReducedProductDetailsSelector);

  const productsCount = useAppSelector(getProductsCountSelector);

  const fromDate = useAppSelector(getFromDateSelector);
  const toDate = useAppSelector(getToDateSelector);

  const pageNo = useAppSelector(productListPageNo);

  useEffect(() => {
    productsCount &&
      productsCount[0] &&
      productsCount[0].appName &&
      dispatch(
        getProductDetails({
          appName: productsCount[0].appName,
          organizationUuid: hiveUuid,
          fromDate: fromDate,
          toDate: toDate,
          pageNo: pageNo,
          pageSize: 15,
        })
      );
  }, [dispatch, productsCount, fromDate, toDate]);

  const retrieveDetails = (name: string) => {
    dispatch(
      getProductDetails({
        appName: name,
        organizationUuid: hiveUuid,
        fromDate: fromDate,
        toDate: toDate,
        pageNo: pageNo,
        pageSize: 15,
      })
    );
  };

  return (
    <>
      <div className="ad_bb_spacing">
        <BackButton />
      </div>
      <DateSelector />
      <LineBreak />
      {productDetailsList &&
      productDetailsList.length > 0 &&
      productDetailsList[0].content ? (
        productsCount.map((product, idx) => {
          return (
            <div
              onClick={() => {
                retrieveDetails(product.appName);
              }}
            >
              <Collapsible boldText={product.appDisplayName}>
                <>
                  <div className="vh_ad_lp">
                    <h3>{productsCount[idx].count} Users</h3>
                    <LinearProgress
                      variant="determinate"
                      value={
                        productsCount[idx].count > 100
                          ? (productsCount[idx].count / 100) * 10
                          : productsCount[idx].count
                      }
                      color="primary"
                    />
                  </div>
                  <div className="vh_analytics_table_wrapper">
                    <div className="analytics_table_dimension">
                      <UsersAnalyticsTable
                        analyticsData={productDetailsList[idx]}
                      />
                    </div>
                    <div className="user_action_table_dimension">
                      <UserActionsTable />
                    </div>
                  </div>
                </>
              </Collapsible>
            </div>
          );
        })
      ) : (
        <div className="no_videos_container">
          <>
            <h3>No User Data found</h3>
            <p className="">
              Here's where you'll find all the user analytics and journey that
              have occured in the selected date range, Please change the date
              filter to view usage history
            </p>
          </>
        </div>
      )}
    </>
  );
};

export default AnalyticsDetails;
