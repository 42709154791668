import { useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import first from "assets/png/first.png";
import second from "assets/png/second.png";
import third from "assets/png/third.png";
import forth from "assets/png/forth.png";
import fifth from "assets/png/fifth.png";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import {
  getShowSpotlightSelector,
  getSpotlightSlideSelector,
  setShowSpotlightTour,
  setSpotlightTourSlide,
} from "store/reducers/Videos/videos";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "#f0f0f0",
  border: "none",
  boxShadow: 24,
  paddingTop: 2,
  paddingBottom: 4,
  outline: "none",
  borderRadius: 4,
};

const SpotLightTourModal = () => {
  const spotlightSlide = useAppSelector(getSpotlightSlideSelector);
  const showSpotLightTour = useAppSelector(getShowSpotlightSelector);

  const dispatch = useAppDispatch();

  return (
    <Modal
      open={showSpotLightTour}
      onClose={() => {
        dispatch(setShowSpotlightTour(false));
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className="relative guidedTour" sx={style}>
        {spotlightSlide > 0 && (
          <button
            onClick={() => {
              dispatch(setSpotlightTourSlide(spotlightSlide - 1));
            }}
            className="spot-tour_backBtn secondaryBtn"
          >
            Back
          </button>
        )}
        <button
          onClick={() => {
            if (spotlightSlide === 4) {
              dispatch(setShowSpotlightTour(false));
              dispatch(setSpotlightTourSlide(0));
            } else {
              dispatch(setSpotlightTourSlide(spotlightSlide + 1));
            }
          }}
          className="spot-tour_nextBtn secondaryBtn"
        >
          {spotlightSlide === 4 ? "Done" : "Next"}
        </button>
        {/* <img src={v_guide} alt="" className="w-4 absolute top-5 left-5" /> */}
        <div className="guidedCarousel">
          <Carousel
            showArrows={false}
            interval={10000}
            // className="w-96 h-96"
            selectedItem={spotlightSlide}
            onChange={(i: number) => {
              if (spotlightSlide !== i) {
                dispatch(setSpotlightTourSlide(i));
              }
              i = spotlightSlide;
            }}
            showIndicators={true}
            showThumbs={false}
            autoPlay
            infiniteLoop={false}
            swipeable={true}
            showStatus={false}
          >
            <div className="spot-tour_carousel-item">
              <div className="guidedImg">
                <img src={first} alt="" />
              </div>
              <h1 className="">Pitch your startup</h1>
              <p className="spot-tour_content">
                Attention all entrepreneurs! We're excited to review your ideas
                and support the next generation of entrepreneurs. Your video
                pitch should include a clear value proposition, target market,
                growth plan and a solid team. Don't miss this opportunity to
                showcase your start-up and take it to the next level. Submit
                your video pitch today and let us help you achieve your
                entrepreneurial dreams!"
              </p>
            </div>
            <div className="spot-tour_carousel-item">
              <div className="guidedImg">
                <img src={second} alt="" />
              </div>
              <h1 className="">Video title</h1>
              <p className="spot-tour_content">
                When submitting a video to the spotlight feature, it's important
                to give your video a clear and descriptive title. This title
                should give viewers an idea of what the video is about and help
                them to find it when searching for similar content.
              </p>
            </div>
            <div className="spot-tour_carousel-item">
              <div className="guidedImg">
                <img src={third} alt="" />
              </div>
              <h1 className="">Tags</h1>
              <p className="spot-tour_content">
                Any relevant tags that describe your company's industry,
                product, or service. Be sure to choose tags that accurately
                reflect your company and help to increase visibility. You can
                add multiple tags to your video to increase its visibility.
              </p>
            </div>

            <div className="spot-tour_carousel-item">
              <div className="guidedImgVid">
                <img src={forth} alt="" />
              </div>
              <h1 className="">Description</h1>
              <p className="spot-tour_content">
                In addition to your pitch, you may also want to include any
                additional information that can help to support your case. This
                can include a business plan, financial projections, customer
                testimonials, or any other relevant data that can help to
                demonstrate the viability and potential of your company. If you
                have any related documents, please make sure that they are
                well-organized, easy to understand and relevant to the pitch.
              </p>
            </div>
            <div className="spot-tour_carousel-item">
              <div className="guidedImg">
                <img src={fifth} alt="" />
              </div>
              <h1 className="">Submit</h1>
              <p className="spot-tour_content">
                Double check all the information you provided is correct and
                complete. Submit the pitch.
              </p>
            </div>
          </Carousel>
        </div>
      </Box>
    </Modal>
  );
};

export default SpotLightTourModal;
