import { CircularProgress, LinearProgress } from "@mui/material";
import CircularProgressWithLabel from "components/CircularProgressWithLabel";
import { UploadImageSVG } from "components/SVG/SVG";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { toastInfo } from "hooks/useToastify";
import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import {
  getPresignedUrlWithType,
  populateFormDataWithType,
  uploadGalleryEvent,
} from "store/async-actions/Hive/eventsActions";
import { uploadToS3 } from "store/async-actions/Hive/hivePage";
import { getCurrentEventSelector } from "store/reducers/HiveDetails/events";
import { getHiveSelector } from "store/reducers/HiveDetails/hiveDetails";
import { getVideoThumbnail } from "store/reducers/Videos/videos";

type Props = {};

type UploadedFile = {
  file: File;
  sourceUrl: string;
};

const UploadGallery = (props: Props) => {
  const [uploadedFiles, setUploadedFiles] = useState<UploadedFile[]>([]);
  const [uploadingFiles, setUploadingFiles] = useState<File[]>([]);
  const [progress, setProgress] = useState(0);
  const [currentUploading, setCurrentUploading] = useState(-1);

  const hive = useAppSelector(getHiveSelector);
  const currentEvent = useAppSelector(getCurrentEventSelector);

  const dispatch = useAppDispatch();

  const handleDrop = async (acceptedFiles: File[]) => {
    var count = 0;
    setUploadingFiles((prevState) => [...prevState, ...acceptedFiles]);
    // setCurrentUploading(-1);

    acceptedFiles.map(async (file, idx) => {
      setProgress(0);
      setCurrentUploading(count);
      // Dispatch an action to get the pre-signed URL
      const data = await getPresignedUrlWithType({
        type: "eventGallery",
        communityId: hive?.communityId || 0,
        file: file,
        setUploadProgress: setProgress,
        fileType: file.type,
      });
      if (data) {
        const formData = new FormData();
        populateFormDataWithType(formData, data.preSigned.data, file);
        await dispatch(
          uploadToS3({ url: data.preSigned.data.url, formData: formData })
        );
        if (file.type === "video/mp4") {
          await getVideoThumbnail(file).then((thumbnail) => {
            dispatch(
              uploadGalleryEvent({
                eventUuid: currentEvent?.eventUuid || "",
                galleryUrl: data?.sourceUrl || "",
                eventThumbnail: thumbnail as string,
              })
            );
          });
        } else {
          dispatch(
            uploadGalleryEvent({
              eventUuid: currentEvent?.eventUuid || "",
              galleryUrl: data?.sourceUrl || "",
              eventThumbnail: "",
            })
          );
        }
      }
      uploadedFiles.push({ file: file, sourceUrl: data?.sourceUrl || "" });

      count += 1;
      setCurrentUploading(count);
      setProgress(0);
    });

    // for (const file of acceptedFiles) {
    //   setProgress(0);
    //   setCurrentUploading(count);
    //   // Dispatch an action to get the pre-signed URL
    //   const data = await getPresignedUrlWithType({
    //     type: "eventGallery",
    //     communityId: hive?.communityId || 0,
    //     file: file,
    //     setUploadProgress: setProgress,
    //     fileType: file.type,
    //   });
    //   if (data) {
    //     const formData = new FormData();
    //     populateFormDataWithType(formData, data.preSigned.data, file);
    //     await dispatch(
    //       uploadToS3({ url: data.preSigned.data.url, formData: formData })
    //     );
    //     getVideoThumbnail(file).then(async (thumbnail) => {
    //       console.log()
    //       await dispatch(
    //         uploadGalleryEvent({
    //           eventUuid: currentEvent?.eventUuid || "",
    //           galleryUrl: data?.sourceUrl || "",
    //           eventThumbnail: thumbnail as string,
    //         })
    //       );
    //     });
    //   }
    //   uploadedFiles.push({ file: file, sourceUrl: data?.sourceUrl || "" });

    //   count += 1;
    //   setCurrentUploading(count);
    //   setProgress(0);
    // }
  };

  const onDropAccepted = useCallback(handleDrop, []);
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    onDrop: onDropAccepted,
  });

  return (
    <div
      {...getRootProps()}
      className={`pointer upload_wrapper dropzone ${
        isDragActive ? "dropzone--isActive border-blue-600" : ""
      } ${isDragAccept ? "dropzone--isAccept border-blue-600" : ""} ${
        isDragReject ? "dropzone--isReject border-red-600" : ""
      }`}
    >
      <input accept="*" {...getInputProps()} />
      {
        <div key={progress} className="upload_content_banner_wrapper">
          <div className="gallery_items_container">
            {uploadingFiles ? (
              uploadingFiles.map((file, idx) => {
                return (
                  <div className="gallery_item_uploading" key={idx}>
                    <img
                      loading="lazy"
                      className="gallery_image_uploading"
                      src={
                        uploadedFiles[idx]?.sourceUrl ||
                        "https://veehivestage-images.s3.us-east-2.amazonaws.com/webApp/uplaoding.png"
                      }
                    />

                    <div className="gallery_loader">
                      {uploadedFiles[idx] ? (
                        <CircularProgressWithLabel
                          color="secondary"
                          variant="determinate"
                          value={100}
                        />
                      ) : idx === currentUploading ? (
                        <CircularProgressWithLabel
                          color="secondary"
                          variant="determinate"
                          value={progress}
                        />
                      ) : (
                        <CircularProgressWithLabel
                          color="secondary"
                          variant="determinate"
                          value={0}
                        />
                      )}
                    </div>
                  </div>
                );
              })
            ) : (
              <UploadImageSVG />
            )}
          </div>
          <div className="upload_text_wrapper">
            <div>
              <h4 className="">{`Drag and drop media here or click to ${
                uploadedFiles?.length === uploadedFiles?.length
                  ? "change"
                  : "upload"
              }`}</h4>
              <p className="text-gray-400 font-semibold text-sm">
                Recommended size: 500kb
              </p>
            </div>
          </div>
        </div>
      }
    </div>
  );
};

export default UploadGallery;
