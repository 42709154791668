import LineBreak from "components/LineBreak";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import React, { useState } from "react";
import { getSocialLinksSelector } from "store/reducers/HiveDetails/hiveSettings";
import { saveSocialLinks } from "store/async-actions/Hive/hivePage";
import { getHiveUuid } from "store/reducers/HiveDetails/hiveDetails";
import { GreenTickSVG, RedCrossSVG } from "components/SVG/SVG";
import { HiveFormObject } from "pages/Hive/Settings/components/HiveAbout";

interface EditLinksType {
  [key: string]: boolean | undefined;
  linkedinLink: boolean;
  facebookLink: boolean;
  twitterLink: boolean;
  instagramLink: boolean;
  whatsappLink: boolean;
}

const ContentDistribute = () => {
  const socialResp = useAppSelector(getSocialLinksSelector);
  const dispatch = useAppDispatch();

  const hiveUuid = useAppSelector(getHiveUuid);

  const [editLink, setEditLink] = useState<EditLinksType>({
    linkedinLink: false,
    facebookLink: false,
    twitterLink: false,
    instagramLink: false,
    whatsappLink: false,
  });

  return (
    <div className="ttv_container">
      <LineBreak />
      {socialResp.map((data, idx) => {
        return (
          <div key={idx} className="settings_social_config_header">
            <div className="settings_social_config_icon">
              <img src={data.icon} className="social_icon" alt="" />
              <p>{data.componentName}</p>
            </div>

            <div className="secondaryBtn">Sign in</div>
          </div>
        );
      })}
    </div>
  );
};

export default ContentDistribute;
