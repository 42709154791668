import IslandLayout from "components/IslandLayout";
import LineBreak from "components/LineBreak";
import PageLayout from "components/PageLayout";
import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";
import AnalyticsTabs from "./components/AnalyticsTab";

const AnalyticsPage = () => {
  return (
    <PageLayout sideMenu={true} rightMenu={false}>
      <IslandLayout>
        <div className="analytics_page_container">
          <h2>Analytics</h2>
          <LineBreak />
          <AnalyticsTabs />
          <LineBreak />
          <Outlet />
        </div>
      </IslandLayout>
    </PageLayout>
  );
};

export default AnalyticsPage;
