import AnalyticsCapsule from "components/AnalyticsCapsule";
import { useAppSelector } from "hooks/redux";
import React from "react";
import {
  videoCountSelector,
  usersCountSelector,
  channelsCountSelector,
  getHiveRevenueSelector,
  getChannelDetailsMetricsSelector,
} from "store/reducers/Analytics/analytics";

const ChannelDetailsMetrics = () => {
  const ChannelMetrics = useAppSelector(getChannelDetailsMetricsSelector);

  return (
    <div className="usage_data_container">
      <AnalyticsCapsule>
        <div className="headers_and_value">
          <h5>Videos</h5>
          <h2>{ChannelMetrics?.totalVideos || 0}</h2>
        </div>
      </AnalyticsCapsule>
      <AnalyticsCapsule>
        <div className="headers_and_value">
          <h5>Revenue</h5>
          <h2>{ChannelMetrics?.totalRevenue.toFixed(2) || 0}</h2>
        </div>
      </AnalyticsCapsule>
      <AnalyticsCapsule>
        <div className="headers_and_value">
          <h5>Members</h5>
          <h2>{ChannelMetrics?.totalMembers || 0}</h2>
        </div>
      </AnalyticsCapsule>
    </div>
  );
};

export default ChannelDetailsMetrics;
