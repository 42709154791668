import React, { useEffect } from "react";
import Collapsible from "components/Collapsible";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import {
  getHiveGuidelinesSelector,
  getHiveSelector,
  getHiveUuid,
} from "store/reducers/HiveDetails/hiveDetails";
import { getHiveGuidelines } from "store/async-actions/Hive/hivePage";

const HiveGuidelines = () => {
  const hiveDetails = useAppSelector(getHiveSelector);
  const hiveUuid = useAppSelector(getHiveUuid);

  const guidelines = useAppSelector(getHiveGuidelinesSelector);

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(
      getHiveGuidelines({
        organizationUuid: hiveUuid,
      })
    );
  }, [hiveUuid]);

  return (
    <>
      {guidelines.map((data, idx) => {
        return (
          <div key={idx}>
            <Collapsible triggerText={data.title}>{data.answer}</Collapsible>
            {idx < guidelines.length - 1 && <hr />}
          </div>
        );
      })}
    </>
  );

  // if (hiveDetails?.communityId! === 197) {
  //   return (
  //     <>
  //       <Collapsible triggerText="What is LCUBE Community?">
  //         LCUBE Community is a safe platform for anyone to connect with other
  //         individuals with liver disease, share their experiences, and receive
  //         support and advice from expert doctors and peers if they want to be
  //         aware of liver health or have any issues. You can access infotainment
  //         bites via short video collections on specific topics related to
  //         various aspects of liver health through different channels.
  //       </Collapsible>
  //       <hr />
  //       <Collapsible triggerText="What is the purpose of LCUBE Community?">
  //         This space answers your health questions from our liver-care expert,
  //         which you may usually be hesitant to ask a doctor or not know about
  //         and access this information digitally from the comfort of your home
  //         without losing consuming much of your time. You can also connect with
  //         other community members with similar experiences and share and learn
  //         from each other.
  //       </Collapsible>
  //       <Collapsible triggerText="Will I be able to watch these videos in my comfortable language?">
  //         LCUBE community has separate channels in both Tamil and English. You
  //         can feel free to watch videos in any of those languages you are
  //         comfortable with.
  //       </Collapsible>
  //       <Collapsible triggerText="Can I bring a family member or friend to LCUBE community?">
  //         You are welcome to bring a family member or friend to join LCUBE
  //         community and gain access to essential resources and information on
  //         liver health.
  //       </Collapsible>
  //       <Collapsible triggerText="Are there any fees or charges associated with using LCUBE Community?">
  //         No. This is a free and open space for all users to join, comfortably
  //         connect, participate with others, and build their support network.
  //       </Collapsible>
  //       <Collapsible triggerText="What kind of topics can I expect to see on LCUBE Community?">
  //         LCUBE Community covers a diverse range of liver health topics, from
  //         liver transplantation, liver disease, fatty liver, pediatric liver
  //         disease, women and liver disease, and many more.
  //       </Collapsible>
  //       <Collapsible triggerText="How do I request information on a specific topic I want to know, within the community?">
  //         You can either post your request in the chatrooms directly or comment
  //         on your questions or topics in video comments, and our doctor will
  //         answer it for you through upcoming videos.
  //       </Collapsible>
  //       <hr />
  //     </>
  //   );
  // } else if (hiveDetails?.communityId === 22) {
  //   return (
  //     <>
  //       <Collapsible triggerText="What is Mind&Mom Community?">
  //         Mind&Mom Community is a safe platform for women to connect, share
  //         their experiences, and receive support and advice on their fertility
  //         journey. This space also provides necessary infotainment bites through
  //         short video collections on specific topics on different channels.
  //       </Collapsible>
  //       <hr></hr>
  //       <Collapsible triggerText="How do I join the Mind&Mom Community?">
  //         You can download the Mind&Mom app from the App Store or Google Play
  //         Store, create an account by signing up and join the community or you
  //         can directly join from Mind&Mom website.
  //       </Collapsible>
  //       <hr></hr>
  //       <Collapsible triggerText="Are there any fees or charges associated with using the Mind&Mom Community?">
  //         No. This is a free and open space for all our users to join,
  //         comfortably connect, participate with other women, and build your
  //         support network.
  //       </Collapsible>
  //       <hr></hr>
  //       <Collapsible triggerText="What kind of topics can I expect to see on Mind&Mom Community?">
  //         Mind&Mom Community covers various women's health topics, from menarche
  //         to menopause, focusing more on women in their fertility journey right
  //         from trying to conceive, pregnancy, nutrition, wellness, fitness,
  //         mental health, fertility treatments, and more.
  //       </Collapsible>
  //       <hr></hr>
  //       <Collapsible triggerText="Can men join Mind&Mom Community?">
  //         Mind&Mom Community is specifically designed for women on their
  //         fertility journey to gain access to resources and connect with other
  //         women. However, men interested in learning more about fertility and
  //         supporting their partners are most welcome to join.
  //       </Collapsible>
  //       <hr></hr>
  //       <Collapsible triggerText="How can I connect with other women in Mind&Mom Community?">
  //         You can connect with other women in Mind&Mom Community by joining and
  //         actively engaging in all the chatrooms and commenting on videos.
  //       </Collapsible>
  //       <hr></hr>
  //       <Collapsible triggerText="Are there any experts on Mind&Mom Community who can answer my questions?">
  //         Yes, there are fertility experts and healthcare professionals in
  //         Mind&Mom Community who can provide valuable insights and answer all
  //         your doubts. Either you can post your questions in chatrooms, and the
  //         expert talks channel will give the answers or you can join live with
  //         our experts inside the community and get directly answered to your
  //         questions.
  //       </Collapsible>
  //       <hr></hr>
  //       <Collapsible triggerText="Is my privacy protected in Mind&Mom Community?">
  //         Yes, your privacy is important to us. Mind&Mom Community has strict
  //         privacy policy in place to ensure that your personal information and
  //         conversations are kept confidential.
  //       </Collapsible>
  //       <hr></hr>
  //       <Collapsible triggerText="Can I invite my other fertility friends and like-minded women on a similar journey to join this platform?">
  //         Yes, you can share our community platform with any of your friends in
  //         different phases of their fertility journey, whether they are trying
  //         to conceive, pregnant, new moms, or undergoing specific fertility
  //         treatments to conceive. This way, you let your dear ones acquire the
  //         benefits of valuable resources from the community that you’ve gained
  //         and also help them find additional support and connection that might
  //         be useful for their journey in a positive way.
  //       </Collapsible>
  //       <hr></hr>
  //       <Collapsible triggerText="What languages are available on Mind&Mom Community?">
  //         Mind&Mom community videos are mostly available in English, however if
  //         we get more number of requests for any specific Indian languages, we
  //         will add them too, in future.
  //       </Collapsible>
  //       <hr></hr>
  //       <Collapsible triggerText="What types of videos are available on Mind&Mom Community?">
  //         All videos available in our community is specifically curated in less
  //         than a minute to help users gain useful information without taking
  //         much of their time.
  //       </Collapsible>
  //       <hr></hr>
  //       <Collapsible triggerText="Can I access Mind&Mom Community on my desktop or laptop computer, or is it only available on mobile devices?">
  //         You can access Mind&Mom community from both desktop and mobile devices
  //         however we would recommend using it from mobile devices for more
  //         enriching experience.
  //       </Collapsible>
  //       <hr></hr>
  //       <Collapsible triggerText="Can I create my own chatroom or channel in Mind&Mom Community?">
  //         No. Only moderators would be able to create chatrooms or channels
  //         inside community. However, if you needed any specific topic to be
  //         discussed, you can type them in chatrooms and we will work on it.
  //       </Collapsible>
  //     </>
  //   );
  // } else if (hiveDetails?.communityId === 264) {
  //   return (
  //     <>
  //       <Collapsible triggerText="What is Wiseforum.ae?">
  //         Wiseforum.ae is a platform for entrepreneurship, innovation, and
  //         networking in the UAE.
  //       </Collapsible>
  //       <hr></hr>
  //       <Collapsible triggerText="Who can benefit from Wiseforum.ae?">
  //         Wiseforum.ae is beneficial for aspiring entrepreneurs, startup
  //         founders, business professionals, and anyone interested in
  //         entrepreneurship and innovation.
  //       </Collapsible>
  //       <hr></hr>
  //       <Collapsible triggerText="What is the Wea Talks Channel?">
  //         The Wea Talks Channel is a section on Wiseforum.ae featuring inspiring
  //         talks on entrepreneurship and innovation.
  //       </Collapsible>
  //       <hr></hr>
  //       <Collapsible triggerText="How can I access the Wea Talks Channel?">
  //         Visit the Wiseforum.ae website and navigate to the Wea Talks Channel
  //         section.
  //       </Collapsible>
  //       <hr></hr>
  //       <Collapsible triggerText="What topics are covered in the Wea Talks Channel?">
  //         The Wea Talks Channel covers various topics related to
  //         entrepreneurship, innovation, and business strategies.
  //       </Collapsible>
  //       <hr></hr>
  //       <Collapsible triggerText="Are the talks free to watch?">
  //         Yes, the talks on the Wea Talks Channel are available for free.
  //       </Collapsible>
  //       <hr></hr>
  //     </>
  //   );
  // } else if (hiveDetails?.communityId === 313) {
  //   return (
  //     <>
  //       <Collapsible triggerText="What is MENAcxo Community about?">
  //         MENAcxo Community is where professionals from MENA gather to share
  //         insights, collaborate, and innovate in various industries.
  //       </Collapsible>
  //       <hr></hr>
  //       <Collapsible triggerText="How should members interact?">
  //         Be respectful and kind. No hate speech or harassment. Let's keep
  //         conversations positive and inclusive.
  //       </Collapsible>
  //       <hr></hr>
  //       <Collapsible triggerText="What can we discuss here?">
  //         Talk about industry trends, leadership, career growth, and regional
  //         business insights. Share your expertise and seek advice.
  //       </Collapsible>
  //       <hr></hr>
  //       <Collapsible triggerText="How can members network and collaborate?">
  //         Connect, exchange ideas, and explore collaborations. Attend events and
  //         engage in discussions to expand your network.
  //       </Collapsible>
  //       <hr></hr>
  //       <Collapsible triggerText="What if there's an issue?">
  //         Report any content or behavior that breaks our guidelines. Your
  //         feedback helps keep our community safe.
  //       </Collapsible>
  //       <hr></hr>
  //       <Collapsible triggerText="Can I share my own work?">
  //         Yes! Share your accomplishments, projects, or relevant articles. Just
  //         keep self-promotion balanced.{" "}
  //       </Collapsible>
  //       <hr></hr>
  //       <Collapsible triggerText="How can I participate in events?">
  //         Watch for announcements. We'll share webinars, workshops, and
  //         networking opportunities. Your active involvement is encouraged.
  //       </Collapsible>
  //       <hr></hr>
  //       <Collapsible triggerText="How can I give suggestions or feedback?">
  //         Share your thoughts with our moderators. Your input helps shape the
  //         future of the community.
  //       </Collapsible>
  //     </>
  //   );
  // } else if (hiveDetails?.communityId === 294) {
  //   return (
  //     <>
  //       <Collapsible triggerText="Who we are">
  //         Team Enteprise is a technology consulting and development services
  //         company based in Dubai, United Arab Emirates. Specialized in immersive
  //         experiences, we aim to become the leaders in the Middle East to
  //         provide userfriendly, engaging and culturally significant content
  //         where we bridge tradtion, technology and the future.
  //       </Collapsible>
  //       <hr></hr>
  //       <Collapsible triggerText="Services">
  //         Build Metaverse worlds<br></br> VR/AR/MR solutions for trainings and
  //         events<br></br>
  //         Game Development on Unreal and Unity engines<br></br> 3D Visualization
  //         for real smart cities, real estate.
  //       </Collapsible>
  //       <hr></hr>
  //       <Collapsible triggerText="Mission">
  //         Create captivating and culturally relevant experiences that engage and
  //         inspire users. To be the leading provider of immersive experiences in
  //         the Middle East, helping both businesses and individuals connect with
  //         their customers and audiences in new and innovative ways. Visually
  //         stunning content powered by Blockchain and AI to ensure the security
  //         and privacy of users.
  //       </Collapsible>
  //       <hr></hr>
  //       <Collapsible triggerText="Vision">
  //         A future where everyone can experience the world in a more immersive
  //         and engaging way. The platform of choice where both businesses and
  //         individuals to connect with their customers. A world where the
  //         possibilities of immersive experiences are endless.
  //       </Collapsible>
  //     </>
  //   );
  // } else {
  //   return (
  //     <>
  //       <Collapsible triggerText="What is a hive?">
  //         A hive is a group of organized channels, each of which contains a
  //         collection of short videos on a specific topic or theme.
  //       </Collapsible>
  //       <hr></hr>
  //       <Collapsible triggerText="What is a channel?">
  //         The channels within a hive are carefully curated to provide viewers
  //         with a focused and informative experience.
  //       </Collapsible>
  //       <hr></hr>
  //       <Collapsible triggerText="How to create a hive?">
  //         To create a hive on veehive.ai:
  //         <br></br>• Go to create.veehive.ai and sign up.<br></br>
  //         <br></br> • During the onboarding process, you will be asked to
  //         provide information about your hive, including its name, webapp link,
  //         and whether it is public or private. <br></br>
  //         <br></br>• You will also be asked to provide a description for your
  //         hive and invite members. <br></br>
  //         <br></br>• Once you have completed these steps, you can access your
  //         hive by clicking on "Go to web app" on the final page.{" "}
  //       </Collapsible>
  //       <hr></hr>
  //       <Collapsible triggerText="How to edit header image?">
  //         When you click on the pen icon on the header image, a pop-up window
  //         will appear asking you to upload an image of a specific size.
  //       </Collapsible>
  //       <hr></hr>
  //       <Collapsible triggerText="How to edit hive details?">
  //         When you click on the pen icon located next to your hive name, a
  //         pop-up window will appear, allowing you to edit various aspects of
  //         your hive, including the name, URL, profile image, description, and
  //         the ability to upload an introduction video.
  //       </Collapsible>
  //       <hr></hr>
  //       <Collapsible triggerText="How to view the home page as a member if I am a super admin?">
  //         To view the home page as a member, click on the "View as Member"
  //         button. This will cause the button to change to "View as Super Admin"
  //         which you can click on to switch back to the super admin view.
  //       </Collapsible>
  //       <hr></hr>
  //       <Collapsible triggerText="How to add members?">
  //         You can add members to your channels by clicking on the "+Add Users"
  //         button. Doing so will bring up a pop-up window where you can enter the
  //         information of the members you wish to add.
  //       </Collapsible>
  //       <hr></hr>
  //       <Collapsible triggerText="How to create channel?">
  //         To create a new channel, click on the "Create Channel" tab with the
  //         "+" sign next to the "Introduction" channel. This will open a pop-up
  //         window where you can enter the details for your new channel and create
  //         it.
  //       </Collapsible>
  //       <hr></hr>
  //       <Collapsible triggerText="How to upload video?">
  //         To upload a video, click on the "+Upload Video" button. This will
  //         bring up a pop-up window where you can fill in the details for the
  //         video you wish to upload.
  //       </Collapsible>
  //       <hr></hr>
  //       <Collapsible triggerText="How can I view analytics of my hive?">
  //         To view analytics details of your hive, click on the "Analytics" tab
  //         located next to the "Home" tab. This tab is still work in progress and
  //         it will be ready soon for your use.
  //       </Collapsible>
  //     </>
  //   );
  // }
};

export default HiveGuidelines;
