import { useAppDispatch, useAppSelector } from "hooks/redux";
import React, { useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import LineBreak from "components/LineBreak";
import {
  getCurrentRedirectLinkSelector,
  getCurrentRedirectOriginSelector,
  getPurchaseChannelListSelector,
  getRedirectDayWiseListSelector,
  getRedirectLinksSelector,
  getRedirectLocationsSelector,
  getRedirectOriginsSelector,
  setAnalyticsTab,
  setCurrentChannelId,
  setCurrentRedirectLink,
  setCurrentRedirectOrigin,
} from "store/reducers/Analytics/analytics";
import LongText from "components/LongText/LongText";
import { PurchaseChannelModel } from "api/models/Analytics/metrics";
import { useNavigate } from "react-router-dom";
import { getHiveUuid } from "store/reducers/HiveDetails/hiveDetails";
import {
  getRedirectDayWiseList,
  getRedirectLinkList,
  getRedirectLocationList,
} from "store/async-actions/Analytics/analyticsActions";
import BackButton from "components/BackButton";
import { BackBtnSVG } from "components/SVG/SVG";
import moment from "moment";

interface Props {
  setStep: any;
}

const RedirectLocationsTable = ({ setStep }: Props) => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const hiveUuid = useAppSelector(getHiveUuid);

  const redirectLink = useAppSelector(getCurrentRedirectLinkSelector);
  const redirectOrigin = useAppSelector(getCurrentRedirectOriginSelector);

  useEffect(() => {
    dispatch(setAnalyticsTab(3));

    if (hiveUuid) {
      dispatch(
        getRedirectLocationList({
          organizationUuid: hiveUuid,
          redirect: redirectLink,
          origin: redirectOrigin,
        })
      );
      dispatch(
        getRedirectDayWiseList({
          organizationUuid: hiveUuid,
          redirect: redirectLink,
          origin: redirectOrigin,
        })
      );
    }

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, hiveUuid]);

  const redirectLocations = useAppSelector(getRedirectLocationsSelector);
  const redirectDayWise = useAppSelector(getRedirectDayWiseListSelector);

  const prevStep = () => {
    setStep(1);
  };

  return (
    <>
      <LineBreak />
      <div className="pointer" onClick={prevStep}>
        <BackBtnSVG />
      </div>
      <LineBreak />
      {!!redirectLocations && (
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>
                  {" "}
                  <p className="bold_heading_table">Country</p>
                </TableCell>
                <TableCell>
                  {" "}
                  <p className="bold_heading_table">State</p>
                </TableCell>
                <TableCell>
                  {" "}
                  <p className="bold_heading_table">City</p>
                </TableCell>
              </TableRow>
            </TableHead>
            {redirectLocations.map((row, idx) => (
              <TableBody>
                <TableRow
                  className="table_cell_hover"
                  key={idx}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {row.ipCountry} ({row.cityCount})
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.ipState}({row.cityCount})
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.ipCity}({row.cityCount})
                  </TableCell>
                </TableRow>
              </TableBody>
            ))}
          </Table>
        </TableContainer>
      )}
      <LineBreak />
      <h3>Day Wise analytics</h3>
      <LineBreak />
      {!!redirectDayWise && (
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>
                  {" "}
                  <p className="bold_heading_table">Date</p>
                </TableCell>
                <TableCell>
                  {" "}
                  <p className="bold_heading_table">Clicks</p>
                </TableCell>
              </TableRow>
            </TableHead>
            {redirectDayWise.map((row, idx) => (
              <TableBody>
                <TableRow
                  className="table_cell_hover"
                  key={idx}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {moment(row.interactionDate).format("LL")}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.sessionCount}
                  </TableCell>
                </TableRow>
              </TableBody>
            ))}
          </Table>
        </TableContainer>
      )}
    </>
  );
};

export default RedirectLocationsTable;
