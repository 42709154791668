import { useAppDispatch, useAppSelector } from "hooks/redux";
import React, { useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import LineBreak from "components/LineBreak";
import {
  getCurrentRedirectLinkSelector,
  getPurchaseChannelListSelector,
  getRedirectLinksSelector,
  getRedirectOriginsSelector,
  setAnalyticsTab,
  setCurrentChannelId,
  setCurrentRedirectLink,
  setCurrentRedirectOrigin,
} from "store/reducers/Analytics/analytics";
import LongText from "components/LongText/LongText";
import { PurchaseChannelModel } from "api/models/Analytics/metrics";
import { useNavigate } from "react-router-dom";
import { getHiveUuid } from "store/reducers/HiveDetails/hiveDetails";
import {
  getRedirectLinkList,
  getRedirectOriginList,
} from "store/async-actions/Analytics/analyticsActions";
import BackButton from "components/BackButton";
import { BackBtnSVG } from "components/SVG/SVG";

interface Props {
  setStep: any;
}

const RedirectOriginsTable = ({ setStep }: Props) => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const hiveUuid = useAppSelector(getHiveUuid);

  const redirectLink = useAppSelector(getCurrentRedirectLinkSelector);

  useEffect(() => {
    dispatch(setAnalyticsTab(3));

    if (hiveUuid) {
      dispatch(
        getRedirectOriginList({
          organizationUuid: hiveUuid,
          redirect: redirectLink,
        })
      );
    }

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, hiveUuid]);

  const redirectOrigins = useAppSelector(getRedirectOriginsSelector);

  const handleClick = (origin: string) => {
    dispatch(setCurrentRedirectOrigin(origin));
    setStep(2);
  };

  const prevStep = () => {
    setStep(0);
  };

  return (
    <>
      <LineBreak />
      <div className="pointer" onClick={prevStep}>
        <BackBtnSVG />
      </div>
      <LineBreak />
      {!!redirectOrigins && (
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>
                  {" "}
                  <p className="bold_heading_table">Sr No.</p>
                </TableCell>
                <TableCell>
                  {" "}
                  <p className="bold_heading_table">Origin</p>
                </TableCell>
                <TableCell>
                  {" "}
                  <p className="bold_heading_table">Clicks</p>
                </TableCell>
              </TableRow>
            </TableHead>
            {redirectOrigins.map((row, idx) => (
              <TableBody>
                <TableRow
                  onClick={() => handleClick(row.origin)}
                  className="table_cell_hover pointer"
                  key={idx}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {idx + 1}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {<LongText title={row.origin} cutoff={36} /> || "-"}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.originCount}
                  </TableCell>
                </TableRow>
              </TableBody>
            ))}
          </Table>
        </TableContainer>
      )}
    </>
  );
};

export default RedirectOriginsTable;
