import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { createChannel } from "store/async-actions/Hive/hivePage";
import { createTypedSelector } from "store/utils";

const initialState: {
  compressedLogoUrl: string;
  compressedMobileLogoUrl: string;
  compressedBannerUrl: string;
  compressedEmailBannerUrl: string;
  imageUploaded: boolean;
  videoMaxDuration: number;
  isPrivate: boolean;
  isAds: boolean;
  isPaid: boolean;
  amount: string;
  isFetching: boolean;
  isDone: boolean;
  urlChanged: boolean;
  bannerUploaded: boolean;
  bannerUrlChanged: boolean;
  emailBannerUploaded: boolean;
  emailBannerUrlChanged: boolean;
  emailBannerFileBlob: File | null;
} = {
  urlChanged: false,
  compressedLogoUrl: "",
  compressedMobileLogoUrl: "",
  compressedBannerUrl: "",
  compressedEmailBannerUrl: "",
  imageUploaded: false,
  videoMaxDuration: 0,
  isPrivate: false,
  isAds: false,
  isPaid: false,
  amount: "",
  isFetching: false,
  isDone: false,
  bannerUploaded: false,
  bannerUrlChanged: false,
  emailBannerUploaded: false,
  emailBannerUrlChanged: false,
  emailBannerFileBlob: null,
};

const createChannelSlice = createSlice({
  name: "createChannel",
  initialState,
  reducers: {
    setLogoFile: (state, action: PayloadAction<string>) => {
      state.compressedLogoUrl = action.payload;
      state.imageUploaded = true;
      state.urlChanged = true;
    },
    setMobileLogoFile: (state, action: PayloadAction<string>) => {
      state.compressedMobileLogoUrl = action.payload;
      state.urlChanged = true;
    },
    setEmailBannerFileBlob: (state, action: PayloadAction<File | null>) => {
      state.emailBannerFileBlob = action.payload;
    },
    setUrlChanged: (state, action: PayloadAction<boolean>) => {
      state.urlChanged = action.payload;
    },
    setBannerUrlChanged: (state, action: PayloadAction<boolean>) => {
      state.bannerUrlChanged = action.payload;
    },
    setEmailBannerUrlChanged: (state, action: PayloadAction<boolean>) => {
      state.emailBannerUrlChanged = action.payload;
    },
    setBannerFile: (state, action: PayloadAction<string>) => {
      state.compressedBannerUrl = action.payload;
      state.bannerUrlChanged = true;
      state.bannerUploaded = true;
    },
    setEmailBannerFile: (state, action: PayloadAction<string>) => {
      state.compressedEmailBannerUrl = action.payload;
      state.emailBannerUrlChanged = true;
      state.emailBannerUploaded = true;
    },
    setImageUploaded: (state, action: PayloadAction<boolean>) => {
      state.imageUploaded = action.payload;
    },
    setBannerUploaded: (state, action: PayloadAction<boolean>) => {
      state.bannerUploaded = action.payload;
    },
    setEmailBannerUploaded: (state, action: PayloadAction<boolean>) => {
      state.emailBannerUploaded = action.payload;
    },
    setVideoDuration: (state, action: PayloadAction<number>) => {
      state.videoMaxDuration = action.payload;
    },
    setIsPaid: (state, action: PayloadAction<boolean>) => {
      state.isPaid = action.payload;
    },
    setIsAds: (state, action: PayloadAction<boolean>) => {
      state.isAds = action.payload;
    },
    setIsPrivate: (state, action: PayloadAction<boolean>) => {
      state.isPrivate = action.payload;
    },
    setIsDone: (state, action: PayloadAction<boolean>) => {
      state.isDone = action.payload;
    },
    setChannelAmount: (state, action: PayloadAction<string>) => {
      state.amount = action.payload;
    },
  },
  extraReducers: {
    [createChannel.pending.type]: (state) => {
      state.isDone = false;
      state.isFetching = true;
    },
    [createChannel.fulfilled.type]: (state, action: PayloadAction<boolean>) => {
      state.isDone = action.payload;
      state.isFetching = false;
    },
    [createChannel.rejected.type]: (state) => {
      state.isDone = false;
      state.isFetching = false;
    },
  },
});

export const {
  setLogoFile,
  setMobileLogoFile,
  setUrlChanged,
  setBannerUrlChanged,
  setBannerUploaded,
  setEmailBannerFile,
  setEmailBannerUploaded,
  setEmailBannerUrlChanged,
  setEmailBannerFileBlob,
} = createChannelSlice.actions;
export const { setBannerFile } = createChannelSlice.actions;
export const { setImageUploaded } = createChannelSlice.actions;

export const {
  setIsAds,
  setIsPaid,
  setIsPrivate,
  setVideoDuration,
  setChannelAmount,
  setIsDone,
} = createChannelSlice.actions;

export const getBannerFileUrlSelector = createTypedSelector(
  (state) => state.createChannel.compressedBannerUrl
);

export const getEmailBannerFileSelector = createTypedSelector(
  (state) => state.createChannel.emailBannerFileBlob
);

export const getEmailBannerFileUrlSelector = createTypedSelector(
  (state) => state.createChannel.compressedEmailBannerUrl
);

export const getLogoFileUrlSelector = createTypedSelector(
  (state) => state.createChannel.compressedLogoUrl
);

export const getMobileLogoFileUrlSelector = createTypedSelector(
  (state) => state.createChannel.compressedMobileLogoUrl
);

export const getImageUploadedSelector = createTypedSelector(
  (state) => state.createChannel.imageUploaded
);

export const getBannerUploadedSelector = createTypedSelector(
  (state) => state.createChannel.bannerUploaded
);

export const getEmailBannerUploadedSelector = createTypedSelector(
  (state) => state.createChannel.emailBannerUploaded
);

export const getUrlChangedSelector = createTypedSelector(
  (state) => state.createChannel.urlChanged
);
export const getBannerUrlChangedSelector = createTypedSelector(
  (state) => state.createChannel.bannerUrlChanged
);
export const getEmailBannerUrlChangedSelector = createTypedSelector(
  (state) => state.createChannel.emailBannerUrlChanged
);

export const getCreateVideoMaxDurationSelector = createTypedSelector(
  (state) => state.createChannel.videoMaxDuration
);
export const getCreateIsPaidSelector = createTypedSelector((state) => {
  return state.createChannel.isPaid;
});
export const getCreateIsAdsSelector = createTypedSelector((state) => {
  return state.createChannel.isAds;
});
export const getCreateIsPrivateSelector = createTypedSelector((state) => {
  return state.createChannel.isPrivate;
});
export const getCreateAmountSelector = createTypedSelector(
  (state) => state.createChannel.amount
);

export const createChannelIsDoneSelector = createTypedSelector(
  (state) => state.createChannel.isDone
);
export const createChannelIsFetchingSelector = createTypedSelector(
  (state) => state.createChannel.isFetching
);

export default createChannelSlice.reducer;
