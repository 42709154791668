import FileDrop from "components/FileDrop/FileDrop";
import { CheckmarkSVG } from "components/SVG/SVG";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import React, { useEffect } from "react";
import ReactPlayer from "react-player";
import { removeVideoFile } from "store/reducers/Videos/videos";

const SpotlightVideoSelect = () => {
  const file = useAppSelector((state) => state.videos.file);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(removeVideoFile());
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="video_select_container">
      {file === null ? (
        <FileDrop duration="90 seconds" />
      ) : (
        <div>
          <ReactPlayer
            width="270px"
            height="480px"
            url={URL.createObjectURL(file!)}
            controls
            style={{
              overflow: "hidden",
              borderRadius: "15px",
              background: "black",
            }}
          />
          <div
            className="video_change_status"
            onClick={() => {
              dispatch(removeVideoFile());
            }}
          >
            <div className="video_change_start">
              <CheckmarkSVG />
              <div className="hover_underline">Change Video</div>
            </div>
            <p className="secondary_text">
              {file.name.length > 10
                ? file.name.slice(0, 10) + "..."
                : file.name}
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default SpotlightVideoSelect;
