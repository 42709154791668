import IslandLayout from "components/IslandLayout";
import PageLayout from "components/PageLayout";
import React, { useEffect, useState } from "react";
import EditGuidelines from "./EditGuidelines";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import {
  getHiveGuidelinesSelector,
  getHiveUuid,
} from "store/reducers/HiveDetails/hiveDetails";
import BackButton from "components/BackButton";
import LineBreak from "components/LineBreak";
import { editHiveGuidelines } from "store/async-actions/Hive/hivePage";
import { toastSuccess } from "hooks/useToastify";

const GuidelinesContainer = () => {
  const hiveGuidelines = useAppSelector(getHiveGuidelinesSelector);

  const [guidelines, setGuidelines] = useState(hiveGuidelines);

  const dispatch = useAppDispatch();

  useEffect(() => {
    setGuidelines(hiveGuidelines);
  }, [hiveGuidelines]);

  const hiveUuid = useAppSelector(getHiveUuid);

  const handleEdit = () => {
    dispatch(
      editHiveGuidelines({
        guidelinesList: guidelines,
        organizationUuid: hiveUuid,
      })
    );
    toastSuccess("Guidelines Edited successfully");
  };

  return (
    <div className="page_padding">
      <BackButton to="/home" />
      <LineBreak />
      <h2>Edit Guidelines</h2>
      <EditGuidelines guidelines={guidelines} setGuidelines={setGuidelines} />
      <div className="nextBtn">
        <div onClick={handleEdit} className="primaryBtn">
          Save
        </div>
      </div>
    </div>
  );
};

export default GuidelinesContainer;
