import LinearProgress from "@mui/material/LinearProgress";
import AnalyticsCapsule from "components/AnalyticsCapsule";
import LineBreak from "components/LineBreak";
import { useAppSelector, useAppDispatch } from "hooks/redux";
import React, { useEffect } from "react";
import { getUsageData } from "store/async-actions/Analytics/analyticsActions";
import { getUsageDataSelector } from "store/reducers/Analytics/analytics";
import { getHiveSelector } from "store/reducers/HiveDetails/hiveDetails";

const convertBytes = (bytes: number): string => {
  const inGb = bytes / 1000000000;
  if (inGb < 1) return (inGb * 1000).toFixed(2) + " MB";
  return inGb.toFixed(2) + " GB";
};

const checkProgress = (used: number): number => {
  return (used / 50000000000) * 100;
};

const ContentUsage = () => {
  return (
    <div className="usage_data_container">
      <AnalyticsCapsule>
        <div className="headers_and_value">
          <h5>Streaming Data</h5>
          <div>
            <h2>
              {12}
              {" / 50 GB"}
              <b className="text-sm"> used</b>
            </h2>
            <LineBreak />
            <LinearProgress variant="determinate" value={24} color="primary" />
          </div>
        </div>
      </AnalyticsCapsule>
      <AnalyticsCapsule>
        <div className="headers_and_value">
          <h5>Storage Data</h5>
          <div>
            <h2>
              {8}
              {" / 50 GB"}
              <b className="text-sm"> used</b>
            </h2>
            <LineBreak />
            <LinearProgress variant="determinate" value={16} color="primary" />
          </div>
        </div>
      </AnalyticsCapsule>
    </div>
  );
};

export default ContentUsage;
