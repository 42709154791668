import LineBreak from "components/LineBreak/LineBreak";
import { CheckmarkSVGlg } from "components/SVG/SVG";
import { useAppDispatch } from "hooks/redux";
import React from "react";
import { useNavigate } from "react-router-dom";
import { setEventPaymentCharged } from "store/reducers/HiveDetails/events";

interface Props {
  message?: string;
  onDone?: any;
}

const PaymentCompleted = ({ message, onDone }: Props) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handleExplore = () => {
    !!onDone ? dispatch(onDone) : dispatch(setEventPaymentCharged(false));
    navigate("/home");
  };

  return (
    <div className="plan_completed_container">
      <div className="flex justify-center items-center">
        <CheckmarkSVGlg />
      </div>
      <h2 className="font-bold">Confirmation</h2>
      <div className="font-light">
        {message
          ? message
          : "Success! Your subscription is now active. Dive in and explore all the premium features available to you."}
      </div>
      <div onClick={handleExplore} className="primaryBtn">
        Explore
      </div>
    </div>
  );
};

export default PaymentCompleted;
