import React, { useEffect } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import {
  getActiveChannelUuidSelector,
  getVideosCurrentPageSelector,
  setVideosTab,
  videosTabSelector,
} from "store/reducers/ChannelDetails/channelList";
import { getVideoTabs } from "store/reducers/ChildComponents/childComponents";
import { getVideoList } from "store/async-actions/Videos/videosActions";
import { getTotalPendingVideosSelector } from "store/reducers/Videos/videoList";

const VideoTabs = () => {
  const videoTabs = useAppSelector(getVideoTabs);
  const channelUuid = useAppSelector(getActiveChannelUuidSelector);

  const activeTab = useAppSelector(videosTabSelector);

  const pageNo = useAppSelector(getVideosCurrentPageSelector);

  const pendingVideos = useAppSelector(getTotalPendingVideosSelector);

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setVideosTab(activeTab));
  }, [dispatch]);

  const selectedTab = useAppSelector(videosTabSelector);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    dispatch(setVideosTab(newValue));
    dispatch(
      getVideoList({
        channelUuid: channelUuid!,
        page: pageNo,
        noOfRecords: 12,
        status:
          videoTabs[newValue].componentName === "Videos"
            ? "Ready"
            : videoTabs[newValue].componentName,
      })
    );
    // dispatch(
    //   getChildComponents({
    //     organizationId: hiveDetails?.communityId!,
    //     parentComponentCode:
    //       videoTabs[newValue].componentName === "Videos"
    //         ? "Ready"
    //         : videoTabs[newValue].componentName,
    //     channelUuid: channelUuid,
    //     isMemberView: isMemberView,
    //   })
    // );
  };

  return (
    <Tabs value={selectedTab} onChange={handleChange} aria-label="video tabs">
      {videoTabs.map((tab, idx) => {
        return (
          <Tab
            style={{
              width: "25%",
              height: "20px",
              fontFamily: "IBM Plex Sans Condensed",
              fontStyle: "normal",
              fontWeight: 600,
              fontSize: "14px",
              lineHeight: "140%",
              textAlign: "center",
              letterSpacing: "0.02em",
              color: "#1C1B1F",
            }}
            key={idx}
            label={
              <div className="vid_tab_display">
                {tab.componentName}
                {tab.componentName === "Pending" && !!pendingVideos && (
                  <span className="readed_tab_badge" />
                )}
              </div>
            }
          />
        );
      })}
    </Tabs>
  );
};

export default VideoTabs;
