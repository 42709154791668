import { VideoListItem } from "api/models/Videos/videoList";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import VideoKebab from "pages/Channel/ChannelVideo/components/VideoKebab";
import React from "react";
import { useNavigate } from "react-router-dom";
import { getVideoTabs } from "store/reducers/ChildComponents/childComponents";
import { setFeedCurrentVideo } from "store/reducers/Feed/feed";
import { launchLogin } from "store/reducers/HiveDetails/hiveDetails";
import { setCurrentVideo } from "store/reducers/Videos/videoList";

interface Props {
  videoItem: VideoListItem;
}

const VideoItem = ({ videoItem }: Props) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const videoTabs = useAppSelector(getVideoTabs);

  const handleClick = () => {
    dispatch(setCurrentVideo(videoItem));
    if (localStorage.getItem("isLoggedIn") === "yes") {
      navigate(videoItem.route);
    } else {
      launchLogin();
    }
  };

  return (
    <div className="video_item_container relative">
      <img
        alt=""
        className="video_card"
        onClick={handleClick}
        src={
          videoItem.thumbnail ||
          "https://veehivestage-images.s3.us-east-2.amazonaws.com/channelImages/defaultChannelLogo.jpg"
        }
      />
      <p className="video_title black_text">
        {!!videoItem &&
        !!videoItem.videoTitle &&
        videoItem.videoTitle.length > 14
          ? videoItem.videoTitle!.slice(0, 14) + "..."
          : videoItem.videoTitle}
      </p>
      {videoTabs && videoTabs.length > 1 && (
        <div className="video_kebab_container">
          <VideoKebab currentVideo={videoItem} />
        </div>
      )}
    </div>
  );
};

export default VideoItem;
