import Papa from "papaparse";
import React, {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import { toastInfo, toastWarn } from "hooks/useToastify";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import {
  getImportCountSelector,
  setCSVErrorMessage,
  setCSVSuccessMessage,
  setImportCount,
} from "store/reducers/HiveDetails/hiveActivities";
import { CircularProgress } from "@mui/material";
import { updatePhoneUsers } from "store/reducers/HiveDetails/hiveSettings";

interface Props {
  selectedTab: number;
  emails: string[];
  setPhoneNos: Dispatch<SetStateAction<string[]>>;
  phoneNos: string[];
  setEmails?: Dispatch<SetStateAction<string[]>>;
}

const CSVImport = ({
  selectedTab,
  emails,
  phoneNos,
  setEmails,
  setPhoneNos,
}: Props) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setCSVErrorMessage(""));
    dispatch(setCSVSuccessMessage(""));
    dispatch(setImportCount(0));
  }, [dispatch]);

  const importCount = useAppSelector(getImportCountSelector);

  const [isLoading, setIsLoading] = useState(false);

  const [count, setCount] = useState(0);

  const csvImportHandler = (event: ChangeEvent<HTMLInputElement>) => {
    // Passing file data (event.target.files[0]) to parse using Papa.parse
    if (
      event.target.files &&
      event.target.files[0] &&
      event.target.files![0].type === "text/csv"
    ) {
      setIsLoading(true);
      dispatch(setCSVErrorMessage(""));
      Papa.parse(event.target.files![0], {
        header: true,
        skipEmptyLines: true,
        complete: function (results: any) {
          let data = results.data;
          // if (data.length < 101) {
          if (selectedTab === 0) {
            try {
              data.map((row: any, index: number) => {
                if (row.email) {
                  if (setEmails !== undefined) {
                    const tempEmails = [...emails];
                    tempEmails.push(row.email);
                    emails.push(row.email);
                    setEmails(tempEmails);
                  } else {
                    emails.push(row.email);
                  }
                  dispatch(
                    setCSVSuccessMessage(
                      `Added ${data.length} emails for Invitation, Please proceed or add more.`
                    )
                  );
                  dispatch(setCSVErrorMessage(""));
                  if (index < 1) {
                    dispatch(setImportCount(importCount + data.length));
                  }
                } else {
                  dispatch(
                    setCSVErrorMessage(
                      `Make sure your .csv file has an "email" column`
                    )
                  );
                  dispatch(setCSVSuccessMessage(""));
                }
              });
            } catch {
              dispatch(
                setCSVErrorMessage(
                  `Make sure your .csv file has an "email" column header under which all user email id's are given`
                )
              );
              dispatch(setCSVSuccessMessage(""));
            }
          } else {
            try {
              data.map((row: any, index: number) => {
                if (row.mobileNo) {
                  const number = /^-?\d+$/;
                  if (row.mobileNo.startsWith("+")) {
                    if (number.test(row.mobileNo.slice(1))) {
                      if (row.mobileNo.length > 9 && row.mobileNo.length < 15) {
                        const tempPhoneNos = [...phoneNos];

                        tempPhoneNos.push(row.mobileNo);
                        phoneNos = [...tempPhoneNos];
                        setPhoneNos(tempPhoneNos);
                        setCount((prevState) => prevState + 1);

                        // dispatch(
                        //   updatePhoneUsers({
                        //     mobileNo: row.mobileNo,
                        //     userOrigin: row.userOrigin || "",
                        //     userName: row.userName || "",
                        //   })
                        // );

                        dispatch(
                          setCSVSuccessMessage(
                            `Added ${data.length} phone numbers for Invitation, Please proceed or add more.`
                          )
                        );
                        dispatch(setCSVErrorMessage(""));
                      } else {
                        toastWarn("Number length should be 10");
                      }
                    } else {
                      toastWarn("only numbers allowed");
                    }
                  } else {
                    toastWarn("Number should start with '+'");
                  }

                  if (index < 1) {
                    dispatch(setImportCount(importCount + data.length));
                  }
                } else {
                  dispatch(
                    setCSVErrorMessage(
                      `Make sure your .csv file has an "mobileNo" column`
                    )
                  );
                  dispatch(setCSVSuccessMessage(""));
                }
              });
            } catch {
              dispatch(
                setCSVErrorMessage(
                  `Make sure your .csv file has an "mobileNo" column header under which all user mobile numbers are given`
                )
              );
              dispatch(setCSVSuccessMessage(""));
            }
          }
        },
        //   else {
        //     toastWarn(
        //       "Whoops, Thats a lot to take in, please upload max 100 rows at a time"
        //     );
        //   }
        // },
      });
    } else {
      dispatch(setCSVSuccessMessage(""));
      dispatch(
        setCSVErrorMessage(`INVALID FILE TYPE! Please upload .csv File`)
      );
    }
    setIsLoading(false);
  };

  return (
    <div className="flex flex-col">
      {/* File Uploader */}
      <input
        id="fileElem"
        onChange={csvImportHandler}
        type="file"
        name="file"
        accept=".csv"
        className="invisible"
        // style={{ display: "block", margin: "10px auto" }}
      />
      <div className="import_csv_btn">
        {isLoading ? (
          <CircularProgress size={20} color="inherit" />
        ) : (
          <label className="primaryBtn half_width" htmlFor="fileElem">
            Import .csv file
          </label>
        )}
      </div>
    </div>
  );
};

export default CSVImport;
