import { useAppDispatch, useAppSelector } from "hooks/redux";
import React from "react";
import { NavLink } from "react-router-dom";
import { scrollToTop } from "react-scroll/modules/mixins/animate-scroll";
import { store } from "store";
import { getChildComponents } from "store/async-actions/Hive/hivePage";
import { getHiveSelector } from "store/reducers/HiveDetails/hiveDetails";
import {
  getSelectedMenu,
  getTotalUnreadCountSelector,
  setSelectedMenu,
} from "store/reducers/HiveDetails/mainMenu";
import { scrollToTopSection } from "store/reducers/HiveDetails/navbar";

interface MENU_ITEM {
  componentType: string;
  componentCode: string;
  componentName: string;
  componentIcon: string;
  componentDescription: string;
  componentRoute: string;
}

interface Props {
  menuItem: MENU_ITEM;
}

const MenuItem = ({ menuItem }: Props) => {
  const selectedMenu = useAppSelector(getSelectedMenu);
  const hiveDetails = useAppSelector(getHiveSelector);

  const dispatch = useAppDispatch();

  const handleClick = (passedMenu: string) => {
    dispatch(setSelectedMenu(passedMenu));
    dispatch(
      getChildComponents({
        organizationId: hiveDetails?.communityId!,
        parentComponentCode: menuItem.componentName,
        channelUuid: null,
        isMemberView: store.getState().profile.isMemberView,
      })
    );
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const unreadMessages = useAppSelector(getTotalUnreadCountSelector);

  return (
    <NavLink
      onClick={() => handleClick(menuItem.componentName)}
      className="menu_item"
      to={menuItem.componentRoute}
    >
      <img alt="" src={menuItem.componentIcon} className="menu_icon" />
      <div
        className={`menu_item_ak ${
          selectedMenu === menuItem.componentName
            ? "selected_menu"
            : "menu_text"
        }`}
      >
        <div>{menuItem.componentName}</div>
        {menuItem.componentName === "Chat" && (
          <>{unreadMessages > 0 && <div className="readed_tab_badge" />}</>
        )}
      </div>
    </NavLink>
  );
};

export default MenuItem;
