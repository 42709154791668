import { useEffect, useState, useCallback } from "react";
import { useAppDispatch, useAppSelector } from "hooks/redux";

import io from "socket.io-client";
import { addNewBotMessage, addNewMessage } from "store/reducers/Chat/chat";
import { profileUserIdSelector } from "store/reducers/Profile/profile";
import { getHiveUuid } from "store/reducers/HiveDetails/hiveDetails";
import { setTotalUnreadCount } from "store/reducers/HiveDetails/mainMenu";

const SOCKET_URL = process.env.REACT_APP_SOCKET_URL as string;

interface UseSocketReturn {
  isConnected: boolean;
}

export const socket = io(SOCKET_URL, {
  autoConnect: false,
  transports: ["websocket"],
  reconnectionDelay: 10000,
});

export const useChatsNotifSocket = (): UseSocketReturn => {
  const dispatch = useAppDispatch();

  const [isConnected, setIsConnected] = useState<boolean>(socket.connected);

  const userId = useAppSelector(profileUserIdSelector); ///1746 the mock id

  const hiveUuid = useAppSelector(getHiveUuid);

  const handleGetNotification = useCallback(
    (newMessage: any) => {
      dispatch(setTotalUnreadCount(newMessage.chatRoomUnreadCount));
    },
    [dispatch]
  );

  useEffect(() => {
    if (!socket.connected) {
      // console.log("chat room connecting");
      socket.connect();
    }

    socket.on("connect", () => {
      // console.log("chat room connected");

      setIsConnected(socket.connected);
    });
    socket.on("disconnect", () => {
      // console.log("chat room disconnected");
      setIsConnected(socket.connected);
    });
    return () => {
      // console.log("chat room disconnect");
      socket.off("connect");
      socket.off("disconnect");
      socket.close();
    };
  }, []);

  useEffect(() => {
    if (isConnected) {
      // console.log("Subscribe: ", roomId, userId);
      socket.emit("mainsubscribe", {
        userId: userId,
        organizationUuid: hiveUuid,
      });

      socket.on("message", handleGetNotification);
    }

    return () => {
      if (isConnected) {
        // console.log("Unsubscribe: ", isConnected, roomId, userId);
        socket.off("message");
        socket.emit("mainunsubscribe", {
          userId: userId,
          organizationUuid: hiveUuid,
        });
      }
    };
  }, [isConnected, userId, handleGetNotification]);

  return {
    isConnected,
  };
};
