import React from "react";
import { Divide } from "hamburger-react";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import {
  getBurgerOpenSelector,
  setHamburgerState,
} from "store/reducers/Hamburger/hamburger";

const Hamburger = () => {
  const isOpen = useAppSelector(getBurgerOpenSelector);
  const dispatch = useAppDispatch();

  const handleToggle = () => {
    dispatch(setHamburgerState(!isOpen));
  };

  return (
    <div className="hamburger_logo">
      <Divide size={25} toggled={isOpen} toggle={handleToggle} />
    </div>
  );
};

export default Hamburger;
