import { Menu, MenuItem } from "@mui/material";
import React, { useState, useEffect } from "react";
import Fade from "@mui/material/Fade";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import {
  editChannelDetails,
  getChannelDetails,
} from "store/async-actions/Channels/channels";
import {
  getActiveChannelIdSelector,
  getActiveChannelUuidSelector,
  getVideosCurrentPageSelector,
  videosTabSelector,
} from "store/reducers/ChannelDetails/channelList";
import {
  channelObjSelector,
  getChannelIsEditedSelector,
  setIsEdited,
} from "store/reducers/ChannelDetails/channelDetails";
import { toastSuccess } from "hooks/useToastify";
import { getChannelList } from "store/async-actions/Hive/hivePage";
import { isMemberViewSelector } from "store/reducers/Profile/profile";
import { getVideoList } from "store/async-actions/Videos/videosActions";
import { getVideoTabs } from "store/reducers/ChildComponents/childComponents";

const SortVideo = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const openSortVids = Boolean(anchorEl);
  const handleSortVidsClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleSortVidsClose = () => {
    setAnchorEl(null);
  };

  const dispatch = useAppDispatch();

  const channelId = useAppSelector(getActiveChannelIdSelector);
  const channelUuid = useAppSelector(getActiveChannelUuidSelector);

  const isEdited = useAppSelector(getChannelIsEditedSelector);
  const isMemberView = useAppSelector(isMemberViewSelector);
  const channelObj = useAppSelector(channelObjSelector);

  const videoTabs = useAppSelector(getVideoTabs);
  const currentVideoTab = useAppSelector(videosTabSelector);

  const pageNo = useAppSelector(getVideosCurrentPageSelector);

  useEffect(() => {
    if (isEdited) {
      toastSuccess("Channel Details Saved");

      dispatch(
        getVideoList({
          channelUuid: channelUuid!,
          page: pageNo,
          noOfRecords: 12,
          status:
            videoTabs[currentVideoTab].componentName === "Videos"
              ? "Ready"
              : videoTabs[currentVideoTab].componentName,
        })
      );
      dispatch(setIsEdited(false));
    }
  }, [isEdited, dispatch]);

  const editChannelVideoSorting = (passedBool: boolean) => {
    dispatch(
      editChannelDetails({
        channelName: "",
        category: "",
        channelLogo: "",
        channelWebLogo: "",
        channelTier: "",
        uploadFlag: false,
        amount: 0,
        description: "",
        maxVideoLength: 0,
        channelId: channelId,
        channelNameFlag: false,
        categoryFlag: false,
        channelTierFlag: false,
        amountFlag: false,
        descriptionFlag: false,
        maxVideoLengthFlag: false,
        channelLogoFlag: false,
        channelWebLogoFlag: false,
        orderByDesc: !passedBool,
        adsRequired: false,
        ctaName: "",
        ctaNameFlag: false,
      })
    );
  };

  return (
    <div>
      <div onClick={handleSortVidsClick} className="flex items-center sortBtn">
        <svg
          width="17"
          height="12"
          viewBox="0 0 20 19"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M5 1.98047L5 17.9805M5 17.9805L9 13.9805M5 17.9805L0.999999 13.9805M15 17.9805L15 1.98047M15 1.98047L19 5.98047M15 1.98047L11 5.98047"
            stroke="black"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>

        <p className="pointer font-bold">Sort</p>
      </div>
      {/* </Button> */}
      <Menu
        id="fade-menu"
        MenuListProps={{
          "aria-labelledby": "fade-button",
        }}
        anchorEl={anchorEl}
        open={openSortVids}
        onClose={handleSortVidsClose}
        TransitionComponent={Fade}
      >
        <MenuItem
          onClick={() => {
            editChannelVideoSorting(false);

            handleSortVidsClose();
          }}
        >
          <p className="text-sm">Date created (new)</p>
        </MenuItem>
        <MenuItem
          onClick={() => {
            editChannelVideoSorting(true);
            handleSortVidsClose();
          }}
        >
          <p className="text-sm">Date created (old)</p>
        </MenuItem>
      </Menu>
    </div>
  );
};

export default SortVideo;
