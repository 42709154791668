import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { createTypedSelector } from "store/utils";

interface InitialState {
  isOpen: boolean;
}

const initialState: InitialState = { isOpen: false };

const burgerSlice = createSlice({
  name: "hamburger",
  initialState,
  reducers: {
    setHamburgerState: (state, action: PayloadAction<boolean>) => {
      state.isOpen = action.payload;
    },
  },
});

export const { setHamburgerState } = burgerSlice.actions;

export const getBurgerOpenSelector = createTypedSelector(
  (state) => state.hamburger.isOpen
);

export default burgerSlice.reducer;
