import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "api";
import { FeedRequestModel } from "api/models/Feed/Feed";
import {
  BasicFormReq,
  CreateFormReq,
  EditFormReq,
} from "api/models/Forms/forms";
import { AttendeeFormDetailsReq } from "api/models/Hive/events";
import { RootState } from "store";

export const createForm = createAsyncThunk(
  "form/createForm",
  async (body: CreateFormReq, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await api.forms.createForm(body);
      dispatch(getForms({ organizationUuid: body.organizationUuid }));
      return data.data;
    } catch (error) {
      return rejectWithValue("Something went wrong");
    }
  }
);

export const getForms = createAsyncThunk(
  "form/getForms",
  async (body: { organizationUuid: string }, { rejectWithValue }) => {
    try {
      const { data } = await api.forms.getForms(body);
      return data.data;
    } catch (error) {
      return rejectWithValue("Something went wrong");
    }
  }
);

export const getFormResponses = createAsyncThunk(
  "form/getFormResponses",
  async (body: BasicFormReq, { rejectWithValue }) => {
    try {
      const { data } = await api.forms.getFormResponses(body);
      return data.data;
    } catch (error) {
      return rejectWithValue("Something went wrong");
    }
  }
);

export const getFormDetails = createAsyncThunk(
  "form/getFormDetails",
  async (body: { formUuid: string }, { rejectWithValue }) => {
    try {
      const { data } = await api.forms.getFormDetails(body);
      return data.data;
    } catch (error) {
      return rejectWithValue("Something went wrong");
    }
  }
);

export const editFormDetails = createAsyncThunk(
  "form/editFormDetails",
  async (body: EditFormReq, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await api.forms.editForm(body);
      dispatch(getFormDetails({ formUuid: body.formUuid }));
      return data.data;
    } catch (error) {
      return rejectWithValue("Something went wrong");
    }
  }
);

export const submitForm = createAsyncThunk(
  "form/submitForm",
  async (
    body: { formUuid: string; formList: AttendeeFormDetailsReq[] },
    { rejectWithValue }
  ) => {
    try {
      const { data } = await api.forms.submitForm(body);
      return data.data;
    } catch (error) {
      return rejectWithValue("Something went wrong");
    }
  }
);

export const deleteForm = createAsyncThunk(
  "form/deleteForm",
  async (
    body: { formUuid: string },
    { rejectWithValue, dispatch, getState }
  ) => {
    try {
      const { data } = await api.forms.deleteForm(body);
      const { hive } = getState() as RootState;

      dispatch(
        getForms({
          organizationUuid: hive.hiveUuid,
        })
      );
      return data.data;
    } catch (error) {
      return rejectWithValue("Something went wrong");
    }
  }
);
