import Dialog from "@mui/material/Dialog";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import IslandLayout from "components/IslandLayout";
import PageLayout from "components/PageLayout";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { stripePaymentChargedSelector } from "store/reducers/ChannelDetails/payments";
import { isMemberViewSelector } from "store/reducers/Profile/profile";
import BackButton from "components/BackButton/BackButton";
import LineBreak from "components/LineBreak/LineBreak";
import {
  getCurrentPlanSelector,
  getPlanDetailsSelector,
  getPlanPaymentChargedSelector,
  subscriptionsFetchingSelector,
} from "store/reducers/Subscriptions/subscriptions";
import PaymentLoader from "components/PaymentLoader/PaymentLoader";
import PaymentCompleted from "pages/Plans/PaymentCompleted";
import StripeEventCheckout from "./StripeEventCheckout";
import RazorEventCheckout from "./RazorEventCheckout";
import {
  getCurrentEventSelector,
  getEventIsLoadingSelector,
  getEventPaymentChargedSelector,
  getEventRespSelector,
  getEventsPromoSelector,
  setEventPaymentCharged,
} from "store/reducers/HiveDetails/events";
import { verifyEventPromo } from "store/async-actions/Hive/eventsActions";
import { getHiveUuid } from "store/reducers/HiveDetails/hiveDetails";

const EventCheckout = () => {
  const navigate = useNavigate();
  const currentEvent = useAppSelector(getCurrentEventSelector);

  useEffect(() => {
    !!!currentEvent && navigate("..");
  }, [currentEvent]);

  const dispatch = useAppDispatch();

  const [openStripe, setOpenStripe] = useState(false);

  function loadScript(src: string) {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }

  const promoResp = useAppSelector(getEventsPromoSelector);

  const [discount, setDiscount] = useState(0);

  useEffect(() => {
    !!promoResp && setDiscount(promoResp.promoAmount);
  }, [promoResp]);

  const handleCheckout = () => {
    if (localStorage.getItem("countryCode") === "IN") {
      //   displayRazorPay();
    } else {
      setOpenStripe(true);
    }
  };

  const stripePromise = loadStripe(
    "pk_live_51IxUMeClzMdxwhfoBWrSrixtZYeyTSuKXhUwDB1bWoNxs5G9IYvxKAKqyBRwOkNrQEw4xkslBYW7JaZHKqZcRDJH00UakEmHVL"
    // "pk_test_51IxUMeClzMdxwhfo9SLZCdor2BSdjvfIfDGcCXgHobTkLTutZD0GaCDYNR21b46mFTOIouIS28vqudkCh8j8bVXn000qz0Lk6t"
  );

  const paymentCharged = useAppSelector(getEventPaymentChargedSelector);
  const isMemberView = useAppSelector(isMemberViewSelector);

  useEffect(() => {
    dispatch(setEventPaymentCharged(false));
  }, [dispatch, currentEvent]);

  //   useEffect(() => {
  //     if (paymentCharged) {
  //       navigate("/home");
  //       dispatch(setPaymentCharged(false));
  //       dispatch(
  //         getChannelList({
  //           hiveId: hiveDetails?.communityId!,
  //           isMemberView: isMemberView,
  //         })
  //       );
  //       alert("Payment Successful");
  //     }
  //   }, [paymentCharged]);

  const isLoading = useAppSelector(getEventIsLoadingSelector);

  const countryCode = localStorage.getItem("countryCode");

  const activePlan = useAppSelector(getCurrentPlanSelector);

  const [promo, setPromo] = useState("");

  const hiveUUid = useAppSelector(getHiveUuid);

  const handlePromo = () => {
    currentEvent &&
      dispatch(
        verifyEventPromo({
          eventUuid: currentEvent?.eventUuid || "",
          organizationUuid: hiveUUid,
          promoCode: promo,
        })
      );
  };

  return (
    // <PageLayout sideMenu={true}>
    <>
      <IslandLayout>
        {paymentCharged ? (
          <PaymentCompleted
            onDone={setEventPaymentCharged}
            message="Your Payment has been successfull"
          />
        ) : (
          <div className="buy_plan_container">
            <div className="plan_deets_wrapper">
              <BackButton to={`/events/${currentEvent?.eventIdentifier}`} />
              <LineBreak />
              <p className="fw-1">{currentEvent?.eventName}</p>
              <h1>
                {" "}
                {currentEvent?.eventCurrency === "AED" ? "AED " : "$"}
                {currentEvent?.eventAmount}
              </h1>
              <LineBreak />
              <p className="fw-1">Promo Code</p>
              <LineBreak />
              <div className="plan_content_flex_between">
                <input
                  className="input_border input_padding full_width --r-border10"
                  placeholder="Enter Promo Code"
                  value={promo}
                  onChange={(e) => {
                    setPromo(e.target.value);
                  }}
                />
                <p onClick={handlePromo} className="text-sm link">
                  APPLY
                </p>
              </div>
              <LineBreak />
              <hr></hr>
              <LineBreak />
              <div className="plan_content_flex_between">
                <p className="fw-1">Event Entry Fee</p>
                <p>
                  {" "}
                  {currentEvent?.eventCurrency === "AED" ? "AED " : "$"}
                  {currentEvent?.eventAmount.toFixed(2)}
                </p>
              </div>
              <LineBreak />
              <div className="plan_content_flex_between">
                <p className="fw-1">Subtotal</p>
                <p>
                  {" "}
                  {currentEvent?.eventCurrency === "AED" ? "AED " : "$"}
                  {currentEvent?.eventAmount.toFixed(2)}
                </p>
              </div>
              <LineBreak />
              <hr></hr>
              <LineBreak />
              <div className="plan_content_flex_between">
                <p className="fw-1">Discount</p>
                <p>
                  - {currentEvent?.eventCurrency === "AED" ? "AED " : "$"}
                  {discount.toFixed(2)}
                </p>
              </div>
              <LineBreak />
              <hr></hr>
              <LineBreak />{" "}
              <div className="plan_content_flex_between">
                <p className="fw-1">Total Due</p>
                <p>
                  {" "}
                  {currentEvent?.eventCurrency === "AED" ? "AED " : "$"}
                  {((currentEvent?.eventAmount || 0) - discount).toFixed(2)}
                </p>
              </div>
            </div>
            <div className="plan_payment_wrapper">
              {countryCode === "IN" ? (
                <div className="plan_razorpay">
                  <RazorEventCheckout />
                </div>
              ) : (
                <Elements stripe={stripePromise}>
                  <StripeEventCheckout setOpenStripe={setOpenStripe} />
                </Elements>
              )}
            </div>
          </div>
        )}
      </IslandLayout>
      {isLoading && <PaymentLoader isLoading={isLoading} />}
    </>
    // </PageLayout>
  );
};

export default EventCheckout;
