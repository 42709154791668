import Buttons from "components/Buttons";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { getNavBarSelector } from "store/reducers/HiveDetails/mainMenu";
import React, { useEffect, useState } from "react";
import { getHiveSelector } from "store/reducers/HiveDetails/hiveDetails";
import Hamburger from "components/Hamburger";
import { Box, CircularProgress, Dialog } from "@mui/material";
import * as Scroll from "react-scroll";
import { useNavigate } from "react-router-dom";
import { profileRespSelector } from "store/reducers/Profile/profile";
import ProfileMenu from "components/ProfileMenu";
import { ProgressSVG } from "components/SVG/SVG";
import {
  downloadInProgressSelector,
  setOpenDownloads,
} from "store/reducers/Ai/ai";
import Downloads from "components/Downloads";

const Navbar = () => {
  const navbarItems = useAppSelector(getNavBarSelector);
  const hiveDetails = useAppSelector(getHiveSelector);

  const profile = useAppSelector(profileRespSelector);

  const navigate = useNavigate();

  const handleLogoClick = () => {
    hiveDetails?.communityGuidelines
      ? window.open(hiveDetails.communityGuidelines, "_self")
      : navigate("/home");
  };

  var Element = Scroll.Element;

  const inProgress = useAppSelector(downloadInProgressSelector);
  const dispatch = useAppDispatch();

  return (
    <div className="navbar_wrapper">
      <Element name="navbar"></Element>
      <div className="navbar_container">
        <div className="navbar_content">
          <div className="logo_section">
            <Hamburger />
            <img
              src={hiveDetails?.communityWebLogo || ""}
              alt=""
              className="hive_logo cursor-pointer"
              onClick={handleLogoClick}
            />
          </div>
          <div className="navbar_buttons">
            {/* <div
              onClick={() => dispatch(setOpenDownloads(true))}
              className="pointer secondaryBtn"
            >
              {inProgress ? (
                <Box sx={{ position: "relative", display: "inline-flex" }}>
                  <CircularProgress size={20} color="inherit" />

                  <Box
                    sx={{
                      top: 0,
                      left: 0,
                      bottom: 0,
                      right: 0,
                      padding: "4px",
                      position: "absolute",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <ProgressSVG />
                  </Box>
                </Box>
              ) : (
                <ProgressSVG />
              )}
            </div> */}

            {navbarItems.map((navItem, idx) => {
              return (
                <Buttons key={idx} button={navItem}>
                  {navItem.componentName}
                </Buttons>
              );
            })}
            {!!profile?.userId &&
              localStorage.getItem("isLoggedIn") === "yes" && <ProfileMenu />}
          </div>
        </div>
      </div>
      {/* <LineBreak /> */}
      <Downloads />
    </div>
  );
};

export default Navbar;
