import React from "react";
import ContentMetrics from "./ContentMetrics";
import ContentUsage from "./ContentUsage";
import LineBreak from "components/LineBreak/LineBreak";

const ContentAnalysis = () => {
  return (
    <div className="ttv_container">
      <ContentMetrics />
      <LineBreak />
      <ContentUsage />
    </div>
  );
};

export default ContentAnalysis;
