import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import {
  getDownloadsOpenSelector,
  getUserDownloadsSelector,
  setCurrentAiVideo,
  setOpenDownloads,
} from "store/reducers/Ai/ai";
import { getUserDownloads } from "store/async-actions/Ai/ai";
import { profileUserIdSelector } from "store/reducers/Profile/profile";
import LinearProgress from "@mui/material/LinearProgress";
import { HourglassSVG, ProgressSVG } from "components/SVG/SVG";
import { NavLink, useNavigate } from "react-router-dom";
import { getHiveUuid } from "store/reducers/HiveDetails/hiveDetails";
import { AiTextGeneratedModel } from "api/models/Ai/ai";
import { setStoryUuid } from "store/reducers/Story/story";
import moment from "moment";
import ReactTimeAgo from "react-time-ago";

type Anchor = "top" | "left" | "bottom" | "right";

export default function Downloads() {
  const userDownloads = useAppSelector(getUserDownloadsSelector);
  const dispatch = useAppDispatch();
  const downloadsOpen = useAppSelector(getDownloadsOpenSelector);
  const userId = useAppSelector(profileUserIdSelector);

  const hiveUuid = useAppSelector(getHiveUuid);

  const navigate = useNavigate();

  const handleVideoClick = (video: AiTextGeneratedModel) => {
    if (video.service_route === "textToVideo") {
      dispatch(setCurrentAiVideo(video));
      navigate(`/ai/videoView`, { state: { from: "/ai" } });
    } else if (video.service_route === "pdfToStory") {
      dispatch(setStoryUuid(video.story_uuid));
      navigate(`/story/create/publish`, { state: { from: "/ai" } });
    } else {
      window.open(video.web_url, "_blank");
    }
  };

  // useEffect(() => {
  //   dispatch(getUserDownloads({ id: userId, uuid: hiveUuid }));

  //   const intervalId = setInterval(() => {
  //     dispatch(getUserDownloads({ id: userId, uuid: hiveUuid }));
  //   }, 30000);

  //   return () => clearInterval(intervalId);
  // }, [dispatch]);

  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event &&
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }
      dispatch(setOpenDownloads(!downloadsOpen));
    };

  const list = (anchor: Anchor) => (
    <Box
      sx={{ width: 350 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <div className="downloads_list_container">
        <List className="h_full">
          <div className="downloads_header">
            <ProgressSVG />
            <h3>Progress</h3>
          </div>
          {!!userDownloads && userDownloads.length > 0 ? (
            <>
              {userDownloads.map((video, index) => (
                <ListItem className="relative" key={index} disablePadding>
                  <div className="progress_content">
                    <img
                      src={
                        video.thumbnail ||
                        "https://veehivestage-images.s3.us-east-2.amazonaws.com/channelImages/defaultChannelLogo.jpg"
                      }
                      alt=""
                      className="progress_thumb_img"
                    />
                    {!!video.web_url || video.status_code === 200 ? (
                      <div
                        onClick={() => handleVideoClick(video)}
                        className="downloads_content_wrapper"
                      >
                        <h4>
                          Converted {video.service_id}
                          {/* {video.service_route === "pdfToStory"
                            ? (video.file_name.length > 18
                                ? video.file_name.slice(0, 15) + "..."
                                : video.file_name) + " to Story"
                            : video.service_id} */}
                        </h4>
                        <p className="success_text">Completed</p>
                        <p className="link">
                          Click to view the{" "}
                          {video.service_route === "pdfToStory"
                            ? "story"
                            : "video"}
                        </p>
                      </div>
                    ) : video.status_code === 300 ? (
                      <div className="downloads_content_wrapper">
                        <h4>
                          Converting {video.service_id}
                          {/* {video.service_route === "pdfToStory"
                            ? (video.file_name.length > 18
                                ? video.file_name.slice(0, 15) + "....pdf"
                                : video.file_name) + " to story"
                            : video.service_id} */}
                        </h4>
                        <p className="failed_text">Failed</p>
                        <NavLink
                          to={`/ai/${video.service_route}`}
                          className="link"
                        >
                          Try again
                        </NavLink>
                      </div>
                    ) : (
                      <div className="downloads_content_wrapper">
                        <h4>Converting {video.service_id}</h4>
                        {video.completed_per} Completed...{" "}
                        <LinearProgress
                          className="w_full"
                          variant="determinate"
                          value={
                            parseInt(
                              (video.completed_per || "10").match(/\d+/)?.[0] ??
                                "0",
                              10
                            ) || 0
                          }
                        />
                      </div>
                    )}
                  </div>
                  {video.date_created && (
                    <h1 className="bold_xs_text text_right created_date_downloads">
                      <ReactTimeAgo
                        date={
                          new Date(
                            moment
                              .utc(
                                video.date_created
                                  .replace(/\.\d+/g, "")
                                  .replace(" ", "T")
                                  .replace(/[-:]/g, "")
                              )
                              .local()
                              .format("LLL")
                          )
                        }
                        locale="en-GB"
                      />
                    </h1>
                  )}
                </ListItem>
              ))}
            </>
          ) : (
            <div className="progress_empty_wrapper">
              <HourglassSVG />
              <p className="tags">
                The progress of your video creation will be shown here.
                Currently there are no videos to be shown.
              </p>
            </div>
          )}
        </List>
      </div>
    </Box>
  );

  return (
    <div>
      {/* {(["left", "right", "top", "bottom"] as const).map((anchor) => ( */}
      <React.Fragment>
        <SwipeableDrawer
          anchor="right"
          open={downloadsOpen}
          onClose={toggleDrawer("right", false)}
          onOpen={toggleDrawer("right", true)}
        >
          {list("right")}
        </SwipeableDrawer>
      </React.Fragment>
      {/* ))} */}
    </div>
  );
}
