import IslandLayout from "components/IslandLayout/IslandLayout";
import LineBreak from "components/LineBreak/LineBreak";
import PageLayout from "components/PageLayout/PageLayout";
import React from "react";
import { useNavigate } from "react-router-dom";

const CancelDelay1 = () => {
  const router = useNavigate();

  const handleCancel = () => {
    router("/plans/cancel/confirm");
  };

  const handleKeep = () => {
    router("/plans");
  };

  return (
    <PageLayout sideMenu={true}>
      <IslandLayout>
        <div className="ce_about_wrapper cancel_delay_wraper_spacing">
          <h2>Cancel Subscription</h2>
          <LineBreak />
          <h4>Are you sre you want to end your suscription?</h4>
          <LineBreak />
          <p>
            Features tied to your subscription will be affected if you cancel
            your membership. You'll no longer have benefits for this
            subscription.
          </p>
          <LineBreak />
          <div className="cancel_plan_btns">
            <div onClick={handleCancel} className="secondaryBtn">
              Continue to cancel
            </div>
            <div onClick={handleKeep} className="primaryBtn">
              Keep my subscription
            </div>
          </div>
        </div>
      </IslandLayout>
    </PageLayout>
  );
};

export default CancelDelay1;
