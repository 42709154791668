import React, { useEffect } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { useNavigate } from "react-router-dom";
import {
  getAnalyticsTabIndexSelector,
  getAnalyticsTabsSelector,
  setAnalyticsTab,
} from "store/reducers/Analytics/analytics";

const AnalyticsTabs = () => {
  const tabsList = useAppSelector(getAnalyticsTabsSelector);
  const selectedHiveTab = useAppSelector(getAnalyticsTabIndexSelector);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    dispatch(setAnalyticsTab(newValue));
    navigate(`/analytics/${tabsList[newValue].toLowerCase()}`);
  };

  return (
    // <div className="tabs_container">
    <Tabs
      value={selectedHiveTab}
      onChange={handleChange}
      aria-label="hive tabs"
      className="tabs-wrapper"
    >
      {tabsList.map((tab, idx) => {
        return (
          <Tab
            style={{
              width: "25%",
              height: "20px",
              fontFamily: "IBM Plex Sans Condensed",
              fontStyle: "normal",
              fontWeight: 600,
              fontSize: "14px",
              lineHeight: "140%",
              textAlign: "center",
              letterSpacing: "0.02em",
              color: "#1C1B1F",
            }}
            key={idx}
            label={tab}
          />
        );
      })}
    </Tabs>
    // </div>
  );
};

export default AnalyticsTabs;
