import AnalyticsCapsule from "components/AnalyticsCapsule";
import { useAppSelector } from "hooks/redux";
import React from "react";
import {
  videoCountSelector,
  usersCountSelector,
  channelsCountSelector,
  getHiveRevenueSelector,
} from "store/reducers/Analytics/analytics";

const ChannelMetricsContainer = () => {
  const videoCount = useAppSelector(videoCountSelector);
  const usersCount = useAppSelector(usersCountSelector);
  const channelsCount = useAppSelector(channelsCountSelector);

  const hiveRevenue = useAppSelector(getHiveRevenueSelector);

  return (
    <div className="usage_data_container">
      <AnalyticsCapsule>
        <div className="headers_and_value">
          <h5>Channels</h5>
          <h2>{channelsCount}</h2>
        </div>
      </AnalyticsCapsule>
      <AnalyticsCapsule>
        <div className="headers_and_value">
          <h5>Purchases</h5>
          <h2>{hiveRevenue?.transactionsCount || 0}</h2>
        </div>
      </AnalyticsCapsule>
      <AnalyticsCapsule>
        <div className="headers_and_value">
          <h5>Revenue</h5>
          <h2>{hiveRevenue?.transactionsAmount || 0}</h2>
        </div>
      </AnalyticsCapsule>
      <AnalyticsCapsule>
        <div className="headers_and_value">
          <h5>Members</h5>
          <h2>{usersCount}</h2>
        </div>
      </AnalyticsCapsule>
    </div>
  );
};

export default ChannelMetricsContainer;
