import { IconButton, Menu, MenuItem } from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import React from "react";
import { useAppDispatch } from "hooks/redux";
import { deleteChatMessage } from "store/async-actions/Chat/chat";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "1px solid #f0f0f0",
  borderRadius: 3,
  padding: 2,
  boxShadow: 24,
};

const ITEM_HEIGHT = 48;

interface Props {
  id: string;
}

const ChatKebab = ({ id }: Props) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
    setAnchorEl(null);
  };

  const dispatch = useAppDispatch();

  const handleDelete = () => {
    dispatch(
      deleteChatMessage({
        messageId: id,
      })
    );
  };

  return (
    <div className="chat_kebab_container">
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
        className="chat_kebab_icon"
      >
        <MoreHorizIcon htmlColor="#000000" />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 3,
            width: "12ch",
          },
        }}
      >
        <MenuItem onClick={(e) => handleClose(e)}>
          <p onClick={handleDelete} className="font-medium">
            Unsend
          </p>
        </MenuItem>
      </Menu>
    </div>
  );
};

export default ChatKebab;
