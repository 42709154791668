import * as React from "react";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import {
  getHiveSelector,
  getHiveUuid,
} from "store/reducers/HiveDetails/hiveDetails";
import {
  addSuperAdmin,
  removeHiveMember,
} from "store/async-actions/Hive/hivePage";
import { removeChannelMember } from "store/async-actions/Channels/channels";
import { useState } from "react";
import ConfirmationModal from "components/Confirmation/Confirmation";
import { CampaignModel } from "api/models/Campaign/campaign";
import { useNavigate } from "react-router-dom";
import { setCampaignUuid } from "store/reducers/Campaign/campaign";
import {
  deleteCampaign,
  sendEmailCampaign,
  sendSMSCampaign,
} from "store/async-actions/Campaign/campaign";

const ITEM_HEIGHT = 48;

interface Props {
  campaign: CampaignModel;
}

export default function CampaignKebab({ campaign }: Props) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const dispatch = useAppDispatch();
  const hiveDetails = useAppSelector(getHiveSelector);

  const handleRemove = () => {
    dispatch(deleteCampaign({ campaignUuid: campaign.campaignUuid }));
    handleRemoveConfirmClose();
  };

  const handleSend = () => {
    campaign.campaignType === "sms"
      ? dispatch(sendSMSCampaign({ campaignUuid: campaign.campaignUuid }))
      : dispatch(
          sendEmailCampaign({
            campaignUuid: campaign.campaignUuid,
            organizationUuid: hiveUuid,
          })
        );
    handleSendConfirmClose();
  };

  const hiveUuid = useAppSelector(getHiveUuid);

  const [isSendOpen, setSendIsOpen] = useState(false);
  const [isRemoveOpen, setIsRemoveOpen] = useState(false);

  const handleSendConfirmClose = () => {
    handleClose();
    setSendIsOpen(false);
  };

  const handleSendConfirmOpen = () => {
    setSendIsOpen(true);
  };

  const handleRemoveConfirmClose = () => {
    handleClose();
    setIsRemoveOpen(false);
  };

  const handleRemoveConfirmOpen = () => {
    setIsRemoveOpen(true);
  };

  const navigate = useNavigate();

  const handleEdit = () => {
    dispatch(setCampaignUuid(campaign.campaignUuid));
    navigate(`${campaign.campaignUuid}/edit`);
  };

  const handleView = () => {
    dispatch(setCampaignUuid(campaign.campaignUuid));
    navigate(`${campaign.campaignUuid}`);
  };

  return (
    <div>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: "17ch",
          },
        }}
      >
        <MenuItem onClick={handleView}>
          <p className="text-sm">View</p>
        </MenuItem>
        <MenuItem onClick={handleEdit}>
          <p className="text-sm">Edit</p>
        </MenuItem>
        <MenuItem onClick={handleSendConfirmOpen}>
          <p className="text-sm">
            Send {`${campaign.campaignType === "sms" ? "Messages" : "Emails"}`}
          </p>
        </MenuItem>
        <MenuItem onClick={handleRemoveConfirmOpen}>
          <p className="text-sm">Delete</p>
        </MenuItem>

        {isRemoveOpen && (
          <ConfirmationModal
            isOpen={isRemoveOpen}
            onReject={handleRemoveConfirmClose}
            onConfirm={handleRemove}
            headline={`Delete ${campaign.campaignName}`}
            description={`Are you sure you want to delete this campaign?`}
            confirmMessage="Yes"
            rejectMessage="No"
          />
        )}

        {isSendOpen && (
          <ConfirmationModal
            isOpen={isSendOpen}
            onReject={handleSendConfirmClose}
            onConfirm={handleSend}
            headline={`${
              campaign.campaignType === "sms" ? "SMS" : "Email"
            } Campaign`}
            description={`Are you sure you want to send the ${
              campaign.campaignType === "sms" ? "messages" : "emails"
            }?`}
            confirmMessage="Yes"
            rejectMessage="No"
          />
        )}
      </Menu>
    </div>
  );
}
