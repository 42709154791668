import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import BackButton from "components/BackButton";
import LineBreak from "components/LineBreak";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import React, { useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import {
  approvedChannelListSelector,
  setChecked,
} from "store/reducers/ChannelDetails/channelList";
import {
  inviteToChannelsList,
  removeInviteToChannel,
  setInvitingToChannel,
} from "store/reducers/HiveDetails/hiveSettings";

const InviteMembers = () => {
  const channelList = useAppSelector(approvedChannelListSelector);
  const dispatch = useAppDispatch();
  const inviteToChannelList = useAppSelector(inviteToChannelsList);

  const handleSelect = (
    e: React.ChangeEvent<HTMLInputElement>,
    idx: number,
    uuid: string
  ) => {
    dispatch(setChecked(idx));
    e.target.checked
      ? dispatch(setInvitingToChannel(uuid))
      : dispatch(removeInviteToChannel(uuid));
  };

  const navigate = useNavigate();

  useEffect(() => {}, [inviteToChannelList]);

  return (
    <div className="invite_to_channels_container">
      <h3>Add users to your channels</h3>
      <LineBreak />
      <div className="back_button_spacing">
        <BackButton />
      </div>
      <p className="my-5">
        Select channels to which you would like to invite members. All members
        will have access to all public channels.
      </p>
      <div className="channel_selection_wrapper">
        {channelList.map((channel, idx) => {
          return (
            <FormControlLabel
              value="bottom"
              control={
                <Checkbox
                  onChange={(e) => handleSelect(e, idx, channel.channelUuid)}
                  size="small"
                  checked={channel.checked!}
                />
              }
              label={
                <div>
                  <img
                    key={idx}
                    className="channel_select_logo"
                    src={
                      channel.webLogo ||
                      "https://veehivestage-images.s3.us-east-2.amazonaws.com/channelImages/defaultChannelLogo.jpg"
                    }
                    alt=""
                  />
                  <p className="text-center text-sm">
                    {channel.channelName.length > 10
                      ? channel.channelName.slice(0, 10) + "..."
                      : channel.channelName}
                  </p>
                </div>
              }
              labelPlacement="bottom"
            />
          );
        })}
      </div>
      {inviteToChannelList.length > 0 ? (
        <NavLink to="contact" className="nextBtn primaryBtn">
          Next
        </NavLink>
      ) : (
        <div className="nextBtn disabledBtn">Next</div>
      )}
    </div>
  );
};

export default InviteMembers;
