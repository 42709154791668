import React, { memo, useCallback } from "react";
import { formatRelative, isValid } from "date-fns";
import parseISO from "date-fns/parseISO";

import Avatar from "components/common/Avatar";
import Text from "components/common/Text";
import RightArrow from "components/common/svg/RightArrow";
import Button from "components/common/Button";
import Loader from "components/common/Loader";
import FileIcon from "components/common/svg/File";
import PhotoIcon from "components/common/svg/Photo";
import VoiceIcon from "components/common/svg/Voice";
import { useAppSelector, useAppShallowSelector } from "hooks/redux";
import {
  getCommentsSelector,
  getOwnerVideoCommentsRoomsSelector,
} from "store/reducers/Content/content";
import { getInitialsOfPerson } from "utils";
import { ChoisenCommentRoom } from "./VideoComments";
import { isImage } from "utils";
import BackButton from "components/BackButton";

interface CommentsRoomListProps {
  selectCommentRoom: (room: ChoisenCommentRoom) => void;
  isLoading: boolean;
}

const CommentsRoomsList: React.FC<CommentsRoomListProps> = ({
  selectCommentRoom,
  isLoading,
}) => {
  const ownerRooms = useAppShallowSelector(getOwnerVideoCommentsRoomsSelector);

  const handleClickRoom = useCallback(
    (selectRoomData: ChoisenCommentRoom) => () => {
      selectCommentRoom(selectRoomData);
    },
    [selectCommentRoom]
  );

  return (
    <div className="comments_rooms_container">
      {/* {isLoading && !ownerRooms.length && <Loader />} */}

      {ownerRooms.map((room: any) => {
        return (
          <CommentingItem
            key={room.roomId}
            roomId={room.roomId}
            name={room.name}
            logoSrc={room.logoSrc}
            messageType={room.messageType}
            lastMessage={room.lastMessage}
            lastMessageDate={room.lastMessageDate}
            readByRecipient={room.readByRecipient}
            attachmentExt={room.attachmentExt}
            onClick={handleClickRoom({
              roomId: room.roomId,
              roomName: room.name,
              roomLogo: room.logoSrc,
            })}
          />
        );
      })}
    </div>
  );
};

export default memo(CommentsRoomsList);

interface CommentingItemProps {
  logoSrc?: string;
  name: string;
  lastMessage?: string;
  messageType?: MessageType;
  lastMessageDate?: string;
  roomId: string;
  onClick: () => void;
  readByRecipient: boolean;
  attachmentExt: string;
}

const CommentingItem: React.FC<CommentingItemProps> = ({
  lastMessage,
  lastMessageDate = "",
  messageType,
  logoSrc,
  name,
  onClick,
  readByRecipient,
  attachmentExt,
}) => {
  let formattedDate: string = "";

  if (lastMessageDate) {
    const parsedDate = parseISO(lastMessageDate);
    if (isValid(parsedDate)) {
      formattedDate = formatRelative(parseISO(lastMessageDate), new Date());
    }
  }

  const makeContent = () => {
    const _isImage = isImage(attachmentExt);
    switch (messageType) {
      case "text":
        return (
          <Text className="last_message_text" as="span">
            {lastMessage}
          </Text>
        );
      case "voice":
        return (
          <Text className="last_message_text attachment_content" as="span">
            <VoiceIcon className="attachment_icon" color="rgba(0,0,0,0.71)" />
            Voice
          </Text>
        );
      case "attachment":
        return _isImage ? (
          <Text className="last_message_text attachment_content" as="span">
            <PhotoIcon className="attachment_icon" /> Photo
          </Text>
        ) : (
          <Text className="last_message_text attachment_content" as="span">
            <FileIcon className="attachment_icon" /> File
          </Text>
        );

      default:
        break;
    }
  };

  return (
    <Button className="commenting_item" onClick={onClick}>
      <div className="left_block">
        <Avatar alt={`${name} logo`} className="commenting_logo" src={logoSrc}>
          {getInitialsOfPerson(name)}
        </Avatar>
        <div className="names_section">
          <Text className="name_text">
            {name}
            {!readByRecipient && <span className="readed_badge" />}
          </Text>
          <Text className="last_message_text" as="span">
            {makeContent()}
          </Text>
          <Text className="last_message_date_text">{formattedDate}</Text>
        </div>
      </div>
      <RightArrow className="commenting_item_icon" />
    </Button>
  );
};
