import React, { memo } from "react";

import Text from "components/common/Text";
import Avatar from "components/common/Avatar";
import ParticipantItemControl from "./ParticipantItemControl";
import { useParticipantsContext } from "../ParticipantsChatRoomContainer";
import { getInitialsOfPerson } from "utils";
import { Participant } from "store/reducers/Chat/chatrooms";

interface ParticipantsItemProps extends Participant {}

const ParticipantsItem: React.FC<ParticipantsItemProps> = ({
  foto,
  id,
  name,
  nikName,
}) => {
  const { isOwner, isCreate, isOpenSelectAll } = useParticipantsContext();

  const Element = isCreate || isOpenSelectAll ? "label" : "div";

  return (
    <Element className="participant_item_container">
      <div className="participant_info_block">
        <Avatar src={foto} alt={`${name} avatar"`} className="foto_container">
          {getInitialsOfPerson(name)}
        </Avatar>
        <div className="personal_info">
          <Text className="participant_name_text" fontWeight="w500">
            {name}
          </Text>
          <Text className="participant_nikname_text" fontWeight="w400">
            {nikName}
          </Text>
        </div>
      </div>
      {(isOwner || isCreate) && <ParticipantItemControl id={id} />}
    </Element>
  );
};

export default memo(ParticipantsItem);
