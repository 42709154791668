import CircularProgress from "@mui/material/CircularProgress";
import { useAppSelector } from "hooks/redux";
import React, { PropsWithChildren } from "react";
import { getAnalyticsIsLoading } from "store/reducers/Analytics/analytics";

const AnalyticsCapsule: React.FC<PropsWithChildren<any>> = ({ children }) => {
  const isLoading = useAppSelector(getAnalyticsIsLoading);

  return (
    <div className="analytics_capsule_container">
      {isLoading ? <CircularProgress size={20} color="inherit" /> : children}
    </div>
  );
};

export default AnalyticsCapsule;
