import { TextField } from "@mui/material";
import AiFileDrop from "components/AiFileDrop/AiFileDrop";
import BackButton from "components/BackButton/BackButton";
import LineBreak from "components/LineBreak/LineBreak";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { createTeaser } from "store/async-actions/Ai/ai";
import {
  getUploadedAiFileSelector,
  setOpenDownloads,
  uploadAiFile,
} from "store/reducers/Ai/ai";
import { getHiveUuid } from "store/reducers/HiveDetails/hiveDetails";
import { profileUserIdSelector } from "store/reducers/Profile/profile";

const CreateTeaser = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(uploadAiFile(null));
  }, [dispatch]);

  const userId = useAppSelector(profileUserIdSelector);

  const uploadedFile = useAppSelector(getUploadedAiFileSelector);

  const navigate = useNavigate();

  const [seconds, setSeconds] = useState("5");

  const handleSetSeconds = (e: any) => {
    if (+e.target.value > 4) {
      setSeconds(e.target.value);
    }
  };

  const hiveUuid = useAppSelector(getHiveUuid);

  const handleCreate = () => {
    const formData = new FormData();
    const obj = {
      length: seconds,
      userid: userId,
      organizationUuid: hiveUuid,
    };

    formData.append("json", JSON.stringify(obj));
    formData.append("file", uploadedFile || "");

    dispatch(createTeaser({ formData, userId }));
    navigate("..");
    dispatch(setOpenDownloads(true));
  };

  return (
    <div className="ttv_container">
      <BackButton />
      <LineBreak />
      <h4 className="tags">Upload Video</h4>
      <LineBreak />
      <AiFileDrop fileType="video" size={150} />
      <LineBreak />
      <TextField
        label="Seconds"
        type="number"
        variant="outlined"
        placeholder="Seconds"
        className=""
        name="seconds"
        onChange={handleSetSeconds}
        value={seconds}
      />
      {uploadedFile ? (
        <div onClick={handleCreate} className="nextBtn primaryBtn">
          Create Teaser
        </div>
      ) : (
        <div className="nextBtn disabledBtn">Create Teaser</div>
      )}
    </div>
  );
};

export default CreateTeaser;
