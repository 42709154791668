import { useAppDispatch, useAppSelector } from "hooks/redux";
import React from "react";
import {
  getProductDetails,
  getUniqueSessions,
  getUserActions,
} from "store/async-actions/Analytics/analyticsActions";
import {
  getHiveSelector,
  getHiveUuid,
} from "store/reducers/HiveDetails/hiveDetails";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import ReactTimeAgo from "react-time-ago";
import moment from "moment";
import LineBreak from "components/LineBreak";
import PageNumbers from "components/PageNumbers";
import {
  ProductDetailsItem,
  ProductDetailsModel,
} from "api/models/Analytics/sessions";
import analytics, {
  getFromDateSelector,
  getToDateSelector,
  setProductListPageNo,
} from "store/reducers/Analytics/analytics";

interface Props {
  analyticsData: ProductDetailsModel;
}

const UsersAnalyticsTable = ({ analyticsData }: Props) => {
  const dispatch = useAppDispatch();

  const hiveUuid = useAppSelector(getHiveUuid);

  const fromDate = useAppSelector(getFromDateSelector);
  const toDate = useAppSelector(getToDateSelector);

  const handleNextPage = (pageNo: number) => {
    dispatch(setProductListPageNo(pageNo));
    hiveUuid &&
      dispatch(
        getProductDetails({
          appName: analyticsData.content[0].appName,
          organizationUuid: hiveUuid,
          fromDate: fromDate,
          toDate: toDate,
          pageNo: pageNo,
          pageSize: 15,
        })
      );
  };

  const handleUserActions = (content: ProductDetailsItem) => {
    dispatch(
      getUserActions({
        sessionId: content.sessionId,
        pageNo: 0,
        pageSize: 15,
      })
    );
  };

  return (
    <>
      <h4>User analytics</h4>
      <LineBreak />
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>
                {" "}
                <p className="bold_heading_table">Username</p>
              </TableCell>

              <TableCell align="center">
                {" "}
                <p className="bold_heading_table">Location</p>
              </TableCell>
              <TableCell align="center">
                {" "}
                <p className="bold_heading_table">Date</p>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {analyticsData &&
              analyticsData.content &&
              analyticsData.content.length > 0 &&
              analyticsData.content.map((row) => (
                <TableRow
                  onClick={() => handleUserActions(row)}
                  className="table_cell_hover pointer"
                  key={row.sessionId}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {row.userName || "-"}
                  </TableCell>
                  <TableCell align="center">{row.ipState}</TableCell>
                  <TableCell align="center">
                    {
                      <ReactTimeAgo
                        date={
                          new Date(
                            moment
                              .utc(row.date)
                              .local()
                              .format("YYYY-MM-DD HH:mm:ss")
                          )
                        }
                        locale="en-GB"
                      />
                    }
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      {analyticsData && analyticsData.totalPages > 1 && (
        <PageNumbers
          totalPages={analyticsData?.totalPages}
          initialPage={0}
          handleChange={handleNextPage}
        />
      )}
    </>
  );
};

export default UsersAnalyticsTable;
