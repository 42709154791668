import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ChannelListModel } from "api/models/Hive/hiveChannels";
import { WritableDraft } from "immer/dist/internal";
import { getAllStatusChannels } from "store/async-actions/Channels/channels";
import { getChannelList } from "store/async-actions/Hive/hivePage";

import { createTypedSelector } from "store/utils";

const initialState: {
  channelList: ChannelListModel[];
  activeChannelUuid: string | null;
  activeTab: number;
  isFetching: boolean;
  activeVideoTab: number;
  activeChannelId: number;
  approvedChannelList: ChannelListModel[];
  kebabOptions: string[];
  currentVideosPage: number;
  allStatusChannels: ChannelListModel[];
} = {
  channelList: [],
  activeChannelUuid: "",
  activeTab: 0,
  isFetching: false,
  activeVideoTab: 0,
  activeChannelId: 0,
  approvedChannelList: [],
  kebabOptions: ["Edit", "Archive", "Reject", "Share"],
  currentVideosPage: 0,
  allStatusChannels: [],
};

const channelsSlice = createSlice({
  name: "channels",
  initialState,
  reducers: {
    setActiveChannelUuid(state, action: PayloadAction<string | null>) {
      state.currentVideosPage = 0;
      state.activeChannelUuid = action.payload;
      state.channelList.find((channel) => {
        if (channel.channelUuid === state.activeChannelUuid) {
          state.activeChannelId = channel.channelId;
        }
      });
    },
    setActiveChannelId(state, action: PayloadAction<number>) {
      state.activeChannelId = action.payload;
      state.channelList.map((channel) => {
        if (channel.channelId === action.payload) {
          state.activeChannelUuid = channel.channelUuid;
        }
      });
    },
    setActiveTab(state, action: PayloadAction<number>) {
      state.activeTab = action.payload;
    },
    setCurrentVideosPage(state, action: PayloadAction<number>) {
      state.currentVideosPage = action.payload;
    },
    setVideosTab(state, action: PayloadAction<number>) {
      state.activeVideoTab = action.payload;
      if (state.activeVideoTab === 0) {
        state.kebabOptions = ["Edit", "Archive", "Reject", "Share"];
      } else if (state.activeVideoTab === 1) {
        state.kebabOptions = ["Edit", "Approve", "Archive", "Reject"];
      } else if (state.activeVideoTab === 2) {
        state.kebabOptions = ["Edit", "Approve", "Reject"];
      } else if (state.activeVideoTab === 3) {
        state.kebabOptions = ["Edit", "Approve", "Archive"];
      }
    },
    setChecked(state, action: PayloadAction<number>) {
      state.approvedChannelList[action.payload].checked =
        !state.approvedChannelList[action.payload].checked;
    },
  },
  extraReducers: {
    [getChannelList.pending.type]: (state) => {
      state.isFetching = true;
    },
    [getChannelList.fulfilled.type]: (
      state,
      action: PayloadAction<ChannelListModel[]>
    ) => {
      state.channelList = [];
      state.channelList = action.payload;
      state.activeChannelId = state.channelList[1]
        ? state.channelList[1].channelId
        : state.channelList[0].channelId;
      state.activeChannelUuid = state.channelList[0].channelUuid
        ? state.channelList[0].channelUuid
        : state.channelList[1].channelUuid;
      state.isFetching = false;
      setApprovedChannels(state);
    },
    [getChannelList.rejected.type]: (state) => {
      state.isFetching = false;
    },

    //GET ALL CHANNELS LIST
    [getAllStatusChannels.pending.type]: (state) => {
      state.isFetching = true;
    },
    [getAllStatusChannels.fulfilled.type]: (
      state,
      action: PayloadAction<ChannelListModel[]>
    ) => {
      state.allStatusChannels = action.payload;
    },
    [getAllStatusChannels.rejected.type]: (state) => {
      state.isFetching = false;
    },
  },
});

const setApprovedChannels = (
  state: WritableDraft<{
    channelList: ChannelListModel[];
    activeChannelUuid: string | null;
    activeTab: number;
    isFetching: boolean;
    activeVideoTab: number;
    activeChannelId: number;
    approvedChannelList: ChannelListModel[];
  }>
) => {
  state.approvedChannelList = [];
  state.channelList.map((channel, idx) => {
    if (channel.channelStatus === "APPROVED") {
      channel.checked = false;
      state.approvedChannelList.push(channel);
    }
  });
};

export const approvedChannelListSelector = createTypedSelector(
  (state) => state.channels.approvedChannelList
);

export const getChannelListSelector = createTypedSelector(
  (state) => state.channels.channelList
);

export const { setActiveTab } = channelsSlice.actions;
export const { setVideosTab } = channelsSlice.actions;
export const {
  setActiveChannelUuid,
  setChecked,
  setActiveChannelId,
  setCurrentVideosPage,
} = channelsSlice.actions;

export const getVideosCurrentPageSelector = createTypedSelector(
  (state) => state.channels.currentVideosPage
);

export const getActiveChannelUuidSelector = createTypedSelector(
  (state) => state.channels.activeChannelUuid
);

export const getKebabOptionsSelector = createTypedSelector(
  (state) => state.channels.kebabOptions
);

export const getActiveChannelSelector = createTypedSelector((state) => {
  const activeChannelUuid = state.channels.activeChannelUuid;
  return state.channels.channelList.find(
    (channel) => channel.channelUuid === activeChannelUuid
  );
});

export const getAllStatusChannelsSelector = createTypedSelector(
  (state) => state.channels.allStatusChannels
);

export const getActiveChannelIdSelector = createTypedSelector(
  (state) => state.channels.activeChannelId
);

export const getActiveTabSelector = createTypedSelector(
  (state) => state.channels.activeTab
);

export const videosTabSelector = createTypedSelector(
  (state) => state.channels.activeVideoTab
);

export default channelsSlice.reducer;
