import React from "react";
import Stories from "react-insta-stories";
import { useState } from "react";
import loveEmoji from "assets/png/love_emoji.png";
import nahEmoji from "assets/png/nah_emoji.png";
import mehEmoji from "assets/png/meh_emoji.png";
import defaultEmoji from "assets/png/defaultEmoji.png";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { useEffect } from "react";
import {
  getCurrentStoryItemSelector,
  getStartClickedSelector,
  getStoriesListSelector,
  getStorySegmentItemReactSelector,
  setCurrentStoryItem,
  setViewingStoryUuid,
} from "store/reducers/Story/story";
import {
  getSegmentLocation,
  getStoryDetails,
  getStorySegmentItemReact,
  updateStoryReaction,
} from "store/async-actions/Story/storyActions";
import {
  getSegmentReactions,
  getSegmentViews,
} from "store/async-actions/Analytics/analyticsActions";
import clientApi from "api";
import { MuteSVG, UnMuteSVG } from "components/SVG/SVG";

function StoryView() {
  const [currentStoryIndex, setCurrentStoryIndex] = useState(0);
  const [currentStory, setCurrentStory] = useState({
    selectedEmoji: "default",
    storyItemId: 0,
    storyUuid: "",
    url: "",
    title: "",
    description: "",
    type: "",
    actionLink: "",
    colorCode: "",
    longDescriptionList: [],
    textColorCode: "",
  });

  const emojis = [
    {
      reactionEmoji: loveEmoji,
      reactionType: "love",
      reactionId: 3,
    },
    {
      reactionEmoji: mehEmoji,
      reactionType: "meh",
      reactionId: 2,
    },
    {
      reactionEmoji: nahEmoji,
      reactionType: "nah",
      reactionId: 1,
    },
  ];

  const location = useLocation();
  const [showSelections, setShowSelections] = useState(false);
  const [isPaused, setIsPaused] = useState(true);
  const urlParams = location.search.split("?")[1];
  const [firstStoryLoaded, setFirstStoryLoaded] = useState(false);

  const segmentItemReactions = useAppSelector(getStorySegmentItemReactSelector);

  const getSelectedEmojiSrc = () => {
    const emoji =
      segmentItemReactions &&
      emojis.find(
        (emojiObj) => emojiObj.reactionId === +segmentItemReactions?.reactionId
      );
    return emoji ? emoji.reactionEmoji : defaultEmoji;
  };

  const storyListReduced = useAppSelector(getStoriesListSelector);
  const dispatch = useAppDispatch();
  const [selectedEmoji, setSelectedEmoji] = useState(
    currentStory?.selectedEmoji
  );

  const url = location.pathname;
  const secondSlashIndex = url.indexOf("/", url.indexOf("/") + 1);
  const thirdSlashIndex = url.indexOf("/", secondSlashIndex + 1);
  const fourthSlashIndex = url.indexOf("/", thirdSlashIndex + 1);
  const storyUuid = url.substring(
    thirdSlashIndex + 1,
    fourthSlashIndex !== -1 ? fourthSlashIndex : url.length
  );

  const startClicked = useAppSelector(getStartClickedSelector);
  const storyItem = useAppSelector(getCurrentStoryItemSelector);

  const navigate = useNavigate();

  const handleStoryStart = (s: any, st: any) => {
    if (s === 0 && !firstStoryLoaded) {
      setIsPaused(false);
    }
    setCurrentStoryIndex(st);
    setCurrentStory(st);

    dispatch(
      getStorySegmentItemReact({
        storyUuid: storyUuid,
        storySegmentId: st.storyItemId,
        sessionId: clientApi.sessionId,
      })
    );

    dispatch(setCurrentStoryItem(st));
    dispatch(
      getSegmentViews({
        storyUuid: storyUuid,
        segmentId: st.storyItemId,
      })
    );

    dispatch(
      getSegmentLocation({
        storyUuid: storyUuid,
        segmentId: st.storyItemId,
      })
    );

    dispatch(
      getSegmentReactions({
        storyUuid: storyUuid,
        segmentId: st.storyItemId,
      })
    );
  };

  useEffect(() => {
    !startClicked && storyUuid && navigate(`/viewstory/start?${storyUuid}`);
  }, [dispatch]);

  useEffect(() => {
    if (storyUuid) {
      dispatch(setViewingStoryUuid(storyUuid));
      window.dispatchEvent(
        new CustomEvent("analyticsEvent", {
          detail: {
            ctaName: "storyInit",
            pageName: location.pathname.slice(1),
            storyUuid: storyUuid,
            // organizationUuid: hiveDetails.communityUuid,
          },
        })
      );
    }
  }, [storyUuid]);

  const setEmoji = (e: any) => {
    setCurrentStory((prevState) => ({
      ...prevState,
      selectedEmoji: e.reactionType,
    }));
    dispatch(
      updateStoryReaction({
        storyUuid: currentStory.storyUuid,
        storySegmentId: currentStory.storyItemId,
        reactionId: e.reactionId,
        reactionType: e.reactionType,
        sessionId: clientApi.sessionId,
      })
    );
    setSelectedEmoji(e.reactionType);
    setShowSelections(false);
  };

  useEffect(() => {
    if (urlParams) {
      dispatch(setViewingStoryUuid(urlParams));
      dispatch(getStoryDetails({ storyUuid: urlParams }));
    }
  }, [dispatch, urlParams]);

  const [isMuted, setIsMuted] = useState(false);

  const toggleMute = (passedBool?: boolean) => {
    const videos = document.querySelectorAll("video");

    videos.forEach((video) => {
      video.muted = passedBool || !isMuted;
    });

    setIsMuted(passedBool || !isMuted);
  };

  const handlePause = () => {
    setIsPaused(!isPaused);
  };

  const handleActionClick = () => {
    window.dispatchEvent(
      new CustomEvent("analyticsEvent", {
        detail: {
          ctaName: "actionLinkClick",
          pageName: location.pathname.slice(1),
          storyUuid: storyUuid,
          request: currentStory.storyItemId,
        },
      })
    );
  };

  return (
    <div className="stories_container">
      <div className="full_wrap">
        {currentStory.type === "video" && (
          <div onClick={() => toggleMute()} className="mute_button">
            {isMuted ? <MuteSVG /> : <UnMuteSVG />}
          </div>
        )}
        <div
          style={{
            backgroundColor: currentStory.colorCode,
          }}
          className="stories_wrapper"
        >
          {storyListReduced && storyListReduced.length > 0 && (
            <Stories
              storyStyles={{
                backgroundColor: currentStory.colorCode,
                objectFit: "cover",
                width: "720px",
                // height: currentStory.type === "image" ? "100vh" : "",
              }}
              loop
              keyboardNavigation
              defaultInterval={8000}
              stories={storyListReduced}
              width="100%"
              height="100%"
              isPaused={isPaused}
              onAllStoriesEnd={(s: any, st: any) =>
                console.log("all stories ended", s, st)
              }
              onStoryStart={handleStoryStart}
            />
          )}
          <div onClick={handlePause} className="pause_element"></div>
          <div className="full_z story_bottom_ak">
            <div className="title_and_emoji_styling">
              <div className="story_title_wrapper_ak">
                <h2 className="stories_title">{currentStory.title}</h2>
                <p className="stories_desc">{currentStory.description}</p>
              </div>
              <div className="full_z emoji_container_items">
                <div
                  className="full_z"
                  onClick={() => setShowSelections((prevState) => !prevState)}
                >
                  <img
                    src={getSelectedEmojiSrc()}
                    className={
                      currentStory.selectedEmoji ? "emoji" : "default_emoji"
                    }
                    alt="selected emoji"
                  />
                </div>
                <div>
                  {showSelections && (
                    <div className="emojis_wrapper">
                      {emojis.map((emojiObj) => (
                        <img
                          key={emojiObj.reactionId}
                          onClick={() => setEmoji(emojiObj)}
                          src={emojiObj.reactionEmoji}
                          className="emoji"
                          alt={emojiObj.reactionType}
                        />
                      ))}
                    </div>
                  )}
                </div>
              </div>
            </div>
            {currentStory?.actionLink && currentStory.actionLink !== "" && (
              <a
                onClick={handleActionClick}
                href={currentStory.actionLink}
                target="_blank"
                rel="noopener noreferrer"
                className="secondaryBtn show_more_btn_story_ak full_zz"
              >
                Click here
              </a>
            )}
          </div>
          {/* <div className="full_z stories_bottom_container">
            <div className="stories_desc_section">
              <div className="stories_title">{currentStory?.title}</div>
              <div className="stories_desc">{currentStory?.description}</div>
            </div>
            <div className="full_z emoji_container_items">
              <div>
                {showSelections && (
                  <div className="emojis_wrapper">
                    {emojis.map((emojiObj) => (
                      <img
                        key={emojiObj.reactionId}
                        onClick={() => setEmoji(emojiObj)}
                        src={emojiObj.reactionEmoji}
                        className="emoji"
                        alt={emojiObj.reactionType}
                      />
                    ))}
                  </div>
                )}
              </div>
              <div
                className="full_z"
                onClick={() => setShowSelections((prevState) => !prevState)}
              >
                <img
                  src={getSelectedEmojiSrc()}
                  className={
                    currentStory.selectedEmoji ? "emoji" : "default_emoji"
                  }
                  alt="selected emoji"
                />
              </div>
            </div>
          </div>
          {currentStory?.actionLink && currentStory.actionLink !== "" && (
            <a
              onClick={handleActionClick}
              href={currentStory.actionLink}
              target="_blank"
              rel="noopener noreferrer"
              className="secondaryBtn show_moreBtn full_zz"
            >
              Click here
            </a>
          )} */}
        </div>
      </div>
    </div>
  );
}

export default StoryView;
