import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "api";
import { RootState, store } from "store";
import { setOpenDownloads } from "store/reducers/Ai/ai";
import hiveDetails from "store/reducers/HiveDetails/hiveDetails";

export const getAiTemplates = createAsyncThunk(
  "ai/getAiTemplates",
  async (body: null, { rejectWithValue }) => {
    try {
      const { data } = await api.ai.getAiTemplates(body);
      return data;
    } catch (error: any) {
      return rejectWithValue(error?.message);
    }
  }
);

export const textToVideoGenerate = createAsyncThunk(
  "ai/textToVideoGenerate",
  async (
    body: {
      user_id: string;
      text: string;
      templateId: string;
      isGenerative: boolean;
      organizationUuid: string;
      durationInSeconds: number;
    },
    { rejectWithValue, dispatch }
  ) => {
    try {
      const { data } = await api.ai.textToVideoGenerate(body);
      dispatch(textToVideoGetGenerated(data.data));

      return data.data;
    } catch (error: any) {
      return rejectWithValue(error?.message);
    }
  }
);

export const textToVideoGetGenerated = createAsyncThunk(
  "ai/textToVideoGetGenerated",
  async (passedData: string, { rejectWithValue }) => {
    try {
      const { data } = await api.ai.textToVideoGetGenerated(passedData);
      return data.data;
    } catch (error: any) {
      return rejectWithValue(error?.message);
    }
  }
);

export const publishToChannel = createAsyncThunk(
  "ai/publishToChannel",
  async (
    body: {
      channelID: string;
      organisationID: string;
      fileKey: string;
      sourceURL: string;
      tags: any;
      videoTitle: string;
      attribute3: string[];
    },
    { rejectWithValue }
  ) => {
    try {
      const { data } = await api.ai.publishToChannel(body);
      return data.data;
    } catch (error: any) {
      return rejectWithValue(error?.message);
    }
  }
);

export const textToVideo = createAsyncThunk(
  "ai/textToVideo",
  async (body: FormData, { rejectWithValue, dispatch, getState }) => {
    try {
      const { data } = await api.ai.textToVideo(body);
      const { hive } = getState() as RootState;

      dispatch(
        getUserDownloads({ id: +body.get("user_id")!, uuid: hive.hiveUuid })
      );
      return data.data;
    } catch (error: any) {
      return rejectWithValue(error?.message);
    }
  }
);

export const generateTextToVideo = createAsyncThunk(
  "ai/generateTextToVideo",
  async (body: FormData, { rejectWithValue, dispatch, getState }) => {
    try {
      const { data } = await api.ai.generateTextToVideo(body);
      const { hive } = getState() as RootState;

      dispatch(
        getUserDownloads({ id: +body.get("user_id")!, uuid: hive.hiveUuid })
      );
      return data.data;
    } catch (error: any) {
      return rejectWithValue(error?.message);
    }
  }
);

export const formToVideo = createAsyncThunk(
  "ai/formToVideo",
  async (body: FormData, { rejectWithValue, dispatch, getState }) => {
    try {
      const { data } = await api.ai.formToVideo(body);
      const { hive } = getState() as RootState;

      dispatch(
        getUserDownloads({ id: +body.get("user_id")!, uuid: hive.hiveUuid })
      );
      return data.data;
    } catch (error: any) {
      return rejectWithValue(error?.message);
    }
  }
);

export const transposeVideo = createAsyncThunk(
  "ai/transposeVideo",
  async (body: FormData, { rejectWithValue, dispatch, getState }) => {
    try {
      const { data } = await api.ai.createTranspose(body);
      const { hive } = getState() as RootState;

      dispatch(
        getUserDownloads({ id: +body.get("user_id")!, uuid: hive.hiveUuid })
      );
      return data.data;
    } catch (error: any) {
      return rejectWithValue(error?.message);
    }
  }
);

export const createTeaser = createAsyncThunk(
  "ai/createTeaser",
  async (
    body: { formData: FormData; userId: number },
    { rejectWithValue, dispatch, getState }
  ) => {
    try {
      const { data } = await api.ai.createTeaser(body.formData);

      const { hive } = getState() as RootState;

      dispatch(getUserDownloads({ id: body.userId, uuid: hive.hiveUuid }));
      return data.data;
    } catch (error: any) {
      return rejectWithValue(error?.message);
    }
  }
);

export const pdfToVideo = createAsyncThunk(
  "ai/pdfToVideo",
  async (
    body: { formData: FormData; userId: number },
    { rejectWithValue, dispatch, getState }
  ) => {
    try {
      const { data } = await api.ai.pdfToVideo(body.formData);

      const { hive } = getState() as RootState;

      dispatch(getUserDownloads({ id: body.userId, uuid: hive.hiveUuid }));
      return data.data;
    } catch (error: any) {
      return rejectWithValue(error?.message);
    }
  }
);

export const pdfToStory = createAsyncThunk(
  "ai/pdfToStory",
  async (
    body: { formData: FormData; userId: number },
    { rejectWithValue, dispatch, getState }
  ) => {
    try {
      const { data } = await api.ai.pdfToStory(body.formData);

      const { hive } = getState() as RootState;

      dispatch(getUserDownloads({ id: body.userId, uuid: hive.hiveUuid }));
      return data.data;
    } catch (error: any) {
      return rejectWithValue(error?.message);
    }
  }
);

export const compileTopics = createAsyncThunk(
  "ai/compileTopics",
  async (
    body: {
      obj: {
        file_uid: string;
        file: string;
        timestamps: any[];
        user_topics: number[];
      };
      userId: number;
    },
    { rejectWithValue, dispatch, getState }
  ) => {
    try {
      const { data } = await api.ai.compileTopics(body.obj);

      const { hive } = getState() as RootState;

      dispatch(getUserDownloads({ id: body.userId, uuid: hive.hiveUuid }));
      return data.data;
    } catch (error: any) {
      return rejectWithValue(error?.message);
    }
  }
);

export const generateTopics = createAsyncThunk(
  "ai/generateTopics",
  async (body: { formData: FormData; userId: number }, { rejectWithValue }) => {
    try {
      const response = await api.ai.generateTopics(body.formData);
      return response.data.body;
    } catch (error: any) {
      return rejectWithValue(error?.message);
    }
  }
);

export const getUserDownloads = createAsyncThunk(
  "ai/getUserDownloads",
  async (body: { id: number; uuid: string }, { rejectWithValue }) => {
    try {
      const { data } = await api.ai.getUserDownloads(body);
      return data.data;
    } catch (error: any) {
      return rejectWithValue(error?.message);
    }
  }
);

export const getReadyVideos = createAsyncThunk(
  "ai/getReadyVideos",
  async (body: { id: number; uuid: string }, { rejectWithValue }) => {
    try {
      const { data } = await api.ai.getReadyVideos(body);
      return data.data;
    } catch (error: any) {
      return rejectWithValue(error?.message);
    }
  }
);
