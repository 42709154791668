import axios, { AxiosError, AxiosInstance } from "axios";

import { BASE_AUTH, BASE_URL } from "./config";
import analyticsApi, { AnalyticsApi } from "./routes/Analytics/analytics";
import channelsApi, { ChannelsApi } from "./routes/Channels/channelDetails";
import chatApi, { ChatApi } from "./routes/Chat/chat";
import communityApi, { CommunityApi } from "./routes/Chat/community";
import contentApi, { ContentApi } from "./routes/Content/content";
import feedApi, { FeedApi } from "./routes/Feed/feed";
import hivePageApi, { HivePageApi } from "./routes/Hive/hivePage";
import profileApi, { ProfileApi } from "./routes/Profile/profile";
import searchApi, { SearchApi } from "./routes/Search/search";
import videosApi, { VideosApi } from "./routes/Videos/videos";
import { v4 as uuidv4 } from "uuid";
import aiApi, { AiApi } from "./routes/Ai/ai";
import storyApi, { StoryApi } from "./routes/Story/story";
import { useAppDispatch } from "hooks/redux";
import notificationApi, {
  NotificationApi,
} from "./routes/Notifications/notifications";
import eventsApi, { EventsApi } from "./routes/Hive/hiveEvents";
import subscriptionsApi, {
  SubscriptionsApi,
} from "./routes/Subscriptions/subscriptions";
import campaignApi, { CampaignApi } from "./routes/Campaign/campaign";
import formsApi, { FormsApi } from "./routes/Forms/forms";
import mapsApi, { MapsApi } from "./routes/Maps/maps";

type Token = string | null;

class ClientApi {
  private token: Token =
    "eyJhbGciOiJSUzI1NiJ9.eyJzdWIiOiIxMzI0IiwiUHJvZmlsZUlEIjoiOTk4YjljODYtZmM0OC00MzI0LThkZDUtZDMwNDM1ZWQ3MjA1IiwidXNlcklkIjoxMzI0LCJpYXQiOjE2ODI5NDM5MjgsImV4cCI6MTY4Mzk0MzkyOH0.Kk3zGi799695-e01VVKYSYBG_GxjPAJSs0JPz2TtdDS3e_1tfRdkJtdIszwD1Uc6KnmV_1fDl09fqGNh1snDO0ovO9CVJpRmt3L17EJ4exwa0O6O7GN_FeEIEUm5fHWdyaGStUHg1oX-MmVQ_s7HIF9ImwU7PlIm2YrujC3W6XCHd8jSE00znAdH6VnC0YayiXa0cziv7HrkwbFG8ZOjEfuSmU8dxNrZKn-Ix882ZspHPLEBUipcFHAasws68il0gjdqRXicLlga0BGEhnVhK3VWBEJoD076eJVcl6MxCquVCySUOVY8hnYKocoxnCLcHYnkF1R-wheEMRz-dK8Tmw";
  private instance: AxiosInstance;

  public hivePage: HivePageApi;
  public channelPage: ChannelsApi;
  public videos: VideosApi;
  public analytics: AnalyticsApi;
  public feed: FeedApi;
  public profile: ProfileApi;
  public chat: ChatApi;
  public community: CommunityApi;
  public content: ContentApi;
  public search: SearchApi;
  public ai: AiApi;
  public story: StoryApi;
  public notifications: NotificationApi;
  public events: EventsApi;
  public subscription: SubscriptionsApi;
  public campaign: CampaignApi;
  public forms: FormsApi;
  public maps: MapsApi;

  constructor(url: string, private readonly _sessionId: string) {
    this.instance = axios.create({
      baseURL: `${url}`,
      // timeout: 15000,
    });

    // Add an interceptor to all requests except for ones to /no-intercept endpoint
    this.instance.interceptors.request.use(
      (config) => {
        if (localStorage.getItem("@jwtToken") !== null) {
          config.headers["Authorization"] = `Bearer ${localStorage.getItem(
            "@jwtToken"
          )}`;
        }
        config.headers["Basic-Auth"] = BASE_AUTH;
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );

    // Add a special interceptor for requests to /no-intercept endpoint
    const noInterceptInstance = axios.create({
      baseURL: `${url}`,
    });
    noInterceptInstance.interceptors.request.use(
      (config) => {
        config.headers["Basic-Auth"] = BASE_AUTH;
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
    // Add a special interceptor for requests to /no-interceptatall endpoint
    const noInterceptAtAllInstance = axios.create({
      baseURL: `${url}`,
    });
    noInterceptInstance.interceptors.request.use(
      (config) => {
        // config.headers["Basic-Auth"] = BASE_AUTH;
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );

    this.hivePage = hivePageApi(this.instance);
    this.channelPage = channelsApi(this.instance);
    this.videos = videosApi(this.instance);
    this.analytics = analyticsApi(this.instance);
    this.feed = feedApi(this.instance);
    this.profile = profileApi(this.instance);
    this.chat = chatApi(this.instance);
    this.community = communityApi(this.instance);
    this.content = contentApi(this.instance);
    this.search = searchApi(this.instance);
    this.ai = aiApi(this.instance);
    this.story = storyApi(this.instance);
    this.notifications = notificationApi(this.instance);
    this.events = eventsApi(this.instance);
    this.subscription = subscriptionsApi(this.instance);
    this.campaign = campaignApi(this.instance);
    this.forms = formsApi(this.instance);
    this.maps = mapsApi(this.instance);

    // Use the noInterceptInstance for requests to /no-intercept endpoint
    this.instance.interceptors.request.use((config) => {
      if (config.url?.startsWith(`https://s3.` || `https://ipapi.co`)) {
        return noInterceptInstance(config);
      }
      return config;
    });
    // Use the noInterceptAtAllInstance for requests to /no-intercept endpoint
    this.instance.interceptors.request.use((config) => {
      if (config.url?.startsWith(`https://ipapi.co`)) {
        return noInterceptAtAllInstance(config);
      }
      return config;
    });

    this.instance.interceptors.response.use(
      (res) => res,
      async (error: AxiosError) => {
        if (error.response?.status === 401) {
          await this.updateToken();
        }
        throw error;
      }
    );
  }

  get sessionId() {
    return this._sessionId;
  }

  public setToken(token: Token): void {
    this.token = token;
  }

  private async updateToken() {
    if (localStorage.getItem("@jwtToken") !== null) {
      await (async () => {
        try {
          const { data } = await this.profile.refreshToken();
          // this.setToken(data.data.authToken)
          if (data.responseInfo.httpCode === 200) {
            localStorage.setItem("@jwtToken", data.data.accessToken);
            window.location.reload();
          }
          if (data.responseInfo.httpCode === 300) {
            localStorage.setItem("isLoggedIn", "no");
            localStorage.removeItem("@jwtToken");
            //   this.hivePage.getHiveDetails({
            //     communitySubDomain: localStorage.getItem("subDomain")!,
            //   });
            //   this.hivePage.getHiveComponents({
            //     communityDomain: localStorage.getItem("subDomain"),
            //   });
          }
        } catch (error) {
          localStorage.setItem("isLoggedIn", "no");
          localStorage.removeItem("@jwtToken");
          // localStorage.setItem("isLoggedIn", "no");
          // this.hivePage.getHiveDetails({
          //   communitySubDomain: localStorage.getItem("subDomain")!,
          // });
          // this.hivePage.getHiveComponents({
          //   communityDomain: localStorage.getItem("subDomain"),
          // });
        }
      })();
      // const { data } = await this.profile.getProfile();
    }
  }
}

export default new ClientApi(BASE_URL as string, uuidv4());
