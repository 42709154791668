import { TextField } from "@mui/material";
import LineBreak from "components/LineBreak";
import { useAppSelector } from "hooks/redux";
import React, { Dispatch, SetStateAction, useState } from "react";
import { getHiveSelector } from "store/reducers/HiveDetails/hiveDetails";
import EmailHeaderDropzone from "./EmailHeaderDropzone";
import { getEmailBannerS3UrlSelector } from "store/reducers/Campaign/campaign";

interface Props {
  handleChange: any;
  emailDetails: any;
  addCta: boolean;
  setAddCta: Dispatch<SetStateAction<boolean>>;
  readonly?: boolean;
  addStory: boolean;
  setAddStory: Dispatch<SetStateAction<boolean>>;
}

const EmailTemplate2 = ({
  handleChange,
  emailDetails,
  addCta,
  setAddCta,
  readonly,
  addStory,
  setAddStory,
}: Props) => {
  const hiveDetails = useAppSelector(getHiveSelector);

  const emailBannerUrl = useAppSelector(getEmailBannerS3UrlSelector);

  return (
    <>
      <div className="">
        {/* <img
          src={hiveDetails?.communityWebLogo || ""}
          alt="hive_logo"
          className="email_logo"
        /> */}
        <EmailHeaderDropzone
          bannerUrl={emailBannerUrl || emailDetails.headerImage}
        />

        <div className="page_padding">
          <LineBreak />
          <input
            readOnly={readonly ? readonly : false}
            onChange={handleChange}
            value={emailDetails.greeting}
            className="h3 email_input"
            name="greeting"
          />
          <LineBreak />
          <textarea
            readOnly={readonly ? readonly : false}
            onChange={handleChange}
            className="empty_input"
            value={emailDetails.heading}
            name="heading"
          />
          <LineBreak />
          <textarea
            readOnly={readonly ? readonly : false}
            onChange={handleChange}
            className="empty_input"
            value={emailDetails.para1}
            name="para1"
          />
          <LineBreak />
          {addCta && (
            <input
              readOnly={readonly ? readonly : false}
              onChange={handleChange}
              value={emailDetails.ctaName}
              name="ctaName"
              className="h4 wkffBtn btn_email_input"
            />
          )}
          <LineBreak />
          <textarea
            readOnly={readonly ? readonly : false}
            onChange={handleChange}
            className="empty_input"
            value={emailDetails.para2}
            name="para2"
          />
          <LineBreak />
          <textarea
            readOnly={readonly ? readonly : false}
            onChange={handleChange}
            className="empty_input"
            value={emailDetails.para3}
            name="para3"
          />
          <LineBreak />
          {addStory && <div className="email_story_link">View Story</div>}
          <LineBreak />
          <input
            readOnly={readonly ? readonly : false}
            onChange={handleChange}
            value={emailDetails.signOff}
            name="signOff"
            className="h4 email_input"
          />
          <input
            readOnly={readonly ? readonly : false}
            onChange={handleChange}
            value={emailDetails.signOffName}
            name="signOffName"
            className="h4 email_input"
          />
          <input
            readOnly={readonly ? readonly : false}
            onChange={handleChange}
            value={emailDetails.signOffDesignation}
            name="signOffDesignation"
            className="h4 email_input"
          />
        </div>
      </div>
      <div className="email_template2_footer">
        <div className="email_footer_socials">
          <img
            width="24px"
            height="24px"
            src="https://images.veehive.ai/email/facebook-4-48.png"
          />

          <img
            width="28px"
            height="28px"
            src="https://images.veehive.ai/email/icons8-instagram-48.png"
          />

          <img
            width="26px"
            height="26px"
            src="https://images.veehive.ai/email/icons8-whatsapp-48.png"
          />
        </div>
        <div className="email_copyright_footer">
          <div>{"© Copyright "}</div>
          <div className="copyright_spacer"> </div>
          <input
            readOnly={readonly ? readonly : false}
            onChange={handleChange}
            value={emailDetails.copyrightName}
            name="copyrightName"
            className="email_input_footer"
          />
        </div>
      </div>
      <LineBreak />
      {!readonly && (
        <p
          onClick={() => {
            setAddCta((prevState) => !prevState);
          }}
          className="link"
        >
          {!addCta ? "Add " : "Remove "}CTA
        </p>
      )}
      <LineBreak />
      {!readonly && (
        <p
          onClick={() => {
            setAddStory((prevState) => !prevState);
          }}
          className="link"
        >
          {!addStory ? "Add " : "Remove "}Story Link
        </p>
      )}
      <LineBreak />

      {!readonly && addCta && (
        <>
          <TextField
            variant="standard"
            onChange={handleChange}
            value={emailDetails.ctaName.slice(0, 40)}
            name="ctaName"
            label="CTA Name"
            placeholder="Enter name"
          />
          <LineBreak />
          <TextField
            fullWidth
            variant="standard"
            onChange={handleChange}
            value={emailDetails.ctaLink}
            name="ctaLink"
            label="CTA Link"
            placeholder="Enter link"
          />
        </>
      )}

      <LineBreak />
      {!readonly && addStory && (
        <>
          <TextField
            fullWidth
            variant="standard"
            onChange={handleChange}
            value={emailDetails.storyCta}
            name="storyCta"
            label="Story Link"
            placeholder="Enter link"
          />
        </>
      )}

      <LineBreak />
      {!readonly && (
        <>
          <h4>Social Links</h4>
          <TextField
            fullWidth
            variant="standard"
            onChange={handleChange}
            value={emailDetails.facebookLink}
            name="facebookLink"
            label="Facebook Link"
            placeholder="Enter link"
          />
          <LineBreak />
          <TextField
            fullWidth
            variant="standard"
            onChange={handleChange}
            value={emailDetails.instagramLink}
            name="instagramLink"
            label="Instagram Link"
            placeholder="Enter link"
          />
          <LineBreak />
          <TextField
            fullWidth
            variant="standard"
            onChange={handleChange}
            value={emailDetails.whatsappLink}
            name="whatsappLink"
            label="Whatsapp Link"
            placeholder="Enter link"
          />
        </>
      )}
    </>
  );
};

export default EmailTemplate2;
