import BackButton from "components/BackButton";
import LineBreak from "components/LineBreak";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import React, { useEffect, useState } from "react";
import { getAllStatusChannels } from "store/async-actions/Channels/channels";
import { getAllStatusChannelsSelector } from "store/reducers/ChannelDetails/channelList";
import { getHiveUuid } from "store/reducers/HiveDetails/hiveDetails";
import HiveTabs from "./HiveSettingsTab";
import { setHiveTab } from "store/reducers/HiveDetails/hiveSettings";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import ChannelsTable from "./ChannelsTable";

const AllChannelList = () => {
  const dispatch = useAppDispatch();

  const channelList = useAppSelector(getAllStatusChannelsSelector);

  const hiveUuid = useAppSelector(getHiveUuid);

  const [status, setStatus] = useState("APPROVED");

  useEffect(() => {
    dispatch(
      getAllStatusChannels({
        organizationUuid: hiveUuid,
        status: status,
      })
    );
  }, [dispatch, hiveUuid, status]);

  useEffect(() => {
    dispatch(setHiveTab(3));
  }, [dispatch]);

  const handleChange = (channel: SelectChangeEvent) => {
    setStatus(channel.target.value);
  };

  return (
    <div className="ce_about_wrapper">
      <div className="back_button_spacing">
        <BackButton to="/home" />
      </div>
      <HiveTabs />
      <div className="about_container">
        <div className="custom_select_wrapper">
          <FormControl variant="standard" sx={{ minWidth: 120 }}>
            <InputLabel id="date_custome_selector">Channel Status</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={status}
              label="Channel Status"
              onChange={handleChange}
            >
              <MenuItem value="APPROVED">Active</MenuItem>
              <MenuItem value="ARCHIVE">Archived</MenuItem>
            </Select>
          </FormControl>
        </div>
        {!!channelList && !!channelList.length && (
          <ChannelsTable status={status} />
        )}
      </div>
    </div>
  );
};

export default AllChannelList;
