import { useAppDispatch, useAppSelector } from "hooks/redux";
import React, { useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import LineBreak from "components/LineBreak";
import {
  getPurchaseChannelListSelector,
  getRedirectLinksSelector,
  setAnalyticsTab,
  setCurrentChannelId,
  setCurrentRedirectLink,
} from "store/reducers/Analytics/analytics";
import LongText from "components/LongText/LongText";
import { PurchaseChannelModel } from "api/models/Analytics/metrics";
import { useNavigate } from "react-router-dom";
import { getHiveUuid } from "store/reducers/HiveDetails/hiveDetails";
import { getRedirectLinkList } from "store/async-actions/Analytics/analyticsActions";

interface Props {
  setStep: any;
}

const RedirectLinksTable = ({ setStep }: Props) => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const hiveUuid = useAppSelector(getHiveUuid);

  useEffect(() => {
    dispatch(setAnalyticsTab(3));

    if (hiveUuid) {
      dispatch(
        getRedirectLinkList({
          organizationUuid: hiveUuid,
        })
      );
    }

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, hiveUuid]);

  const redirectLinks = useAppSelector(getRedirectLinksSelector);

  const handleClick = (link: string) => {
    dispatch(setCurrentRedirectLink(link));
    setStep(1);
  };

  return (
    <>
      <LineBreak />
      {!!redirectLinks && (
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>
                  {" "}
                  <p className="bold_heading_table">Sr No.</p>
                </TableCell>
                <TableCell>
                  {" "}
                  <p className="bold_heading_table">Link</p>
                </TableCell>
              </TableRow>
            </TableHead>
            {redirectLinks.map((row, idx) => (
              <TableBody>
                <TableRow
                  onClick={() => handleClick(row.redirectName)}
                  className="table_cell_hover pointer"
                  key={idx}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {idx + 1}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {<LongText title={row.redirectName} cutoff={36} /> || "-"}
                  </TableCell>
                </TableRow>
              </TableBody>
            ))}
          </Table>
        </TableContainer>
      )}
    </>
  );
};

export default RedirectLinksTable;
