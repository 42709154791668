import IslandLayout from "components/IslandLayout";
import LineBreak from "components/LineBreak";
import React, { useEffect } from "react";
import UploadGallery from "./components/UploadGallery";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import {
  getCurrentEventSelector,
  getGalleryItemsSelector,
} from "store/reducers/HiveDetails/events";
import {
  getGalleryEvent,
  removeGalleryEvent,
} from "store/async-actions/Hive/eventsActions";
import BackButton from "components/BackButton";
import { useNavigate } from "react-router";
import {
  DeleteStorySVG,
  DeleteStoryWhiteSVG,
  RedirectSVG,
} from "components/SVG/SVG";
import { GalleryItem } from "api/models/Hive/events";
import { getChildSelector } from "store/reducers/ChildComponents/childComponents";

type Props = {};

const EventGalleryPage = (props: Props) => {
  const currentEvent = useAppSelector(getCurrentEventSelector);
  const dispatch = useAppDispatch();

  const items = useAppSelector(getGalleryItemsSelector);

  const navigate = useNavigate();

  useEffect(() => {
    currentEvent
      ? dispatch(
          getGalleryEvent({
            eventUuid: currentEvent.eventUuid,
          })
        )
      : navigate("/events");
  }, [currentEvent]);

  const handleRedirect = (item: GalleryItem) => {
    window.open(item.galleryUrl, "_blank");
  };

  const handleDelete = (item: GalleryItem) => {
    dispatch(
      removeGalleryEvent({
        eventUuid: item.eventUuid,
        galleryId: item.id,
      })
    );
  };

  const child = useAppSelector(getChildSelector);

  return (
    <IslandLayout>
      <div className="page_padding">
        <BackButton to={`/events/${currentEvent?.eventIdentifier}`} />
        <LineBreak />
        <h2>Gallery</h2>
        <LineBreak />
        <div className="gallery_items_container">
          {items.map((data, idx) => {
            return (
              <div className="gallery_item">
                <img
                  className="gallery_image"
                  key={idx}
                  src={data.thumbnailUrl || data.galleryUrl}
                />
                <div className="gallery_action_items">
                  <div
                    className="gallery_action_item"
                    onClick={() => handleRedirect(data)}
                  >
                    <RedirectSVG />
                  </div>
                  {child[0] && (
                    <div
                      className="gallery_action_item"
                      onClick={() => handleDelete(data)}
                    >
                      <DeleteStoryWhiteSVG />
                    </div>
                  )}
                </div>
              </div>
            );
          })}
          {items.length < 1 && (
            <h4>
              Looks like the media gallery is feeling a bit lonely! Spice it up
              by uploading more media to bring it to life!
            </h4>
          )}
        </div>
        <LineBreak />
        {child[0] && (
          <>
            <h3>Add More Media</h3>
            <LineBreak />
            <UploadGallery />
            <LineBreak />
          </>
        )}
      </div>
    </IslandLayout>
  );
};

export default EventGalleryPage;
