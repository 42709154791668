import { StoryItemModel } from "api/models/Story/story";
import LongText from "components/LongText/LongText";
import { StoryKebabSVG } from "components/SVG/SVG";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { toastInfo } from "hooks/useToastify";
import React, { Dispatch, SetStateAction } from "react";
import { getStoriesComponents } from "store/reducers/ChildComponents/childComponents";
import StoryOptions from "./StoryOptions";
import { useNavigate } from "react-router-dom";
import { setViewingStoryUuid } from "store/reducers/Story/story";
import { getStoryItem } from "store/async-actions/Story/storyActions";

interface Props {
  story: StoryItemModel;
  idx: number;
  setKebabClicked: Dispatch<SetStateAction<string>>;
  kebabClicked: string;
}

const AllStoriesView = ({
  story,
  idx,
  kebabClicked,
  setKebabClicked,
}: Props) => {
  const storiesChild = useAppSelector(getStoriesComponents);

  const handleKebab = () => {
    setKebabClicked(story.storyUuid);
  };

  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const handleStoryClick = () => {
    dispatch(setViewingStoryUuid(story.storyUuid));
    navigate(`/viewstory/start/?${story.storyUuid}`);
    if (story.storyPublishedUrl) {
      // window.open(story.storyPublishedUrl, "_blank");
    } else {
      toastInfo(
        "This story isnt available at the moment, please try another one"
      );
    }
  };

  const handleSetStory = () => {
    dispatch(getStoryItem({ storyUuid: story.storyUuid }));
  };

  return (
    <div onClick={handleSetStory} className="story_template">
      <div
        onClick={handleStoryClick}
        style={{ backgroundColor: story.colorCode || "" }}
        className="story_template_display_wrapper pointer"
      >
        {story.thumbnailUrl && (
          <img
            src={story.thumbnailUrl || ""}
            alt=""
            className="ai_template_img story_template_img"
          />
        )}
      </div>
      <div className="story_template_content_wrapper">
        <p className="text-sm font-bold story_template_description">
          <LongText title={story.storyTitle} cutoff={16} />
        </p>
        {!!storiesChild &&
          storiesChild[0] &&
          !!storiesChild[0].componentName && (
            <div className="pointer" onClick={handleKebab}>
              <StoryKebabSVG />
            </div>
          )}
      </div>
      {kebabClicked === story.storyUuid && (
        <StoryOptions
          kebabClicked={kebabClicked}
          story={story}
          setKebabClicked={setKebabClicked}
        />
      )}
    </div>
  );
};

export default AllStoriesView;
