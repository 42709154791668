import { useAppDispatch, useAppSelector } from "hooks/redux";
import React, { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { getSpotlight } from "store/async-actions/Videos/videosActions";
import {
  getHiveSelector,
  launchLogin,
} from "store/reducers/HiveDetails/hiveDetails";
import {
  getSpotlightVidsSelector,
  setCurrentVideo,
} from "store/reducers/Videos/videoList";

const SpotlightMain = () => {
  const dispatch = useAppDispatch();
  const videos = useAppSelector(getSpotlightVidsSelector);

  const hiveDetails = useAppSelector(getHiveSelector);

  const navigate = useNavigate();

  useEffect(() => {
    hiveDetails &&
      dispatch(getSpotlight({ communityId: hiveDetails.communityId }));

    localStorage.setItem("path", "/spotlight");

    if (localStorage.getItem("isLoggedIn") === "yes") {
      if (!!videos && videos.videoList[0]) {
        dispatch(setCurrentVideo(videos.videoList[0]));
        navigate(`/spotlight/edit/${videos.videoList[0].videoUuid}`);
      } else {
        navigate("/spotlight/upload");
      }
    } else {
      launchLogin();
    }
  }, []);

  return <Outlet />;
};

export default SpotlightMain;
