import React, { createContext, useContext } from 'react'

import SettingsNameSections from './components/SettingsNameSections'
import BioSections from './components/BioSections'

interface SettingsChatRoomProps {
  roomId?: UniqueId
  isCreate?: boolean
  isOwner?: boolean
  nameValue: string
  bioValue: string
  logo?: string
  changeNameValue: (value: string) => void
  changeBioValue: (value: string) => void
  changeLogo?: (file: File) => void
  submitName?: () => void
  submitBio?: () => void
}

interface ContextValues {
  isOwner: boolean
  isCreate: boolean
  roomId: string
  nameValue: string
  bioValue: string
  logo?: string
  changeNameValue: (value: string) => void
  changeBioValue: (value: string) => void
  changeLogo?: (file: File) => void
  submitName?: () => void
  submitBio?: () => void
}

const SettingsContext = createContext<ContextValues>({} as ContextValues)

export const useSettingsContext = () => useContext(SettingsContext)

const SettingsChatRoom: React.FC<SettingsChatRoomProps> = ({
  isCreate = false,
  isOwner = false,
  roomId = '',
  nameValue = '',
  bioValue = '',
  changeNameValue,
  changeBioValue,
  changeLogo,
  logo,
  submitName,
  submitBio,
}) => {
  return (
    <SettingsContext.Provider
      value={{
        isOwner,
        isCreate,
        roomId,
        nameValue,
        bioValue,
        changeNameValue,
        changeBioValue,
        changeLogo,
        logo,
        submitName,
        submitBio,
      }}
    >
      <div className="chat_room_settings_container">
        <SettingsNameSections />
        <BioSections />
      </div>
    </SettingsContext.Provider>
  )
}

export default SettingsChatRoom
