import { CircularProgress, Tab, Tabs } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import { AiTemplateItem } from "api/models/Ai/ai";
import AiDropzone from "components/AiDropzone";
import BackButton from "components/BackButton/BackButton";
import LineBreak from "components/LineBreak/LineBreak";
import { SelectedSVG } from "components/SVG/SVG";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  getAiTemplates,
  textToVideo,
  textToVideoGenerate,
} from "store/async-actions/Ai/ai";
import {
  clearAiImages,
  getActiveTemplateSelector,
  getAiShowNext,
  getAiTemplatesSelector,
  getSelectedTemplateSelector,
  getTtvGeneratedTextSelector,
  getTtvIsDoneGeneratingSelector,
  getTtvIsGeneratingSelector,
  getTtvTextSelector,
  getUploadedAiImagesSelector,
  setActiveTemplate,
  setIsUploaded,
  setOpenDownloads,
  setShowNext,
  setTtvIsDoneGenerating,
  setTtvText,
} from "store/reducers/Ai/ai";
import { getHiveUuid } from "store/reducers/HiveDetails/hiveDetails";
import { profileUserIdSelector } from "store/reducers/Profile/profile";

const TextToVideo = () => {
  const dispatch = useAppDispatch();

  const templates = useAppSelector(getAiTemplatesSelector);

  useEffect(() => {
    dispatch(getAiTemplates(null));
  }, [dispatch]);

  const showNext = useAppSelector(getAiShowNext);

  const text = useAppSelector(getTtvTextSelector);

  const handleTextChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    dispatch(
      setTtvText(
        e.target.value.slice(
          0,
          activeTab === 0 ? 240 : selectedTemplate?.maxChars
        )
      )
    );
  };

  const userId = useAppSelector(profileUserIdSelector);
  const hiveUuid = useAppSelector(getHiveUuid);

  const activeTemplate = useAppSelector(getActiveTemplateSelector);
  const selectedTemplate = useAppSelector(getSelectedTemplateSelector);

  const handleTemplateClick = (template: AiTemplateItem) => {
    dispatch(setActiveTemplate(template));
  };

  const navigate = useNavigate();

  const uploadedImages = useAppSelector(getUploadedAiImagesSelector);

  const isFetching = useAppSelector(getTtvIsGeneratingSelector);

  const isDone = useAppSelector(getTtvIsDoneGeneratingSelector);

  const textGeneratedResp = useAppSelector(getTtvGeneratedTextSelector);

  const handleCreate = () => {
    dispatch(
      textToVideoGenerate({
        user_id: userId.toString(),
        text: text,
        templateId: activeTemplate,
        isGenerative: activeTab === 0,
        organizationUuid: hiveUuid,
        durationInSeconds: selectedTemplate?.duration || 0,
      })
    );
  };

  useEffect(() => {
    if (isDone && !!textGeneratedResp && !!textGeneratedResp.content_text) {
      dispatch(setTtvIsDoneGenerating(false));
      navigate("create");
    }
  }, [isDone, textGeneratedResp]);

  const handleCreateTTV = () => {
    const formData = new FormData();
    formData.append("user_id", userId.toString());
    formData.append("text", text);
    uploadedImages.map((image, index) => {
      formData.append("images", image, image.name);
    });
    // formData.append("templateId", "51924fe808ff427493a38e9e3a69aa84 ");
    formData.append("organizationUuid", hiveUuid);
    formData.append("templateId", activeTemplate);
    formData.append("isGenerative", `${activeTab === 0 ? "True" : "False"}`);
    dispatch(textToVideo(formData));
    navigate("..");
    dispatch(setOpenDownloads(true));
    handleClearAiImages();
  };

  const [activeTab, setActiveTab] = useState(0);

  const tabs = ["Generative", "Custom"];

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  const handleClearAiImages = () => {
    dispatch(clearAiImages());
    dispatch(setIsUploaded(false));
    dispatch(setShowNext(false));
  };

  return (
    <div className="ttv_container">
      <BackButton />
      <LineBreak />
      <h4 className="tags">Choose a template</h4>
      <div className="all_ai_templates">
        {templates.map((template, idx) => {
          return (
            <div className="ai_template_wrapper_container">
              <h5 className="text_right p-2">
                Duration: {template?.duration || 1000} secs{" "}
              </h5>
              <div className="ai_template_wrapper">
                <img
                  onClick={() => handleTemplateClick(template)}
                  src={
                    template.templateImage ||
                    "https://veehivestage-images.s3.us-east-2.amazonaws.com/channelImages/defaultChannelLogo.jpg"
                  }
                  alt=""
                  className="ai_template_img"
                />
                {activeTemplate === template.templateId && (
                  <div className="ai_template_selected_img">
                    <SelectedSVG />
                  </div>
                )}
              </div>
              <div className="ai_template_wrapper_footer">
                <div className="secondaryBtnAi text_xs">Story Size</div>
                <p>1080x1920</p>
              </div>
            </div>
          );
        })}
      </div>
      <LineBreak />
      <div className="title_and_limit">
        <h4 className="tags">
          Upload Images{" "}
          {activeTemplate === "110c9963648d4692bb0e288cbb0d6ed2" ||
          activeTemplate === "101c9963648d4692bb0e288cbb0d6ed3" ||
          activeTemplate === "100c9963648d4692bb0e288cbb0d6ed1"
            ? ""
            : "(optional)"}
        </h4>
        {uploadedImages[0] && (
          <p onClick={handleClearAiImages} className="link text-sm">
            Clear all
          </p>
        )}
      </div>
      <LineBreak />
      <AiDropzone
        mandatory={
          activeTemplate === "110c9963648d4692bb0e288cbb0d6ed2" ||
          activeTemplate === "101c9963648d4692bb0e288cbb0d6ed3" ||
          activeTemplate === "100c9963648d4692bb0e288cbb0d6ed1"
            ? true
            : false
        }
      />
      <LineBreak />
      <div className="script_mode_ttv">
        <h4>Script Mode:</h4>
        <Tabs
          value={activeTab}
          onChange={handleChange}
          aria-label="channel tabs"
        >
          {tabs.map((tab, idx) => {
            return (
              <Tab
                style={{
                  padding: "1px",
                  width: "10%",
                  height: "20px",
                  fontFamily: "IBM Plex Sans Condensed",
                  fontStyle: "normal",
                  fontWeight: 600,
                  fontSize: "14px",
                  lineHeight: "50%",
                  textAlign: "center",
                  letterSpacing: "0.02em",
                  color: "#1C1B1F",
                }}
                label={tab}
              />
            );
          })}
        </Tabs>
      </div>
      <LineBreak />
      <div className="ttv_text_area_wrapper">
        <textarea
          value={text}
          onChange={handleTextChange}
          className="ttv_text_area"
          placeholder={
            activeTab === 0
              ? "Enter keywords to generate a script for the video"
              : "Enter a script to generate a video accordingly"
          }
        ></textarea>
        <div className="character_limit character_limit_ttv text-sm">
          {text.length}/{activeTab === 0 ? 240 : selectedTemplate?.maxChars}
        </div>
      </div>
      {activeTemplate === "110c9963648d4692bb0e288cbb0d6ed2" ||
      activeTemplate === "101c9963648d4692bb0e288cbb0d6ed3" ||
      activeTemplate === "100c9963648d4692bb0e288cbb0d6ed1" ? (
        <>
          {isFetching ? (
            <div className="nextBtn primaryBtn">
              <CircularProgress size={20} color="inherit" />
            </div>
          ) : (
            <>
              {text.length > 0 && uploadedImages[0] ? (
                <div onClick={handleCreate} className="nextBtn primaryBtn">
                  Next
                </div>
              ) : (
                <div className="nextBtn disabledBtn">Next</div>
              )}
            </>
          )}
        </>
      ) : (
        <>
          {text.length > 0 ? (
            <div onClick={handleCreateTTV} className="nextBtn primaryBtn">
              Create
            </div>
          ) : (
            <div className="nextBtn disabledBtn">Create</div>
          )}
        </>
      )}
    </div>
  );
};

export default TextToVideo;
