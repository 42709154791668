import IslandLayout from "components/IslandLayout";
import PageLayout from "components/PageLayout";
import React, { useEffect } from "react";
import AllPlansView from "./AllPlansView";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import {
  getAllPlans,
  getCurrentPlan,
} from "store/async-actions/Subscriptions/subscriptions";
import { getHiveSelector } from "store/reducers/HiveDetails/hiveDetails";
import {
  getCurrentPlanSelector,
  getPlanPaymentChargedSelector,
  setPlanPaymentCharged,
} from "store/reducers/Subscriptions/subscriptions";
import BackButton from "components/BackButton/BackButton";
import LineBreak from "components/LineBreak/LineBreak";

const PlansContainer = () => {
  const dispatch = useAppDispatch();

  const hiveDetails = useAppSelector(getHiveSelector);

  useEffect(() => {
    dispatch(setPlanPaymentCharged(false));
    dispatch(getCurrentPlan({ organizationId: hiveDetails?.communityId || 2 }));
    dispatch(
      getAllPlans({ organizationUuid: hiveDetails?.communityUuid || "" })
    );
  }, [dispatch]);

  const currentPlan = useAppSelector(getCurrentPlanSelector);

  const paymentCharged = useAppSelector(getPlanPaymentChargedSelector);

  return (
    // <PageLayout sideMenu={true}>
    <IslandLayout>
      <div className="plans_home_container">
        <BackButton />
        <LineBreak />
        <div className="allplan_templates_container">
          <div>
            <h1>Flexible Plans</h1>
            <p className="text-sm fw-1 tags">
              You are currently on a {currentPlan?.planName} Plan.
            </p>
          </div>
        </div>
        <div className="all_plans_items_wrapper">
          <AllPlansView />
        </div>
      </div>
    </IslandLayout>
    // </PageLayout>
  );
};

export default PlansContainer;
