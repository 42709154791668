import React from 'react'

import Input from 'components/common/Input'
import EditFieldButton from 'components/EditFieldButton'
import Text from 'components/common/Text'
import { useSettingsContext } from '../SettingsChatRoom'

const BioSections = () => {
  const { isOwner, isCreate, bioValue, changeBioValue, submitBio } =
    useSettingsContext()

  const handleChanheBio = (event: React.ChangeEvent<HTMLInputElement>) => {
    changeBioValue(event.target.value)
  }

  const handleSubmit = () => {
    if (typeof submitBio === 'function') {
      submitBio()
    }
  }

  return (
    <div className="bio_section_container">
      <div className="label_block">
        <Text fontWeight="w500" className="bio_label_text">
          Bio
        </Text>
        {isOwner && <EditFieldButton onClick={handleSubmit} />}
      </div>
      <Input
        multiline
        maxRows={3}
        placeholder="Short description"
        defaultValue={bioValue}
        className="bio_setting_input"
        helperText="The group bio is visible to participants of this chatroom."
        readOnly={!(isOwner || isCreate)}
        onChange={handleChanheBio}
      />
    </div>
  )
}

export default BioSections
