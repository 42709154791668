import React, { useEffect } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { useNavigate } from "react-router-dom";
import {
  hiveTabIndexSelector,
  hiveTabsListSelector,
  setHiveTab,
} from "store/reducers/HiveDetails/hiveSettings";

const HiveTabs = () => {
  const tabsList = useAppSelector(hiveTabsListSelector);
  const selectedHiveTab = useAppSelector(hiveTabIndexSelector);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    dispatch(setHiveTab(newValue));
    navigate(`/home/settings/${tabsList[newValue].toLowerCase()}`);
  };

  return (
    // <div className="tabs_container">
    <Tabs
      value={selectedHiveTab}
      onChange={handleChange}
      aria-label="hive tabs"
      className="tabs-wrapper"
    >
      {tabsList.map((tab, idx) => {
        return (
          <Tab
            style={{
              width: "25%",
              height: "20px",
              fontFamily: "IBM Plex Sans Condensed",
              fontStyle: "normal",
              fontWeight: 600,
              fontSize: "14px",
              lineHeight: "140%",
              textAlign: "center",
              letterSpacing: "0.02em",
              color: "#1C1B1F",
            }}
            key={idx}
            label={tab}
          />
        );
      })}
    </Tabs>
    // </div>
  );
};

export default HiveTabs;
