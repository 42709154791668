import CSVImport from "components/CSVImport/CSVImport";
import ContactInput from "components/ContactInput/ContactInput";
import React, { useEffect, useState } from "react";

import { CSVLink } from "react-csv";
import { BackBtnSVG, CSVDownloadSVG } from "components/SVG/SVG";
import TokenInput from "react-customize-token-input";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import {
  getCSVErrorMessageSelector,
  getCSVSuccessMessageSelector,
  getImportCountSelector,
} from "store/reducers/HiveDetails/hiveActivities";
import {
  getPhoneUsersSelector,
  updatePhoneUsers,
} from "store/reducers/HiveDetails/hiveSettings";
import PageLayout from "components/PageLayout/PageLayout";
import IslandLayout from "components/IslandLayout/IslandLayout";
import LineBreak from "components/LineBreak/LineBreak";
import { TextField } from "@mui/material";
import { getCampaignUuidSelector } from "store/reducers/Campaign/campaign";
import { useNavigate } from "react-router-dom";
import { addCampaignNumbers } from "store/async-actions/Campaign/campaign";
import { toastSuccess } from "hooks/useToastify";

const csvPhoneData = [
  ["firstname", "lastname", "mobileNo", "userName", "userOrigin"],
  ["John", "Doe", "+971501234567", "itsjohn", "ACME Corp."],
  ["Jane", "Doe", "+918451942268", "itsjane", "ACME Dev."],
  ["Veehive", "", "+971558843692", "letsveehive", "Veehive FZ LLC."],
];

interface Props {
  handleBack: any;
  handleNext: any;
}

const AddNumbers = ({ handleBack, handleNext }: Props) => {
  const campaignUuid = useAppSelector(getCampaignUuidSelector);
  const navigate = useNavigate();

  // useEffect(() => {
  //   if (!!!campaignUuid) {
  //     navigate("/campaigns");
  //   }
  // }, []);

  const dispatch = useAppDispatch();

  const [emails, setEmails] = useState<string[]>([]);

  const [phoneNos, setPhoneNos] = useState<string[]>([]);

  const handlePhoneNumbersChange = (nos: string[]) => {
    setPhoneNos(nos);
  };

  const handleGetTokenDisplayLabel = (tokenValue: any, tokenMeta: any) => {
    // Could return any react node
    return <div className="rounded-full">{`${tokenValue}`}</div>;
  };

  const csvErrorMessage = useAppSelector(getCSVErrorMessageSelector);
  const setCSVSuccessMessage = useAppSelector(getCSVSuccessMessageSelector);
  const importCount = useAppSelector(getImportCountSelector);

  const handleTokenValueValidate = (e: string) => {
    const number = /^-?\d+$/;
    if (e.startsWith("+")) {
      if (number.test(e.slice(1))) {
        if (e.length > 9 && e.length < 15) {
          return null;
        } else {
          return true;
        }
      } else {
        return true;
      }
    } else {
      return true;
    }
  };

  const handleSave = () => {
    dispatch(
      addCampaignNumbers({
        smsNumbersList: phoneNos,
        campaignUuid: campaignUuid,
      })
    );
    toastSuccess("Created campaign successfully");
    handleNext();
  };

  return (
    // <PageLayout sideMenu={true}>
    //   <IslandLayout>
    <div className="">
      <div className="backBtn_spacing">
        <div className="go_back pointer" onClick={handleBack}>
          <BackBtnSVG />
          <p>Back</p>
        </div>
      </div>
      <h2>Add Phone Numbers</h2>
      <div className="csv_import_container_wrapper">
        <CSVImport
          setPhoneNos={setPhoneNos}
          selectedTab={1}
          phoneNos={phoneNos}
          emails={emails}
        />

        <CSVLink filename="phone_format" data={csvPhoneData}>
          <CSVDownloadSVG />
        </CSVLink>
      </div>
      <LineBreak />
      <span className="error_message">{csvErrorMessage}</span>
      <span className="success_message">{setCSVSuccessMessage}</span>
      <div className="">
        <TokenInput
          separators={[",", " "]}
          tokenValues={phoneNos}
          onTokenValuesChange={setPhoneNos}
          className=""
          onTokenValueValidate={handleTokenValueValidate}
          onGetTokenDisplayLabel={handleGetTokenDisplayLabel}
          placeholder="ex: +91234567891"
        />
      </div>
      <div className="campaign_nextBtn_container">
        {phoneNos.length > 0 ? (
          <div onClick={handleSave} className="primaryBtn campaign_nextBtn">
            Finish
          </div>
        ) : (
          <div className="campaign_nextBtn disabledBtn">Finish</div>
        )}
      </div>
    </div>
    //   </IslandLayout>
    // </PageLayout>
  );
};

export default AddNumbers;
