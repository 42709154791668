import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "api";
import { FeedRequestModel } from "api/models/Feed/Feed";

export const getFeedVideos = createAsyncThunk(
  "feed/getFeedVideos",
  async (body: FeedRequestModel, { rejectWithValue }) => {
    try {
      const { data } = await api.feed.getFeedVideos(body);
      return data.data;
    } catch (error) {
      return rejectWithValue("Something went wrong");
    }
  }
);
