import LineBreak from "components/LineBreak/LineBreak";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import React from "react";
import { useNavigate } from "react-router-dom";
import {
  changeRazorPlan,
  changeStripePlan,
} from "store/async-actions/Subscriptions/subscriptions";
import { getHiveUuid } from "store/reducers/HiveDetails/hiveDetails";
import { getPlanDetailsSelector } from "store/reducers/Subscriptions/subscriptions";

const RazorChangePlan = () => {
  const dispatch = useAppDispatch();
  const currentPlan = useAppSelector(getPlanDetailsSelector);
  const hiveUuid = useAppSelector(getHiveUuid);

  const navigate = useNavigate();

  const handleChange = () => {
    dispatch(
      changeRazorPlan({
        organizationUuid: hiveUuid,
        planUuid: currentPlan?.planUuid || "",
      })
    );

    navigate("/plans");
  };

  return (
    <>
      <div onClick={handleChange} className="primaryBtn plan_buyBtn">
        Swtich to {currentPlan?.planName}
      </div>
      {/* <p className="text-xs text-center">
        *Saved card will be used for any additional charges
      </p> */}
    </>
  );
};

export default RazorChangePlan;
