import HamburgerMenu from "components/Hamburger/HamburgerMenu";
import MainMenu from "components/MainMenu";
import { useAppSelector } from "hooks/redux";
import ChatRoomDetail from "pages/Chat/ChatRoomDetail";
import { ToastContainer } from "react-toastify";
import ChatRoomsPage from "pages/Chat/ChatRoomsPage";
import React, { useEffect, useRef } from "react";
import { getBurgerOpenSelector } from "store/reducers/Hamburger/hamburger";
import HiveConfig from "components/HiveConfig";
import { useChatMessages } from "pages/Chat/ChatRoomPage/useChatMessages";
import { Params, useParams } from "react-router-dom";
import DefaultChatRoom from "components/DefaultChatRoom";
import MobileMenu from "components/MainMenu/MobileMenu";

interface Props {
  sideMenu: boolean;
}

const MobileLayout: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  sideMenu,
}) => {
  const isOpen = useAppSelector(getBurgerOpenSelector);

  const { roomId = "" } = useParams<Params<"roomId">>();

  const { chatList } = useChatMessages(roomId);

  const chatContainerRef = useRef<HTMLDivElement>(null);

  const scrollChatToBottom = () => {
    const container = chatContainerRef.current;
    if (container) {
      container.scrollTop = container.scrollHeight;
    }
  };

  useEffect(() => {
    // Scroll to the bottom of the chat container on initial load and when chatList changes
    scrollChatToBottom();
  }, [chatList]);

  return (
    <div className={`${sideMenu && "page_container"}`}>
      <div className={`${sideMenu && "body_container"}`}>
        {isOpen ? <HamburgerMenu /> : children}
      </div>
      {/* <div className="mobile_main_menu_wrapper">
        <MobileMenu />
      </div> */}
    </div>
  );
};

export default MobileLayout;
