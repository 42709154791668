import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import { ChannelListModel } from "api/models/Hive/hiveChannels";
import ConfirmationModal from "components/Confirmation";
import LongText from "components/LongText/LongText";
import { ActiveChannelSVG, ArchiveChannelSVG } from "components/SVG/SVG";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import React, { useState } from "react";
import { changeChannelStatus } from "store/async-actions/Channels/channels";
import { getAllStatusChannelsSelector } from "store/reducers/ChannelDetails/channelList";

interface Props {
  status: string;
}

const ChannelsTable = ({ status }: Props) => {
  const channels = useAppSelector(getAllStatusChannelsSelector);

  const dispatch = useAppDispatch();

  const [channel, setChannel] = useState<ChannelListModel | undefined>(
    undefined
  );

  const handleChannelStatusChange = (channel: ChannelListModel) => {
    setChannel(channel);
    handleConfirmOpen();
  };

  const [isOpen, setIsOpen] = useState(false);

  const handleConfirmClose = () => {
    setIsOpen(false);
  };

  const handleConfirmOpen = () => {
    setIsOpen(true);
  };

  const handleChangeStatus = () => {
    dispatch(
      changeChannelStatus({
        channelId: channel?.channelId || 0,
        channelStatus: status === "APPROVED" ? "ARCHIVE" : "APPROVE",
        comments: "by admin",
        status: status === "APPROVED" ? "archive" : "unarchive",
      })
    );
    handleConfirmClose();
  };

  return (
    <TableContainer component={Paper}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>
              {" "}
              <p className="bold_heading_table">Channel Name</p>
            </TableCell>
            <TableCell align="center">
              {" "}
              <p className="bold_heading_table">Status</p>
            </TableCell>
            <TableCell align="center">
              {" "}
              <p className="bold_heading_table">Action</p>
            </TableCell>
          </TableRow>
        </TableHead>
        {channels.map((channel, idx) => (
          <TableBody>
            <TableRow
              className="table_cell_hover"
              key={idx}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                <div className="user_and_profile_table">
                  <img
                    key={idx}
                    src={
                      channel.webLogo ||
                      "https://veehivestage-images.s3.us-east-2.amazonaws.com/channelImages/defaultChannelLogo.jpg"
                    }
                    className="event_attendee_profile_table"
                  />
                  {(
                    <LongText title={channel.channelName || "-"} cutoff={16} />
                  ) || "-"}
                </div>
              </TableCell>
              <TableCell align="center" component="th" scope="row">
                {(
                  <LongText
                    title={
                      (channel.channelStatus === "APPROVED"
                        ? "Active"
                        : "Archived") || "-"
                    }
                    cutoff={16}
                  />
                ) || "-"}
              </TableCell>
              <TableCell align="center" component="th" scope="row">
                <div
                  onClick={() => handleChannelStatusChange(channel)}
                  className="flex justify-center pointer"
                >
                  {channel.channelStatus === "APPROVED" ? (
                    <ArchiveChannelSVG />
                  ) : (
                    <ActiveChannelSVG />
                  )}
                </div>
              </TableCell>
            </TableRow>
          </TableBody>
        ))}
      </Table>
      {isOpen && (
        <ConfirmationModal
          isOpen={isOpen}
          onReject={handleConfirmClose}
          onConfirm={handleChangeStatus}
          headline="Change Channel Status"
          description={`Are you sure you want to ${
            status === "APPROVED" ? "archive" : "approve"
          } ${channel?.channelName || "this channel"}?`}
          confirmMessage="Yes"
          rejectMessage="No"
        />
      )}
    </TableContainer>
  );
};

export default ChannelsTable;
