import IslandLayout from "components/IslandLayout";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import React, { useEffect } from "react";
import { getChannelListSelector } from "store/reducers/ChannelDetails/channelList";
import SpotlightEditSelect from "./components/SpotlightEditSelect";
import SpotlightEditInfo from "./components/SpotlightEditInfo";
import { setShowSpotlightTour } from "store/reducers/Videos/videos";
import SpotLightTourModal from "components/SpotlightTour/SpotlightTour";

const SpotlightEdit = () => {
  const channelList = useAppSelector(getChannelListSelector);

  const dispatch = useAppDispatch();

  return (
    <>
      <div className="upload_container">
        {channelList.length > 1 && (
          <div className="upload_section">
            <IslandLayout>
              <div className="upload_info scrollable-y">
                <SpotlightEditInfo />
              </div>
            </IslandLayout>
          </div>
        )}
        <div className="upload_section">
          <IslandLayout>
            <div className="video_select_wrapper">
              <SpotlightEditSelect />
            </div>
          </IslandLayout>
        </div>
      </div>

      {/* <Dialog open={isUploading}>
        <div className="popup_dialogue"> */}
      {/* </div>
      </Dialog> */}
    </>
  );
};

export default SpotlightEdit;
