import React, { useEffect } from "react";
import IslandLayout from "components/IslandLayout/IslandLayout";
import PageLayout from "components/PageLayout/PageLayout";
import { Outlet, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { getHiveUuid } from "store/reducers/HiveDetails/hiveDetails";
import { getForms } from "store/async-actions/Forms/forms";
import LineBreak from "components/LineBreak/LineBreak";
import { getFormsListSelector } from "store/reducers/Forms/forms";
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import moment from "moment";
import CampaignKebab from "pages/Campaign/components/CampaignKebab";
import FormKebab from "./components/FormKebab";

const FormsContainer = () => {
  const dispatch = useAppDispatch();
  const hiveUuid = useAppSelector(getHiveUuid);

  useEffect(() => {
    dispatch(getForms({ organizationUuid: hiveUuid }));
  }, [dispatch, hiveUuid]);

  const formsList = useAppSelector(getFormsListSelector);

  const navigate = useNavigate();

  const handleCreate = () => {
    navigate("create");
  };

  return (
    <PageLayout sideMenu={true} rightMenu={false}>
      <IslandLayout>
        <div className="page_padding">
          <div className="title_and_limit">
            <h2>Forms</h2>
            <div onClick={handleCreate} className="secondaryBtn">
              + Create New Form
            </div>
          </div>
          <LineBreak />
          {!!formsList && !!formsList.length ? (
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="members table">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <p className="bold_heading_table">Form Name</p>
                    </TableCell>

                    <TableCell align="center">
                      <p className="bold_heading_table">Created On</p>
                    </TableCell>
                    <TableCell align="center">
                      <p className="bold_heading_table">Responses</p>
                    </TableCell>
                    <TableCell align="left">
                      <p className="bold_heading_table">Actions</p>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {!!formsList &&
                    formsList?.map((data, idx) => (
                      <TableRow
                        className="table_cell_hover"
                        key={idx}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell
                          //   onClick={() => handleUserClick(member)}
                          component="th"
                          scope="row"
                        >
                          {data.formName || "-"}
                        </TableCell>

                        <TableCell
                          //   onClick={() => handleUserClick(member)}
                          align="center"
                        >
                          {moment.utc(data.createdDate).local().format("LL")}
                        </TableCell>
                        <TableCell
                          //   onClick={() => handleUserClick(member)}
                          align="center"
                        >
                          {data.responsesCount || 0}
                        </TableCell>
                        <TableCell
                          //   onClick={() => handleUserClick(member)}
                          align="left"
                        >
                          <FormKebab form={data} />
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <h4>No Created forms to show</h4>
          )}
        </div>
      </IslandLayout>
    </PageLayout>
  );
};

export default FormsContainer;
