import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { createTypedSelector } from "store/utils";
import {
  CardDetails,
  CurrentPlanItem,
  PlanItemModel,
  PlanModel,
} from "api/models/Subscriptions/subscriptions";
import {
  getAllPlans,
  getCurrentPlan,
  getPlanDetails,
  getStripeCard,
  razorPaySub,
  razorPaySubVerify,
  stripePayment,
} from "store/async-actions/Subscriptions/subscriptions";
import { BaseResponse } from "api/models/base";
import { toastError, toastSuccess } from "hooks/useToastify";
import { RazorPaySubOrder } from "api/models/Channel/cartModel";

const initialState: {
  isFetching: boolean;
  allPlans: PlanModel[];
  currentPlan: CurrentPlanItem | undefined;
  planDetails: CurrentPlanItem | undefined;
  paymentCharged: boolean;
  razorpayOrder: RazorPaySubOrder | undefined;
  stripeCard: CardDetails | undefined;
} = {
  isFetching: false,
  allPlans: [],
  currentPlan: undefined,
  planDetails: undefined,
  paymentCharged: false,
  razorpayOrder: undefined,
  stripeCard: undefined,
};

const subscriptionsSlice = createSlice({
  name: "subscriptions",
  initialState,
  reducers: {
    setPlanPaymentCharged: (state, action: PayloadAction<boolean>) => {
      state.paymentCharged = action.payload;
    },
  },
  extraReducers: {
    [getAllPlans.pending.type]: (state) => {
      state.isFetching = true;
    },
    [getAllPlans.fulfilled.type]: (
      state,
      action: PayloadAction<PlanModel[]>
    ) => {
      state.allPlans = action.payload;
      state.isFetching = false;
    },
    [getAllPlans.rejected.type]: (state) => {
      state.isFetching = false;
    },

    //GET CURRENT PLAN
    [getCurrentPlan.pending.type]: (state) => {
      state.isFetching = true;
    },
    [getCurrentPlan.fulfilled.type]: (
      state,
      action: PayloadAction<CurrentPlanItem>
    ) => {
      state.currentPlan = action.payload;
      state.isFetching = false;
    },
    [getCurrentPlan.rejected.type]: (state) => {
      state.isFetching = false;
    },

    //GET PLAN DETAILS
    [getPlanDetails.pending.type]: (state) => {
      state.isFetching = true;
    },
    [getPlanDetails.fulfilled.type]: (
      state,
      action: PayloadAction<CurrentPlanItem>
    ) => {
      state.planDetails = action.payload;
      state.isFetching = false;
    },
    [getPlanDetails.rejected.type]: (state) => {
      state.isFetching = false;
    },

    //STRIPE PAYMENT
    [stripePayment.pending.type]: (state) => {
      state.isFetching = true;
    },
    [stripePayment.fulfilled.type]: (
      state,
      action: PayloadAction<BaseResponse<boolean>>
    ) => {
      if (action.payload.data) {
        toastSuccess("Payment Successfull");
        state.paymentCharged = true;
      } else {
        toastError(action.payload.responseInfo.message);
      }
      state.isFetching = false;
    },
    [stripePayment.rejected.type]: (state) => {
      state.isFetching = false;
    },

    //Razor Pay Sub
    [razorPaySub.pending.type]: (state) => {
      state.isFetching = true;
    },
    [razorPaySub.fulfilled.type]: (
      state,
      action: PayloadAction<BaseResponse<RazorPaySubOrder>>
    ) => {
      if (action.payload.responseInfo.httpCode === 200) {
        state.razorpayOrder = action.payload.data;
      } else {
      }
      state.isFetching = false;
    },
    [razorPaySub.rejected.type]: (state) => {
      state.isFetching = false;
    },

    //Razor Pay Verify
    [razorPaySubVerify.pending.type]: (state) => {
      state.isFetching = true;
    },
    [razorPaySubVerify.fulfilled.type]: (
      state,
      action: PayloadAction<BaseResponse<boolean>>
    ) => {
      if (action.payload.data) {
        toastSuccess("Payment Successfull");
        state.paymentCharged = true;
      } else {
        toastError(action.payload.responseInfo.message);
      }
      state.isFetching = false;
    },
    [razorPaySubVerify.rejected.type]: (state) => {
      state.isFetching = false;
    },

    //GET STRIPE CARD
    [getStripeCard.pending.type]: (state) => {
      state.isFetching = true;
    },
    [getStripeCard.fulfilled.type]: (
      state,
      action: PayloadAction<CardDetails>
    ) => {
      state.stripeCard = action.payload;
      state.isFetching = false;
    },
    [getStripeCard.rejected.type]: (state) => {
      state.isFetching = false;
    },
  },
});

export const { setPlanPaymentCharged } = subscriptionsSlice.actions;

export const subscriptionsFetchingSelector = createTypedSelector(
  (state) => state.subscriptions.isFetching
);

export const getAllPlansSelector = createTypedSelector(
  (state) => state.subscriptions.allPlans
);

export const getStripeCardSelector = createTypedSelector(
  (state) => state.subscriptions.stripeCard
);

export const getCurrentPlanSelector = createTypedSelector(
  (state) => state.subscriptions.currentPlan
);

export const getPlanDetailsSelector = createTypedSelector(
  (state) => state.subscriptions.planDetails
);

export const getPlanPaymentChargedSelector = createTypedSelector(
  (state) => state.subscriptions.paymentCharged
);

export const getRazorPaySubOrderSelector = createTypedSelector(
  (state) => state.subscriptions.razorpayOrder
);

export default subscriptionsSlice.reducer;
