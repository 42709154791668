import { useEffect, useRef, useCallback } from "react";

import {
  useAppDispatch,
  useAppSelector,
  useAppShallowSelector,
} from "hooks/redux";
import { getChatBotMessages } from "store/async-actions/Chat/chat";
import {
  ChatBotItem,
  ChatItem,
  getChatBotListSelector,
  getChatBotMessagesLenghtSelector,
  getChatListSelector,
  getChatMessagesLenghtSelector,
  getIsFetchingChatMessagesSelector,
} from "store/reducers/Chat/chat";
import { profileUserIdSelector } from "store/reducers/Profile/profile";

const LOAD_LIMIT = 30;

interface UseChatMessagesReturn {
  loadMoreMessageList: () => void;
  chatList: ChatBotItem[];
  isLoading: boolean;
}

export const useChatBotMessages = (roomId: string): UseChatMessagesReturn => {
  const page = useRef<number>(0);
  const hasAllLoaded = useRef<boolean>(false);
  const dispatch = useAppDispatch();
  const profileId = useAppSelector(profileUserIdSelector);
  const chatList = useAppShallowSelector(getChatBotListSelector);
  const chatMessagesLenght = useAppSelector(getChatBotMessagesLenghtSelector);
  const isLoading = useAppSelector(getIsFetchingChatMessagesSelector);

  const loadMoreMessageList = useCallback(() => {
    if (chatMessagesLenght < LOAD_LIMIT - 1 || isLoading) return;
    if (!hasAllLoaded.current) {
      dispatch(
        getChatBotMessages({
          chatRoomId: roomId,
          userId: profileId,
          page: page.current + 1,
          limit: LOAD_LIMIT,
        })
      )
        .unwrap()
        .then((res) => {
          if (res.length) {
            page.current = page.current + 1;
          } else {
            hasAllLoaded.current = true;
          }
        });
    }
  }, [chatMessagesLenght, dispatch, isLoading, profileId, roomId]);

  useEffect(() => {
    if (roomId && !page.current) {
      dispatch(
        getChatBotMessages({
          chatRoomId: roomId,
          userId: profileId,
          page: page.current,
          limit: LOAD_LIMIT,
        })
      );
    }
    return () => {
      page.current = 0;
    };
  }, [dispatch, roomId, profileId]);

  return { loadMoreMessageList, chatList, isLoading };
};
