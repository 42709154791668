import CSVImport from "components/CSVImport/CSVImport";
import ContactInput from "components/ContactInput/ContactInput";
import React, { useEffect, useState } from "react";

import { CSVLink } from "react-csv";
import { BackBtnSVG, CSVDownloadSVG } from "components/SVG/SVG";
import TokenInput from "react-customize-token-input";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import {
  getCSVErrorMessageSelector,
  getCSVSuccessMessageSelector,
  getImportCountSelector,
} from "store/reducers/HiveDetails/hiveActivities";
import {
  getPhoneUsersSelector,
  updatePhoneUsers,
} from "store/reducers/HiveDetails/hiveSettings";
import PageLayout from "components/PageLayout/PageLayout";
import IslandLayout from "components/IslandLayout/IslandLayout";
import LineBreak from "components/LineBreak/LineBreak";
import { TextField } from "@mui/material";
import { getCampaignUuidSelector } from "store/reducers/Campaign/campaign";
import { useNavigate } from "react-router-dom";
import {
  addCampaignEmails,
  addCampaignNumbers,
} from "store/async-actions/Campaign/campaign";
import { toastSuccess } from "hooks/useToastify";
import { ReactMultiEmail } from "react-multi-email";

const csvEmailData = [
  ["firstname", "lastname", "email"],
  ["John", "Doe", "john@doe.com"],
  ["Jane", "Doe", "janedoe@smthing.co"],
  ["veehive", "", "contact@veehive.ai"],
];

interface Props {
  handleBack: any;
  handleNext: any;
}

const AddEmails = ({ handleBack, handleNext }: Props) => {
  const campaignUuid = useAppSelector(getCampaignUuidSelector);
  const navigate = useNavigate();

  // useEffect(() => {
  //   if (!!!campaignUuid) {
  //     navigate("/campaigns");
  //   }
  // }, []);

  const dispatch = useAppDispatch();

  const [emails, setEmails] = useState<string[]>([]);

  const [phoneNos, setPhoneNos] = useState<string[]>([]);

  const phoneUsers = useAppSelector(getPhoneUsersSelector);

  const csvErrorMessage = useAppSelector(getCSVErrorMessageSelector);
  const setCSVSuccessMessage = useAppSelector(getCSVSuccessMessageSelector);
  const importCount = useAppSelector(getImportCountSelector);

  const handleSave = () => {
    dispatch(
      addCampaignEmails({
        emailList: emails,
        campaignUuid: campaignUuid,
      })
    );
    toastSuccess("Created campaign successfully");
    handleNext();
  };

  return (
    // <PageLayout sideMenu={true}>
    //   <IslandLayout>
    <div className="">
      <div className="backBtn_spacing">
        <div className="go_back pointer" onClick={handleBack}>
          <BackBtnSVG />
          <p>Back</p>
        </div>
      </div>
      <h2>Add Emails</h2>
      <div className="csv_import_container_wrapper">
        <CSVImport
          setPhoneNos={setPhoneNos}
          selectedTab={0}
          phoneNos={phoneNos}
          emails={emails}
          setEmails={setEmails}
        />

        <CSVLink filename="email_format" data={csvEmailData}>
          <CSVDownloadSVG />
        </CSVLink>
      </div>
      <LineBreak />
      <span className="error_message">{csvErrorMessage}</span>
      <span className="success_message">{setCSVSuccessMessage}</span>
      <div className="react_multi_email_container">
        <ReactMultiEmail
          placeholder="ex: john@example.com; jane@example.com;"
          className="text-sm"
          emails={emails}
          onChange={setEmails}
          getLabel={(
            email: string,
            index: number,
            removeEmail: (index: number) => void
          ) => {
            return (
              <div
                // data-tag
                key={index}
                className="multi_email_tag"
              >
                <p>{email}</p>
                <span
                  data-tag-handle
                  onClick={() => {
                    removeEmail(index);
                  }}
                  className="cursor-pointer"
                >
                  ×
                </span>
              </div>
            );
          }}
        />
      </div>
      <div className="campaign_nextBtn_container">
        {emails.length > 0 ? (
          <div onClick={handleSave} className="primaryBtn campaign_nextBtn">
            Finish
          </div>
        ) : (
          <div className="campaign_nextBtn disabledBtn">Finish</div>
        )}
      </div>
    </div>
    //   </IslandLayout>
    // </PageLayout>
  );
};

export default AddEmails;
