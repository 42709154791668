import { CloseMediaSVG } from "components/SVG/SVG";
import React, { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import SegmentAnalytics from "./components/SegmentAnalytics";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { getChildSelector } from "store/reducers/ChildComponents/childComponents";
import { getChildComponents } from "store/async-actions/Hive/hivePage";
import { getHiveSelector } from "store/reducers/HiveDetails/hiveDetails";
import { isMemberViewSelector } from "store/reducers/Profile/profile";

const ViewStoryContainer = () => {
  const navigate = useNavigate();

  const location = useLocation();

  const hive = useAppSelector(getHiveSelector);

  const handleClose = () => {
    navigate(location.state?.from || "/story");
  };

  const dispatch = useAppDispatch();

  const isMemberView = useAppSelector(isMemberViewSelector);

  useEffect(() => {
    dispatch(
      getChildComponents({
        organizationId: hive?.communityId || 2,
        parentComponentCode: "Stories",
        isMemberView: isMemberView,
        channelUuid: null,
      })
    );
  }, [dispatch, hive]);

  const childComponents = useAppSelector(getChildSelector);

  return (
    <div className={`main_container_view_story story_admin_container`}>
      <div onClick={handleClose} className="view_story_close_btn pointer">
        <CloseMediaSVG />
      </div>
      {!!childComponents.length && <div className="spacer"></div>}
      <div className="view_story_outlet_container">
        <Outlet />
      </div>
      {!!childComponents.length && (
        <div className="segment_analytics_container">
          <SegmentAnalytics />
        </div>
      )}
    </div>
  );
};

export default ViewStoryContainer;
