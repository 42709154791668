import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "api";

import {
  ChatBotChatroomMessagesReq,
  ChatBotChatroomsReq,
  GetChatMessageRequestData,
  GetChatMessagesWithUserMentionsRequestData,
  GetChatRoomDetailData,
  SendMessageBotRequestBody,
  SendMessageRequestBody,
} from "api/models/Chat/chat";
import { AxiosProgressEvent } from "axios";
import { RootState } from "store";

export const getChatMessages = createAsyncThunk(
  "chat/getChatMessages",
  async (body: GetChatMessageRequestData, { rejectWithValue }) => {
    try {
      const { data } = await api.chat.getMessagesOfChantRoom(body);
      return data.data;
    } catch (error) {
      return rejectWithValue("Something went wrong");
    }
  }
);

export const getNewChatMessages = createAsyncThunk(
  "chat/getNewChatMessages",
  async (body: GetChatMessageRequestData, { rejectWithValue }) => {
    try {
      const { data } = await api.chat.getMessagesOfChantRoom(body);
      return data.data;
    } catch (error) {
      return rejectWithValue("Something went wrong");
    }
  }
);

export const deleteChatMessage = createAsyncThunk(
  "chat/deleteChatMessage",
  async (
    body: {
      messageId: string;
    },
    { rejectWithValue, dispatch, getState }
  ) => {
    try {
      const { data } = await api.chat.deleteMessage(body);
      const { chat, profile } = getState() as RootState;

      dispatch(
        getNewChatMessages({
          chatRoomId: chat.chatRoomId,
          page: chat.chatPageNo,
          limit: 10,
          userId: profile.userId || 0,
        })
      );

      return data.data;
    } catch (error) {
      return rejectWithValue("Something went wrong");
    }
  }
);

export const getChatBotMessages = createAsyncThunk(
  "chat/getChatBotMessages",
  async (body: GetChatMessageRequestData, { rejectWithValue }) => {
    try {
      const { data } = await api.chat.getMessagesOfChatBotRoom(body);
      return data.data;
    } catch (error) {
      return rejectWithValue("Something went wrong");
    }
  }
);

export const getChatBotId = createAsyncThunk(
  "chat/getChatBotId",
  async (
    body: {
      organizationId: number;
      organizationUuid: string;
      sessionId: string;
    },
    { rejectWithValue }
  ) => {
    try {
      const { data } = await api.chat.getChatBotId(body);
      return data.data;
    } catch (error) {
      return rejectWithValue("Something went wrong");
    }
  }
);

export const sendChatMessage = createAsyncThunk(
  "chat/sendChatMessage",
  async (body: SendMessageRequestBody, { rejectWithValue }) => {
    try {
      const { data } = await api.chat.sendMessage(body);
      return data.data;
    } catch (error) {
      return rejectWithValue("Something went wrong");
    }
  }
);

export const sendChatBotMessage = createAsyncThunk(
  "chat/sendChatMessage",
  async (body: SendMessageBotRequestBody, { rejectWithValue }) => {
    try {
      const { data } = await api.chat.sendChatBotMessage(body);
      return data.data;
    } catch (error) {
      return rejectWithValue("Something went wrong");
    }
  }
);

export const sendChatAttachmentMessage = createAsyncThunk(
  "chat/sendChatAttachmentMessage",
  async (
    {
      body,
      cb,
      abortSignal,
    }: {
      body: FormData;
      cb?: HandlePropgressCb<AxiosProgressEvent>;
      abortSignal?: AbortSignal;
    },
    { rejectWithValue, signal }
  ) => {
    try {
      const { data } = await api.chat.sendAttachmentFile(body, cb, abortSignal);
      return data.data;
    } catch (error: any) {
      return rejectWithValue(error?.message || "Something went wrong");
    }
  }
);

export const sendVoiceMessage = createAsyncThunk(
  "chat/sendVoiceMessage",
  async (body: FormData, { rejectWithValue }) => {
    try {
      const { data } = await api.chat.sendVoice(body);
      return data.data;
    } catch (error) {
      return rejectWithValue("Something went wrong");
    }
  }
);

export const getChatRoomUsersMentions = createAsyncThunk(
  "chat/getChatRoomUsersMentions",
  async (body: GetChatRoomDetailData, { rejectWithValue }) => {
    try {
      const { data } = await api.chat.getChannelUsersListMentions(body);
      return data.data;
    } catch (error) {
      return rejectWithValue("Something went wrong");
    }
  }
);

export const getChatMessagesWithUserMentions = createAsyncThunk(
  "chat/getChatMessagesWithUserMentions",
  async (
    body: GetChatMessagesWithUserMentionsRequestData,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await api.chat.getChatMessagesWithUserMentions(body);
      return data.data;
    } catch (error) {
      return rejectWithValue(
        "getChatMessagesWithUserMentions: Something went wrong"
      );
    }
  }
);
