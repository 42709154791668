import AiDropzone from "components/AiDropzone/AiDropzone";
import BackButton from "components/BackButton/BackButton";
import LineBreak from "components/LineBreak/LineBreak";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import React, { useState } from "react";
import { formToVideo } from "store/async-actions/Ai/ai";
import {
  getUploadedAiImagesSelector,
  setOpenDownloads,
} from "store/reducers/Ai/ai";
import { profileUserIdSelector } from "store/reducers/Profile/profile";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useNavigate } from "react-router-dom";
import { getHiveUuid } from "store/reducers/HiveDetails/hiveDetails";

const FormToVideo = () => {
  const dispatch = useAppDispatch();

  const userId = useAppSelector(profileUserIdSelector);

  const navigate = useNavigate();

  const uploadedImages = useAppSelector(getUploadedAiImagesSelector);
  const hiveUuid = useAppSelector(getHiveUuid);

  const handleCreate = () => {
    const formData = new FormData();

    const obj = {
      name: detailsForm.name,
      age: +detailsForm.age,
      gender: detailsForm.gender,
      height: detailsForm.height,
      weight: detailsForm.weight,
      religion: detailsForm.religion,
      nationality: detailsForm.nationality,
      employment: detailsForm.job,
      salary: detailsForm.salary,
      personality: detailsForm.personality,
      brideQualities: detailsForm.brideQualities,
    };

    formData.append("user_id", userId.toString());

    uploadedImages.map((image) => {
      formData.append("images", image, image.name);
    });

    formData.append("json", JSON.stringify(obj));
    formData.append("organizationUuid", hiveUuid);

    dispatch(formToVideo(formData));
    navigate("..");
    dispatch(setOpenDownloads(true));
  };

  const [detailsForm, setDetailsForm] = useState({
    name: "",
    height: "",
    age: "",
    gender: "",
    weight: "",
    nationality: "",
    religion: "",
    job: "",
    salary: "",
    personality: "",
    brideQualities: "",
  });

  const handleDetailsChange = (e: any) => {
    setDetailsForm((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  return (
    <div className="ttv_container">
      <BackButton />
      <LineBreak />
      <h3 className="">Enter your personal details*</h3>
      <LineBreak />
      <div className="ftv_columns">
        <TextField
          fullWidth
          placeholder="Name"
          className=""
          name="name"
          onChange={handleDetailsChange}
          value={detailsForm.name}
          id="outlined-basic"
          label="Name"
          variant="outlined"
        />

        <TextField
          fullWidth
          label="Age"
          type="number"
          variant="outlined"
          placeholder="Age"
          className=""
          name="age"
          onChange={handleDetailsChange}
          value={detailsForm.age}
        />
      </div>
      <LineBreak />
      <div className="ftv_columns">
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Gender</InputLabel>
          <Select
            className="w_full"
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={detailsForm.gender}
            label="Gender"
            name="gender"
            onChange={handleDetailsChange}
          >
            <MenuItem value="male">Male</MenuItem>
            <MenuItem value="female">Female</MenuItem>
            <MenuItem value="other">Other</MenuItem>
          </Select>
        </FormControl>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Height(cm)</InputLabel>
          <Select
            className=""
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            label="Height(cm)"
            name="height"
            onChange={handleDetailsChange}
            value={detailsForm.height}
          >
            <MenuItem value="less than 150cm">Short {"(<150 cm)"}</MenuItem>
            <MenuItem value="150 - 180 cm">Average (150-175 cm)</MenuItem>
            <MenuItem value="Above 180 cm">Tall {"(>180 cm)"}</MenuItem>
          </Select>
        </FormControl>
      </div>
      <LineBreak />
      <div className="ftv_columns">
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Weight(kg)</InputLabel>
          <Select
            className=""
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            label="Weight(kg)"
            name="weight"
            onChange={handleDetailsChange}
            value={detailsForm.weight}
          >
            <MenuItem value="less than 50kg">Light {"(< 50kg)"}</MenuItem>
            <MenuItem value="50-90 kgs">Average {"( 50 - 90kg)"} </MenuItem>
            <MenuItem value="above 90 kgs">Heavy {"(> 90kg)"}</MenuItem>
          </Select>
        </FormControl>

        <TextField
          fullWidth
          label="Religion"
          variant="outlined"
          placeholder="Religion"
          className=""
          name="religion"
          onChange={handleDetailsChange}
          value={detailsForm.religion}
        />
      </div>
      <LineBreak />
      <div className="ftv_columns">
        <TextField
          fullWidth
          label="Nationality"
          variant="outlined"
          placeholder="Nationality"
          className=""
          name="nationality"
          onChange={handleDetailsChange}
          value={detailsForm.nationality}
        />

        <TextField
          fullWidth
          label="Employment"
          variant="outlined"
          placeholder="Employment"
          className=""
          name="job"
          onChange={handleDetailsChange}
          value={detailsForm.job}
        />
      </div>
      <LineBreak />
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">Salary(INR/m)</InputLabel>
        <Select
          className=""
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          label="Salary(INR/m)"
          name="salary"
          onChange={handleDetailsChange}
          value={detailsForm.salary}
        >
          <MenuItem value="less than INR 10,000">{"< 10,000"}</MenuItem>
          <MenuItem value="INR 10,000 - 50,000">{"10,000 - 50,000"} </MenuItem>
          <MenuItem value="INR 50,000 - 150,000">{"50,000 - 150,000"}</MenuItem>
          <MenuItem value="INR 15,000 - 50,000">{"150,000 - 500,000"}</MenuItem>
          <MenuItem value="above INR 500,000">{"> 500,000"}</MenuItem>
        </Select>
      </FormControl>
      <LineBreak />
      <h4 className="tags">Enter your personal description*</h4>
      <LineBreak />
      <textarea
        name="personality"
        placeholder="Write a few words about yourself."
        className="ttv_text_area"
        value={detailsForm.personality}
        onChange={handleDetailsChange}
      />
      <h4 className="tags">
        {" "}
        Describe the qualities you're looking for in a a partner (optional)
      </h4>
      <LineBreak />
      <textarea
        name="brideQualities"
        placeholder="Explain the qualities you are looking for in a partner."
        className="ttv_text_area"
        value={detailsForm.brideQualities}
        onChange={handleDetailsChange}
      />
      <LineBreak />
      <h4 className="tags">Upload Images (optional)</h4>
      <LineBreak />
      <AiDropzone />
      {!!detailsForm.name &&
      !!detailsForm.height &&
      !!detailsForm.job &&
      !!detailsForm.nationality &&
      !!detailsForm.religion &&
      !!detailsForm.personality &&
      !!detailsForm.weight ? (
        <div onClick={handleCreate} className="nextBtn primaryBtn">
          Create Scene
        </div>
      ) : (
        <div className="nextBtn disabledBtn">Create Scene</div>
      )}
    </div>
  );
};

export default FormToVideo;
