import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "api";
import { FeedRequestModel } from "api/models/Feed/Feed";
import { FCMTokenReq } from "api/models/Notifications/notifications";

export const getNotifications = createAsyncThunk(
  "notifications/getNotifications",
  async (
    body: {
      organizationUuid: string;
      page: number;
      limit: number;
    },
    { rejectWithValue }
  ) => {
    try {
      const { data } = await api.notifications.getUserNotification(body);
      return data.data;
    } catch (error) {
      return rejectWithValue("Something went wrong");
    }
  }
);

export const readUserNotifications = createAsyncThunk(
  "notifications/readUserNotifications",
  async (
    body: {
      organizationUuid: string;
    },
    { rejectWithValue }
  ) => {
    try {
      const { data } = await api.notifications.readUserNotification(body);
      return data.data;
    } catch (error) {
      return rejectWithValue("Something went wrong");
    }
  }
);

export const updateFCMToken = createAsyncThunk(
  "notifications/updateFCMToken",
  async (body: FCMTokenReq, { rejectWithValue }) => {
    try {
      const { data } = await api.notifications.updateFCMToken(body);
      return data.data;
    } catch (error) {
      return rejectWithValue("Something went wrong");
    }
  }
);
