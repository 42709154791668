import { CircularProgress, TextField } from "@mui/material";
import AiFileDrop from "components/AiFileDrop/AiFileDrop";
import BackButton from "components/BackButton/BackButton";
import LineBreak from "components/LineBreak/LineBreak";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  createTeaser,
  generateTopics,
  transposeVideo,
} from "store/async-actions/Ai/ai";
import {
  getGeneratingTopicsSelector,
  getLtsRespSelector,
  getTopicsIsGeneratedSelector,
  getUploadedAiFileSelector,
  setOpenDownloads,
  setTopicsGenerated,
  uploadAiFile,
} from "store/reducers/Ai/ai";
import { getHiveUuid } from "store/reducers/HiveDetails/hiveDetails";
import { profileUserIdSelector } from "store/reducers/Profile/profile";

const LongToShort = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(uploadAiFile(null));
  }, [dispatch]);

  const userId = useAppSelector(profileUserIdSelector);

  const uploadedFile = useAppSelector(getUploadedAiFileSelector);

  const generatingTopics = useAppSelector(getGeneratingTopicsSelector);

  const topicsGenerated = useAppSelector(getTopicsIsGeneratedSelector);

  const navigate = useNavigate();

  const hiveUuid = useAppSelector(getHiveUuid);

  useEffect(() => {
    topicsGenerated && navigate("/ai/compileTopics");
    dispatch(setTopicsGenerated(false));
  }, [topicsGenerated, dispatch]);

  const handleCreate = () => {
    const formData = new FormData();
    const obj = {
      userid: userId.toString(),
      organizationUuid: hiveUuid,
    };

    formData.append("json", JSON.stringify(obj));
    formData.append("file", uploadedFile || "");
    formData.append("organizationUuid", hiveUuid);

    dispatch(generateTopics({ formData, userId }));
    // navigate("/ai/compileTopics");
    // dispatch(setOpenDownloads(true));
  };

  return (
    <>
      {generatingTopics ? (
        <div className="generating_topics_loader">
          <CircularProgress size={40} color="inherit" />
          <h4>
            Generating topics, this may take a few minutes, please hang tight!
          </h4>
        </div>
      ) : (
        <div className="ttv_container">
          <BackButton />
          <LineBreak />
          <h4 className="tags">Upload Video</h4>
          <LineBreak />
          <AiFileDrop fileType="video" size={150} />
          <LineBreak />

          {uploadedFile ? (
            <div onClick={handleCreate} className="nextBtn primaryBtn">
              Generate Topics
            </div>
          ) : (
            <div className="nextBtn disabledBtn">Generate Topics</div>
          )}
        </div>
      )}
    </>
  );
};

export default LongToShort;
