import { Box, Divider, Menu, MenuItem, Tooltip } from "@mui/material";
import { AddLinkSVG, GreenTickSVG } from "components/SVG/SVG";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import React from "react";
import { editStorySegmentsBg } from "store/async-actions/Story/storyActions";
import { getHiveUuid } from "store/reducers/HiveDetails/hiveDetails";
import {
  getStoryUuidSelector,
  getCurrentStoryIndexSelector,
  getStorySegmentsSelector,
  getCurrentStoryColorSelector,
  getStoryTitleSelector,
  getStoryDescSelector,
  getActionLinkSelector,
  setActionLink,
} from "store/reducers/Story/story";

const AddLinkDrop = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const dispatch = useAppDispatch();

  const hiveUuid = useAppSelector(getHiveUuid);
  const storyUuid = useAppSelector(getStoryUuidSelector);

  const storyIdx = useAppSelector(getCurrentStoryIndexSelector);

  const storySegments = useAppSelector(getStorySegmentsSelector);
  const colorCode = useAppSelector(getCurrentStoryColorSelector);

  const title = useAppSelector(getStoryTitleSelector);
  const description = useAppSelector(getStoryDescSelector);

  const actionLink = useAppSelector(getActionLinkSelector);

  const handleSave = () => {
    dispatch(
      editStorySegmentsBg({
        description: description,
        isActive: false,
        storyUrl: storySegments[storyIdx].storyUrl || "",
        storyUuid: storyUuid,
        title: title,
        isImage: storySegments[storyIdx].type === "image",
        isVideo: storySegments[storyIdx].type === "video",
        colorCode: storySegments[storyIdx].colorCode || "",
        communityUuid: hiveUuid,
        contentType: "",
        isMediaChanged: false,
        storyId: storySegments[storyIdx].id,
        thumbnail: "",
        actionLink: actionLink,
        order: storySegments[storyIdx].segmentOrder,
      })
    );

    handleClose();
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setActionLink(e.target.value));
  };

  return (
    <div>
      <Box sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
        <Tooltip title="Add Link">
          <div onClick={handleClick}>
            <AddLinkSVG />
          </div>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 4,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <div className="story_link_wrapper">
          <input
            value={actionLink}
            onChange={handleChange}
            className="story_link_item"
            placeholder="Enter link here"
          />
          <div onClick={handleSave} className="pointer">
            <GreenTickSVG />
          </div>
        </div>
      </Menu>
    </div>
  );
};

export default AddLinkDrop;
