import { useAppDispatch, useAppSelector } from "hooks/redux";
import { toastError } from "hooks/useToastify";
import React, { useEffect } from "react";
import {
  razorPayChargeEvent,
  razorPayVerifyEvent,
} from "store/async-actions/Hive/eventsActions";
import {
  getCreateEventOrderSelector,
  getCurrentEventSelector,
  getEventFormDetailsSelector,
  getEventsPromoSelector,
} from "store/reducers/HiveDetails/events";
import { getHiveSelector } from "store/reducers/HiveDetails/hiveDetails";

const RazorEventCheckout = () => {
  const dispatch = useAppDispatch();
  const createOrderResp = useAppSelector(getCreateEventOrderSelector);

  const hive = useAppSelector(getHiveSelector);
  const currentEvent = useAppSelector(getCurrentEventSelector);

  const promoResp = useAppSelector(getEventsPromoSelector);

  useEffect(() => {
    hive &&
      currentEvent &&
      dispatch(
        razorPayChargeEvent({
          eventUuid: currentEvent.eventUuid,
          promoCode: promoResp?.promoCode || "",
        })
      );
  }, [dispatch, currentEvent]);

  function loadScript(src: string) {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }

  const formDetails = useAppSelector(getEventFormDetailsSelector);

  async function displayRazorPay() {
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );
    if (!res) {
      alert("Razorpay SDK failed to load. Are you online?");
      return;
    }
    const options = {
      // key: "rzp_test_j2sDfnlDqZgcNo",
      key: "rzp_live_wHpMkB5GN12X7J",
      amount: createOrderResp!.orderAmount.toString(),
      currency: createOrderResp!.currency,
      name: hive?.communityName,
      description: currentEvent?.eventName,
      order_id: createOrderResp!.orderId,
      handler: function (response: {
        razorpay_payment_id: string;
        razorpay_order_id: string;
        razorpay_signature: string;
      }) {
        dispatch(
          razorPayVerifyEvent({
            paymentId: response.razorpay_payment_id,
            orderId: response.razorpay_order_id,
            signature: response.razorpay_signature,
            formDetails: !!formDetails.length ? formDetails : null,
          })
        );
      },
      theme: {
        color: "#61dafb",
      },
    };

    const paymentObject = new (window as any).Razorpay(options);
    paymentObject.on(
      "payment.failed",
      function (response: {
        error: {
          code: any;
          description: any;
          source: any;
          step: any;
          reason: any;
          metadata: { order_id: any; payment_id: any };
        };
      }) {
        // alert(response.error.code);
        // alert(response.error.description);
        // alert(response.error.source);
        // alert(response.error.step);
        alert(response.error.reason);
        // alert(response.error.metadata.order_id);
        // alert(response.error.metadata.payment_id);
      }
    );
    paymentObject.open();
  }

  const handleContinue = () => {
    createOrderResp?.orderId
      ? displayRazorPay()
      : toastError("Sorry there was an error while processing your request");
  };

  return (
    <div onClick={handleContinue} className="primaryBtn plan_buyBtn">
      Continue
    </div>
  );
};

export default RazorEventCheckout;
