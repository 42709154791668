import BackButton from "components/BackButton/BackButton";
import LineBreak from "components/LineBreak/LineBreak";
import React, { useEffect } from "react";
import storyTemplate from "../../../assets/png/storyTemplate.png";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import {
  emptyStorySegments,
  getStoryCreatedSelector,
  getStoryDescSelector,
  getStoryIsFetchingSelector,
  getStoryTitleSelector,
  setActionLink,
  setCurrentStoryIndex,
  setStoryCreated,
  setStoryDesc,
  setStoryTitle,
  setStoryUuid,
} from "store/reducers/Story/story";
import { createStory } from "store/async-actions/Story/storyActions";
import { getHiveSelector } from "store/reducers/HiveDetails/hiveDetails";
import { CircularProgress } from "@mui/material";

const CreateStory = () => {
  const title = useAppSelector(getStoryTitleSelector);
  const desc = useAppSelector(getStoryDescSelector);

  const hiveDetails = useAppSelector(getHiveSelector);

  const isFetching = useAppSelector(getStoryIsFetchingSelector);
  const isCreated = useAppSelector(getStoryCreatedSelector);

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setStoryTitle(""));
    dispatch(setStoryDesc(""));
    dispatch(setStoryUuid(""));
    dispatch(emptyStorySegments());
    dispatch(setCurrentStoryIndex(0));
  }, [dispatch]);

  const handleCreateStory = () => {
    hiveDetails &&
      dispatch(
        createStory({
          title: title,
          description: desc,
          organizationId: hiveDetails?.communityId,
          organizationUuid: hiveDetails?.communityUuid,
        })
      );
  };

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (isCreated) {
      navigate("/story/create/publish");
      dispatch(setStoryCreated(false));
    }
  }, [isCreated]);

  return (
    <div className="create_story_container">
      <LineBreak />
      <div className="backBtn_spacing">
        <BackButton to={location.state?.from || "/story"} />
      </div>
      <LineBreak />
      <div className="title_and_limit">
        <p className="tags text-sm font-bold">Story title</p>
        <div className="character_limit text-sm">{title.length}/60</div>
      </div>
      <LineBreak />
      <input
        value={title}
        onChange={(e) => {
          dispatch(setStoryTitle(e.target.value.slice(0, 60)));
        }}
        placeholder="Give a title for your story"
        className="input_border text_padding input_width_full"
      />
      <LineBreak />
      <div className="title_and_limit">
        <p className="tags text-sm font-bold">Story Description</p>
        <div className="character_limit text-sm">{desc.length}/240</div>
      </div>{" "}
      <LineBreak />
      <input
        value={desc}
        onChange={(e) => {
          dispatch(setStoryDesc(e.target.value.slice(0, 240)));
        }}
        placeholder="Give a description for your story"
        className="input_border text_padding input_width_full"
      />
      <LineBreak />
      <p className="tags text-sm font-bold">Templates</p>
      <LineBreak />
      <div className="story_template">
        <img
          src={storyTemplate}
          alt=""
          className="ai_template_img story_template_img"
        />
        <p className="text-sm font-bold story_template_description">
          Default Template
        </p>
      </div>
      <LineBreak />
      <>
        {isFetching ? (
          <div className="story_template_nextBtn primaryBtn">
            <CircularProgress size={20} color="inherit" />
          </div>
        ) : (
          <div>
            {title.length > 0 && desc.length > 0 ? (
              <div
                onClick={handleCreateStory}
                className="primaryBtn story_template_nextBtn"
              >
                Continue
              </div>
            ) : (
              <div className="story_template_nextBtn disabledBtn">Continue</div>
            )}
          </div>
        )}
      </>
    </div>
  );
};

export default CreateStory;
