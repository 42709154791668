import { CircularProgress } from "@mui/material";
import BackButton from "components/BackButton";
import LineBreak from "components/LineBreak";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import React, { useEffect, useState } from "react";
import {
  getHiveContextSelector,
  getHiveIsEditedSelector,
  getHiveIsEditingSelector,
  getHiveIsPrivateSelector,
  getHiveProductsSelector,
  getHiveSelector,
  getShowChatBotSelector,
  getShowSuggestedHiveSelector,
  setHiveContext,
  setHiveEdited,
  setIsPrivate,
  setShowChatBot,
  setShowSuggested,
} from "store/reducers/HiveDetails/hiveDetails";
import {
  getHiveContactSelector,
  getSocialRoutesSelector,
  setHiveTab,
} from "store/reducers/HiveDetails/hiveSettings";
import Switch from "@mui/joy/Switch";
import { StyledEngineProvider, CssVarsProvider } from "@mui/joy/styles";
import HiveTabs from "./HiveSettingsTab";
import {
  editHiveContext,
  editHiveDetails,
  getContactInfo,
  getHiveContext,
  getHiveDetails,
  saveContactInfo,
} from "store/async-actions/Hive/hivePage";
import { toastError, toastSuccess } from "hooks/useToastify";
import SocialLinkEdit from "./SocialLinkEdit";
import HiveProducts from "./HiveProducts";

export interface HiveFormObject {
  [key: string]: string | undefined;
  hiveName?: string;
  hiveDescription?: string;
  hiveAddress: string;
  hiveEmail: string;
  linkedinLink?: any;
  facebookLink?: any;
  twitterLink?: any;
  instagramLink?: any;
  whatsappLink?: any;
  customDomain: string;
}

const HiveAbout = () => {
  const hiveDetails = useAppSelector(getHiveSelector);

  const socialRoutes = useAppSelector(getSocialRoutesSelector);
  const contactInfo = useAppSelector(getHiveContactSelector);

  const [editHiveForm, setEditHiveForm] = useState<HiveFormObject>({
    hiveName: hiveDetails?.communityName,
    hiveDescription: hiveDetails?.longDescription,
    hiveAddress: contactInfo?.address || "",
    hiveEmail: contactInfo?.email || "",
    linkedinLink: socialRoutes.linkedinLink || "",
    facebookLink: socialRoutes.facebookLink || "",
    twitterLink: socialRoutes.twitterLink || "",
    instagramLink: socialRoutes.instagramLink || "",
    whatsappLink: socialRoutes.whatsappLink || "",
    customDomain: hiveDetails?.customDomain || "",
  });

  const isEdited = useAppSelector(getHiveIsEditedSelector);
  const isPrivate = useAppSelector(getHiveIsPrivateSelector);

  const dispatch = useAppDispatch();

  const hiveContext = useAppSelector(getHiveContextSelector);

  useEffect(() => {
    editHiveForm.hiveAddress = contactInfo?.address || "";
    editHiveForm.hiveEmail = contactInfo?.email || "";
    dispatch(
      getHiveContext({ organizationUuid: hiveDetails?.communityUuid || "" })
    );
  }, [dispatch, contactInfo]);

  useEffect(() => {
    if (isEdited) {
      toastSuccess("Hive Details Saved");

      dispatch(setHiveEdited(false));

      dispatch(
        getHiveDetails({
          // communitySubDomain: localStorage.getItem("subDomain"),
          organizationUuid: process.env.REACT_APP_ORG_UUID,
        })
      );
    }
  }, [isEdited, dispatch]);

  useEffect(() => {
    dispatch(setHiveTab(0));
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleHiveForm = (e: React.ChangeEvent<any>, limit: number) => {
    setEditHiveForm((prevForm) => ({
      ...prevForm,
      [e.target.name]: e.target.value.slice(0, limit),
    }));
  };

  const showSuggested = useAppSelector(getShowSuggestedHiveSelector);
  const showChatBot = useAppSelector(getShowChatBotSelector);

  const handleSave = () => {
    if (hiveDetails) {
      dispatch(
        editHiveDetails({
          communityName: editHiveForm.hiveName!,
          communityTier: "",
          communityLogo: "",
          communityBanner: "",
          marketPlace: isPrivate ? "PRIVATE" : "COMMUNITY",
          longDescription: editHiveForm.hiveDescription!,
          communityUuid: hiveDetails?.communityUuid!,
          communitySubDomain: localStorage.getItem("subDomain")!,
          communityId: hiveDetails?.communityId!,
          communityBio: "",
          description: "",
          communityBioFlag: false,
          communityNameFlag: true,
          descriptionFlag: false,
          communityTierFlag: false,
          marketPlaceFlag: true,
          communityLogoFlag: false,
          communityBannerFlag: false,
          longDescriptionFlag: true,
          communityWebLogo: "",
          communityWebLogoFlag: false,
          introVideo: "",
          introVideoFlag: false,
          communitySubDomainFlag: false,
          showSuggested: showSuggested,
          showSuggestedFlag: true,
          chatSupportEnabled: showChatBot,
          chatSupportEnabledFlag: true,
          customDomain: editHiveForm.customDomain || "",
          customDomainFlag: true,
          introVideoThumbnail: "",
          introVideoThumbnailType: "",
          introVideoThumbnailFlag: false,
        })
      );
      if (
        !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
          editHiveForm.hiveEmail
        )
      ) {
        editHiveForm.hiveEmail = "";
      }
      dispatch(
        saveContactInfo({
          organizationUuid: hiveDetails?.communityUuid,
          email: editHiveForm.hiveEmail || "",
          address: editHiveForm.hiveAddress || "",
        })
      );
    }
  };

  const isLoading = useAppSelector(getHiveIsEditingSelector);

  const handleError = () => {
    toastError("Hive name should be of atleast 3 characters");
  };

  const handleContextError = () => {
    toastError("Set a detailed context of your hive for your Ai bot");
  };

  return (
    // <PageLayout sideMenu={true}>
    //   <IslandLayout>
    <div className="ce_about_wrapper">
      <div className="back_button_spacing">
        <BackButton to="/home" />
      </div>
      <HiveTabs />
      <StyledEngineProvider injectFirst>
        <CssVarsProvider>
          <div className="about_container">
            <LineBreak />
            <div>
              <div className="title_and_limit">
                <h4>Hive Name</h4>
                {editHiveForm.hiveName && (
                  <div className="character_limit text-sm">
                    {editHiveForm.hiveName.length}/18
                  </div>
                )}
              </div>{" "}
              <LineBreak />
              <input
                name="hiveName"
                value={editHiveForm.hiveName!}
                onChange={(e) => handleHiveForm(e, 18)}
                className="input_border text_padding input_width_full"
                placeholder="Eg: Introduction"
              />
            </div>
            <div>
              <div className="title_and_limit">
                <h4>Who are we</h4>
                {editHiveForm.hiveDescription && (
                  <div className="character_limit text-sm">
                    {editHiveForm.hiveDescription.length}/280
                  </div>
                )}
              </div>{" "}
              <LineBreak />
              <textarea
                name="hiveDescription"
                value={editHiveForm.hiveDescription!}
                onChange={(e) => handleHiveForm(e, 280)}
                className="input_border text_padding input_width_full who_are_we_section_text"
                placeholder="A few words about the channel"
              />
            </div>
            <div>
              <h4>Contact Us</h4>
              <LineBreak />
              <div className="input_border text_padding input_width_full input_container_w_border">
                <input
                  name="hiveAddress"
                  value={editHiveForm.hiveAddress}
                  onChange={(e) => handleHiveForm(e, 80)}
                  className="input_width_full input_within_border"
                  placeholder="Eg: Hive city, Country"
                />
                <div className="character_limit text-sm char_limit_input">
                  {editHiveForm.hiveAddress.length}/80
                </div>{" "}
              </div>
              <p className="small_helper_text">
                Enter your address that you want to show in the contact us
                section.
              </p>
              <LineBreak />
              <div className="input_border text_padding input_width_full input_container_w_border">
                <input
                  name="hiveEmail"
                  type="email"
                  value={editHiveForm.hiveEmail}
                  onChange={(e) => handleHiveForm(e, 28)}
                  className="input_width_full input_within_border"
                  placeholder="Eg: hive@hive_email.com"
                />
                <div className="character_limit text-sm char_limit_input">
                  {editHiveForm.hiveEmail.length}/28
                </div>{" "}
              </div>
              <p className="small_helper_text">
                Enter your email that you want to be contact with.
              </p>
            </div>
            <div>
              <h4>Custom Domain</h4>
              <LineBreak />
              <div className="input_border text_padding input_width_full input_container_w_border">
                <input
                  name="customDomain"
                  value={editHiveForm.customDomain}
                  onChange={(e) => handleHiveForm(e, 180)}
                  className="input_width_full input_within_border"
                  placeholder="Eg: https://customdomain.veehive.ai"
                />
                <div className="character_limit text-sm char_limit_input">
                  {editHiveForm.customDomain.length}/180
                </div>{" "}
              </div>
              <p className="small_helper_text">
                Enter your custom domain that you configure your HiveLink with.
              </p>
              <LineBreak />
            </div>
            <SocialLinkEdit
              editHiveForm={editHiveForm}
              handleHiveForm={handleHiveForm}
            />
            <div className="hive_setting_switch_container">
              <div className="hive_setting_switch_wrapper">
                <Switch
                  checked={isPrivate}
                  onChange={(event) =>
                    dispatch(setIsPrivate(event.target.checked))
                  }
                />
                <p>Set hive visibility to private</p>
              </div>
              <div className="hive_setting_switch_wrapper">
                <Switch
                  checked={showSuggested}
                  onChange={(event) =>
                    dispatch(setShowSuggested(event.target.checked))
                  }
                />
                <p>Show suggested hives</p>
              </div>
            </div>
          </div>
        </CssVarsProvider>
      </StyledEngineProvider>
      <>
        {isLoading ? (
          <div className="nextBtn primaryBtn">
            <CircularProgress size={20} color="inherit" />
          </div>
        ) : (
          <>
            {showChatBot ? (
              <>
                {!!hiveContext && hiveContext.length > 10 ? (
                  <div onClick={handleSave} className="nextBtn primaryBtn">
                    Save
                  </div>
                ) : (
                  <div
                    onClick={handleContextError}
                    className="nextBtn disabledBtn"
                  >
                    Save
                  </div>
                )}
              </>
            ) : (
              <>
                {editHiveForm.hiveName && editHiveForm.hiveName.length > 2 ? (
                  <div onClick={handleSave} className="nextBtn primaryBtn">
                    Save
                  </div>
                ) : (
                  <div onClick={handleError} className="nextBtn disabledBtn">
                    Save
                  </div>
                )}
              </>
            )}
          </>
        )}
      </>
    </div>
    //   </IslandLayout>
    // </PageLayout>
  );
};

export default HiveAbout;
