import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { createTypedSelector } from "store/utils";
import { FeedItem } from "api/models/Feed/Feed";
import { getFeedVideos } from "store/async-actions/Feed/feedActions";
import { TokenResponse } from "@react-oauth/google";

const initialState: {
  connectedGoogleDrive: boolean;
  isFetching: boolean;
  currentStep: number;
  googleAuthResp: TokenResponse | undefined;
} = {
  connectedGoogleDrive: false,
  isFetching: false,
  currentStep: 0,
  googleAuthResp: undefined,
};

const contentMgrSlice = createSlice({
  name: "contentMgr",
  initialState,
  reducers: {
    setGoogleConnected: (state, action: PayloadAction<boolean>) => {
      state.connectedGoogleDrive = action.payload;
    },
    setContentCurrentStep: (state, action: PayloadAction<number>) => {
      state.currentStep = action.payload;
    },
    setGoogleResp: (state, action: PayloadAction<TokenResponse>) => {
      state.googleAuthResp = action.payload;
    },
  },
  extraReducers: {},
});

export const getGoogleDriveConnectedSelector = createTypedSelector(
  (state) => state.contentMgr.connectedGoogleDrive
);

export const getContentCurrStepSelector = createTypedSelector(
  (state) => state.contentMgr.currentStep
);

export const getGoogleRespSelector = createTypedSelector(
  (state) => state.contentMgr.googleAuthResp
);

export const { setGoogleConnected, setContentCurrentStep, setGoogleResp } =
  contentMgrSlice.actions;

export default contentMgrSlice.reducer;
