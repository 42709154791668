import { Dialog, SelectChangeEvent } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import BackButton from "components/BackButton";
import ImageDropzone from "components/ImageDropzone";
import IslandLayout from "components/IslandLayout";
import LineBreak from "components/LineBreak";
import Loader from "components/Loader";
import PageLayout from "components/PageLayout";
import Headers from "components/TextStyles/components/Headers";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import ChannelSettings from "pages/Hive/CreateChannel/ChannelSettings";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { store } from "store";
import {
  createChannel,
  getChannelList,
} from "store/async-actions/Hive/hivePage";
import { InitializeApp } from "store/async-actions/initializeApp";
import {
  getCreateAmountSelector,
  getCreateIsPaidSelector,
  getCreateIsAdsSelector,
  getCreateIsPrivateSelector,
  getCreateVideoMaxDurationSelector,
  setVideoDuration,
  setIsPaid,
  setIsPrivate,
  setIsAds,
  setChannelAmount,
  getLogoFileUrlSelector,
  getMobileLogoFileUrlSelector,
  createChannelIsFetchingSelector,
  createChannelIsDoneSelector,
  setIsDone,
} from "store/reducers/CreateChannel/createChannel";
import { getHiveSelector } from "store/reducers/HiveDetails/hiveDetails";
import { isMemberViewSelector } from "store/reducers/Profile/profile";

const CreateChannel = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(setVideoDuration(0));
    dispatch(setIsPaid(false));
    dispatch(setIsPrivate(false));
    dispatch(setIsAds(false));
    dispatch(setChannelAmount(""));
    dispatch(setIsDone(false));
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const amount = useAppSelector(getCreateAmountSelector);
  const isPaid = useAppSelector(getCreateIsPaidSelector);
  const isAds = useAppSelector(getCreateIsAdsSelector);
  const isPrivate = useAppSelector(getCreateIsPrivateSelector);
  const videoDuration = useAppSelector(getCreateVideoMaxDurationSelector);
  const isFetching = useAppSelector(createChannelIsFetchingSelector);
  const isDone = useAppSelector(createChannelIsDoneSelector);

  const logoUrl = useAppSelector(getLogoFileUrlSelector);
  const mobileUrl = useAppSelector(getMobileLogoFileUrlSelector);

  const hiveDetails = useAppSelector(getHiveSelector);

  const [createChannelForm, setCreateChannelForm] = useState({
    channelName: "",
    channelDescription: "",
  });

  const isMember = useAppSelector(isMemberViewSelector);

  const [linkTag, setLinkTag] = useState("");

  const handleLinkChange = (value: SelectChangeEvent) => {
    setLinkTag(value.target.value);
  };

  const [customTag, setCustomTag] = useState("");

  const handleCustomTag = (e: any) => {
    setCustomTag(e.target.value.slice(0, 30));
  };

  useEffect(() => {
    if (isDone) {
      dispatch(
        getChannelList({
          hiveId: hiveDetails?.communityId!,
          isMemberView: isMember,
        })
      );
      navigate("/home");
      dispatch(setIsDone(false));
    }
  }, [isDone]);

  const handleCreateChannel = () => {
    dispatch(
      createChannel({
        channelName: createChannelForm.channelName,
        category: "",
        channelTier:
          isPrivate && +amount > 0
            ? "PRIVATE_PAID"
            : +amount > 0
            ? "PAID"
            : isPrivate
            ? "INVITE"
            : "FREE",
        uploadFlag: false,
        amount: +amount,
        paymentType: "FREE",
        organizationId: hiveDetails?.communityId!,
        description: createChannelForm.channelDescription,
        channelType: "VIDEO",
        channelLogo: mobileUrl,
        channelWebLogo: logoUrl,
        channelBanner: "",
        duration: videoDuration,
        advFrequency: 5,
        advertisement: isAds,
        orderByDesc: true,
        ctaName: linkTag === "custom" ? customTag : linkTag,
        channelTabs: [
          {
            tabType: "attribute1",
            tabName: "",
            activeFlag: false,
          },
          {
            tabType: "attribute2",
            tabName: "",
            activeFlag: false,
          },
          {
            tabType: "attribute3",
            tabName: "Description",
            activeFlag: true,
          },
          {
            tabType: "attribute4",
            tabName: "",
            activeFlag: false,
          },
          {
            tabType: "attribute5",
            tabName: "",
            activeFlag: false,
          },
        ],
      })
    );
  };

  const handleChannelForm = (e: React.ChangeEvent<any>, limit: number) => {
    setCreateChannelForm((prevForm) => ({
      ...prevForm,
      [e.target.name]: e.target.value.slice(0, limit),
    }));
  };

  return (
    <PageLayout sideMenu={true}>
      <IslandLayout>
        <div className="create_channel_container">
          <BackButton />
          <Headers children="Create new channel" />
          <div>
            <div className="title_and_limit">
              <h4>Channel Name*</h4>
              <div className="character_limit text-sm">
                {createChannelForm.channelName.length}/18
              </div>
            </div>
            <LineBreak />
            <input
              name="channelName"
              value={createChannelForm.channelName}
              onChange={(e) => handleChannelForm(e, 18)}
              className="input_border text_padding input_width_full"
              placeholder="Eg: Introduction"
            />
          </div>
          <div>
            <div className="title_and_limit">
              <h4>Channel Description</h4>
              <div className="character_limit text-sm">
                {createChannelForm.channelDescription.length}/280
              </div>{" "}
            </div>
            <LineBreak />
            <textarea
              name="channelDescription"
              value={createChannelForm.channelDescription}
              onChange={(e) => handleChannelForm(e, 280)}
              className="input_border text_padding input_width_full who_are_we_section_text"
              placeholder="A few words about the channel"
            />
          </div>
          <div>
            <h4>Channel Image</h4>
            <LineBreak />
            <ImageDropzone file="channel" />
          </div>
          <div>
            <h4>Channel Settings</h4>
            <LineBreak />
            <ChannelSettings
              linkTag={linkTag}
              handleLinkChange={handleLinkChange}
              handleCustomTag={handleCustomTag}
              customTag={customTag}
            />
          </div>
          <div className="flex-end">
            <div className="create_channel_btns">
              <div onClick={() => navigate("..")} className="secondaryBtn">
                Cancel
              </div>
              {createChannelForm.channelName ? (
                <div onClick={handleCreateChannel} className="primaryBtn">
                  Create
                </div>
              ) : (
                <div className="disabledBtn">Create</div>
              )}
            </div>
          </div>
        </div>
      </IslandLayout>
      <Dialog open={isFetching}>
        <div className="loader_padding">
          <CircularProgress size={30} color="inherit" />
        </div>
      </Dialog>
    </PageLayout>
  );
};

export default CreateChannel;
