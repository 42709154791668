import AnalyticsCapsule from "components/AnalyticsCapsule";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import React, { useEffect } from "react";
import ReactTimeAgo from "react-time-ago";
import { getVisitorsData } from "store/async-actions/Analytics/analyticsActions";
import {
  getFromDateSelector,
  getToDateSelector,
  getVisitorsSelector,
} from "store/reducers/Analytics/analytics";
import { getHiveUuid } from "store/reducers/HiveDetails/hiveDetails";
import { VictoryBar, VictoryChart, VictoryStack, VictoryAxis } from "victory";
import moment from "moment";
import dayjs from "dayjs";

type FormatterProps = {
  value: number;
  unit: string;
  suffix: string;
};

const VisitorsGraph = () => {
  const dispatch = useAppDispatch();
  const hiveUuid = useAppSelector(getHiveUuid);

  const fromDate = useAppSelector(getFromDateSelector);
  const toDate = useAppSelector(getToDateSelector);

  const visitorsData = useAppSelector(getVisitorsSelector);

  useEffect(() => {
    dispatch(
      getVisitorsData({
        organizationUuid: hiveUuid,
        fromDate: fromDate,
        toDate: toDate,
      })
    );
  }, [hiveUuid, dispatch, fromDate, toDate]);

  return (
    <AnalyticsCapsule>
      <div className="tags visitors_graph_limit">
        <h3>
          Usage date from:{" "}
          <ReactTimeAgo
            date={
              new Date(
                moment.utc(fromDate).local().format("YYYY-MM-DD HH:mm:ss")
              )
            }
            locale="en-GB"
          />{" "}
        </h3>

        <VictoryChart domainPadding={10}>
          <VictoryAxis
            tickFormat={(date) => moment(date).format("MMM D")}
            style={{
              tickLabels: { fontSize: 8 },
            }}
            tickCount={3}
          />
          <VictoryAxis
            dependentAxis
            style={{
              tickLabels: { fontSize: 8 },
            }}
            tickCount={7}
          />
          <VictoryStack>
            <VictoryBar
              cornerRadius={3}
              style={{ data: { fill: "#000", width: "10" } }}
              data={visitorsData}
              x="date"
              y="newUsers"
            />
            <VictoryBar
              cornerRadius={3}
              style={{ data: { fill: "#41C667", width: "10" } }}
              data={visitorsData}
              x="date"
              y="existingUsers"
            />
          </VictoryStack>
        </VictoryChart>
      </div>
    </AnalyticsCapsule>
  );
};

export default VisitorsGraph;
