import PageLayout from "components/PageLayout";
import { Outlet } from "react-router-dom";
import { useParams, Params } from "react-router-dom";

import { useChatsSocket } from "services/socket";

const ChatsPagesContainer = () => {
  const { roomId = "" } = useParams<Params<"roomId">>();

  useChatsSocket(roomId);

  return (
    <PageLayout sideMenu={false}>
      <Outlet />
    </PageLayout>
  );
};

export default ChatsPagesContainer;
