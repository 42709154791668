import Activities from "components/Activities";
import ContactSection from "components/ContactSection.tsx/ContactSection";
import IslandLayout from "components/IslandLayout";
import LineBreak from "components/LineBreak";
import OnlineMembers from "components/OnlineMembers/OnlineMembers";
import PlansCta from "components/PlansCta/PlansCta";
import SocialLinks from "components/SocialLinks";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import React, { useState, useEffect } from "react";
import {
  getContactInfo,
  getHiveActivities,
  getOnlineMembers,
} from "store/async-actions/Hive/hivePage";
import {
  getHiveActivitiesSelector,
  getOnlineMembersSelector,
  lessHiveDetailsSelector,
} from "store/reducers/HiveDetails/hiveActivities";
import {
  getHiveSelector,
  getHiveUuid,
} from "store/reducers/HiveDetails/hiveDetails";
import { getHiveContactSelector } from "store/reducers/HiveDetails/hiveSettings";
import {
  getHiveConfigMenuSelector,
  getSubscriptionsTabSelector,
} from "store/reducers/HiveDetails/mainMenu";

const HiveConfig = () => {
  const hiveActivities = useAppSelector(getHiveActivitiesSelector);
  const lessActivties = useAppSelector(lessHiveDetailsSelector);
  const contactInfo = useAppSelector(getHiveContactSelector);

  const hiveConfigMenu = useAppSelector(getHiveConfigMenuSelector);

  const dispatch = useAppDispatch();

  const [showMoreClicked, setShowMoreClicked] = useState(false);

  const handleShowMore = () => {
    setShowMoreClicked((prevState) => !prevState);
  };

  const hiveDetails = useAppSelector(getHiveSelector);

  useEffect(() => {
    hiveDetails &&
      dispatch(
        getHiveActivities({
          communityId: hiveDetails?.communityId!,
          pageNo: 0,
          contentLimit: 30,
        })
      );
  }, [hiveDetails, dispatch]);

  const hiveUuid = useAppSelector(getHiveUuid);

  useEffect(() => {
    setTimeout(() => {
      dispatch(
        getOnlineMembers({
          organizationUuid: hiveUuid,
        })
      );
    }, 5000);
  }, [hiveUuid]);

  useEffect(() => {
    dispatch(
      getOnlineMembers({
        organizationUuid: hiveUuid,
      })
    );
    const intervalId = setInterval(() => {
      dispatch(
        getOnlineMembers({
          organizationUuid: hiveUuid,
        })
      );
    }, 30000);

    return () => clearInterval(intervalId);
  }, [dispatch, hiveUuid]);

  useEffect(() => {
    hiveDetails &&
      dispatch(getContactInfo({ organizationUuid: hiveDetails.communityUuid }));
  }, [dispatch, hiveDetails]);

  const onlineMembers = useAppSelector(getOnlineMembersSelector);

  const subscriptions = useAppSelector(getSubscriptionsTabSelector);

  return (
    <div className="hive_config_container">
      <IslandLayout>
        {!!subscriptions && subscriptions.length > 0 && <PlansCta />}
      </IslandLayout>
      {!!subscriptions && subscriptions.length > 0 && <LineBreak />}
      <IslandLayout>
        <SocialLinks />
      </IslandLayout>
      <LineBreak />
      {contactInfo?.isActive &&
        (!!contactInfo.address ||
          !!contactInfo.email ||
          (!!hiveConfigMenu[0] &&
            !!hiveConfigMenu[0].componentDescription)) && (
          <IslandLayout>
            <ContactSection />
          </IslandLayout>
        )}
      <LineBreak />
      {!!onlineMembers && onlineMembers.length > 0 && (
        <IslandLayout>
          <OnlineMembers />
        </IslandLayout>
      )}
      <LineBreak />
      {!!hiveActivities && (
        <IslandLayout>
          <Activities
            hiveActivities={
              showMoreClicked ? hiveActivities.content : lessActivties
            }
          />
          <div className="show_more_btn" onClick={handleShowMore}>
            <div className="secondaryBtn">
              Show {showMoreClicked ? "Less" : "More"}
            </div>
          </div>
        </IslandLayout>
      )}
      <LineBreak />
    </div>
  );
};

export default HiveConfig;
