import IslandLayout from "components/IslandLayout";
import PageLayout from "components/PageLayout";
import React from "react";
import contentStudio from "../../assets/png/contentStudioDummy.png";
import Headers from "components/TextStyles/components/Headers";
import { Outlet } from "react-router-dom";

const Ai = () => {
  return (
    <PageLayout sideMenu={true} rightMenu={false}>
      <IslandLayout>
        <div className="ai_container">
          <h1>Veehive Studio</h1>
          <p>
            AI-powered creative tools to help you generate engaging and
            impactful content.
          </p>
          <Outlet />
        </div>
      </IslandLayout>
    </PageLayout>
  );
};

export default Ai;
