import React, { memo, useCallback, useEffect, useState } from "react";
import Modal from "@mui/material/Modal";
import Slide from "@mui/material/Slide";
import { AxiosProgressEvent } from "axios";

// import { TabValues, useActiveTabContext } from "../ChannelPageContext";

import CommentsRoomsList from "./CommentsRoomsList";
import TitleModal from "./TitleModal";

import Chat from "components/Chat";
import {
  useAppDispatch,
  useAppSelector,
  useAppShallowSelector,
} from "hooks/redux";
import {
  getVideoCommentViewerMessages,
  sendViewerTextMessages,
  getVideoRoomsByOwner,
  getVideoMessagesByOwner,
  sendMessagesByOwner,
  sendCommentAttachmentFileByOwner,
  sendCommentVoiceByOwner,
  sendCommentAttachmentFileByViewer,
  sendCommentVoiceByViewer,
  checkOwnership,
} from "store/async-actions/Content/content";

import {
  clearComments,
  getChatListCommentsSelector,
  getCommentDataRequestSelector,
  getIsOwnerContentSelector,
  clearCommentingPersonList,
  getIsLoadingCommentsSelector,
  getIsLoadingSendCommentsAttachmentSelector,
  getActiveVideoSelector,
} from "store/reducers/Content/content";
import { profileUserIdSelector } from "store/reducers/Profile/profile";
import { getActiveChannelUuidSelector } from "store/reducers/ChannelDetails/channelList";

type AvailableFormDataKeysOwner = "roomId" | "message" | "userId" | "file";
type AvailableFormDataKeysViewer =
  | "organizationId"
  | "message"
  | "channelId"
  | "videoId"
  | "file";

interface TypedFormData extends FormData {
  append(
    name: AvailableFormDataKeysOwner | AvailableFormDataKeysViewer,
    value: number | string | Blob,
    fileName?: string
  ): void;
}

export type ChoisenCommentRoom = {
  roomId: string;
  roomName: string;
  roomLogo: string;
} | null;

const ChannelCommentsModal = () => {
  const dispatch = useAppDispatch();
  const { channelId, organizationId, videoId } = useAppShallowSelector(
    getCommentDataRequestSelector
  );
  const userId = useAppSelector(profileUserIdSelector);
  const chanList = useAppShallowSelector(getChatListCommentsSelector);
  const isOwnerContent = useAppSelector(getIsOwnerContentSelector);
  const isLoading = useAppSelector(getIsLoadingCommentsSelector);
  const isLoadingAttachment = useAppSelector(
    getIsLoadingSendCommentsAttachmentSelector
  );

  const activeVideo = useAppSelector(getActiveVideoSelector);

  const [choiseVideoRoomComments, setChoiseVideoRoomComments] =
    useState<ChoisenCommentRoom>(null);

  useEffect(() => {
    organizationId &&
      channelId &&
      dispatch(
        checkOwnership({
          organizationId: organizationId,
          channelId: channelId,
        })
      );
  }, [dispatch, isOwnerContent, organizationId, channelId]);

  useEffect(() => {
    if (channelId && organizationId && videoId && !isOwnerContent) {
      dispatch(
        getVideoCommentViewerMessages({
          organizationId,
          channelId,
          videoId,
        })
      );
    }
  }, [
    channelId,
    dispatch,
    organizationId,
    videoId,
    isOwnerContent,
    activeVideo,
  ]);

  useEffect(() => {
    if (isOwnerContent && videoId) {
      dispatch(
        getVideoRoomsByOwner({ videoId: videoId, channelId: channelId! })
      );
    }
  }, [isOwnerContent, dispatch, videoId]);

  useEffect(() => {
    if (isOwnerContent && choiseVideoRoomComments?.roomId) {
      dispatch(
        getVideoMessagesByOwner({ roomId: choiseVideoRoomComments?.roomId })
      );
    }

    return () => {
      dispatch(clearComments());
    };
  }, [choiseVideoRoomComments?.roomId, dispatch, isOwnerContent]);

  useEffect(() => {
    return () => {
      dispatch(clearComments());
      dispatch(clearCommentingPersonList());
    };
  }, [dispatch]);

  const writeCommentOnVideo = useCallback(
    async (messageData: SendMessageParams<AxiosProgressEvent, AbortSignal>) => {
      if (channelId && organizationId && videoId) {
        if (messageData.type === "text") {
          dispatch(
            sendViewerTextMessages({
              message: messageData.content ?? "",
              channelId,
              organizationId,
              videoId,
            })
          );
        } else {
          const formData = new FormData() as TypedFormData;
          formData.append("organizationId", organizationId);
          formData.append("channelId", channelId);
          formData.append("videoId", videoId);
          formData.append("message", messageData.content || "");
          formData.append("file", messageData.file as File);
          messageData.type === "attachment"
            ? await dispatch(
                sendCommentAttachmentFileByViewer({
                  body: formData,
                  abortSignal: messageData.abortSignal!,
                  cb: messageData.cb!,
                })
              )
                .unwrap()
                .catch((error) => {
                  throw error;
                })
            : dispatch(sendCommentVoiceByViewer(formData));
        }
      }
    },
    [channelId, dispatch, organizationId, videoId]
  );

  const replyToComments = useCallback(
    async (messageData: SendMessageParams<AxiosProgressEvent, AbortSignal>) => {
      if (choiseVideoRoomComments?.roomId) {
        if (messageData.type === "text") {
          dispatch(
            sendMessagesByOwner({
              roomId: choiseVideoRoomComments.roomId,
              message: messageData.content ?? "",
            })
          );
        } else {
          if (userId) {
            const formData = new FormData() as TypedFormData;
            formData.append("message", messageData?.content || "");
            formData.append("file", messageData.file as File);
            formData.append("roomId", choiseVideoRoomComments.roomId);
            formData.append("userId", userId);
            messageData.type === "attachment"
              ? await dispatch(
                  sendCommentAttachmentFileByOwner({
                    body: formData,
                    abortSignal: messageData.abortSignal!,
                    cb: messageData.cb!,
                  })
                )
                  .unwrap()
                  .catch((error) => {
                    throw error;
                  })
              : dispatch(sendCommentVoiceByOwner(formData));
          }
        }
      }
    },
    [choiseVideoRoomComments?.roomId, dispatch, userId]
  );

  const handleSendMessage = useCallback(
    async (messageData: SendMessageParams<AxiosProgressEvent, AbortSignal>) => {
      if (isOwnerContent) {
        await replyToComments(messageData);
      }
      if (!isOwnerContent) {
        await writeCommentOnVideo(messageData);
      }
    },
    [isOwnerContent, replyToComments, writeCommentOnVideo]
  );

  const handleClickBack = useCallback(() => {
    setChoiseVideoRoomComments(null);
    if (isOwnerContent && videoId) {
      dispatch(
        getVideoRoomsByOwner({ videoId: videoId, channelId: channelId! })
      );
    }
  }, [dispatch, videoId, isOwnerContent]);

  return (
    <div
      // open={true}
      className={"comments_modal_container channel_page_modal"}
      // hideBackdrop={true}
    >
      {/* <Slide direction="left" in={true} mountOnEnter unmountOnExit> */}
      <div className="comments_modal_wrap">
        {/* {!choiseVideoRoomComments && ( */}
        {/* )} */}
        {/* // {isOwnerContent && !choiseVideoRoomComments ? ( */}
        {isOwnerContent && !choiseVideoRoomComments ? (
          <CommentsRoomsList
            selectCommentRoom={setChoiseVideoRoomComments}
            isLoading={isLoading}
          />
        ) : (
          <>
            <TitleModal
              selectedCommentRoom={choiseVideoRoomComments}
              onBack={handleClickBack}
              logoSrc={choiseVideoRoomComments?.roomLogo}
              roomName={choiseVideoRoomComments?.roomName}
            />
            <Chat
              chatList={chanList}
              handlePagginationUpdate={() => {}}
              sendMessage={handleSendMessage}
              loading={isLoading}
              loadingAttachment={isLoadingAttachment}
            />
          </>
        )}
      </div>
      {/* </Slide> */}
    </div>
  );
};

const Container = () => {
  return <ChannelCommentsModal />;
  //   const { activeTopTab } = useActiveTabContext();
  //   return activeTopTab === TabValues.COMMENTS ? <ChannelCommentsModal /> : null;
};

export default memo(Container);
