import AnalyticsCapsule from "components/AnalyticsCapsule";
import { BearIndication, BullIndication } from "components/SVG/SVG";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import React, { useEffect } from "react";
import { getSentimentAnalysis } from "store/async-actions/Analytics/analyticsActions";
import { getSentimentsSelector } from "store/reducers/Analytics/analytics";

const SentimentAnalysis = () => {
  const dispatch = useAppDispatch();

  const sentiments = useAppSelector(getSentimentsSelector);

  useEffect(() => {
    dispatch(
      getSentimentAnalysis({
        chatroomId: "b1166ee6e1b4411c8a6826ce7d9684bb",
      })
    );
  }, [dispatch]);

  return (
    <div className="usage_data_container">
      <AnalyticsCapsule>
        <div className="headers_and_value">
          <h5>Positive</h5>
          <div className="sentiment_content">
            <h2>{sentiments?.Positive.toFixed(0)}</h2>
            <BullIndication />
          </div>
        </div>
      </AnalyticsCapsule>
      <AnalyticsCapsule>
        <div className="headers_and_value">
          <h5>Negative</h5>
          <div className="sentiment_content">
            <h2>{sentiments?.Negative.toFixed(0)} </h2>
            <BearIndication />
          </div>
        </div>
      </AnalyticsCapsule>
      <AnalyticsCapsule>
        <div className="headers_and_value">
          <h5>Neutral</h5>
          <h2>{sentiments?.Neutral.toFixed(0)}</h2>
        </div>
      </AnalyticsCapsule>
    </div>
  );
};

export default SentimentAnalysis;
