import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  CreateStoryModel,
  OrganizationStory,
  OrganizationStoryItem,
  OrganizationStoryObject,
  SegmentItem,
  SegmentItemReactModel,
  StoryDaywiseAnalytics,
  StoryItemModel,
  StoryLocationModel,
  StorySocialCountItem,
} from "api/models/Story/story";
import {
  addStorySegments,
  createStory,
  editStory,
  editStorySegments,
  getDayWiseAnalytics,
  getSegmentLocation,
  getStories,
  getStoryDetails,
  getStoryItem,
  getStoryLocation,
  getStorySegmentItemReact,
  getStorySegments,
  getStorySocialCount,
} from "store/async-actions/Story/storyActions";
import { createTypedSelector } from "store/utils";

const initialState: {
  isFetching: boolean;
  createStoryResp: CreateStoryModel | undefined;
  storyTitle: string;
  storyDesc: string;
  actionLink: string;
  storyCreated: boolean;
  storyUuid: string;
  storySegments: SegmentItem[];
  imageUploaded: boolean;
  compressedImgUrl: string;
  addSegmentsResp: any;
  currentStoryId: number;
  currentStoryIndex: number;
  editSegmentsResp: any;
  uploadedFile: File | Blob | null;
  color: string;
  getStoriesResp: StoryItemModel[];
  storyLocation: StoryLocationModel[];
  storiesList: OrganizationStory[];
  reducedStoryList: OrganizationStoryObject[];
  storyItem: OrganizationStoryItem | null;
  startClicked: boolean;
  currentStoryItem: OrganizationStoryItem | null;
  viewingStoryUuid: string;
  segmentItemReact: SegmentItemReactModel | undefined;
  editStoryResp: boolean;
  storySocialCount: StorySocialCountItem[];
  unknownCount: number;
  dayWiseAnalytics: StoryDaywiseAnalytics[];
} = {
  addSegmentsResp: "",
  isFetching: false,
  createStoryResp: undefined,
  storyTitle: "",
  storyDesc: "",
  storyCreated: false,
  storyUuid: "",
  storySegments: [],
  imageUploaded: false,
  compressedImgUrl: "",
  currentStoryId: 0,
  currentStoryIndex: 0,
  editSegmentsResp: "",
  uploadedFile: null,
  color: "#B4CEBE",
  getStoriesResp: [],
  storyLocation: [],
  storiesList: [],
  reducedStoryList: [],
  storyItem: null,
  startClicked: false,
  viewingStoryUuid: "",
  currentStoryItem: null,
  segmentItemReact: undefined,
  actionLink: "",
  editStoryResp: false,
  storySocialCount: [],
  unknownCount: 0,
  dayWiseAnalytics: [],
};

const storySlice = createSlice({
  name: "story",
  initialState,
  reducers: {
    setStoryImgFile: (state, action: PayloadAction<string>) => {
      state.compressedImgUrl = action.payload;
      state.imageUploaded = true;
    },
    setStoryImageUploaded: (state, action: PayloadAction<boolean>) => {
      state.imageUploaded = action.payload;
    },
    emptyStorySegments: (state) => {
      state.storySegments = [];
    },
    setStoryTitle(state, action: PayloadAction<string>) {
      state.storyTitle = action.payload.slice(0, 60);
    },
    setActionLink(state, action: PayloadAction<string>) {
      state.actionLink = action.payload;
    },
    setStoryDesc(state, action: PayloadAction<string>) {
      state.storyDesc = action.payload.slice(0, 240);
    },
    setStoryUuid(state, action: PayloadAction<string>) {
      state.storyUuid = action.payload;
    },
    setStoryColor(state, action: PayloadAction<string>) {
      state.color = action.payload.slice(0, 100);
    },
    setStoryCreated(state, action: PayloadAction<boolean>) {
      state.storyCreated = action.payload;
    },
    setCurrentStoryId(state, action: PayloadAction<number>) {
      state.currentStoryId = action.payload;
    },
    setCurrentStoryIndex(state, action: PayloadAction<number>) {
      state.currentStoryIndex = action.payload;
    },
    setStoryBlob(state, action: PayloadAction<File | Blob | null>) {
      state.uploadedFile = action.payload;
    },
    setStartClicked: (state, action: PayloadAction<boolean>) => {
      state.startClicked = action.payload;
    },
    setViewingStoryUuid: (state, action: PayloadAction<string>) => {
      state.viewingStoryUuid = action.payload;
    },
    setCurrentStoryItem: (
      state,
      action: PayloadAction<OrganizationStoryItem>
    ) => {
      state.currentStoryItem = action.payload;
    },
  },
  extraReducers: {
    [createStory.pending.type]: (state) => {
      state.isFetching = true;
      state.storyCreated = false;
    },
    [createStory.fulfilled.type]: (
      state,
      action: PayloadAction<CreateStoryModel>
    ) => {
      state.createStoryResp = action.payload;
      state.isFetching = false;
      state.storyUuid = action.payload.storyUuid;
      state.storyCreated = true;
    },
    [createStory.rejected.type]: (state) => {
      state.isFetching = false;
      state.storyCreated = false;
    },

    //GEt STORY SEGMENTS
    [getStorySegments.pending.type]: (state) => {
      state.isFetching = true;
    },
    [getStorySegments.fulfilled.type]: (
      state,
      action: PayloadAction<SegmentItem[]>
    ) => {
      state.storySegments = action.payload;
      action.payload[0] &&
        action.payload[0].id &&
        (state.currentStoryId = action.payload[0].id);
      state.isFetching = false;
    },
    [getStorySegments.rejected.type]: (state) => {
      state.isFetching = false;
    },

    //ADD STORY SEGMENTS
    [addStorySegments.pending.type]: (state) => {
      state.isFetching = true;
    },
    [addStorySegments.fulfilled.type]: (state, action: PayloadAction<any>) => {
      state.addSegmentsResp = action.payload;

      state.isFetching = false;
    },
    [addStorySegments.rejected.type]: (state) => {
      state.isFetching = false;
    },

    //Edit STORY
    [editStory.pending.type]: (state) => {
      state.isFetching = true;
    },
    [editStory.fulfilled.type]: (state, action: PayloadAction<boolean>) => {
      state.editStoryResp = action.payload;

      state.isFetching = false;
    },
    [editStory.rejected.type]: (state) => {
      state.isFetching = false;
    },

    //Edit STORY SEGMENTS
    [editStorySegments.pending.type]: (state) => {
      state.isFetching = true;
    },
    [editStorySegments.fulfilled.type]: (state, action: PayloadAction<any>) => {
      state.editSegmentsResp = action.payload;
      state.isFetching = false;
    },
    [editStorySegments.rejected.type]: (state) => {
      state.isFetching = false;
    },

    //GET STORY SEGMENT REACTIONS
    [getStorySegmentItemReact.pending.type]: (state) => {
      state.isFetching = true;
    },
    [getStorySegmentItemReact.fulfilled.type]: (
      state,
      action: PayloadAction<any>
    ) => {
      state.segmentItemReact = action.payload;
      state.isFetching = false;
    },
    [getStorySegmentItemReact.rejected.type]: (state) => {
      state.isFetching = false;
    },

    //GET STORY DAY WISE ANALYTICS
    [getDayWiseAnalytics.pending.type]: (state) => {
      state.isFetching = true;
    },
    [getDayWiseAnalytics.fulfilled.type]: (
      state,
      action: PayloadAction<StoryDaywiseAnalytics[]>
    ) => {
      state.dayWiseAnalytics = action.payload;
      state.isFetching = false;
    },
    [getDayWiseAnalytics.rejected.type]: (state) => {
      state.isFetching = false;
    },

    //GET STORIES
    [getStories.pending.type]: (state) => {
      state.isFetching = true;
    },
    [getStories.fulfilled.type]: (
      state,
      action: PayloadAction<StoryItemModel[]>
    ) => {
      state.getStoriesResp = action.payload;

      state.isFetching = false;
    },
    [getStories.rejected.type]: (state) => {
      state.isFetching = false;
    },

    //GET STORY LOCATION
    [getStoryLocation.pending.type]: (state) => {
      state.isFetching = true;
    },
    [getStoryLocation.fulfilled.type]: (
      state,
      action: PayloadAction<StoryLocationModel[]>
    ) => {
      state.storyLocation = action.payload;
      state.isFetching = false;
    },
    [getStoryLocation.rejected.type]: (state) => {
      state.isFetching = false;
    },

    //GET STORY SOCIAL COUNT
    [getStorySocialCount.pending.type]: (state) => {
      state.isFetching = true;
    },
    [getStorySocialCount.fulfilled.type]: (
      state,
      action: PayloadAction<StorySocialCountItem[]>
    ) => {
      state.unknownCount = 0;
      state.storySocialCount = action.payload;
      action.payload.map((data, idx) => {
        if (data.origin === "unknown") {
          state.unknownCount = data.originCount;
        }
      });
      // action.payload.map((data, idx) => {
      //   if (data.origin !== "unknown") {
      //     state.unknownCount -= data.originCount;
      //   }
      // });
      state.isFetching = false;
    },
    [getStorySocialCount.rejected.type]: (state) => {
      state.isFetching = false;
    },

    //GET STORY LOCATION
    [getSegmentLocation.pending.type]: (state) => {
      state.isFetching = true;
    },
    [getSegmentLocation.fulfilled.type]: (
      state,
      action: PayloadAction<StoryLocationModel[]>
    ) => {
      state.storyLocation = action.payload;
      state.isFetching = false;
    },
    [getSegmentLocation.rejected.type]: (state) => {
      state.isFetching = false;
    },

    /**
     *
     * Stories
     */

    [getStoryDetails.pending.type]: (state) => {
      state.isFetching = true;
    },
    [getStoryDetails.fulfilled.type]: (
      state,
      action: PayloadAction<OrganizationStory[]>
    ) => {
      state.storiesList = action.payload;
      state.reducedStoryList = [];
      state.storiesList.map((story, idx) => {
        state.reducedStoryList.push({
          selectedEmoji: "default",
          storyItemId: story.id,
          storyUuid: story.storyUuid,
          url:
            story.type === "text"
              ? "https://veehivedev-images.s3.amazonaws.com/background/portrait_photo.png"
              : story.storyUrl,
          title: story.title,
          description: story.description,
          type: story.type === "text" ? "image" : story.type,
          actionLink: story.actionLink,
          colorCode: story.colorCode,
          longDescriptionList: story.longDescriptionList,
          textColorCode: story.textColorCode,
        });
      });
      state.isFetching = false;
    },
    [getStoryDetails.rejected.type]: (state) => {
      state.isFetching = false;
    },

    /**
     * Story Item
     */

    [getStoryItem.pending.type]: (state) => {
      state.isFetching = true;
    },
    [getStoryItem.fulfilled.type]: (
      state,
      action: PayloadAction<OrganizationStoryItem>
    ) => {
      state.storyItem = action.payload;
      state.isFetching = false;
    },
    [getStoryItem.rejected.type]: (state) => {
      state.isFetching = false;
    },
  },
});

export const getCurrentStoryIndexSelector = createTypedSelector(
  (state) => state.story.currentStoryIndex
);

export const getStoryLocationsSelector = createTypedSelector(
  (state) => state.story.storyLocation
);

export const getStorySegmentItemReactSelector = createTypedSelector(
  (state) => state.story.segmentItemReact
);

export const getStoryDaywiseAnalyticsSelector = createTypedSelector(
  (state) => state.story.dayWiseAnalytics
);

export const getUnknownCountSelector = createTypedSelector(
  (state) => state.story.unknownCount
);

export const getAllStoriesSelector = createTypedSelector(
  (state) => state.story.getStoriesResp
);

export const getStorySocialCountSelector = createTypedSelector(
  (state) => state.story.storySocialCount
);

export const getCurrentStoryColorSelector = createTypedSelector(
  (state) => state.story.color
);

export const getCurrentStoryIdSelector = createTypedSelector(
  (state) => state.story.currentStoryId
);

export const getStoryTitleSelector = createTypedSelector(
  (state) => state.story.storyTitle
);

export const getStorySegmentsSelector = createTypedSelector(
  (state) => state.story.storySegments
);

export const getStoryDescSelector = createTypedSelector(
  (state) => state.story.storyDesc
);

export const getCreateStoryRespSelector = createTypedSelector(
  (state) => state.story.createStoryResp
);

export const getStoryIsFetchingSelector = createTypedSelector(
  (state) => state.story.isFetching
);

export const getStoryCreatedSelector = createTypedSelector(
  (state) => state.story.storyCreated
);

export const getStoryUuidSelector = createTypedSelector(
  (state) => state.story.storyUuid
);

export const getStoryImageIsUploaded = createTypedSelector(
  (state) => state.story.imageUploaded
);

export const getStoryImgUrlSelector = createTypedSelector(
  (state) => state.story.compressedImgUrl
);

export const getStoriesListSelector = createTypedSelector(
  (state) => state.story.reducedStoryList
);

export const getStoryItemSelector = createTypedSelector(
  (state) => state.story.storyItem
);

export const getStartClickedSelector = createTypedSelector(
  (state) => state.story.startClicked
);

export const getViewingStoryUuidSelector = createTypedSelector(
  (state) => state.story.viewingStoryUuid
);

export const getCurrentStoryItemSelector = createTypedSelector(
  (state) => state.story.currentStoryItem
);

export const getActionLinkSelector = createTypedSelector(
  (state) => state.story.actionLink
);

export const getEditStoryRespSelector = createTypedSelector(
  (state) => state.story.editStoryResp
);

export const {
  setStoryDesc,
  setStoryTitle,
  setStoryCreated,
  setStoryImgFile,
  setStoryImageUploaded,
  setCurrentStoryId,
  setCurrentStoryIndex,
  setStoryBlob,
  setStoryColor,
  setStoryUuid,
  setStartClicked,
  setCurrentStoryItem,
  setViewingStoryUuid,
  emptyStorySegments,
  setActionLink,
} = storySlice.actions;

export default storySlice.reducer;
