import BaseApi from "api/base";
import {
  AnalyticsCTARequestData,
  CtaNameReq,
  CtaPageReq,
  ProductCountItem,
  ProductDetailsRequest,
  RevenueGraphModel,
  RevenueGraphRequest,
  UserActionsRequest,
  VisitorsModel,
  VisitorsRequest,
} from "api/models/Analytics/ctaModel";
import {
  ChannelMetricsModel,
  MetricsRequestModel,
  PurchaseChannelModel,
  PurchaseDetailModel,
  RedirectLinkReq,
  RevenueModel,
  SentimentAnalysisModel,
  StoryViewModel,
  UsageRequestModel,
  UsageRespModel,
  VideoCountModel,
  WordCloudModel,
} from "api/models/Analytics/metrics";
import {
  ProductDetailsModel,
  UniqueSessionsRequest,
  UserActionsModel,
} from "api/models/Analytics/sessions";
import { AxiosInstance, AxiosPromise } from "axios";

import { BaseResponse } from "../../models/base";
import { ReactionModel } from "api/models/Story/story";

export class AnalyticsApi extends BaseApi {
  fetchCTALogs(data: AnalyticsCTARequestData): AxiosPromise<BaseResponse> {
    return this.request({
      url: `/spring/analytics/cta/${
        localStorage.getItem("isLoggedIn") === "yes" ? "auth" : "unAuth"
      }/update/logs`,
      method: "POST",
      data,
    });
  }

  getVideosCount(
    data: MetricsRequestModel
  ): AxiosPromise<BaseResponse<VideoCountModel>> {
    return this.request({
      url: `${this.url}/${
        localStorage.getItem("isLoggedIn") === "no" ? "noAuth/" : ""
      }analytics/videos/count`,
      method: "POST",
      data,
    });
  }
  getUsersCount(
    data: MetricsRequestModel
  ): AxiosPromise<BaseResponse<VideoCountModel>> {
    return this.request({
      url: `${this.url}/${
        localStorage.getItem("isLoggedIn") === "no" ? "noAuth/" : ""
      }analytics/users/count`,
      method: "POST",
      data,
    });
  }
  getChannelsCount(
    data: MetricsRequestModel
  ): AxiosPromise<BaseResponse<VideoCountModel>> {
    return this.request({
      url: `${this.url}/${
        localStorage.getItem("isLoggedIn") === "no" ? "noAuth/" : ""
      }analytics/channels/count`,
      method: "POST",
      data,
    });
  }

  getUsageData(
    data: UsageRequestModel
  ): AxiosPromise<BaseResponse<UsageRespModel>> {
    return this.request({
      url: `${this.url}/analytics/get/usage/data`,
      method: "POST",
      data,
    });
  }

  getSentimentAnalysis(data: {
    chatroomId: string;
  }): AxiosPromise<BaseResponse<SentimentAnalysisModel>> {
    return this.request({
      url: `${this.url}/analytics/get/sentiment/analysis`,
      method: "POST",
      data,
    });
  }

  getHiveRevenue(data: {
    organizationId: number;
  }): AxiosPromise<BaseResponse<RevenueModel>> {
    return this.request({
      url: `${this.url}/analytics/org/revenue`,
      method: "POST",
      data,
    });
  }

  getRedirectLinkList(
    data: RedirectLinkReq
  ): AxiosPromise<BaseResponse<string[]>> {
    return this.request({
      url: `/spring/analytics/cta/dashboard/redirect/link/list`,
      method: "POST",
      data,
    });
  }

  getRedirectOriginList(
    data: RedirectLinkReq
  ): AxiosPromise<BaseResponse<string[]>> {
    return this.request({
      url: `/spring/analytics/cta/dashboard/redirect/origin/list`,
      method: "POST",
      data,
    });
  }

  getRedirectLocationList(
    data: RedirectLinkReq
  ): AxiosPromise<BaseResponse<string[]>> {
    return this.request({
      url: `/spring/analytics/cta/dashboard/redirect/location/list`,
      method: "POST",
      data,
    });
  }

  getRedirectDayWiseList(
    data: RedirectLinkReq
  ): AxiosPromise<BaseResponse<string[]>> {
    return this.request({
      url: `/spring/analytics/cta/dashboard/redirect/day/list`,
      method: "POST",
      data,
    });
  }

  getHiveRevenueGraph(
    data: RevenueGraphRequest
  ): AxiosPromise<BaseResponse<RevenueGraphModel[]>> {
    return this.request({
      url: `${this.url}/analytics/org/date/revenue`,
      method: "POST",
      data,
    });
  }

  getPurchaseChannelList(data: {
    organizationId: number;
  }): AxiosPromise<BaseResponse<PurchaseChannelModel[]>> {
    return this.request({
      url: `${this.url}/analytics/all/channel`,
      method: "POST",
      data,
    });
  }

  getAnalyticsChannelMetrics(data: {
    channelId: number;
  }): AxiosPromise<BaseResponse<ChannelMetricsModel>> {
    return this.request({
      url: `${this.url}/analytics/channel/metrics`,
      method: "POST",
      data,
    });
  }

  getStoryViews(data: {
    storyUuid: string;
  }): AxiosPromise<BaseResponse<StoryViewModel>> {
    return this.request({
      url: `/spring/analytics/cta/dashboard/story/count`,
      method: "POST",
      data,
    });
  }

  getSegmentViews(data: {
    storyUuid: string;
    segmentId: number;
  }): AxiosPromise<BaseResponse<StoryViewModel>> {
    return this.request({
      url: `/spring/analytics/cta/dashboard/story/segment/count`,
      method: "POST",
      data,
    });
  }

  getStoryReactions(data: {
    storyUuid: string;
  }): AxiosPromise<BaseResponse<ReactionModel>> {
    return this.request({
      url: `${this.url}/analytics/story/reactions/count`,
      method: "POST",
      data,
    });
  }

  getSegmentReactions(data: {
    storyUuid: string;
    segmentId: number;
  }): AxiosPromise<BaseResponse<ReactionModel>> {
    return this.request({
      url: `${this.url}/analytics/story/segment/reactions/count`,
      method: "POST",
      data,
    });
  }

  getPurchaseDetailList(data: {
    channelId: number;
  }): AxiosPromise<BaseResponse<PurchaseDetailModel[]>> {
    return this.request({
      url: `${this.url}/analytics/payment/transactions/channel`,
      method: "POST",
      data,
    });
  }

  getWordCloud(data: {
    chatroomId: string;
  }): AxiosPromise<BaseResponse<WordCloudModel>> {
    return this.request({
      url: `/ai/wordCloud/getwordCloudScore/chatRoom`,
      method: "POST",
      data,
    });
  }

  getUniqueSessions(
    data: UniqueSessionsRequest
  ): AxiosPromise<BaseResponse<WordCloudModel>> {
    return this.request({
      url: `/spring/analytics/cta/dashboard/get/sessions/list`,
      method: "POST",
      data,
    });
  }

  getVisitorsData(
    data: VisitorsRequest
  ): AxiosPromise<BaseResponse<VisitorsModel>> {
    return this.request({
      url: `/spring/analytics/cta/dashboard/fetch/user/count`,
      method: "POST",
      data,
    });
  }

  getProductsCount(
    data: VisitorsRequest
  ): AxiosPromise<BaseResponse<ProductCountItem[]>> {
    return this.request({
      url: `/spring/analytics/cta/dashboard/fetch/product/count`,
      method: "POST",
      data,
    });
  }

  getProductDetails(
    data: ProductDetailsRequest
  ): AxiosPromise<BaseResponse<ProductDetailsModel>> {
    return this.request({
      url: `/spring/analytics/cta/dashboard/product/sessions/list`,
      method: "POST",
      data,
    });
  }

  getUserActions(
    data: UserActionsRequest
  ): AxiosPromise<BaseResponse<UserActionsModel>> {
    return this.request({
      url: `/spring/analytics/cta/dashboard/session/action/list`,
      method: "POST",
      data,
    });
  }

  getCtaNameDetails(data: CtaNameReq): AxiosPromise<BaseResponse<number>> {
    return this.request({
      url: `/spring/analytics/cta/dashboard/count/cta/name`,
      method: "POST",
      data,
    });
  }

  getPageNameDetails(data: CtaPageReq): AxiosPromise<BaseResponse<number>> {
    return this.request({
      url: `/spring/analytics/cta/dashboard/count/page/name`,
      method: "POST",
      data,
    });
  }
}

export default function analyticsApi(request: AxiosInstance) {
  return new AnalyticsApi({
    request,
    url: `/webApp`,
  });
}
