import IslandLayout from "components/IslandLayout/IslandLayout";
import React, { useEffect, useState } from "react";
import LineBreak from "components/LineBreak/LineBreak";
import {
  CircularProgress,
  MenuItem,
  Select,
  StyledEngineProvider,
  TextField,
} from "@mui/material";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import EventsDateTimePicker from "./components/EventsDateTimePicker";
import {
  getCurrentEventSelector,
  getEventIsLoadingSelector,
  getEventRespSelector,
  getEventsFromDateSelector,
  getEventsToDateSelector,
  setCurrentEvent,
  setEventFromDate,
  setEventToDate,
} from "store/reducers/HiveDetails/events";
import {
  createEvent,
  deleteEvent,
  editEvent,
  uploadEventVideo,
} from "store/async-actions/Hive/eventsActions";
import dayjs from "dayjs";
import { getHiveUuid } from "store/reducers/HiveDetails/hiveDetails";
import { useNavigate } from "react-router-dom";
import BackButton from "components/BackButton/BackButton";
import ImageDropzone from "components/ImageDropzone";
import FileDrop from "components/FileDrop";
import ReactPlayer from "react-player";
import {
  uploadFileSelector,
  isUploadingSelector,
  setVideoFile,
} from "store/reducers/Videos/videos";
import { setFromDate } from "store/reducers/Analytics/analytics";
import {
  setBannerUrlChanged,
  setUrlChanged,
  setBannerUploaded,
  getUrlChangedSelector,
  getBannerUrlChangedSelector,
  getBannerFileUrlSelector,
  getLogoFileUrlSelector,
} from "store/reducers/CreateChannel/createChannel";
import ConfirmationModal from "components/Confirmation";
import AttendeeFormEdit from "./components/AttendeeFormEdit";
import { AttendeeFormDetails } from "api/models/Hive/events";
import Switch from "@mui/joy/Switch";
import { toastInfo } from "hooks/useToastify";
import { CssVarsProvider } from "@mui/joy";

const EditEvent = () => {
  const dispatch = useAppDispatch();
  const currentEvent = useAppSelector(getCurrentEventSelector);

  const [eventDetails, setEventDetails] = useState({
    name: currentEvent?.eventName || "",
    description: currentEvent?.eventDescription || "",
    medium: currentEvent?.eventMedium || "",
    location: currentEvent?.eventLocation || "",
    address: currentEvent?.eventAddress || "",
    link: currentEvent?.eventLink || "",
    banner: currentEvent?.eventBanner || "",
    thumbnail: currentEvent?.eventThumbnail || "",
  });

  const handleDetailsChange = (e: any) => {
    setEventDetails((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleMedium = (medium: string) => {
    setEventDetails((prevState) => ({ ...prevState, medium: medium }));
  };

  const fromDate = useAppSelector(getEventsFromDateSelector);
  const toDate = useAppSelector(getEventsToDateSelector);

  useEffect(() => {
    setEventDetails({
      name: currentEvent?.eventName || "",
      description: currentEvent?.eventDescription || "",
      medium: currentEvent?.eventMedium || "",
      location: currentEvent?.eventLocation || "",
      address: currentEvent?.eventAddress || "",
      link: currentEvent?.eventLink || "",
      banner: currentEvent?.eventBanner || "",
      thumbnail: currentEvent?.eventThumbnail || "",
    });
    dispatch(setEventFromDate(currentEvent?.eventStartDate));
    dispatch(setEventToDate(currentEvent?.eventEndDate));
  }, [dispatch, currentEvent]);

  const hiveUuid = useAppSelector(getHiveUuid);

  const isLoading = useAppSelector(getEventIsLoadingSelector);

  const bannerUrl = useAppSelector(getBannerFileUrlSelector);
  const fileUrl = useAppSelector(getLogoFileUrlSelector);

  const [isPaid, setIsPaid] = useState(false);
  const [eventAmount, setEventAmount] = useState(0);

  const handleEdit = () => {
    dispatch(
      editEvent({
        eventLocation: eventDetails.location,
        eventName: eventDetails.name,
        eventDescription: eventDetails.description,
        eventStartDate:
          fromDate === currentEvent?.eventStartDate ? null : fromDate,
        eventEndDate: toDate === currentEvent?.eventEndDate ? null : toDate,
        eventAddress: eventDetails.address,
        organizationUuid: hiveUuid,
        eventMedium: eventDetails.medium,
        eventUuid: currentEvent?.eventUuid || "",
        eventBanner: !!bannerUrl ? bannerUrl : null,
        eventThumbnail: !!fileUrl ? fileUrl : null,
        formDetails: attendeeFormDetails,
        isPaidEvent: isPaid && eventAmount > 0,
        eventAmount: eventAmount,
        eventCurrency: currency || "",
      })
    );
  };

  const uploadedFile = useAppSelector(uploadFileSelector);

  const isUploading = useAppSelector(isUploadingSelector);

  const [changeIntro, setChangeIntro] = useState(false);

  const handleChangeIntro = () => {
    setChangeIntro((prevState) => !prevState);
    dispatch(setVideoFile(null));
  };

  useEffect(() => {
    dispatch(setBannerUrlChanged(false));
    dispatch(setUrlChanged(false));
    dispatch(setBannerUploaded(false));

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [isOpen, setIsOpen] = useState(false);

  const handleDelete = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const navigate = useNavigate();

  const handleConfirmDelete = () => {
    dispatch(
      deleteEvent({
        eventUuid: currentEvent?.eventUuid || "",
        hiveUuid: hiveUuid,
      })
    );

    setCurrentEvent(undefined);
    navigate("/events");
  };

  const eventResp = useAppSelector(getEventRespSelector);

  const [attendeeFormDetails, setAttendeeFormDetails] = useState<
    AttendeeFormDetails[]
  >(eventResp?.formDetails || []);

  useEffect(() => {
    eventResp && setAttendeeFormDetails(eventResp?.formDetails);
  }, [eventResp]);

  useEffect(() => {
    setIsPaid(eventResp?.event.isPaidEvent || false);
    setEventAmount(eventResp?.event.eventAmount || 0);
  }, [currentEvent, eventResp]);

  useEffect(() => {
    console.log(isPaid);
  }, [isPaid]);

  const [currency, setCurrency] = useState(
    currentEvent?.eventCurrency || "USD"
  );

  useEffect(() => {
    setCurrency(currentEvent?.eventCurrency || "USD");
  }, [currentEvent, dispatch]);

  const handleCurrencyChange = (e: any) => {
    setCurrency(e.target.value);
  };

  return (
    <IslandLayout>
      <div className="create_event_container">
        <BackButton to={`/events/${currentEvent?.eventIdentifier}`} />
        <LineBreak />
        <div className="title_and_limit">
          <h2>Edit Event</h2>
          <div onClick={handleDelete} className="primaryBtn">
            Delete Event
          </div>
        </div>
        <div className="event_details_wrapper">
          <div className="title_and_limit">
            <h3>Event Banner</h3>
          </div>
          <ImageDropzone bannerUrl={eventDetails?.banner} file="banner" />
          <LineBreak />

          <div>
            <h4>Event Name*</h4>
            <LineBreak />
            <TextField
              className="half_width"
              id="standard-basic"
              label="Name"
              variant="outlined"
              name="name"
              onChange={handleDetailsChange}
              value={eventDetails.name}
            />
          </div>
          <div>
            <EventsDateTimePicker />
          </div>
          <div className="title_and_limit">
            <h3>Event Thumbnail</h3>
          </div>
          <ImageDropzone urlSent={eventDetails?.thumbnail} file="channel" />
          <LineBreak />
          <div>
            <h4>Location*</h4>
            <LineBreak />
            <div className="video_limit_btns medium_btns">
              <button
                onClick={() => handleMedium("online")}
                className={`${
                  eventDetails.medium === "online"
                    ? "primaryBtn"
                    : "secondaryBtn"
                }`}
              >
                Online
              </button>
              <button
                onClick={() => handleMedium("offline")}
                className={`${
                  eventDetails.medium === "offline"
                    ? "primaryBtn"
                    : "secondaryBtn"
                }`}
              >
                In-Person
              </button>
            </div>
            <LineBreak />
            <div className="flex items-center create-event-gap-location">
              <TextField
                className="half_width"
                id="standard-basic"
                label="Location"
                placeholder="Where will the event be located?"
                variant="outlined"
                multiline
                maxRows={4}
                name="location"
                onChange={handleDetailsChange}
                value={eventDetails.location}
              />
              <TextField
                className="half_width"
                id="standard-basic"
                label="Link"
                placeholder={
                  eventDetails.medium === "offline"
                    ? "Add Event Address or Google Maps Link"
                    : "Add Online Meet Link"
                }
                variant="outlined"
                multiline
                maxRows={4}
                name="address"
                onChange={handleDetailsChange}
                value={eventDetails.address}
              />
            </div>
          </div>

          <div>
            <h4>Description/Instructions</h4>
            <LineBreak />
            <TextField
              className="half_width"
              id="standard-basic"
              label="Description"
              variant="outlined"
              multiline
              maxRows={4}
              name="description"
              onChange={handleDetailsChange}
              value={eventDetails.description}
            />
          </div>

          <div className="channel_paid_container">
            <StyledEngineProvider injectFirst>
              <CssVarsProvider>
                <div className="switch_display">
                  <Switch
                    checked={isPaid}
                    onChange={(event) => {
                      setIsPaid(event.target.checked);
                      setEventAmount(0);
                    }}
                  />
                  <p>Paid</p>
                </div>
              </CssVarsProvider>
            </StyledEngineProvider>
            {isPaid && (
              <div className="channel_amount_wrapper">
                <input
                  className="outline-none"
                  placeholder="0.00"
                  value={eventAmount}
                  onChange={(e) => {
                    if (+e.target.value > 999) {
                      toastInfo("Please enter amount less than 1000");
                    }
                    /^\d*(\.\d{0,2})?$/.test(e.target.value) &&
                      (+e.target.value < 1000 || e.target.value === "") &&
                      setEventAmount(+e.target.value);
                  }}
                />
                <Select
                  // className="w_full"
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  variant="standard"
                  value={currency}
                  label="Currency"
                  name="currency"
                  className="border-none"
                  onChange={handleCurrencyChange}
                >
                  <MenuItem value="USD">USD</MenuItem>
                  <MenuItem value="AED">AED</MenuItem>
                </Select>
              </div>
            )}
          </div>

          <>
            <div className="intro_video_edit_header">
              <h3>Event Video</h3>

              <p onClick={handleChangeIntro} className="link text-sm">
                {`${changeIntro ? "Cancel" : "Change Video"}`}
              </p>
            </div>

            {!!!currentEvent?.eventVideo ? (
              <>
                {uploadedFile ? (
                  <div className="intro_video_edit_wrapper">
                    <ReactPlayer
                      width="270px"
                      height="480px"
                      url={URL.createObjectURL(uploadedFile)}
                      controls
                      style={{
                        overflow: "hidden",
                        background: "black",
                      }}
                    />
                  </div>
                ) : (
                  <FileDrop uploadWhenFinished={true} />
                )}
              </>
            ) : (
              <>
                {changeIntro ? (
                  <>
                    {uploadedFile ? (
                      <div className="intro_video_edit_wrapper">
                        <ReactPlayer
                          width="270px"
                          height="480px"
                          url={URL.createObjectURL(uploadedFile)}
                          controls
                          style={{
                            overflow: "hidden",
                            background: "black",
                          }}
                        />
                      </div>
                    ) : (
                      <FileDrop uploadWhenFinished={true} />
                    )}
                  </>
                ) : (
                  <div className="intro_video_edit_wrapper">
                    <ReactPlayer
                      width="270px"
                      height="480px"
                      url={currentEvent?.eventVideo}
                      controls
                      style={{
                        overflow: "hidden",
                        background: "black",
                      }}
                    />
                  </div>
                )}
              </>
            )}
          </>
          <AttendeeFormEdit
            attendeeFormDetails={attendeeFormDetails}
            setAttendeeFormDetails={setAttendeeFormDetails}
          />
        </div>
        {!!eventDetails.name &&
        !!eventDetails.location &&
        !!eventDetails.medium &&
        !!fromDate &&
        !!toDate ? (
          <>
            {isLoading ? (
              <div className="primaryBtn nextBtn_event">
                <CircularProgress size={20} color="inherit" />
              </div>
            ) : (
              <div onClick={handleEdit} className="primaryBtn nextBtn_event">
                Save
              </div>
            )}
          </>
        ) : (
          <div className="disabledBtn nextBtn_event">Save</div>
        )}
      </div>
      {isOpen && (
        <ConfirmationModal
          isOpen={isOpen}
          onReject={handleClose}
          onConfirm={handleConfirmDelete}
          headline={`Delete ${currentEvent?.eventName}`}
          description={`Are you sure you want to delete ${currentEvent?.eventName} event?`}
          confirmMessage="Yes"
          rejectMessage="No"
        />
      )}
    </IslandLayout>
  );
};

export default EditEvent;
