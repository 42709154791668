import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "api";
import {
  EditChannelRequest,
  EditVideoRequest,
} from "api/models/Channel/editChannel";
import { RootState } from "store";
import { getHiveMembers } from "../Hive/hivePage";
import { getAnalyticsChannelMetrics } from "../Analytics/analyticsActions";
import { getVideoList } from "../Videos/videosActions";
import { UploadContentItem } from "api/models/Videos/uploadContent";

export const getChannelDetails = createAsyncThunk(
  "channelDetails/getChannelDetails",
  async (body: string, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await api.channelPage.getChannelDetails(body);
      return data.data;
    } catch (error) {
      return rejectWithValue("Something went wrong");
    }
  }
);

export const editChannelDetails = createAsyncThunk(
  "channelDetails/editChannelDetails",
  async (body: EditChannelRequest, { rejectWithValue, dispatch, getState }) => {
    try {
      const { data } = await api.channelPage.editChannelDetails(body);

      // if (data) {
      //   const { channels, child } = getState() as RootState;

      //   dispatch(
      //     getVideoList({
      //       channelUuid: channels.activeChannelUuid || "",
      //       page: channels.currentVideosPage,
      //       noOfRecords: 12,
      //       status:
      //         child.videoTabs[channels.activeVideoTab].componentName ===
      //         "Videos"
      //           ? "Ready"
      //           : child.videoTabs[channels.activeVideoTab].componentName,
      //     })
      //   );
      // }

      return data.data;
    } catch (error) {
      return rejectWithValue("Something went wrong");
    }
  }
);

export const editVideoDetails = createAsyncThunk(
  "channelDetails/editVideoDetails",
  async (body: EditVideoRequest, { rejectWithValue }) => {
    try {
      const { data } = await api.channelPage.editVideoDetails(body);
      return data.data;
    } catch (error) {
      return rejectWithValue("Something went wrong");
    }
  }
);

export const changeChannelStatus = createAsyncThunk(
  "channelDetails/changeChannelStatus",
  async (
    body: {
      channelId: number;
      channelStatus: string;
      comments: string;
      status: string;
    },
    { rejectWithValue, dispatch, getState }
  ) => {
    try {
      const { data } = await api.channelPage.changeChannelStatus(
        {
          channelId: body.channelId,
          channelStatus: body.channelStatus,
          comments: body.comments,
        },
        body.status
      );

      const { hive } = getState() as RootState;

      dispatch(
        getAllStatusChannels({
          organizationUuid: hive.hiveUuid,
          status: body.channelStatus === "APPROVE" ? "ARCHIVE" : "APPROVED",
        })
      );

      return data.data;
    } catch (error) {
      return rejectWithValue("Something went wrong");
    }
  }
);

export const getAllStatusChannels = createAsyncThunk(
  "channelDetails/getAllStatusChannels",
  async (
    body: {
      organizationUuid: string;
      status: string;
    },
    { rejectWithValue }
  ) => {
    try {
      const { data } = await api.channelPage.getAllStatusChannels(body);
      return data.data;
    } catch (error) {
      return rejectWithValue("Something went wrong");
    }
  }
);

export const removeChannelMember = createAsyncThunk(
  "hiveMembers/removeChannelMember",
  async (
    body: {
      userId: number;
      channelId: number;
    },
    { rejectWithValue, dispatch, getState }
  ) => {
    try {
      const { data } = await api.hivePage.removeChannelMember(body);

      const { hiveSettings, channels, hive } = getState() as RootState;

      dispatch(
        getHiveMembers({
          hiveId: hive.hiveDetails?.communityId || 2,
          channelUuid: channels.activeChannelUuid || "",
          pageNo: hiveSettings.hiveMembersPageNo,
          contentLimit: 15,
        })
      );
      return data.data;
    } catch (error) {
      return rejectWithValue("Something went wrong");
    }
  }
);
