import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import groupBy from "lodash/groupBy";
import orderBy from "lodash/orderBy";
import parseISO from "date-fns/parseISO";
import format from "date-fns/format";

import { parseUrlToFileName } from "utils";
import { ChannelContentsDataResponse } from "api/models/Content/content";
import {
  getChannelContents,
  getSearchContents,
  getSearchContentsPaggination,
  getVideoCommentViewerMessages,
  sendViewerTextMessages,
  getVideoRoomsByOwner,
  getVideoMessagesByOwner,
  sendMessagesByOwner,
  sendCommentAttachmentFileByOwner,
  sendCommentVoiceByOwner,
  sendCommentAttachmentFileByViewer,
  sendCommentVoiceByViewer,
  checkOwnership,
} from "store/async-actions/Content/content";
import { createTypedSelector } from "store/utils";
import {
  VideoComment,
  CommentsRoomResponse,
  GetOwnerVideoRoomsResponse,
} from "api/models/Chat/chat";
import { ChatItem, Message } from "../Chat/chat";

interface InitialState {
  content: ChannelContentsDataResponse[];
  searchResults: ChannelContentsDataResponse[];
  isLoadingContent: boolean;
  activeContent: ChannelContentsDataResponse;
  activeVideoId: string;
  comments: VideoComment[];
  commentingPersonList: GetOwnerVideoRoomsResponse[];
  isLoadingComments: boolean;
  isLoadingCommentsAttachemnts: boolean;
  isSuperAdmin: boolean;
}

const initialState: InitialState = {
  content: [],
  searchResults: [],
  activeContent: {} as ChannelContentsDataResponse,
  isLoadingContent: false,
  activeVideoId: "",
  comments: [],
  commentingPersonList: [],
  isLoadingComments: false,
  isLoadingCommentsAttachemnts: false,
  isSuperAdmin: false,
};

const contentSlice = createSlice({
  name: "content",
  initialState,
  reducers: {
    setFoundContent(state, action: PayloadAction<ChannelContentsDataResponse>) {
      state.content = [action.payload];
    },
    resetContent(state) {
      state.content = [];
    },
    setActiveVideoId(state, actions) {
      state.activeVideoId = actions.payload;
    },
    clearComments(state) {
      state.comments = initialState.comments;
    },
    clearCommentingPersonList(state) {
      state.commentingPersonList = initialState.commentingPersonList;
    },
  },
  extraReducers: {
    // getChannelContents
    [getChannelContents.pending.type]: (state) => {
      state.isLoadingContent = true;
    },
    [getChannelContents.fulfilled.type]: (
      state,
      actions: PayloadAction<ChannelContentsDataResponse[]>
    ) => {
      state.isLoadingContent = false;
      state.content = actions.payload;
    },
    [getChannelContents.rejected.type]: (state) => {
      state.isLoadingContent = false;
    },

    // checkOwnership
    [checkOwnership.pending.type]: (state) => {
      state.isLoadingContent = true;
    },
    [checkOwnership.fulfilled.type]: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.isLoadingContent = false;
      state.isSuperAdmin = action.payload;
    },
    [checkOwnership.rejected.type]: (state) => {
      state.isLoadingContent = false;
    },

    // getSearchContents
    [getSearchContents.pending.type]: (state) => {
      state.isLoadingContent = true;
    },
    [getSearchContents.fulfilled.type]: (
      state,
      actions: PayloadAction<ChannelContentsDataResponse[]>
    ) => {
      state.isLoadingContent = false;
      state.searchResults = actions.payload;
    },
    [getSearchContents.rejected.type]: (state) => {
      state.isLoadingContent = false;
    },

    /// getSearchContentsPaggination
    [getSearchContentsPaggination.pending.type]: (state) => {
      state.isLoadingContent = true;
    },
    [getSearchContentsPaggination.fulfilled.type]: (
      state,
      actions: PayloadAction<ChannelContentsDataResponse[]>
    ) => {
      state.isLoadingContent = false;
      state.searchResults = actions.payload;
    },
    [getSearchContentsPaggination.rejected.type]: (state) => {
      state.isLoadingContent = false;
    },

    //// GET COMMENTS
    ///getVideoCommentViewerMessages
    [getVideoCommentViewerMessages.pending.type]: (state) => {
      state.isLoadingComments = true;
    },
    [getVideoCommentViewerMessages.fulfilled.type]: (
      state,
      action: PayloadAction<CommentsRoomResponse>
    ) => {
      state.comments = action.payload.videoComments;
      state.isLoadingComments = false;
    },
    [getVideoCommentViewerMessages.rejected.type]: (state) => {
      state.isLoadingComments = false;
    },

    // sendViewerTextMessages
    [sendViewerTextMessages.pending.type]: (state) => {
      state.isLoadingComments = true;
    },
    [sendViewerTextMessages.fulfilled.type]: (
      state,
      action: PayloadAction<VideoComment>
    ) => {
      state.comments = [...state.comments, action.payload];
      state.isLoadingComments = false;
    },
    [sendViewerTextMessages.rejected.type]: (state) => {
      state.isLoadingComments = false;
    },

    //getVideoRoomsByOwner
    [getVideoRoomsByOwner.pending.type]: (state) => {
      state.isLoadingComments = true;
    },
    [getVideoRoomsByOwner.fulfilled.type]: (
      state,
      action: PayloadAction<GetOwnerVideoRoomsResponse[]>
    ) => {
      state.commentingPersonList = action.payload;
      state.isLoadingComments = false;
    },
    [getVideoRoomsByOwner.rejected.type]: (state) => {
      state.isLoadingComments = false;
    },

    ///getVideoMessagesByOwner
    [getVideoMessagesByOwner.pending.type]: (state) => {
      state.isLoadingComments = true;
    },
    [getVideoMessagesByOwner.fulfilled.type]: (
      state,
      action: PayloadAction<VideoComment[]>
    ) => {
      state.comments = action.payload;
      state.isLoadingComments = false;
    },
    [getVideoMessagesByOwner.rejected.type]: (state) => {
      state.isLoadingComments = false;
    },

    //sendMessagesByOwner
    [sendMessagesByOwner.pending.type]: (state) => {
      state.isLoadingComments = true;
    },
    [sendMessagesByOwner.fulfilled.type]: (
      state,
      action: PayloadAction<VideoComment>
    ) => {
      state.comments = [...state.comments, action.payload];
      state.isLoadingComments = false;
    },
    [sendMessagesByOwner.rejected.type]: (state) => {
      state.isLoadingComments = false;
    },

    ////sendCommentAttachmentFileByOwner
    [sendCommentAttachmentFileByOwner.pending.type]: (state) => {
      state.isLoadingCommentsAttachemnts = true;
    },
    [sendCommentAttachmentFileByOwner.fulfilled.type]: (
      state,
      action: PayloadAction<VideoComment>
    ) => {
      state.comments = [...state.comments, action.payload];
      state.isLoadingCommentsAttachemnts = false;
    },
    [sendCommentAttachmentFileByOwner.rejected.type]: (state) => {
      state.isLoadingCommentsAttachemnts = false;
    },

    /// sendCommentVoiceByOwner

    [sendCommentVoiceByOwner.pending.type]: (state) => {
      state.isLoadingCommentsAttachemnts = true;
    },
    [sendCommentVoiceByOwner.fulfilled.type]: (
      state,
      action: PayloadAction<VideoComment>
    ) => {
      state.comments = [...state.comments, action.payload];
      state.isLoadingCommentsAttachemnts = false;
    },
    [sendCommentVoiceByOwner.rejected.type]: (state) => {
      state.isLoadingCommentsAttachemnts = false;
    },

    /// sendCommentAttachmentFileByViewer
    [sendCommentAttachmentFileByViewer.pending.type]: (state) => {
      state.isLoadingCommentsAttachemnts = true;
    },
    [sendCommentAttachmentFileByViewer.fulfilled.type]: (
      state,
      action: PayloadAction<VideoComment>
    ) => {
      state.comments = [...state.comments, action.payload];
      state.isLoadingCommentsAttachemnts = false;
    },
    [sendCommentAttachmentFileByViewer.rejected.type]: (state) => {
      state.isLoadingCommentsAttachemnts = false;
    },

    ///sendCommentVoiceByViewer
    [sendCommentVoiceByViewer.pending.type]: (state) => {
      state.isLoadingCommentsAttachemnts = true;
    },
    [sendCommentVoiceByViewer.fulfilled.type]: (
      state,
      action: PayloadAction<VideoComment>
    ) => {
      state.comments = [...state.comments, action.payload];
      state.isLoadingCommentsAttachemnts = false;
    },
    [sendCommentVoiceByViewer.rejected.type]: (state) => {
      state.isLoadingCommentsAttachemnts = false;
    },
  },
});

export const getIsLoadingSendCommentsAttachmentSelector = createTypedSelector(
  (state) => state.content.isLoadingCommentsAttachemnts
);

export const getIsLoadingCommentsSelector = createTypedSelector(
  (state) => state.content.isLoadingComments
);

export const getIsLoadingContentSelector = createTypedSelector(
  (state) => state.content.isLoadingContent
);

export const getChannelContentsSelector = createTypedSelector(
  (state) => state.content.content
);

export const getChannelActiveContentSelector = createTypedSelector(
  (state) => state.content?.activeContent
);

export const getChannelSearchResultsSelector = createTypedSelector(
  (state) => state.content.searchResults
);

export const getActiveVideoSelector = createTypedSelector((state) => {
  return state.content.content?.find(({ id }) => {
    return id === state.content.activeVideoId;
  });
});

export const getActiveContentIdSelector = createTypedSelector(
  (state) => state.content.activeVideoId
);

export const getCommentsSelector = createTypedSelector(
  (state) => state.content.comments
);

export const getCommentDataRequestSelector = createTypedSelector((state) => {
  const activeVideo = state.content.content.find(
    (video) => video.id === state.content.activeVideoId
  );

  return {
    organizationId: state.hive.hiveDetails?.communityId,
    channelId: state.channels.activeChannelId,
    videoId:
      state.videoList.currentVideo?.videoId ||
      state.feed.currentVideo?.videoId ||
      0,
  };
});

export const getIsOwnerContentSelector = createTypedSelector((state) => {
  const activeVideo = state.videoList.currentVideo;

  return (
    Number(activeVideo?.userId) === state.profile.userId ||
    state.content.isSuperAdmin
  );
});

export const getChatListCommentsSelector = createTypedSelector<ChatItem[]>(
  (state) => {
    // const currentUserId = 1324;
    const currentUserId = state.profile.userId;

    const grouped = groupBy(
      orderBy(state.content.comments, ["createdAt"], ["desc"]),
      (item) => {
        return format(parseISO(item.createdAt), "MMM d, yyyy");
      }
    );

    return Object.entries(grouped).reduce<ChatItem[]>((res, [key, values]) => {
      res.push({
        date: key,
        messages: orderBy(
          values.map<Omit<Message, "userMentions">>((item) => ({
            time: item.createdAt,
            formattedTime: format(parseISO(item.createdAt), "HH:mm"),
            isOwner: currentUserId === item?.userId,
            message: item.message || "",
            senderName: item?.userName || "",
            id: item._id,
            type: item?.type,
            attachmentUrl: item?.attachmentUrl,
            thumbnailUrl: item?.attachmentUrl || "",
            videoUrl: "",
          })),
          ["time"],
          ["asc"]
        ),
      });
      return res;
    }, []);
  }
);

interface GetOwnerCommentsRooms {
  logoSrc: string;
  name: string;
  lastMessage: string;
  messageType: MessageType;
  lastMessageDate: string;
  roomId: string;
  readByRecipient: boolean;
  attachmentExt: string;
}

export const getOwnerVideoCommentsRoomsSelector = createTypedSelector(
  (state) => {
    return state.content.commentingPersonList.reduce<GetOwnerCommentsRooms[]>(
      (result, { lastComment, room }) => {
        result.push({
          roomId: room?._id,
          name: room?.roomName,
          logoSrc: room?.roomLogo,
          messageType: lastComment?.type,
          lastMessage: lastComment?.message,
          lastMessageDate: lastComment?.createdAt,
          readByRecipient: !!lastComment?.readByRecipient,
          attachmentExt: parseUrlToFileName(lastComment?.attachmentUrl || "")
            ?.ext,
        });

        return result;
      },
      [] as GetOwnerCommentsRooms[]
    );
  }
);

export const {
  setFoundContent,
  resetContent,
  setActiveVideoId,
  clearComments,
  clearCommentingPersonList,
} = contentSlice.actions;

export default contentSlice.reducer;
