import BaseApi from "api/base";
import { FeedModel, FeedRequestModel } from "api/models/Feed/Feed";
import { AxiosInstance, AxiosPromise } from "axios";

import { BaseResponse } from "../../models/base";
import {
  FCMTokenReq,
  PaginationModel,
} from "api/models/Notifications/notifications";
import { LocationData } from "api/models/Maps/maps";

export class MapsApi extends BaseApi {
  updateMaps(data: LocationData[]): AxiosPromise<BaseResponse<boolean>> {
    return this.request({
      url: `${this.url}/hive/save/ecotec/details`,
      method: "POST",
      data,
    });
  }

  getMaps(): AxiosPromise<BaseResponse<LocationData[]>> {
    return this.request({
      url: `${this.url}/noAuth/get/ecotec/details`,
      method: "POST",
    });
  }
}

export default function mapsApi(request: AxiosInstance) {
  return new MapsApi({
    request,
    url: `/webApp`,
  });
}
