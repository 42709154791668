import { StoryItemModel } from "api/models/Story/story";
import LineBreak from "components/LineBreak";
import LongText from "components/LongText/LongText";
import {
  CloseBlackSmSVG,
  NoOfViewsSVG,
  CopyLinkSVG,
  DeleteStorySVG,
  HappySVG,
  NeuralSVG,
  SadSVG,
  WhatsappSVG,
  LinkedInSVG,
  TwitterSVG,
} from "components/SVG/SVG";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { toastInfo } from "hooks/useToastify";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  getStoryReactions,
  getStoryViews,
} from "store/async-actions/Analytics/analyticsActions";
import {
  deleteStory,
  getStoryItem,
  getStoryLocation,
  getStorySocialCount,
} from "store/async-actions/Story/storyActions";
import {
  getStoryViewsSelector,
  getTotalReactionSelector,
} from "store/reducers/Analytics/analytics";
import {
  getStoryLocationsSelector,
  getStorySocialCountSelector,
  getUnknownCountSelector,
  setStoryUuid,
} from "store/reducers/Story/story";

interface Props {
  setKebabClicked: Dispatch<SetStateAction<string>>;
  story: StoryItemModel;
  kebabClicked: string;
}

const StoryOptions = ({ setKebabClicked, story, kebabClicked }: Props) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(
      getStoryViews({
        storyUuid: story.storyUuid,
      })
    );

    dispatch(
      getStoryReactions({
        storyUuid: story.storyUuid,
      })
    );

    dispatch(
      getStoryLocation({
        storyUuid: story.storyUuid,
      })
    );

    dispatch(
      getStorySocialCount({
        storyUuid: story.storyUuid,
      })
    );
  }, [dispatch, story]);

  const storyLocations = useAppSelector(getStoryLocationsSelector);
  const totalReactions = useAppSelector(getTotalReactionSelector);

  const handleDelete = () => {
    dispatch(
      deleteStory({
        storyUuid: story.storyUuid,
      })
    );
    setKebabClicked("");
  };

  const storyViews = useAppSelector(getStoryViewsSelector);

  const socialCount = useAppSelector(getStorySocialCountSelector);

  const handleEdit = () => {
    dispatch(getStoryItem({ storyUuid: story.storyUuid }));
    dispatch(setStoryUuid(story.storyUuid));
    navigate("edit", { state: { from: "/story" } });
  };

  const handleCopy = (text: string | null, social?: string) => {
    navigator.clipboard
      .writeText(
        `${process.env.REACT_APP_STORIES_URL}${text}${
          social
            ? `?${
                social === "linkedin"
                  ? "LinkedIn"
                  : social === "whatsapp"
                  ? "Whatsapp"
                  : "Twitter"
              }`
            : ""
        }`
      )
      .then(() => {
        if (!!text) {
          toastInfo("Copied link to clipboard");
        } else {
          toastInfo(
            "This story isnt available at the moment, please try another one"
          );
        }
      })
      .catch((error) => {
        console.error("Error copying text: ", error);
      });
  };

  const [scrollUp, setScrollUp] = useState(false);

  const handleScroll = () => {
    setScrollUp(true);
  };

  const unknownCount = useAppSelector(getUnknownCountSelector);

  return (
    <div
      onScroll={handleScroll}
      className={`slide-up ${
        scrollUp ? "story_info_pop_up_scroll" : "story_info_pop_up"
      }`}
    >
      <div
        onClick={() => setKebabClicked("")}
        className="story_pop_up_close pointer"
      >
        <CloseBlackSmSVG />
      </div>
      <div className="story_pop_up_views">
        <NoOfViewsSVG />
        <p>{storyViews?.count}</p>
      </div>
      <div className="story_content_actions">
        <div className="story_content_copy_link">
          <div className="flex_row_align gap_5">
            <div
              onClick={(e) => handleCopy(story.storyUuid, "linkedin")}
              className="pointer"
            >
              <LinkedInSVG />
            </div>
            <div
              onClick={(e) => handleCopy(story.storyUuid, "whatsapp")}
              className="pointer"
            >
              <WhatsappSVG />
            </div>
            <div
              onClick={(e) => handleCopy(story.storyUuid, "twitter")}
              className="pointer"
            >
              <TwitterSVG />
            </div>
            <div
              className="pointer"
              onClick={(e) => handleCopy(story.storyUuid)}
            >
              <CopyLinkSVG />
            </div>
          </div>
          <div className="pointer" onClick={handleDelete}>
            <DeleteStorySVG />
          </div>
        </div>
        <div className="w_full story_options_spacing">
          <hr></hr>
        </div>
        <p onClick={handleEdit} className="pointer">
          Edit Story
        </p>
        <div className="w_full story_options_spacing">
          <hr></hr>
        </div>
        <div className="story_reactions_wrapper">
          <div className="story_reaction_wrapper text_xs">
            <HappySVG />
            <p>{totalReactions.happy}</p>
          </div>
          <div className="story_reaction_wrapper text_xs">
            <NeuralSVG />
            <p>{totalReactions.neutral}</p>
          </div>
          <div className="story_reaction_wrapper text_xs">
            <SadSVG />
            <p>{totalReactions.sad}</p>
          </div>
        </div>
        <div className="w_full story_options_spacing">
          <hr></hr>
        </div>
        <div className="story_metrics_container">
          <h3 className="">Statistics</h3>
          <div className="story_metrics_wrapper">
            <p>Views</p>
            <p>{storyViews?.count}</p>
          </div>

          <div className="story_metrics_wrapper">
            <p>Reactions</p>
            <p>{totalReactions.total}</p>
          </div>
          <div className="story_metrics_wrapper">
            <p>Interactions</p>
            <p>{storyViews?.countInteractions}</p>
          </div>
          {!!storyLocations && storyLocations.length > 0 && (
            <div className="w_full story_options_spacing">
              <hr></hr>
            </div>
          )}
          {!!storyLocations && storyLocations.length > 0 && (
            <h3 className="">Location</h3>
          )}
          {!!storyLocations &&
            storyLocations.length > 0 &&
            storyLocations.map((location) => {
              return (
                <div className="story_metrics_wrapper">
                  <LongText
                    title={
                      !!location.location && location.location.length > 1
                        ? location.location[0].toUpperCase() +
                          location.location.slice(1).toLowerCase()
                        : "Unknown"
                    }
                    cutoff={18}
                  />

                  <p>{location.locationCount}</p>
                </div>
              );
            })}
          {!!socialCount && socialCount.length > 0 && (
            <div className="w_full story_options_spacing">
              <hr></hr>
            </div>
          )}
          {!!socialCount && socialCount.length > 0 && (
            <h3 className="">Origin</h3>
          )}
          {!!socialCount &&
            socialCount.length > 0 &&
            socialCount.map((data, idx) => {
              if (data.origin !== "unknown") {
                return (
                  <div key={idx} className="story_metrics_wrapper">
                    <p>{data.origin}</p>
                    <p>{data.originCount}</p>
                  </div>
                );
              } else {
                return (
                  <div key={idx} className="story_metrics_wrapper">
                    <p>Unknown</p>
                    <p>{unknownCount}</p>
                  </div>
                );
              }
            })}
        </div>
      </div>
    </div>
  );
};

export default StoryOptions;
