import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { createTypedSelector } from "store/utils";
import { FeedItem, FeedModel } from "api/models/Feed/Feed";
import { getFeedVideos } from "store/async-actions/Feed/feedActions";
import { VideoListItem } from "api/models/Videos/videoList";

const initialState: {
  feedVideosResp: VideoListItem[] | string;
  feedVideos: VideoListItem[];
  isFetching: boolean;
  totalPages: number;
  currentVideo: VideoListItem | undefined;
  currentVideoIndex: number;
} = {
  feedVideosResp: [],
  feedVideos: [],
  isFetching: false,
  totalPages: 0,
  currentVideo: undefined,
  currentVideoIndex: 0,
};

const feedSlice = createSlice({
  name: "feed",
  initialState,
  reducers: {
    setFeedIsFetching: (state, action: PayloadAction<boolean>) => {
      state.isFetching = action.payload;
    },
    setFeedCurrentVideo: (state, action: PayloadAction<VideoListItem>) => {
      state.currentVideo = action.payload;
      !!state.feedVideos &&
        state.feedVideos.map((video, idx) => {
          if (video.videoUuid === state.currentVideo?.videoUuid) {
            state.currentVideoIndex = idx;
          }
        });
    },
    setFeedNextVideo: (state) => {
      // for (var i = 0; i < state.videoList.length; i++) {
      //   if (state.videoList[i].videoUuid === state.currentVideo?.videoUuid) {
      //     state.currentVideo = state.videoList[i + 1];
      //     break;
      //   }
      // }
      state.currentVideo = state.feedVideos[state.currentVideoIndex + 1];
      state.currentVideoIndex += 1;
    },
    setFeedPrevVideo: (state) => {
      state.currentVideo = state.feedVideos[state.currentVideoIndex - 1];
      state.currentVideoIndex -= 1;
    },
  },
  extraReducers: {
    [getFeedVideos.pending.type]: (state) => {
      state.isFetching = true;
    },
    [getFeedVideos.fulfilled.type]: (
      state,
      action: PayloadAction<FeedModel>
    ) => {
      state.feedVideosResp = action.payload.videos;
      state.totalPages = action.payload.totalCount;
      !!action.payload &&
        action.payload.videos[0] &&
        action.payload.videos[0].videoUuid &&
        action.payload.videos.forEach((video) => {
          if (
            !state.feedVideos.some(
              (existingVideo) => existingVideo.videoUuid === video.videoUuid
            )
          ) {
            video.sourceUrl && state.feedVideos.push(video);
          }
        });
      state.isFetching = false;
    },
    [getFeedVideos.rejected.type]: (state) => {
      state.isFetching = false;
    },
  },
});

export const {
  setFeedIsFetching,
  setFeedCurrentVideo,
  setFeedNextVideo,
  setFeedPrevVideo,
} = feedSlice.actions;

export const feedVideosSelector = createTypedSelector(
  (state) => state.feed.feedVideos
);

export const feedVideosRespSelector = createTypedSelector(
  (state) => state.feed.feedVideosResp
);

export const feedFetchingSelector = createTypedSelector(
  (state) => state.feed.isFetching
);

export const totalFeedPagesSelector = createTypedSelector(
  (state) => state.feed.totalPages
);

export const currentFeedVideoSelector = createTypedSelector(
  (state) => state.feed.currentVideo
);

export const getCurrentFeedVideoIndex = createTypedSelector(
  (state) => state.feed.currentVideoIndex
);

export default feedSlice.reducer;
