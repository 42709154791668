import PageLayout from "components/PageLayout";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import React, { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { store } from "store";
import { getChannelDetails } from "store/async-actions/Channels/channels";
import {
  getChildComponents,
  getHiveActivities,
} from "store/async-actions/Hive/hivePage";
import {
  getActiveChannelUuidSelector,
  setActiveChannelUuid,
} from "store/reducers/ChannelDetails/channelList";
import { getHiveSelector } from "store/reducers/HiveDetails/hiveDetails";

const ChannelContainer = () => {
  const hiveDetails = useAppSelector(getHiveSelector);
  const dispatch = useAppDispatch();
  const location = useLocation();
  const url = location.pathname;
  const secondSlashIndex = url.indexOf("/", url.indexOf("/") + 1);
  const thirdSlashIndex = url.indexOf("/", secondSlashIndex + 1);
  const channelUuidFromUrl = url.substring(
    secondSlashIndex + 1,
    thirdSlashIndex !== -1 ? thirdSlashIndex : url.length
  );

  const activeChannel = useAppSelector(getActiveChannelUuidSelector);

  useEffect(() => {
    activeChannel &&
      hiveDetails &&
      dispatch(
        getHiveActivities({
          communityId: hiveDetails?.communityId!,
          pageNo: 0,
          contentLimit: 30,
          channelUuid: activeChannel,
        })
      );
  }, [hiveDetails, activeChannel]);

  useEffect(() => {
    localStorage.setItem("path", "");
    if (channelUuidFromUrl) {
      localStorage.setItem("path", location.pathname);
      dispatch(setActiveChannelUuid(channelUuidFromUrl));
      dispatch(getChannelDetails(channelUuidFromUrl));
      dispatch(
        getChildComponents({
          organizationId: hiveDetails?.communityId!,
          parentComponentCode: "Channel",
          channelUuid: channelUuidFromUrl,
          isMemberView: store.getState().profile.isMemberView,
        })
      );
    }
    // if (!!!channelUuid) navigate("/home");
  }, [channelUuidFromUrl, activeChannel, dispatch]);

  return (
    <>
      <Outlet />
    </>
  );
};

export default ChannelContainer;
