import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { BaseResponse } from "api/models/base";
import { CartModel, RazorPayOrder } from "api/models/Channel/cartModel";
import { toastError } from "hooks/useToastify";
import { getChannelDetails } from "store/async-actions/Channels/channels";
import {
  addToCart,
  chargeForPayment,
  createRazorPayOrder,
  getCartList,
  razorPayVerifyPayment,
} from "store/async-actions/Channels/payment";

import { createTypedSelector } from "store/utils";

const initialState: {
  isFetching: boolean;
  CartListResp: CartModel[];
  createOrderResp: RazorPayOrder | undefined;
  PaymentCharged: boolean;
} = {
  isFetching: false,
  CartListResp: [],
  createOrderResp: undefined,
  PaymentCharged: false,
};

const paymentsSlice = createSlice({
  name: "payments",
  initialState,
  reducers: {
    setPaymentCharged(state, action: PayloadAction<boolean>) {
      state.PaymentCharged = action.payload;
    },
  },
  extraReducers: {
    //Add to cart
    [addToCart.pending.type]: (state) => {
      state.isFetching = true;
    },
    [addToCart.fulfilled.type]: (state, action: PayloadAction<boolean>) => {
      state.isFetching = false;
    },
    [addToCart.rejected.type]: (state) => {
      state.isFetching = false;
    },
    //Get cart List
    [getCartList.pending.type]: (state) => {
      state.isFetching = true;
    },
    [getCartList.fulfilled.type]: (
      state,
      action: PayloadAction<CartModel[]>
    ) => {
      state.CartListResp = action.payload;
      state.isFetching = false;
    },
    [getCartList.rejected.type]: (state) => {
      state.isFetching = false;
    },
    //Create RazorPay order
    [createRazorPayOrder.pending.type]: (state) => {
      state.isFetching = true;
    },
    [createRazorPayOrder.fulfilled.type]: (
      state,
      action: PayloadAction<RazorPayOrder>
    ) => {
      state.createOrderResp = action.payload;
      state.isFetching = false;
    },
    [createRazorPayOrder.rejected.type]: (state) => {
      state.isFetching = false;
    },
    //Verify RazorPay order
    [razorPayVerifyPayment.pending.type]: (state) => {
      state.isFetching = true;
      state.PaymentCharged = false;
    },
    [razorPayVerifyPayment.fulfilled.type]: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.PaymentCharged = action.payload;

      state.isFetching = false;
    },
    [razorPayVerifyPayment.rejected.type]: (state) => {
      state.PaymentCharged = false;
      state.isFetching = false;
    },
    //Stripe Charge Payment
    [chargeForPayment.pending.type]: (state) => {
      state.isFetching = true;
      state.PaymentCharged = false;
    },
    [chargeForPayment.fulfilled.type]: (
      state,
      action: PayloadAction<BaseResponse<boolean>>
    ) => {
      if (action.payload.responseInfo.httpCode === 200) {
        state.PaymentCharged = true;
      } else {
        toastError(action.payload.responseInfo.message);
      }
      state.isFetching = false;
    },
    [chargeForPayment.rejected.type]: (state) => {
      toastError("Payment Failed");
      state.PaymentCharged = false;
      state.isFetching = false;
    },
  },
});

export const { setPaymentCharged } = paymentsSlice.actions;

export const getRazorPayOrderSelector = createTypedSelector(
  (state) => state.payments.createOrderResp
);
export const cartIsLoadingSelector = createTypedSelector(
  (state) => state.payments.isFetching
);

export const getCartListSelector = createTypedSelector(
  (state) => state.payments.CartListResp
);

export const stripePaymentChargedSelector = createTypedSelector(
  (state) => state.payments.PaymentCharged
);

export default paymentsSlice.reducer;
