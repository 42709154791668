import AnalyticsCapsule from "components/AnalyticsCapsule";
import React from "react";

const ContentMetrics = () => {
  return (
    <div className="usage_data_container">
      <AnalyticsCapsule>
        <div className="headers_and_value">
          <h5>Accounts Linked</h5>
          <h2>3</h2>
        </div>
      </AnalyticsCapsule>
      <AnalyticsCapsule>
        <div className="headers_and_value">
          <h5>Videos Created</h5>
          <h2>12</h2>
        </div>
      </AnalyticsCapsule>
      <AnalyticsCapsule>
        <div className="headers_and_value">
          <h5>Videos Shared</h5>
          <h2>6</h2>
        </div>
      </AnalyticsCapsule>
    </div>
  );
};

export default ContentMetrics;
