import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "hooks/redux";
import { getChannelUsers } from "store/async-actions/Chat/chatrooms";
import {
  clearCreateChatRoom,
  getIsFetchingChatRoomsSelector,
} from "store/reducers/Chat/chatrooms";
import { getActiveChannelIdSelector } from "store/reducers/ChannelDetails/channelList";
import { CircularProgress } from "@mui/joy";
// import Loader from "components/common/Loader";

const ChatRoomCreatePage = () => {
  const dispatch = useAppDispatch();

  const channelId = useAppSelector(getActiveChannelIdSelector);

  useEffect(() => {
    if (channelId) {
      dispatch(getChannelUsers({ channelId, pageNo: 0 }));
    }

    return () => {
      dispatch(clearCreateChatRoom());
    };
  }, [dispatch, channelId]);

  return (
    <>
      <Outlet />
      <LoaderIndicator />
    </>
  );
};

const LoaderIndicator = () => {
  const isFetching = useAppSelector(getIsFetchingChatRoomsSelector);
  return isFetching ? <CircularProgress /> : null;
};

export default ChatRoomCreatePage;
