import { useAppDispatch, useAppSelector } from "hooks/redux";
import React from "react";
import {
  getProductDetails,
  getUniqueSessions,
  getUserActions,
} from "store/async-actions/Analytics/analyticsActions";
import {
  getHiveSelector,
  getHiveUuid,
} from "store/reducers/HiveDetails/hiveDetails";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import ReactTimeAgo from "react-time-ago";
import moment from "moment";
import LineBreak from "components/LineBreak";
import PageNumbers from "components/PageNumbers";
import {
  ProductDetailsItem,
  ProductDetailsModel,
} from "api/models/Analytics/sessions";
import analytics, {
  getFromDateSelector,
  getToDateSelector,
  getUserActionsSelector,
} from "store/reducers/Analytics/analytics";
import LongText from "components/LongText/LongText";

const UserActionsTable = () => {
  const dispatch = useAppDispatch();

  const userActions = useAppSelector(getUserActionsSelector);

  const hiveUuid = useAppSelector(getHiveUuid);

  const fromDate = useAppSelector(getFromDateSelector);
  const toDate = useAppSelector(getToDateSelector);

  return (
    <>
      <h4>User journey</h4>
      <LineBreak />
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>
                {" "}
                <p className="bold_heading_table">Events</p>
              </TableCell>

              <TableCell align="center">
                {" "}
                <p className="bold_heading_table">Time</p>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {userActions &&
              userActions.content &&
              userActions.content.length > 0 &&
              userActions.content.map((row, idx) => (
                <TableRow
                  //   onClick={() => handleUserActions(row)}
                  className="table_cell_hover"
                  key={idx}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {<LongText title={row.pageName} cutoff={16} /> || "-"}
                  </TableCell>
                  <TableCell align="center">
                    {
                      <ReactTimeAgo
                        date={
                          new Date(
                            moment
                              .utc(row.date)
                              .local()
                              .format("YYYY-MM-DD HH:mm:ss")
                          )
                        }
                        locale="en-GB"
                      />
                    }
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      {/* {userActions && userActions.totalPages > 1 && (
        <PageNumbers
          totalPages={userActions?.totalPages}
          initialPage={0}
          handleChange={userActions}
        />
      )} */}
    </>
  );
};

export default UserActionsTable;
