import React, { PropsWithChildren } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { Components } from "api/models/Hive/hiveComponents";
import { launchLogin } from "store/reducers/HiveDetails/hiveDetails";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import {
  isMemberViewSelector,
  setIsMemberView,
} from "store/reducers/Profile/profile";
import { InitializeApp } from "store/async-actions/initializeApp";

interface Props {
  button: Components;
}

const Buttons: React.FC<PropsWithChildren<Props>> = ({ button, children }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const isMember = useAppSelector(isMemberViewSelector);

  const handleClick = () => {
    // if(button.componentName = "")
    if (button.accessType === "superadmin") {
      dispatch(setIsMemberView(!isMember));
      InitializeApp(dispatch);
    }
    if (localStorage.getItem("isLoggedIn") === "yes") {
      navigate(button.componentRoute);
    } else {
      launchLogin();
    }
  };

  return (
    <div
      onClick={handleClick}
      className={`cursor-pointer ${button.componentType}`}
    >
      {children}
    </div>
  );
};

export default Buttons;
