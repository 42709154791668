import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "api";
import {
  AddSegmentRequest,
  CreateStoryRequest,
  EditSegmentRequest,
  EditStoryRequest,
  SegmentItemReactRequest,
  StoriesReactionRequest,
  StoriesRequest,
  StoryDayWiseReq,
  StoryOrderRequest,
} from "api/models/Story/story";
import { RootState } from "store";
import { removeVideoFile, setThumbnail } from "store/reducers/Videos/videos";

export const createStory = createAsyncThunk(
  "story/createStory",
  async (body: CreateStoryRequest, { rejectWithValue, dispatch, getState }) => {
    try {
      const { data } = await api.story.createStory(body);
      const { hive } = getState() as RootState;

      dispatch(
        addStorySegments({
          description: body.description,
          isActive: false,
          storyUrl: "",
          storyUuid: data.data.storyUuid,
          title: body.title,
          isImage: false,
          isVideo: false,
          colorCode: "#B4CEBE",
          communityUuid: hive.hiveUuid,
          contentType: "",
          order: 0,
        })
      );

      return data.data;
    } catch (error) {
      return rejectWithValue("Something went wrong");
    }
  }
);

export const getStorySegments = createAsyncThunk(
  "story/getStorySegments",
  async (
    body: {
      storyUuid: string;
    },
    { rejectWithValue }
  ) => {
    try {
      const { data } = await api.story.getSegments(body);

      return data.data;
    } catch (error) {
      return rejectWithValue("Something went wrong");
    }
  }
);

export const publishStory = createAsyncThunk(
  "story/publishStory",
  async (
    body: {
      storyUuid: string;
    },
    { rejectWithValue, dispatch, getState }
  ) => {
    try {
      const { data } = await api.story.publishStory(body);
      const { hive } = getState() as RootState;

      dispatch(
        getStories({
          organizationUuid: hive.hiveUuid,
        })
      );

      return data.data;
    } catch (error) {
      return rejectWithValue("Something went wrong");
    }
  }
);

export const deleteStory = createAsyncThunk(
  "story/deleteStory",
  async (
    body: {
      storyUuid: string;
    },
    { rejectWithValue, dispatch, getState }
  ) => {
    try {
      const { data } = await api.story.deleteStory(body);
      const { hive } = getState() as RootState;

      dispatch(
        getStories({
          organizationUuid: hive.hiveUuid,
        })
      );

      return data.data;
    } catch (error) {
      return rejectWithValue("Something went wrong");
    }
  }
);

export const getStories = createAsyncThunk(
  "story/getStories",
  async (
    body: {
      organizationUuid: string;
    },
    { rejectWithValue }
  ) => {
    try {
      const { data } = await api.story.getStories(body);
      return data.data;
    } catch (error) {
      return rejectWithValue("Something went wrong");
    }
  }
);

export const editStory = createAsyncThunk(
  "story/editStory",
  async (body: EditStoryRequest, { rejectWithValue }) => {
    try {
      const { data } = await api.story.editStory(body);
      return data.data;
    } catch (error) {
      return rejectWithValue("Something went wrong");
    }
  }
);

export const getStoryDetails = createAsyncThunk(
  "hive/getStories",
  async (body: StoriesRequest, { rejectWithValue }) => {
    try {
      const { data } = await api.story.getStoryDetails(body);
      return data.data;
    } catch (error) {
      return rejectWithValue("Something went wrong");
    }
  }
);

export const getStoryItem = createAsyncThunk(
  "hive/getStoryItem",
  async (body: StoriesRequest, { rejectWithValue }) => {
    try {
      const { data } = await api.story.getStoryItem(body);
      return data.data;
    } catch (error) {
      return rejectWithValue("Something went wrong");
    }
  }
);

export const updateStoryReaction = createAsyncThunk(
  "hive/updateStoryReaction",
  async (body: StoriesReactionRequest, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await api.story.updateStoryReaction(body);
      dispatch(
        getStorySegmentItemReact({
          storySegmentId: body.storySegmentId,
          sessionId: body.sessionId,
          storyUuid: body.storyUuid,
        })
      );
      return data.data;
    } catch (error) {
      return rejectWithValue("Something went wrong");
    }
  }
);

export const getStoryLocation = createAsyncThunk(
  "story/getStoryLocation",
  async (
    body: {
      storyUuid: string;
    },
    { rejectWithValue }
  ) => {
    try {
      const { data } = await api.story.getStoryLocation(body);
      return data.data;
    } catch (error) {
      return rejectWithValue("Something went wrong");
    }
  }
);

export const getStorySocialCount = createAsyncThunk(
  "story/getStorySocialCount",
  async (
    body: {
      storyUuid: string;
    },
    { rejectWithValue }
  ) => {
    try {
      const { data } = await api.story.getStorySocialCount(body);
      return data.data;
    } catch (error) {
      return rejectWithValue("Something went wrong");
    }
  }
);

export const getStorySegmentItemReact = createAsyncThunk(
  "story/getStorySegmentItemReact",
  async (body: SegmentItemReactRequest, { rejectWithValue }) => {
    try {
      const { data } = await api.story.getSegmentItemReact(body);
      return data.data;
    } catch (error) {
      return rejectWithValue("Something went wrong");
    }
  }
);

export const getDayWiseAnalytics = createAsyncThunk(
  "story/getDayWiseAnalytics",
  async (body: StoryDayWiseReq, { rejectWithValue }) => {
    try {
      const { data } = await api.story.getDayWiseAnalytics(body);
      return data.data;
    } catch (error) {
      return rejectWithValue("Something went wrong");
    }
  }
);

export const getSegmentLocation = createAsyncThunk(
  "story/getStoryLocation",
  async (
    body: {
      storyUuid: string;
      segmentId: number;
    },
    { rejectWithValue }
  ) => {
    try {
      const { data } = await api.story.getSegmentLocation(body);
      return data.data;
    } catch (error) {
      return rejectWithValue("Something went wrong");
    }
  }
);

export const removeSegment = createAsyncThunk(
  "story/removeSegment",
  async (
    body: {
      storyUuid: string;
      id: number;
    },
    { rejectWithValue, dispatch, getState }
  ) => {
    try {
      const { data } = await api.story.removeSegment(body);
      const { story } = getState() as RootState;

      dispatch(
        getStorySegments({
          storyUuid: story.storyUuid,
        })
      );

      return data.data;
    } catch (error) {
      return rejectWithValue("Something went wrong");
    }
  }
);

export const addStorySegments = createAsyncThunk(
  "story/addStorySegments",
  async (body: AddSegmentRequest, { rejectWithValue, dispatch, getState }) => {
    try {
      const { data } = await api.story.addSegments(body);

      const { story } = getState() as RootState;

      dispatch(
        getStorySegments({
          storyUuid: story.storyUuid,
        })
      );

      return data.data;
    } catch (error) {
      return rejectWithValue("Something went wrong");
    }
  }
);

export const updateStoryOrder = createAsyncThunk(
  "story/updateStoryOrder",
  async (body: StoryOrderRequest, { rejectWithValue, dispatch, getState }) => {
    try {
      const { data } = await api.story.updateStoryOrder(body);

      const { story } = getState() as RootState;

      dispatch(
        getStorySegments({
          storyUuid: story.storyUuid,
        })
      );

      return data.data;
    } catch (error) {
      return rejectWithValue("Something went wrong");
    }
  }
);

export const uploadStoryToS3 = createAsyncThunk(
  "videos/uploadToS3",
  async (
    body: {
      url: string;
      formData: FormData;
    },
    { rejectWithValue, dispatch, getState }
  ) => {
    try {
      const { data } = await api.videos.uploadToS3(body.formData, body.url);

      const { story } = getState() as RootState;
      dispatch(
        getStorySegments({
          storyUuid: story.storyUuid,
        })
      );

      return data.data;
    } catch (error) {
      const { story } = getState() as RootState;
      dispatch(
        getStorySegments({
          storyUuid: story.storyUuid,
        })
      );
      return rejectWithValue("Something went wrong");
    }
  }
);

export const editStorySegments = createAsyncThunk(
  "story/editStorySegments",
  async (body: EditSegmentRequest, { rejectWithValue, dispatch, getState }) => {
    try {
      const { data } = await api.story.editSegment(body);

      const { story } = getState() as RootState;

      const formData = new FormData();

      formData.append(
        "x-amz-meta-userid",
        data.data.objPreSignedResponse.body.data.fields["x-amz-meta-userid"]!
      );
      formData.append(
        "Content-Type",
        data.data.objPreSignedResponse.body.data.fields["Content-Type"]
      );
      formData.append(
        "key",
        data.data.objPreSignedResponse.body.data.fields.key!
      );
      formData.append(
        "bucket",
        data.data.objPreSignedResponse.body.data.fields.bucket!
      );
      formData.append(
        "X-Amz-Algorithm",
        data.data.objPreSignedResponse.body.data.fields["X-Amz-Algorithm"]!
      );
      formData.append(
        "X-Amz-Credential",
        data.data.objPreSignedResponse.body.data.fields["X-Amz-Credential"]!
      );
      formData.append(
        "X-Amz-Date",
        data.data.objPreSignedResponse.body.data.fields["X-Amz-Date"]!
      );
      formData.append(
        "X-Amz-Security-Token",
        data.data.objPreSignedResponse.body.data.fields["X-Amz-Security-Token"]!
      );
      formData.append(
        "X-Amz-Signature",
        data.data.objPreSignedResponse.body.data.fields["X-Amz-Signature"]!
      );
      formData.append(
        "Policy",
        data.data.objPreSignedResponse.body.data.fields.Policy!
      );
      formData.append("file", story.uploadedFile!);

      dispatch(removeVideoFile());
      dispatch(setThumbnail(""));

      dispatch(
        uploadStoryToS3({
          url: data.data.objPreSignedResponse.body.data.url,
          formData: formData,
        })
      );

      return data.data;
    } catch (error) {
      return rejectWithValue("Something went wrong");
    }
  }
);

export const editStorySegmentsBg = createAsyncThunk(
  "story/editStorySegments",
  async (body: EditSegmentRequest, { rejectWithValue, dispatch, getState }) => {
    try {
      const { data } = await api.story.editSegment(body);

      const { story } = getState() as RootState;

      dispatch(
        getStorySegments({
          storyUuid: story.storyUuid,
        })
      );

      return data.data;
    } catch (error) {
      return rejectWithValue("Something went wrong");
    }
  }
);
