import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ChildComponent, Components } from "api/models/Hive/hiveComponents";
import { WritableDraft } from "immer/dist/internal";
import { getChildComponents } from "store/async-actions/Hive/hivePage";
import { createTypedSelector } from "store/utils";

interface ChildComponentModel {
  childComponents: ChildComponent[];
  channelComponents: ChildComponent[];
  homeComponents: ChildComponent[];
  storyComponents: ChildComponent[];
  isFetching: boolean;
  videoTabs: Components[];
}

const initialState: ChildComponentModel = {
  homeComponents: [],
  channelComponents: [],
  childComponents: [],
  isFetching: false,
  videoTabs: [],
  storyComponents: [],
};

const childSlice = createSlice({
  name: "child",
  initialState,
  reducers: {},
  extraReducers: {
    [getChildComponents.pending.type]: (state) => {
      state.isFetching = true;
    },
    [getChildComponents.fulfilled.type]: (
      state,
      action: PayloadAction<ChildComponent[]>
    ) => {
      state.channelComponents = [];
      state.homeComponents = [];
      state.childComponents = [];
      state.storyComponents = [];
      state.childComponents = action.payload;
      setComponents(state);
      state.childComponents.map((comp, idx) => {
        if (comp.componentType === "button")
          comp.componentType = "secondaryBtn";
      });

      state.isFetching = false;
    },
    [getChildComponents.rejected.type]: (state) => {
      state.isFetching = false;
    },
    [getChildComponents.pending.type]: (state) => {
      // to do
    },
    [getChildComponents.rejected.type]: (state) => {
      // to do
    },
  },
});

const setComponents = (state: WritableDraft<ChildComponentModel>) => {
  state.channelComponents = [];
  state.homeComponents = [];
  state.videoTabs = [];
  state.childComponents.map((child, idx) => {
    if (child.parentComponentCode === "Channel") {
      if (child.componentType === "video_tabs") {
        state.videoTabs.push(child);
      } else {
        state.channelComponents.push(child);
      }
    } else if (child.parentComponentCode === "Stories") {
      state.storyComponents.push(child);
    } else {
      state.homeComponents.push(child);
    }
  });
};

export const getChildSelector = createTypedSelector(
  (state) => state.child.childComponents
);

export const getHomeComponents = createTypedSelector(
  (state) => state.child.homeComponents
);
export const getChannelComponents = createTypedSelector(
  (state) => state.child.channelComponents
);
export const getVideoTabs = createTypedSelector(
  (state) => state.child.videoTabs
);

export const getStoriesComponents = createTypedSelector(
  (state) => state.child.storyComponents
);

export default childSlice.reducer;
