import React from "react";
import PushPinOutlinedIcon from "@mui/icons-material/PushPinOutlined";
import IconButton from "@mui/material/IconButton";
import { Link, Params, useParams } from "react-router-dom";

import { Chatroom } from "../ChatRoomsPage";
import Text from "components/common/Text";
import Avatar from "components/common/Avatar";

import {
  pinningChatRoom,
  unpinningChatRoom,
} from "store/async-actions/Chat/chatrooms";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { profileUserIdSelector } from "store/reducers/Profile/profile";
import { ChatRoomItemModel } from "api/models/Chat/chat";
import { updateChatRoomsList } from "store/reducers/Chat/chatrooms";

interface Props {
  chatRoomItem: ChatRoomItemModel;
}

const ChatRoomItem = ({
  chatRoomItem,
}: // logo = "",
Props) => {
  const dispatch = useAppDispatch();

  const profileIdent = useAppSelector(profileUserIdSelector);

  // const handleClickPin = (e: React.MouseEvent<HTMLButtonElement>) => {
  //   e.preventDefault();
  //   //// pin/unpin chanel room
  //   if (isPinned) {
  //     dispatch(unpinningChatRoom({ chatRoomId: id, userId: profileIdent! }));
  //   } else {
  //     dispatch(pinningChatRoom({ chatRoomId: id, userId: profileIdent! }));
  //   }
  // };

  // const classNamePinned = isPinned
  //   ? "chatroom_pin_button pinned"
  //   : "chatroom_pin_button";

  const { roomId = "" } = useParams<Params<"roomId">>();

  const removeReadMessages = () => {
    dispatch(updateChatRoomsList(chatRoomItem));
  };

  return (
    <Link
      onClick={removeReadMessages}
      className={`${
        roomId === String(chatRoomItem._id)
          ? "selected_chatroom_item_container"
          : ""
      } chatroom_item_container`}
      to={String(chatRoomItem._id)}
    >
      <Avatar
        className="chat_room_logo_container"
        src={""}
        alt={`${chatRoomItem.chatRoomName} chatroom logo`}
      >
        {chatRoomItem.chatRoomName.at(0)?.toUpperCase()}
      </Avatar>
      <div className="content_container">
        <Text className="chatroom_item_title" fontWeight="w700">
          {chatRoomItem.chatRoomName}
        </Text>
        <Text className="chatroom_description" fontWeight="w500">
          {chatRoomItem.chatRoomBio}
        </Text>
      </div>
      {/* <div className="pinned_container"> */}
      {/* <Text className="chatroom_onlinecount" fontWeight="w400">
          {activeUsers.length} online
        </Text> */}
      {chatRoomItem.unreadMessageCount > 0 &&
        (chatRoomItem.unreadMessageCount > 99 ? (
          <div className="unread_wrapper">100+</div>
        ) : (
          <div className="unread_wrapper">
            {chatRoomItem.unreadMessageCount}
          </div>
        ))}

      {/* <IconButton className={classNamePinned} onClick={handleClickPin}>
          <PushPinOutlinedIcon className="button_icon" />
        </IconButton> */}
      {/* </div> */}
    </Link>
  );
};

export default ChatRoomItem;
