import { HiveContentItem } from "api/models/Hive/hiveActivities";
import { ProfileSVG } from "components/SVG/SVG";
import Headers from "components/TextStyles/components/Headers";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ReactTimeAgo from "react-time-ago";
import { getHiveActivities } from "store/async-actions/Hive/hivePage";
import { setClickedProfileId } from "store/reducers/HiveDetails/hiveActivities";
import { getHiveSelector } from "store/reducers/HiveDetails/hiveDetails";
import ActivityUserPage from "./ActivityUserDetails";
import {
  getChildSelector,
  getVideoTabs,
} from "store/reducers/ChildComponents/childComponents";
import { getNavBarSelector } from "store/reducers/HiveDetails/mainMenu";

interface Props {
  hiveActivities: HiveContentItem[];
}

const Activities = ({ hiveActivities }: Props) => {
  const dispatch = useAppDispatch();
  const hiveDetails = useAppSelector(getHiveSelector);

  useEffect(() => {
    dispatch(
      getHiveActivities({
        communityId: hiveDetails?.communityId!,
        pageNo: 0,
        contentLimit: 30,
      })
    );
  }, [hiveDetails]);

  const navigate = useNavigate();

  const path = useLocation();

  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleUserClick = (user: HiveContentItem) => {
    dispatch(setClickedProfileId(user.profileId));
    handleOpen();
    // navigate(`/activity/users/${user.profileId}`, {
    //   state: { from: path.pathname },
    // });
  };

  const videoTabs = useAppSelector(getVideoTabs);
  const navbarItems = useAppSelector(getNavBarSelector);

  const childComponents = useAppSelector(getChildSelector);

  return (
    <div className="activities_container">
      <h4>Activities</h4>
      {hiveActivities?.map((activity, idx) => {
        return (
          <div
            onClick={() => !!childComponents[0] && handleUserClick(activity)}
            className={`activity_wrapper ${
              !!childComponents[0] && "cursor-pointer"
            }`}
            key={idx}
          >
            {activity.profile_photo ? (
              <img
                src={activity.profile_photo || ""}
                alt=""
                className="activity_profile_photo"
              />
            ) : (
              <ProfileSVG />
            )}
            <div>
              <p className="text-sm">{activity.activity}</p>
              <div className="bold_xs_text">
                {activity.created_date && (
                  <h1 className="bold_xs_text">
                    <ReactTimeAgo
                      date={
                        new Date(
                          moment
                            .utc(
                              activity.created_date
                                .replace(/\.\d+/g, "")
                                .replace(" ", "T")
                                .replace(/[-:]/g, "")
                            )
                            .local()
                            .format("LLL")
                        )
                      }
                      locale="en-GB"
                    />
                  </h1>
                )}
              </div>
            </div>
          </div>
        );
      })}
      {isOpen && <ActivityUserPage isOpen={isOpen} onReject={handleClose} />}
    </div>
  );
};

export default Activities;
