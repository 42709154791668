import { Dialog, CircularProgress } from "@mui/material";
import { useAppSelector } from "hooks/redux";
import React from "react";
import { subscriptionsFetchingSelector } from "store/reducers/Subscriptions/subscriptions";

interface Props {
  isLoading: boolean;
}

const PaymentLoader = ({ isLoading }: Props) => {
  return (
    <Dialog open={isLoading}>
      <div className="payment_loader_padding">
        <CircularProgress size={30} color="inherit" />
        <p>
          We're processing your request, please stay on this page, you will be
          redirected once completed.
        </p>
      </div>
    </Dialog>
  );
};

export default PaymentLoader;
