import { useEffect, useState } from "react";

import {
  useAppDispatch,
  useAppSelector,
  useAppShallowSelector,
} from "hooks/redux";
import PageLayout from "components/PageLayout";
import TitleSearchSections from "./components/TitleSearchSections";
import ChatRoomsListSections from "./components/ChatRoomsListSections";
import FlatActionButton from "components/FlatActionButton";
// import Loader from "components/common/Loader";
import {
  getChatRooms,
  getChatRoomsByChannel,
} from "store/async-actions/Chat/chatrooms";
import {
  getChatRoomsByChannelSelector,
  getFilteredChatRoomsByChannelSelector,
  getFilteredChatRoomsByIsPinnedSelector,
  getIsFetchingChatRoomsSelector,
} from "store/reducers/Chat/chatrooms";
// import {
//   getActiveChannelIdentifierSelector,
//   getIsModeratorChannelSelector,
// } from "store/reducers/Chat/channels";
import { profileUserIdSelector } from "store/reducers/Profile/profile";
import { getActiveChannelIdSelector } from "store/reducers/ChannelDetails/channelList";
import CircularProgress from "@mui/material/CircularProgress/CircularProgress";
import { Outlet, useNavigate } from "react-router-dom";
import BackButton from "components/BackButton";
import LineBreak from "components/LineBreak";
import IslandLayout from "components/IslandLayout";
import { checkOwnership } from "store/async-actions/Content/content";
import {
  getHiveSelector,
  launchLogin,
} from "store/reducers/HiveDetails/hiveDetails";
import { getIsOwnerContentSelector } from "store/reducers/Content/content";
import { Divider } from "@mui/material";

export interface Chatroom {
  id: UniqueId;
  title: string;
  description: string;
  logo: Logo;
  isPinned: boolean;
  onlineCount: number;
}

const ChatRoomsPage = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const userId = useAppSelector(profileUserIdSelector);
  const channelId = useAppSelector(getActiveChannelIdSelector);
  const isModerator = useAppSelector(getIsOwnerContentSelector);

  const hiveDetails = useAppSelector(getHiveSelector);
  const activeChannelId = useAppSelector(getActiveChannelIdSelector);

  const { pinned, unpinned } = useAppShallowSelector(
    getFilteredChatRoomsByIsPinnedSelector
  );

  const chatRoomsByChannels = useAppSelector(getChatRoomsByChannelSelector);
  const filteredChatRooms = useAppSelector(
    getFilteredChatRoomsByChannelSelector
  );

  useEffect(() => {
    localStorage.getItem("isLoggedIn") !== "yes" && launchLogin();
  }, []);

  // useEffect(() => {
  //   if (userId && channelId !== null) {
  //     dispatch(
  //       getChatRooms({
  //         userId: userId, //1746, //////// remove mock userId and pass userId from store (profile reducers)
  //         channelId,
  //       })
  //     );
  //   }
  // }, [channelId, dispatch, userId]);

  const [redirected, setRedirected] = useState(false);

  useEffect(() => {
    hiveDetails &&
      dispatch(
        getChatRoomsByChannel({ organizationId: hiveDetails?.communityId })
      );
  }, [hiveDetails, dispatch]);

  // useEffect(() => {
  //   if (
  //     chatRoomsByChannels &&
  //     !!chatRoomsByChannels[0] &&
  //     !!chatRoomsByChannels[0].chatRoomList &&
  //     !redirected
  //   ) {
  //     navigate(chatRoomsByChannels[0].chatRoomList[0]._id);
  //     setRedirected(true);
  //   }
  // }, [chatRoomsByChannels]);

  useEffect(() => {
    hiveDetails &&
      channelId &&
      dispatch(
        checkOwnership({
          organizationId: hiveDetails?.communityId,
          channelId: channelId,
        })
      );
  }, [hiveDetails, channelId]);

  return (
    <IslandLayout>
      {/* <PageLayout sideMenu={false}> */}
      {/* <PageLayout className="chatrooms_page"> */}
      <div className="chatrooms_page_container">
        {/* <div className="fixed_back_button"> */}
        <BackButton />
        {/* </div> */}
        <h1>Chat</h1>
        <Divider />
        {/* {!!pinned.length && (
          <ChatRoomsListSections title="Pinned" dataList={pinned} />
        )}
        {!!unpinned.length && (
          <ChatRoomsListSections title="Rooms" dataList={unpinned} />
        )} */}
        {chatRoomsByChannels.map((channel, idx) => {
          return (
            <div className="chat_rooms_by_channels" key={idx}>
              {channel.chatRoomList && channel.chatRoomList.length > 0 && (
                <div>
                  <h3>{channel.channelName}</h3>
                  <ChatRoomsListSections dataList={channel.chatRoomList} />
                </div>
              )}
            </div>
          );
        })}
        <PageLoader />
      </div>
      {/* </PageLayout> */}
      {/* {isModerator && <FlatActionButton />} */}
    </IslandLayout>
  );
};

const PageLoader = () => {
  const isFetching = useAppSelector(getIsFetchingChatRoomsSelector);
  return isFetching ? <CircularProgress /> : null;
};

export default ChatRoomsPage;
