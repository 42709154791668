import { useAppDispatch, useAppSelector } from "hooks/redux";
import React from "react";
import { useNavigate } from "react-router-dom";
import {
  sendEmailCampaign,
  sendSMSCampaign,
} from "store/async-actions/Campaign/campaign";
import {
  getCurrentCampaignSelector,
  resetCampaignDetails,
} from "store/reducers/Campaign/campaign";
import { getHiveUuid } from "store/reducers/HiveDetails/hiveDetails";

const CampaignCreated = () => {
  const currentCampaign = useAppSelector(getCurrentCampaignSelector);
  const dispatch = useAppDispatch();

  const hiveUuid = useAppSelector(getHiveUuid);

  const handleSendSms = () => {
    dispatch(resetCampaignDetails());

    currentCampaign?.campaignType === "sms"
      ? dispatch(
          sendSMSCampaign({
            campaignUuid: currentCampaign?.campaignUuid || "",
          })
        )
      : dispatch(
          sendEmailCampaign({
            organizationUuid: hiveUuid,
            campaignUuid: currentCampaign?.campaignUuid || "",
          })
        );
    navigate("/campaigns");
  };

  const navigate = useNavigate();

  const handleRedirect = () => {
    dispatch(resetCampaignDetails());

    navigate("/campaigns");
  };

  return (
    <div className="camp_created_wrapper">
      <h2>Congratulations! Your Campaign was created successfully!</h2>
      <div className="flex gap_x_20">
        <div onClick={handleSendSms} className="secondaryBtn">
          Send {currentCampaign?.campaignType === "sms" ? "Messages" : "Emails"}{" "}
          Now
        </div>
        <div onClick={handleRedirect} className="primaryBtn">
          Send Later
        </div>
      </div>
    </div>
  );
};

export default CampaignCreated;
