import { Chat, Comment, CommentBankOutlined } from "@mui/icons-material";
import { FeedItem } from "api/models/Feed/Feed";
import { VideoListItem } from "api/models/Videos/videoList";
import LineBreak from "components/LineBreak";
import ProfilePhoto from "components/ProfilePhoto";
import {
  ChatBotHeaderSVG,
  CommentSVG,
  ProfileSVG,
  ShareSVG,
} from "components/SVG/SVG";
import { useAppDispatch } from "hooks/redux";
import { toastInfo } from "hooks/useToastify";
import React, { useEffect, useRef, useState } from "react";
import ReactPlayer from "react-player";
import { useNavigate } from "react-router-dom";
import feed, { setFeedCurrentVideo } from "store/reducers/Feed/feed";
import { launchLogin } from "store/reducers/HiveDetails/hiveDetails";
import { setCurrentVideo } from "store/reducers/Videos/videoList";

interface Props {
  feedItem: VideoListItem;
}

const FeedVideoItem = ({ feedItem }: Props) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handleFeedClick = () => {
    dispatch(setFeedCurrentVideo(feedItem));
    navigate(`${feedItem.videoUuid}`);
  };

  const handleCopy = (text: string) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        if (!!text) {
          toastInfo("Copied link to clipboard");
        } else {
          toastInfo(
            "This video isnt available at the moment, please try another one"
          );
        }
      })
      .catch((error) => {
        console.error("Error copying text: ", error);
      });
  };

  function getMainDomain(url: string): string | null {
    try {
      const match = url.match(/^(?:https?:\/\/)?(?:www\.)?([^\/:]+)/i);
      if (match) {
        return match[1];
      }
      return null;
    } catch (error) {
      console.error("Error while extracting main domain:", error);
      return null;
    }
  }
  return (
    <div className="feed_item">
      <div className="feed_head">
        <ProfilePhoto imgUrl={feedItem.profilePic || ""} />
        <div>
          <h4>{feedItem.videoTitle}</h4>
          <LineBreak />
          <div className="flex-wrap tags_wrapper">
            {!!feedItem.tags &&
              feedItem.tags.map((tag, idx) => {
                return (
                  <p className="tags" key={idx}>
                    #{tag}
                  </p>
                );
              })}
          </div>
          <LineBreak />
          <p className="tags text-sm">Posted by {feedItem.userName}</p>
        </div>
      </div>
      <div className="feed_body_container">
        <div onClick={handleFeedClick} className="pointer feed_body">
          {/* {localStorage.getItem("isLoggedIn") === "yes" ? ( */}
          <ReactPlayer
            url={feedItem.sourceUrl}
            // ref={videoRef}
            // playing={isPlaying}
            controls
            width="300px"
            height="533px"
            style={{
              overflow: "hidden",
              borderRadius: "15px",
              background: "black",
            }}
          />
          {/* ) : (
            <img
              onClick={launchLogin}
              src={feedItem.previewImage}
              alt=""
              className="feed_video"
            />
          )} */}
        </div>
        <div className="feed_item_icons">
          <div onClick={handleFeedClick} className="feed_item_icon_wrapper">
            <CommentSVG />
          </div>
          <div
            onClick={() =>
              handleCopy(
                `${document.location.origin}/channels/${feedItem.channelUuid}/videos/${feedItem.videoUuid}`
              )
            }
            className="feed_item_icon_wrapper"
          >
            <ShareSVG />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeedVideoItem;
