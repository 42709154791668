import HiveGuidelines from "components/HiveGuidelines/HiveGuidelines";
import IslandLayout from "components/IslandLayout";
import LineBreak from "components/LineBreak";
import PageLayout from "components/PageLayout";
import { LoadingSVG, RocketSVG } from "components/SVG/SVG";
import Headers from "components/TextStyles/components/Headers";
import { useAppSelector } from "hooks/redux";
import React from "react";
import { getHiveSelector } from "store/reducers/HiveDetails/hiveDetails";

const ComingSoon = () => {
  const hiveDetails = useAppSelector(getHiveSelector);

  return (
    <PageLayout sideMenu={true}>
      <IslandLayout>
        <div className="coming_soon">
          <RocketSVG />
          <LineBreak />
          Get ready for something amazing!
        </div>
        <h4 className="coming_soon_content">
          A new project is on its way to transport you to a world of wonder.
          Stay tuned!{" "}
        </h4>
      </IslandLayout>
      <LineBreak />
      <IslandLayout>
        <div className="home_page_content">
          <div className="home_guidelines">
            <Headers
              children={
                hiveDetails?.communityId === 294 ? "About Us" : "Guidelines"
              }
            />
            <HiveGuidelines />
          </div>
        </div>
      </IslandLayout>
    </PageLayout>
  );
};

export default ComingSoon;
