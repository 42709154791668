import DateSelector from "components/DateSelector/DateSelector";
import React from "react";

const ContentSchedule = () => {
  return (
    <div className="ttv_container">
      <DateSelector date="custom" />
    </div>
  );
};

export default ContentSchedule;
