import { useQuery } from "hooks/useQuery";
import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const AttendeeRegistration = () => {
  const navigate = useNavigate();

  const searchParams = useQuery();

  const user = searchParams.get("user")!;

  const location = useLocation();
  const path = location.pathname;

  const openDubaiTechTalks = () => {
    window.open(
      `https://members.dubaitechtalks.com${path}?login=${localStorage.getItem(
        "@jwtToken"
      )}&user=${user}`,
      "_self"
    );
    setTimeout(() => {
      window.location.reload();
    }, 5000);
  };

  useEffect(() => {
    if (
      localStorage.getItem("subDomain") === "wise" ||
      localStorage.getItem("subDomain") === "vee" ||
      localStorage.getItem("subDomain") === "mnm" ||
      localStorage.getItem("subDomain") === "guruji" ||
      localStorage.getItem("subDomain") === "wkff" ||
      localStorage.getItem("subDomain") === "tbs" ||
      localStorage.getItem("subDomain") === "te"
    ) {
      navigate(
        `/mobile${path}?login=${localStorage.getItem("@jwtToken")}&user=${user}`
      );
      window.location.reload();
    }
    if (localStorage.getItem("subDomain") === "dtt") {
      openDubaiTechTalks();
    } else {
      navigate(
        `/mobile${path}?login=${localStorage.getItem("@jwtToken")}&user=${user}`
      );
      window.location.reload();
    }
  }, [navigator.userAgent]);

  return <></>;
};

export default AttendeeRegistration;
