import { TextField } from "@mui/material";
import { AttendeeFormDetails } from "api/models/Hive/events";
import IslandLayout from "components/IslandLayout/IslandLayout";
import LineBreak from "components/LineBreak/LineBreak";
import PageLayout from "components/PageLayout/PageLayout";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import AttendeeForm from "pages/Events/components/AttendeeForm";
import React, { useEffect, useState } from "react";
import FormInformation from "./FormInformation";
import { getHiveUuid } from "store/reducers/HiveDetails/hiveDetails";
import { createForm, editFormDetails } from "store/async-actions/Forms/forms";
import BackButton from "components/BackButton/BackButton";
import { toastInfo } from "hooks/useToastify";
import { useNavigate } from "react-router-dom";
import { getCurrentFormSelector } from "store/reducers/Forms/forms";
import FormEmailNotifications from "./components/FormEmailNotification";

const EditForm = () => {
  const dispatch = useAppDispatch();

  const currentForm = useAppSelector(getCurrentFormSelector);
  const formDetails = currentForm?.formList;

  const [name, setName] = useState(currentForm?.formName || "");
  const [link, setLink] = useState(currentForm?.downloadLink || "");

  const [attendeeFormDetails, setAttendeeFormDetails] = useState<
    AttendeeFormDetails[]
  >(formDetails || []);

  useEffect(() => {
    if (formDetails) {
      setAttendeeFormDetails(formDetails);
      setName(currentForm.formName);
      setLink(currentForm.downloadLink);
    }
  }, [currentForm]);

  const hiveUuid = useAppSelector(getHiveUuid);
  const navigate = useNavigate();

  const [sendNotif, setSendNotif] = useState(
    currentForm?.emailEnabled || false
  );

  const [emails, setEmails] = useState<string[]>(
    currentForm?.notifyEmails || []
  );

  useEffect(() => {
    if (!!currentForm) {
      setSendNotif(currentForm.emailEnabled || false);
      setEmails(currentForm.notifyEmails || []);
    }
  }, [currentForm]);

  const handleEdit = () => {
    currentForm &&
      dispatch(
        editFormDetails({
          organizationUuid: hiveUuid,
          formList: attendeeFormDetails,
          formName: name,
          downloadLink: link,
          formUuid: currentForm?.formUuid || "",
          notifyEmails: emails,
          emailEnabled: sendNotif,
        })
      );
    toastInfo("Edited Form Successfully!");
  };

  return (
    <PageLayout sideMenu={true}>
      <IslandLayout>
        <div className="page_padding">
          <BackButton to="/forms" />
          <div className="backBtn_spacing">
            <h2>Bring your form to life</h2>
            <p>Create a form to engage with your users</p>
          </div>
          <LineBreak />
          <TextField
            className="half_width"
            id="standard-basic"
            label="Form Name"
            variant="standard"
            name="name"
            onChange={(e) => setName(e.target.value)}
            value={name}
            required
          />
          <LineBreak />
          <TextField
            className="half_width"
            id="standard-basic"
            label="Redirect Link"
            variant="standard"
            name="link"
            onChange={(e) => setLink(e.target.value)}
            value={link}
          />
          <LineBreak />
          <FormInformation
            attendeeFormDetails={attendeeFormDetails}
            setAttendeeFormDetails={setAttendeeFormDetails}
          />
          <FormEmailNotifications
            sendNotif={sendNotif}
            setSendNotif={setSendNotif}
            emails={emails}
            setEmails={setEmails}
          />
          <div onClick={handleEdit} className="primaryBtn nextBtn">
            Save
          </div>
        </div>
      </IslandLayout>
    </PageLayout>
  );
};

export default EditForm;
