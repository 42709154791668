import { Backdrop, CircularProgress } from "@mui/material";
import IslandLayout from "components/IslandLayout";
import React from "react";

type Props = {};

const FeedLoader = (props: Props) => {
  return (
    <div className="left-aligned-shimmer-loader">
      <div className="shimmer"></div>
      <div className="content">
        <div className="title-placeholder"></div>
        <div className="description-placeholder"></div>
        <div className="video-placeholder"></div>
      </div>
    </div>
  );
};

export default FeedLoader;
