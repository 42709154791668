import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import LineBreak from "components/LineBreak";
import React, { useState, useEffect, ChangeEvent } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import {
  getActiveChannelSelector,
  getActiveChannelUuidSelector,
  getChannelListSelector,
  setActiveChannelUuid,
  setVideosTab,
} from "store/reducers/ChannelDetails/channelList";
import {
  uploadContent,
  uploadToS3,
} from "store/async-actions/Videos/videosActions";
import {
  contentUploadRespSelector,
  getUrl,
  isUploadingSelector,
  populateFormData,
  s3Uploaded,
  setIsUploading,
  setVideoFile,
  thumbnailSelector,
  uploadFileSelector,
} from "store/reducers/Videos/videos";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import TokenInput from "react-customize-token-input";
import "pages/UploadVideo/components/small_token_input.scss";

import {
  getHiveSelector,
  getHiveUuid,
} from "store/reducers/HiveDetails/hiveDetails";
import "../../../UploadVideo/components/react-quill.scss";
import SpotDropzone from "components/SpotDropzone/SpotDropzone";

const SpotlightUploadInfo = () => {
  const channelList = useAppSelector(getChannelListSelector);
  const channelUuid = useAppSelector(getActiveChannelUuidSelector);
  const dispatch = useAppDispatch();
  const s3Done = useAppSelector(s3Uploaded);
  const thumbnail = useAppSelector(thumbnailSelector);
  const activeChannel = useAppSelector(getActiveChannelSelector);
  const isUploading = useAppSelector(isUploadingSelector);

  const navigate = useNavigate();

  const hiveUuid = useAppSelector(getHiveUuid);

  const s3Url = useAppSelector(getUrl);
  const file = useAppSelector(uploadFileSelector);
  const contentUploadResp = useAppSelector(contentUploadRespSelector);

  const [channel, setChannel] = useState(
    channelUuid || channelList[0].channelUuid || ""
  );

  const handleChange = (channel: SelectChangeEvent) => {
    setChannel(channel.target.value);
    dispatch(setActiveChannelUuid(channel.target.value));
  };

  useEffect(() => {
    if (s3Done) {
      dispatch(setIsUploading(false));
      dispatch(setVideoFile(null));
      navigate(`..`);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [s3Done]);

  // useEffect(() => {
  //   if (s3Url && !isUploading) {
  //     const formData = new FormData();
  //     populateFormData(formData, contentUploadResp!, file!);
  //     dispatch(uploadToS3({ url: s3Url, formData: formData }));
  //   }
  // }, [s3Url]);

  const hiveDetails = useAppSelector(getHiveSelector);

  const handleVideoUpload = async () => {
    await dispatch(
      uploadContent({
        organisationID: hiveUuid,
        channelID: channel,
        thumbNailBase64: thumbnail,
        previewImage: thumbnail,
        userId: "1324",
        name: videoTitle,
        channelType: "VIDEO",
        attribute5: [],
        attribute4: [],
        attribute3: [data],
        attribute2: [],
        attribute1: [],
        receipeName: videoTitle,
        itemDescription: null,
        tags: values,
        pitchDeckFile: base64Url,
        pitchDeckFileType: fileType,
        productUrl: productUrl,
        communityId: hiveDetails?.communityId || 0,
      })
    );
  };

  const [data, setData] = useState("");

  function handleTextBox(event: string) {
    if (removeHtmlTags(event).length < 3000) {
      setData(event);
    }
  }

  const [base64Url, setBase64Url] = useState("");

  const [videoTitle, setVideoTitle] = useState("");
  const [titleRemaining, setTitleRemaining] = useState(20);

  const handleTitleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setVideoTitle(e.target.value.slice(0, 20));
    setTitleRemaining(20 - e.target.value.slice(0, 20).length);
  };

  const [values, setValues] = useState([]);
  const [productUrl, setProductUrl] = useState("");

  const [fileType, setFileType] = useState("");

  const handleUrlChange = (e: ChangeEvent<HTMLInputElement>) => {
    setProductUrl(e.target.value.slice(0, 180));
  };

  function removeHtmlTags(htmlString: string): string {
    return htmlString.replace(/<[^>]+>/g, "");
  }

  return (
    <div className="upload_info_container">
      <div className="upload_info_margins">
        <h3>Upload Video</h3>
        <p className="text-sm">Post a video to this channel</p>
      </div>
      <div>
        <div className="upload_info__content_spacing">
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Uploading to</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={channel}
              label="Uploading to"
              onChange={handleChange}
            >
              {/* {channelList.map((channel, idx) => {
                if (channel.channelUuid) {
                  return ( */}
              <MenuItem value={activeChannel?.channelUuid}>
                {activeChannel?.channelName}
              </MenuItem>
              {/* );
                 }
               })} */}
            </Select>
          </FormControl>
        </div>
        <h5 className="upload_headings">Title</h5>
        <div className="upload_info_margins upload_title_input">
          <input
            className={`${
              videoTitle.length > 0 ? "video_upload_title" : "video_title_empty"
            }`}
            value={videoTitle}
            onInput={handleTitleChange}
            placeholder="Untitled"
          />
          <div className="character_limit text-sm">{videoTitle.length}/20</div>
        </div>
        <div className="upload_info_margins">
          <div className="title_and_limit">
            <h4 className="upload_headings">Tags</h4>
            <div className="character_limit text-sm">*max 5 tags</div>
          </div>
          {/* <LineBreak /> */}
          <TokenInput
            separators={[" ", ",", ";"]}
            tokenValues={values.slice(0, 5)}
            onTokenValuesChange={setValues}
            className=""
            placeholder={`Tags separated by commas`}
          />
        </div>
        <div className="text_box_container">
          <h4 className="upload_headings">Description</h4>
          <LineBreak />
          <div className="react-quill-text-container">
            <ReactQuill
              className="text_box"
              theme="snow"
              value={data}
              onChange={(e) => handleTextBox(e)}
            />
            <div className="react-quill-text-limit character_limit text-sm">
              {removeHtmlTags(data).length}/3000
            </div>
          </div>
        </div>
        <LineBreak />
      </div>
      <div className="upload_info_margins_pitch">
        <h4 className="upload_headings">Attachment</h4>
        <LineBreak />
        <SpotDropzone
          base64Url={base64Url}
          setBase64Url={setBase64Url}
          setFileType={setFileType}
        />
      </div>
      <LineBreak />
      <div className="upload_info_margins">
        <h4 className="upload_headings">Product Url</h4>
        <LineBreak />
        <div className="upload_title_input">
          <input
            className="video_upload_title"
            value={productUrl}
            onInput={handleUrlChange}
            placeholder="https://example.com"
          />
          <div className="character_limit text-sm">{productUrl.length}/180</div>
        </div>
      </div>
      <div className="button_rows-edit_video">
        <NavLink to=".." className="secondaryBtn half_width">
          Discard
        </NavLink>
        {thumbnail && videoTitle.length > 1 ? (
          <div onClick={handleVideoUpload} className="primaryBtn half_width">
            Post
          </div>
        ) : (
          <div className="disabledBtn half_width">Post</div>
        )}
      </div>
    </div>
  );
};

export default SpotlightUploadInfo;
