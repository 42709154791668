import { GreenTickSVG, RedCrossSVG } from "components/SVG/SVG";
import { useAppSelector } from "hooks/redux";
import React from "react";
import {
  getCurrentEventSelector,
  getEventRespSelector,
} from "store/reducers/HiveDetails/events";

interface Props {
  handleMemberRegistration: any;
  handleSetRegister: any;
}

const EventRSVP = ({ handleMemberRegistration, handleSetRegister }: Props) => {
  const eventResp = useAppSelector(getEventRespSelector);
  const currentEvent = useAppSelector(getCurrentEventSelector);

  return (
    <div className="rsvp_event_container nextBtn_events">
      <h2 className="">RSVP?</h2>
      <div
        onClick={handleMemberRegistration}
        className={`${
          eventResp?.hasAccess === 1 ? "primaryBtn" : "secondaryBtn"
        } event_btn_next event_btn_hover`}
      >
        <GreenTickSVG />
        <p>Yes</p>
      </div>
      {currentEvent?.isPaidEvent ? (
        <>
          {eventResp?.hasAccess !== 1 && (
            <div
              onClick={() => handleSetRegister(2)}
              className={`${
                eventResp?.hasAccess === 2 ? "primaryBtn" : "secondaryBtn"
              } event_btn_next event_btn_hover`}
            >
              <RedCrossSVG />
              <p>No</p>
            </div>
          )}
        </>
      ) : (
        <div
          onClick={() => handleSetRegister(2)}
          className={`${
            eventResp?.hasAccess === 2 ? "primaryBtn" : "secondaryBtn"
          } event_btn_next event_btn_hover`}
        >
          <RedCrossSVG />
          <p>No</p>
        </div>
      )}
      {currentEvent?.isPaidEvent ? (
        <>
          {eventResp?.hasAccess !== 1 && (
            <div
              onClick={() => handleSetRegister(3)}
              className={`${
                eventResp?.hasAccess === 3 ? "primaryBtn" : "secondaryBtn"
              } event_btn_hover`}
            >
              Maybe
            </div>
          )}
        </>
      ) : (
        <div
          onClick={() => handleSetRegister(3)}
          className={`${
            eventResp?.hasAccess === 3 ? "primaryBtn" : "secondaryBtn"
          } event_btn_hover`}
        >
          Maybe
        </div>
      )}
    </div>
  );
};

export default EventRSVP;
