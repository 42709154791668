import Buttons from "components/Buttons";
import HiveGuidelines from "components/HiveGuidelines/HiveGuidelines";
import IslandLayout from "components/IslandLayout";
import LineBreak from "components/LineBreak";
import PageLayout from "components/PageLayout";
import {
  ChannelStatsSVG,
  OnlineStatsSVG,
  StoriesStatsSVG,
  UsersStatsSVG,
  VideoStatsSVG,
} from "components/SVG/SVG";
import Headers from "components/TextStyles/components/Headers";
import Button from "components/common/Button";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { toastError, toastSuccess } from "hooks/useToastify";
import SpotlightContainer from "pages/Hive/Spotlight/SpotlightContainer";
import { useEffect, useRef, useState } from "react";
import ReactPlayer from "react-player";
import { useNavigate } from "react-router-dom";
import { useChatsNotifSocket } from "services/socketNotification";
import { store } from "store";
import {
  getChildComponents,
  getHiveActivities,
} from "store/async-actions/Hive/hivePage";
import { getStories } from "store/async-actions/Story/storyActions";
import {
  channelsCountSelector,
  usersCountSelector,
  videoCountSelector,
} from "store/reducers/Analytics/analytics";
import { getHomeComponents } from "store/reducers/ChildComponents/childComponents";
import { getOnlineMembersSelector } from "store/reducers/HiveDetails/hiveActivities";
import { getHiveSelector } from "store/reducers/HiveDetails/hiveDetails";
import { countryCodeSelector } from "store/reducers/Profile/profile";
import {
  getAllStoriesSelector,
  getStoriesListSelector,
} from "store/reducers/Story/story";
import YouTube from "react-youtube";

function formatUserCount(userCount: number): string {
  if (userCount < 1000) {
    return userCount.toString();
  } else if (userCount < 10000) {
    const thousands = Math.floor(userCount / 1000);
    const remainder = userCount % 1000;
    if (remainder === 0) {
      return `${thousands}k`;
    } else {
      return `${thousands}.${Math.floor(remainder / 100)}k`;
    }
  } else {
    return (userCount / 1000).toFixed(0) + "k";
  }
}

const HomePage = () => {
  const hiveDetails = useAppSelector(getHiveSelector);
  const childComponents = useAppSelector(getHomeComponents);

  const videoCount = useAppSelector(videoCountSelector);
  const usersCount = useAppSelector(usersCountSelector);
  const channelsCount = useAppSelector(channelsCountSelector);

  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const countryCode = useAppSelector(countryCodeSelector);
  const videoRef = useRef<HTMLVideoElement>(null);
  const [showControls, setShowControls] = useState(false);
  const [showBuyButton, setShowBuyButton] = useState(false);
  useEffect(() => {
    dispatch(
      getHiveActivities({
        communityId: hiveDetails?.communityId!,
        pageNo: 0,
        contentLimit: 30,
      })
    );
  }, [hiveDetails]);

  useEffect(() => {
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      navigate(`/mobile?login=${localStorage.getItem("@jwtToken")}`);
    } else {
      navigate("/home");
    }
  }, [navigator.userAgent]);

  const stories = useAppSelector(getAllStoriesSelector);
  const members = useAppSelector(getOnlineMembersSelector);

  useEffect(() => {
    hiveDetails &&
      dispatch(
        getChildComponents({
          organizationId: hiveDetails?.communityId,
          parentComponentCode: "Home",
          channelUuid: null,
          isMemberView: store.getState().profile.isMemberView,
        })
      );
    localStorage.setItem("path", "/home");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hiveDetails, dispatch]);

  useEffect(() => {
    hiveDetails &&
      dispatch(
        getStories({
          organizationUuid: hiveDetails?.communityUuid,
        })
      );
  }, [dispatch, hiveDetails]);

  const openURL = () => {
    if (videoRef.current) {
      setShowControls(true);
      setShowBuyButton(false);
    }
  };

  const handleVideoStart = () => {
    setTimeout(() => {
      // setShowControls(false);
      setShowBuyButton(true);
    }, 1000);
  };

  const handleVideoEnd = () => {
    setTimeout(() => {
      setShowControls(true);
      setShowBuyButton(false);
    }, 5000);
  };

  useChatsNotifSocket();

  const handleEditGuidelines = () => {
    navigate("/home/settings/guidelines");
  };

  return (
    <PageLayout sideMenu={true} rightMenu={true}>
      <div className="home_page_container">
        <IslandLayout>
          {/* <h1 onClick={() => toastSuccess("Error")}>Click alert</h1> */}
          <div className="home_media_container">
            <img
              alt=""
              className="home_banner"
              src={hiveDetails?.communityBanner}
            />
            <img
              alt=""
              className="home_logo"
              src={hiveDetails?.communityWebLogo || ""}
            />
          </div>
          <div className="home_page_content">
            <h2 className="home_page_heading">{hiveDetails?.communityName}</h2>
            <div className="home_details_wrapper">
              <div className="home_insights">
                {/* <p> */}
                <div className="stat_gapping">
                  <VideoStatsSVG />
                  {videoCount}
                </div>
                {/* • */}
                <div className="stat_gapping">
                  <UsersStatsSVG />
                  {formatUserCount(usersCount)}
                </div>
                {/* • */}
                <div className="stat_gapping">
                  <ChannelStatsSVG />
                  {channelsCount}
                </div>
                {/* • */}
                <div className="stat_gapping">
                  <StoriesStatsSVG />
                  {stories.length || 0}
                </div>
                {/* • */}
                <div className="stat_gapping">
                  <OnlineStatsSVG />
                  {members.length || 0}
                </div>
                {/* </p> */}
              </div>
              <div className="home_buttons">
                {childComponents.map((button, idx) => {
                  return (
                    <Buttons key={idx} button={button}>
                      {button.componentType === "logo" ? (
                        <img src={button.componentIcon} alt="" width="20px" />
                      ) : (
                        button.componentName
                      )}
                    </Buttons>
                  );
                })}
              </div>
            </div>
          </div>
        </IslandLayout>
        <LineBreak />
        <SpotlightContainer />
        <LineBreak />
        <IslandLayout>
          <div className="home_page_content">
            <div className="home_description">
              <Headers children="Who Are We?" />
              <p>
                {hiveDetails?.longDescription ||
                  "Digital hives often have features like forums, chat rooms, blogs, and other tools for communication and collaboration. They can be used for a variety of purposes, such as networking, knowledge-sharing, and socializing."}
              </p>
            </div>
          </div>
        </IslandLayout>
        <LineBreak />
        {hiveDetails?.introVideo && (
          <IslandLayout>
            {hiveDetails.communityFlier ? (
              <div className="home_page_content">
                <div className="intro_vid-ak-container">
                  <div
                    className="intro_video_container video_gradient"
                    style={{ position: "relative", height: "533px" }}
                  >
                    <video
                      muted={true}
                      id="video"
                      ref={videoRef}
                      poster={hiveDetails.communityFlier || ""}
                      controls={showControls}
                      autoPlay={false}
                      style={{
                        height: "533px",
                        width: "300px",
                        overflow: "hidden",
                        borderRadius: "15px",
                        background: "black",
                      }}
                      onClick={openURL}
                      onPlay={handleVideoStart}
                      onEnded={handleVideoEnd}
                    >
                      <source
                        src={hiveDetails?.introVideo || ""}
                        type="video/mp4"
                      />
                    </video>
                    {hiveDetails.videoUploadGuide && showBuyButton && (
                      <div
                        className="shoppable_align"
                        style={{
                          position: "absolute",
                          // background: "rgba(0, 0, 0, 0.5)",
                          width: "300px",
                          height: "50px",
                          bottom: "60px",
                          color: "white",
                          padding: "5px 10px",
                        }}
                      >
                        <div>
                          {/* <h4 style={{ padding: "5px" }}>
                            {hiveDetails.monetizationPlan || ""}
                          </h4> */}
                          {/* <h6 style={{ padding: "5px" }}>
                          {hiveDetails.communityGuide || ""}
                        </h6> */}
                        </div>
                        <a
                          style={
                            {
                              // right: "10px",
                              // position: "absolute",
                              // top: "5px",
                            }
                          }
                          href={hiveDetails.videoUploadGuide || ""}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="tertiaryBtn"
                        >
                          {hiveDetails.communityBio || ""}
                        </a>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ) : (
              <div className="home_page_content">
                <div className="intro_video_container">
                  <ReactPlayer
                    muted={true}
                    url={hiveDetails?.introVideo}
                    width="300px"
                    height="533px"
                    controls
                    style={{
                      overflow: "hidden",
                      borderRadius: "15px",
                      background: "black",
                    }}
                  />
                </div>
              </div>
            )}
          </IslandLayout>
        )}
        <LineBreak />
        <IslandLayout>
          <div className="home_page_content">
            <div className="home_guidelines">
              <div className="title_and_limit">
                <Headers
                  children={
                    hiveDetails?.communityId === 294 ? "About Us" : "Guidelines"
                  }
                />
                {!!childComponents[0] && (
                  <p onClick={handleEditGuidelines} className="link">
                    Edit
                  </p>
                )}
              </div>
              <HiveGuidelines />
            </div>
          </div>
        </IslandLayout>
      </div>
    </PageLayout>
  );
};

export default HomePage;
