import { CircularProgress } from "@mui/material";
import AnalyticsCapsule from "components/AnalyticsCapsule";
import BackButton from "components/BackButton/BackButton";
import IslandLayout from "components/IslandLayout/IslandLayout";
import LineBreak from "components/LineBreak/LineBreak";
import LongText from "components/LongText/LongText";
import PageLayout from "components/PageLayout/PageLayout";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import moment from "moment";
import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ReactTimeAgo from "react-time-ago";
import { getHiveListUsers } from "store/async-actions/Hive/hivePage";
import { getClickedProfileIdSelector } from "store/reducers/HiveDetails/hiveActivities";
import {
  getFetchingMemberDetailsSelector,
  getHiveUuid,
  getMembersDetailsList,
} from "store/reducers/HiveDetails/hiveDetails";

const UserPage = () => {
  const dispatch = useAppDispatch();

  const hiveUuid = useAppSelector(getHiveUuid);

  const profileId = useAppSelector(getClickedProfileIdSelector);

  const isLoading = useAppSelector(getFetchingMemberDetailsSelector);

  const navigate = useNavigate();

  useEffect(() => {
    if (profileId) {
      dispatch(
        getHiveListUsers({
          profileId: profileId,
          organizationUuid: hiveUuid,
        })
      );
    } else {
      navigate("..");
    }
  }, [dispatch, profileId]);

  const member = useAppSelector(getMembersDetailsList);

  const location = useLocation();

  return (
    <PageLayout sideMenu={true} rightMenu={false}>
      <IslandLayout>
        {isLoading ? (
          <div>
            <CircularProgress size={30} color="inherit" />
          </div>
        ) : (
          <div className="user_page_container">
            <div className="backBtn_Spacing">
              <BackButton to={location.state?.from || "/home"} />
            </div>
            <LineBreak />
            <div className="user_data_container">
              <div className="h_n_v-user">
                <AnalyticsCapsule>
                  <div className="headers_and_value">
                    <h5>User Name</h5>
                    <h2>{member?.userName || ""}</h2>
                  </div>
                </AnalyticsCapsule>
              </div>
              <div className="h_n_v-user">
                <AnalyticsCapsule>
                  <div className="headers_and_value">
                    <h5>Email</h5>
                    <LongText
                      bold={true}
                      title={member?.emailId || ""}
                      cutoff={25}
                    ></LongText>
                  </div>
                </AnalyticsCapsule>
              </div>
              <div className="h_n_v-user">
                <AnalyticsCapsule>
                  {member && (
                    <div className="headers_and_value">
                      <h5>Last Active</h5>
                      {member.lastLoginDate ? (
                        <h2 className="">
                          <ReactTimeAgo
                            date={
                              new Date(
                                moment
                                  .utc(member?.lastLoginDate)
                                  .local()
                                  .format("LLL")
                              )
                            }
                            locale="en-GB"
                          />
                        </h2>
                      ) : (
                        <h2>-</h2>
                      )}{" "}
                    </div>
                  )}
                </AnalyticsCapsule>
              </div>
              <div className="h_n_v-user">
                <AnalyticsCapsule>
                  <div className="headers_and_value">
                    <h5>Email Verified</h5>
                    <h2>{member?.isEmailVerified ? "Yes" : "No"}</h2>
                  </div>
                </AnalyticsCapsule>
              </div>
              <div className="h_n_v-user">
                <AnalyticsCapsule>
                  <div className="headers_and_value">
                    <h5>Phone Verfied</h5>
                    <h2>{member?.isMobileVerified ? "Yes" : "No"}</h2>
                  </div>
                </AnalyticsCapsule>
              </div>
            </div>
          </div>
        )}
      </IslandLayout>
    </PageLayout>
  );
};

export default UserPage;
