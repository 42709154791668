import LineBreak from "components/LineBreak/LineBreak";
import { AddContactSVG, EmailSVG, LocationSVG } from "components/SVG/SVG";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import React, { useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { getContactInfo } from "store/async-actions/Hive/hivePage";
import { getHiveUuid } from "store/reducers/HiveDetails/hiveDetails";
import { getHiveContactSelector } from "store/reducers/HiveDetails/hiveSettings";
import { getHiveConfigMenuSelector } from "store/reducers/HiveDetails/mainMenu";

const ContactSection = () => {
  const dispatch = useAppDispatch();
  const contactInfo = useAppSelector(getHiveContactSelector);
  const hiveConfigMenu = useAppSelector(getHiveConfigMenuSelector);

  const hiveUuid = useAppSelector(getHiveUuid);

  useEffect(() => {
    hiveUuid && dispatch(getContactInfo({ organizationUuid: hiveUuid }));
  }, [dispatch, hiveUuid]);

  const navigate = useNavigate();

  const handleEditContact = () => {
    navigate("/home/settings/about");
  };

  return (
    <div className="contact_section_container">
      {hiveConfigMenu.map((menu, idx) => {
        return (
          <div key={idx}>
            <div className="cu_header_wrapper">
              <h4>{menu.componentName}</h4>
            </div>
            <LineBreak />
            <div className="cu_address_email_wrapper">
              {!!contactInfo?.address ? (
                <div className="cu_address_wrapper">
                  <LocationSVG />
                  <p className="text-sm w-75">{contactInfo?.address || ""}</p>
                </div>
              ) : (
                <>
                  {!!menu.componentDescription && (
                    <div
                      onClick={handleEditContact}
                      className="contact_empty_add"
                    >
                      <AddContactSVG />
                      <p>Add Address</p>
                    </div>
                  )}
                </>
              )}
              {!!contactInfo?.email ? (
                <div className="cu_address_wrapper">
                  <EmailSVG />
                  <p className="text-sm w-75">{contactInfo?.email || ""}</p>
                </div>
              ) : (
                <>
                  {!!menu.componentDescription && (
                    <div
                      onClick={handleEditContact}
                      className="contact_empty_add"
                    >
                      <AddContactSVG />
                      <p>Add Email</p>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default ContactSection;
