import LineBreak from "components/LineBreak";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import React, { useEffect, useState } from "react";
import ReactPlayer from "react-player";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import {
  currentVideoSelector,
  getCurrentVideoIndex,
  setNextVideo,
  setPrevVideo,
  videoListSelector,
} from "store/reducers/Videos/videoList";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.bubble.css";
import {
  channelObjSelector,
  channelPropSelector,
} from "store/reducers/ChannelDetails/channelDetails";
import BackButton from "components/BackButton";
import VideoComments from "./VideoComments";
import IslandLayout from "components/IslandLayout";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import {
  CloseMediaSVG,
  CopyLinkSVG,
  DownArrowSVG,
  UpArrowSVG,
} from "components/SVG/SVG";
import YouTube from "react-youtube";
import { ContentCopy, CopyAllOutlined, Height } from "@mui/icons-material";
import { toastInfo } from "hooks/useToastify";
import sanitizeHtml from "sanitize-html";
import { getVideoDetails } from "store/async-actions/Videos/videosActions";
import YoutubePlayer from "./components/YoutubePlayer";

const style = {
  position: "absolute" as "absolute",
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  // bgcolor: "#ffffff",
  outline: "none",
};

const VideoView = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const currentVideo = useAppSelector(currentVideoSelector);
  const channel = useAppSelector(channelObjSelector);
  const videoListResp = useAppSelector(videoListSelector);
  const currentVideoIndex = useAppSelector(getCurrentVideoIndex);

  const [videoOpen, setVideoOpen] = useState(true);

  const channelProps = useAppSelector(channelPropSelector);

  const handleVideoClose = () => {
    setVideoOpen(false);
  };

  const handleGoBack = () => {
    navigate("..");
    setVideoOpen(false);
  };

  const handleNextVideo = () => {
    dispatch(setNextVideo());
  };

  const handlePrevVideo = () => {
    dispatch(setPrevVideo());
  };

  const handlePitchClick = (url: string) => {
    window.open(url, "_blank", "noreferrer");
  };

  function extractYoutubeId(url: string): string {
    const regex = /v=([^\&\?]+)/;
    const result = url.match(regex);

    return result ? result[1] : "";
  }

  function convertShortsToEmbed(url: string) {
    // Check if the URL contains "/shorts"
    if (url.includes("/shorts/")) {
      // Replace "/shorts" with "/embed"
      const newUrl = url.replace("/shorts/", "/embed/");
      setYoutubeUrl(newUrl);
    } else {
      // If "/shorts" is not found in the URL, return the original URL
      return url;
    }
  }

  function convertVideoToEmbed(url: string) {
    // Check if the URL contains "/shorts"
    if (url.includes("/watch")) {
      // Replace "/shorts" with "/embed"
      const id = extractYoutubeId(url);
      const newUrl = url.replace("/watch", `/embed/${id}`);
      setYoutubeUrl(newUrl);
    } else {
      // If "/shorts" is not found in the URL, return the original URL
      return url;
    }
  }

  const location = useLocation();

  const handleCopy = (text: string) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        if (!!text) {
          toastInfo("Copied link to clipboard");
        } else {
          toastInfo(
            "This video isnt available at the moment, please try another one"
          );
        }
      })
      .catch((error) => {
        console.error("Error copying text: ", error);
      });
  };

  const pathArray = location.pathname.split("/");
  const videouuid = pathArray[pathArray.length - 1];
  const channelUuid = pathArray[pathArray.length - 3];

  const [youtubeUrl, setYoutubeUrl] = useState("");

  useEffect(() => {
    dispatch(
      getVideoDetails({
        videoId: videouuid,
        channelID: channelUuid,
      })
    );
  }, [videouuid, channelUuid]);

  useEffect(() => {
    if (
      currentVideo?.channelType === "YOUTUBE" &&
      !!currentVideo?.horizontalVideoUrl
    ) {
      currentVideo.horizontalVideoUrl.includes("shorts")
        ? convertShortsToEmbed(currentVideo.horizontalVideoUrl)
        : convertVideoToEmbed(currentVideo.horizontalVideoUrl);
    }
  }, [currentVideo?.horizontalVideoUrl]);

  return (
    <Modal
      open={videoOpen}
      onClose={handleGoBack}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className="z-index-max">
        <div className="vhive-1qg2388-DivBrowserModeContainer e11s2kul0">
          <div onClick={handleGoBack} className="vhive_close_media_btn">
            <CloseMediaSVG />
          </div>
          <div className="vhive-1tunefa-DivVideoContainer e11s2kul26">
            <div className="vhive-7tjqm6-DivBlurBackground e11s2kul8">
              <div className="vhive-16ognrj-DivVideoWrapper e11s2kul9">
                <div className="vhive-1jxhpnd-DivContainer e1yey0rl0">
                  <div className="vhive-1h63bmc-DivBasicPlayerWrapper e1yey0rl2">
                    {/* <div></div> */}
                    <div className="ch-live-broadcast__main__live__media-container ch-live-broadcast__main__media-container">
                      {currentVideo?.channelType === "YOUTUBE" &&
                      !!currentVideo?.horizontalVideoURL ? (
                        <YoutubePlayer currentVideo={currentVideo} />
                      ) : (
                        <ReactPlayer
                          width="100%"
                          height="100%"
                          url={
                            currentVideo?.sourceUrl || currentVideo?.sourceURL
                          }
                          controls
                          style={{
                            overflow: "hidden",
                          }}
                        />
                      )}
                    </div>
                    <div className="shoppable_link_in_video_view">
                      {currentVideo?.channelType !== "YOUTUBE" &&
                        !!currentVideo?.horizontalVideoUrl && (
                          <div
                            onClick={() =>
                              handlePitchClick(currentVideo?.horizontalVideoUrl)
                            }
                            className="primaryBtn"
                          >
                            {channelProps?.ctaName || "Visit"}
                          </div>
                        )}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="vhive_media_arrow_container">
              {currentVideoIndex > 0 && (
                <div onClick={handlePrevVideo} className="media_arrow_design">
                  <UpArrowSVG />
                </div>
              )}
              {videoListResp &&
                currentVideoIndex + 1 < videoListResp?.videoList.length && (
                  <div onClick={handleNextVideo} className="media_arrow_design">
                    <DownArrowSVG />
                  </div>
                )}
            </div>
          </div>
          <div className="vhive-3q30id-DivContentContainer e1mecfx00">
            <div className="vhive_media-info">
              <LineBreak />
              <div className="vhive_media_info_spacing">
                {/* <div className="vhive_media_info_heading_spacing"> */}
                <div>
                  <h2>{channel?.channelName}</h2>
                  <hr></hr>
                </div>
                {/* <div className="vh_media_title_second_headers"> */}
                <div className="title_and_limit">
                  <h3>
                    {currentVideo?.videoTitle || currentVideo?.actualFileName}
                  </h3>
                  <div
                    onClick={() =>
                      handleCopy(
                        currentVideo?.videoTitle ||
                          currentVideo?.actualFileName ||
                          ""
                      )
                    }
                  >
                    <ContentCopy
                      className="pointer"
                      sx={{ width: "20px", height: "20px" }}
                    />
                  </div>
                </div>
                <div className="flex-wrap tags_wrapper">
                  {currentVideo?.tags.map((tag, idx) => {
                    return (
                      <span className="tags" key={idx}>
                        #{tag}
                      </span>
                    );
                  })}
                </div>
                {!!currentVideo?.horizontalPreviewImage && (
                  <div
                    onClick={() =>
                      handlePitchClick(currentVideo?.horizontalPreviewImage)
                    }
                    className="view_pitch_deck_wrapper"
                  >
                    <img
                      src="https://images.veehive.ai/webApp/forms.png"
                      width="30px"
                    />
                    <h4>View Attachment</h4>
                  </div>
                )}

                <p className="tags text-sm more_spacing_tags_posted_by">
                  Posted by {currentVideo?.userName}
                </p>
                {/* </div> */}
                {/* </div> */}
                {!!currentVideo?.attribute3 && currentVideo?.attribute3[0] && (
                  <div className="title_and_limit">
                    <h3>Description</h3>
                    <div
                      onClick={() =>
                        handleCopy(
                          sanitizeHtml(currentVideo?.attribute3[0], {
                            allowedTags: [],
                          })
                        )
                      }
                    >
                      <ContentCopy
                        className="pointer"
                        sx={{ width: "20px", height: "20px" }}
                      />
                    </div>
                  </div>
                )}
                {!!currentVideo?.attribute3 && currentVideo?.attribute3[0] && (
                  <div className="minus_margins_textbox video_view_desc_sizing">
                    {/* <h3 className="border_bottom">Description</h4> */}
                    {/* <LineBreak /> */}
                    <div>
                      <ReactQuill
                        className="react-quill"
                        readOnly
                        value={currentVideo?.attribute3[0]}
                        theme="bubble"
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
            {currentVideo?.sourceURL && (
              <>
                <div className="hr_padding_vw_container">
                  <hr className="hr_padding_vw"></hr>
                </div>
                <h3 className="heading_placement_comments">Comments</h3>
                <div className="video_comments_container">
                  <VideoComments />
                </div>
              </>
            )}
          </div>
        </div>
      </Box>
    </Modal>
  );
};

export default React.memo(VideoView);
