import LineBreak from "components/LineBreak";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import React, { ChangeEvent, useEffect, useState } from "react";
import ReactPlayer from "react-player";
import { useNavigate } from "react-router-dom";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.bubble.css";
import "../../Channel/ChannelVideo/EditVideo/react-quill.scss";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { CloseMediaSVG } from "components/SVG/SVG";
import {
  getAiIsLoadingSelector,
  getCurrentAiVideoSelector,
} from "store/reducers/Ai/ai";
import ChannelDropdown from "components/ChannelDropdown/ChannelDropdown";
import { publishToChannel } from "store/async-actions/Ai/ai";
import {
  getActiveChannelUuidSelector,
  setVideosTab,
} from "store/reducers/ChannelDetails/channelList";
import { getHiveUuid } from "store/reducers/HiveDetails/hiveDetails";
import TokenInput from "react-customize-token-input";
import "pages/UploadVideo/components/small_token_input.scss";
import { getProfileDetails } from "store/async-actions/Profile/profileAction";
import { profileDetailsSelector } from "store/reducers/Profile/profile";
import Loader from "components/common/Loader/Loader";
import Dialog from "@mui/material/Dialog";
import CircularProgress from "@mui/material/CircularProgress";

const style = {
  position: "absolute" as "absolute",
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  // bgcolor: "#ffffff",
  outline: "none",
};

const AiVideoView = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const currentVideo = useAppSelector(getCurrentAiVideoSelector);

  const [videoOpen, setVideoOpen] = useState(true);

  const handleVideoClose = () => {
    setVideoOpen(false);
  };

  const handleGoBack = () => {
    navigate("..");
  };

  useEffect(() => {
    dispatch(
      getProfileDetails({
        userId: +currentVideo?.user_id! || 0,
      })
    );
  }, [currentVideo]);

  const profileDetails = useAppSelector(profileDetailsSelector);

  useEffect(() => {
    currentVideo === null && navigate("..");
  }, []);

  const [title, setTitle] = useState("Title");

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setTitle(e.target.value.slice(0, 18));
  };

  const channelUuid = useAppSelector(getActiveChannelUuidSelector);
  const hiveUuid = useAppSelector(getHiveUuid);

  const [values, setValues] = useState(currentVideo?.content_tags || []);

  const [data, setData] = useState(currentVideo?.content_text || "");

  const isLoading = useAppSelector(getAiIsLoadingSelector);

  const handlePublish = () => {
    dispatch(
      publishToChannel({
        fileKey: currentVideo?.s3_path || "",
        sourceURL: currentVideo?.web_url || "",
        videoTitle: title,
        channelID: channelUuid || "",
        organisationID: hiveUuid,
        tags: values,
        attribute3: [data || ""],
      })
    );

    setTimeout(() => {
      navigate(`/channels/${channelUuid}`);
      dispatch(setVideosTab(1));
    }, 1500);
  };

  function handleTextBox(event: string) {
    setData(event);
  }

  return (
    <Modal
      open={videoOpen}
      onClose={() => {
        handleVideoClose();
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className="z-index-max">
        <div className="vhive-1qg2388-DivBrowserModeContainer e11s2kul0">
          <div onClick={handleGoBack} className="vhive_close_media_btn">
            <CloseMediaSVG />
          </div>
          <div className="vhive-1tunefa-DivVideoContainer e11s2kul26">
            <div className="vhive-7tjqm6-DivBlurBackground e11s2kul8">
              <div className="vhive-16ognrj-DivVideoWrapper e11s2kul9">
                <div className="vhive-1jxhpnd-DivContainer e1yey0rl0">
                  <div className="vhive-1h63bmc-DivBasicPlayerWrapper e1yey0rl2">
                    {/* <div></div> */}
                    <div className="ch-live-broadcast__main__live__media-container ch-live-broadcast__main__media-container">
                      <ReactPlayer
                        width="100%"
                        height="100%"
                        url={currentVideo?.web_url}
                        controls
                        style={{
                          overflow: "hidden",
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="vhive-3q30id-DivContentContainer e1mecfx00">
            <div className="vhive_media-info">
              <LineBreak />
              <div className="vhive_media_info_spacing">
                {/* <div className="vhive_media_info_heading_spacing"> */}
                <div>
                  <h2>{currentVideo?.service_id}</h2>
                  <hr></hr>
                </div>
                <p className="tags text-sm more_spacing_tags_posted_by">
                  Posted by {profileDetails?.userName || ""}
                </p>
                {/* <div className="vh_media_title_second_headers"> */}
                <div className="upload_info_margins upload_title_input">
                  <input
                    className="video_upload_title"
                    value={title}
                    onInput={handleChange}
                    placeholder="Untitled"
                  />
                  <div className="character_limit text-sm">
                    {title && title.length}/20
                  </div>
                </div>

                {!!currentVideo?.content_tags &&
                  currentVideo.content_tags.length > 0 && (
                    <div className="title_and_limit">
                      <h4 className="upload_headings">Tags</h4>
                      <div className="character_limit text-sm">*max 5 tags</div>
                    </div>
                  )}
                {!!currentVideo?.content_tags &&
                  currentVideo.content_tags.length > 0 && (
                    <TokenInput
                      separators={[" ", ",", ";"]}
                      tokenValues={values!.slice(0, 5)}
                      onTokenValuesChange={setValues}
                      className=""
                      placeholder={`Tags separated by commas`}
                    />
                  )}
                {/* </div> */}
                {/* </div> */}
                {!!currentVideo?.content_text && (
                  <div className="text_box_container h-100">
                    <h4 className="upload_headings">Description</h4>
                    <LineBreak />
                    <ReactQuill
                      className="text_box"
                      theme="snow"
                      value={data}
                      onChange={(e) => handleTextBox(e)}
                    />
                  </div>
                )}
              </div>
              <div className="hr_padding_vw_container"></div>
              <ChannelDropdown />
              <div className="hr_padding_vw_container"></div>
              <div onClick={handlePublish} className="primaryBtn">
                Publish
              </div>
            </div>
          </div>
        </div>
        <Dialog open={isLoading}>
          <div className="loader_padding">
            <CircularProgress size={30} color="inherit" />
          </div>
        </Dialog>
      </Box>
    </Modal>
  );
};

export default AiVideoView;
