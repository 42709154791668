import { TextField } from "@mui/material";
import LineBreak from "components/LineBreak";
import { useAppSelector } from "hooks/redux";
import React, { Dispatch, SetStateAction, useState } from "react";
import { getHiveSelector } from "store/reducers/HiveDetails/hiveDetails";

interface Props {
  handleChange: any;
  emailDetails: any;
  addCta: boolean;
  setAddCta: Dispatch<SetStateAction<boolean>>;
  readonly?: boolean;
}

const EmailTemplate = ({
  handleChange,
  emailDetails,
  addCta,
  setAddCta,
  readonly,
}: Props) => {
  const hiveDetails = useAppSelector(getHiveSelector);

  return (
    <>
      <div className="email-emp-border">
        <img
          src={hiveDetails?.communityWebLogo || ""}
          alt="hive_logo"
          className="email_logo"
        />
        <LineBreak />
        <input
          readOnly={readonly ? readonly : false}
          onChange={handleChange}
          value={emailDetails.greeting}
          className="h3 email_input"
          name="greeting"
        />
        <LineBreak />
        <textarea
          readOnly={readonly ? readonly : false}
          onChange={handleChange}
          className="empty_input"
          value={emailDetails.heading}
          name="heading"
        />
        <LineBreak />
        <textarea
          readOnly={readonly ? readonly : false}
          onChange={handleChange}
          className="empty_input"
          value={emailDetails.para1}
          name="para1"
        />
        <LineBreak />
        <textarea
          readOnly={readonly ? readonly : false}
          onChange={handleChange}
          className="empty_input"
          value={emailDetails.para2}
          name="para2"
        />
        <LineBreak />
        <textarea
          readOnly={readonly ? readonly : false}
          onChange={handleChange}
          className="empty_input"
          value={emailDetails.para3}
          name="para3"
        />
        <LineBreak />
        {addCta && (
          <input
            readOnly={readonly ? readonly : false}
            onChange={handleChange}
            value={emailDetails.ctaName}
            name="ctaName"
            className="h4 primaryBtn btn_email_input"
          />
        )}
        <LineBreak />
        <input
          readOnly={readonly ? readonly : false}
          onChange={handleChange}
          value={emailDetails.signOff}
          name="signOff"
          className="h4 email_input"
        />
        <input
          readOnly={readonly ? readonly : false}
          onChange={handleChange}
          value={emailDetails.signOffName}
          name="signOffName"
          className="h4 email_input"
        />
        <input
          readOnly={readonly ? readonly : false}
          onChange={handleChange}
          value={emailDetails.signOffDesignation}
          name="signOffDesignation"
          className="h4 email_input"
        />
      </div>

      <LineBreak />
      {!readonly && (
        <p
          onClick={() => {
            setAddCta((prevState) => !prevState);
          }}
          className="link"
        >
          {!addCta ? "Add " : "Remove "}CTA
        </p>
      )}
      <LineBreak />

      {!readonly && addCta && (
        <>
          <TextField
            variant="standard"
            onChange={handleChange}
            value={emailDetails.ctaName.slice(0, 11)}
            name="ctaName"
            label="CTA Name"
            placeholder="Enter name"
          />
          <LineBreak />
          <TextField
            fullWidth
            variant="standard"
            onChange={handleChange}
            value={emailDetails.ctaLink}
            name="ctaLink"
            label="CTA Link"
            placeholder="Enter link"
          />
        </>
      )}
    </>
  );
};

export default EmailTemplate;
