import FileDrop from "components/FileDrop/FileDrop";
import LineBreak from "components/LineBreak/LineBreak";
import { CheckmarkSVG } from "components/SVG/SVG";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import YoutubeUploadLink from "pages/UploadVideo/components/YoutubeUploadLink";
import React, { useEffect, useState } from "react";
import ReactPlayer from "react-player";
import YouTube from "react-youtube";
import { currentVideoSelector } from "store/reducers/Videos/videoList";
import {
  getYoutubeUrlSelector,
  removeVideoFile,
  s3Uploaded,
  uploadFileSelector,
} from "store/reducers/Videos/videos";

const EditVideoSelect = () => {
  const dispatch = useAppDispatch();

  const currentVideo = useAppSelector(currentVideoSelector);

  const [changeVideo, setChangeVideo] = useState(false);

  const uploadedVideoFile = useAppSelector(uploadFileSelector);

  const s3Done = useAppSelector(s3Uploaded);

  useEffect(() => {
    if (s3Done) {
      setChangeVideo(false);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [s3Done]);

  const handleChangeVideo = () => {
    setChangeVideo((prevState) => !prevState);
  };

  useEffect(() => {
    dispatch(removeVideoFile());
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function extractYoutubeId(url: string): string {
    const regex = /v=([^\&\?]+)/;
    const result = url.match(regex);

    return result ? result[1] : "";
  }

  const youtubeUrl = useAppSelector(getYoutubeUrlSelector);

  return (
    <div className="video_select_container">
      {changeVideo && !uploadedVideoFile ? (
        <>
          {currentVideo?.channelType === "YOUTUBE" &&
          currentVideo.horizontalVideoUrl ? (
            <YoutubeUploadLink url={currentVideo.horizontalVideoUrl} />
          ) : (
            <FileDrop />
          )}
        </>
      ) : (
        <div>
          {currentVideo?.channelType === "YOUTUBE" &&
          currentVideo.horizontalVideoUrl ? (
            <div>
              <YoutubeUploadLink url={currentVideo.horizontalVideoUrl} />
            </div>
          ) : (
            <ReactPlayer
              width="270px"
              height="480px"
              url={
                uploadedVideoFile
                  ? URL.createObjectURL(uploadedVideoFile)
                  : currentVideo?.sourceUrl
              }
              controls
              style={{
                overflow: "hidden",
                borderRadius: "15px",
                background: "black",
              }}
            />
          )}
        </div>
      )}
      {currentVideo?.channelType === "VIDEO" && (
        <>
          {changeVideo ? (
            <div className="video_change_status">
              <div
                onClick={handleChangeVideo}
                className="hover_underline pointer"
              >
                Cancel
              </div>
            </div>
          ) : (
            <div
              className="video_change_status"
              onClick={() => {
                dispatch(removeVideoFile());
              }}
            >
              <div className="video_change_start">
                <CheckmarkSVG />
                <div onClick={handleChangeVideo} className="hover_underline">
                  Change Video
                </div>
              </div>
              <p className="">
                {currentVideo?.videoTitle &&
                currentVideo?.videoTitle.length > 18
                  ? currentVideo?.videoTitle.slice(0, 18) + "..."
                  : currentVideo?.videoTitle}
              </p>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default EditVideoSelect;
