import IslandLayout from "components/IslandLayout";
import { useAppSelector } from "hooks/redux";
import React from "react";
import { getChannelListSelector } from "store/reducers/ChannelDetails/channelList";
import UploadInfo from "./EditUploadInfo";
import VideoSelect from "./EditVideoSelect";

const EditVideo = () => {
  const channelList = useAppSelector(getChannelListSelector);

  return (
    <>
      <div className="upload_container">
        {channelList.length > 1 && (
          <div className="upload_section">
            <IslandLayout>
              <div className="upload_info scrollable-y">
                <UploadInfo />
              </div>
            </IslandLayout>
          </div>
        )}
        <div className="upload_section">
          <IslandLayout>
            <div className="video_select_wrapper">
              <VideoSelect />
            </div>
          </IslandLayout>
        </div>
      </div>
      {/* <Dialog open={isUploading}>
        <div className="popup_dialogue"> */}
      {/* </div>
      </Dialog> */}
    </>
  );
};

export default EditVideo;
