import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { useEffect } from "react";
import {
  getStoryDetails,
  getStoryItem,
} from "store/async-actions/Story/storyActions";
import {
  getStoryItemSelector,
  setStartClicked,
  setViewingStoryUuid,
} from "store/reducers/Story/story";
import LineBreak from "components/LineBreak";

function StoryStart() {
  const location = useLocation();

  const urlParams = location.search.split("?")[1];

  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const storyItem = useAppSelector(getStoryItemSelector);

  const backgroundImage = storyItem?.thumbnailUrl
    ? `url(${storyItem.thumbnailUrl})`
    : "none";

  const handleClick = () => {
    navigate(`/viewstory/story/${urlParams}`);
    dispatch(setStartClicked(true));
  };

  useEffect(() => {
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      window.open(`${process.env.REACT_APP_STORIES_URL}${urlParams}`, "_self");
    }
  }, [navigator.userAgent]);

  useEffect(() => {
    if (urlParams) {
      dispatch(setViewingStoryUuid(urlParams));
      dispatch(getStoryDetails({ storyUuid: urlParams }));
      dispatch(getStoryItem({ storyUuid: urlParams }));
    }
  }, [dispatch, urlParams]);

  return (
    <div onClick={handleClick} className="home_wrap">
      <div
        className="black-screen"
        style={{
          backgroundImage,
        }}
      >
        <div className="overlay_screen"></div>
        <div className="story_click_btn">
          <h1>{storyItem?.storyTitle || "Dicover More"}</h1>
          <LineBreak />
          <h2>Click anywhere</h2>
        </div>
        {/* <button className="start-button">
          Start
        </button> */}
      </div>
    </div>
  );
}

export default StoryStart;
