import * as React from "react";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import {
  getHiveSelector,
  getHiveUuid,
} from "store/reducers/HiveDetails/hiveDetails";
import {
  addSuperAdmin,
  removeHiveMember,
} from "store/async-actions/Hive/hivePage";
import { removeChannelMember } from "store/async-actions/Channels/channels";
import { useState } from "react";
import ConfirmationModal from "components/Confirmation/Confirmation";
import { EventUserItem } from "api/models/Hive/events";
import { markAttended } from "store/async-actions/Hive/eventsActions";
import { getCurrentEventSelector } from "store/reducers/HiveDetails/events";

const ITEM_HEIGHT = 48;

interface Props {
  user: EventUserItem;
}

export default function EventPeopleKebab({ user }: Props) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const event = useAppSelector(getCurrentEventSelector);

  const dispatch = useAppDispatch();
  const hiveDetails = useAppSelector(getHiveSelector);

  const hiveUuid = useAppSelector(getHiveUuid);

  const handleSuperAdmin = () => {
    !!event &&
      dispatch(
        markAttended({
          organizationUuid: hiveUuid,
          profileId: user.profileId,
          eventUuid: event?.eventUuid || "",
        })
      );
    handleConfirmClose();
  };

  const [isOpen, setIsOpen] = useState(false);

  const handleConfirmClose = () => {
    handleClose();
    setIsOpen(false);
  };

  const handleConfirmOpen = () => {
    setIsOpen(true);
  };

  return (
    <div>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: "17ch",
          },
        }}
      >
        <MenuItem onClick={handleConfirmOpen}>
          <p className="text-sm">Mark Attended</p>
        </MenuItem>

        {isOpen && (
          <ConfirmationModal
            isOpen={isOpen}
            onReject={handleConfirmClose}
            onConfirm={handleSuperAdmin}
            headline="Mark as Attended"
            description={`Are you sure you want mark ${user.userName} as attended`}
            confirmMessage="Yes"
            rejectMessage="No"
          />
        )}
      </Menu>
    </div>
  );
}
