import IconButton from '@mui/material/IconButton'

import Button from 'components/common/Button'
import TrashIcon from 'components/common/svg/TrashIcon'
import { useParticipantsContext } from '../ParticipantsChatRoomContainer'

const SelectAllActions = () => {
  const {
    isOpenSelectAll,
    handleClickSelect,
    handleClickCancel,
    handleSelectAll,
    handleDeleteAllParticipants,
    selectedState,
    isParticipantsEmpty,
  } = useParticipantsContext()

  return (
    <div className="select_all_container">
      <Button
        variant="text"
        color="primary"
        onClick={handleClickSelect}
        disabled={isParticipantsEmpty}
        className={`select_button ${isOpenSelectAll ? 'showAll' : ''}`}
      >
        Select
      </Button>
      <span
        className={`select_all_buttons_container ${
          isOpenSelectAll ? 'showAll' : ''
        }`}
      >
        <Button
          variant="text"
          color="secondary"
          onClick={handleClickCancel}
          className="cancel_button"
        >
          Cancel
        </Button>
        <Button
          variant="text"
          color="primary"
          onClick={handleSelectAll}
          className="select_all_button"
        >
          Select All
        </Button>
        <IconButton
          className="delete_all_button"
          color="primary"
          onClick={handleDeleteAllParticipants}
          disabled={!selectedState.length}
        >
          <TrashIcon />
        </IconButton>
      </span>
    </div>
  )
}

export default SelectAllActions
