import AvatarGroup from '@mui/material/AvatarGroup'
import Collapse from '@mui/material/Collapse'

import { useParticipantsContext } from '../ParticipantsChatRoomContainer'
import TopAvatarListItem from './TopAvatarListItem'

const TopAvatarList = () => {
  const { selectedState } = useParticipantsContext()

  return (
    <Collapse
      in={!!selectedState.length}
      easing={{
        enter: 'linear',
      }}
      timeout={'auto'}
      exit={false}
    >
      <AvatarGroup className="top_avatar_list_container" max={5}>
        {selectedState.map((i) => (
          <TopAvatarListItem key={i} id={i} />
        ))}
      </AvatarGroup>
    </Collapse>
  )
}

export default TopAvatarList
