import { useEffect, useCallback } from "react";
import { useLocation } from "react-router-dom";

import clientApi from "api";
import { useAppSelector } from "./redux";
import {
  getEventRegisterTypeSelector,
  getHiveSelector,
} from "store/reducers/HiveDetails/hiveDetails";
import { useQuery } from "./useQuery";
import {
  getCurrentStoryItemSelector,
  getViewingStoryUuidSelector,
} from "store/reducers/Story/story";

export interface FetchParams {
  ctaName: string;
  pageName: string;
  organizationUuid?: string;
  currentStoryId?: number;
  segmentId?: string;
  request?: any;
  storyUuid?: string;
  origin?: string;
  redirect?: string;
}

const REF_KEY = "ref";
const REDIRECT = "rxurx";

const fetch = async (params: FetchParams) => {
  await clientApi.analytics.fetchCTALogs({
    sessionId: clientApi.sessionId,
    appName: "webApp_Desktop",
    domain: `${localStorage.getItem("subDomain")}.vhive.org`,
    ...params,
  });
};

export const useAnalytics = () => {
  const searchParams = useQuery();
  var ref = searchParams.get(REF_KEY);
  var redirect = searchParams.get(REDIRECT);
  const location = useLocation();
  const hiveDetails = useAppSelector(getHiveSelector);
  const currentStory = useAppSelector(getCurrentStoryItemSelector);
  const storyUuid = useAppSelector(getViewingStoryUuidSelector);

  const handleAnalyticsEvent = useCallback(
    async (e: any) => {
      if (hiveDetails) {
        await fetch({
          organizationUuid: hiveDetails?.communityUuid,
          ...e?.detail,
        });
        if (ref) {
          await fetch({
            organizationUuid: hiveDetails?.communityUuid,
            origin: ref,
            ...e?.detail,
          });
          ref = null;
        }
      }
    },
    [hiveDetails?.communityUuid]
  );

  useEffect(() => {
    window.addEventListener("analyticsEvent", handleAnalyticsEvent);
    return () => {
      window.removeEventListener("analyticsEvent", handleAnalyticsEvent);
    };
  }, [handleAnalyticsEvent]);

  useEffect(() => {
    if (hiveDetails) {
      window.dispatchEvent(
        new CustomEvent<FetchParams>("analyticsEvent", {
          detail: {
            ctaName: "changePage",
            pageName: location.pathname.slice(1),
            organizationUuid: hiveDetails.communityUuid,
          },
        })
      );
    }
  }, [location.pathname, hiveDetails?.communityUuid]);

  useEffect(() => {
    if (redirect && ref) {
      window.dispatchEvent(
        new CustomEvent<FetchParams>("analyticsEvent", {
          detail: {
            ctaName: "redirectUrl",
            pageName: location.pathname.slice(1),
            redirect: redirect,
            origin: ref,
          },
        })
      );
    }
  }, [location.pathname, redirect]);

  useEffect(() => {
    window.dispatchEvent(
      new CustomEvent<FetchParams>("analyticsEvent", {
        detail: {
          ctaName: "changeStory",
          pageName: location.pathname.slice(1),
          currentStoryId: currentStory?.storyItemId,
          request: currentStory?.storyItemId,
          storyUuid: storyUuid,
          origin: ref || "",
          // organizationUuid: hiveDetails.communityUuid,
        },
      })
    );
  }, [currentStory]);

  useEffect(() => {
    window.dispatchEvent(
      new CustomEvent<FetchParams>("analyticsEvent", {
        detail: {
          ctaName: "changeStory",
          pageName: location.pathname.slice(1),
          currentStoryId: currentStory?.storyItemId,
          request: currentStory?.storyItemId,
          storyUuid: storyUuid,
          origin: ref || "",
          // organizationUuid: hiveDetails.communityUuid,
        },
      })
    );
  }, [currentStory]);

  useEffect(() => {
    window.dispatchEvent(
      new CustomEvent<FetchParams>("analyticsEvent", {
        detail: {
          ctaName: "changeStory",
          pageName: location.pathname.slice(1),
          currentStoryId: currentStory?.storyItemId,
          request: currentStory?.storyItemId,
          storyUuid: storyUuid,
          origin: ref || "",
          // organizationUuid: hiveDetails.communityUuid,
        },
      })
    );
  }, [currentStory]);

  const registerVal = useAppSelector(getEventRegisterTypeSelector);

  useEffect(() => {
    window.dispatchEvent(
      new CustomEvent<FetchParams>("analyticsEvent", {
        detail: {
          ctaName:
            registerVal === 1
              ? "Yes"
              : registerVal === 2
              ? "No"
              : registerVal === 3
              ? "Maybe"
              : "Not Clicked",
          pageName: location.pathname.slice(1),
        },
      })
    );
  }, [registerVal]);
};
