import Lottie from "lottie-react";
import React from "react";
import maintenanceJson from "json/maintenanceJson.json";

const MaintenancePage = () => {
  return (
    <div className="maintenance_page">
      <div className="lottie_holder">
        <Lottie
          // width={100}
          // height={100}
          animationData={maintenanceJson}
          loop={true}
        ></Lottie>
      </div>
      <h2 className="maintenance_header">
        Server pit stop! 🛠️ We'll be back in action real soon. Thanks for your
        patience!
      </h2>
    </div>
  );
};

export default MaintenancePage;
