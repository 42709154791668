import MainBody from "components/MainBody";
import Navbar from "components/Navbar";
import { useAnalytics } from "hooks/useAnalytics";
import React from "react";
import * as Scroll from "react-scroll";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../ReactToastify/react-toastify.scss";
import ChatBot from "components/ChatBot/ChatBot";
import { useAppSelector } from "hooks/redux";
import { getHiveSelector } from "store/reducers/HiveDetails/hiveDetails";
import PaymentDue from "components/PaymentDue";
import { getCurrentPlanSelector } from "store/reducers/Subscriptions/subscriptions";
import { getSubscriptionsTabSelector } from "store/reducers/HiveDetails/mainMenu";

const MainLayout = () => {
  var Element = Scroll.Element;
  useAnalytics();

  const hiveDetails = useAppSelector(getHiveSelector);

  const plan = useAppSelector(getCurrentPlanSelector);
  const subscription = useAppSelector(getSubscriptionsTabSelector);

  return (
    <div className="main_container">
      {/* <div className="payment_due_main_container">
        {!!subscription &&
          subscription.length > 0 &&
          localStorage.getItem("isLoggedIn") === "yes" &&
          plan?.isPaymentDue === true && <PaymentDue />}
      </div> */}
      {hiveDetails?.chatSupportEnabled && (
        <div className="chat_bot_container">
          <ChatBot />
        </div>
      )}
      <Navbar />
      <Element name="top_section"></Element>
      <div className="layout_container">
        <div className="main_body_container">
          <MainBody />
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default React.memo(MainLayout);
