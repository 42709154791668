import { useAppDispatch, useAppSelector } from "hooks/redux";
import React, { useState } from "react";
import { getLtsRespSelector, setOpenDownloads } from "store/reducers/Ai/ai";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import BackButton from "components/BackButton";
import LineBreak from "components/LineBreak/LineBreak";
import { profileUserIdSelector } from "store/reducers/Profile/profile";
import { useNavigate } from "react-router-dom";
import { compileTopics } from "store/async-actions/Ai/ai";

const CompileTopics = () => {
  const topics = useAppSelector(getLtsRespSelector);

  const userId = useAppSelector(profileUserIdSelector);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleCompile = () => {
    const obj = {
      file_uid: topics?.file_uid,
      file: topics?.file,
      timestamps: timeStamp,
      user_topics: userTopics,
    };
    dispatch(
      compileTopics({
        obj: {
          file_uid: topics?.file_uid!,
          file: topics?.file!,
          timestamps: timeStamp,
          user_topics: userTopics,
        },
        userId,
      })
    );
    navigate("..");
    dispatch(setOpenDownloads(true));
  };

  const [timeStamp, setTimeStamp] = useState<
    {
      end_ts: number;
      start_ts: number;
    }[]
  >([]);
  const [userTopics, setUserTopics] = useState<number[]>([]);

  const handleTopicSelect = (
    timestamp: { end_ts: number; start_ts: number },
    user_topics: number,
    checked: boolean
  ) => {
    if (checked) {
      timeStamp.push(timestamp);
      userTopics.push(user_topics);
    }
    if (!checked) {
      setTimeStamp(timeStamp.filter((element) => element !== timestamp));
      setUserTopics(userTopics.filter((element) => element !== user_topics));
    }
  };

  return (
    <>
      {!!topics && (
        <div className="compile_topics_container">
          <LineBreak />
          <div className="compile_topics_header_spacing">
            <BackButton to="/ai/longToShort" />
          </div>
          <LineBreak />
          <h3 className="tags">Topics</h3>
          <LineBreak />
          {topics.topics.map((topic, idx) => {
            return (
              <div className="topic_form_border">
                <FormControl fullWidth component="fieldset">
                  <FormGroup
                    className="w_full form_group_compile"
                    aria-label="position"
                    row
                  >
                    <FormControlLabel
                      className="w_full items-center"
                      value="start"
                      control={
                        <Checkbox
                          onChange={(e) =>
                            handleTopicSelect(
                              topics.timestamps[idx],
                              idx,
                              e.target.checked
                            )
                          }
                        />
                      }
                      label={
                        <div className="">
                          <p>{topic.para.slice(0, 100)}</p>
                        </div>
                      }
                      labelPlacement="start"
                    />
                  </FormGroup>
                </FormControl>
              </div>
            );
          })}
          {/* {uploadedFile ? ( */}
          <div onClick={handleCompile} className="nextBtn primaryBtn">
            Create Teaser
          </div>
          {/* ) : (
             <div className="nextBtn disabledBtn">Create Teaser</div>
           )} */}
        </div>
      )}
    </>
  );
};

export default CompileTopics;
