import React from 'react'

import Text from 'components/common/Text'

interface CountSizeParticipantsProps {
  count: string | number
}

const CountSizeParticipants: React.FC<CountSizeParticipantsProps> = ({
  count,
}) => {
  return (
    <Text fontWeight="w500" className="participants_count_text">
      Participants: {count}
    </Text>
  )
}

export default CountSizeParticipants
