import PageLayout from "components/PageLayout/PageLayout";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";
import { getChildComponents } from "store/async-actions/Hive/hivePage";
import {
  getHiveSelector,
  launchLogin,
} from "store/reducers/HiveDetails/hiveDetails";
import { isMemberViewSelector } from "store/reducers/Profile/profile";

const EventsContainer = () => {
  const dispatch = useAppDispatch();

  const hiveDetails = useAppSelector(getHiveSelector);

  const isMember = useAppSelector(isMemberViewSelector);

  useEffect(() => {
    hiveDetails &&
      dispatch(
        getChildComponents({
          organizationId: hiveDetails.communityId,
          isMemberView: isMember,
          parentComponentCode: "Events",
          channelUuid: "",
        })
      );
  }, [dispatch]);

  return (
    <PageLayout sideMenu={true} rightMenu={false}>
      <Outlet />
    </PageLayout>
  );
};

export default EventsContainer;
