import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "api";
import {
  CtaNameReq,
  CtaPageReq,
  ProductDetailsRequest,
  RevenueGraphRequest,
  UserActionsRequest,
  VisitorsRequest,
} from "api/models/Analytics/ctaModel";
import {
  MetricsRequestModel,
  RedirectLinkReq,
  UsageRequestModel,
} from "api/models/Analytics/metrics";
import { UniqueSessionsRequest } from "api/models/Analytics/sessions";

export const getVideoCount = createAsyncThunk(
  "analytics/getVideoCount",
  async (body: MetricsRequestModel, { rejectWithValue }) => {
    try {
      const { data } = await api.analytics.getVideosCount(body);
      return data.data;
    } catch (error) {
      return rejectWithValue("Something went wrong");
    }
  }
);
export const getUsersCount = createAsyncThunk(
  "analytics/getUsersCount",
  async (body: MetricsRequestModel, { rejectWithValue }) => {
    try {
      const { data } = await api.analytics.getUsersCount(body);
      return data.data;
    } catch (error) {
      return rejectWithValue("Something went wrong");
    }
  }
);
export const getChannelsCount = createAsyncThunk(
  "analytics/getChannelsCount",
  async (body: MetricsRequestModel, { rejectWithValue }) => {
    try {
      const { data } = await api.analytics.getChannelsCount(body);
      return data.data;
    } catch (error) {
      return rejectWithValue("Something went wrong");
    }
  }
);

export const getUsageData = createAsyncThunk(
  "analytics/getUsageData",
  async (body: UsageRequestModel, { rejectWithValue }) => {
    try {
      const { data } = await api.analytics.getUsageData(body);
      return data.data;
    } catch (error) {
      return rejectWithValue("Something went wrong");
    }
  }
);

export const getRedirectLinkList = createAsyncThunk(
  "analytics/getRedirectLinkList",
  async (body: RedirectLinkReq, { rejectWithValue }) => {
    try {
      const { data } = await api.analytics.getRedirectLinkList(body);
      return data.data;
    } catch (error) {
      return rejectWithValue("Something went wrong");
    }
  }
);

export const getRedirectOriginList = createAsyncThunk(
  "analytics/getRedirectOriginList",
  async (body: RedirectLinkReq, { rejectWithValue }) => {
    try {
      const { data } = await api.analytics.getRedirectOriginList(body);
      return data.data;
    } catch (error) {
      return rejectWithValue("Something went wrong");
    }
  }
);

export const getRedirectLocationList = createAsyncThunk(
  "analytics/getRedirectLocationList",
  async (body: RedirectLinkReq, { rejectWithValue }) => {
    try {
      const { data } = await api.analytics.getRedirectLocationList(body);
      return data.data;
    } catch (error) {
      return rejectWithValue("Something went wrong");
    }
  }
);

export const getRedirectDayWiseList = createAsyncThunk(
  "analytics/getRedirectDayWiseList",
  async (body: RedirectLinkReq, { rejectWithValue }) => {
    try {
      const { data } = await api.analytics.getRedirectDayWiseList(body);
      return data.data;
    } catch (error) {
      return rejectWithValue("Something went wrong");
    }
  }
);

export const getSentimentAnalysis = createAsyncThunk(
  "analytics/getSentimentAnalysis",
  async (body: { chatroomId: string }, { rejectWithValue }) => {
    try {
      const { data } = await api.analytics.getSentimentAnalysis(body);
      return data.data;
    } catch (error) {
      return rejectWithValue("Something went wrong");
    }
  }
);

export const getWordCloud = createAsyncThunk(
  "analytics/getWordCloud",
  async (body: { chatroomId: string }, { rejectWithValue }) => {
    try {
      const { data } = await api.analytics.getWordCloud(body);
      return data.data;
    } catch (error) {
      return rejectWithValue("Something went wrong");
    }
  }
);

export const getUniqueSessions = createAsyncThunk(
  "analytics/getUniqueSessions",
  async (body: UniqueSessionsRequest, { rejectWithValue }) => {
    try {
      const { data } = await api.analytics.getUniqueSessions(body);
      return data.data;
    } catch (error) {
      return rejectWithValue("Something went wrong");
    }
  }
);

export const getVisitorsData = createAsyncThunk(
  "analytics/getVisitorsData",
  async (body: VisitorsRequest, { rejectWithValue }) => {
    try {
      const { data } = await api.analytics.getVisitorsData(body);
      return data.data;
    } catch (error) {
      return rejectWithValue("Something went wrong");
    }
  }
);

export const getProductsCount = createAsyncThunk(
  "analytics/getProductsCount",
  async (body: VisitorsRequest, { rejectWithValue }) => {
    try {
      const { data } = await api.analytics.getProductsCount(body);
      return data.data;
    } catch (error) {
      return rejectWithValue("Something went wrong");
    }
  }
);

export const getProductDetails = createAsyncThunk(
  "analytics/getProductDetails",
  async (body: ProductDetailsRequest, { rejectWithValue }) => {
    try {
      const { data } = await api.analytics.getProductDetails(body);
      return data.data;
    } catch (error) {
      return rejectWithValue("Something went wrong");
    }
  }
);

export const getUserActions = createAsyncThunk(
  "analytics/getUserActions",
  async (body: UserActionsRequest, { rejectWithValue }) => {
    try {
      const { data } = await api.analytics.getUserActions(body);
      return data.data;
    } catch (error) {
      return rejectWithValue("Something went wrong");
    }
  }
);

export const getHiveRevenue = createAsyncThunk(
  "analytics/getHiveRevenue",
  async (body: { organizationId: number }, { rejectWithValue }) => {
    try {
      const { data } = await api.analytics.getHiveRevenue(body);
      return data.data;
    } catch (error) {
      return rejectWithValue("Something went wrong");
    }
  }
);

export const getPurchaseChannelList = createAsyncThunk(
  "analytics/getPurchaseChannelList",
  async (body: { organizationId: number }, { rejectWithValue }) => {
    try {
      const { data } = await api.analytics.getPurchaseChannelList(body);
      return data.data;
    } catch (error) {
      return rejectWithValue("Something went wrong");
    }
  }
);

export const getPurchaseDetailList = createAsyncThunk(
  "analytics/getPurchaseDetailList",
  async (body: { channelId: number }, { rejectWithValue }) => {
    try {
      const { data } = await api.analytics.getPurchaseDetailList(body);
      return data.data;
    } catch (error) {
      return rejectWithValue("Something went wrong");
    }
  }
);

export const getAnalyticsChannelMetrics = createAsyncThunk(
  "analytics/getAnalyticsChannelMetrics",
  async (body: { channelId: number }, { rejectWithValue }) => {
    try {
      const { data } = await api.analytics.getAnalyticsChannelMetrics(body);
      return data.data;
    } catch (error) {
      return rejectWithValue("Something went wrong");
    }
  }
);

export const getStoryViews = createAsyncThunk(
  "analytics/getStoryViews",
  async (body: { storyUuid: string }, { rejectWithValue }) => {
    try {
      const { data } = await api.analytics.getStoryViews(body);
      return data.data;
    } catch (error) {
      return rejectWithValue("Something went wrong");
    }
  }
);

export const getSegmentViews = createAsyncThunk(
  "analytics/getSegmentViews",
  async (
    body: { storyUuid: string; segmentId: number },
    { rejectWithValue }
  ) => {
    try {
      const { data } = await api.analytics.getSegmentViews(body);
      return data.data;
    } catch (error) {
      return rejectWithValue("Something went wrong");
    }
  }
);

export const getStoryReactions = createAsyncThunk(
  "analytics/getStoryReactions",
  async (body: { storyUuid: string }, { rejectWithValue }) => {
    try {
      const { data } = await api.analytics.getStoryReactions(body);
      return data.data;
    } catch (error) {
      return rejectWithValue("Something went wrong");
    }
  }
);

export const getSegmentReactions = createAsyncThunk(
  "analytics/getSegmentReactions",
  async (
    body: { storyUuid: string; segmentId: number },
    { rejectWithValue }
  ) => {
    try {
      const { data } = await api.analytics.getSegmentReactions(body);
      return data.data;
    } catch (error) {
      return rejectWithValue("Something went wrong");
    }
  }
);

export const getHiveRevenueGraph = createAsyncThunk(
  "analytics/getHiveRevenueGraph",
  async (body: RevenueGraphRequest, { rejectWithValue }) => {
    try {
      const { data } = await api.analytics.getHiveRevenueGraph(body);
      return data.data;
    } catch (error) {
      return rejectWithValue("Something went wrong");
    }
  }
);

export const getCtaNameDetails = createAsyncThunk(
  "analytics/getCtaNameDetails",
  async (body: CtaNameReq, { rejectWithValue }) => {
    try {
      const { data } = await api.analytics.getCtaNameDetails(body);
      return data.data;
    } catch (error) {
      return rejectWithValue("Something went wrong");
    }
  }
);

export const getYesCtaDetails = createAsyncThunk(
  "analytics/getYesCtaDetails",
  async (body: CtaNameReq, { rejectWithValue }) => {
    try {
      const { data } = await api.analytics.getCtaNameDetails(body);
      return data.data;
    } catch (error) {
      return rejectWithValue("Something went wrong");
    }
  }
);

export const getNoCtaDetails = createAsyncThunk(
  "analytics/getNoCtaDetails",
  async (body: CtaNameReq, { rejectWithValue }) => {
    try {
      const { data } = await api.analytics.getCtaNameDetails(body);
      return data.data;
    } catch (error) {
      return rejectWithValue("Something went wrong");
    }
  }
);

export const getMaybeCtaDetails = createAsyncThunk(
  "analytics/getMaybeCtaDetails",
  async (body: CtaNameReq, { rejectWithValue }) => {
    try {
      const { data } = await api.analytics.getCtaNameDetails(body);
      return data.data;
    } catch (error) {
      return rejectWithValue("Something went wrong");
    }
  }
);

export const getPageNameDetails = createAsyncThunk(
  "analytics/getPageNameDetails",
  async (body: CtaPageReq, { rejectWithValue }) => {
    try {
      const { data } = await api.analytics.getPageNameDetails(body);
      return data.data;
    } catch (error) {
      return rejectWithValue("Something went wrong");
    }
  }
);
