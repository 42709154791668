import React, { useEffect, useState } from "react";
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
} from "@stripe/react-stripe-js";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { getCartListSelector } from "store/reducers/ChannelDetails/payments";
import { useNavigate } from "react-router-dom";
import { stripePayment } from "store/async-actions/Subscriptions/subscriptions";
import { getHiveSelector } from "store/reducers/HiveDetails/hiveDetails";
import { getPlanDetailsSelector } from "store/reducers/Subscriptions/subscriptions";
import { stripeChargeEvent } from "store/async-actions/Hive/eventsActions";
import {
  getCurrentEventSelector,
  getEventFormDetailsSelector,
  getEventsPromoSelector,
} from "store/reducers/HiveDetails/events";

interface Props {
  setOpenStripe: any;
}

const StripeEventCheckout = ({ setOpenStripe }: Props) => {
  const stripe = useStripe();
  const elements = useElements();

  const dispatch = useAppDispatch();
  const hiveDetails = useAppSelector(getHiveSelector);
  const cartListResp = useAppSelector(getCartListSelector);

  const currentEvent = useAppSelector(getCurrentEventSelector);

  const formDetails = useAppSelector(getEventFormDetailsSelector);

  useEffect(() => {
    var card = elements?.create("card");
    card?.mount("#card-element");
  }, []);

  const [errorMessage, setErrorMessage] = useState("");

  const promoResp = useAppSelector(getEventsPromoSelector);

  const handleSubmit = async (event: { preventDefault: () => void }) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      console.log("exited");
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    stripe
      .createToken(elements.getElement("cardNumber")!)
      .then(function (result) {
        if (result.error) {
          setErrorMessage(result.error.message!);
        } else {
          hiveDetails &&
            currentEvent &&
            dispatch(
              stripeChargeEvent({
                eventUuid: currentEvent?.eventUuid || "",
                token: result.token.id,
                formDetails: !!formDetails.length ? formDetails : null,
                promoCode: promoResp?.promoCode,
              })
            );
          setOpenStripe(false);
        }
      });
  };

  // const paymentElement = elements?.create("payment");
  // paymentElement?.mount("#payment-element")

  const navigate = useNavigate();

  return (
    <form onSubmit={handleSubmit} className="stripe_plan_payment_container">
      <div className="stripe_plan_header">
        <h4>Enter Card Details</h4>
        <img
          alt="credit-logos"
          width="120px"
          src="https://veehivestage-images.s3.us-east-2.amazonaws.com/webApp/credit-card-logos.png"
        />
      </div>
      <div className="card_input_element">
        <CardNumberElement
          className="input_border input_padding full-width --r-border10"
          options={{
            style: {
              base: {
                backgroundColor: "#fff",
                padding: "12px",
                fontSize: "16px",
                lineHeight: "40px",
              },
            },
          }}
        ></CardNumberElement>
        <h3 className="bold_xs_text">Enter card number*</h3>
      </div>
      <div className="card_input_element">
        <CardExpiryElement
          className="input_border input_padding full-width --r-border10"
          options={{
            style: {
              base: {
                backgroundColor: "#fff",
                padding: "12px",
                fontSize: "16px",
                lineHeight: "40px",
              },
            },
          }}
        ></CardExpiryElement>
        <h3 className="bold_xs_text">Enter expiry date*</h3>
      </div>
      <div className="card_input_element">
        <CardCvcElement
          className="input_border input_padding full-width --r-border10"
          options={{
            style: {
              base: {
                backgroundColor: "#fff",
                padding: "12px",
                fontSize: "16px",
                lineHeight: "40px",
              },
            },
          }}
        ></CardCvcElement>
        <h3 className="bold_xs_text">CVC*</h3>
      </div>
      {/* <PaymentElement id="payment-element" /> */}
      <div className="stripeBtns">
        <button disabled={!stripe} className="primaryBtn stripeBtn">
          Pay Securely
        </button>
        <div
          onClick={() => {
            navigate(`/events/${currentEvent?.eventIdentifier || ""}`);
          }}
          className="secondaryBtn stripeBtn"
        >
          Cancel
        </div>
      </div>
      {errorMessage && <div className="">{errorMessage}</div>}
    </form>
  );
};

export default StripeEventCheckout;
