import LineBreak from "components/LineBreak";
import React, { useState, useEffect, ChangeEvent } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import {
  getActiveChannelSelector,
  getActiveChannelUuidSelector,
  setVideosTab,
} from "store/reducers/ChannelDetails/channelList";
import {
  YTUploadContent,
  uploadContent,
} from "store/async-actions/Videos/videosActions";
import {
  getIsYTUploadSelector,
  getYoutubeUrlSelector,
  s3Uploaded,
  setIsUploading,
  setIsYTUpload,
  setThumbnail,
  setVideoFile,
  setYoutubeUrl,
  thumbnailSelector,
} from "store/reducers/Videos/videos";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import TokenInput from "react-customize-token-input";
import "pages/UploadVideo/components/small_token_input.scss";

import hiveDetails, {
  getHiveSelector,
  getHiveUuid,
} from "store/reducers/HiveDetails/hiveDetails";
import { getChannelDetails } from "store/async-actions/Channels/channels";
import "./react-quill.scss";
import ChannelDropdown from "components/ChannelDropdown/ChannelDropdown";
import { toastError, toastSuccess } from "hooks/useToastify";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from "@mui/material";
import ShoppableLinkEdit from "components/ShoppableLinkEdit/ShoppableLinkEdit";
import {
  getLogoFileUrlSelector,
  setLogoFile,
} from "store/reducers/CreateChannel/createChannel";
import ImageDropzone from "components/ImageDropzone";
import SpotDropzone from "components/SpotDropzone";

const UploadInfo = () => {
  const channelUuid = useAppSelector(getActiveChannelUuidSelector);
  const dispatch = useAppDispatch();
  const s3Done = useAppSelector(s3Uploaded);
  const thumbnail = useAppSelector(thumbnailSelector);
  const activeChannel = useAppSelector(getActiveChannelSelector);
  const thumbnailBase64 = useAppSelector(getLogoFileUrlSelector);

  const navigate = useNavigate();

  const hiveUuid = useAppSelector(getHiveUuid);

  useEffect(() => {
    if (s3Done) {
      dispatch(setIsUploading(true));
      dispatch(getChannelDetails(activeChannel?.channelUuid!));
      navigate(`/channels/${channelUuid}`);
      dispatch(setVideosTab(1));
      dispatch(setIsUploading(false));
    }
  }, [s3Done]);

  const isYoutube = useAppSelector(getIsYTUploadSelector);

  const youtubeUrl = useAppSelector(getYoutubeUrlSelector);

  const handleYoutubeVideoUpload = () => {
    dispatch(
      YTUploadContent({
        channelID: channelUuid || "",
        previewImage: thumbnail,
        channelType: "YOUTUBE",
        attribute3: [data],
        attribute2: [],
        attribute1: [],
        receipeName: videoTitle,
        itemDescription: null,
        tags: values.slice(0, 5),
        productUrl: youtubeUrl,
        thumbnail: thumbnailBase64,
        name: videoTitle,
        sourceURL: "",
        communityId: hiveDetails?.communityId || 0,
      })
    );
    dispatch(setIsUploading(true));

    setTimeout(() => {
      toastSuccess("Youtube Video Uploaded Successfully");
      dispatch(getChannelDetails(activeChannel?.channelUuid!));
      dispatch(setYoutubeUrl(""));
      dispatch(setIsYTUpload(0));
      dispatch(setVideoFile(null));
      dispatch(setLogoFile(""));
      navigate(`/channels/${channelUuid}`);
      dispatch(setVideosTab(1));
      dispatch(setIsUploading(false));
    }, 2000);
  };

  const hiveDetails = useAppSelector(getHiveSelector);

  const handleVideoUpload = async () => {
    isYoutube === 1
      ? handleYoutubeVideoUpload()
      : await dispatch(
          uploadContent({
            organisationID: hiveUuid,
            channelID: channelUuid || "",
            thumbNailBase64: thumbnail,
            previewImage:
              thumbnailBase64.length > 0 ? thumbnailBase64 : thumbnail,
            userId: "1324",
            name: videoTitle,
            channelType: "VIDEO",
            attribute5: [],
            attribute4: [],
            attribute3: [data],
            attribute2: [],
            attribute1: [],
            receipeName: videoTitle,
            itemDescription: null,
            tags: values.slice(0, 5),
            productUrl: productUrl,
            communityId: hiveDetails?.communityId || 0,
            pitchDeckFile: base64Url,
            pitchDeckFileType: fileType,
          })
        );
  };

  const [data, setData] = useState("");

  function handleTextBox(event: string) {
    setData(event);
  }

  const [videoTitle, setVideoTitle] = useState("");
  const [titleRemaining, setTitleRemaining] = useState(20);

  const handleTitleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setVideoTitle(e.target.value.slice(0, 20));
    setTitleRemaining(20 - e.target.value.slice(0, 20).length);
  };

  const [productUrl, setProductUrl] = useState("");

  const handleUrlChange = (e: ChangeEvent<HTMLInputElement>) => {
    setProductUrl(e.target.value.slice(0, 180));
  };

  const [values, setValues] = useState([]);

  const handleError = () => {
    if (videoTitle.length < 1) {
      toastError("Please enter video title");
    }
  };

  const [changeThumbnail, setChangeThumbnail] = useState(false);

  const handleThumbnailChange = () => {
    setChangeThumbnail((prevState) => !prevState);
  };

  const [base64Url, setBase64Url] = useState("");
  const handlePitchClick = (url: string) => {
    window.open(url, "_blank", "noreferrer");
  };

  const [removeFile, setRemoveFile] = useState(false);

  const [fileType, setFileType] = useState("");

  useEffect(() => {
    dispatch(setThumbnail(""));
  }, []);

  return (
    <div className="upload_info_container">
      <div className="upload_info_margins">
        <h3>Upload Video</h3>
        <p className="text-sm">Post a video to this channel</p>
      </div>
      <div className="thumbnail_edit_container">
        <h5 className="upload_headings">Cover photo</h5>
        {changeThumbnail ? (
          <ImageDropzone file="story" />
        ) : thumbnail ? (
          <img
            className="thumbnail_photo"
            src={`data:image/jpeg;base64,${thumbnail}`}
          />
        ) : (
          <ImageDropzone file="story" />
        )}
        <p onClick={handleThumbnailChange} className="link text-sm">
          {changeThumbnail ? "Cancel" : "Edit Thumbnail"}
        </p>
      </div>
      {/* <div> */}
      <div className="upload_info__content_spacing">
        <ChannelDropdown />
      </div>
      <h5 className="upload_headings">Title</h5>
      <div className="upload_info_margins upload_title_input">
        <input
          className={`${
            videoTitle.length > 0 ? "video_upload_title" : "video_title_empty"
          }`}
          value={videoTitle}
          onInput={handleTitleChange}
          placeholder="Untitled"
        />
        <div className="character_limit text-sm">{videoTitle.length}/20</div>
      </div>
      <div className="upload_info_margins">
        <div className="title_and_limit">
          <h4 className="upload_headings">Tags</h4>
          <div className="character_limit text-sm">*max 5 tags</div>
        </div>{" "}
        <TokenInput
          separators={[" ", ",", ";"]}
          tokenValues={values.slice(0, 5)}
          onTokenValuesChange={setValues}
          className=""
          placeholder={`Tags separated by commas`}
        />
      </div>
      <div className="">
        <h4 className="upload_headings">Description</h4>
        <LineBreak />
        <div className="react-quill-text-container">
          <ReactQuill
            className="text_box"
            theme="snow"
            value={data}
            onChange={(e) => handleTextBox(e)}
          />
          {/* <div className="react-quill-text-limit character_limit text-sm">
            {removeHtmlTags(data).length}/3000
          </div> */}
        </div>
      </div>

      <div className="">
        <div className="title_and_limit">
          <h4 className="upload_headings">Attachment</h4>
          {base64Url && (
            <p
              className="link text_xs"
              onClick={() => {
                setBase64Url("");
                setRemoveFile(false);
              }}
            >
              Cancel
            </p>
          )}
        </div>
        <LineBreak />
        {!removeFile && base64Url ? (
          <div
            onClick={() => handlePitchClick(productUrl)}
            className="view_pitch_deck_wrapper"
          >
            <img
              src="https://images.veehive.ai/webApp/forms.png"
              width="30px"
            />
            <h4>View Attachment</h4>
          </div>
        ) : (
          <SpotDropzone
            base64Url={base64Url}
            setBase64Url={setBase64Url}
            setFileType={setFileType}
          />
        )}
      </div>

      <LineBreak />

      <ShoppableLinkEdit
        productUrl={productUrl}
        handleUrlChange={handleUrlChange}
      />

      {/* </div> */}
      <div className="button_rows-edit_video">
        <NavLink to=".." className="secondaryBtn half_width">
          Discard
        </NavLink>
        {(thumbnail || isYoutube === 1) && videoTitle.length > 1 ? (
          <div onClick={handleVideoUpload} className="primaryBtn half_width">
            Post
          </div>
        ) : (
          <div onClick={handleError} className="disabledBtn half_width">
            Post
          </div>
        )}
      </div>
    </div>
  );
};

export default UploadInfo;
