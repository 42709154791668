import { useAppDispatch, useAppSelector } from "hooks/redux";
import React, { useEffect, ChangeEvent } from "react";
import ReactPlayer from "react-player";
import {
  editStorySegments,
  editStorySegmentsBg,
} from "store/async-actions/Story/storyActions";
import { getHiveUuid } from "store/reducers/HiveDetails/hiveDetails";
import {
  getStorySegmentsSelector,
  setStoryTitle,
  setStoryDesc,
  getCurrentStoryIndexSelector,
  getCurrentStoryIdSelector,
  getStoryTitleSelector,
  getStoryDescSelector,
  setActionLink,
} from "store/reducers/Story/story";

const StoryDisplay = () => {
  const storySegments = useAppSelector(getStorySegmentsSelector);
  const dispatch = useAppDispatch();

  const currentStoryIdx = useAppSelector(getCurrentStoryIndexSelector);
  const currentStoryId = useAppSelector(getCurrentStoryIdSelector);

  const title = useAppSelector(getStoryTitleSelector);
  const description = useAppSelector(getStoryDescSelector);

  const hiveUuid = useAppSelector(getHiveUuid);

  useEffect(() => {
    if (storySegments[currentStoryIdx]) {
      dispatch(setStoryTitle(storySegments[currentStoryIdx].title));
      dispatch(setStoryDesc(storySegments[currentStoryIdx].description));
      dispatch(setActionLink(storySegments[currentStoryIdx].actionLink || ""));
    }
  }, [currentStoryIdx, currentStoryId]);

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.target.name === "title" &&
      dispatch(setStoryTitle(e.target.value.slice(0, 27)));
    e.target.name === "description" &&
      dispatch(setStoryDesc(e.target.value.slice(0, 240)));
  };

  const handleEditStory = () => {
    dispatch(
      editStorySegmentsBg({
        description: description,
        isActive: false,
        storyUrl: storySegments[currentStoryIdx].storyUrl || "",
        storyUuid: storySegments[currentStoryIdx].storyUuid,
        title: title,
        isImage: storySegments[currentStoryIdx].type === "image",
        isVideo: storySegments[currentStoryIdx].type === "video",
        colorCode: storySegments[currentStoryIdx].colorCode || "",
        communityUuid: hiveUuid,
        contentType: storySegments[currentStoryIdx].type || "",
        storyId: storySegments[currentStoryIdx].id,
        isMediaChanged: false,
        thumbnail: "",
        actionLink: storySegments[currentStoryIdx].actionLink,
        order: storySegments[currentStoryIdx].segmentOrder,
      })
    );
  };

  return (
    <>
      {!!storySegments && storySegments.length > 0 && (
        <div
          onClick={handleEditStory}
          style={{
            backgroundColor: storySegments[currentStoryIdx].colorCode || "",
          }}
          className="story_display_wrapper"
        >
          {storySegments[currentStoryIdx].type === "video" ? (
            <ReactPlayer
              width="256.5px"
              height="456px"
              url={storySegments[currentStoryIdx].storyUrl || ""}
              controls
              style={{
                overflow: "hidden",
                borderRadius: "5px",
                background: "black",
              }}
            />
          ) : (
            <>
              {storySegments[currentStoryIdx].thumbnailUrl && (
                <img
                  src={storySegments[currentStoryIdx].thumbnailUrl || ""}
                  alt="story_thumbnail"
                  className="story_display_image_wrapper"
                />
              )}
            </>
          )}
          <div className="story_content_wrapper">
            <input
              name="title"
              value={title}
              onChange={handleInputChange}
              className="story_title"
              placeholder="Add title here..."
            />{" "}
            <input
              name="description"
              value={description}
              onChange={handleInputChange}
              className="story_desc"
              placeholder="Add description here..."
            />
          </div>
        </div>
      )}
    </>
  );
};

export default StoryDisplay;
