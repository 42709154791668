import React from "react";

// import TopNavigationLayout from 'components/TopNavigationLayout'
// import BackButton from 'components/BackButton'
// import MenuButton from 'components/MenuButton'
// import { AbsoluteRoutes } from 'routes'

interface NavigationSectionsProps {
  roomName: string;
}

const NavigationSections: React.FC<NavigationSectionsProps> = ({
  roomName,
}) => {
  return <></>;
};

export default NavigationSections;
