import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { createTypedSelector } from "store/utils";
import { getNotifications } from "store/async-actions/Notifications/notifications";
import {
  NotificationItem,
  PaginationModel,
} from "api/models/Notifications/notifications";
import { LocationData } from "api/models/Maps/maps";
import { getMaps, updateMaps } from "store/async-actions/Maps/maps";

const initialState: {
  isFetching: boolean;
  installations: LocationData[];
} = {
  isFetching: false,
  installations: [],
};

const mapsSlice = createSlice({
  name: "maps",
  initialState,
  reducers: {},
  extraReducers: {
    [getMaps.pending.type]: (state) => {
      state.isFetching = true;
    },
    [getMaps.fulfilled.type]: (
      state,
      action: PayloadAction<LocationData[]>
    ) => {
      state.installations = action.payload;
      state.isFetching = false;
    },
    [getMaps.rejected.type]: (state) => {
      state.isFetching = false;
    },

    // Update Maps
    [updateMaps.pending.type]: (state) => {
      state.isFetching = true;
    },
    [updateMaps.fulfilled.type]: (state, action: PayloadAction<boolean>) => {
      state.isFetching = false;
    },
    [updateMaps.rejected.type]: (state) => {
      state.isFetching = false;
    },
  },
});

export const getInstallationsSelector = createTypedSelector(
  (state) => state.maps.installations
);

export const getInstallationsIsFetchingSelector = createTypedSelector(
  (state) => state.maps.isFetching
);

export default mapsSlice.reducer;
