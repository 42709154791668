import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "api";
import { LocationData } from "api/models/Maps/maps";

export const updateMaps = createAsyncThunk(
  "maps/updateMaps",
  async (body: LocationData[], { rejectWithValue, dispatch }) => {
    try {
      const { data } = await api.maps.updateMaps(body);
      dispatch(getMaps(null));

      return data.data;
    } catch (error) {
      return rejectWithValue("Something went wrong");
    }
  }
);

export const getMaps = createAsyncThunk(
  "maps/getMaps",
  async (body: null, { rejectWithValue }) => {
    try {
      const { data } = await api.maps.getMaps();
      return data.data;
    } catch (error) {
      return rejectWithValue("Something went wrong");
    }
  }
);
