import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import {
  getCurrentEventSelector,
  getGalleryItemsSelector,
} from "store/reducers/HiveDetails/events";
import { getGalleryEvent } from "store/async-actions/Hive/eventsActions";
import { NavLink } from "react-router-dom";
import { getChildSelector } from "store/reducers/ChildComponents/childComponents";

const GalleryBtn = () => {
  const dispatch = useAppDispatch();
  const currentEvent = useAppSelector(getCurrentEventSelector);

  const childComponents = useAppSelector(getChildSelector);

  const gallery = useAppSelector(getGalleryItemsSelector);

  useEffect(() => {
    currentEvent &&
      dispatch(
        getGalleryEvent({
          eventUuid: currentEvent.eventUuid,
        })
      );
  }, [currentEvent]);

  return (
    <>
      {(childComponents[0] || gallery[0]) && (
        <NavLink to="gallery">
          <div className="primaryBtn w-fit">View Gallery</div>
        </NavLink>
      )}
    </>
  );
};

export default GalleryBtn;
