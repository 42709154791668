import { Divider } from "@mui/material";
import LineBreak from "components/LineBreak/LineBreak";
import React, { useState } from "react";
import ContentIntegrate from "./ContentIntegrate";
import ItemDivider from "./ItemDivider";
import { AlertSVG, GreenCheckmarkSVG } from "components/SVG/SVG";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import {
  getContentCurrStepSelector,
  getGoogleDriveConnectedSelector,
  setContentCurrentStep,
} from "store/reducers/ContentMgr/contentMgr";
import ContentGenerate from "./ContentGenerate";
import ContentSchedule from "./ContentSchedule";
import ContentDistribute from "./ContentDistribute";
import ContentAnalysis from "./ContentAnalysis";

const ContentMgrPage = () => {
  const dispatch = useAppDispatch();

  const handleClick = (idx: number) => {
    currentStep !== idx && dispatch(setContentCurrentStep(idx));
  };

  const googleDriveConnected = useAppSelector(getGoogleDriveConnectedSelector);
  const currentStep = useAppSelector(getContentCurrStepSelector);

  return (
    <div className="contentMgr_items_container">
      <div
        onClick={() => handleClick(1)}
        className={`content_mgr_item ${
          currentStep === 1 && "content_mgr_item_selected"
        }`}
      >
        <div className="content_mgr_item_header">
          <div>
            <p className="font_lg">1. Integrate</p>
            <p>Connect and sync your cloud storage.</p>
          </div>
          {googleDriveConnected ? <GreenCheckmarkSVG /> : <AlertSVG />}
        </div>

        {currentStep === 1 && (
          <div>
            <LineBreak />
            <ContentIntegrate />
          </div>
        )}
      </div>
      <ItemDivider step={1} />
      <div
        onClick={() => handleClick(2)}
        className={`content_mgr_item ${
          currentStep === 2 && "content_mgr_item_selected"
        }`}
      >
        <div>
          <p className="font_lg">2. Generate</p>
          <p>Create stunning videos from selected content.</p>
        </div>
        {currentStep === 2 && (
          <div>
            <LineBreak />
            <ContentGenerate />
          </div>
        )}
      </div>
      <ItemDivider step={2} />
      <div
        onClick={() => handleClick(3)}
        className={`content_mgr_item ${
          currentStep === 3 && "content_mgr_item_selected"
        }`}
      >
        <div>
          <p className="font_lg">3. Distribute</p>
          <p>Share videos seamlessly on social media.</p>
        </div>
        {currentStep === 3 && (
          <div>
            <LineBreak />
            <ContentDistribute />
          </div>
        )}
      </div>
      <ItemDivider step={3} />
      <div
        onClick={() => handleClick(4)}
        className={`content_mgr_item ${
          currentStep === 4 && "content_mgr_item_selected"
        }`}
      >
        <div>
          <p className="font_lg">4. Schedule</p>
          <p>Plan and set automated video distribution.</p>
        </div>
        {currentStep === 4 && (
          <div>
            <LineBreak />
            <ContentSchedule />
          </div>
        )}
      </div>
      <ItemDivider step={4} />

      <div
        onClick={() => handleClick(5)}
        className={`content_mgr_item ${
          currentStep === 5 && "content_mgr_item_selected"
        }`}
      >
        <div>
          <p className="font_lg">5. Analyze</p>
          <p>Gain valuable insights from video analytics.</p>
        </div>
        {currentStep === 5 && (
          <div>
            <LineBreak />
            <ContentAnalysis />
          </div>
        )}
      </div>
      {/* <div className="content_mgr_item">
        <ContentIntegrate />
      </div> */}
    </div>
  );
};

export default ContentMgrPage;
