import {
  StoryImgSVG,
  StoryVidSVG,
  StoryAddSVG,
  StoryRemoveSVG,
  CopyLinkSVG,
  AddLinkSVG,
} from "components/SVG/SVG";
import StoryDropzone from "components/StoryDropzone/StoryDropzone";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import React, { useState, useEffect } from "react";
import {
  addStorySegments,
  editStorySegmentsBg,
  removeSegment,
} from "store/async-actions/Story/storyActions";
import { getHiveUuid } from "store/reducers/HiveDetails/hiveDetails";
import {
  getCurrentStoryColorSelector,
  getCurrentStoryIdSelector,
  getCurrentStoryIndexSelector,
  getStoryDescSelector,
  getStorySegmentsSelector,
  getStoryTitleSelector,
  getStoryUuidSelector,
  setCurrentStoryId,
  setCurrentStoryIndex,
  setStoryColor,
} from "store/reducers/Story/story";
import { TwitterPicker } from "react-color";
import { toastError } from "hooks/useToastify";
import AddLinkDrop from "components/AddLinkDrop/AddLinkDrop";

const StoryActions = () => {
  const [openDrop, setOpenDrop] = useState(false);

  const [file, setFile] = useState("img");

  const dispatch = useAppDispatch();

  const hiveUuid = useAppSelector(getHiveUuid);
  const storyUuid = useAppSelector(getStoryUuidSelector);

  const storyId = useAppSelector(getCurrentStoryIdSelector);
  const storyIdx = useAppSelector(getCurrentStoryIndexSelector);

  const storySegments = useAppSelector(getStorySegmentsSelector);
  const colorCode = useAppSelector(getCurrentStoryColorSelector);

  const title = useAppSelector(getStoryTitleSelector);
  const description = useAppSelector(getStoryDescSelector);

  useEffect(() => {
    dispatch(setStoryColor("#B4CEBE"));
  }, [dispatch, storyIdx]);

  const handleImageUpload = () => {
    setFile("img");
    setOpenDrop(true);
  };

  const handleVideoUpload = () => {
    setFile("video");
    setOpenDrop(true);
  };

  const handleAddSegment = () => {
    dispatch(
      addStorySegments({
        description: "",
        isActive: false,
        storyUrl: "",
        storyUuid: storyUuid,
        title: "",
        isImage: false,
        isVideo: false,
        colorCode: "#B4CEBE",
        communityUuid: hiveUuid,
        contentType: "",
        order: storySegments[storyIdx].segmentOrder + 1,
      })
    );
  };

  const handleRemoveSegment = () => {
    if (storySegments.length > 1) {
      dispatch(
        removeSegment({
          storyUuid: storyUuid,
          id: storyId,
        })
      );
      dispatch(setCurrentStoryId(0));
      dispatch(setCurrentStoryIndex(0));
    } else {
      toastError("Sorry, a story must have atleast one frame");
    }
  };

  const handleColorChange = (color: any) => {
    dispatch(setStoryColor(color.hex));
  };

  const [openColor, setOpenColor] = useState(false);

  const handleColorClick = () => {
    if (openColor) {
      dispatch(
        editStorySegmentsBg({
          description: description,
          isActive: false,
          storyUrl: "",
          storyUuid: storyUuid,
          title: title,
          isImage: false,
          isVideo: false,
          colorCode: colorCode,
          communityUuid: hiveUuid,
          contentType: "",
          isMediaChanged: false,
          storyId: storySegments[storyIdx].id,
          thumbnail: "",
          actionLink: storySegments[storyIdx].actionLink,
          order: storySegments[storyIdx].segmentOrder,
        })
      );
    }
    setOpenColor(!openColor);
  };

  return (
    <>
      {openColor && <TwitterPicker onChange={handleColorChange} />}
      <div className="story_actions_container">
        <div className="pointer story_action_item" onClick={handleColorClick}>
          <div className="action_item_icon">
            <div
              style={{ backgroundColor: colorCode }}
              className="bg_color_story"
            ></div>
          </div>
          <p className="text_xs">BG color</p>
        </div>
        <div className="pointer story_action_item" onClick={handleImageUpload}>
          <div className="action_item_icon">
            <StoryImgSVG />
          </div>
          <p className="text_xs">Image</p>
        </div>
        <div onClick={handleVideoUpload} className="pointer story_action_item">
          <div className="action_item_icon">
            <StoryVidSVG />
          </div>
          <p className="text_xs">Video</p>
        </div>
        {/* <StoryTextSVG /> */}
        <div onClick={handleAddSegment} className="pointer story_action_item">
          <div className="action_item_icon">
            <StoryAddSVG />
          </div>
          <p className="text_xs">Add</p>
        </div>
        <div
          onClick={handleRemoveSegment}
          className="pointer story_action_item"
        >
          <div className="action_item_icon">
            <StoryRemoveSVG />
          </div>
          <p className="text_xs">Remove</p>
        </div>
        <div
          // onClick={handleRemoveSegment}
          className="pointer story_action_item"
        >
          <div className="action_item_icon">
            <AddLinkDrop />
          </div>
          <p className="text_xs">Add Link</p>
        </div>
      </div>
      {openDrop && (
        <StoryDropzone
          openDrop={openDrop}
          setOpenDrop={setOpenDrop}
          file={file}
        />
      )}
    </>
  );
};

export default StoryActions;
