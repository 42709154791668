import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "api";
import {
  ContentUploadRequest,
  ReUploadUrlRequest,
  YoutubeVideoData,
} from "api/models/Videos/uploadContent";
import { VideoListRequest } from "api/models/Videos/videoList";
import { toastError, toastSuccess } from "hooks/useToastify";
import { RootState } from "store";
import { setActiveChannelId } from "store/reducers/ChannelDetails/channelList";
import { setLogoFile } from "store/reducers/CreateChannel/createChannel";
import { setCurrentVideo } from "store/reducers/Videos/videoList";
import {
  populateFormData,
  setIsUploading,
  setVideoFile,
} from "store/reducers/Videos/videos";

export const uploadContent = createAsyncThunk(
  "videos/uploadContent",
  async (
    body: ContentUploadRequest,
    { rejectWithValue, dispatch, getState }
  ) => {
    try {
      const { data } = await api.videos.contentUpload(body);

      const { videos } = getState() as RootState;

      const formData = new FormData();
      populateFormData(formData, data.data.data, videos.file!);
      dispatch(uploadToS3({ url: data.data.data.url, formData: formData }));
      return data.data;
    } catch (error) {
      return rejectWithValue("Something went wrong");
    }
  }
);

export const YTUploadContent = createAsyncThunk(
  "videos/YTUploadContent",
  async (body: YoutubeVideoData, { rejectWithValue }) => {
    try {
      const { data } = await api.videos.YTContentUpload(body);

      return data.data;
    } catch (error) {
      return rejectWithValue("Something went wrong");
    }
  }
);

export const getVideoDetails = createAsyncThunk(
  "videos/getVideoDetails",
  async (
    body: {
      channelID: string;
      videoId: string;
    },
    { rejectWithValue }
  ) => {
    try {
      const { data } = await api.videos.getVideoDetails(body);
      return data.data;
    } catch (error) {
      return rejectWithValue("Something went wrong");
    }
  }
);

export const readyToUpload = createAsyncThunk(
  "videos/readyToUpload",
  async (body: { organizationUuid: string }, { rejectWithValue }) => {
    try {
      const { data } = await api.videos.readyToUpload(body);
      return data.data;
    } catch (error) {
      return rejectWithValue("Something went wrong");
    }
  }
);

export const getPresignedUrl = createAsyncThunk(
  "videos/getPresignedUrl",
  async (body: ReUploadUrlRequest, { rejectWithValue, dispatch, getState }) => {
    try {
      const { data } = await api.videos.getPresignedUrl(body);
      const { videos } = getState() as RootState;

      // console.log(data.data.data);

      const formData = new FormData();

      populateFormData(formData, data.data.data, videos.file!);
      dispatch(uploadToS3({ url: data.data.data.url, formData: formData }));

      return data.data;
    } catch (error) {
      return rejectWithValue("Something went wrong");
    }
  }
);

// export const uploadToS3 = createAsyncThunk(
//   "videos/uploadToS3",
//   async (
//     body: {
//       url: string;
//       formData: FormData;
//     },
//     { rejectWithValue, dispatch }
//   ) => {
//     dispatch(setIsUploading(true));
//     try {
//       console.log("trying");
//       const response = await api.videos.uploadToS3(body.formData, body.url);
//       console.log("done trying");
//       toastSuccess("Video uploaded successfully");
//       dispatch(setIsUploading(false));
//       return response;
//     } catch (error) {
//       console.log("error");
//       toastError("Video upload failed");
//       return rejectWithValue("Something went wrong");
//     }
//   }
// );

export const uploadToS3 = createAsyncThunk(
  "videos/uploadToS3",
  async (
    body: {
      url: string;
      formData: FormData;
    },
    { rejectWithValue, dispatch }
  ) => {
    try {
      const response = await api.videos.uploadToS3(body.formData, body.url);
      const { status } = response; // Extract the response status code

      dispatch(setVideoFile(null));
      dispatch(setLogoFile(""));

      if (status === 204) {
        // Success response
        return response.data; // Return the response data if needed
      } else {
        // Handle other response codes
        // You can customize this logic based on your requirements
        return rejectWithValue("Unexpected response code");
      }
    } catch (error: any) {
      if (error?.response?.status && error?.response?.status > 205) {
        toastError("Video upload failed");
      } else {
        toastSuccess("Video uploaded successfully");
      }
      return rejectWithValue("Something went wrong");
    }
  }
);

export const getVideoList = createAsyncThunk(
  "videos/getVideoList",
  async (body: VideoListRequest, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await api.videos.getVideoList(body);
      return data.data;
    } catch (error) {
      return rejectWithValue("Something went wrong");
    }
  }
);

export const getPendingVideoList = createAsyncThunk(
  "videos/getPendingVideoList",
  async (body: VideoListRequest, { rejectWithValue }) => {
    try {
      const { data } = await api.videos.getVideoList(body);
      return data.data;
    } catch (error) {
      return rejectWithValue("Something went wrong");
    }
  }
);

export const getSpotlight = createAsyncThunk(
  "videos/getSpotlight",
  async (
    body: {
      communityId: number;
    },
    { rejectWithValue, dispatch, getState }
  ) => {
    try {
      const { data } = await api.videos.getSpotlight(body);

      const { channels } = getState() as RootState;

      if (data.data[0] && data.data[0].channelId) {
        dispatch(setActiveChannelId(data.data[0].channelId));

        // setTimeout(() => {
        dispatch(
          getSpotlightVids({
            channelUuid: channels.activeChannelUuid!,
            page: 0,
            noOfRecords: 1,
          })
        );
        // }, 1500);
      }
      return data.data;
    } catch (error) {
      return rejectWithValue("Something went wrong");
    }
  }
);

export const getSpotlightVids = createAsyncThunk(
  "videos/getSpotlightVids",
  async (
    body: {
      channelUuid: string;
      page: number;
      noOfRecords: number;
    },
    { rejectWithValue, dispatch }
  ) => {
    try {
      const { data } = await api.videos.getSpotlightVids(body);
      return data.data;
    } catch (error) {
      return rejectWithValue("Something went wrong");
    }
  }
);

export const updateVideoStatus = createAsyncThunk(
  "videos/updateVideoStatus",
  async (
    body: {
      videoId: string;
      channelUUID: string;
      status: string;
      approvalNotes: string;
      communityId: number;
    },
    { rejectWithValue }
  ) => {
    try {
      const { data } = await api.videos.updateVideoStatus(body);
      toastSuccess(
        `Video has been moved to the ${
          body.status === "Ready" ? "Videos" : body.status.toLowerCase()
        } tab`
      );
      return data.data;
    } catch (error) {
      toastError("Couldnt change video status");
      return rejectWithValue("Something went wrong");
    }
  }
);
