import { ChannelListModel } from "api/models/Hive/hiveChannels";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import React, { useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { store } from "store";
import { getChannelDetails } from "store/async-actions/Channels/channels";
import { getChildComponents } from "store/async-actions/Hive/hivePage";
import {
  getActiveChannelUuidSelector,
  setActiveChannelUuid,
  setCurrentVideosPage,
  setVideosTab,
} from "store/reducers/ChannelDetails/channelList";
import { getHiveSelector } from "store/reducers/HiveDetails/hiveDetails";

interface Props {
  channelItem: ChannelListModel;
}

const ChannelItem = ({ channelItem }: Props) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const activeChannel = useAppSelector(getActiveChannelUuidSelector);
  const hiveDetails = useAppSelector(getHiveSelector);

  const handleChannelClick = () => {
    if (channelItem.channelTier === "CREATE") {
      navigate("/create/channel");
    } else {
      dispatch(setCurrentVideosPage(0));
      dispatch(setActiveChannelUuid(channelItem.channelUuid));
      // dispatch(getChannelDetails(channelItem.channelUuid));
      // dispatch(
      //   getChildComponents({
      //     organizationId: hiveDetails?.communityId!,
      //     parentComponentCode: "Channel",
      //     channelUuid: channelItem.channelUuid,
      //     isMemberView: store.getState().profile.isMemberView,
      //   })
      // );
      navigate(`/channels/${channelItem.channelUuid}`);
    }
    dispatch(setVideosTab(0));
  };

  return (
    <>
      <div
        onClick={handleChannelClick}
        className={`channel_item cursor-pointer ${
          activeChannel === channelItem.channelUuid
            ? "selected_channel_item"
            : ""
        }`}
      >
        <div className="channel_card">
          <img
            className="channel_logo"
            src={
              channelItem.webLogo ||
              "https://veehivestage-images.s3.us-east-2.amazonaws.com/channelImages/defaultChannelLogo.jpg"
            }
            alt=""
          />
          <h4>
            {channelItem.channelName.length > 16
              ? channelItem.channelName.slice(0, 16) + "..."
              : channelItem.channelName}
          </h4>
        </div>
        <div>
          <svg
            width="8"
            height="14"
            viewBox="0 0 8 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1 13L7 7L1 1"
              stroke="black"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
      </div>
    </>
  );
};

export default ChannelItem;
