import { TextField } from "@mui/material";
import AiFileDrop from "components/AiFileDrop/AiFileDrop";
import BackButton from "components/BackButton/BackButton";
import LineBreak from "components/LineBreak/LineBreak";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { createTeaser, transposeVideo } from "store/async-actions/Ai/ai";
import {
  getUploadedAiFileSelector,
  setOpenDownloads,
  uploadAiFile,
} from "store/reducers/Ai/ai";
import { getHiveUuid } from "store/reducers/HiveDetails/hiveDetails";
import { profileUserIdSelector } from "store/reducers/Profile/profile";

const Transpose = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(uploadAiFile(null));
  }, [dispatch]);

  const userId = useAppSelector(profileUserIdSelector);

  const uploadedFile = useAppSelector(getUploadedAiFileSelector);

  const hiveUuid = useAppSelector(getHiveUuid);

  const navigate = useNavigate();

  const handleCreate = () => {
    const formData = new FormData();
    const obj = {
      method: "border",
      userid: userId,
      organizationUuid: hiveUuid,
    };

    formData.append("json", JSON.stringify(obj));
    formData.append("file", uploadedFile || "");
    formData.append("organizationUuid", hiveUuid);

    dispatch(transposeVideo(formData));
    navigate("..");
    dispatch(setOpenDownloads(true));
  };

  return (
    <div className="ttv_container">
      <BackButton />
      <LineBreak />
      <h4 className="tags">Upload Video</h4>
      <LineBreak />
      <AiFileDrop fileType="video" size={150} />
      <LineBreak />

      {uploadedFile ? (
        <div onClick={handleCreate} className="nextBtn primaryBtn">
          Transpose Video
        </div>
      ) : (
        <div className="nextBtn disabledBtn">Transpose Video</div>
      )}
    </div>
  );
};

export default Transpose;
