import IslandLayout from "components/IslandLayout/IslandLayout";
import PageLayout from "components/PageLayout";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import React, { useEffect } from "react";
import {
  getNotifications,
  readUserNotifications,
} from "store/async-actions/Notifications/notifications";
import { getHiveUuid } from "store/reducers/HiveDetails/hiveDetails";
import NotificationList from "./components/NotificationList";
import LineBreak from "components/LineBreak/LineBreak";
import {
  getNotificationsPageNoSelector,
  getNotificationsSelector,
} from "store/reducers/Notifications/notifications";
import PageNumbers from "components/PageNumbers/PageNumbers";
import { messaging } from "messaging_init_in_sw";

const NotificationPage = () => {
  const dispatch = useAppDispatch();

  const hiveUuid = useAppSelector(getHiveUuid);

  const pageNo = useAppSelector(getNotificationsPageNoSelector);

  const notifications = useAppSelector(getNotificationsSelector);

  useEffect(() => {
    dispatch(
      getNotifications({
        organizationUuid: hiveUuid,
        page: 0,
        limit: 10,
      })
    );

    dispatch(
      readUserNotifications({
        organizationUuid: hiveUuid,
      })
    );
  }, [dispatch, hiveUuid]);

  const handleNextPage = (pageNo: number) => {
    dispatch(
      getNotifications({
        organizationUuid: hiveUuid,
        page: pageNo,
        limit: 10,
      })
    );
  };

  return (
    <PageLayout sideMenu={true} rightMenu={false}>
      <IslandLayout>
        <div className="notification_page_container">
          <h1>Notifications</h1>
          <LineBreak />
          {!!notifications &&
          !!notifications.content &&
          notifications.content.length > 0 ? (
            <>
              <NotificationList />
              <LineBreak />
              <PageNumbers
                handleChange={handleNextPage}
                initialPage={pageNo}
                totalPages={notifications.totalPages}
              />
            </>
          ) : (
            <div>No Notifications</div>
          )}
        </div>
      </IslandLayout>
    </PageLayout>
  );
};
export default NotificationPage;
