import IslandLayout from "components/IslandLayout";
import PageLayout from "components/PageLayout";
import React from "react";
import { Outlet } from "react-router-dom";

const LinkFeatures = () => {
  return (
    <PageLayout sideMenu={true} rightMenu={false}>
      <IslandLayout>
        <div className="page_padding">
          <h2>Link Features</h2>
          <Outlet />
        </div>
      </IslandLayout>
    </PageLayout>
  );
};

export default LinkFeatures;
