import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import PageLayout from "components/PageLayout";
import TopNavigatorLink from "components/common/TopNavigatorLink";
import Text from "components/common/Text";
import ParticipantsChatRoom from "components/ParticipantsChatRoom";

import SettingsChatRoom from "components/SettingsChatRoom";
import {
  useAppDispatch,
  useAppSelector,
  useAppShallowSelector,
} from "hooks/redux";
import {
  getParticipansHowIsSelected,
  getCreateSelectedStateSelector,
  setCreateSelectedState,
} from "store/reducers/Chat/chatrooms";
import { createChatRoom } from "store/async-actions/Chat/chatrooms";
import { getActiveChannelIdSelector } from "store/reducers/ChannelDetails/channelList";

const ChatRoomCreatePageSettings = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [roomName, setRoomName] = useState<string>("");
  const [roomBio, setRoomBio] = useState<string>("");

  const participansList = useAppShallowSelector(getParticipansHowIsSelected);
  const channelId = useAppSelector(getActiveChannelIdSelector);
  const communityId = 2;
  const selectedState = useAppShallowSelector(getCreateSelectedStateSelector);

  const setSelectedState = useCallback(
    (newState: UserId[]) => {
      dispatch(setCreateSelectedState(newState));
    },
    [dispatch]
  );

  const handleDeletePartisipan = useCallback(
    ([id]: UserId[]) => {
      const _newList = selectedState.filter((p: number) => p !== id);
      dispatch(setCreateSelectedState(_newList));
    },
    [dispatch, selectedState]
  );

  const handleClickCreate = () => {
    if (channelId && roomName && communityId) {
      const newChatRoom = {
        chatRoomName: roomName,
        chatRoomBio: roomBio,
        communityId,
        channelId: channelId,
        type: "default", //// what is kind of types?
        userIds: selectedState.map((s: any) => ({
          userId: s,
          isPinned: false,
        })),
      };
      dispatch(createChatRoom(newChatRoom))
        .unwrap()
        .then(() => {
          navigate("chat");
        });
    }
  };

  useEffect(() => {
    if (!selectedState.length) {
      navigate("..");
    }
  }, [navigate, selectedState.length]);

  return (
    <>
      {/* <TopNavigationLayout> */}
      <TopNavigatorLink to={`..`}>Back</TopNavigatorLink>
      <Text className="top_navigation_title" fontWeight="w700">
        Chatroom info
      </Text>
      <TopNavigatorLink
        to={"#"}
        onClick={handleClickCreate}
        disabled={!roomName}
      >
        Create
      </TopNavigatorLink>
      {/* </TopNavigationLayout> */}
      <PageLayout sideMenu={false}>
        {/* <PageLayout className="chat_room_create_page_container"> */}
        <SettingsChatRoom
          isCreate
          nameValue={roomName}
          bioValue={roomBio}
          changeNameValue={setRoomName}
          changeBioValue={setRoomBio}
        />
        <ParticipantsChatRoom
          selectedState={selectedState}
          changeSelectedState={setSelectedState}
          isOwner={true}
          withSelectAction={false}
          data={participansList}
          deleteParticipanst={handleDeletePartisipan}
        />
      </PageLayout>
    </>
  );
};

export default ChatRoomCreatePageSettings;
