import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  AiTemplateItem,
  AiTextGeneratedModel,
  GenerateTopicsModel,
  UserDownloadItem,
} from "api/models/Ai/ai";
import { toastSuccess } from "hooks/useToastify";
import {
  formToVideo,
  generateTopics,
  getAiTemplates,
  getReadyVideos,
  getUserDownloads,
  publishToChannel,
  textToVideo,
  textToVideoGenerate,
  textToVideoGetGenerated,
} from "store/async-actions/Ai/ai";
import { createTypedSelector } from "store/utils";

const initialState: {
  uploadedImages: File[];
  isUploaded: boolean;
  showNext: boolean;
  aiTemplatesResp: AiTemplateItem[];
  isFetching: boolean;
  activeTemplate: string;
  ttvResp: any;
  ltsResp: GenerateTopicsModel | undefined;
  userDownloads: AiTextGeneratedModel[];
  openDownloads: boolean;
  downloadInProgress: boolean;
  selectedTemplate: AiTemplateItem | undefined;
  uploadedFile: File | null;
  generatingTopics: boolean;
  topicsGenerated: boolean;
  readyVidsResp: any;
  ttvTextIsDoneGenerating: boolean;
  ttvTextIsGenerating: boolean;
  ttvTextGenerate: string;
  generatedTextResp: AiTextGeneratedModel | undefined;
  ttvText: string;
  currentAiVideo: AiTextGeneratedModel | null;
  publlishToChannelResp: boolean;
} = {
  readyVidsResp: "",
  selectedTemplate: undefined,
  uploadedImages: [],
  isUploaded: false,
  showNext: false,
  aiTemplatesResp: [],
  isFetching: false,
  activeTemplate: "",
  ttvResp: "",
  ltsResp: undefined,
  userDownloads: [],
  openDownloads: false,
  downloadInProgress: false,
  uploadedFile: null,
  generatingTopics: false,
  topicsGenerated: false,
  ttvTextGenerate: "",
  generatedTextResp: undefined,
  ttvTextIsDoneGenerating: false,
  ttvTextIsGenerating: false,
  ttvText: "",
  currentAiVideo: null,
  publlishToChannelResp: false,
};

const aiSlice = createSlice({
  name: "ai",
  initialState,
  reducers: {
    uploadAiImage: (state, action: PayloadAction<any>) => {
      state.uploadedImages.push(action.payload);
    },
    clearAiImages: (state) => {
      state.uploadedImages = [];
    },
    setIsUploaded: (state, action: PayloadAction<boolean>) => {
      state.isUploaded = action.payload;
    },
    setCurrentAiVideo: (
      state,
      action: PayloadAction<AiTextGeneratedModel | null>
    ) => {
      state.currentAiVideo = action.payload;
    },
    setShowNext: (state, action: PayloadAction<boolean>) => {
      state.showNext = action.payload;
    },
    setTtvIsDoneGenerating: (state, action: PayloadAction<boolean>) => {
      state.ttvTextIsDoneGenerating = action.payload;
    },
    setTopicsGenerated: (state, action: PayloadAction<boolean>) => {
      state.topicsGenerated = action.payload;
    },
    setTtvText: (state, action: PayloadAction<string>) => {
      state.ttvText = action.payload;
    },
    setActiveTemplate: (
      state,
      action: PayloadAction<AiTemplateItem | undefined>
    ) => {
      if (action.payload !== undefined) {
        state.activeTemplate = action.payload.templateId;
        state.selectedTemplate = action.payload;
      } else {
        state.activeTemplate = "";
        state.selectedTemplate = undefined;
      }
    },
    uploadAiFile: (state, action: PayloadAction<any>) => {
      state.uploadedFile = action.payload;
    },
    setOpenDownloads: (state, action: PayloadAction<boolean>) => {
      state.openDownloads = action.payload;
    },
  },
  extraReducers: {
    // Get Ai Templates
    [getAiTemplates.pending.type]: (state) => {
      state.isFetching = true;
    },
    [getAiTemplates.fulfilled.type]: (
      state,
      actions: PayloadAction<AiTemplateItem[]>
    ) => {
      state.aiTemplatesResp = actions.payload;
      state.isFetching = false;

      if (state.selectedTemplate === undefined) {
        state.activeTemplate = state.aiTemplatesResp[0].templateId;
        state.selectedTemplate = state.aiTemplatesResp[0];
      }
    },
    [getAiTemplates.rejected.type]: (state) => {
      state.isFetching = false;
    },

    // Publish To Channel
    [publishToChannel.pending.type]: (state) => {
      state.isFetching = true;
    },
    [publishToChannel.fulfilled.type]: (
      state,
      actions: PayloadAction<boolean>
    ) => {
      state.publlishToChannelResp = actions.payload;
      state.isFetching = false;
      toastSuccess("Video Published!");
    },
    [publishToChannel.rejected.type]: (state) => {
      state.isFetching = false;
    },

    // Text to video
    [textToVideo.pending.type]: (state) => {
      state.isFetching = true;
    },
    [textToVideo.fulfilled.type]: (state, actions: PayloadAction<any>) => {
      state.ttvResp = actions.payload;
      state.isFetching = false;
    },
    [textToVideo.rejected.type]: (state) => {
      state.isFetching = false;
    },

    // Text to video generate text
    [textToVideoGenerate.pending.type]: (state) => {
      state.isFetching = true;
      state.ttvTextIsDoneGenerating = false;
      state.ttvTextIsGenerating = true;
      state.generatedTextResp = undefined;
    },
    [textToVideoGenerate.fulfilled.type]: (
      state,
      actions: PayloadAction<string>
    ) => {
      state.ttvTextGenerate = actions.payload;
      state.isFetching = false;
      state.ttvTextIsDoneGenerating = true;
      state.ttvTextIsGenerating = false;
    },
    [textToVideoGenerate.rejected.type]: (state) => {
      state.isFetching = false;
      state.ttvTextIsDoneGenerating = false;
      state.ttvTextIsGenerating = false;
    },

    // Text to video generated text response
    [textToVideoGetGenerated.pending.type]: (state) => {
      state.isFetching = true;
      state.generatedTextResp = undefined;
    },
    [textToVideoGetGenerated.fulfilled.type]: (
      state,
      actions: PayloadAction<any>
    ) => {
      state.generatedTextResp = actions.payload;
      state.isFetching = false;
    },
    [textToVideoGetGenerated.rejected.type]: (state) => {
      state.isFetching = false;
      state.generatedTextResp = undefined;
    },

    // Generate Topics
    [generateTopics.pending.type]: (state) => {
      state.isFetching = true;
      state.generatingTopics = true;
      state.topicsGenerated = false;
    },
    [generateTopics.fulfilled.type]: (
      state,
      actions: PayloadAction<GenerateTopicsModel>
    ) => {
      state.ltsResp = actions.payload;
      state.isFetching = false;
      state.generatingTopics = false;
      state.topicsGenerated = true;
    },
    [generateTopics.rejected.type]: (state) => {
      state.isFetching = false;
      state.generatingTopics = false;
      state.topicsGenerated = false;
    },

    // Get Ready Videos
    [getReadyVideos.pending.type]: (state) => {
      state.isFetching = true;
    },
    [getReadyVideos.fulfilled.type]: (state, actions: PayloadAction<any>) => {
      state.readyVidsResp = actions.payload;
      state.isFetching = false;
    },
    [getReadyVideos.rejected.type]: (state) => {
      state.isFetching = false;
    },

    // Get user downloads
    [getUserDownloads.pending.type]: (state) => {
      state.isFetching = true;
    },
    [getUserDownloads.fulfilled.type]: (
      state,
      actions: PayloadAction<AiTextGeneratedModel[]>
    ) => {
      state.userDownloads = actions.payload;
      state.downloadInProgress = false;
      state.userDownloads.map((download) => {
        // if (download.is_active) {
        //   state.downloadInProgress = true;
        // }
        if (
          download.completed_per &&
          parseInt(download.completed_per.match(/\d+/)?.[0] ?? "0", 10) > 0 &&
          parseInt(download.completed_per.match(/\d+/)?.[0] ?? "0", 10) < 100
        ) {
          state.downloadInProgress = true;
        }
      });
      state.isFetching = false;
    },
    [getUserDownloads.rejected.type]: (state) => {
      state.isFetching = false;
    },
  },
});

export const {
  uploadAiImage,
  setIsUploaded,
  setShowNext,
  clearAiImages,
  setActiveTemplate,
  setOpenDownloads,
  uploadAiFile,
  setTopicsGenerated,
  setTtvIsDoneGenerating,
  setTtvText,
  setCurrentAiVideo,
} = aiSlice.actions;

export const getReadyVidsRespSelector = createTypedSelector(
  (state) => state.ai.readyVidsResp
);

export const getPublishToChannelRespSelector = createTypedSelector(
  (state) => state.ai.publlishToChannelResp
);

export const getCurrentAiVideoSelector = createTypedSelector(
  (state) => state.ai.currentAiVideo
);

export const getTtvTextSelector = createTypedSelector(
  (state) => state.ai.ttvText
);

export const getTtvTextGenerateSelector = createTypedSelector(
  (state) => state.ai.ttvTextGenerate
);

export const getTtvGeneratedTextSelector = createTypedSelector(
  (state) => state.ai.generatedTextResp
);

export const getUploadedAiImagesSelector = createTypedSelector(
  (state) => state.ai.uploadedImages
);

export const getTopicsIsGeneratedSelector = createTypedSelector(
  (state) => state.ai.topicsGenerated
);

export const getGeneratingTopicsSelector = createTypedSelector(
  (state) => state.ai.generatingTopics
);

export const getLtsRespSelector = createTypedSelector(
  (state) => state.ai.ltsResp
);

export const getUploadedAiFileSelector = createTypedSelector(
  (state) => state.ai.uploadedFile
);

export const downloadInProgressSelector = createTypedSelector(
  (state) => state.ai.downloadInProgress
);

export const getActiveTemplateSelector = createTypedSelector(
  (state) => state.ai.activeTemplate
);

export const getDownloadsOpenSelector = createTypedSelector(
  (state) => state.ai.openDownloads
);

export const getAiImagesIsUploadedSelector = createTypedSelector(
  (state) => state.ai.isUploaded
);

export const getSelectedTemplateSelector = createTypedSelector(
  (state) => state.ai.selectedTemplate
);

export const getAiTemplatesSelector = createTypedSelector(
  (state) => state.ai.aiTemplatesResp
);

export const getTtvRespSelector = createTypedSelector(
  (state) => state.ai.ttvResp
);

export const getUserDownloadsSelector = createTypedSelector(
  (state) => state.ai.userDownloads
);

export const getAiIsLoadingSelector = createTypedSelector(
  (state) => state.ai.isFetching
);

export const getTtvIsDoneGeneratingSelector = createTypedSelector(
  (state) => state.ai.ttvTextIsDoneGenerating
);

export const getTtvIsGeneratingSelector = createTypedSelector(
  (state) => state.ai.ttvTextIsGenerating
);

export const getAiShowNext = createTypedSelector((state) => state.ai.showNext);

export default aiSlice.reducer;
