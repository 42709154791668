import { Dialog, Divider } from "@mui/material";
import {
  AttendeeFormDetails,
  AttendeeFormDetailsReq,
} from "api/models/Hive/events";
import IslandLayout from "components/IslandLayout/IslandLayout";
import LineBreak from "components/LineBreak/LineBreak";
import Loader from "components/Loader/Loader";
import {
  CopyLinkSVG,
  EditIconSVG,
  GreenCheckmarkSVG,
  GreenTickSVG,
  LocationSVG,
  RedCrossSVG,
} from "components/SVG/SVG";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { useQuery } from "hooks/useQuery";
import { toastInfo } from "hooks/useToastify";
import moment from "moment";
import React, { useEffect, useState } from "react";
import ReactPlayer from "react-player";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import {
  getEventAttendeesList,
  getEventDetails,
  getEventOrgOwner,
  registerEvent,
  registerEventInBg,
  registerFormEvent,
} from "store/async-actions/Hive/eventsActions";
import { getChildSelector } from "store/reducers/ChildComponents/childComponents";
import {
  getCurrentEventSelector,
  getEventAttendeesListSelector,
  getEventIsLoadingSelector,
  getEventOrgOwnerSelector,
  getEventRegistrationFormSelector,
  getEventRespSelector,
  resetPromo,
  setEventFormDetails,
} from "store/reducers/HiveDetails/events";
import {
  getHiveUuid,
  launchLogin,
  setEventRegisterType,
  setHiveIsLoading,
} from "store/reducers/HiveDetails/hiveDetails";
import EventRegistrationForm from "./components/EventRegistrationForm";
import MemberLoginConfirm from "components/MemberLoginConfirm/MemberLoginConfirm";
import EventRSVP from "./components/EventRSVP";
import { FetchParams } from "hooks/useAnalytics";
import EventCta from "./EventCta";
import UploadGallery from "./components/UploadGallery";
import GalleryBtn from "./components/GalleryBtn";

const REGISTER = "register";

const EventPage = () => {
  localStorage.setItem("userType", "member");

  const location = useLocation();
  const hiveUuid = useAppSelector(getHiveUuid);

  const searchParams = useQuery();
  var register = searchParams.get(REGISTER);

  const dispatch = useAppDispatch();
  const currentEvent = useAppSelector(getCurrentEventSelector);

  const url = location.pathname;
  const secondSlashIndex = url.indexOf("/", url.indexOf("/") + 1);
  const thirdSlashIndex = url.indexOf("/", secondSlashIndex + 1);
  const eventIdentifier = url.substring(
    secondSlashIndex + 1,
    thirdSlashIndex !== -1 ? thirdSlashIndex : url.length
  );

  localStorage.setItem("path", url);

  const navigate = useNavigate();

  const openDubaiTechTalks = () => {
    window.open(
      `https://members.dubaitechtalks.com/events/${
        currentEvent?.eventIdentifier || ""
      }?atxd=${localStorage.getItem("atxd")}&register=${localStorage.getItem(
        "register"
      )}`,
      "_self"
    );
    setTimeout(() => {
      window.location.reload();
    }, 5000);
  };

  useEffect(() => {
    if (!!currentEvent) {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        dispatch(setHiveIsLoading(true));
        if (
          localStorage.getItem("subDomain") === "wise" ||
          localStorage.getItem("subDomain") === "vee" ||
          localStorage.getItem("subDomain") === "mnm" ||
          localStorage.getItem("subDomain") === "guruji" ||
          localStorage.getItem("subDomain") === "wkff" ||
          localStorage.getItem("subDomain") === "tbs" ||
          localStorage.getItem("subDomain") === "imsats" ||
          localStorage.getItem("subDomain") === "menacxo" ||
          localStorage.getItem("subDomain") === "svk" ||
          localStorage.getItem("subDomain") === "te"
        ) {
          navigate(
            `/mobile/events/${
              currentEvent?.eventIdentifier || ""
            }?atxd=${localStorage.getItem(
              "atxd"
            )}&register=${localStorage.getItem("register")}`
          );
          window.location.reload();
        }
        if (localStorage.getItem("subDomain") === "dtt") {
          openDubaiTechTalks();
        } else {
          navigate(
            `/mobile/events/${
              currentEvent?.eventIdentifier || ""
            }?atxd=${localStorage.getItem(
              "atxd"
            )}&register=${localStorage.getItem("register")}`
          );
          window.location.reload();
        }
      }
    }
  }, [navigator.userAgent, currentEvent]);

  useEffect(() => {
    dispatch(getEventDetails({ eventIdentifier: eventIdentifier }));
  }, [dispatch, eventIdentifier]);

  useEffect(() => {
    currentEvent &&
      dispatch(
        getEventAttendeesList({
          eventIdentifier: currentEvent?.eventIdentifier || "",
        })
      );
    dispatch(
      getEventOrgOwner({
        organizationUuid: hiveUuid,
      })
    );
  }, [currentEvent, hiveUuid, dispatch]);

  const eventResp = useAppSelector(getEventRespSelector);
  const orgOwner = useAppSelector(getEventOrgOwnerSelector);
  const attendeesList = useAppSelector(getEventAttendeesListSelector);

  const handleInitRegister = () => {
    !!eventResp?.formDetails &&
    eventResp.formDetails.length > 0 &&
    eventResp.isFormFilled !== true
      ? handleFormRegister()
      : registerBg();
  };

  const registerBg = () => {
    if (!!currentEvent?.eventAmount) {
      navigate("payment");
    } else {
      dispatch(
        registerEventInBg({
          eventUuid: currentEvent?.eventUuid || "",
          eventIdentifier: currentEvent?.eventIdentifier || "",
          registerType: 1,
          attended: localStorage.getItem("atxd") === "txd",
        })
      );
      setMemberConfirm(true);
    }
  };

  useEffect(() => {
    if (register === "true") {
      localStorage.getItem("isLoggedIn") !== "yes"
        ? launchLogin()
        : handleInitRegister();
      searchParams.delete(REGISTER);
    }
  }, [dispatch, currentEvent]);

  useEffect(() => {
    if (!!currentEvent) {
      if (localStorage.getItem("axtd") === "txd") {
        localStorage.getItem("isLoggedIn") !== "yes"
          ? launchLogin()
          : handleInitRegister();
        searchParams.delete(REGISTER);
      }
    }
  }, [dispatch, currentEvent]);

  const [registerType, setRegisterType] = useState(eventResp?.hasAccess || 0);

  const handleSetRegister = (val: number) => {
    if (localStorage.getItem("isLoggedIn") === "yes") {
      dispatch(setEventRegisterType(val));
      if (val === 1 && !!currentEvent?.eventAmount) {
        if (!eventResp?.hasAccess) {
          navigate("payment");
        }
      } else {
        setRegisterType(val);
        dispatch(
          registerEvent({
            eventUuid: currentEvent?.eventUuid || "",
            eventIdentifier: currentEvent?.eventIdentifier || "",
            registerType: val,
          })
        );
        val === 1 && setMemberConfirm(true);
      }
    } else {
      launchLogin();
    }
  };

  const handleRegister = () => {
    if (localStorage.getItem("isLoggedIn") === "yes") {
    } else {
      localStorage.setItem("path", url);
      launchLogin();
    }
  };

  const handleCopy = () => {
    navigator.clipboard
      .writeText(`${document.location.href}?type=member`)
      .then(() => {
        if (!!document.location.href) {
          toastInfo("Copied link to clipboard");
        } else {
          toastInfo(
            "This story isnt available at the moment, please try another one"
          );
        }
      })
      .catch((error) => {
        console.error("Error copying text: ", error);
      });
  };

  const handleEdit = () => {
    navigate("edit");
  };

  const childComponents = useAppSelector(getChildSelector);

  const isLoading = useAppSelector(getEventIsLoadingSelector);
  const handleAttendeesClick = () => {
    if (!!childComponents[0]) {
      navigate("attendees");
    }
  };

  const registrationForm = useAppSelector(getEventRegistrationFormSelector);

  const [attendeeFormDetails, setAttendeeFormDetails] =
    useState<AttendeeFormDetailsReq[]>(registrationForm);

  useEffect(() => {
    setAttendeeFormDetails(registrationForm);
  }, [registrationForm]);

  const [openForm, setOpenForm] = useState(false);

  const handleFormRegister = () => {
    setOpenForm(true);
  };

  const handleFinalFormSubmit = () => {
    if (!!currentEvent?.eventAmount) {
      navigate("payment");
    } else {
      dispatch(
        registerFormEvent({
          eventUuid: currentEvent?.eventUuid || "",
          eventIdentifier: currentEvent?.eventIdentifier || "",
          registerType: 1,
          formDetails: attendeeFormDetails,
          attended: localStorage.getItem("atxd") === "txd",
        })
      );
      setOpenForm(false);
      setMemberConfirm(true);
    }
  };

  const [memberConfirm, setMemberConfirm] = useState(false);

  const handleMemberRegistration = () => {
    if (localStorage.getItem("isLoggedIn") === "yes") {
      dispatch(setEventRegisterType(1));
      !!eventResp?.formDetails &&
      eventResp.formDetails.length > 0 &&
      !eventResp?.isFormFilled
        ? handleFormRegister()
        : handleSetRegister(1);
    } else {
      launchLogin();
    }
  };

  const closeMemberConfirm = () => {
    setMemberConfirm(false);
  };

  useEffect(() => {
    dispatch(resetPromo(undefined));
  }, [dispatch]);

  return (
    <IslandLayout>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <div className="">
            <img
              alt="event_banner"
              className="home_event_banner"
              src={
                currentEvent?.eventBanner ||
                "https://veehivestage-images.s3.us-east-2.amazonaws.com/organizationImages/banner.png"
              }
            />
            <LineBreak />
          </div>
          {!!childComponents[0] && <EventCta />}
          <div className="event_copy_btn_wrapper">
            <div onClick={handleCopy} className="secondaryBtn event_copy_btn">
              <CopyLinkSVG />
              <p>Share</p>
            </div>
          </div>
          <div className="event_page_container">
            <div className="home_event_header">
              <img
                alt="event_logo"
                className="home_event_thumbnail"
                src={
                  currentEvent?.eventThumbnail ||
                  "https://images.veehive.ai/organizationImages/logo_org.png"
                }
              />
              <div>
                <h2>{currentEvent?.eventName}</h2>
                <h3 className="event_location_view_wrapper">
                  {currentEvent?.eventMedium === "online"
                    ? "Online"
                    : "In-Person"}{" "}
                  {"("}
                  {moment
                    .utc(currentEvent?.eventStartDate)
                    .local()
                    .format("LLL")}
                  {" - "}
                  {moment.utc(currentEvent?.eventEndDate).local().format("LLL")}
                  {")"}
                </h3>
                {!!currentEvent?.eventAmount && (
                  <h3 className="text-sm">
                    Entry Fee -{" "}
                    {currentEvent?.eventCurrency === "AED" ? "AED " : "$"}
                    {currentEvent?.eventAmount}
                  </h3>
                )}
              </div>
              {childComponents[0] && (
                <div onClick={handleEdit} className="edit_event_details_icon">
                  <EditIconSVG />
                </div>
              )}
            </div>
            <LineBreak />

            <div className="home_event_body">
              <div>
                <h2>
                  Posted by {eventResp?.organizationDetail.organizationName}
                </h2>

                <LineBreak />
                <p>{eventResp?.organizationDetail.organizationDesc}</p>
              </div>
              <hr></hr>
              <h2>Where</h2>
              <div className="event_deet_location">
                <LocationSVG />
                <p>
                  {currentEvent?.eventMedium === "online"
                    ? "Online"
                    : "In-Person"}
                  {" - "}
                  <a
                    className="link"
                    href={currentEvent?.eventAddress}
                    target="_blank"
                  >
                    {currentEvent?.eventLocation.slice(0, 50)}
                  </a>
                </p>
              </div>
              {!!currentEvent?.eventDescription && <hr></hr>}
              {!!currentEvent?.eventDescription && <h2>Event Details</h2>}
              {currentEvent?.eventDescription && (
                <p>{currentEvent.eventDescription.slice(0, 1000)}</p>
              )}
              {currentEvent?.eventVideo && <hr></hr>}
              {currentEvent?.eventVideo && (
                <ReactPlayer
                  muted={true}
                  url={currentEvent?.eventVideo}
                  width="300px"
                  height="533px"
                  controls
                  style={{
                    overflow: "hidden",
                    borderRadius: "15px",
                    background: "black",
                  }}
                />
              )}
              {!!attendeesList.length && attendeesList.length > 1 && <hr></hr>}
              {!!attendeesList.length && attendeesList.length > 1 && (
                <div className="title_and_limit">
                  <h2>{attendeesList.length} people attending</h2>
                  {!!childComponents[0] && (
                    <p onClick={handleAttendeesClick} className="link">
                      See all
                    </p>
                  )}
                </div>
              )}
              {!!attendeesList.length && attendeesList.length > 1 && (
                <div
                  onClick={handleAttendeesClick}
                  className={`event_attendee_profile_list ${
                    !!childComponents[0] && "pointer"
                  }`}
                >
                  {attendeesList.map((user, idx) => {
                    if (idx < 6)
                      return (
                        <img
                          key={idx}
                          src={
                            user.profilePhoto ||
                            "https://veehivestage-images.s3.us-east-2.amazonaws.com/profileImage/defaultAvatar.png"
                          }
                          className="event_attendee_profile"
                        />
                      );
                  })}
                </div>
              )}
              <GalleryBtn />
            </div>
            <LineBreak />
            {/* <div className="home_event_body_org_info">
              <div className="event_info_header">
                This event is part of a hive
              </div>
              <div className="home_event_body_org_info_content">
                <div className="title_and_limit">
                  <div>
                    <h2>{eventResp?.organizationDetail.organizationName}</h2>
                    <h3 className="text-gray-light">by {orgOwner?.userName}</h3>
                  </div>
                  <img
                    src={
                      orgOwner?.profilePhoto ||
                      "https://veehivestage-images.s3.us-east-2.amazonaws.com/profileImage/defaultAvatar.png"
                    }
                    alt="hive_owner_profile"
                    className="home_event_thumbnail"
                  />
                </div>
              </div>
            </div>
            <LineBreak /> */}

            {!!eventResp && eventResp.qrCodeLink && (
              <div className="home_event_body">
                <div className="flex justify-center items-center">
                  {/* <h3>Scan the below QR Code for entry</h3> */}
                  <img
                    src={eventResp.qrCodeLink}
                    width="150px"
                    height="150px"
                  />
                </div>
              </div>
            )}
          </div>
          <Dialog onClose={() => setOpenForm(false)} open={openForm}>
            {!!registrationForm && registrationForm.length > 0 && (
              <EventRegistrationForm
                handleFinalFormSubmit={handleFinalFormSubmit}
                attendeeFormDetails={attendeeFormDetails}
                setAttendeeFormDetails={setAttendeeFormDetails}
              />
            )}
          </Dialog>
          <div className="event_back_btn secondaryBtn">
            <NavLink to=".." className="go_back black_text">
              <svg
                width="18"
                height="14"
                viewBox="0 0 18 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M17 7H1M1 7L7 13M1 7L7 1"
                  stroke="black"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <p>Back</p>
            </NavLink>
          </div>
        </>
      )}
      {memberConfirm && (
        <MemberLoginConfirm
          isOpen={memberConfirm}
          title="member"
          close={closeMemberConfirm}
        />
      )}
      {currentEvent && (
        <EventRSVP
          handleMemberRegistration={handleMemberRegistration}
          handleSetRegister={handleSetRegister}
        />
      )}
    </IslandLayout>
  );
};

export default EventPage;
