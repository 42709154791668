import BackButton from "components/BackButton";
import ChatDayBlock from "components/Chat/components/ChatDayBlock";
import { useChatContext } from "components/Chat/context";
import ChatLayout from "components/ChatLayout";
import LineBreak from "components/LineBreak";
import AncholForPaggination from "components/common/AncholForPaggination";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { useScrollPagination } from "hooks/useScrollPagination";
import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { getChatbotChatroomMessages } from "store/async-actions/Hive/hivePage";
import { getHiveUuid } from "store/reducers/HiveDetails/hiveDetails";
import { chatbotChatroomMessagesSelector } from "store/reducers/HiveDetails/hiveSettings";

export const ViewChatBotMessages = () => {
  const dispatch = useAppDispatch();

  const location = useLocation();
  const url = location.pathname;
  const secondSlashIndex = url.indexOf("/", url.indexOf("/") + 1);
  const thirdSlashIndex = url.indexOf("/", secondSlashIndex + 1);
  const fourthSlashIndex = url.indexOf("/", thirdSlashIndex + 1);
  const fifthSlashIndex = url.indexOf("/", fourthSlashIndex + 1);
  const chatRoomId = url.substring(
    fourthSlashIndex + 1,
    fifthSlashIndex !== -1 ? fifthSlashIndex : url.length
  );

  const hiveUuid = useAppSelector(getHiveUuid);

  useEffect(() => {
    dispatch(
      getChatbotChatroomMessages({
        pageNo: 1,
        pageSize: 50,
        organizationUuid: hiveUuid,
        chatRoomId: chatRoomId,
      })
    );
  }, [dispatch, chatRoomId]);

  const chatList = useAppSelector(chatbotChatroomMessagesSelector);

  return (
    <div className="page_padding">
      <BackButton to="/home/settings/chatbot" />
      <LineBreak />
      <div className="chatbot_view__chat_container_wrapper">
        {chatList.map((chat, idx) => {
          return (
            <div className="chatbot_view__chat_container" key={idx}>
              <div className="response_chat">{chat.response_message}</div>
              <div className="sender_chat">{chat.request_message}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
