import React, { useEffect } from "react";
import ttv from "../../../assets/png/text_to_video.png";
import ftv from "../../../assets/png/form_to_video.png";
import lts from "../../../assets/png/longToShort.png";
import teaser from "../../../assets/png/teaser.png";
import stories from "../../../assets/png/stories_create.png";
import transpose from "../../../assets/png/transpose.png";
import pts from "../../../assets/png/pdf_to_story.png";
import LineBreak from "components/LineBreak/LineBreak";
import { NavLink, useNavigate } from "react-router-dom";
import ReadyVideos from "./ReadyVideos";
import { useAppDispatch } from "hooks/redux";
import {
  setTtvText,
  setActiveTemplate,
  clearAiImages,
  setIsUploaded,
  setShowNext,
} from "store/reducers/Ai/ai";

const AiHome = () => {
  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const handleStoriesCreate = () => {
    navigate("/story/create/upload", { state: { from: "/ai" } });
  };

  const handleClearAiImages = () => {
    dispatch(clearAiImages());
    dispatch(setIsUploaded(false));
    dispatch(setShowNext(false));
  };

  const clearAll = () => {
    dispatch(setTtvText(""));
    handleClearAiImages();
    dispatch(setActiveTemplate(undefined));
  };

  useEffect(() => {
    clearAll();
  }, [dispatch]);

  return (
    <div className="ai_home_container">
      <div className="ai_home_header_wrapper">
        <h4>Create new video</h4>
      </div>
      <LineBreak />
      <div className="ai_services_wrapper">
        <NavLink to="/ai/textToVideo">
          <img src={ttv} className="content_select_image" />
          <div className="content_select_description">
            <b>Text to video</b>
            <p>Generate videos with images and texts.</p>
          </div>
        </NavLink>
        <NavLink to="/ai/teaser">
          <img src={teaser} className="content_select_image" />
          <div className="content_select_description">
            <b>Teaser</b>
            <p>
              Generate short teaser from your videos of your preferred length.
            </p>
          </div>
        </NavLink>
        <NavLink to="/ai/pdfToVideo">
          <img src={ftv} className="content_select_image" />
          <div className="content_select_description">
            <b>PDF to video</b>
            <p>Generate videos from a pdf.</p>
          </div>
        </NavLink>
        <NavLink to="/ai/transpose">
          <img src={transpose} className="content_select_image" />
          <div className="content_select_description">
            <b>Horizontal to vertical</b>
            <p>
              Convert your existing horizontal videos to mobile friendly
              vertical videos
            </p>
          </div>
        </NavLink>
        <NavLink to="/ai/longToShort">
          <img src={lts} alt="" className="content_select_image" />
          <div className="content_select_description">
            <b>Long form to shortform</b>
            <p>Convert your long videos into byte sized videos.</p>
          </div>
        </NavLink>
        {localStorage.getItem("subDomain") === "ffmatches" && (
          <NavLink to="/ai/formToVideo">
            <img src={ftv} className="content_select_image" />
            <div className="content_select_description">
              <b>Form to video</b>
              <p>Generate videos from a form.</p>
            </div>
          </NavLink>
        )}
        <div onClick={handleStoriesCreate} className="pointer">
          <img src={stories} alt="" className="content_select_image" />
          <div className="content_select_description">
            <b>Story builder</b>
            <p>Create shareable stories with images and videos</p>
          </div>
        </div>
        <NavLink to="/ai/pdfToStory">
          <img src={pts} className="content_select_image" />
          <div className="content_select_description">
            <b>PDF to stories</b>
            <p>Create shareable stories from PDF.</p>
          </div>
        </NavLink>
      </div>
      <LineBreak />
      {/* <h3>Projects</h3> */}
      <LineBreak />
      {/* <ReadyVideos /> */}
    </div>
  );
};

export default AiHome;
