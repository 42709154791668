import LineBreak from "components/LineBreak";
import React, { useState, useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import TokenInput from "react-customize-token-input";
import "pages/UploadVideo/components/small_token_input.scss";

import { getHiveUuid } from "store/reducers/HiveDetails/hiveDetails";
import {
  clearAiImages,
  getActiveTemplateSelector,
  getSelectedTemplateSelector,
  getTtvGeneratedTextSelector,
  getTtvTextGenerateSelector,
  getUploadedAiImagesSelector,
  setActiveTemplate,
  setIsUploaded,
  setOpenDownloads,
  setShowNext,
  setTtvText,
} from "store/reducers/Ai/ai";
import BackButton from "components/BackButton/BackButton";
import AiDropzone from "components/AiDropzone";
import { generateTextToVideo } from "store/async-actions/Ai/ai";
import { profileUserIdSelector } from "store/reducers/Profile/profile";

const TTVNext = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const textGenerateResp = useAppSelector(getTtvTextGenerateSelector);

  const textGeneratedResp = useAppSelector(getTtvGeneratedTextSelector);

  useEffect(() => {
    if (!textGeneratedResp?.content_text) {
      navigate("/ai/textToVideo");
    }
  }, [textGeneratedResp]);

  const hiveUuid = useAppSelector(getHiveUuid);

  const [data, setData] = useState(textGeneratedResp?.content_text || "");

  const userId = useAppSelector(profileUserIdSelector);

  const activeTemplate = useAppSelector(getActiveTemplateSelector);
  const selectedTemplate = useAppSelector(getSelectedTemplateSelector);

  const handleCreateTTV = () => {
    const formData = new FormData();
    formData.append("user_id", userId.toString());
    formData.append("text", data);
    uploadedImages.map((image) => {
      formData.append("images", image, image.name);
    });
    formData.append("organizationUuid", hiveUuid);
    formData.append("templateId", activeTemplate);
    formData.append("videoEntry", textGenerateResp);
    formData.append("videoId", textGeneratedResp?.video_id || "");
    formData.append(
      "durationInSeconds",
      selectedTemplate?.duration.toString() || "0"
    );
    dispatch(generateTextToVideo(formData));
    navigate("..");
    dispatch(setOpenDownloads(true));
  };

  const [values, setValues] = useState(textGeneratedResp?.content_tags || []);

  const uploadedImages = useAppSelector(getUploadedAiImagesSelector);

  const handleTextChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setData(e.target.value);
  };

  return (
    <div className="upload_info_container">
      <div className="metrics_backBtn_spacing">
        <BackButton to="/ai/textToVideo" />
      </div>
      <div className="upload_info_margins">
        <h3>Review Video</h3>
        <p className="text-sm">Make changes to your video</p>
      </div>
      <div className="ttv_next_content_wrapper">
        <div className="ttv_next_content_wrapper_element">
          <img
            src={
              uploadedImages[0]
                ? URL.createObjectURL(uploadedImages[0])
                : "https://veehivestage-images.s3.us-east-2.amazonaws.com/channelImages/defaultChannelLogo.jpg"
            }
            className="ttv_next_content"
          />
        </div>
        <div className="ttv_next_content_description_wrapper">
          {data.slice(0, 120)}
        </div>
      </div>
      <h4 className="tags">Replace Images (optional)</h4>
      <LineBreak />
      <AiDropzone />

      <div>
        <div className="upload_info_margins">
          <div className="title_and_limit">
            <h4 className="upload_headings">Tags</h4>
            <div className="character_limit text-sm">*max 5 tags</div>
          </div>
          {/* <LineBreak /> */}
          <TokenInput
            separators={[" ", ",", ";"]}
            tokenValues={values!.slice(0, 5)}
            onTokenValuesChange={setValues}
            className=""
            placeholder={`Tags separated by commas`}
          />
        </div>
        <div className="text_box_container h-100">
          <h4 className="upload_headings">Description</h4>
          <LineBreak />
          <div className="ttv_text_area_wrapper">
            <textarea
              value={data}
              onChange={handleTextChange}
              className="ttv_text_area"
              placeholder="Test description"
            ></textarea>
          </div>
        </div>
      </div>
      <div className="button_rows-edit_video">
        <NavLink to=".." className="secondaryBtn half_width">
          Discard
        </NavLink>
        <>
          {data.length > 0 ? (
            <div onClick={handleCreateTTV} className="primaryBtn half_width">
              Create
            </div>
          ) : (
            <div className="disabledBtn half_width">Create</div>
          )}
        </>
      </div>
    </div>
  );
};

export default TTVNext;
