import BackButton from "components/BackButton/BackButton";
import IslandLayout from "components/IslandLayout/IslandLayout";
import LineBreak from "components/LineBreak/LineBreak";
import PageLayout from "components/PageLayout/PageLayout";
import React from "react";
import { Outlet } from "react-router-dom";

const CreateStoryContainer = () => {
  return (
    <PageLayout sideMenu={true}>
      <IslandLayout>
        <div className="create_story_container story_home_container">
          <LineBreak />
          <h2>Upload a story</h2>
          <LineBreak />
          <p>Create captivating stories and mobile-optimized videos.</p>
          <LineBreak />
          <Outlet />
        </div>
      </IslandLayout>
    </PageLayout>
  );
};

export default CreateStoryContainer;
