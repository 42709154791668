import { CircularProgress, TextField } from "@mui/material";
import LineBreak from "components/LineBreak";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import React, { useEffect, useState } from "react";
import {
  getChatbotPersonaSelector,
  getHiveContextSelector,
  getHiveIsEditingSelector,
  getHiveSelector,
  getShowChatBotSelector,
  setChatbotPersona,
  setHiveContext,
  setShowChatBot,
} from "store/reducers/HiveDetails/hiveDetails";
import Switch from "@mui/joy/Switch";
import { StyledEngineProvider, CssVarsProvider } from "@mui/joy/styles";
import {
  editHiveContext,
  editHiveDetails,
  getHiveContext,
} from "store/async-actions/Hive/hivePage";
import HiveProducts from "../HiveProducts";
import { toastError } from "hooks/useToastify";
import PageLayout from "components/PageLayout";
import IslandLayout from "components/IslandLayout";
import ViewChatBotRooms from "./ViewChatBotRooms";

const ChatBotSettings = () => {
  const dispatch = useAppDispatch();

  const hiveDetails = useAppSelector(getHiveSelector);

  const hiveContext = useAppSelector(getHiveContextSelector);

  const persona = useAppSelector(getChatbotPersonaSelector);

  useEffect(() => {
    dispatch(
      getHiveContext({ organizationUuid: hiveDetails?.communityUuid || "" })
    );
  }, [dispatch]);

  const handleContextChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    dispatch(setHiveContext(e.target.value.slice(0, 3000)));
  };

  const showChatBot = useAppSelector(getShowChatBotSelector);
  const isLoading = useAppSelector(getHiveIsEditingSelector);

  const handleSave = () => {
    if (hiveDetails) {
      dispatch(
        editHiveContext({
          organizationContext: hiveContext,
          organizationName: hiveDetails.communityName,
          organizationUuid: hiveDetails.communityUuid,
          organizationId: hiveDetails.communityId,
          organizationPersona: persona,
        })
      );

      dispatch(
        editHiveDetails({
          communityName: hiveDetails.communityName || "",
          communityTier: "",
          communityLogo: "",
          communityBanner: "",
          marketPlace: "COMMUNITY",
          longDescription: hiveDetails.longDescription,
          communityUuid: hiveDetails?.communityUuid!,
          communitySubDomain: localStorage.getItem("subDomain")!,
          communityId: hiveDetails?.communityId!,
          communityBio: "",
          description: "",
          communityBioFlag: false,
          communityNameFlag: false,
          descriptionFlag: false,
          communityTierFlag: false,
          marketPlaceFlag: false,
          communityLogoFlag: false,
          communityBannerFlag: false,
          longDescriptionFlag: false,
          communityWebLogo: "",
          communityWebLogoFlag: false,
          introVideo: "",
          introVideoFlag: false,
          communitySubDomainFlag: false,
          showSuggested: false,
          showSuggestedFlag: false,
          chatSupportEnabled: showChatBot,
          chatSupportEnabledFlag: true,
          customDomain: "",
          customDomainFlag: false,
          introVideoThumbnail: "",
          introVideoThumbnailType: "",
          introVideoThumbnailFlag: false,
        })
      );
    }
  };

  const handleContextError = () => {
    toastError("Set a detailed context of your hive for your Ai bot");
  };

  return (
    // <PageLayout sideMenu={true}>
    //   <IslandLayout>
    <div className="page_padding">
      <h2>Chat Bot Configuration</h2>
      <p>
        Customize your chat bot's behavior and persona with easy-to-use
        configuration options.
      </p>
      <LineBreak />
      <StyledEngineProvider injectFirst>
        <CssVarsProvider>
          <div className="hive_setting_switch_wrapper">
            <Switch
              checked={showChatBot}
              onChange={(event) =>
                dispatch(setShowChatBot(event.target.checked))
              }
            />
            <p>Enable ChatBot</p>
          </div>
        </CssVarsProvider>
      </StyledEngineProvider>
      <LineBreak />
      <h4>Give your chatbot a name</h4>
      <TextField
        value={persona}
        onChange={(e) =>
          dispatch(setChatbotPersona(e.target.value.slice(0, 50)))
        }
        variant="standard"
        placeholder="eg: Customer Service, Digital Concierge etc."
        style={{ fontSize: "14px" }}
      />
      <LineBreak />
      {showChatBot && (
        <div>
          <LineBreak />
          <div className="title_and_limit">
            <h4>Set context*</h4>
            <div className="character_limit text-sm">
              {!!hiveContext && hiveContext.length}/3000
            </div>
          </div>{" "}
          <LineBreak />
          <p>
            Elaborate on your hive's characteristics, functionalities, and
            capabilities to enable our AI to construct a brand twin for your
            hive.
          </p>
          <LineBreak />
          <textarea
            name="hiveContext"
            value={hiveContext}
            onChange={handleContextChange}
            className="input_border text_padding input_width_full who_are_we_section_text"
            placeholder="An extensive description about your hive"
          />
          <LineBreak />
          <h4>Products</h4>
          <LineBreak />
          <HiveProducts />
        </div>
      )}

      <>
        {isLoading ? (
          <div className="nextBtn primaryBtn">
            <CircularProgress size={20} color="inherit" />
          </div>
        ) : (
          <>
            {showChatBot ? (
              <>
                {!!hiveContext && hiveContext.length > 10 ? (
                  <div onClick={handleSave} className="nextBtn primaryBtn">
                    Save
                  </div>
                ) : (
                  <div
                    onClick={handleContextError}
                    className="nextBtn disabledBtn"
                  >
                    Save
                  </div>
                )}
              </>
            ) : (
              <div onClick={handleSave} className="nextBtn primaryBtn">
                Save
              </div>
            )}
          </>
        )}
      </>

      {/* <ViewChatBotRooms /> */}
    </div>
    //   </IslandLayout>
    // </PageLayout>
  );
};

export default ChatBotSettings;
