import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  HiveActivitiesItem,
  HiveContentItem,
  OnlineMembersModel,
} from "api/models/Hive/hiveActivities";
import {
  MemberDetailItem,
  MembersItemModel,
} from "api/models/Hive/hiveMembers";
import {
  getHiveActivities,
  getHiveListUsers,
  getOnlineMembers,
} from "store/async-actions/Hive/hivePage";
import { createTypedSelector } from "store/utils";

const initialState: {
  hiveActivities: HiveActivitiesItem | null;
  lessHiveDetails: HiveContentItem[];
  isFetching: boolean;
  onlineMembers: OnlineMembersModel[];
  clickedProfileId: string;
  csvSuccessMessage: string;
  csvErrorMessage: string;
  importCount: number;
} = {
  csvSuccessMessage: "",
  csvErrorMessage: "",
  importCount: 0,
  hiveActivities: null,
  lessHiveDetails: [],
  isFetching: true,
  onlineMembers: [],
  clickedProfileId: "",
};

const hiveActivitiesSlice = createSlice({
  name: "hiveActivities",
  initialState,
  reducers: {
    setClickedProfileId: (state, action: PayloadAction<string>) => {
      state.clickedProfileId = action.payload;
    },
    setCSVSuccessMessage: (state, action: PayloadAction<string>) => {
      state.csvSuccessMessage = action.payload;
    },
    setCSVErrorMessage: (state, action: PayloadAction<string>) => {
      state.csvErrorMessage = action.payload;
    },
    setImportCount: (state, action: PayloadAction<number>) => {
      state.importCount = action.payload;
    },
  },
  extraReducers: {
    [getHiveActivities.pending.type]: (state) => {
      state.isFetching = true;
    },
    [getHiveActivities.fulfilled.type]: (
      state,
      action: PayloadAction<HiveActivitiesItem>
    ) => {
      state.isFetching = false;
      state.hiveActivities = action.payload;
      state.lessHiveDetails = state.hiveActivities.content.slice(0, 6);
    },
    [getHiveActivities.rejected.type]: (state) => {
      // state.doesExist = false;
      state.isFetching = false;
    },
    [getHiveActivities.pending.type]: (state) => {
      // to do
    },
    [getHiveActivities.rejected.type]: (state) => {
      // to do
      state.isFetching = false;
    },

    //Online Members
    [getOnlineMembers.pending.type]: (state) => {
      state.isFetching = true;
    },
    [getOnlineMembers.fulfilled.type]: (
      state,
      action: PayloadAction<OnlineMembersModel[]>
    ) => {
      state.isFetching = false;
      state.onlineMembers = action.payload;
    },
    [getOnlineMembers.rejected.type]: (state) => {
      // to do
      state.isFetching = false;
    },
  },
});

export const {
  setClickedProfileId,
  setCSVSuccessMessage,
  setCSVErrorMessage,
  setImportCount,
} = hiveActivitiesSlice.actions;

export const getClickedProfileIdSelector = createTypedSelector(
  (state) => state.activities.clickedProfileId
);

export const getHiveActivitiesSelector = createTypedSelector(
  (state) => state.activities.hiveActivities
);

export const getCSVSuccessMessageSelector = createTypedSelector(
  (state) => state.activities.csvSuccessMessage
);

export const getCSVErrorMessageSelector = createTypedSelector(
  (state) => state.activities.csvErrorMessage
);

export const getImportCountSelector = createTypedSelector(
  (state) => state.activities.importCount
);

export const getOnlineMembersSelector = createTypedSelector(
  (state) => state.activities.onlineMembers
);

export const lessHiveDetailsSelector = createTypedSelector(
  (state) => state.activities.lessHiveDetails
);

export const getActivitiesIsFetchingSelector = createTypedSelector(
  (state) => state.activities.isFetching
);

export default hiveActivitiesSlice.reducer;
