import { useCallback, useEffect, useRef } from "react";
import { useParams, Params } from "react-router-dom";
import { AxiosProgressEvent } from "axios";

import { useAppDispatch, useAppSelector } from "hooks/redux";
import PageLayout from "components/PageLayout";
import NavigationSections from "./components/NavigationSections";
import Chat from "components/Chat";
import {
  getChatRoomNameSelector,
  getOrganizationUuidSelector,
} from "store/reducers/Chat/chatrooms";
// import {
//   getProfileUserIdSelector,
//   getProfileUserNameSelector,
// } from "store/reducers/profile";
import {
  sendChatMessage,
  sendChatAttachmentMessage,
  sendVoiceMessage,
} from "store/async-actions/Chat/chat";

import { useChatMessages } from "./useChatMessages";
import { useMentioningUsersList } from "./useMentioningUsersList";
import { useSearchMe } from "./useSearchMe";
import { getIsFetchingSendMessageSelector } from "store/reducers/Chat/chat";
import IslandLayout from "components/IslandLayout";
import {
  getProfileUsernameSelector,
  profileUserIdSelector,
} from "store/reducers/Profile/profile";
import { useChatsSocket } from "services/socket";

type AvailableFormDataKeys =
  | "chatRoomId"
  | "message"
  | "userName"
  | "organizationUuid"
  | "file"
  | "fileName"
  | "type"
  | "mentionUsersIds";
interface TypedFormData extends FormData {
  append(
    name: AvailableFormDataKeys,
    value: string | Blob,
    fileName?: string
  ): void;
}

const ChatRoom = () => {
  const dispatch = useAppDispatch();
  const { roomId = "" } = useParams<Params<"roomId">>();
  const roomName = useAppSelector(getChatRoomNameSelector);
  const userId = useAppSelector(profileUserIdSelector);
  const userName = useAppSelector(getProfileUsernameSelector);
  const organizationUuid = useAppSelector(getOrganizationUuidSelector);
  const isLoadingAttachment = useAppSelector(getIsFetchingSendMessageSelector);

  const { loadMoreMessageList, chatList, isLoading } = useChatMessages(roomId);
  const { usersList } = useMentioningUsersList(roomId);
  const {
    isShowSearchControls,
    closeSearchControls,
    chatListWithMention,
    detectSearchSymbol,
    chatListWithMentionLength,
  } = useSearchMe(roomId, userId);

  const chatContainerRef = useRef<HTMLDivElement>(null);

  const handleSendMessage = useCallback(
    async ({
      content = "",
      file,
      type = "attachment",
      cb,
      abortSignal,
      mentionUsersIds = [],
    }: SendMessageParams<AxiosProgressEvent, AbortSignal>) => {
      if (roomId && userId) {
        if (file) {
          const formData = new FormData() as TypedFormData;
          formData.append("chatRoomId", roomId);
          formData.append("message", content);
          formData.append("userName", userName);
          formData.append("organizationUuid", organizationUuid);
          formData.append("file", file as File);
          formData.append("fileName", file.name);
          if (type === "voice") {
            formData.append("type", "voice");
            dispatch(sendVoiceMessage(formData));
          }
          if (type === "attachment") {
            formData.append("type", "attachment");
            formData.append("mentionUsersIds", JSON.stringify(mentionUsersIds));
            await dispatch(
              sendChatAttachmentMessage({ body: formData, cb, abortSignal })
            )
              .unwrap()
              .catch((error) => {
                throw error;
              });
          }
        } else {
          dispatch(
            sendChatMessage({
              chatRoomId: roomId,
              message: content,
              type: "text",
              userId,
              userName,
              mentionUsersIds,
            })
          );
        }
      }
      // if (chatContainerRef.current) {
      //   chatContainerRef.current.scrollTop =
      //     chatContainerRef.current.scrollHeight;
      // }
    },
    [dispatch, organizationUuid, roomId, userId, userName]
  );

  // useEffect(() => {
  //   // Scroll to the bottom of the chat container on initial load and when chatList changes
  //   if (chatContainerRef.current) {
  //     chatContainerRef.current.scrollTop = 1000;
  //   }
  // }, [chatList]);

  return (
    <div className="chat_room_wrapper" ref={chatContainerRef}>
      <IslandLayout>
        <div className="chat_room_container">
          <NavigationSections roomName={roomName} />
          {/* <PageLayout sideMenu={false}> */}
          {/* <PageLayout className="chat_room_page"> */}
          <Chat
            handlePagginationUpdate={loadMoreMessageList}
            sendMessage={handleSendMessage}
            loading={isLoading}
            chatList={
              isShowSearchControls && chatListWithMention.length
                ? chatListWithMention
                : chatList
            }
            usersList={usersList}
            loadingAttachment={isLoadingAttachment}
            isShowSearchControls={isShowSearchControls}
            detectSearchSymbol={detectSearchSymbol}
            closeSearchControls={closeSearchControls}
            searchMentionMessagesCount={chatListWithMentionLength}
          />
        </div>
        {/* </PageLayout> */}
      </IslandLayout>
    </div>
  );
};

export default ChatRoom;
