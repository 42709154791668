import React, { useEffect } from "react";
import PurchaseDetailTable from "./PurchaseDetailTable";
import ChannelMetricsContainer from "./ChannelMetricsContainer";
import LineBreak from "components/LineBreak/LineBreak";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import {
  getAnalyticsChannelMetrics,
  getPurchaseDetailList,
} from "store/async-actions/Analytics/analyticsActions";
import {
  getCurrentChannelIdSelector,
  getPurchaseDetailListSelector,
} from "store/reducers/Analytics/analytics";
import BackButton from "components/BackButton/BackButton";
import ChannelDetailsMetrics from "./ChannelDetailMetrics";

const AnalyticsChannelDetails = () => {
  const currentChannelId = useAppSelector(getCurrentChannelIdSelector);

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(
      getPurchaseDetailList({
        channelId: currentChannelId,
      })
    );
    dispatch(
      getAnalyticsChannelMetrics({
        channelId: currentChannelId,
      })
    );
  }, [dispatch, currentChannelId]);

  const channel = useAppSelector(getPurchaseDetailListSelector);

  return (
    <>
      <div className="metrics_backBtn_spacing">
        <BackButton to=".." />
      </div>
      <ChannelDetailsMetrics />
      <LineBreak />
      {!!channel && channel.length > 0 && <PurchaseDetailTable />}
    </>
  );
};

export default AnalyticsChannelDetails;
