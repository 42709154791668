import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "api";
import { CartIdListForCheckoutModel } from "api/models/Channel/cartModel";
import { toastError } from "hooks/useToastify";

export const addToCart = createAsyncThunk(
  "payment/addToCart",
  async (
    body: [
      {
        cartItemName: string;
        cartItemType: string;
        cartItemReferenceId: number;
        quantity: number;
        communityId: number;
      }
    ],
    { rejectWithValue, dispatch }
  ) => {
    try {
      const { data } = await api.channelPage.addToCart(body);
      dispatch(
        getCartList({
          communityId: body[0].communityId,
        })
      );
      return data.data;
    } catch (error) {
      return rejectWithValue("Something went wrong");
    }
  }
);
export const getCartList = createAsyncThunk(
  "payment/getCartList",
  async (
    body: {
      communityId: number;
    },
    { rejectWithValue, dispatch }
  ) => {
    try {
      const { data } = await api.channelPage.getCartList(body);
      data.data[0] &&
        dispatch(
          createRazorPayOrder({
            cartItems: [
              {
                cartId: data.data[0].id,
                referenceId: data.data[0].cartItemReferenceId,
              },
            ],
            userId: data.data[0].userId,
            countryCode: localStorage.getItem("countryCode")!,
          })
        );
      return data.data;
    } catch (error) {
      return rejectWithValue("Something went wrong");
    }
  }
);
export const createRazorPayOrder = createAsyncThunk(
  "payment/createRazorPayOrder",
  async (
    body: {
      cartItems: CartIdListForCheckoutModel[];
      userId: number;
      countryCode: string;
    },
    { rejectWithValue }
  ) => {
    try {
      const { data } = await api.channelPage.createRazorPayOrder(body);
      return data.data;
    } catch (error) {
      return rejectWithValue("Something went wrong");
    }
  }
);
export const razorPayVerifyPayment = createAsyncThunk(
  "payment/razorPayVerifyPayment",
  async (
    body: {
      orderId: string;
      paymentId: string;
      signature: string;
    },
    { rejectWithValue }
  ) => {
    try {
      const { data } = await api.channelPage.razorPayVerifyPayment(body);
      return data.data;
    } catch (error) {
      return rejectWithValue("Something went wrong");
    }
  }
);
export const chargeForPayment = createAsyncThunk(
  "payment/chargeForPayment",
  async (
    body: {
      customerId: number;
      stripeToken: string;
      cartItems: CartIdListForCheckoutModel[];
      userId: number;
      countryCode: string;
      currency: string | null;
    },
    { rejectWithValue }
  ) => {
    try {
      const { data } = await api.channelPage.chargeForPayment(body);
      return data;
    } catch (error) {
      toastError("Payment Failed");
      return rejectWithValue("Something went wrong");
    }
  }
);
