import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UploadContentItem } from "api/models/Videos/uploadContent";
import {
  getPresignedUrl,
  getVideoDetails,
  readyToUpload,
  updateVideoStatus,
  uploadContent,
  uploadToS3,
} from "store/async-actions/Videos/videosActions";
import { createTypedSelector } from "store/utils";
import { blobToURL, fromURL } from "image-resize-compress";
import { VideoListItem } from "api/models/Videos/videoList";

interface VideoState {
  file: File | null;
  contentUploadResp: UploadContentItem | undefined;
  isUploading: boolean;
  // formData: any;
  s3Resp: boolean;
  s3Uploaded: boolean;
  thumbnail: any;
  isUpdating: boolean;
  isUpdated: boolean;
  stillInEC2: boolean;
  contentType: string;
  spotlightSlide: number;
  showSpotLightTour: boolean;
  isYTUpload: number;
  youtubeUrl: string;
  isFetching: boolean;
}

const initialState: VideoState = {
  isFetching: false,
  stillInEC2: false,
  file: null,
  contentUploadResp: undefined,
  isUploading: false,
  // formData: null,
  s3Resp: false,
  s3Uploaded: false,
  thumbnail: null,
  isUpdating: false,
  isUpdated: false,
  contentType: "",
  spotlightSlide: 0,
  showSpotLightTour: false,
  isYTUpload: 0,
  youtubeUrl: "",
};

export const videoSlice = createSlice({
  name: "videos",
  initialState,
  reducers: {
    setVideoFile: (state, action: PayloadAction<File | null>) => {
      state.file = action.payload;
    },
    removeVideoFile: (state) => {
      state.file = null;
    },
    setThumbnail: (state, action: PayloadAction<any>) => {
      state.thumbnail = action.payload;
    },
    setS3Done: (state, action: PayloadAction<boolean>) => {
      state.s3Uploaded = action.payload;
    },
    setShowSpotlightTour: (state, action: PayloadAction<boolean>) => {
      state.showSpotLightTour = action.payload;
    },
    setSpotlightTourSlide: (state, action: PayloadAction<number>) => {
      state.spotlightSlide = action.payload;
    },
    setContentType: (state, action: PayloadAction<string>) => {
      state.contentType = action.payload;
    },
    setIsUploading: (state, action: PayloadAction<boolean>) => {
      state.isUploading = action.payload;
    },
    setContentUploadResp: (state, action: PayloadAction<any>) => {
      state.contentUploadResp = action.payload;
    },
    setIsYTUpload: (state, action: PayloadAction<number>) => {
      state.isYTUpload = action.payload;
    },
    setYoutubeUrl: (state, action: PayloadAction<string>) => {
      state.youtubeUrl = action.payload;
    },
  },
  extraReducers: {
    //Update Video Status
    [updateVideoStatus.pending.type]: (state) => {
      state.isUpdating = true;
      state.isUpdated = false;
    },
    [updateVideoStatus.fulfilled.type]: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.isUpdated = action.payload;
      state.isUpdating = false;
    },
    [updateVideoStatus.rejected.type]: (state) => {
      state.isUpdating = false;
      state.isUpdated = false;
    },

    //Get presigned URL
    [uploadContent.pending.type]: (state) => {
      state.isUploading = true;
    },
    [uploadContent.fulfilled.type]: (
      state,
      action: PayloadAction<UploadContentItem>
    ) => {
      state.contentUploadResp = action.payload;
      // state.isUploading = false;
    },
    [uploadContent.rejected.type]: (state) => {
      // state.isUploading = false;
    },

    //Get presigned URL for reupload
    [getPresignedUrl.pending.type]: (state) => {
      state.isUploading = true;
    },
    [getPresignedUrl.fulfilled.type]: (
      state,
      action: PayloadAction<UploadContentItem>
    ) => {
      state.contentUploadResp = action.payload;
      // state.isUploading = false;
    },
    [getPresignedUrl.rejected.type]: (state) => {
      // state.isUploading = false;
    },

    // Upload to s3 api call below
    [uploadToS3.pending.type]: (state) => {
      state.isUploading = true;
      state.s3Uploaded = false;
    },
    [uploadToS3.fulfilled.type]: (state, action: PayloadAction<any>) => {
      state.s3Resp = action.payload;
      state.s3Uploaded = true;
      state.isUploading = false;
    },
    [uploadToS3.rejected.type]: (state) => {
      state.s3Uploaded = true;
      state.isUploading = false;
    },

    //Check if video upload is ready
    [readyToUpload.pending.type]: (state) => {
      state.stillInEC2 = false;
    },
    [readyToUpload.fulfilled.type]: (state, action: PayloadAction<boolean>) => {
      state.stillInEC2 = !action.payload;
    },
    [readyToUpload.rejected.type]: (state) => {
      state.stillInEC2 = false;
    },
  },
});

export const populateFormData = (
  formData: FormData,
  contentUploadResp: UploadContentItem,
  file: File
) => {
  formData.append(
    "x-amz-meta-userid",
    contentUploadResp?.fields["x-amz-meta-userid"]!
  );
  formData.append("Content-Type", "video/mp4");
  formData.append("key", contentUploadResp?.fields.key!);
  formData.append("bucket", contentUploadResp?.fields.bucket!);
  formData.append(
    "X-Amz-Algorithm",
    contentUploadResp?.fields["X-Amz-Algorithm"]!
  );
  formData.append(
    "X-Amz-Credential",
    contentUploadResp?.fields["X-Amz-Credential"]!
  );
  formData.append("X-Amz-Date", contentUploadResp?.fields["X-Amz-Date"]!);
  formData.append(
    "X-Amz-Security-Token",
    contentUploadResp?.fields["X-Amz-Security-Token"]!
  );
  formData.append(
    "X-Amz-Signature",
    contentUploadResp?.fields["X-Amz-Signature"]!
  );
  formData.append("Policy", contentUploadResp?.fields.Policy!);
  formData.append("file", file!);
};

export const getVideoThumbnail = (file: File) => {
  var fileURL = URL.createObjectURL(file);
  const importFileandPreview = (
    file: Blob | MediaSource,
    revoke: undefined
  ) => {
    return new Promise((resolve, reject) => {
      window.URL = window.URL || window.webkitURL;
      let preview = window.URL.createObjectURL(file);
      // remove reference
      if (revoke) {
        window.URL.revokeObjectURL(preview);
      }
      setTimeout(() => {
        resolve(preview);
      }, 100);
    });
  };
  return new Promise((resolve, reject) => {
    if (file.type.match("video")) {
      importFileandPreview(file, undefined).then((urlOfFIle) => {
        var video = document.createElement("video");
        var timeupdate = function () {
          if (snapImage()) {
            video.removeEventListener("timeupdate", timeupdate);
            video.pause();
          }
        };
        video.addEventListener("loadeddata", function () {
          if (snapImage()) {
            video.removeEventListener("timeupdate", timeupdate);
          }
        });
        var snapImage = function () {
          var canvas = document.createElement("canvas");
          canvas.width = video.videoWidth;
          canvas.height = video.videoHeight;
          canvas
            .getContext("2d")!
            .drawImage(video, 0, 0, canvas.width, canvas.height);
          var image = canvas.toDataURL("image/png", 0.1);
          image = image.substring(image.indexOf(",") + 1);
          var success = image.length > 100000;
          if (success) {
            URL.revokeObjectURL(fileURL);
            const width = 0;
            const height = 0;
            const format = "webp";

            image = "data:image/jpeg;base64," + image;
            fromURL(image, 90, width, height, "png").then((firstBlob) => {
              fromURL(
                image,
                firstBlob.size < 300000
                  ? 90
                  : firstBlob.size < 500000
                  ? 80
                  : firstBlob.size < 700000
                  ? 60
                  : 45,
                width,
                height,
                format
              ).then((blob) => {
                blobToURL(blob).then((url) => {
                  var newFile = url.split(",")[1];
                  resolve(newFile);
                  return newFile;
                });
              });
            });
          }
          return success;
        };
        video.addEventListener("timeupdate", timeupdate);
        video.preload = "metadata";
        video.src = fileURL;
        // Load video in Safari / IE11
        video.muted = true;
        video.playsInline = true;
        video.play();
      });
    } else {
      reject("file not valid");
    }
  });
};

export const {
  setVideoFile,
  setContentType,
  setSpotlightTourSlide,
  setShowSpotlightTour,
  setIsYTUpload,
  setYoutubeUrl,
} = videoSlice.actions;
export const { removeVideoFile } = videoSlice.actions;
export const { setThumbnail } = videoSlice.actions;
export const { setS3Done, setIsUploading } = videoSlice.actions;
export const { setContentUploadResp } = videoSlice.actions;

// export const getFormData = createTypedSelector(
//   (state) => state.videos.formData
// );

export const getContentTypeSelector = createTypedSelector(
  (state) => state.videos.contentType
);

export const getYoutubeUrlSelector = createTypedSelector(
  (state) => state.videos.youtubeUrl
);

export const getIsYTUploadSelector = createTypedSelector(
  (state) => state.videos.isYTUpload
);

export const getSpotlightSlideSelector = createTypedSelector(
  (state) => state.videos.spotlightSlide
);

export const getShowSpotlightSelector = createTypedSelector(
  (state) => state.videos.showSpotLightTour
);

export const getStillInEc2Selector = createTypedSelector(
  (state) => state.videos.stillInEC2
);

export const videoUpdatedSelector = createTypedSelector(
  (state) => state.videos.isUpdated
);

export const getUrl = createTypedSelector(
  (state) => state.videos.contentUploadResp?.url
);

export const s3Uploaded = createTypedSelector(
  (state) => state.videos.s3Uploaded
);

export const isUploadingSelector = createTypedSelector(
  (state) => state.videos.isUploading
);

export const thumbnailSelector = createTypedSelector(
  (state) => state.videos.thumbnail
);

export const uploadFileSelector = createTypedSelector(
  (state) => state.videos.file
);

export const contentUploadRespSelector = createTypedSelector(
  (state) => state.videos.contentUploadResp
);

export default videoSlice.reducer;
