import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "api";
import { FeedRequestModel } from "api/models/Feed/Feed";
import {
  RazorPaySubReq,
  RazorPaySubVerifyReq,
  StripeRequestModel,
} from "api/models/Subscriptions/subscriptions";
import { toastError, toastSuccess } from "hooks/useToastify";
import { RootState } from "store";

export const getAllPlans = createAsyncThunk(
  "subscriptions/getAllPlans",
  async (body: { organizationUuid: string }, { rejectWithValue }) => {
    try {
      const { data } = await api.subscription.getAllPlans(body);
      return data.data;
    } catch (error) {
      return rejectWithValue("Something went wrong");
    }
  }
);

export const getCurrentPlan = createAsyncThunk(
  "subscriptions/getCurrentPlan",
  async (body: { organizationId: number }, { rejectWithValue }) => {
    try {
      const { data } = await api.subscription.getCurrentPlan(body);
      return data.data;
    } catch (error) {
      return rejectWithValue("Something went wrong");
    }
  }
);

export const getPlanDetails = createAsyncThunk(
  "subscriptions/getPlanDetails",
  async (body: { planUuid: string }, { rejectWithValue }) => {
    try {
      const { data } = await api.subscription.getPlanDetails(body);
      return data.data;
    } catch (error) {
      return rejectWithValue("Something went wrong");
    }
  }
);

export const stripePayment = createAsyncThunk(
  "subscriptions/stripePayment",
  async (body: StripeRequestModel, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await api.subscription.stripePurchase(body);

      dispatch(
        getCurrentPlan({
          organizationId: body.organizationId,
        })
      );

      return data;
    } catch (error) {
      return rejectWithValue("Something went wrong");
    }
  }
);

export const changeStripePlan = createAsyncThunk(
  "subscriptions/changeStripePlan",
  async (
    body: {
      organizationUuid: string;
      planUuid: string;
    },
    { rejectWithValue, dispatch, getState }
  ) => {
    try {
      const { data } = await api.subscription.changeStripePlan(body);

      const { hive } = getState() as RootState;

      dispatch(
        getCurrentPlan({
          organizationId: hive.hiveDetails?.communityId || 0,
        })
      );

      toastSuccess("Changed your Plan successfully");

      return data;
    } catch (error) {
      return rejectWithValue("Something went wrong");
    }
  }
);

export const changeRazorPlan = createAsyncThunk(
  "subscriptions/changeRazorPlan",
  async (
    body: {
      organizationUuid: string;
      planUuid: string;
    },
    { rejectWithValue, dispatch, getState }
  ) => {
    try {
      const { data } = await api.subscription.changeRazorPlan(body);

      const { hive } = getState() as RootState;

      dispatch(
        getCurrentPlan({
          organizationId: hive.hiveDetails?.communityId || 0,
        })
      );

      toastSuccess("Changed your Plan successfully");

      return data;
    } catch (error) {
      return rejectWithValue("Something went wrong");
    }
  }
);

export const razorPaySub = createAsyncThunk(
  "subscriptions/razorPaySub",
  async (body: RazorPaySubReq, { rejectWithValue }) => {
    try {
      const { data } = await api.subscription.razorPaySub(body);

      return data;
    } catch (error) {
      return rejectWithValue("Something went wrong");
    }
  }
);

export const getStripeCard = createAsyncThunk(
  "subscriptions/getStripeCard",
  async (body: { organizationUuid: string }, { rejectWithValue }) => {
    try {
      const { data } = await api.subscription.getStripeCard(body);

      return data.data;
    } catch (error) {
      return rejectWithValue("Something went wrong");
    }
  }
);

export const cancelSubscription = createAsyncThunk(
  "subscriptions/cancelSubscription",
  async (
    body: {
      organizationUuid: string;
      notes: string;
    },
    { rejectWithValue }
  ) => {
    try {
      const { data } = await api.subscription.cancelSubscription(body);
      toastSuccess(
        "Your plan has been cancelled. You can continue to access all features until the plan's expiration date"
      );
      return data.data;
    } catch (error) {
      return rejectWithValue("Something went wrong");
    }
  }
);

export const razorPaySubVerify = createAsyncThunk(
  "subscriptions/razorPaySubVerify",
  async (body: RazorPaySubVerifyReq, { rejectWithValue }) => {
    try {
      const { data } = await api.subscription.razorPaySubVerify(body);

      return data;
    } catch (error) {
      return rejectWithValue("Something went wrong");
    }
  }
);
