import { AxiosInstance, AxiosPromise } from "axios";

import BaseApi from "api/base";
import { BaseResponse } from "../../models/base";
import { AiTemplateModel, UserDownloadModel } from "api/models/Ai/ai";

export class AiApi extends BaseApi {
  getAiTemplates(data: null): AxiosPromise<BaseResponse<AiTemplateModel>> {
    return this.request({
      url: `https://veehiveservice.uaenorth.cloudapp.azure.com/ai/services/get/all/templates`,
      method: "get",
    });
  }

  textToVideo(data: FormData): AxiosPromise<BaseResponse<any>> {
    return this.request({
      url: `https://veehiveservice.uaenorth.cloudapp.azure.com/ai/services/textto/video`,
      method: "post",
      data,
    });
  }

  generateTextToVideo(data: FormData): AxiosPromise<BaseResponse<any>> {
    return this.request({
      url: `https://veehiveservice.uaenorth.cloudapp.azure.com/ai/services/generate/video`,
      method: "post",
      data,
    });
  }

  textToVideoGenerate(data: {
    user_id: string;
    text: string;
    templateId: string;
    isGenerative: boolean;
    organizationUuid: string;
    durationInSeconds: number;
  }): AxiosPromise<BaseResponse<any>> {
    return this.request({
      url: `https://veehiveservice.uaenorth.cloudapp.azure.com/ai/services/generate/text`,
      method: "POST",
      data,
    });
  }

  textToVideoGetGenerated(passedData: string): AxiosPromise<BaseResponse<any>> {
    return this.request({
      url: `https://veehiveservice.uaenorth.cloudapp.azure.com/ai/services/get/generate/text/${passedData}`,
      method: "GET",
    });
  }

  formToVideo(data: FormData): AxiosPromise<BaseResponse<any>> {
    return this.request({
      url: `https://veehiveservice.uaenorth.cloudapp.azure.com/ai/services/formto/video`,
      method: "post",
      data,
    });
  }

  createTeaser(data: FormData): AxiosPromise<BaseResponse<any>> {
    return this.request({
      url: `https://veehiveservice.uaenorth.cloudapp.azure.com/ai/studio/createteaser`,
      method: "post",
      data,
    });
  }

  pdfToVideo(data: FormData): AxiosPromise<BaseResponse<any>> {
    return this.request({
      url: `https://veehiveservice.uaenorth.cloudapp.azure.com/ai/studio/convertdoctovideo`,
      method: "post",
      data,
    });
  }

  pdfToStory(data: FormData): AxiosPromise<BaseResponse<any>> {
    return this.request({
      url: `/studio/pdf/story_create`,
      method: "POST",
      data,
    });
  }

  createTranspose(data: FormData): AxiosPromise<BaseResponse<any>> {
    return this.request({
      url: `https://veehiveservice.uaenorth.cloudapp.azure.com/ai/studio/converttovertical`,
      method: "post",
      data,
    });
  }

  compileTopics(data: {
    file_uid: string;
    file: string;
    timestamps: any[];
    user_topics: number[];
  }): AxiosPromise<BaseResponse<any>> {
    return this.request({
      url: `http://20.46.144.73:8807/shortformvideo/createVideo`,
      method: "post",
      data,
    });
  }

  generateTopics(data: FormData): AxiosPromise<any> {
    return this.request({
      url: `http://20.46.144.73:8807/shortformvideo/generatetopics`,
      method: "post",
      data,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  getUserDownloads(data: {
    id: number;
    uuid: string;
  }): AxiosPromise<BaseResponse<UserDownloadModel>> {
    return this.request({
      url: `https://veehiveservice.uaenorth.cloudapp.azure.com/ai/services/get/userdetails/${data.id}/${data.uuid}`,
      method: "get",
    });
  }

  getReadyVideos(data: {
    id: number;
    uuid: string;
  }): AxiosPromise<BaseResponse<UserDownloadModel>> {
    return this.request({
      url: `https://veehiveservice.uaenorth.cloudapp.azure.com/ai/services/get/ready/videos/${data.id}/${data.uuid}`,
      method: "GET",
    });
  }

  publishToChannel(data: {
    channelID: string;
    organisationID: string;
    fileKey: string;
    sourceURL: string;
    tags: any;
    videoTitle: string;
    attribute3: string[];
  }): AxiosPromise<BaseResponse<UserDownloadModel>> {
    return this.request({
      url: `/content/webapp/share/studio/video`,
      method: "POST",
      data,
    });
  }
}

export default function aiApi(request: AxiosInstance) {
  return new AiApi({
    url: "/ai",
    request,
  });
}
