import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import PageLayout from "components/PageLayout/PageLayout";
import IslandLayout from "components/IslandLayout/IslandLayout";
import LineBreak from "components/LineBreak/LineBreak";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import {
  editCampaign,
  getCurrentCampaign,
} from "store/async-actions/Campaign/campaign";
import {
  getCampaignUuidSelector,
  getEmailBannerS3UrlSelector,
} from "store/reducers/Campaign/campaign";
import { useNavigate } from "react-router-dom";
import { BackBtnSVG } from "components/SVG/SVG";
import EmailTemplate from "./components/EmailTemplate";
import EmailTemplate2 from "./components/EmailTemplate2";
import { getEmailBannerFileUrlSelector } from "store/reducers/CreateChannel/createChannel";

interface Props {
  handleBack: any;
  handleNext: any;
}

const CreateEmailInfo = ({ handleBack, handleNext }: Props) => {
  const campaignUuid = useAppSelector(getCampaignUuidSelector);

  // useEffect(() => {
  //   if (!!!campaignUuid) {
  //     navigate("/campaigns");
  //   }
  // }, []);

  const [message, setMessage] = useState("");
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const banner = useAppSelector(getEmailBannerS3UrlSelector);

  const handleEdit = () => {
    dispatch(
      editCampaign({
        campaignName: "",
        campaignUuid: campaignUuid,
        greetingMessage: emailDetails.greeting,
        heading: emailDetails.heading,
        para1: emailDetails.para1,
        para2: emailDetails.para2,
        para3: emailDetails.para3,
        signOff: emailDetails.signOff,
        signatureName: emailDetails.signOffName,
        signatureDesignation: emailDetails.signOffDesignation,
        campaignType: "EMAIL",
        subject: emailDetails.subject,
        ctaEnabled: addCta,
        ctaName: emailDetails.ctaName,
        ctaLink: emailDetails.ctaLink,
        template: template,
        headerImage: banner || "",
        copyrightName: emailDetails.copyrightName,
        storyCta: addStory ? emailDetails.storyCta : "",
        facebookLink: emailDetails.facebookLink,
        whatsappLink: emailDetails.whatsappLink,
        instagramLink: emailDetails.instagramLink,
      })
    );
    handleNext();
  };

  useEffect(() => {
    dispatch(
      getCurrentCampaign({
        campaignUuid: campaignUuid,
      })
    );
  }, [campaignUuid]);

  const [template, setTemplate] = useState(1);

  const [emailDetails, setEmailDetails] = useState({
    greeting: "Hi there,",
    heading: "Welcome to our hive",
    para1:
      "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.",
    para2:
      "Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt.",
    para3:
      "Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem.",
    signOff: "Best Regards,",
    signOffName: "Name",
    signOffDesignation: "Designation",
    subject: "Subject",
    ctaEnabled: false,
    ctaName: "",
    ctaLink: "",
    storyCta: "",
    template: template,
    copyrightName: "YourCompanyName 2023",
    facebookLink: "",
    whatsappLink: "",
    instagramLink: "",
    headerImage: "https://images.veehive.ai/email/EMAIL+CAMPAIGN.png",
  });

  const [addCta, setAddCta] = useState(false);
  const [addStory, setAddStory] = useState(false);

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setEmailDetails((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleTemplateChange = (e: any) => {
    setTemplate(e.target.value);
  };

  return (
    // <PageLayout sideMenu={true}>
    //   <IslandLayout>
    <div className="">
      <div className="backBtn_spacing">
        <div className="go_back pointer" onClick={handleBack}>
          <BackBtnSVG />
          <p>Back</p>
        </div>
      </div>
      <h2>Customize your email template</h2>
      <LineBreak />
      <LineBreak />
      <div className="flex items-end gap-x-2">
        <TextField
          className="half_width"
          variant="standard"
          onChange={handleChange}
          value={emailDetails.subject}
          name="subject"
          label="Subject"
          placeholder="Subject"
        />
        <FormControl className="half_width">
          <Select
            className=""
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            label="Template"
            name="template"
            variant="standard"
            onChange={handleTemplateChange}
            value={template}
          >
            <MenuItem value={1}>Template 1</MenuItem>
            <MenuItem value={2}>Template 2</MenuItem>
          </Select>
        </FormControl>
      </div>
      <LineBreak />
      {template === 1 ? (
        <EmailTemplate
          handleChange={handleChange}
          emailDetails={emailDetails}
          addCta={addCta}
          setAddCta={setAddCta}
        />
      ) : (
        <EmailTemplate2
          handleChange={handleChange}
          emailDetails={emailDetails}
          addCta={addCta}
          setAddCta={setAddCta}
          addStory={addStory}
          setAddStory={setAddStory}
        />
      )}
      <LineBreak />
      <div className="campaign_nextBtn_container">
        <div onClick={handleEdit} className="primaryBtn campaign_nextBtn">
          Next
        </div>
      </div>
    </div>
    //   </IslandLayout>
    // </PageLayout>
  );
};

export default CreateEmailInfo;
