import { useAppDispatch, useAppSelector } from "hooks/redux";
import React, { useEffect } from "react";
import { getUsageData } from "store/async-actions/Analytics/analyticsActions";
import { setAnalyticsTab } from "store/reducers/Analytics/analytics";
import { getHiveSelector } from "store/reducers/HiveDetails/hiveDetails";
import LineBreak from "components/LineBreak";
import UsageDataContainer from "./UsageDataContainer";
import MetricsContainer from "./MetricsContainer";
import VisitorsGraph from "./VisitorsGraph";
import ProductCount from "./ProductCount";
import DateSelector from "components/DateSelector";

const AnalyticsOverview = () => {
  const dispatch = useAppDispatch();
  const hiveDetails = useAppSelector(getHiveSelector);

  useEffect(() => {
    hiveDetails &&
      dispatch(
        getUsageData({
          organizationId: hiveDetails?.communityId,
        })
      );
  }, [dispatch, hiveDetails]);

  useEffect(() => {
    dispatch(setAnalyticsTab(0));
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hiveDetails]);

  return (
    <>
      <DateSelector />
      <LineBreak />
      <UsageDataContainer />
      <LineBreak />
      <MetricsContainer />
      <LineBreak />

      {/* <UniqueSessions /> */}
      <div className="graph_and_details">
        <VisitorsGraph />
        <ProductCount />
      </div>
    </>
  );
};

export default AnalyticsOverview;
