import { CircularProgress, MenuItem, Select } from "@mui/material";
import BackButton from "components/BackButton";
import IslandLayout from "components/IslandLayout/IslandLayout";
import LineBreak from "components/LineBreak";
import PageLayout from "components/PageLayout/PageLayout";
import { BackBtnSVG } from "components/SVG/SVG";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { createCampaign } from "store/async-actions/Campaign/campaign";
import {
  getCampaignIsFetchingSelector,
  getCampaignNameSelector,
  getCampaignTypeSelector,
  getCampaignUuidSelector,
  setCampaignName,
  setCampaignType,
  setCampaignUuid,
} from "store/reducers/Campaign/campaign";
import { getHiveUuid } from "store/reducers/HiveDetails/hiveDetails";

interface Props {
  handleBack: any;
  handleNext: any;
}

const CreateCampaign = ({ handleBack, handleNext }: Props) => {
  const navigate = useNavigate();
  const location = useLocation();

  const name = useAppSelector(getCampaignNameSelector);
  const type = useAppSelector(getCampaignTypeSelector);

  const isFetching = useAppSelector(getCampaignIsFetchingSelector);

  const hiveUuid = useAppSelector(getHiveUuid);
  const dispatch = useAppDispatch();
  const campaignUuid = useAppSelector(getCampaignUuidSelector);

  const handleTypeChange = (e: any) => {
    dispatch(setCampaignType(e.target.value));
  };

  const handleCreateCampaign = () => {
    dispatch(
      createCampaign({
        organizationUuid: hiveUuid,
        campaignName: name,
        campaignType: type,
      })
    );
    handleNext();
  };

  return (
    // <PageLayout sideMenu={true}>
    // <IslandLayout>
    <div className="">
      <LineBreak />
      <div className="backBtn_spacing">
        <div className="go_back pointer" onClick={handleBack}>
          <BackBtnSVG />
          <p>Back</p>
        </div>
      </div>
      <LineBreak />
      <div className="title_and_limit">
        <p className="tags text-sm font-bold">Campaign Name</p>
        <div className="character_limit text-sm">{name.length}/27</div>
      </div>
      <LineBreak />
      <input
        value={name}
        onChange={(e) => {
          dispatch(setCampaignName(e.target.value.slice(0, 27)));
        }}
        placeholder="Give a name for your campaign"
        className="input_border text_padding input_width_full"
      />
      <LineBreak />
      <div className="title_and_limit">
        <p className="tags text-sm font-bold">Campaign Type</p>
      </div>{" "}
      <LineBreak />
      <Select
        // className="w_full"
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        variant="standard"
        value={type}
        label="Type"
        name="type"
        className="border-none"
        onChange={handleTypeChange}
      >
        <MenuItem value="sms">SMS</MenuItem>
        <MenuItem value="email">Email</MenuItem>
      </Select>
      <LineBreak />
      <>
        <div className="campaign_nextBtn_container">
          {name.length > 0 ? (
            <div
              onClick={handleCreateCampaign}
              className="primaryBtn campaign_nextBtn"
            >
              {isFetching ? (
                <CircularProgress size={20} color="inherit" />
              ) : (
                "Next"
              )}
            </div>
          ) : (
            <div className="campaign_nextBtn disabledBtn">Next</div>
          )}
        </div>
      </>
    </div>
    //   </IslandLayout>
    // </PageLayout>
  );
};

export default CreateCampaign;
