import CircularProgress from "@mui/material/CircularProgress";
import IslandLayout from "components/IslandLayout/IslandLayout";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getSpotlight } from "store/async-actions/Videos/videosActions";
import {
  getHiveSelector,
  launchLogin,
} from "store/reducers/HiveDetails/hiveDetails";
import {
  getIsSpotlightIsFetchingSelector,
  getSpotlightSelector,
  getSpotlightVidsSelector,
  setCurrentVideo,
} from "store/reducers/Videos/videoList";

const SpotlightContainer = () => {
  const dispatch = useAppDispatch();
  const spotlight = useAppSelector(getSpotlightSelector);
  const videos = useAppSelector(getSpotlightVidsSelector);

  const hiveDetails = useAppSelector(getHiveSelector);

  const isFetching = useAppSelector(getIsSpotlightIsFetchingSelector);

  const navigate = useNavigate();

  useEffect(() => {
    hiveDetails &&
      setTimeout(() => {
        dispatch(getSpotlight({ communityId: hiveDetails.communityId }));
      }, 1500);
  }, [dispatch, hiveDetails]);

  const handleSpotlightClick = () => {
    // if (localStorage.getItem("isLoggedIn") === "yes") {
    //   if (!!videos && videos.videoList[0]) {
    //     dispatch(setCurrentVideo(videos.videoList[0]));
    //     navigate(`/home/spotlight/edit/${videos.videoList[0].videoUuid}`);
    //   } else {
    //     navigate("/home/spotlight/upload");
    //   }
    // } else {
    //   launchLogin();
    // }
    spotlight[0].spotlightType === "CHANNEL_VIEW"
      ? navigate(`/channels/${spotlight[0].channelUuid}`)
      : spotlight[0].spotlightType === "EVENT_VIEW"
      ? navigate(`/events/${spotlight[0].eventIdentifier}`)
      : navigate("/spotlight");
  };

  return (
    <>
      {spotlight[0] && spotlight[0].spotlightImage && (
        <div className="w_full h_full">
          <IslandLayout>
            {isFetching ? (
              <div className="spotlight_is_fetching">
                <CircularProgress size={20} color="inherit" />
              </div>
            ) : (
              <img
                onClick={handleSpotlightClick}
                alt=""
                src={spotlight[0].spotlightImage}
                className="w_full h_full rounded_spotlight pointer"
              />
            )}
          </IslandLayout>
        </div>
      )}
    </>
  );
};

export default SpotlightContainer;
