import {
  DownArrowSVG,
  GreenDownArrowSVG,
  GreyDownArrowSVG,
  PlusSignSVG,
} from "components/SVG/SVG";
import { useAppSelector } from "hooks/redux";
import React from "react";
import { getContentCurrStepSelector } from "store/reducers/ContentMgr/contentMgr";

interface Props {
  step: number;
}

const ItemDivider = ({ step }: Props) => {
  const currentStep = useAppSelector(getContentCurrStepSelector);

  return (
    // <div className="css-zigog8">
    //   <div className="css-1ysym28"></div>
    //   {/* <div className="content_trigger_wrap">
    //     <PlusSignSVG />
    //   </div> */}
    //   <div className="css-c3455h"></div>
    // </div>
    <div className="item_divider_wrapper">
      {currentStep < step ? <GreyDownArrowSVG /> : <GreenDownArrowSVG />}
    </div>
  );
};

export default ItemDivider;
