import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {
  RazorPayEventOrder,
  RazorPaySubOrder,
} from "api/models/Channel/cartModel";
import {
  AttendeeFormDetails,
  AttendeeFormDetailsReq,
  EventPromoModel,
  EventUserItem,
  EventsItem,
  EventsListModel,
  EventsModel,
  EventsRequestModel,
  GalleryItem,
} from "api/models/Hive/events";
import { BaseResponse } from "api/models/base";
import { navbar } from "mock/HiveDetails/navbar";
import * as Scroll from "react-scroll";
import {
  attendeeQrRegistration,
  createEvent,
  editEvent,
  getEventAttendeesList,
  getEventDetails,
  getEventOrgOwner,
  getEventsList,
  getEventsPeopleList,
  getGalleryEvent,
  getPresignedUrlWithType,
  razorPayChargeEvent,
  razorPayVerifyEvent,
  stripeChargeEvent,
  verifyEventPromo,
} from "store/async-actions/Hive/eventsActions";
import { createTypedSelector } from "store/utils";

interface InitialState {
  isFetching: boolean;
  fromDate: Date | null;
  toDate: Date | null;
  eventName: string;
  eventDescription: string;
  eventMedium: string;
  eventLink: string;
  eventLocation: string;
  eventAddress: string;
  eventDuration: string;
  currentEvent: EventsItem | undefined;
  eventsList: EventsListModel[];
  eventsResp: EventsModel | undefined;
  orgOwner: EventUserItem | undefined;
  attendeesList: EventUserItem[];
  eventActive: boolean;
  peopleList: EventUserItem[];
  registrationForm: AttendeeFormDetailsReq[];
  attendeeQrDetails: any;
  currentEventUser: EventUserItem | undefined;
  paymentCharged: boolean;
  eventFormDetails: AttendeeFormDetailsReq[];
  createOrderResp: RazorPayEventOrder | undefined;
  eventPromo: EventPromoModel | undefined;
  exportData: string[][];
  galleryItems: GalleryItem[];
}

const initialState: InitialState = {
  currentEvent: undefined,
  isFetching: false,
  fromDate: null,
  toDate: null,
  eventName: "",
  eventDescription: "",
  eventMedium: "",
  eventLink: "",
  eventLocation: "",
  eventAddress: "",
  eventDuration: "",
  eventsList: [],
  eventsResp: undefined,
  orgOwner: undefined,
  attendeesList: [],
  eventActive: false,
  peopleList: [],
  registrationForm: [],
  attendeeQrDetails: "",
  currentEventUser: undefined,
  paymentCharged: false,
  eventFormDetails: [],
  createOrderResp: undefined,
  eventPromo: undefined,
  exportData: [],
  galleryItems: [],
};

const eventsSlice = createSlice({
  name: "events",
  initialState,
  reducers: {
    setEventFromDate: (state, action: PayloadAction<Date | null>) => {
      state.fromDate = action.payload;
    },
    setEventToDate: (state, action: PayloadAction<Date | null>) => {
      state.toDate = action.payload;
    },
    setEventName: (state, action: PayloadAction<string>) => {
      state.eventName = action.payload;
    },
    setEventDesc: (state, action: PayloadAction<string>) => {
      state.eventDescription = action.payload;
    },
    setEventMedium: (state, action: PayloadAction<string>) => {
      state.eventMedium = action.payload;
    },
    setEventLink: (state, action: PayloadAction<string>) => {
      state.eventLink = action.payload;
    },
    setEventLocation: (state, action: PayloadAction<string>) => {
      state.eventLocation = action.payload;
    },
    setEventAddress: (state, action: PayloadAction<string>) => {
      state.eventAddress = action.payload;
    },
    setEventDuration: (state, action: PayloadAction<string>) => {
      state.eventDuration = action.payload;
    },
    setEventPaymentCharged: (state, action: PayloadAction<boolean>) => {
      state.paymentCharged = action.payload;
    },
    setEventFormDetails: (
      state,
      action: PayloadAction<AttendeeFormDetailsReq[]>
    ) => {
      state.eventFormDetails = action.payload;
    },
    setCurrentEvent: (state, action: PayloadAction<EventsItem | undefined>) => {
      state.currentEvent = action.payload;
    },
    setCurrentEventUser: (
      state,
      action: PayloadAction<EventUserItem | undefined>
    ) => {
      state.currentEventUser = action.payload;
    },
    resetPromo: (state, action: PayloadAction<undefined>) => {
      state.eventPromo = action.payload;
    },
  },
  extraReducers: {
    [createEvent.pending.type]: (state) => {
      state.isFetching = true;
    },
    [createEvent.fulfilled.type]: (
      state,
      action: PayloadAction<EventsItem>
    ) => {
      state.currentEvent = action.payload;

      state.isFetching = false;
    },
    [createEvent.rejected.type]: (state) => {
      state.isFetching = false;
    },

    //GET EVENT DETAILS
    [getEventDetails.pending.type]: (state) => {
      state.isFetching = true;
    },
    [getEventDetails.fulfilled.type]: (
      state,
      action: PayloadAction<EventsModel>
    ) => {
      state.currentEvent = action.payload.event;
      state.eventsResp = action.payload;
      state.registrationForm = [];
      !!state.eventsResp.formDetails &&
        state.eventsResp.formDetails.map((data, idx) => {
          state.registrationForm.push({
            fieldName: data.fieldName,
            fieldValue: "",
            type: data.type,
            options: data.options,
          });
        });

      state.isFetching = false;
    },
    [getEventDetails.rejected.type]: (state) => {
      document.location.href = "/events";
      state.isFetching = false;
    },

    //GET EVENTS LIST
    [getEventsList.pending.type]: (state) => {
      state.isFetching = true;
    },
    [getEventsList.fulfilled.type]: (
      state,
      action: PayloadAction<EventsListModel[]>
    ) => {
      state.eventsList = action.payload;
      state.isFetching = false;
    },
    [getEventsList.rejected.type]: (state) => {
      state.isFetching = false;
    },

    //GET EVENT ATTENDEES LIST
    [getEventAttendeesList.pending.type]: (state) => {
      state.isFetching = true;
    },
    [getEventAttendeesList.fulfilled.type]: (
      state,
      action: PayloadAction<EventUserItem[]>
    ) => {
      state.attendeesList = action.payload;
      state.isFetching = false;
    },
    [getEventAttendeesList.rejected.type]: (state) => {
      state.isFetching = false;
    },

    //ATTENDEE QR REGISTRATION
    [attendeeQrRegistration.pending.type]: (state) => {
      state.isFetching = true;
    },
    [attendeeQrRegistration.fulfilled.type]: (
      state,
      action: PayloadAction<EventUserItem[]>
    ) => {
      state.attendeeQrDetails = action.payload;
      state.isFetching = false;
    },
    [attendeeQrRegistration.rejected.type]: (state) => {
      state.isFetching = false;
    },

    //GET EVENT PEOPLE LIST
    [getEventsPeopleList.pending.type]: (state) => {
      state.isFetching = true;
      state.peopleList = [];
    },
    [getEventsPeopleList.fulfilled.type]: (
      state,
      action: PayloadAction<EventUserItem[]>
    ) => {
      state.peopleList = [];
      state.peopleList = action.payload;
      state.exportData = [];
      state.exportData.push([
        "Username",
        "Email",
        "Phone Number",
        "Registration Number",
      ]);
      !!action.payload &&
        !!action.payload[0] &&
        !!action.payload[0].eventForm &&
        action.payload[0].eventForm.map((data) => {
          state.exportData[0].push(data.fieldName);
        });
      !!action.payload &&
        !!action.payload[0] &&
        action.payload.map((data, idx) => {
          state.exportData.push([
            data.userName || "No Data",
            data.email || "No Data",
            data.phone ? "+" + data.phone.toString() : "No Data",
            data.registrationNumber || "No Data",
          ]);
          data.eventForm &&
            data.eventForm.map((data) =>
              state.exportData[idx + 1].push(
                data.fieldValue || data.options[0] || "No Response"
              )
            );
        });
      state.isFetching = false;
    },
    [getEventsPeopleList.rejected.type]: (state) => {
      state.peopleList = [];

      state.isFetching = false;
    },

    //GET ORG OWNER
    [getEventOrgOwner.pending.type]: (state) => {
      state.isFetching = true;
    },
    [getEventOrgOwner.fulfilled.type]: (
      state,
      action: PayloadAction<EventUserItem>
    ) => {
      state.orgOwner = action.payload;
      state.isFetching = false;
    },
    [getEventOrgOwner.rejected.type]: (state) => {
      state.isFetching = false;
    },

    //GET Gallery
    [getGalleryEvent.pending.type]: (state) => {
      // state.isFetching = true;
    },
    [getGalleryEvent.fulfilled.type]: (
      state,
      action: PayloadAction<GalleryItem[]>
    ) => {
      // state.isFetching = false;
      state.galleryItems = action.payload;
    },
    [getGalleryEvent.rejected.type]: (state) => {
      // state.isFetching = false;
    },

    //CHARGE STRIPE PAYMENT
    [stripeChargeEvent.pending.type]: (state) => {
      state.isFetching = true;
    },
    [stripeChargeEvent.fulfilled.type]: (
      state,
      action: PayloadAction<BaseResponse<boolean>>
    ) => {
      // if (action.payload.responseInfo.httpCode === 200) {
      state.paymentCharged = action.payload.data;
      // }
      state.isFetching = false;
    },
    [stripeChargeEvent.rejected.type]: (state) => {
      state.isFetching = false;
    },

    //CHARGE RAZOR PAY PAYMENT
    [razorPayChargeEvent.pending.type]: (state) => {
      state.isFetching = true;
    },
    [razorPayChargeEvent.fulfilled.type]: (
      state,
      action: PayloadAction<RazorPayEventOrder>
    ) => {
      // if (action.payload.responseInfo.httpCode === 200) {
      state.createOrderResp = action.payload;
      // }
      state.isFetching = false;
    },
    [razorPayChargeEvent.rejected.type]: (state) => {
      state.isFetching = false;
    },

    // RAZOR PAY PAYMENT VERIFY
    [razorPayVerifyEvent.pending.type]: (state) => {
      state.isFetching = true;
    },
    [razorPayVerifyEvent.fulfilled.type]: (
      state,
      action: PayloadAction<boolean>
    ) => {
      // if (action.payload.responseInfo.httpCode === 200) {
      state.paymentCharged = action.payload;
      // }
      state.isFetching = false;
    },
    [razorPayVerifyEvent.rejected.type]: (state) => {
      state.isFetching = false;
    },

    // PROMO
    [verifyEventPromo.pending.type]: (state) => {
      state.isFetching = true;
    },
    [verifyEventPromo.fulfilled.type]: (
      state,
      action: PayloadAction<EventPromoModel>
    ) => {
      // if (action.payload.responseInfo.httpCode === 200) {
      state.eventPromo = action.payload;
      // }
      state.isFetching = false;
    },
    [verifyEventPromo.rejected.type]: (state) => {
      state.isFetching = false;
    },
  },
});

export const getEventsFromDateSelector = createTypedSelector(
  (state) => state.events.fromDate
);

export const getEventsPromoSelector = createTypedSelector(
  (state) => state.events.eventPromo
);

export const getEventsPeopleSelector = createTypedSelector(
  (state) => state.events.peopleList
);

export const getGalleryItemsSelector = createTypedSelector(
  (state) => state.events.galleryItems
);

export const getEventFormDetailsSelector = createTypedSelector(
  (state) => state.events.eventFormDetails
);

export const getEventOrgOwnerSelector = createTypedSelector(
  (state) => state.events.orgOwner
);

export const getEventsExportDataSelector = createTypedSelector(
  (state) => state.events.exportData
);

export const getEventRegistrationFormSelector = createTypedSelector(
  (state) => state.events.registrationForm
);

export const getEventAttendeesListSelector = createTypedSelector(
  (state) => state.events.attendeesList
);

export const getEventsToDateSelector = createTypedSelector(
  (state) => state.events.toDate
);

export const getCreateEventOrderSelector = createTypedSelector(
  (state) => state.events.createOrderResp
);

export const getEventPaymentChargedSelector = createTypedSelector(
  (state) => state.events.paymentCharged
);

export const getEventsDetailsSelector = createTypedSelector(
  (state) => state.events
);

export const getEventNameSelector = createTypedSelector(
  (state) => state.events.eventName
);

export const getCurrentEventSelector = createTypedSelector(
  (state) => state.events.currentEvent
);

export const getEventIsLoadingSelector = createTypedSelector(
  (state) => state.events.isFetching
);

export const getEventsListSelector = createTypedSelector(
  (state) => state.events.eventsList
);

export const getEventRespSelector = createTypedSelector(
  (state) => state.events.eventsResp
);

export const getCurrentEventUserSelector = createTypedSelector(
  (state) => state.events.currentEventUser
);

export const {
  setEventAddress,
  setEventDesc,
  setEventDuration,
  setEventLink,
  setEventLocation,
  setEventMedium,
  setEventName,
  setEventFromDate,
  setEventToDate,
  setCurrentEvent,
  setCurrentEventUser,
  setEventPaymentCharged,
  setEventFormDetails,
  resetPromo,
} = eventsSlice.actions;

export default eventsSlice.reducer;
