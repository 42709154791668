import { store } from "store";
import { getChannelDetails } from "./Channels/channels";
import { getFeedVideos } from "./Feed/feedActions";
import {
  getHiveComponents,
  getChannelList,
  getHiveDetails,
  getChildComponents,
  getHiveActivities,
} from "./Hive/hivePage";
import { setUserId } from "store/reducers/Profile/profile";

export const InitializeApp = async (dispatch: any) => {
  await dispatch(
    getHiveDetails({
      organizationUuid: process.env.REACT_APP_ORG_UUID,

      // communitySubDomain: localStorage.getItem("subDomain"),
    })
  );

  await Promise.all([
    dispatch(
      getHiveComponents({
        organizationUuid: process.env.REACT_APP_ORG_UUID,

        organizationId: store.getState().hive.hiveDetails?.communityId!,
        isMemberView: store.getState().profile.isMemberView,
      })
    ),
    dispatch(
      getChannelList({
        hiveId: store.getState().hive.hiveDetails?.communityId!,
        isMemberView: store.getState().profile.isMemberView,
      })
    ),
    dispatch(
      getChannelDetails(store.getState().channels.channelList[0].channelUuid)
    ),
    ,
  ]);
};

export const InitializeNoAuthApp = async (dispatch: any) => {
  dispatch(setUserId(0));
  await dispatch(
    getHiveDetails({
      organizationUuid: process.env.REACT_APP_ORG_UUID,

      // communitySubDomain: localStorage.getItem("subDomain")!,
    })
  );
  await Promise.all([
    dispatch(
      getHiveComponents({
        organizationUuid: process.env.REACT_APP_ORG_UUID,

        // communityDomain: localStorage.getItem("subDomain"),
        isMemberView: store.getState().profile.isMemberView,
      })
    ),
    dispatch(
      getChannelList({
        hiveId: store.getState().hive.hiveDetails?.communityId!,
        isMemberView: store.getState().profile.isMemberView,
      })
    ),
    dispatch(
      getChannelDetails(store.getState().channels.channelList[0].channelUuid)
    ),
  ]);
};
