import Cropzone from "components/CropZone";
import { UploadImageSVG } from "components/SVG/SVG";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { toastError, toastInfo } from "hooks/useToastify";
import React, { useCallback, useState, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import {
  getBannerFileUrlSelector,
  getBannerUploadedSelector,
  getEmailBannerFileUrlSelector,
  getEmailBannerUploadedSelector,
  getImageUploadedSelector,
  getLogoFileUrlSelector,
  setBannerFile,
  setEmailBannerFile,
  setEmailBannerUploaded,
  setImageUploaded,
  setLogoFile,
} from "store/reducers/CreateChannel/createChannel";

interface Props {
  bannerUrl?: string;
}

const EmailHeaderDropzone = ({ bannerUrl }: Props) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setEmailBannerFile(""));
    dispatch(setEmailBannerUploaded(false));
  }, []);

  const emailBannerUploaded = useAppSelector(getEmailBannerUploadedSelector);
  const emailBannerFile = useAppSelector(getEmailBannerFileUrlSelector);

  const [image, setImage] = useState<string | ArrayBuffer>("");
  const handleDrop = (acceptedFiles: File[]) => {
    setImage("");
    handleCropClose();
    setSubmitClick(false);
    /// Get the first file
    const file = acceptedFiles[0];
    if (file.type === "image/png" || file.type === "image/jpeg") {
      if (file.size > 100000000) {
        toastInfo("Please upload images less than 80 mb");
      } else {
        // Create a new FileReader
        const reader = new FileReader();

        // Set the image state when the file has loaded
        reader.onload = (e) => {
          setImage(e.target?.result!);
        };

        // Read the file as a data URL
        reader.readAsDataURL(file);
        handleCropOpen();
      }
    } else {
      toastError("Invalid file type, please pass an image file .jpg or .png");
    }
  };

  const onDropAccepted = useCallback(handleDrop, []);
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    onDrop: onDropAccepted,
  });

  const [openCrop, setOpenCrop] = useState(false);
  const handleCropClose = () => {
    setOpenCrop(false);
  };
  const handleCropOpen = () => {
    setOpenCrop(true);
  };

  const [onSubmitClick, setSubmitClick] = useState(false);

  return (
    <div className="cursor-pointer">
      <div
        {...getRootProps()}
        className={`upload_wrapper dropzone ${
          isDragActive ? "dropzone--isActive border-blue-600" : ""
        } ${isDragAccept ? "dropzone--isAccept border-blue-600" : ""} ${
          isDragReject ? "dropzone--isReject border-red-600" : ""
        }`}
      >
        <input accept="image/*" {...getInputProps()} />
        <div className="upload_content_banner_wrapper">
          {emailBannerUploaded ? (
            <img
              alt="banner_img"
              className="uploaded_email_banner_image"
              src={`data:image/jpeg;base64,${emailBannerFile}`}
            />
          ) : (
            <>
              {bannerUrl ? (
                <img
                  src={bannerUrl}
                  className="uploaded_email_banner_image"
                  alt="changingLogo"
                />
              ) : (
                <UploadImageSVG />
              )}
            </>
          )}
          <div className="upload_text_wrapper">
            <div>
              <h4 className="">{`Drag and drop image here or click to ${
                emailBannerUploaded ? "change" : "upload"
              }`}</h4>
              <p className="text-gray-400 font-semibold text-sm">
                Recommended size: 300kb
              </p>
              <p className="text-gray-400 text-sm">
                Recommended dimensions: {"1200px x 630px"}
              </p>
            </div>
          </div>
        </div>
      </div>
      {image && !onSubmitClick && (
        <Cropzone
          file={"emailBanner"}
          openCrop={openCrop}
          handleCropClose={handleCropClose}
          image={image}
          setSubmitClick={setSubmitClick}
        />
      )}
    </div>
  );
};

export default EmailHeaderDropzone;
