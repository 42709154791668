import LineBreak from "components/LineBreak/LineBreak";
import { ArrowRightBlackSVG } from "components/SVG/SVG";
import React from "react";
import { useNavigate } from "react-router-dom";

const PlansCta = () => {
  const navigate = useNavigate();

  const handleRedirect = () => {
    navigate("/plans");
  };

  return (
    <div onClick={handleRedirect} className="p-layout cursor-pointer">
      <h4>Manage your Subscriptions</h4>
      <LineBreak />
      <div className="flex items-center site_plans-cta-gap">
        <p className="text-sm fw-1">View Site Plans</p>
        <ArrowRightBlackSVG />
      </div>
    </div>
  );
};

export default PlansCta;
