import IslandLayout from "components/IslandLayout/IslandLayout";
import React, { useEffect, useState } from "react";
import LineBreak from "components/LineBreak/LineBreak";
import {
  CircularProgress,
  MenuItem,
  Select,
  StyledEngineProvider,
  TextField,
} from "@mui/material";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import EventsDateTimePicker from "./components/EventsDateTimePicker";
import {
  getCurrentEventSelector,
  getEventIsLoadingSelector,
  getEventsFromDateSelector,
  getEventsToDateSelector,
  setCurrentEvent,
  setEventFromDate,
  setEventToDate,
} from "store/reducers/HiveDetails/events";
import { createEvent } from "store/async-actions/Hive/eventsActions";
import dayjs from "dayjs";
import { getHiveUuid } from "store/reducers/HiveDetails/hiveDetails";
import { useNavigate } from "react-router-dom";
import BackButton from "components/BackButton/BackButton";
import AttendeeForm from "./components/AttendeeForm";
import { AttendeeFormDetails } from "api/models/Hive/events";
import Switch from "@mui/joy/Switch";
import { toastInfo } from "hooks/useToastify";
import { CssVarsProvider } from "@mui/joy";

const CreateEvent = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setEventFromDate(dayjs().toDate()));
    dispatch(setEventToDate(dayjs().toDate()));
  }, [dispatch]);

  const [eventDetails, setEventDetails] = useState({
    name: "",
    description: "",
    medium: "offline",
    location: "",
    address: "",
    link: "",
  });

  const handleDetailsChange = (e: any) => {
    setEventDetails((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleMedium = (medium: string) => {
    setEventDetails((prevState) => ({ ...prevState, medium: medium }));
  };

  const fromDate = useAppSelector(getEventsFromDateSelector);
  const toDate = useAppSelector(getEventsToDateSelector);

  const hiveUuid = useAppSelector(getHiveUuid);

  const isLoading = useAppSelector(getEventIsLoadingSelector);

  const [attendeeFormDetails, setAttendeeFormDetails] = useState<
    AttendeeFormDetails[]
  >([]);

  const handleCreate = () => {
    dispatch(setCurrentEvent(undefined));
    dispatch(
      createEvent({
        eventLocation: eventDetails.location,
        eventName: eventDetails.name,
        eventDescription: eventDetails.description,
        eventVideo: "",
        eventThumbnail: "",
        eventBanner: "",
        eventColor: "",
        eventStartDate: fromDate,
        eventEndDate: toDate,
        eventAddress: eventDetails.address,
        organizationUuid: hiveUuid,
        eventDurationInMinutes: 60,
        eventMedium: eventDetails.medium,
        formDetails: attendeeFormDetails,
        isPaidEvent: isPaid,
        eventAmount: eventAmount,
        eventCurrency: currency || "",
      })
    );
  };

  const [currency, setCurrency] = useState("USD");

  const handleCurrencyChange = (e: any) => {
    setCurrency(e.target.value);
  };

  const navigate = useNavigate();
  const currentEvent = useAppSelector(getCurrentEventSelector);

  useEffect(() => {
    if (!!currentEvent) {
      navigate("/events/info");
    }
  }, [currentEvent]);

  const [isPaid, setIsPaid] = useState(false);
  const [eventAmount, setEventAmount] = useState(0);

  return (
    <IslandLayout>
      <div className="create_event_container">
        <BackButton to="/events/home" />
        <LineBreak />
        <h2>Create an Event</h2>
        <div className="event_details_wrapper">
          <div>
            <h4>Event Name*</h4>
            <LineBreak />
            <TextField
              className="half_width"
              id="standard-basic"
              label="Name"
              variant="outlined"
              name="name"
              onChange={handleDetailsChange}
              value={eventDetails.name}
            />
          </div>
          <div>
            <EventsDateTimePicker />
          </div>
          <div>
            <h4>Location*</h4>
            <LineBreak />
            <div className="video_limit_btns medium_btns">
              <button
                onClick={() => handleMedium("online")}
                className={`${
                  eventDetails.medium === "online"
                    ? "primaryBtn"
                    : "secondaryBtn"
                }`}
              >
                Online
              </button>
              <button
                onClick={() => handleMedium("offline")}
                className={`${
                  eventDetails.medium === "offline"
                    ? "primaryBtn"
                    : "secondaryBtn"
                }`}
              >
                In-Person
              </button>
            </div>
            <LineBreak />
            <div className="flex items-center create-event-gap-location">
              <TextField
                className="half_width"
                id="standard-basic"
                label="Location"
                placeholder="Where will the event be located?"
                variant="outlined"
                multiline
                maxRows={4}
                name="location"
                onChange={handleDetailsChange}
                value={eventDetails.location}
              />
              <TextField
                className="half_width"
                id="standard-basic"
                label="Link"
                placeholder={
                  eventDetails.medium === "offline"
                    ? "Add Event Address or Google Maps Link"
                    : "Add Online Meet Link"
                }
                variant="outlined"
                multiline
                maxRows={4}
                name="address"
                onChange={handleDetailsChange}
                value={eventDetails.address}
              />
            </div>
          </div>

          <div>
            <h4>Description/Instructions</h4>
            <LineBreak />
            <TextField
              className="half_width"
              id="standard-basic"
              label="Description"
              variant="outlined"
              multiline
              maxRows={4}
              name="description"
              onChange={handleDetailsChange}
              value={eventDetails.description}
            />
          </div>
          <div className="channel_paid_container">
            <StyledEngineProvider injectFirst>
              <CssVarsProvider>
                <div className="switch_display">
                  <Switch
                    checked={isPaid}
                    onChange={(event) => setIsPaid(event.target.checked)}
                  />
                  <p>Paid</p>
                </div>
              </CssVarsProvider>
            </StyledEngineProvider>
            {isPaid && (
              <div className="channel_amount_wrapper">
                <input
                  className="outline-none"
                  placeholder="0.00"
                  value={eventAmount}
                  onChange={(e) => {
                    if (+e.target.value > 999) {
                      toastInfo("Please enter amount less than 1000");
                    }
                    /^\d*(\.\d{0,2})?$/.test(e.target.value) &&
                      (+e.target.value < 1000 || e.target.value === "") &&
                      setEventAmount(+e.target.value);
                  }}
                />
                <Select
                  // className="w_full"
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  variant="standard"
                  value={currency}
                  label="Currency"
                  name="currency"
                  className="border-none"
                  onChange={handleCurrencyChange}
                >
                  <MenuItem value="USD">USD</MenuItem>
                  <MenuItem value="AED">AED</MenuItem>
                </Select>
              </div>
            )}
          </div>
        </div>
        <AttendeeForm
          attendeeFormDetails={attendeeFormDetails}
          setAttendeeFormDetails={setAttendeeFormDetails}
        />
        {!!eventDetails.name &&
        !!eventDetails.location &&
        !!eventDetails.medium &&
        !!fromDate &&
        !!toDate ? (
          <>
            {isLoading ? (
              <div className="primaryBtn nextBtn_event">
                <CircularProgress size={20} color="inherit" />
              </div>
            ) : (
              <div onClick={handleCreate} className="primaryBtn nextBtn_event">
                Next
              </div>
            )}
          </>
        ) : (
          <div className="disabledBtn nextBtn_event">Next</div>
        )}
      </div>
    </IslandLayout>
  );
};

export default CreateEvent;
