import * as React from "react";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import {
  getHiveSelector,
  getHiveUuid,
} from "store/reducers/HiveDetails/hiveDetails";
import {
  addSuperAdmin,
  removeHiveMember,
} from "store/async-actions/Hive/hivePage";
import { removeChannelMember } from "store/async-actions/Channels/channels";
import { useState } from "react";
import ConfirmationModal from "components/Confirmation/Confirmation";
import { CampaignModel } from "api/models/Campaign/campaign";
import { useNavigate } from "react-router-dom";
import { setCampaignUuid } from "store/reducers/Campaign/campaign";
import {
  deleteCampaign,
  sendSMSCampaign,
} from "store/async-actions/Campaign/campaign";
import { FormModel } from "api/models/Forms/forms";
import { toastInfo } from "hooks/useToastify";
import { deleteForm, getFormDetails } from "store/async-actions/Forms/forms";
import { setCurrentForm } from "store/reducers/Forms/forms";

const ITEM_HEIGHT = 48;

interface Props {
  form: FormModel;
}

export default function FormKebab({ form }: Props) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const dispatch = useAppDispatch();
  const hiveDetails = useAppSelector(getHiveSelector);

  const handleRemove = () => {
    dispatch(deleteForm({ formUuid: form.formUuid }));
    handleRemoveConfirmClose();
  };

  const hiveUuid = useAppSelector(getHiveUuid);

  const [isRemoveOpen, setIsRemoveOpen] = useState(false);

  const handleRemoveConfirmClose = () => {
    handleClose();
    setIsRemoveOpen(false);
  };

  const handleRemoveConfirmOpen = () => {
    setIsRemoveOpen(true);
  };

  const navigate = useNavigate();

  const handleEdit = () => {
    dispatch(
      getFormDetails({
        formUuid: form.formUuid,
      })
    );
    navigate(`/forms/${form.formUuid}/edit`);
  };

  const handleViewResponses = () => {
    dispatch(
      getFormDetails({
        formUuid: form.formUuid,
      })
    );
    navigate(`/forms/${form.formUuid}/responses`);
  };

  const handleCopy = () => {
    navigator.clipboard
      .writeText(`${document.location.href}/${form.formUuid}`)
      .then(() => {
        if (!!document.location.href) {
          toastInfo("Copied link to clipboard");
        } else {
          toastInfo(
            "This story isnt available at the moment, please try another one"
          );
        }
      })
      .catch((error) => {
        console.error("Error copying text: ", error);
      });
  };

  return (
    <div>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: "17ch",
          },
        }}
      >
        <MenuItem onClick={handleCopy}>
          <p className="text-sm">Share Form</p>
        </MenuItem>

        <MenuItem onClick={handleEdit}>
          <p className="text-sm">Edit</p>
        </MenuItem>

        <MenuItem onClick={handleRemoveConfirmOpen}>
          <p className="text-sm">Delete</p>
        </MenuItem>

        <MenuItem onClick={handleViewResponses}>
          <p className="text-sm">View Responses</p>
        </MenuItem>

        {isRemoveOpen && (
          <ConfirmationModal
            isOpen={isRemoveOpen}
            onReject={handleRemoveConfirmClose}
            onConfirm={handleRemove}
            headline={`Delete ${form.formName}`}
            description={`Are you sure you want to delete this form?`}
            confirmMessage="Yes"
            rejectMessage="No"
          />
        )}
      </Menu>
    </div>
  );
}
