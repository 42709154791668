import { CircularProgress, Dialog } from "@mui/material";
import IslandLayout from "components/IslandLayout";
import Loader from "components/Loader";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { toastError, toastInfo } from "hooks/useToastify";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { readyToUpload } from "store/async-actions/Videos/videosActions";
import { getChannelListSelector } from "store/reducers/ChannelDetails/channelList";
import { getHiveUuid } from "store/reducers/HiveDetails/hiveDetails";
import {
  getStillInEc2Selector,
  isUploadingSelector,
} from "store/reducers/Videos/videos";
import UploadInfo from "./components/UploadInfo";
import VideoSelect from "./components/VideoSelect";
import VideoUploadTabs from "./components/VideoUploadTab";

const UploadVideo = () => {
  const channelList = useAppSelector(getChannelListSelector);
  const isUploading = useAppSelector(isUploadingSelector);
  const stillInEC2 = useAppSelector(getStillInEc2Selector);

  const hiveUuid = useAppSelector(getHiveUuid);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(
      readyToUpload({
        organizationUuid: hiveUuid,
      })
    );
  }, [dispatch, stillInEC2]);

  useEffect(() => {
    if (stillInEC2) {
      toastInfo("Setting up your hive! Upload content after 10-15 min.");
      navigate("/home");
    }
  }, [dispatch, stillInEC2]);

  return (
    <>
      <div className="upload_container">
        {channelList.length > 1 && (
          <div className="upload_section">
            <IslandLayout>
              <div className="upload_info">
                <UploadInfo />
              </div>
            </IslandLayout>
          </div>
        )}
        <div className="upload_section">
          <IslandLayout>
            <div className="vid_upload_tabs_wrapper">
              <VideoUploadTabs />
            </div>

            <div className="video_select_wrapper">
              <VideoSelect />
            </div>
          </IslandLayout>
        </div>
      </div>
      {/* <Dialog open={isUploading}>
        <div className="popup_dialogue"> */}
      <Dialog open={isUploading}>
        <div className="loader_padding">
          <CircularProgress size={30} color="inherit" />
        </div>
      </Dialog>
      {/* </div>
      </Dialog> */}
    </>
  );
};

export default UploadVideo;
