import DoesNotExist from "components/DoesNotExist";
import Loader from "components/Loader";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { useQuery } from "hooks/useQuery";
import React, { useEffect, useState } from "react";
import BaseRoute from "routes/BaseRoute";
import { store } from "store";
import {
  getVideoCount,
  getUsersCount,
  getChannelsCount,
} from "store/async-actions/Analytics/analyticsActions";
import { getFeedVideos } from "store/async-actions/Feed/feedActions";
import {
  InitializeApp,
  InitializeNoAuthApp,
} from "store/async-actions/initializeApp";
import {
  getProfile,
  validateToken,
} from "store/async-actions/Profile/profileAction";
import {
  getDoesExistSelector,
  getHiveSelector,
  hiveDetailsLoadingSelector,
  launchLogin,
} from "store/reducers/HiveDetails/hiveDetails";
import { profileLoadingSelector } from "store/reducers/Profile/profile";
import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en.json";
import ae from "javascript-time-ago/locale/en-GB.json";
import MemberLoginConfirm from "components/MemberLoginConfirm/MemberLoginConfirm";
import Maintenance from "pages/Maintenance";

const LOGIN_KEY = "login";
const INVITE_TOKEN = "token";
const REF_KEY = "ref";
const USER_TYPE = "userType";
const TYPE = "type";
const REGISTER = "register";
const ATTENDED = "atxd";

TimeAgo.addDefaultLocale(en);
TimeAgo.addLocale(ae);

const App = () => {
  const dispatch = useAppDispatch();
  const doesExist = useAppSelector(getDoesExistSelector);
  const profileLoading = useAppSelector(profileLoadingSelector);
  const isLoading = useAppSelector(hiveDetailsLoadingSelector);

  // const fgMessages = fgMessaging();

  // onMessage(fgMessages, (payload) => {
  //   console.log("Message received. foreground from app ", payload);
  //   // ...
  // });

  const searchParams = useQuery();

  const token = searchParams.get(LOGIN_KEY)!;
  const invite_token = searchParams.get(INVITE_TOKEN);
  const userType = searchParams.get(USER_TYPE);
  const type = searchParams.get(TYPE);
  const register = searchParams.get(REGISTER);
  const attended = searchParams.get(ATTENDED);

  const initialize = async () => {
    await dispatch(getProfile(null));
    setTimeout(async () => {
      try {
        localStorage.getItem("isLoggedIn") === "yes"
          ? await InitializeApp(dispatch)
          : await InitializeNoAuthApp(dispatch);
      } catch (error) {
        // handle error
      }
      await Promise.all([
        dispatch(
          getVideoCount({
            hiveId: store.getState().hive.hiveDetails?.communityId!,
          })
        ),
        dispatch(
          getUsersCount({
            hiveId: store.getState().hive.hiveDetails?.communityId!,
          })
        ),
        dispatch(
          getChannelsCount({
            hiveId: store.getState().hive.hiveDetails?.communityId!,
          })
        ),
      ]);
      dispatch(
        getFeedVideos({
          organizationUuid: store.getState().hive.hiveDetails?.communityUuid!,
          pageNo: 0,
          contentLimit: 15,
          searchQuery: null,
        })
      );
    }, 800);
  };

  // const subDomain = process.env.REACT_APP_SUB_DOMAIN;

  useEffect(() => {
    if (!!register) {
      localStorage.setItem("register", register);
    }
  }, [dispatch, register]);

  useEffect(() => {
    let subDomain = document.location.hostname.split(".")[0];
    if (subDomain === "localhost" || subDomain === "10") {
      subDomain = "vee";
    }

    localStorage.setItem("subDomain", subDomain!);
    if (token) {
      localStorage.setItem("@jwtToken", token);
    }

    if (invite_token) {
      dispatch(validateToken({ token: invite_token }));
    }

    initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [isOpen, setIsOpen] = useState(false);

  const handleConfirmOpen = () => {
    setIsOpen(true);
  };

  const handleConfirmClose = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    if (!!userType) {
      handleConfirmOpen();
    }

    if (!!type) {
      localStorage.setItem("userType", type);
    }
  }, [type, userType]);

  useEffect(() => {
    if (!!attended) {
      localStorage.setItem("atxd", attended);
    } else {
      localStorage.removeItem("atxd");
    }
  }, [attended]);

  // async function requestPermission() {
  //   const permission = await Notification.requestPermission();
  //   if (permission === "granted") {
  //     //Generate token
  //     const token = await getToken(messaging, {
  //       vapidKey:
  //         "BLZBGou3Hgxkh5ey2JLJXhE50NO2cVfNjlyt9DKASb4qzFKM362WpuyebPe86IKjcifjIdJPgMw6-D8obC_I4sk",
  //     });
  //     dispatch(
  //       updateFCMToken({
  //         notificationId: token,
  //         appVersion: "1.0.1",
  //         appOS: "Web",
  //       })
  //     );
  //   } else {
  //     console.log("Notification not allowed");
  //     // toastInfo("Please allow notifications for better experience");
  //   }
  // }

  // useEffect(() => {
  //   requestPermission();
  // }, []);

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <>{doesExist ? <BaseRoute /> : <Maintenance />}</>
      )}
      {/* {isOpen &&
        (userType === "sponsor" ||
          userType === "pitch" ||
          userType === "member") && (
          <MemberLoginConfirm
            isOpen={isOpen}
            close={handleConfirmClose}
            title={userType || ""}
          />
        )} */}
    </>
  );
};

export default App;
