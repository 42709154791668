export const navbar = [
  {
    id: 10,
    user_type: "admin",
    component_type: "secondaryBtn",
    component_code: "Join",
    component_name: "Join",
    component_icon: "join-icon.svg",
    route: "/join",
  },
  {
    id: 11,
    user_type: "admin",
    component_type: "primaryBtn",
    component_code: "upload",
    component_name: "Upload",
    component_icon: "upload-icon.svg",
    route: "/upload",
  },
];
