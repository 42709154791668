import BackButton from "components/BackButton";
import LineBreak from "components/LineBreak";
import CampaignUUIDDropdown from "components/UUIDDropdown/CampaignUuid";
import StoryUUIDDropdown from "components/UUIDDropdown/UUIDDropdown";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { toastSuccess } from "hooks/useToastify";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  getCampaignsList,
  getLinkedCampaigns,
  linkCampaignToStory,
} from "store/async-actions/Campaign/campaign";
import { getStories } from "store/async-actions/Story/storyActions";
import { getCampaignsListSelector } from "store/reducers/Campaign/campaign";
import { getHiveUuid } from "store/reducers/HiveDetails/hiveDetails";
import { getAllStoriesSelector } from "store/reducers/Story/story";

const CampaignToStory = () => {
  const dispatch = useAppDispatch();
  const hiveUuid = useAppSelector(getHiveUuid);

  useEffect(() => {
    dispatch(
      getStories({
        organizationUuid: hiveUuid,
      })
    );
    dispatch(getCampaignsList({ organizationUuid: hiveUuid }));
  }, [dispatch, hiveUuid]);

  const campaignList = useAppSelector(getCampaignsListSelector);
  const stories = useAppSelector(getAllStoriesSelector);

  const [selectedStory, setSelectedStory] = useState("");
  const [selectedCampaign, setSelectedCampaign] = useState("");

  const navigate = useNavigate();

  const handleLink = () => {
    dispatch(
      linkCampaignToStory({
        campaignUuid: selectedCampaign,
        serviceType: "STORY",
        serviceUuid: selectedStory,
        organizationUuid: hiveUuid,
      })
    );
    toastSuccess("Successfully linked your story");
    navigate("/linkfeatures/all");
  };

  return (
    <>
      <LineBreak />
      <BackButton />
      <LineBreak />
      <h4>Select Campaign</h4>
      <LineBreak />
      <CampaignUUIDDropdown
        uuidList={campaignList}
        selectedUuid={selectedCampaign}
        setSelectedUuid={setSelectedCampaign}
      />
      <LineBreak />
      <h4>Select Story</h4>
      <LineBreak />
      <StoryUUIDDropdown
        uuidList={stories}
        selectedUuid={selectedStory}
        setSelectedUuid={setSelectedStory}
      />
      <div onClick={handleLink} className="nextBtn primaryBtn">
        Link
      </div>
    </>
  );
};

export default CampaignToStory;
