import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "api";
import { CreateChannelRequest } from "api/models/Hive/createChannel";
import { ContextRequest, EditHiveRequest } from "api/models/Hive/hiveEdit";
import { UploadContentItem } from "api/models/Videos/uploadContent";
import { toastSuccess } from "hooks/useToastify";
import { RootState } from "store";
import { setVideoFile } from "store/reducers/Videos/videos";
import { getCurrentPlan } from "../Subscriptions/subscriptions";
import { HiveGuidelinesModel } from "api/models/Hive/hiveDetails";
import {
  ChatBotChatroomsReq,
  ChatBotChatroomMessagesReq,
  ChatBotGoodResponseReq,
} from "api/models/Chat/chat";

export const getHiveComponents = createAsyncThunk(
  "mainMenu/getHiveComponents",
  async (body: any, { rejectWithValue }) => {
    try {
      const { data } = await api.hivePage.getHiveComponents(body);
      return data.data;
    } catch (error) {
      return rejectWithValue("Something went wrong");
    }
  }
);

export const getChildComponents = createAsyncThunk(
  "child/getChildComponents",
  async (
    body: {
      organizationId: number;
      parentComponentCode: string;
      channelUuid: string | null;
      isMemberView: boolean;
    },
    { rejectWithValue }
  ) => {
    try {
      const { data } = await api.hivePage.getChildComponents(
        body.channelUuid !== null
          ? body
          : {
              organizationId: body.organizationId,
              parentComponentCode: body.parentComponentCode,
              isMemberView: body.isMemberView,
            }
      );
      return data.data;
    } catch (error) {
      return rejectWithValue("Something went wrong");
    }
  }
);

export const getChannelList = createAsyncThunk(
  "channels/getChannelList",
  async (
    body: { hiveId: number; isMemberView: boolean },
    { rejectWithValue }
  ) => {
    try {
      const { data } = await api.hivePage.getHiveChannels(body);
      return data.data;
    } catch (error) {
      return rejectWithValue("Something went wrong");
    }
  }
);

export const getHiveGuidelines = createAsyncThunk(
  "hive/getHiveGuidelines",
  async (body: { organizationUuid: string }, { rejectWithValue }) => {
    try {
      const { data } = await api.hivePage.getHiveGuidelines(body);
      return data.data;
    } catch (error) {
      return rejectWithValue("Something went wrong");
    }
  }
);

export const editHiveGuidelines = createAsyncThunk(
  "hive/editHiveGuidelines",
  async (
    body: {
      guidelinesList: HiveGuidelinesModel[];
      organizationUuid: string;
    },
    { rejectWithValue, dispatch, getState }
  ) => {
    try {
      const { data } = await api.hivePage.editHiveGuidelines(body);

      const { hive } = getState() as RootState;
      dispatch(getHiveGuidelines({ organizationUuid: hive.hiveUuid }));

      return data.data;
    } catch (error) {
      return rejectWithValue("Something went wrong");
    }
  }
);

export const addHiveGuidelines = createAsyncThunk(
  "hive/addHiveGuidelines",
  async (
    body: {
      organizationUuid: string;
    },
    { rejectWithValue }
  ) => {
    try {
      const { data } = await api.hivePage.addHiveGuidelines(body);

      return data.data;
    } catch (error) {
      return rejectWithValue("Something went wrong");
    }
  }
);

export const deleteHiveGuidelines = createAsyncThunk(
  "hive/deleteHiveGuidelines",
  async (
    body: {
      guidelineId: number;
      organizationUuid: string;
    },
    { rejectWithValue }
  ) => {
    try {
      const { data } = await api.hivePage.deleteHiveGuidelines(body);

      return data.data;
    } catch (error) {
      return rejectWithValue("Something went wrong");
    }
  }
);

export const getHiveContext = createAsyncThunk(
  "hive/getHiveContext",
  async (body: { organizationUuid: string }, { rejectWithValue }) => {
    try {
      const { data } = await api.hivePage.getHiveContext(body);
      return data.data;
    } catch (error) {
      return rejectWithValue("Something went wrong");
    }
  }
);

export const editHiveContext = createAsyncThunk(
  "hive/editHiveContext",
  async (body: ContextRequest, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await api.hivePage.editHiveContext(body);
      dispatch(
        getHiveContext({
          organizationUuid: body.organizationUuid,
        })
      );
      return data.data;
    } catch (error) {
      return rejectWithValue("Something went wrong");
    }
  }
);

export const getHiveDetails = createAsyncThunk(
  "hive/getHiveDetails",
  async (body: any, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await api.hivePage.getHiveDetails(body);

      if (data.responseInfo.httpCode === 200) {
        dispatch(
          getCurrentPlan({
            organizationId: data.data.communityId,
          })
        );
      }

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const editHiveDetails = createAsyncThunk(
  "hive/editHiveDetails",
  async (body: EditHiveRequest, { rejectWithValue }) => {
    try {
      const { data } = await api.hivePage.editHiveDetails(body);
      return data.data;
    } catch (error) {
      return rejectWithValue("Something went wrong");
    }
  }
);

export const deleteHiveProduct = createAsyncThunk(
  "hive/deleteHiveProduct",
  async (
    body: {
      productId: string;
      organizationUuid: string;
    },
    { rejectWithValue, dispatch }
  ) => {
    try {
      const { data } = await api.hivePage.deleteHiveProduct(body);
      dispatch(
        getHiveContext({
          organizationUuid: body.organizationUuid,
        })
      );
      return data.data;
    } catch (error) {
      return rejectWithValue("Something went wrong");
    }
  }
);

export const getHiveListUsers = createAsyncThunk(
  "hive/getHiveListUsers",
  async (
    body: {
      profileId: string;
      organizationUuid: string;
    },
    { rejectWithValue, dispatch }
  ) => {
    try {
      const { data } = await api.hivePage.getHiveListUser(body);

      return data.data;
    } catch (error) {
      return rejectWithValue("Something went wrong");
    }
  }
);

export const getActivityUserDetails = createAsyncThunk(
  "hive/getActivityUserDetails",
  async (
    body: {
      profileId: string;
      organizationUuid: string;
    },
    { rejectWithValue }
  ) => {
    try {
      const { data } = await api.hivePage.getHiveActivitiesUser(body);

      return data.data;
    } catch (error) {
      return rejectWithValue("Something went wrong");
    }
  }
);

export const addHiveProduct = createAsyncThunk(
  "hive/addHiveProduct",
  async (
    body: {
      productName: string;
      organizationUuid: string;
      productLink: string | null;
      productDesc: string | null;
      productUrl: string | null;
    },
    { rejectWithValue, dispatch }
  ) => {
    try {
      const { data } = await api.hivePage.addHiveProduct(body);
      dispatch(
        getHiveContext({
          organizationUuid: body.organizationUuid,
        })
      );
      return data.data;
    } catch (error) {
      return rejectWithValue("Something went wrong");
    }
  }
);

export const editHiveProduct = createAsyncThunk(
  "hive/editHiveProduct",
  async (
    body: {
      productName: string;
      productId: string;
      organizationUuid: string;
      productUrl: string;
      productLink: string | null;
      productDesc: string | null;
    },
    { rejectWithValue, dispatch }
  ) => {
    try {
      const { data } = await api.hivePage.editHiveProductName(body);
      dispatch(
        getHiveContext({
          organizationUuid: body.organizationUuid,
        })
      );
      return data.data;
    } catch (error) {
      return rejectWithValue("Something went wrong");
    }
  }
);

export const uploadHiveProduct = createAsyncThunk(
  "hive/uploadHiveProduct",
  async (
    body: {
      productId: string;
      organizationUuid: string;
      contentType: string;
    },
    { rejectWithValue, dispatch, getState }
  ) => {
    try {
      const { data } = await api.hivePage.uploadHiveProduct(body);

      const { videos } = getState() as RootState;

      const formData = new FormData();
      populateProductFormData(formData, data.data.data, videos.file!);

      await dispatch(
        uploadProductToS3({
          url: data.data.data.url,
          formData: formData,
        })
      );

      dispatch(
        getHiveContext({
          organizationUuid: body.organizationUuid,
        })
      );
      return data.data;
    } catch (error) {
      return rejectWithValue("Something went wrong");
    }
  }
);

export const getIntroPresignedUrl = createAsyncThunk(
  "hive/getIntroPresignedUrl",
  async (
    body: {
      type: string;
      communityId: number;
    },
    { rejectWithValue, dispatch, getState }
  ) => {
    try {
      const { data } = await api.hivePage.getIntroPresignedUrl(body);
      const { videos } = getState() as RootState;
      const formData = new FormData();
      populateFormData(formData, data.data.preSigned.data, videos.file!);

      dispatch(
        uploadToS3({ url: data.data.preSigned.data.url, formData: formData })
      );

      return data.data;
    } catch (error) {
      return rejectWithValue("Something went wrong");
    }
  }
);

export const populateFormData = (
  formData: FormData,
  contentUploadResp: UploadContentItem,
  file: File
) => {
  formData.append(
    "x-amz-meta-userid",
    contentUploadResp?.fields["x-amz-meta-userid"]!
  );
  formData.append("Content-Type", "video/mp4");
  formData.append("key", contentUploadResp?.fields.key!);
  formData.append("bucket", contentUploadResp?.fields.bucket!);
  formData.append(
    "X-Amz-Algorithm",
    contentUploadResp?.fields["X-Amz-Algorithm"]!
  );
  formData.append(
    "X-Amz-Credential",
    contentUploadResp?.fields["X-Amz-Credential"]!
  );
  formData.append("X-Amz-Date", contentUploadResp?.fields["X-Amz-Date"]!);
  formData.append(
    "X-Amz-Security-Token",
    contentUploadResp?.fields["X-Amz-Security-Token"]!
  );
  formData.append(
    "X-Amz-Signature",
    contentUploadResp?.fields["X-Amz-Signature"]!
  );
  formData.append("Policy", contentUploadResp?.fields.Policy!);
  formData.append("file", file!);
};

export const populateProductFormData = (
  formData: FormData,
  contentUploadResp: UploadContentItem,
  file: File
) => {
  formData.append(
    "x-amz-meta-userid",
    contentUploadResp?.fields["x-amz-meta-userid"]!
  );
  formData.append("Content-Type", contentUploadResp?.fields["Content-Type"]);
  formData.append("key", contentUploadResp?.fields.key!);
  formData.append("bucket", contentUploadResp?.fields.bucket!);
  formData.append(
    "X-Amz-Algorithm",
    contentUploadResp?.fields["X-Amz-Algorithm"]!
  );
  formData.append(
    "X-Amz-Credential",
    contentUploadResp?.fields["X-Amz-Credential"]!
  );
  formData.append("X-Amz-Date", contentUploadResp?.fields["X-Amz-Date"]!);
  formData.append(
    "X-Amz-Security-Token",
    contentUploadResp?.fields["X-Amz-Security-Token"]!
  );
  formData.append(
    "X-Amz-Signature",
    contentUploadResp?.fields["X-Amz-Signature"]!
  );
  formData.append("Policy", contentUploadResp?.fields.Policy!);
  formData.append("file", file!);
};

export const uploadToS3 = createAsyncThunk(
  "videos/uploadToS3",
  async (
    body: {
      url: string;
      formData: FormData;
    },
    { rejectWithValue }
  ) => {
    try {
      const { data } = await api.videos.uploadToS3(body.formData, body.url);
      return data.data;
    } catch (error) {
      return rejectWithValue("Something went wrong");
    }
  }
);

export const getChatbotChatrooms = createAsyncThunk(
  "hive/getChatbotChatrooms",
  async (body: ChatBotChatroomsReq, { rejectWithValue }) => {
    try {
      const { data } = await api.hivePage.getChatbotChatrooms(body);
      return data.data;
    } catch (error) {
      return rejectWithValue("Something went wrong");
    }
  }
);

export const getChatbotChatroomMessages = createAsyncThunk(
  "hive/getChatbotChatroomMessages",
  async (body: ChatBotChatroomMessagesReq, { rejectWithValue }) => {
    try {
      const { data } = await api.hivePage.getChatbotChatroomMessages(body);
      return data.data;
    } catch (error) {
      return rejectWithValue("Something went wrong");
    }
  }
);

export const markChatbotGoodResponse = createAsyncThunk(
  "hive/markChatbotGoodResponse",
  async (body: ChatBotGoodResponseReq, { rejectWithValue }) => {
    try {
      const { data } = await api.hivePage.markChatbotGoodResponse(body);
      return data.data;
    } catch (error) {
      return rejectWithValue("Something went wrong");
    }
  }
);

export const uploadProductToS3 = createAsyncThunk(
  "videos/uploadToS3",
  async (
    body: {
      url: string;
      formData: FormData;
    },
    { rejectWithValue, dispatch }
  ) => {
    try {
      const { data } = await api.videos.uploadToS3(body.formData, body.url);
      return data.data;
    } catch (error) {
      return rejectWithValue("Something went wrong");
    }
  }
);

export const getHiveActivities = createAsyncThunk(
  "hive/getHiveActivities",
  async (
    body: {
      communityId: number;
      pageNo: number;
      contentLimit: number;
      channelUuid?: string;
    },
    { rejectWithValue }
  ) => {
    try {
      const { data } = await api.hivePage.getHiveActivities(body);
      return data.data;
    } catch (error) {
      return rejectWithValue("Something went wrong");
    }
  }
);

export const getOnlineMembers = createAsyncThunk(
  "hiveActivities/getOnlineMembers",
  async (
    body: {
      organizationUuid: string;
    },
    { rejectWithValue }
  ) => {
    try {
      const { data } = await api.hivePage.getOnlineMembers(body);
      return data.data;
    } catch (error) {
      return rejectWithValue("Something went wrong");
    }
  }
);

export const getSocialLinks = createAsyncThunk(
  "hive/getSocialLinks",
  async (body: { organizationUuid: string }, { rejectWithValue }) => {
    try {
      const { data } = await api.hivePage.getSocialLinks(body);
      return data.data;
    } catch (error) {
      return rejectWithValue("Something went wrong");
    }
  }
);

export const getContactInfo = createAsyncThunk(
  "hive/getContactInfo",
  async (body: { organizationUuid: string }, { rejectWithValue }) => {
    try {
      const { data } = await api.hivePage.getContactInfo(body);
      return data.data;
    } catch (error) {
      return rejectWithValue("Something went wrong");
    }
  }
);

export const saveSocialLinks = createAsyncThunk(
  "hive/saveSocialLinks",
  async (
    body: {
      organizationUuid: string;
      componentCode: string;
      handleUrl: string;
    },
    { rejectWithValue, dispatch }
  ) => {
    try {
      const { data } = await api.hivePage.saveSocialLinks(body);
      dispatch(getSocialLinks({ organizationUuid: body.organizationUuid }));
      return data.data;
    } catch (error) {
      return rejectWithValue("Something went wrong");
    }
  }
);

export const saveContactInfo = createAsyncThunk(
  "hive/saveContactInfo",
  async (
    body: { organizationUuid: string; email: string; address: string },
    { rejectWithValue, dispatch }
  ) => {
    try {
      const { data } = await api.hivePage.saveContactInfo(body);
      dispatch(getContactInfo({ organizationUuid: body.organizationUuid }));
      return data.data;
    } catch (error) {
      return rejectWithValue("Something went wrong");
    }
  }
);

export const getPublicHives = createAsyncThunk(
  "hive/getPublicHives",
  async (body: { organizationUuid: string }, { rejectWithValue }) => {
    try {
      const { data } = await api.hivePage.getPublicHives(body);
      return data.data;
    } catch (error) {
      return rejectWithValue("Something went wrong");
    }
  }
);

export const createChannel = createAsyncThunk(
  "createChannel/createChannel",
  async (body: CreateChannelRequest, { rejectWithValue }) => {
    try {
      const { data } = await api.hivePage.createChannel(body);
      return data.data;
    } catch (error) {
      return rejectWithValue("Something went wrong");
    }
  }
);
export const getHiveMembers = createAsyncThunk(
  "hiveMembers/getHiveMembers",
  async (
    body: {
      hiveId: number;
      pageNo: number;
      contentLimit: number;
      userName?: string;
      channelUuid?: string;
    },
    { rejectWithValue }
  ) => {
    try {
      const { data } = await api.hivePage.getMembersList(body);
      return data.data;
    } catch (error) {
      return rejectWithValue("Something went wrong");
    }
  }
);

export const getAllHiveMembers = createAsyncThunk(
  "hiveMembers/getAllHiveMembers",
  async (
    body: {
      hiveId: number;
      pageNo: number;
      contentLimit: number;
    },
    { rejectWithValue }
  ) => {
    try {
      const { data } = await api.hivePage.getMembersList(body);
      return data.data;
    } catch (error) {
      return rejectWithValue("Something went wrong");
    }
  }
);

export const removeHiveMember = createAsyncThunk(
  "hiveMembers/removeHiveMember",
  async (
    body: {
      userId: number;
      hiveId: number;
    },
    { rejectWithValue, dispatch, getState }
  ) => {
    try {
      const { data } = await api.hivePage.removeHiveMember(body);

      const { hiveSettings } = getState() as RootState;

      dispatch(
        getHiveMembers({
          hiveId: body.hiveId,
          pageNo: hiveSettings.hiveMembersPageNo,
          contentLimit: 15,
        })
      );
      return data.data;
    } catch (error) {
      return rejectWithValue("Something went wrong");
    }
  }
);

export const addSuperAdmin = createAsyncThunk(
  "hiveMembers/addSuperAdmin",
  async (
    body: {
      userId: number;
      organizationUuid: string;
    },
    { rejectWithValue, dispatch, getState }
  ) => {
    try {
      const { data } = await api.hivePage.addSuperAdmin(body);

      const { hiveSettings, hive } = getState() as RootState;

      dispatch(
        getHiveMembers({
          hiveId: hive.hiveDetails?.communityId || 0,
          pageNo: hiveSettings.hiveMembersPageNo,
          contentLimit: 15,
        })
      );
      return data.data;
    } catch (error) {
      return rejectWithValue("Something went wrong");
    }
  }
);

export const emailMemberInvite = createAsyncThunk(
  "hiveMembers/emailMemberInvite",
  async (
    body: {
      users: {
        email: string;
      }[];
      channels: string[];
      communityId: number;
      skipEmails: boolean;
    },
    { rejectWithValue }
  ) => {
    try {
      const { data } = await api.hivePage.emailMemberInvite(body);
      return data.data;
    } catch (error) {
      return rejectWithValue("Something went wrong");
    }
  }
);
export const emailAdminInvite = createAsyncThunk(
  "hiveMembers/emailAdminInvite",
  async (
    body: {
      users: {
        email: string;
      }[];
      channels: string[];
      communityId: number;
      skipEmails: boolean;
    },
    { rejectWithValue }
  ) => {
    try {
      const { data } = await api.hivePage.emailAdminInvite(body);
      return data.data;
    } catch (error) {
      return rejectWithValue("Something went wrong");
    }
  }
);
export const phoneMemberInvite = createAsyncThunk(
  "hiveMembers/phoneMemberInvite",
  async (
    body: {
      users: {
        mobileNo: string;
      }[];
      channels: string[];
      communityId: number;
      skipEmails: boolean;
    },
    { rejectWithValue }
  ) => {
    try {
      const { data } = await api.hivePage.phoneMemberInvite(body);
      return data.data;
    } catch (error) {
      return rejectWithValue("Something went wrong");
    }
  }
);
export const phoneAdminInvite = createAsyncThunk(
  "hiveMembers/phoneAdminInvite",
  async (
    body: {
      users: {
        mobileNo: string;
      }[];
      channels: string[];
      communityId: number;
      skipEmails: boolean;
    },
    { rejectWithValue }
  ) => {
    try {
      const { data } = await api.hivePage.phoneAdminInvite(body);
      return data.data;
    } catch (error) {
      return rejectWithValue("Something went wrong");
    }
  }
);
