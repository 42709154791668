import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { createTypedSelector } from "store/utils";
import {
  getAnalyticsChannelMetrics,
  getChannelsCount,
  getCtaNameDetails,
  getHiveRevenue,
  getHiveRevenueGraph,
  getMaybeCtaDetails,
  getNoCtaDetails,
  getPageNameDetails,
  getProductDetails,
  getProductsCount,
  getPurchaseChannelList,
  getPurchaseDetailList,
  getRedirectDayWiseList,
  getRedirectLinkList,
  getRedirectLocationList,
  getRedirectOriginList,
  getSegmentReactions,
  getSegmentViews,
  getSentimentAnalysis,
  getStoryReactions,
  getStoryViews,
  getUniqueSessions,
  getUsageData,
  getUserActions,
  getUsersCount,
  getVideoCount,
  getVisitorsData,
  getWordCloud,
  getYesCtaDetails,
} from "store/async-actions/Analytics/analyticsActions";
import {
  AllStoryViewsModel,
  ChannelMetricsModel,
  ChannelsCountItem,
  PurchaseChannelModel,
  PurchaseDetailModel,
  RevenueModel,
  SentimentAnalysisModel,
  StoryViewModel,
  UsageRespModel,
  UsersCountItem,
  VideoCountItem,
  WordCloudModel,
} from "api/models/Analytics/metrics";
import {
  ProductDetailsModel,
  SessionsModel,
  UserActionsModel,
} from "api/models/Analytics/sessions";
import {
  DefaultCTAModel,
  ProductCountItem,
  RedirectDayWiseItem,
  RedirectLinksItem,
  RedirectLocationItem,
  RedirectOriginItem,
  RevenueGraphModel,
  VisitorsModel,
} from "api/models/Analytics/ctaModel";
import dayjs from "dayjs";
import { ReactionModel } from "api/models/Story/story";

interface ReducedReactions {
  happy: number;
  neutral: number;
  sad: number;
  total: number;
}

const initialState: {
  analyticsTabs: string[];
  analtyticsTabIndex: number;
  videoCount: number;
  usersCount: number;
  channelsCount: number;
  isFetching: boolean;
  usageData: UsageRespModel | undefined;
  sentimentAnalysis: SentimentAnalysisModel | undefined;
  wordCloudResp: WordCloudModel[];
  uniqueSessions: SessionsModel | undefined;
  visitorsData: VisitorsModel[];
  productsCount: ProductCountItem[];
  fromDate: Date | null;
  toDate: Date | null;
  productDetails: ProductDetailsModel | undefined;
  reducedProductDetails: any[];
  userActions: UserActionsModel | undefined;
  productListPageNo: number;
  hiveRevenue: RevenueModel | undefined;
  hiveRevenueGraph: RevenueGraphModel[];
  purchaseChannelList: PurchaseChannelModel[];
  purchaseDetailList: PurchaseDetailModel[];
  currentChannelId: number;
  analyticsChannelMetrics: ChannelMetricsModel | undefined;
  storyViews: StoryViewModel | undefined;
  storyReactions: ReactionModel[];
  reducedReactions: ReducedReactions;
  ctaNameDetails: any;
  ctaPageDetails: number;
  yesCtaCount: number;
  noCtaCount: number;
  maybeCtaCount: number;
  redirectLinks: RedirectLinksItem[];
  redirectOrigins: RedirectOriginItem[];
  redirectLocations: RedirectLocationItem[];
  redirectDayWiseList: RedirectDayWiseItem[];
  currentRedirectLink: string;
  currentRedirectOrigin: string;
} = {
  productListPageNo: 0,
  userActions: undefined,
  productDetails: undefined,
  fromDate: dayjs().startOf("day").toDate(),
  toDate: dayjs().endOf("day").toDate(),
  productsCount: [],
  visitorsData: [],
  analyticsTabs: ["Overview", "Channels", "Sentiments", "Links"],
  analtyticsTabIndex: 0,
  videoCount: 0,
  usersCount: 0,
  channelsCount: 0,
  isFetching: false,
  usageData: undefined,
  sentimentAnalysis: undefined,
  wordCloudResp: [],
  uniqueSessions: undefined,
  reducedProductDetails: [],
  hiveRevenue: undefined,
  hiveRevenueGraph: [],
  purchaseChannelList: [],
  purchaseDetailList: [],
  currentChannelId: 0,
  analyticsChannelMetrics: undefined,
  storyViews: undefined,
  storyReactions: [],
  reducedReactions: {
    happy: 0,
    neutral: 0,
    sad: 0,
    total: 0,
  },
  ctaNameDetails: "",
  ctaPageDetails: 0,
  yesCtaCount: 0,
  noCtaCount: 0,
  maybeCtaCount: 0,
  redirectLinks: [],
  redirectOrigins: [],
  redirectLocations: [],
  redirectDayWiseList: [],
  currentRedirectLink: "",
  currentRedirectOrigin: "",
};

const analyticsSlice = createSlice({
  name: "analytics",
  initialState,
  reducers: {
    setAnalyticsTab: (state, action: PayloadAction<number>) => {
      state.analtyticsTabIndex = action.payload;
    },
    setCurrentChannelId: (state, action: PayloadAction<number>) => {
      state.currentChannelId = action.payload;
    },
    setFromDate: (state, action: PayloadAction<Date | null>) => {
      state.fromDate = action.payload;
    },
    setToDate: (state, action: PayloadAction<Date | null>) => {
      state.toDate = action.payload;
    },
    setProductListPageNo: (state, action: PayloadAction<number>) => {
      state.productListPageNo = action.payload;
    },
    setCurrentRedirectLink: (state, action: PayloadAction<string>) => {
      state.currentRedirectLink = action.payload;
    },
    setCurrentRedirectOrigin: (state, action: PayloadAction<string>) => {
      state.currentRedirectOrigin = action.payload;
    },
  },
  extraReducers: {
    //VIDEO-COUNT

    [getVideoCount.pending.type]: (state) => {
      state.isFetching = true;
    },
    [getVideoCount.fulfilled.type]: (
      state,
      action: PayloadAction<VideoCountItem>
    ) => {
      state.videoCount = action.payload.noOfVideos;
      state.isFetching = false;
    },
    [getVideoCount.rejected.type]: (state) => {
      state.isFetching = false;
    },

    //USERS COUNT

    [getUsersCount.pending.type]: (state) => {
      state.isFetching = true;
    },
    [getUsersCount.fulfilled.type]: (
      state,
      action: PayloadAction<UsersCountItem>
    ) => {
      state.usersCount = action.payload.noOfUsers;
      state.isFetching = false;
    },
    [getUsersCount.rejected.type]: (state) => {
      state.isFetching = false;
    },

    //CHANNELS COUNT
    [getChannelsCount.pending.type]: (state) => {
      state.isFetching = true;
    },
    [getChannelsCount.fulfilled.type]: (
      state,
      action: PayloadAction<ChannelsCountItem>
    ) => {
      state.channelsCount = action.payload.noOfChannels;
      state.isFetching = false;
    },
    [getChannelsCount.rejected.type]: (state) => {
      state.isFetching = false;
    },

    //USAGE DATA
    [getUsageData.pending.type]: (state) => {
      state.isFetching = true;
    },
    [getUsageData.fulfilled.type]: (
      state,
      action: PayloadAction<UsageRespModel>
    ) => {
      state.usageData = action.payload;
      state.isFetching = false;
    },
    [getUsageData.rejected.type]: (state) => {
      state.isFetching = false;
    },

    //STORY VIEWS
    [getStoryViews.pending.type]: (state) => {
      state.isFetching = true;
    },
    [getStoryViews.fulfilled.type]: (
      state,
      action: PayloadAction<StoryViewModel>
    ) => {
      state.storyViews = action.payload;
      state.isFetching = false;
    },
    [getStoryViews.rejected.type]: (state) => {
      state.isFetching = false;
    },

    //SEGMENT VIEWS
    [getSegmentViews.pending.type]: (state) => {
      state.isFetching = true;
    },
    [getSegmentViews.fulfilled.type]: (
      state,
      action: PayloadAction<StoryViewModel>
    ) => {
      state.storyViews = action.payload;
      state.isFetching = false;
    },
    [getSegmentViews.rejected.type]: (state) => {
      state.isFetching = false;
    },

    //REDIRECT LINKS LIST
    [getRedirectLinkList.pending.type]: (state) => {
      state.isFetching = true;
    },
    [getRedirectLinkList.fulfilled.type]: (
      state,
      action: PayloadAction<RedirectLinksItem[]>
    ) => {
      state.redirectLinks = action.payload;
      state.isFetching = false;
    },
    [getRedirectLinkList.rejected.type]: (state) => {
      state.isFetching = false;
    },

    //REDIRECT ORIGIN LIST
    [getRedirectOriginList.pending.type]: (state) => {
      state.isFetching = true;
    },
    [getRedirectOriginList.fulfilled.type]: (
      state,
      action: PayloadAction<RedirectOriginItem[]>
    ) => {
      state.redirectOrigins = action.payload;
      state.isFetching = false;
    },
    [getRedirectOriginList.rejected.type]: (state) => {
      state.isFetching = false;
    },

    //REDIRECT LOCATION LIST
    [getRedirectLocationList.pending.type]: (state) => {
      state.isFetching = true;
    },
    [getRedirectLocationList.fulfilled.type]: (
      state,
      action: PayloadAction<RedirectLocationItem[]>
    ) => {
      state.redirectLocations = action.payload;
      state.isFetching = false;
    },
    [getRedirectLocationList.rejected.type]: (state) => {
      state.isFetching = false;
    },

    //REDIRECT DAY WISE LIST
    [getRedirectDayWiseList.pending.type]: (state) => {
      state.isFetching = true;
    },
    [getRedirectDayWiseList.fulfilled.type]: (
      state,
      action: PayloadAction<RedirectDayWiseItem[]>
    ) => {
      state.redirectDayWiseList = action.payload;
      state.isFetching = false;
    },
    [getRedirectDayWiseList.rejected.type]: (state) => {
      state.isFetching = false;
    },

    //STORY REACTIONS
    [getStoryReactions.pending.type]: (state) => {
      state.isFetching = true;
    },
    [getStoryReactions.fulfilled.type]: (
      state,
      action: PayloadAction<ReactionModel[]>
    ) => {
      state.reducedReactions.total = 0;
      state.reducedReactions.happy = 0;
      state.reducedReactions.neutral = 0;
      state.reducedReactions.sad = 0;
      state.storyReactions = action.payload;
      action.payload.map((reaction) => {
        state.reducedReactions.total += reaction.reactionCount;
        if (reaction.reactionId === 3) {
          state.reducedReactions.happy += reaction.reactionCount;
        } else if (reaction.reactionId === 2) {
          state.reducedReactions.neutral += reaction.reactionCount;
        } else if (reaction.reactionId === 1) {
          state.reducedReactions.sad += reaction.reactionCount;
        }
      });
      state.isFetching = false;
    },
    [getStoryReactions.rejected.type]: (state) => {
      state.isFetching = false;
    },

    //SEGMENT REACTIONS
    [getSegmentReactions.pending.type]: (state) => {
      state.isFetching = true;
    },
    [getSegmentReactions.fulfilled.type]: (
      state,
      action: PayloadAction<ReactionModel[]>
    ) => {
      state.reducedReactions.total = 0;
      state.reducedReactions.happy = 0;
      state.reducedReactions.neutral = 0;
      state.reducedReactions.sad = 0;
      state.storyReactions = action.payload;
      action.payload.map((reaction) => {
        state.reducedReactions.total += reaction.reactionCount;
        if (reaction.reactionId === 3) {
          state.reducedReactions.happy += reaction.reactionCount;
        } else if (reaction.reactionId === 2) {
          state.reducedReactions.neutral += reaction.reactionCount;
        } else if (reaction.reactionId === 1) {
          state.reducedReactions.sad += reaction.reactionCount;
        }
      });
      state.isFetching = false;
    },
    [getSegmentReactions.rejected.type]: (state) => {
      state.isFetching = false;
    },

    //SENTIMENT ANALYSIS
    [getSentimentAnalysis.pending.type]: (state) => {
      state.isFetching = true;
    },
    [getSentimentAnalysis.fulfilled.type]: (
      state,
      action: PayloadAction<SentimentAnalysisModel>
    ) => {
      state.sentimentAnalysis = action.payload;
      state.isFetching = false;
    },
    [getSentimentAnalysis.rejected.type]: (state) => {
      state.isFetching = false;
    },

    //UNIQUE SESSIONS
    [getUniqueSessions.pending.type]: (state) => {
      state.isFetching = true;
    },
    [getUniqueSessions.fulfilled.type]: (
      state,
      action: PayloadAction<SessionsModel>
    ) => {
      state.uniqueSessions = action.payload;
      state.isFetching = false;
    },
    [getUniqueSessions.rejected.type]: (state) => {
      state.isFetching = false;
    },

    //Visitors Data
    [getVisitorsData.pending.type]: (state) => {
      state.isFetching = true;
    },
    [getVisitorsData.fulfilled.type]: (
      state,
      action: PayloadAction<VisitorsModel[]>
    ) => {
      state.visitorsData = action.payload;
      state.isFetching = false;
    },
    [getVisitorsData.rejected.type]: (state) => {
      state.isFetching = false;
    },

    //Hive Revenue
    [getHiveRevenue.pending.type]: (state) => {
      state.isFetching = true;
    },
    [getHiveRevenue.fulfilled.type]: (
      state,
      action: PayloadAction<RevenueModel>
    ) => {
      state.hiveRevenue = action.payload;
      state.isFetching = false;
    },
    [getHiveRevenue.rejected.type]: (state) => {
      state.isFetching = false;
    },

    //Purchase Channel List
    [getPurchaseChannelList.pending.type]: (state) => {
      state.isFetching = true;
    },
    [getPurchaseChannelList.fulfilled.type]: (
      state,
      action: PayloadAction<PurchaseChannelModel[]>
    ) => {
      state.purchaseChannelList = action.payload;
      state.isFetching = false;
    },
    [getPurchaseChannelList.rejected.type]: (state) => {
      state.isFetching = false;
    },

    //Purchase Detail List
    [getPurchaseDetailList.pending.type]: (state) => {
      state.isFetching = true;
    },
    [getPurchaseDetailList.fulfilled.type]: (
      state,
      action: PayloadAction<PurchaseDetailModel[]>
    ) => {
      state.purchaseDetailList = action.payload;
      state.isFetching = false;
    },
    [getPurchaseDetailList.rejected.type]: (state) => {
      state.isFetching = false;
    },

    //Analytics Channel Metrics
    [getAnalyticsChannelMetrics.pending.type]: (state) => {
      state.isFetching = true;
    },
    [getAnalyticsChannelMetrics.fulfilled.type]: (
      state,
      action: PayloadAction<ChannelMetricsModel>
    ) => {
      state.analyticsChannelMetrics = action.payload;
      state.isFetching = false;
    },
    [getAnalyticsChannelMetrics.rejected.type]: (state) => {
      state.isFetching = false;
    },

    //Hive Revenue Graph
    [getHiveRevenueGraph.pending.type]: (state) => {
      state.isFetching = true;
    },
    [getHiveRevenueGraph.fulfilled.type]: (
      state,
      action: PayloadAction<RevenueGraphModel[]>
    ) => {
      state.hiveRevenueGraph = action.payload;
      state.isFetching = false;
    },
    [getHiveRevenueGraph.rejected.type]: (state) => {
      state.isFetching = false;
    },

    //Products Count Data
    [getProductsCount.pending.type]: (state) => {
      state.isFetching = true;
    },
    [getProductsCount.fulfilled.type]: (
      state,
      action: PayloadAction<ProductCountItem[]>
    ) => {
      state.reducedProductDetails = [];
      state.productsCount = action.payload;
      state.productsCount.map((product) => {
        state.reducedProductDetails.push(product.appName);
      });
      state.isFetching = false;
    },
    [getProductsCount.rejected.type]: (state) => {
      state.isFetching = false;
    },

    //Products Detail Data
    [getProductDetails.pending.type]: (state) => {
      state.isFetching = true;
    },
    [getProductDetails.fulfilled.type]: (
      state,
      action: PayloadAction<ProductDetailsModel>
    ) => {
      state.productDetails = action.payload;
      state.reducedProductDetails.map((product, idx) => {
        if (state.productDetails?.content[0].appName === product) {
          state.reducedProductDetails[idx] = action.payload;
        } else if (
          product.content &&
          state.productDetails?.content[0].appName ===
            product.content[0].appName
        ) {
          state.reducedProductDetails[idx] = action.payload;
        }
      });
      state.isFetching = false;
    },
    [getProductDetails.rejected.type]: (state) => {
      state.isFetching = false;
    },

    //User Actions Data
    [getUserActions.pending.type]: (state) => {
      state.isFetching = true;
    },
    [getUserActions.fulfilled.type]: (
      state,
      action: PayloadAction<UserActionsModel>
    ) => {
      state.userActions = action.payload;
      state.isFetching = false;
    },
    [getUserActions.rejected.type]: (state) => {
      state.isFetching = false;
    },

    //CTA NAME DETAILS
    [getCtaNameDetails.pending.type]: (state) => {
      state.isFetching = true;
    },
    [getCtaNameDetails.fulfilled.type]: (
      state,
      action: PayloadAction<UserActionsModel>
    ) => {
      state.ctaNameDetails = action.payload;
      state.isFetching = false;
    },
    [getCtaNameDetails.rejected.type]: (state) => {
      state.isFetching = false;
    },

    //YES CTA NAME DETAILS
    [getYesCtaDetails.pending.type]: (state) => {
      state.isFetching = true;
    },
    [getYesCtaDetails.fulfilled.type]: (
      state,
      action: PayloadAction<number>
    ) => {
      state.yesCtaCount = action.payload;
      state.isFetching = false;
    },
    [getYesCtaDetails.rejected.type]: (state) => {
      state.isFetching = false;
    },

    //NO CTA NAME DETAILS
    [getNoCtaDetails.pending.type]: (state) => {
      state.isFetching = true;
    },
    [getNoCtaDetails.fulfilled.type]: (
      state,
      action: PayloadAction<number>
    ) => {
      state.noCtaCount = action.payload;
      state.isFetching = false;
    },
    [getNoCtaDetails.rejected.type]: (state) => {
      state.isFetching = false;
    },

    //MAYBE CTA NAME DETAILS
    [getMaybeCtaDetails.pending.type]: (state) => {
      state.isFetching = true;
    },
    [getMaybeCtaDetails.fulfilled.type]: (
      state,
      action: PayloadAction<number>
    ) => {
      state.maybeCtaCount = action.payload;
      state.isFetching = false;
    },
    [getMaybeCtaDetails.rejected.type]: (state) => {
      state.isFetching = false;
    },

    //CTA PAGE DETAILS
    [getPageNameDetails.pending.type]: (state) => {
      state.isFetching = true;
    },
    [getPageNameDetails.fulfilled.type]: (
      state,
      action: PayloadAction<number>
    ) => {
      state.ctaPageDetails = action.payload;
      state.isFetching = false;
    },
    [getPageNameDetails.rejected.type]: (state) => {
      state.isFetching = false;
    },

    // //WORDCLOUD DATA
    // [getWordCloud.pending.type]: (state) => {
    //   state.isFetching = true;
    // },
    // [getWordCloud.fulfilled.type]: (
    //   state,
    //   action: PayloadAction<WordCloudModel[]>
    // ) => {
    //   state.wordCloudResp = action.payload;
    // },
    // [getWordCloud.rejected.type]: (state) => {
    //   state.isFetching = false;
    // },
  },
});

export const {
  setAnalyticsTab,
  setFromDate,
  setToDate,
  setProductListPageNo,
  setCurrentChannelId,
  setCurrentRedirectLink,
  setCurrentRedirectOrigin,
} = analyticsSlice.actions;

export const getAnalyticsTabIndexSelector = createTypedSelector(
  (state) => state.analytics.analtyticsTabIndex
);

export const getTotalReactionSelector = createTypedSelector(
  (state) => state.analytics.reducedReactions
);

export const getCurrentRedirectLinkSelector = createTypedSelector(
  (state) => state.analytics.currentRedirectLink
);

export const getCurrentRedirectOriginSelector = createTypedSelector(
  (state) => state.analytics.currentRedirectOrigin
);

export const getChannelDetailsMetricsSelector = createTypedSelector(
  (state) => state.analytics.analyticsChannelMetrics
);

export const getRedirectDayWiseListSelector = createTypedSelector(
  (state) => state.analytics.redirectDayWiseList
);

export const getStoryViewsSelector = createTypedSelector(
  (state) => state.analytics.storyViews
);

export const getYesCtaCountSelector = createTypedSelector(
  (state) => state.analytics.yesCtaCount
);

export const getNoCtaCountSelector = createTypedSelector(
  (state) => state.analytics.noCtaCount
);

export const getMaybeCtaCountSelector = createTypedSelector(
  (state) => state.analytics.maybeCtaCount
);

export const getRedirectLinksSelector = createTypedSelector(
  (state) => state.analytics.redirectLinks
);

export const getRedirectOriginsSelector = createTypedSelector(
  (state) => state.analytics.redirectOrigins
);

export const getRedirectLocationsSelector = createTypedSelector(
  (state) => state.analytics.redirectLocations
);

export const getStoryReactionsSelector = createTypedSelector(
  (state) => state.analytics.storyReactions
);

export const getCurrentChannelIdSelector = createTypedSelector(
  (state) => state.analytics.currentChannelId
);

export const getPurchaseDetailListSelector = createTypedSelector(
  (state) => state.analytics.purchaseDetailList
);

export const getHiveRevenueSelector = createTypedSelector(
  (state) => state.analytics.hiveRevenue
);

export const getHiveRevenueGraphSelector = createTypedSelector(
  (state) => state.analytics.hiveRevenueGraph
);

export const getPurchaseChannelListSelector = createTypedSelector(
  (state) => state.analytics.purchaseChannelList
);

export const getUsageDataSelector = createTypedSelector(
  (state) => state.analytics.usageData
);

export const getUniqueSessionsSelector = createTypedSelector(
  (state) => state.analytics.uniqueSessions
);

export const getUserActionsSelector = createTypedSelector(
  (state) => state.analytics.userActions
);

export const getProductDetailsSelector = createTypedSelector(
  (state) => state.analytics.productDetails
);

export const getReducedProductDetailsSelector = createTypedSelector(
  (state) => state.analytics.reducedProductDetails
);

export const getAnalyticsTabsSelector = createTypedSelector(
  (state) => state.analytics.analyticsTabs
);

export const getSentimentsSelector = createTypedSelector(
  (state) => state.analytics.sentimentAnalysis
);

export const videoCountSelector = createTypedSelector(
  (state) => state.analytics.videoCount
);

export const usersCountSelector = createTypedSelector(
  (state) => state.analytics.usersCount
);

export const productListPageNo = createTypedSelector(
  (state) => state.analytics.productListPageNo
);

export const channelsCountSelector = createTypedSelector(
  (state) => state.analytics.channelsCount
);

export const getWordCloudSelector = createTypedSelector(
  (state) => state.analytics.wordCloudResp
);

export const getAnalyticsIsLoading = createTypedSelector(
  (state) => state.analytics.isFetching
);

export const getCtaPageSelector = createTypedSelector(
  (state) => state.analytics.ctaPageDetails
);

export const getCtaNameSelector = createTypedSelector(
  (state) => state.analytics.ctaNameDetails
);

export const getVisitorsSelector = createTypedSelector(
  (state) => state.analytics.visitorsData
);

export const getProductsCountSelector = createTypedSelector(
  (state) => state.analytics.productsCount
);

export const getFromDateSelector = createTypedSelector(
  (state) => state.analytics.fromDate
);

export const getToDateSelector = createTypedSelector(
  (state) => state.analytics.toDate
);

export default analyticsSlice.reducer;
