import React from 'react'
import Modal from '@mui/material/Modal'
import IconButton from '@mui/material/IconButton'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'

import TrashIcon from 'components/common/svg/TrashIcon'
import Text from 'components/common/Text'
import Button from 'components/common/Button'

interface DeleteConfirmatioModalProps {
  onClose: () => void
  onDelete: () => void
}

const DeleteConfirmatioModal: React.FC<DeleteConfirmatioModalProps> = ({
  onClose,
  onDelete,
}) => {
  return (
    <Modal open={true} className="confirmation_modal_container">
      <div className="confirmation_modal">
        <IconButton className="modal_close_button" onClick={onClose}>
          <CloseRoundedIcon fontSize="inherit" />
        </IconButton>
        <div className="modal_icon">
          <TrashIcon />
        </div>
        <Text fontWeight="w600" className="modal_title">
          Remove members
        </Text>
        <Text fontWeight="w400" className="modal_description">
          Are you sure you want to remove these members from the chatroom? This
          action cannot be undone.
        </Text>
        <Button
          className="delete_button modal_button"
          variant="contained"
          color="error"
          onClick={onDelete}
        >
          Delete
        </Button>
        <Button
          className="cancel_button modal_button"
          variant="outlined"
          onClick={onClose}
        >
          Cancel
        </Button>
      </div>
    </Modal>
  )
}

export default DeleteConfirmatioModal
