import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "api";
import {
  AddCampaignEmailsReq,
  AddCampaignNosReq,
  CreateCampaignReq,
  EditSMSCampaignReq,
  UploadCampaignResourceReq,
} from "api/models/Campaign/campaign";
import { RootState } from "store";
import { uploadToS3 } from "../Videos/videosActions";
import { toastError, toastSuccess } from "hooks/useToastify";

export const createCampaign = createAsyncThunk(
  "campaign/createCampaign",
  async (body: CreateCampaignReq, { rejectWithValue }) => {
    try {
      const { data } = await api.campaign.createCampaign(body);
      return data.data;
    } catch (error) {
      return rejectWithValue("Something went wrong");
    }
  }
);

export const editCampaign = createAsyncThunk(
  "campaign/editCampaign",
  async (body: EditSMSCampaignReq, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await api.campaign.editCampaign(body);
      dispatch(getCurrentCampaign({ campaignUuid: body.campaignUuid }));
      return data.data;
    } catch (error) {
      return rejectWithValue("Something went wrong");
    }
  }
);

export const getEmailImagePresignedUrl = createAsyncThunk(
  "hive/getImagePresignedUrl",
  async (
    body: {
      type: string;
      communityId: number;
    },
    { rejectWithValue, dispatch, getState }
  ) => {
    try {
      const { data } = await api.campaign.getEmailImagePresignedUrl(body);
      const { createChannel } = getState() as RootState;
      const formData = new FormData();
      formData.append(
        "x-amz-meta-userid",
        data.data.preSigned.data?.fields["x-amz-meta-userid"]!
      );
      formData.append(
        "Content-Type",
        createChannel.emailBannerFileBlob?.type || "image/jpeg"
      );
      formData.append("key", data.data.preSigned.data?.fields.key!);
      formData.append("bucket", data.data.preSigned.data?.fields.bucket!);
      formData.append(
        "X-Amz-Algorithm",
        data.data.preSigned.data?.fields["X-Amz-Algorithm"]!
      );
      formData.append(
        "X-Amz-Credential",
        data.data.preSigned.data?.fields["X-Amz-Credential"]!
      );
      formData.append(
        "X-Amz-Date",
        data.data.preSigned.data?.fields["X-Amz-Date"]!
      );
      formData.append(
        "X-Amz-Security-Token",
        data.data.preSigned.data?.fields["X-Amz-Security-Token"]!
      );
      formData.append(
        "X-Amz-Signature",
        data.data.preSigned.data?.fields["X-Amz-Signature"]!
      );
      formData.append("Policy", data.data.preSigned.data?.fields.Policy!);
      formData.append("file", createChannel.emailBannerFileBlob!);
      dispatch(
        uploadEmailImageToS3({
          url: data.data.preSigned.data.url,
          formData: formData,
        })
      );

      return data.data;
    } catch (error) {
      return rejectWithValue("Something went wrong");
    }
  }
);

export const uploadEmailImageToS3 = createAsyncThunk(
  "campaign/uploadToS3",
  async (
    body: {
      url: string;
      formData: FormData;
    },
    { rejectWithValue, dispatch }
  ) => {
    try {
      const response = await api.videos.uploadToS3(body.formData, body.url);
      const { status } = response; // Extract the response status code

      if (status === 204) {
        // Success response
        return response.data; // Return the response data if needed
      } else {
        // Handle other response codes
        // You can customize this logic based on your requirements
        return rejectWithValue("Unexpected response code");
      }
    } catch (error: any) {
      if (error?.response?.status && error?.response?.status > 205) {
      } else {
      }
      return rejectWithValue("Something went wrong");
    }
  }
);

export const editCampaignWithoutCurrent = createAsyncThunk(
  "campaign/editCampaign",
  async (body: EditSMSCampaignReq, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await api.campaign.editCampaign(body);
      return data.data;
    } catch (error) {
      return rejectWithValue("Something went wrong");
    }
  }
);

export const uploadCampaignResources = createAsyncThunk(
  "campaign/uploadCampaignResources",
  async (body: UploadCampaignResourceReq, { rejectWithValue }) => {
    try {
      const { data } = await api.campaign.uploadCampaignResources(body);
      return data.data;
    } catch (error) {
      return rejectWithValue("Something went wrong");
    }
  }
);

export const addCampaignNumbers = createAsyncThunk(
  "campaign/addCampaignNumbers",
  async (body: AddCampaignNosReq, { rejectWithValue }) => {
    try {
      const { data } = await api.campaign.addCampaignNumbers(body);
      return data.data;
    } catch (error) {
      return rejectWithValue("Something went wrong");
    }
  }
);

export const addCampaignEmails = createAsyncThunk(
  "campaign/addCampaignEmails",
  async (body: AddCampaignEmailsReq, { rejectWithValue }) => {
    try {
      const { data } = await api.campaign.addCampaignEmails(body);
      return data.data;
    } catch (error) {
      return rejectWithValue("Something went wrong");
    }
  }
);

export const linkCampaignToStory = createAsyncThunk(
  "campaign/linkCampaignToStory",
  async (
    body: {
      campaignUuid: string;
      serviceType: string;
      serviceUuid: string;
      organizationUuid: string;
    },
    { rejectWithValue }
  ) => {
    try {
      const { data } = await api.campaign.linkCampaignToStory(body);
      return data.data;
    } catch (error) {
      return rejectWithValue("Something went wrong");
    }
  }
);

export const getLinkedCampaigns = createAsyncThunk(
  "campaign/getLinkedCampaigns",
  async (
    body: {
      campaignUuid: string;
      organizationUuid: string;
    },
    { rejectWithValue }
  ) => {
    try {
      const { data } = await api.campaign.getLinkedCampaigns(body);
      return data.data;
    } catch (error) {
      return rejectWithValue("Something went wrong");
    }
  }
);

export const getCampaignsList = createAsyncThunk(
  "campaign/getCampaignsList",
  async (body: { organizationUuid: string }, { rejectWithValue }) => {
    try {
      const { data } = await api.campaign.getCampaignsList(body);
      return data.data;
    } catch (error) {
      return rejectWithValue("Something went wrong");
    }
  }
);

export const getCurrentCampaign = createAsyncThunk(
  "campaign/getCurrentCampaign",
  async (body: { campaignUuid: string }, { rejectWithValue }) => {
    try {
      const { data } = await api.campaign.getCurrentCampaign(body);
      return data.data;
    } catch (error) {
      return rejectWithValue("Something went wrong");
    }
  }
);

export const getCampaignNumbers = createAsyncThunk(
  "campaign/getCampaignNumbers",
  async (body: { campaignUuid: string }, { rejectWithValue }) => {
    try {
      const { data } = await api.campaign.getCampaignNumbers(body);
      return data.data;
    } catch (error) {
      return rejectWithValue("Something went wrong");
    }
  }
);

export const sendSMSCampaign = createAsyncThunk(
  "campaign/sendSMSCampaign",
  async (body: { campaignUuid: string }, { rejectWithValue }) => {
    try {
      const { data } = await api.campaign.sendSMSCampaign(body);
      return data.data;
    } catch (error) {
      return rejectWithValue("Something went wrong");
    }
  }
);

export const getEmailCampaignData = createAsyncThunk(
  "campaign/getEmailCampaignData",
  async (
    body: { campaignUuid: string; organizationUuid: string },
    { rejectWithValue }
  ) => {
    try {
      const { data } = await api.campaign.getEmailCampaignData(body);
      return data.data;
    } catch (error) {
      return rejectWithValue("Something went wrong");
    }
  }
);

export const getCampaignEmails = createAsyncThunk(
  "campaign/getCampaignEmails",
  async (body: { campaignUuid: string }, { rejectWithValue }) => {
    try {
      const { data } = await api.campaign.getCampaignEmails(body);
      return data.data;
    } catch (error) {
      return rejectWithValue("Something went wrong");
    }
  }
);

export const sendEmailCampaign = createAsyncThunk(
  "campaign/sendEmailCampaign",
  async (
    body: { campaignUuid: string; organizationUuid: string },
    { rejectWithValue }
  ) => {
    try {
      const { data } = await api.campaign.sendEmailCampaign(body);
      return data.data;
    } catch (error) {
      return rejectWithValue("Something went wrong");
    }
  }
);

export const deleteCampaign = createAsyncThunk(
  "campaign/deleteCampaign",
  async (
    body: { campaignUuid: string },
    { rejectWithValue, dispatch, getState }
  ) => {
    try {
      const { data } = await api.campaign.deleteCampaign(body);
      const { hive } = getState() as RootState;

      dispatch(
        getCampaignsList({
          organizationUuid: hive.hiveUuid,
        })
      );
      return data.data;
    } catch (error) {
      return rejectWithValue("Something went wrong");
    }
  }
);
