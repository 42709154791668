import BackButton from "components/BackButton";
import IslandLayout from "components/IslandLayout";
import LineBreak from "components/LineBreak";
import PageLayout from "components/PageLayout";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { getFormResponses } from "store/async-actions/Forms/forms";
import { getFormsResponsesListSelector } from "store/reducers/Forms/forms";
import { getHiveUuid } from "store/reducers/HiveDetails/hiveDetails";
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import moment from "moment";
import { FormResponseItem } from "api/models/Forms/forms";
import { FormDetailsModal } from "./components/FormDetailsModal";
import LongText from "components/LongText/LongText";

export const FormResponses = () => {
  const dispatch = useAppDispatch();

  const location = useLocation();

  const url = location.pathname;
  const secondSlashIndex = url.indexOf("/", url.indexOf("/") + 1);
  const thirdSlashIndex = url.indexOf("/", secondSlashIndex + 1);
  const formIdentifier = url.substring(
    secondSlashIndex + 1,
    thirdSlashIndex !== -1 ? thirdSlashIndex : url.length
  );

  const hiveUuid = useAppSelector(getHiveUuid);

  useEffect(() => {
    dispatch(
      getFormResponses({
        organizationUuid: hiveUuid,
        formUuid: formIdentifier,
      })
    );
  }, []);

  const formsList = useAppSelector(getFormsResponsesListSelector);

  const [currentForm, setCurrentForm] = useState<FormResponseItem[]>([]);
  const [openFormView, setOpenFormView] = useState(false);

  const handleFormViewClose = () => {
    setOpenFormView(false);
  };

  return (
    <PageLayout sideMenu={true}>
      <IslandLayout>
        <div className="page_padding">
          <BackButton to={`/forms`} />
          <LineBreak />
          <h2>Reponses</h2>
          <LineBreak />
          {!!formsList && !!formsList.length ? (
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="members table">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <p className="bold_heading_table">User Name</p>
                    </TableCell>
                    <TableCell>
                      <p className="bold_heading_table">Email Id</p>
                    </TableCell>

                    <TableCell align="left">
                      <p className="bold_heading_table">Mobile No.</p>
                    </TableCell>
                    <TableCell align="left">
                      <p className="bold_heading_table">Created on</p>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {!!formsList &&
                    formsList?.map((data, idx) => (
                      <TableRow
                        onClick={() => {
                          setCurrentForm(data.formList);
                          setOpenFormView(true);
                        }}
                        className="table_cell_hover"
                        key={idx}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell
                          //   onClick={() => handleUserClick(member)}
                          component="th"
                          scope="row"
                        >
                          {data.userName || "-"}
                        </TableCell>
                        <TableCell
                          //   onClick={() => handleUserClick(member)}
                          component="th"
                          scope="row"
                        >
                          {!!data.emailId ? (
                            <LongText title={data.emailId} cutoff={25} />
                          ) : (
                            "-"
                          )}
                        </TableCell>
                        <TableCell
                          //   onClick={() => handleUserClick(member)}
                          component="th"
                          scope="row"
                        >
                          {!!data.mobileNumber ? (
                            <LongText title={data.mobileNumber} cutoff={25} />
                          ) : (
                            "-"
                          )}
                        </TableCell>

                        <TableCell align="left">
                          {moment.utc(data.createdDate).local().format("LL")}
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <h4>No Created forms to show</h4>
          )}
        </div>
        {openFormView && (
          <FormDetailsModal
            isOpen={openFormView}
            onClose={handleFormViewClose}
            formDetails={currentForm}
          />
        )}
      </IslandLayout>
    </PageLayout>
  );
};
