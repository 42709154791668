import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getHiveComponents } from "store/async-actions/Hive/hivePage";
import { createTypedSelector } from "store/utils";
import { Components } from "api/models/Hive/hiveComponents";

const initialState: {
  allMenuItems: Components[];
  userMenuItems: Components[];
  adminMenuItems: Components[];
  navBarItems: Components[];
  hiveConfigItems: Components[];
  subscriptionItems: Components[];
  footerItems: Components[];
  selectedMenu: string;
  isFetching: boolean;
  totalUnreadCount: number;
} = {
  hiveConfigItems: [],
  allMenuItems: [],
  userMenuItems: [],
  adminMenuItems: [],
  navBarItems: [],
  subscriptionItems: [],
  footerItems: [],
  selectedMenu: "",
  isFetching: false,
  totalUnreadCount: 0,
};

const mainMenuSlice = createSlice({
  name: "mainMenu",
  initialState,
  reducers: {
    setSelectedMenu: (state, action: PayloadAction<string>) => {
      state.selectedMenu = action.payload;
    },
    setTotalUnreadCount: (state, action: PayloadAction<number>) => {
      state.totalUnreadCount = action.payload;
    },
  },
  extraReducers: {
    [getHiveComponents.pending.type]: (state) => {
      state.isFetching = true;
    },
    [getHiveComponents.fulfilled.type]: (
      state,
      action: PayloadAction<Components[]>
    ) => {
      state.allMenuItems = [];
      state.allMenuItems = action.payload;
      state.adminMenuItems = [];
      state.hiveConfigItems = [];
      state.navBarItems = [];
      state.userMenuItems = [];
      state.subscriptionItems = [];
      state.allMenuItems.map((menu, idx) => {
        if (menu.componentType === "user_menu") {
          state.userMenuItems.push(menu);
        }
        return 1;
      });
      state.allMenuItems.map((menu, idx) => {
        if (menu.componentType === "admin_menu") {
          state.adminMenuItems.push(menu);
        }
        return 1;
      });
      state.allMenuItems.map((menu, idx) => {
        if (menu.componentType === "hiveConfig") {
          state.hiveConfigItems.push(menu);
        }
        return 1;
      });
      state.allMenuItems.map((menu, idx) => {
        if (menu.componentType === "subscriptions") {
          state.subscriptionItems.push(menu);
        }
        return 1;
      });
      state.allMenuItems.map((menu, idx) => {
        if (menu.componentType === "footer") {
          state.footerItems.push(menu);
        }
        return 1;
      });
      state.allMenuItems.map((menu, idx) => {
        if (
          menu.componentType === "primaryBtn" ||
          menu.componentType === "secondaryBtn"
        ) {
          state.navBarItems.push(menu);
        }
        return 1;
      });
      state.isFetching = false;
    },
    [getHiveComponents.rejected.type]: (state) => {
      state.isFetching = false;
    },
    [getHiveComponents.pending.type]: (state) => {
      // to do
    },
    [getHiveComponents.rejected.type]: (state) => {
      // to do
    },
  },
});

export const getMainMenuSelector = createTypedSelector(
  (state) => state.mainMenu.allMenuItems
);

export const getSelectedMenu = createTypedSelector(
  (state) => state.mainMenu.selectedMenu
);

export const getUserMenuSelector = createTypedSelector(
  (state) => state.mainMenu.userMenuItems
);

export const getFooterSelector = createTypedSelector(
  (state) => state.mainMenu.footerItems
);

export const getHiveConfigMenuSelector = createTypedSelector(
  (state) => state.mainMenu.hiveConfigItems
);

export const getAdminMenuSelector = createTypedSelector(
  (state) => state.mainMenu.adminMenuItems
);

export const getNavBarSelector = createTypedSelector(
  (state) => state.mainMenu.navBarItems
);

export const getSubscriptionsTabSelector = createTypedSelector(
  (state) => state.mainMenu.subscriptionItems
);

export const getTotalUnreadCountSelector = createTypedSelector(
  (state) => state.mainMenu.totalUnreadCount
);

export const { setSelectedMenu, setTotalUnreadCount } = mainMenuSlice.actions;

export default mainMenuSlice.reducer;
