import React, { useCallback, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import { toastError } from "hooks/useToastify";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { getUploadedAiFileSelector, uploadAiFile } from "store/reducers/Ai/ai";
import { CheckmarkSVG, UploadAiSVG } from "components/SVG/SVG";
import ReactPlayer from "react-player";

interface Props {
  fileType: string;
  size: number;
  mandatory?: boolean;
}

const AiFileDrop = ({ fileType, size, mandatory }: Props) => {
  const uploadedFile = useAppSelector(getUploadedAiFileSelector);

  const allowedFileType =
    fileType === "video" ? "video/mp4" || "video/quicktime" : "application/pdf";

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(uploadAiFile(null));
  }, [dispatch]);

  const handleDrop = (acceptedFiles: File[]) => {
    dispatch(uploadAiFile(null));
    if (acceptedFiles[0].type === allowedFileType) {
      if (acceptedFiles[0].size < size * 1000000) {
        dispatch(uploadAiFile(acceptedFiles[0]));
      } else {
        toastError(
          `${acceptedFiles[0].name} exceeds the max size, please upload video less than ${size}MB`
        );
      }
    } else {
      toastError(
        `Invalid file: ${acceptedFiles[0].name}, please pass a ${fileType} file`
      );
    }
  };

  const onDropAccepted = useCallback(handleDrop, []);
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    onDrop: onDropAccepted,
  });

  return (
    <div className="w_full pointer">
      <div
        {...getRootProps()}
        className={`upload_wrapper dropzone ${
          isDragActive ? "dropzone--isActive border-blue-600" : ""
        } ${isDragAccept ? "dropzone--isAccept border-blue-600" : ""} ${
          isDragReject ? "dropzone--isReject border-red-600" : ""
        }`}
      >
        <input
          accept={`${fileType === "video" ? "video/*" : "application/pdf"}`}
          {...getInputProps()}
        />
        {!!uploadedFile ? (
          <>
            {fileType === "video" ? (
              <div className="dropped_ai_images">
                <ReactPlayer
                  url={URL.createObjectURL(uploadedFile)}
                  controls
                  width="200px"
                  height="333px"
                  style={{
                    overflow: "hidden",
                    borderRadius: "15px",
                    background: "black",
                  }}
                />
              </div>
            ) : (
              <div className="pdf_upload_checkmark">
                <p>{uploadedFile.name}</p>
                <CheckmarkSVG />
              </div>
            )}
          </>
        ) : (
          <div className="ai_dropzone_content">
            <UploadAiSVG />
            <div>
              <p className="">
                Select file to upload {!mandatory && "(optional)"}
              </p>
              <p className="text-sm text-gray-400">or drag and drop files</p>
              <div className="font-bold text-sm">
                <div>Maximum image size: 10 MB</div>
                <div>Format: jpg, png</div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AiFileDrop;
