import { createSlice, PayloadAction, createSelector } from "@reduxjs/toolkit";
import groupBy from "lodash/groupBy";
import orderBy from "lodash/orderBy";
import unionBy from "lodash/unionBy";
import keyBy from "lodash/keyBy";
import uniqBy from "lodash/uniqBy";
import { parseISO, format } from "date-fns";

import { createTypedSelector } from "store/utils";

import {
  getChatMessages,
  sendChatMessage,
  getChatRoomUsersMentions,
  sendChatAttachmentMessage,
  getChatMessagesWithUserMentions,
  getChatBotId,
  sendChatBotMessage,
  getChatBotMessages,
  getNewChatMessages,
} from "../../async-actions/Chat/chat";
import {
  ChatBotRespModel,
  MessageDataResponse,
  UserMentionItem,
} from "api/models/Chat/chat";
import { ChannelUser } from "api/models/Hive/hiveDetails";

export type UsersListForMentioning = {
  name: string;
  avatar: string;
  id: UserId;
};

export type UserMentionDictionary = Dictionary<UserMentionItem>;

export interface Message {
  isOwner: boolean;
  senderName: UserName;
  time: string;
  formattedTime: string;
  message: string;
  id: string;
  type: MessageType;
  attachmentUrl: string;
  thumbnailUrl: string;
  userMentions?: UserMentionDictionary;
  videoUrl: string;
}

export interface ChatBotMessageItem {
  isOwner: boolean;
  senderName: UserName;
  time: string;
  formattedTime: string;
  message: string;
  id: string;
  type: string;
  videoUrl: string;
}

export interface ChatItem {
  date: string;
  messages: Message[];
}

export interface ChatBotItem {
  date: string;
  messages: ChatBotMessageItem[];
}

interface ChatInitialState {
  chatList: MessageDataResponse[];
  isFetching: boolean;
  mentionsUsersList: ChannelUser[];
  isFetchingSendMessage: boolean;
  chatListWithMentioning: MessageDataResponse[];
  chatBotIsOpen: boolean;
  chatBotId: string;
  chatBotList: ChatBotRespModel[];
  chatPageNo: number;
  chatRoomId: string;
}

const initialState: ChatInitialState = {
  chatList: [],
  isFetching: false,
  mentionsUsersList: [],
  isFetchingSendMessage: false,
  chatListWithMentioning: [],
  chatBotIsOpen: false,
  chatBotId: "",
  chatBotList: [],
  chatPageNo: 0,
  chatRoomId: "",
};

const chatSlice = createSlice({
  name: "chat",
  initialState,
  reducers: {
    clearChatMessage: (state) => {
      state.chatList = initialState.chatList;
      state.chatListWithMentioning = initialState.chatListWithMentioning;
    },
    addNewMessage: (state, action: PayloadAction<MessageDataResponse>) => {
      state.chatList = unionBy(state.chatList, [action.payload], "_id");
    },
    addNewBotMessage: (state, action: PayloadAction<ChatBotRespModel>) => {
      state.chatBotList = unionBy(state.chatBotList, [action.payload], "_id");
    },
    setChatBotIsOpen: (state, action: PayloadAction<boolean>) => {
      state.chatBotIsOpen = action.payload;
    },
    clearChatListWithMentioning: (state) => {
      state.chatListWithMentioning = initialState.chatListWithMentioning;
    },
    setChatRoomId: (state, action: PayloadAction<string>) => {
      state.chatRoomId = action.payload;
    },
    setChatPageNo: (state, action: PayloadAction<number>) => {
      state.chatPageNo = action.payload;
    },
  },
  extraReducers: {
    /// getChatMessages
    [getChatMessages.pending.type]: (state) => {
      state.isFetching = true;
    },
    [getChatMessages.fulfilled.type]: (
      state,
      action: PayloadAction<MessageDataResponse[]>
    ) => {
      state.chatList = unionBy(state.chatList, action.payload, "_id");
      state.isFetching = false;
    },
    [getChatMessages.rejected.type]: (state) => {
      state.isFetching = false;
    },

    /// getNewChatMessages
    [getNewChatMessages.pending.type]: (state) => {
      state.isFetching = true;
    },
    [getNewChatMessages.fulfilled.type]: (
      state,
      action: PayloadAction<MessageDataResponse[]>
    ) => {
      state.chatList = [];
      state.chatList = unionBy(state.chatList, action.payload, "_id");
      state.isFetching = false;
    },
    [getNewChatMessages.rejected.type]: (state) => {
      state.isFetching = false;
    },

    /// getChatBotMessages
    [getChatBotMessages.pending.type]: (state) => {
      state.isFetching = true;
    },
    [getChatBotMessages.fulfilled.type]: (
      state,
      action: PayloadAction<ChatBotRespModel[]>
    ) => {
      state.chatBotList = action.payload;
      state.isFetching = false;
    },
    [getChatBotMessages.rejected.type]: (state) => {
      state.isFetching = false;
    },

    /// sendChatMessage
    [sendChatMessage.pending.type]: (state) => {
      state.isFetchingSendMessage = true;
    },
    [sendChatMessage.fulfilled.type]: (
      state,
      action: PayloadAction<MessageDataResponse>
    ) => {
      state.isFetchingSendMessage = false;
    },
    [sendChatMessage.rejected.type]: (state) => {
      state.isFetchingSendMessage = false;
    },

    /// sendChatBotMessage
    [sendChatBotMessage.pending.type]: (state) => {
      state.isFetchingSendMessage = true;
    },
    [sendChatBotMessage.fulfilled.type]: (
      state,
      action: PayloadAction<MessageDataResponse>
    ) => {
      state.isFetchingSendMessage = false;
    },
    [sendChatBotMessage.rejected.type]: (state) => {
      state.isFetchingSendMessage = false;
    },

    /// Get Chatbot ID
    [getChatBotId.pending.type]: (state) => {
      state.isFetching = true;
    },
    [getChatBotId.fulfilled.type]: (state, action: PayloadAction<string>) => {
      state.isFetching = false;
      state.chatBotId = action.payload;
    },
    [getChatBotId.rejected.type]: (state) => {
      state.isFetching = false;
    },

    // getChatRoomUsersMentions
    [getChatRoomUsersMentions.fulfilled.type]: (
      state,
      action: PayloadAction<ChannelUser[]>
    ) => {
      state.mentionsUsersList = uniqBy(action.payload, "userId");
    },

    //sendChatAttachmentMessage
    [sendChatAttachmentMessage.pending.type]: (state) => {
      state.isFetchingSendMessage = true;
    },
    [sendChatAttachmentMessage.fulfilled.type]: (state) => {
      state.isFetchingSendMessage = false;
    },
    [sendChatAttachmentMessage.rejected.type]: (state) => {
      state.isFetchingSendMessage = false;
    },

    //getChatMessagesWithUserMentions
    [getChatMessagesWithUserMentions.fulfilled.type]: (
      state,
      action: PayloadAction<MessageDataResponse[]>
    ) => {
      state.chatListWithMentioning = action.payload;
    },
  },
});

export const getIsFetchingSendMessageSelector = createTypedSelector(
  (state) => state.chat.isFetchingSendMessage
);

export const getChatPageNoSelector = createTypedSelector(
  (state) => state.chat.chatPageNo
);

export const getChatRoomIdSelector = createTypedSelector(
  (state) => state.chat.chatRoomId
);

export const getIsFetchingChatMessagesSelector = createTypedSelector<boolean>(
  (state) => state.chat.isFetching
);

export const getChatMessagesLenghtSelector = createTypedSelector<number>(
  (state) => state.chat.chatList.length ?? 0
);

export const getChatBotMessagesLenghtSelector = createTypedSelector<number>(
  (state) => state.chat.chatBotList.length ?? 0
);

export const getChatBotIdSelector = createTypedSelector(
  (state) => state.chat.chatBotId
);

export const getChatMessagesMentioningLenghtSelector =
  createTypedSelector<number>(
    (state) => state.chat.chatListWithMentioning.length ?? 0
  );

export const getChatListSelector = createTypedSelector<ChatItem[]>((state) => {
  const currentUserId = state.profile.userId;

  const grouped = groupBy(
    orderBy(state.chat.chatList, ["createdAt"], ["desc"]),
    (item) => {
      return format(parseISO(item.createdAt), "MMM d, yyyy");
    }
  );

  return Object.entries(grouped).reduce<ChatItem[]>((res, [key, values]) => {
    res.push({
      date: key,
      messages: orderBy(
        values.map<Message>((item) => ({
          time: item.createdAt,
          formattedTime: format(parseISO(item.createdAt), "HH:mm"),
          isOwner: currentUserId === item?.userId,
          message: item.message?.message || "",
          senderName: item?.userName || "",
          id: item._id,
          type: item?.type,
          attachmentUrl: item?.attachmentUrl,
          thumbnailUrl: item?.thumbnailUrl || "",
          userMentions: keyBy(item?.userMentions, "id") || {},
          videoUrl: item.videoUrl || "",
        })),
        ["time"],
        ["asc"]
      ),
    });
    return res;
  }, []);
});

export const getChatBotListSelector = createTypedSelector<ChatBotItem[]>(
  (state) => {
    const currentUserId = state.profile.userId;

    const grouped = groupBy(
      orderBy(state.chat.chatBotList, ["createdAt"], ["desc"]),
      (item) => {
        return format(parseISO(item.createdAt), "MMM d, yyyy");
      }
    );

    return Object.entries(grouped).reduce<ChatBotItem[]>(
      (res, [key, values]) => {
        res.push({
          date: key,
          messages: orderBy(
            values.map<ChatBotMessageItem>((item) => ({
              time: item.createdAt,
              formattedTime: format(parseISO(item.createdAt), "HH:mm"),
              isOwner: currentUserId === item?.userId,
              message: item.message || "",
              senderName: item?.userName || "",
              id: item._id,
              type: item.type,
              videoUrl: item.videoUrl || "",
            })),
            ["time"],
            ["asc"]
          ),
        });
        return res;
      },
      []
    );
  }
);

export const getChatListWithMentionSelector = createTypedSelector<ChatItem[]>(
  (state) => {
    const currentUserId = state.profile.userId;

    const grouped = groupBy(
      orderBy(state.chat.chatListWithMentioning, ["createdAt"], ["desc"]),
      (item) => {
        return format(parseISO(item.createdAt), "MMM d, yyyy");
      }
    );

    return Object.entries(grouped).reduce<ChatItem[]>((res, [key, values]) => {
      res.push({
        date: key,
        messages: orderBy(
          values.map<Message>((item) => ({
            time: item.createdAt,
            formattedTime: format(parseISO(item.createdAt), "HH:mm"),
            isOwner: currentUserId === item?.userId,
            message: item.message?.message || "",
            senderName: item?.userName || "",
            id: item._id,
            type: item?.type,
            attachmentUrl: item?.attachmentUrl,
            thumbnailUrl: item?.thumbnailUrl || "",
            userMentions: keyBy(item?.userMentions, "id"),
            videoUrl: item.videoUrl || "",
          })),
          ["time"],
          ["asc"]
        ),
      });
      return res;
    }, []);
  }
);

export const getMentionsUsersListS = createTypedSelector(
  (state) => state.chat.mentionsUsersList
);

export const getChatBotIsOpenSelector = createTypedSelector(
  (state) => state.chat.chatBotIsOpen
);

export const getMentionsUsersListSelector = createSelector(
  getMentionsUsersListS,
  (users) => {
    return users.map<UsersListForMentioning>((user) => ({
      name: user.userName || "not_name",
      avatar: user.profilePhoto || "",
      id: user.userId,
    }));
  }
);

export const {
  clearChatMessage,
  addNewMessage,
  clearChatListWithMentioning,
  setChatBotIsOpen,
  addNewBotMessage,
  setChatPageNo,
  setChatRoomId,
} = chatSlice.actions;

export default chatSlice.reducer;
