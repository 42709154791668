import { useAppDispatch, useAppSelector } from "hooks/redux";
import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import LineBreak from "components/LineBreak";
import {
  getPurchaseChannelListSelector,
  setCurrentChannelId,
} from "store/reducers/Analytics/analytics";
import LongText from "components/LongText/LongText";
import { PurchaseChannelModel } from "api/models/Analytics/metrics";
import { useNavigate } from "react-router-dom";

const ChannelListTable = () => {
  const channelList = useAppSelector(getPurchaseChannelListSelector);

  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const handleChannelClick = (row: PurchaseChannelModel) => {
    dispatch(setCurrentChannelId(row.channelId));
    navigate(`/analytics/channels/${row.channelId}`);
  };

  return (
    <>
      <LineBreak />
      {!!channelList && (
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>
                  {" "}
                  <p className="bold_heading_table">Channel Name</p>
                </TableCell>
                <TableCell align="center">
                  {" "}
                  <p className="bold_heading_table">Type</p>
                </TableCell>
                <TableCell align="center">
                  {" "}
                  <p className="bold_heading_table">Members</p>
                </TableCell>

                <TableCell align="center">
                  {" "}
                  <p className="bold_heading_table">Videos</p>
                </TableCell>
                <TableCell align="center">
                  {" "}
                  <p className="bold_heading_table">Details</p>
                </TableCell>
              </TableRow>
            </TableHead>
            {channelList.map((row, idx) => (
              <TableBody>
                <TableRow
                  className="table_cell_hover"
                  key={idx}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {<LongText title={row.channelName} cutoff={16} /> || "-"}
                  </TableCell>
                  <TableCell align="center" component="th" scope="row">
                    {<LongText title={row.channelTier} cutoff={16} /> || "-"}
                  </TableCell>
                  <TableCell align="center" component="th" scope="row">
                    {(
                      <LongText
                        title={row.totalMembers.toString()}
                        cutoff={16}
                      />
                    ) || "-"}
                  </TableCell>
                  <TableCell align="center" component="th" scope="row">
                    {(
                      <LongText
                        title={row.totalVideos.toString()}
                        cutoff={16}
                      />
                    ) || "-"}
                  </TableCell>
                  <TableCell
                    onClick={() => handleChannelClick(row)}
                    align="center"
                    component="th"
                    scope="row"
                  >
                    <p className="link">View</p>
                  </TableCell>
                </TableRow>
              </TableBody>
            ))}
          </Table>
        </TableContainer>
      )}
    </>
  );
};

export default ChannelListTable;
