import { createSlice } from "@reduxjs/toolkit";
import { navbar } from "mock/HiveDetails/navbar";
import * as Scroll from "react-scroll";
import { createTypedSelector } from "store/utils";

interface InitialState {
  id: number;
  user_type: string;
  component_type: string;
  component_code: string;
  component_name: string;
  component_icon: string;
  route: string;
}

const initialState: InitialState[] = navbar;

const navbarSlice = createSlice({
  name: "navbar",
  initialState,
  reducers: {},
});

export const getNavbarSelector = createTypedSelector((state) => state.navbar);

export const scrollToTopSection = () => {
  Scroll.scroller.scrollTo("top_section", {
    duration: 1000,
    delay: 50,
    smooth: "easeInOutQuart",
    offset: -10,
  });
};

export default navbarSlice.reducer;
