import React, { useEffect, useState } from "react";
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
} from "@stripe/react-stripe-js";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { profileUserIdSelector } from "store/reducers/Profile/profile";
import { chargeForPayment } from "store/async-actions/Channels/payment";
import { getCartListSelector } from "store/reducers/ChannelDetails/payments";
import {
  PaymentElement,
  LinkAuthenticationElement,
} from "@stripe/react-stripe-js";

interface Props {
  setOpenStripe: any;
}

const CheckoutForm = ({ setOpenStripe }: Props) => {
  const stripe = useStripe();
  const elements = useElements();

  const dispatch = useAppDispatch();
  const userId = useAppSelector(profileUserIdSelector);
  const cartListResp = useAppSelector(getCartListSelector);

  useEffect(() => {
    var card = elements?.create("card");
    card?.mount("#card-element");
  }, []);

  const [errorMessage, setErrorMessage] = useState("");

  const handleSubmit = async (event: { preventDefault: () => void }) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    stripe
      .createToken(elements.getElement("cardNumber")!)
      .then(function (result) {
        if (result.error) {
          setErrorMessage(result.error.message!);
        } else {
          cartListResp[0] &&
            dispatch(
              chargeForPayment({
                userId: userId,
                stripeToken: result.token.id,
                cartItems: [
                  {
                    cartId: cartListResp[0].id,
                    referenceId: cartListResp[0].cartItemReferenceId,
                  },
                ],
                customerId: userId,
                currency: null,
                countryCode: result.token.card?.country!,
              })
            );
          setOpenStripe(false);
        }
      });
  };

  return (
    <form onSubmit={handleSubmit} className="stripe_payment_container">
      <h2 className="stripe_header">Enter Card Details</h2>
      <div className="card_input_element">
        <CardNumberElement
          className="input_border input_padding full-width"
          options={{
            style: {
              base: {
                backgroundColor: "#fff",
                padding: "12px",
                fontSize: "16px",
                lineHeight: "40px",
              },
            },
          }}
        ></CardNumberElement>
        <h3 className="bold_xs_text">Enter card number*</h3>
      </div>
      <div className="card_input_element">
        <CardExpiryElement
          className="input_border input_padding full-width"
          options={{
            style: {
              base: {
                backgroundColor: "#fff",
                padding: "12px",
                fontSize: "16px",
                lineHeight: "40px",
              },
            },
          }}
        ></CardExpiryElement>
        <h3 className="bold_xs_text">Enter expiry date*</h3>
      </div>
      <div className="card_input_element">
        <CardCvcElement
          className="input_border input_padding full-width"
          options={{
            style: {
              base: {
                backgroundColor: "#fff",
                padding: "12px",
                fontSize: "16px",
                lineHeight: "40px",
              },
            },
          }}
        ></CardCvcElement>
        <h3 className="bold_xs_text">CVC*</h3>
      </div>
      {/* <PaymentElement id="payment-element" /> */}
      <div className="stripeBtns">
        <button disabled={!stripe} className="primaryBtn stripeBtn">
          Pay Securely
        </button>
        <button
          onClick={() => {
            setOpenStripe(false);
          }}
          disabled={!stripe}
          className="secondaryBtn stripeBtn"
        >
          Cancel
        </button>
      </div>
      {errorMessage && <div className="">{errorMessage}</div>}
    </form>
  );
};

export default CheckoutForm;
