import Dialog from "@mui/material/Dialog";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import IslandLayout from "components/IslandLayout";
import PageLayout from "components/PageLayout";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { stripePaymentChargedSelector } from "store/reducers/ChannelDetails/payments";
import { isMemberViewSelector } from "store/reducers/Profile/profile";
import PlanPayment from "./components/PlanPayment";
import BackButton from "components/BackButton/BackButton";
import LineBreak from "components/LineBreak/LineBreak";
import {
  getCurrentPlanSelector,
  getPlanDetailsSelector,
  getPlanPaymentChargedSelector,
  subscriptionsFetchingSelector,
} from "store/reducers/Subscriptions/subscriptions";
import PaymentLoader from "components/PaymentLoader/PaymentLoader";
import PaymentCompleted from "./PaymentCompleted";
import RazorPayPlan from "./components/RazorPayPlan";
import StripeChangePlan from "./components/StripeChangePlan";
import RazorChangePlan from "./components/RazorChangePlan";

const PurchasePlanContainer = () => {
  const dispatch = useAppDispatch();

  const [openStripe, setOpenStripe] = useState(false);

  function loadScript(src: string) {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }

  const currentPlan = useAppSelector(getPlanDetailsSelector);

  //   async function displayRazorPay() {
  //     const res = await loadScript(
  //       "https://checkout.razorpay.com/v1/checkout.js"
  //     );
  //     if (!res) {
  //       alert("Razorpay SDK failed to load. Are you online?");
  //       return;
  //     }
  //     const options = {
  //       // key: "rzp_test_j2sDfnlDqZgcNo",
  //       key: "rzp_live_wHpMkB5GN12X7J",
  //       amount: createOrderResp!.orderAmount.toString(),
  //       currency: createOrderResp!.currency,
  //       name: hiveDetails?.communityName,
  //       description: "Buy Channel",
  //       order_id: createOrderResp!.orderId,
  //       handler: function (response: {
  //         razorpay_payment_id: string;
  //         razorpay_order_id: string;
  //         razorpay_signature: string;
  //       }) {
  //         dispatch(
  //           razorPayVerifyPayment({
  //             paymentId: response.razorpay_payment_id,
  //             orderId: response.razorpay_order_id,
  //             signature: response.razorpay_signature,
  //           })
  //         );
  //       },
  //       theme: {
  //         color: "#61dafb",
  //       },
  //     };

  //     const paymentObject = new (window as any).Razorpay(options);
  //     paymentObject.on(
  //       "payment.failed",
  //       function (response: {
  //         error: {
  //           code: any;
  //           description: any;
  //           source: any;
  //           step: any;
  //           reason: any;
  //           metadata: { order_id: any; payment_id: any };
  //         };
  //       }) {
  //         // alert(response.error.code);
  //         // alert(response.error.description);
  //         // alert(response.error.source);
  //         // alert(response.error.step);
  //         alert(response.error.reason);
  //         // alert(response.error.metadata.order_id);
  //         // alert(response.error.metadata.payment_id);
  //       }
  //     );
  //     paymentObject.open();
  //   }

  const handleCheckout = () => {
    if (localStorage.getItem("countryCode") === "IN") {
      //   displayRazorPay();
    } else {
      setOpenStripe(true);
    }
  };

  const stripePromise = loadStripe(
    "pk_live_51IxUMeClzMdxwhfoBWrSrixtZYeyTSuKXhUwDB1bWoNxs5G9IYvxKAKqyBRwOkNrQEw4xkslBYW7JaZHKqZcRDJH00UakEmHVL"
    // "pk_test_51IxUMeClzMdxwhfo9SLZCdor2BSdjvfIfDGcCXgHobTkLTutZD0GaCDYNR21b46mFTOIouIS28vqudkCh8j8bVXn000qz0Lk6t"
  );

  const paymentCharged = useAppSelector(getPlanPaymentChargedSelector);
  const navigate = useNavigate();
  const isMemberView = useAppSelector(isMemberViewSelector);

  //   useEffect(() => {
  //     if (paymentCharged) {
  //       navigate("/home");
  //       dispatch(setPaymentCharged(false));
  //       dispatch(
  //         getChannelList({
  //           hiveId: hiveDetails?.communityId!,
  //           isMemberView: isMemberView,
  //         })
  //       );
  //       alert("Payment Successful");
  //     }
  //   }, [paymentCharged]);

  const isLoading = useAppSelector(subscriptionsFetchingSelector);

  const countryCode = localStorage.getItem("countryCode");

  const activePlan = useAppSelector(getCurrentPlanSelector);

  return (
    // <PageLayout sideMenu={true}>
    <>
      <IslandLayout>
        {paymentCharged ? (
          <PaymentCompleted />
        ) : (
          <div className="buy_plan_container">
            <div className="plan_deets_wrapper">
              <BackButton to="/plans" />
              <LineBreak />
              <p className="fw-1">{currentPlan?.planName}</p>
              <h1>${currentPlan?.monthlyAmount} /mo</h1>
              <LineBreak />
              <div className="plan_content_flex_between">
                <p className="fw-1">{currentPlan?.planName} Plan</p>
                <p>${currentPlan?.monthlyAmount.toFixed(2)}</p>
              </div>
              <p className="text-sm">billed monthly</p>
              <LineBreak />
              <div className="plan_content_flex_between">
                <p className="fw-1">Subtotal</p>
                <p>${currentPlan?.monthlyAmount.toFixed(2)}</p>
              </div>
              <LineBreak />
              <hr></hr>
              <LineBreak />
              <div className="plan_content_flex_between">
                <p className="fw-1">Discount</p>
                <p>- $0.00</p>
              </div>
              <LineBreak />
              <hr></hr>
              <LineBreak />{" "}
              <div className="plan_content_flex_between">
                <p className="fw-1">Total Due</p>
                <p>${currentPlan?.monthlyAmount.toFixed(2)}</p>
              </div>
            </div>
            <div className="plan_payment_wrapper">
              {countryCode === "IN" ? (
                <>
                  {!!activePlan && activePlan?.monthlyAmount > 0 ? (
                    <div className="plan_razorpay">
                      <RazorChangePlan />
                    </div>
                  ) : (
                    <div className="plan_razorpay">
                      <RazorPayPlan />
                    </div>
                  )}
                </>
              ) : (
                <>
                  {!!activePlan && activePlan?.monthlyAmount > 0 ? (
                    <div className="">
                      <StripeChangePlan />
                    </div>
                  ) : (
                    <Elements stripe={stripePromise}>
                      <PlanPayment setOpenStripe={setOpenStripe} />
                    </Elements>
                  )}
                </>
              )}
            </div>
          </div>
        )}
      </IslandLayout>
      {isLoading && <PaymentLoader isLoading={isLoading} />}
    </>
    // </PageLayout>
  );
};

export default PurchasePlanContainer;
