import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "api";
import {
  AttendeeFormDetails,
  AttendeeFormDetailsReq,
  EventPromoReq,
  EventsContentUploadModel,
  EventsEditRequestModel,
  EventsRequestModel,
} from "api/models/Hive/events";
import { RootState } from "store";
import { populateFormData, uploadToS3 } from "./hivePage";
import { setVideoFile } from "store/reducers/Videos/videos";
import { toastSuccess } from "hooks/useToastify";
import { RazorPaySubVerifyReq } from "api/models/Subscriptions/subscriptions";
import { UploadContentItem } from "api/models/Videos/uploadContent";
import { PresignedData } from "api/models/Hive/introVideo";

export const createEvent = createAsyncThunk(
  "events/createEvent",
  async (body: EventsRequestModel, { rejectWithValue }) => {
    try {
      const { data } = await api.events.createEvent(body);
      return data.data;
    } catch (error) {
      return rejectWithValue("Something went wrong");
    }
  }
);

export const editEvent = createAsyncThunk(
  "events/editEvent",
  async (body: EventsEditRequestModel, { rejectWithValue }) => {
    try {
      const { data } = await api.events.editEvent(body);
      toastSuccess("Edited your event successfully!");
      return data.data;
    } catch (error) {
      return rejectWithValue("Something went wrong");
    }
  }
);

export const getEventsList = createAsyncThunk(
  "events/getEventsList",
  async (body: { organizationUuid: string }, { rejectWithValue }) => {
    try {
      const { data } = await api.events.getEventsList(body);
      return data.data;
    } catch (error) {
      return rejectWithValue("Something went wrong");
    }
  }
);

export const verifyEventPromo = createAsyncThunk(
  "events/verifyEventPromo",
  async (body: EventPromoReq, { rejectWithValue }) => {
    try {
      const { data } = await api.events.verifyEventPromo(body);
      return data.data;
    } catch (error) {
      return rejectWithValue("Something went wrong");
    }
  }
);

export const getEventsPeopleList = createAsyncThunk(
  "events/getEventsPeopleList",
  async (
    body: {
      eventIdentifier: string;
      registerType: number;
      searchInput?: string;
    },
    { rejectWithValue }
  ) => {
    try {
      const { data } = await api.events.getEventPeopleList(body);
      return data.data;
    } catch (error) {
      return rejectWithValue("Something went wrong");
    }
  }
);

export const getEventDetails = createAsyncThunk(
  "events/getEventDetails",
  async (body: { eventIdentifier: string }, { rejectWithValue }) => {
    try {
      const { data } = await api.events.getEventDetails(body);
      return data.data;
    } catch (error) {
      return rejectWithValue("Something went wrong");
    }
  }
);

export const attendeeQrRegistration = createAsyncThunk(
  "events/attendeeQrRegistration",
  async (
    body: { eventUuid: string; organizationUuid: string; profileId: string },
    { rejectWithValue }
  ) => {
    try {
      const { data } = await api.events.attendeeQrRegistration(body);
      return data;
    } catch (error) {
      return rejectWithValue("Something went wrong");
    }
  }
);

export const markAttended = createAsyncThunk(
  "events/markAttended",
  async (
    body: { eventUuid: string; organizationUuid: string; profileId: string },
    { rejectWithValue, dispatch, getState }
  ) => {
    try {
      const { data } = await api.events.markAttended(body);

      const { events } = getState() as RootState;

      dispatch(
        getEventsPeopleList({
          eventIdentifier: events.currentEvent?.eventIdentifier || "",
          registerType: 1,
        })
      );
      return data;
    } catch (error) {
      return rejectWithValue("Something went wrong");
    }
  }
);

export const getEventAttendeesList = createAsyncThunk(
  "events/getEventAttendeesList",
  async (body: { eventIdentifier: string }, { rejectWithValue }) => {
    try {
      const { data } = await api.events.getEventAttendeesList(body);
      return data.data;
    } catch (error) {
      return rejectWithValue("Something went wrong");
    }
  }
);

export const getEventOrgOwner = createAsyncThunk(
  "events/getEventOrgOwner",
  async (body: { organizationUuid: string }, { rejectWithValue }) => {
    try {
      const { data } = await api.events.getEventOrgOwner(body);
      return data.data;
    } catch (error) {
      return rejectWithValue("Something went wrong");
    }
  }
);

export const registerEvent = createAsyncThunk(
  "events/registerEvent",
  async (
    body: { eventUuid: string; eventIdentifier: string; registerType: number },
    { rejectWithValue, dispatch }
  ) => {
    try {
      const { data } = await api.events.registerEvent({
        eventUuid: body.eventUuid,
        registerType: body.registerType,
      });

      dispatch(getEventDetails({ eventIdentifier: body.eventIdentifier }));

      return data.data;
    } catch (error) {
      return rejectWithValue("Something went wrong");
    }
  }
);

export const registerEventInBg = createAsyncThunk(
  "events/registerEvent",
  async (
    body: {
      eventUuid: string;
      eventIdentifier: string;
      registerType: number;
      attended?: boolean;
    },
    { rejectWithValue }
  ) => {
    try {
      const { data } = await api.events.registerEvent({
        eventUuid: body.eventUuid,
        registerType: body.registerType,
      });

      return data.data;
    } catch (error) {
      return rejectWithValue("Something went wrong");
    }
  }
);

export const registerFormEvent = createAsyncThunk(
  "events/registerFormEvent",
  async (
    body: {
      eventUuid: string;
      eventIdentifier: string;
      registerType: number;
      formDetails: AttendeeFormDetailsReq[];
      attended?: boolean;
    },
    { rejectWithValue, dispatch }
  ) => {
    try {
      const { data } = await api.events.registerEvent({
        eventUuid: body.eventUuid,
        registerType: body.registerType,
        formDetails: body.formDetails,
      });

      dispatch(getEventDetails({ eventIdentifier: body.eventIdentifier }));

      return data.data;
    } catch (error) {
      return rejectWithValue("Something went wrong");
    }
  }
);

export const deleteEvent = createAsyncThunk(
  "events/deleteEvent",
  async (
    body: { eventUuid: string; hiveUuid: string },
    { rejectWithValue, dispatch }
  ) => {
    try {
      const { data } = await api.events.deleteEvent({
        eventUuid: body.eventUuid,
      });

      dispatch(getEventsList({ organizationUuid: body.hiveUuid }));
      return data.data;
    } catch (error) {
      return rejectWithValue("Something went wrong");
    }
  }
);

export const uploadGalleryEvent = createAsyncThunk(
  "events/uploadGalleryEvent",
  async (
    body: { eventUuid: string; galleryUrl: string; eventThumbnail: string },
    { rejectWithValue, dispatch }
  ) => {
    try {
      const { data } = await api.events.uploadGalleryEvent(body);
      dispatch(getGalleryEvent({ eventUuid: body.eventUuid }));

      return data.data;
    } catch (error) {
      return rejectWithValue("Something went wrong");
    }
  }
);

export const getGalleryEvent = createAsyncThunk(
  "events/getGalleryEvent",
  async (body: { eventUuid: string }, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await api.events.getGalleryEvent(body);

      return data.data;
    } catch (error) {
      return rejectWithValue("Something went wrong");
    }
  }
);

export const removeGalleryEvent = createAsyncThunk(
  "events/removeGalleryEvent",
  async (
    body: { eventUuid: string; galleryId: number },
    { rejectWithValue, dispatch }
  ) => {
    try {
      const { data } = await api.events.removeGalleryEvent(body);
      dispatch(getGalleryEvent({ eventUuid: body.eventUuid }));
      return data.data;
    } catch (error) {
      return rejectWithValue("Something went wrong");
    }
  }
);

export const stripeChargeEvent = createAsyncThunk(
  "events/stripeChargeEvent",
  async (
    body: {
      eventUuid: string;
      token: string;
      formDetails: AttendeeFormDetailsReq[] | null;
      promoCode?: string;
    },
    { rejectWithValue, dispatch }
  ) => {
    try {
      const { data } = await api.events.stripeChargeEvent(body);

      return data;
    } catch (error) {
      return rejectWithValue("Something went wrong");
    }
  }
);

export const razorPayChargeEvent = createAsyncThunk(
  "events/razorPayChargeEvent",
  async (
    body: {
      eventUuid: string;
      promoCode: string;
    },
    { rejectWithValue, dispatch }
  ) => {
    try {
      const { data } = await api.events.razorPayChargeEvent(body);

      return data.data;
    } catch (error) {
      return rejectWithValue("Something went wrong");
    }
  }
);

export const getPresignedUrlWithType = async (body: {
  type: string;
  communityId: number;
  file: File;
  setUploadProgress: any;
  fileType: string;
}) => {
  try {
    const { data } = await api.hivePage.getPresignedUrlWithType({
      type: body.type,
      communityId: body.communityId,
      setUploadProgress: body.setUploadProgress,
      fileType: body.fileType,
    });
    const formData = new FormData();
    populateFormDataWithType(formData, data.data.preSigned.data, body.file);

    return data.data;
  } catch (error) {
    return undefined;
  }
};

export const populateFormDataWithType = (
  formData: FormData,
  contentUploadResp: UploadContentItem,
  file: File
) => {
  formData.append(
    "x-amz-meta-userid",
    contentUploadResp?.fields["x-amz-meta-userid"]!
  );
  formData.append("Content-Type", file.type);
  formData.append("key", contentUploadResp?.fields.key!);
  formData.append("bucket", contentUploadResp?.fields.bucket!);
  formData.append(
    "X-Amz-Algorithm",
    contentUploadResp?.fields["X-Amz-Algorithm"]!
  );
  formData.append(
    "X-Amz-Credential",
    contentUploadResp?.fields["X-Amz-Credential"]!
  );
  formData.append("X-Amz-Date", contentUploadResp?.fields["X-Amz-Date"]!);
  formData.append(
    "X-Amz-Security-Token",
    contentUploadResp?.fields["X-Amz-Security-Token"]!
  );
  formData.append(
    "X-Amz-Signature",
    contentUploadResp?.fields["X-Amz-Signature"]!
  );
  formData.append("Policy", contentUploadResp?.fields.Policy!);
  formData.append("file", file!);
};

export const razorPayVerifyEvent = createAsyncThunk(
  "events/razorPayVerifyEvent",
  async (
    body: {
      orderId: string;
      paymentId: string;
      signature: string;
      formDetails: AttendeeFormDetailsReq[] | null;
    },
    { rejectWithValue, dispatch }
  ) => {
    try {
      const { data } = await api.events.razorPayVerifyEvent(body);

      return data.data;
    } catch (error) {
      return rejectWithValue("Something went wrong");
    }
  }
);

export const uploadEventVideo = createAsyncThunk(
  "events/uploadEventVideo",
  async (
    body: EventsContentUploadModel,
    { rejectWithValue, dispatch, getState }
  ) => {
    try {
      const { data } = await api.events.uploadEventVideo(body);

      const { videos, events } = getState() as RootState;

      const formData = new FormData();
      populateFormData(formData, data.data.body.data, videos.file!);
      dispatch(
        uploadToS3({ url: data.data.body.data.url, formData: formData })
      );
      dispatch(setVideoFile(null));

      dispatch(
        getEventDetails({
          eventIdentifier: events.currentEvent?.eventIdentifier || "",
        })
      );

      return data.data;
    } catch (error) {
      return rejectWithValue("Something went wrong");
    }
  }
);
