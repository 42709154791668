import React, { useEffect, useState } from "react";
import PageLayout from "components/PageLayout/PageLayout";
import IslandLayout from "components/IslandLayout/IslandLayout";
import LineBreak from "components/LineBreak/LineBreak";
import { TextField } from "@mui/material";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import {
  editCampaign,
  getCurrentCampaign,
} from "store/async-actions/Campaign/campaign";
import { getCampaignUuidSelector } from "store/reducers/Campaign/campaign";
import { useNavigate } from "react-router-dom";
import { BackBtnSVG } from "components/SVG/SVG";

interface Props {
  handleBack: any;
  handleNext: any;
}

const CreateSMSInfo = ({ handleBack, handleNext }: Props) => {
  const campaignUuid = useAppSelector(getCampaignUuidSelector);

  // useEffect(() => {
  //   if (!!!campaignUuid) {
  //     navigate("/campaigns");
  //   }
  // }, []);

  const [message, setMessage] = useState("");
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const handleEdit = () => {
    dispatch(
      editCampaign({
        campaignName: "",
        campaignUuid: campaignUuid,
        smsMessage: message,
        campaignType: "SMS",
      })
    );
    handleNext();
  };

  useEffect(() => {
    dispatch(
      getCurrentCampaign({
        campaignUuid: campaignUuid,
      })
    );
  }, [campaignUuid]);

  return (
    // <PageLayout sideMenu={true}>
    //   <IslandLayout>
    <div className="">
      <div className="backBtn_spacing">
        <div className="go_back pointer" onClick={handleBack}>
          <BackBtnSVG />
          <p>Back</p>
        </div>
      </div>
      <h2>Customize your campaign</h2>
      <LineBreak />
      <div>
        <TextField
          multiline={true}
          minRows={4}
          placeholder="Enter your message Here"
          fullWidth
          variant="outlined"
          label="Message"
          value={message}
          onChange={(e) => setMessage(e.target.value.slice(0, 300))}
        />
      </div>
      <LineBreak />
      <div className="campaign_nextBtn_container">
        {message.length > 0 ? (
          <div onClick={handleEdit} className="primaryBtn campaign_nextBtn">
            Next
          </div>
        ) : (
          <div className="campaign_nextBtn disabledBtn">Next</div>
        )}
      </div>
    </div>
    //   </IslandLayout>
    // </PageLayout>
  );
};

export default CreateSMSInfo;
