import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { createTypedSelector } from "store/utils";
import { FeedItem } from "api/models/Feed/Feed";
import {
  createForm,
  getFormDetails,
  getFormResponses,
  getForms,
  submitForm,
} from "store/async-actions/Forms/forms";
import { FormModel, FormResponseModel } from "api/models/Forms/forms";
import { AttendeeFormDetailsReq } from "api/models/Hive/events";
import { getGeoInfoJson } from "../Profile/profile";

const initialState: {
  isFetching: boolean;
  currentForm: FormModel | undefined;
  formsList: FormModel[];
  reducedFillForm: AttendeeFormDetailsReq[];
  formResponses: FormResponseModel[];
} = {
  isFetching: false,
  currentForm: undefined,
  formsList: [],
  reducedFillForm: [],
  formResponses: [],
};

const formsSlice = createSlice({
  name: "forms",
  initialState,
  reducers: {
    setCurrentForm(state, action: PayloadAction<FormModel>) {
      state.currentForm = action.payload;
    },
  },
  extraReducers: {
    [createForm.pending.type]: (state) => {
      state.isFetching = true;
    },
    [createForm.fulfilled.type]: (state, action: PayloadAction<FormModel>) => {
      state.currentForm = action.payload;
      state.isFetching = false;
    },
    [createForm.rejected.type]: (state) => {
      state.isFetching = false;
    },

    //GET ALL FORMS
    [getForms.pending.type]: (state) => {
      state.isFetching = true;
    },
    [getForms.fulfilled.type]: (state, action: PayloadAction<FormModel[]>) => {
      state.formsList = action.payload;
      state.isFetching = false;
    },
    [getForms.rejected.type]: (state) => {
      state.isFetching = false;
    },

    //GET FORM RESPONSES
    [getFormResponses.pending.type]: (state) => {
      state.isFetching = true;
    },
    [getFormResponses.fulfilled.type]: (
      state,
      action: PayloadAction<FormResponseModel[]>
    ) => {
      state.formResponses = action.payload;
      state.isFetching = false;
    },
    [getFormResponses.rejected.type]: (state) => {
      state.isFetching = false;
    },

    //GET Form Details
    [getFormDetails.pending.type]: (state) => {
      state.isFetching = true;
    },
    [getFormDetails.fulfilled.type]: (
      state,
      action: PayloadAction<FormModel>
    ) => {
      getGeoInfoJson();
      state.currentForm = action.payload;
      state.reducedFillForm = [];
      action.payload.formList.map((data, idx) => {
        state.reducedFillForm.push({
          fieldName: data.fieldName,
          fieldValue: "",
          type: data.type,
          options: data.options,
        });
      });
      state.isFetching = false;
    },
    [getFormDetails.rejected.type]: (state) => {
      state.isFetching = false;
    },
  },
});

export const formsFetchingSelector = createTypedSelector(
  (state) => state.forms.isFetching
);

export const getCurrentFormSelector = createTypedSelector(
  (state) => state.forms.currentForm
);

export const getFormsListSelector = createTypedSelector(
  (state) => state.forms.formsList
);

export const getFormsResponsesListSelector = createTypedSelector(
  (state) => state.forms.formResponses
);

export const getReducedFillFormSelector = createTypedSelector(
  (state) => state.forms.reducedFillForm
);

export const { setCurrentForm } = formsSlice.actions;

export default formsSlice.reducer;
