import { CircularProgress } from "@mui/material";
import FeedItem from "components/FeedItem";
import IslandLayout from "components/IslandLayout";
import LineBreak from "components/LineBreak";
import PageLayout from "components/PageLayout";
import { SadBigSVG, SadSVG } from "components/SVG/SVG";
import FeedLoader from "components/common/FeedLoader/FeedLoader";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { useScrollPagination } from "hooks/useScrollPagination";
import Lottie from "lottie-react";
import ComingSoon from "pages/ComingSoon";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getFeedVideos } from "store/async-actions/Feed/feedActions";
import { getHiveActivities } from "store/async-actions/Hive/hivePage";
import {
  feedFetchingSelector,
  feedVideosSelector,
  setFeedIsFetching,
  totalFeedPagesSelector,
} from "store/reducers/Feed/feed";
import { getHiveSelector } from "store/reducers/HiveDetails/hiveDetails";

const FeedPage = () => {
  const [reachedBottom, setReachedBottom] = useState(false);
  const [apiCallInProgress, setApiCallInProgress] = useState(false);

  const hiveDetails = useAppSelector(getHiveSelector);

  const handleScroll = () => {
    if (reachedBottom || apiCallInProgress) {
      return;
    } else {
      setReachedBottom(true);
      setPageNo((prevState) => prevState + 1);
      setTimeout(() => {
        setReachedBottom(false);
      }, 3000);
    }
  };

  const feedVideos = useAppSelector(feedVideosSelector);
  const isFetching = useAppSelector(feedFetchingSelector);
  const totalPages = useAppSelector(totalFeedPagesSelector);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setFeedIsFetching(false));
  }, [feedVideos]);

  const scrollAncholRef = useScrollPagination(
    handleScroll,
    !!feedVideos.length
  );

  const [pageNo, setPageNo] = useState(1);

  useEffect(() => {
    localStorage.setItem("path", "/feed");

    if (apiCallInProgress) {
      return;
    }
    setApiCallInProgress(true);
    dispatch(
      getFeedVideos({
        organizationUuid: hiveDetails?.communityUuid!,
        pageNo: pageNo,
        contentLimit: 15,
        searchQuery: null,
      })
    ).then(() => {
      setApiCallInProgress(false);
    });
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hiveDetails, pageNo]);

  const navigate = useNavigate();

  useEffect(() => {
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      navigate(`/mobile?login=${localStorage.getItem("@jwtToken")}`);
    } else {
      navigate("/feed");
    }
  }, [navigator.userAgent]);

  return (
    <>
      {feedVideos ? (
        <PageLayout sideMenu={true} rightMenu={true}>
          <IslandLayout>
            <>
              {feedVideos.length > 0 && (
                <>
                  {" "}
                  {hiveDetails &&
                    feedVideos &&
                    feedVideos.length > 0 &&
                    feedVideos?.map((feed, index) => {
                      return (
                        <div key={index}>
                          <FeedItem feedItem={feed} />
                          {index < feedVideos.length - 1 && (
                            <hr className="padding"></hr>
                          )}

                          <span
                            ref={scrollAncholRef}
                            style={{
                              width: "100%",
                              minHeight: "1px",
                              marginBottom: "-1px",
                              opacity: 0,
                            }}
                          />
                        </div>
                      );
                    })}
                </>
              )}

              {!isFetching && feedVideos.length < 1 && (
                <div>
                  <div className="coming_soon text-center">
                    <SadBigSVG />
                    <LineBreak />
                    <h2>
                      Whoops! It looks like our videos took a day off. Care to
                      fill in the spot?{" "}
                    </h2>
                  </div>
                  <h4 className="coming_soon_content"></h4>
                  <LineBreak />
                  <div className="flex justify-center">
                    <div
                      className="primaryBtn"
                      onClick={() => navigate("/home")}
                    >
                      Go To Home
                    </div>
                  </div>
                </div>
              )}
              <div className="load_more_container">
                {isFetching === true && feedVideos.length < 1 && <FeedLoader />}
              </div>
            </>
          </IslandLayout>
        </PageLayout>
      ) : (
        <ComingSoon />
      )}
    </>
  );
};

export default FeedPage;
