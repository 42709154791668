import { useCallback, useRef } from "react";
import { AxiosProgressEvent } from "axios";

import { useAppDispatch, useAppSelector } from "hooks/redux";
import NavigationSections from "./components/NavigationSections";
import { getChatRoomNameSelector } from "store/reducers/Chat/chatrooms";
// import {
//   getProfileUserIdSelector,
//   getProfileUserNameSelector,
// } from "store/reducers/profile";
import {
  sendChatMessage,
  sendChatAttachmentMessage,
  sendVoiceMessage,
  sendChatBotMessage,
} from "store/async-actions/Chat/chat";
import { useSearchMe } from "./useSearchMe";
import {
  getChatBotIdSelector,
  getIsFetchingSendMessageSelector,
} from "store/reducers/Chat/chat";
import {
  getProfileUsernameSelector,
  profileUserIdSelector,
} from "store/reducers/Profile/profile";
import { useChatBotMessages } from "./useChatBotMessages";
import ChatBotCon from "components/ChatBotContext/context";
import { getHiveUuid } from "store/reducers/HiveDetails/hiveDetails";

type AvailableFormDataKeys =
  | "chatRoomId"
  | "message"
  | "userName"
  | "organizationUuid"
  | "file"
  | "type"
  | "mentionUsersIds";
interface TypedFormData extends FormData {
  append(
    name: AvailableFormDataKeys,
    value: string | Blob,
    fileName?: string
  ): void;
}

const ChatBotRoom = () => {
  const dispatch = useAppDispatch();
  // const { roomId = "" } = useParams<Params<"roomId">>();
  const roomId = useAppSelector(getChatBotIdSelector);
  const roomName = useAppSelector(getChatRoomNameSelector);
  const userId = useAppSelector(profileUserIdSelector);
  const userName = useAppSelector(getProfileUsernameSelector);
  const organizationUuid = useAppSelector(getHiveUuid);
  const isLoadingAttachment = useAppSelector(getIsFetchingSendMessageSelector);

  const { loadMoreMessageList, chatList, isLoading } =
    useChatBotMessages(roomId);
  // const { usersList } = useMentioningUsersList(roomId);
  const {
    isShowSearchControls,
    closeSearchControls,
    chatListWithMention,
    detectSearchSymbol,
    chatListWithMentionLength,
  } = useSearchMe(roomId, userId);

  const chatContainerRef = useRef<HTMLDivElement>(null);

  const handleSendMessage = useCallback(
    async ({
      content = "",
      file,
      type = "attachment",
      cb,
      abortSignal,
      mentionUsersIds = [],
    }: SendMessageParams<AxiosProgressEvent, AbortSignal>) => {
      if (roomId) {
        if (file) {
          const formData = new FormData() as TypedFormData;
          formData.append("chatRoomId", roomId);
          formData.append("message", content);
          formData.append("userName", userName);
          formData.append("organizationUuid", organizationUuid);
          formData.append("file", file as File);
          if (type === "voice") {
            formData.append("type", "voice");
            dispatch(sendVoiceMessage(formData));
          }
          if (type === "attachment") {
            formData.append("type", "attachment");
            formData.append("mentionUsersIds", JSON.stringify(mentionUsersIds));
            await dispatch(
              sendChatAttachmentMessage({ body: formData, cb, abortSignal })
            )
              .unwrap()
              .catch((error) => {
                throw error;
              });
          }
        } else {
          dispatch(
            sendChatBotMessage({
              chatRoomId: roomId,
              message: content,
              type: "text",
              userId,
              userName,
              organizationUuid,
              sessionId: localStorage.getItem("chatSessionId") || "",
            })
          );
        }
      }
      // if (chatContainerRef.current) {
      //   chatContainerRef.current.scrollTop =
      //     chatContainerRef.current.scrollHeight;
      // }
    },
    [dispatch, organizationUuid, roomId, userId, userName]
  );

  // useEffect(() => {
  //   // Scroll to the bottom of the chat container on initial load and when chatList changes
  //   if (chatContainerRef.current) {
  //     chatContainerRef.current.scrollTop = 1000;
  //   }
  // }, [chatList]);

  return (
    <div className="" ref={chatContainerRef}>
      {/* <IslandLayout> */}
      <div className="chat_room_container">
        <NavigationSections roomName={roomName} />
        {/* <PageLayout sideMenu={false}> */}
        {/* <PageLayout className="chat_room_page"> */}
        <ChatBotCon
          handlePagginationUpdate={loadMoreMessageList}
          sendMessage={handleSendMessage}
          loading={isLoading}
          chatList={
            isShowSearchControls && chatListWithMention.length
              ? chatListWithMention
              : chatList
          }
          // usersList={usersList}
          loadingAttachment={isLoadingAttachment}
          isShowSearchControls={isShowSearchControls}
          detectSearchSymbol={detectSearchSymbol}
          closeSearchControls={closeSearchControls}
          searchMentionMessagesCount={chatListWithMentionLength}
        />
      </div>
      {/* </PageLayout> */}
      {/* </IslandLayout> */}
    </div>
  );
};

export default ChatBotRoom;
