import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { createTypedSelector } from "store/utils";
import { FeedItem } from "api/models/Feed/Feed";
import { getFeedVideos } from "store/async-actions/Feed/feedActions";
import {
  createCampaign,
  editCampaign,
  getCampaignEmails,
  getCampaignNumbers,
  getCampaignsList,
  getCurrentCampaign,
  getEmailCampaignData,
  getEmailImagePresignedUrl,
  getLinkedCampaigns,
  uploadCampaignResources,
} from "store/async-actions/Campaign/campaign";
import {
  CampaignEmailListModel,
  CampaignModel,
  CampaignNumbersModel,
  EditSMSCampaignReq,
  LinkedServiceModel,
  UploadCampaignResourceModel,
} from "api/models/Campaign/campaign";
import { ResourcePreSignedModel } from "api/models/Hive/introVideo";

const initialState: {
  isFetching: boolean;
  campaignUuid: string;
  campaignsList: CampaignModel[];
  currentCampaign: CampaignModel | undefined;
  campaignNumbers: string[];
  campaignEmails: string[];
  campaignName: string;
  campaignType: string;
  campaignEmailData: EditSMSCampaignReq | undefined;
  linkedCampaigns: LinkedServiceModel[];
  emailBannerUrl: string;
} = {
  isFetching: false,
  campaignUuid: "",
  campaignsList: [],
  currentCampaign: undefined,
  campaignNumbers: [],
  campaignEmails: [],
  campaignName: "",
  campaignType: "sms",
  campaignEmailData: undefined,
  linkedCampaigns: [],
  emailBannerUrl: "",
};

const campaignSlice = createSlice({
  name: "campaign",
  initialState,
  reducers: {
    setCampaignUuid(state, action: PayloadAction<string>) {
      state.campaignUuid = action.payload;
    },
    setCampaignName(state, action: PayloadAction<string>) {
      state.campaignName = action.payload;
    },
    setCampaignType(state, action: PayloadAction<string>) {
      state.campaignType = action.payload;
    },
    resetCampaignDetails(state) {
      state.campaignName = "";
      state.campaignType = "sms";
    },
  },
  extraReducers: {
    //Create Campaign
    [createCampaign.pending.type]: (state) => {
      state.isFetching = true;
    },
    [createCampaign.fulfilled.type]: (state, action: PayloadAction<string>) => {
      state.campaignUuid = action.payload;
      state.isFetching = false;
    },
    [createCampaign.rejected.type]: (state) => {
      state.isFetching = false;
    },

    //Get Campaigns List
    [getCampaignsList.pending.type]: (state) => {
      state.isFetching = true;
    },
    [getCampaignsList.fulfilled.type]: (
      state,
      action: PayloadAction<CampaignModel[]>
    ) => {
      state.campaignsList = action.payload;
      state.isFetching = false;
    },
    [getCampaignsList.rejected.type]: (state) => {
      state.isFetching = false;
    },

    //Get Current Campaign
    [getCurrentCampaign.pending.type]: (state) => {
      state.isFetching = true;
    },
    [getCurrentCampaign.fulfilled.type]: (
      state,
      action: PayloadAction<CampaignModel>
    ) => {
      state.currentCampaign = action.payload;
      state.isFetching = false;
    },
    [getCurrentCampaign.rejected.type]: (state) => {
      state.isFetching = false;
    },

    //Get Linked Campaigns
    [getLinkedCampaigns.pending.type]: (state) => {
      state.isFetching = true;
    },
    [getLinkedCampaigns.fulfilled.type]: (
      state,
      action: PayloadAction<LinkedServiceModel[]>
    ) => {
      state.linkedCampaigns = action.payload;
      state.isFetching = false;
    },
    [getLinkedCampaigns.rejected.type]: (state) => {
      state.isFetching = false;
    },

    //Get Current Campaign Numbers
    [getCampaignNumbers.pending.type]: (state) => {
      state.isFetching = true;
    },
    [getCampaignNumbers.fulfilled.type]: (
      state,
      action: PayloadAction<CampaignNumbersModel[]>
    ) => {
      state.campaignNumbers = [];
      action.payload.map((data, idx) =>
        state.campaignNumbers.push(data.phoneNumber)
      );
      state.isFetching = false;
    },
    [getCampaignNumbers.rejected.type]: (state) => {
      state.isFetching = false;
    },

    //Get EMAIL IMAGE PRESIGNED URL
    [getEmailImagePresignedUrl.pending.type]: (state) => {
      state.isFetching = true;
    },
    [getEmailImagePresignedUrl.fulfilled.type]: (
      state,
      action: PayloadAction<ResourcePreSignedModel>
    ) => {
      state.emailBannerUrl = action.payload.sourceUrl;

      state.isFetching = false;
    },
    [getEmailImagePresignedUrl.rejected.type]: (state) => {
      state.isFetching = false;
    },

    //UPLOAD CAMPAIGN RESOURCES
    [uploadCampaignResources.pending.type]: (state) => {
      state.isFetching = true;
    },
    [uploadCampaignResources.fulfilled.type]: (
      state,
      action: PayloadAction<UploadCampaignResourceModel>
    ) => {
      state.emailBannerUrl = action.payload.s3Url;

      state.isFetching = false;
    },
    [uploadCampaignResources.rejected.type]: (state) => {
      state.isFetching = false;
    },

    //Get Current Campaign Emails
    [getCampaignEmails.pending.type]: (state) => {
      state.isFetching = true;
    },
    [getCampaignEmails.fulfilled.type]: (
      state,
      action: PayloadAction<CampaignEmailListModel[]>
    ) => {
      state.campaignEmails = [];
      action.payload.map((data, idx) =>
        state.campaignEmails.push(data.emailId)
      );
      state.isFetching = false;
    },
    [getCampaignEmails.rejected.type]: (state) => {
      state.isFetching = false;
    },

    //Get Email Campaign Data
    [getEmailCampaignData.pending.type]: (state) => {
      state.isFetching = true;
    },
    [getEmailCampaignData.fulfilled.type]: (
      state,
      action: PayloadAction<EditSMSCampaignReq>
    ) => {
      state.campaignEmailData = action.payload;
      state.isFetching = false;
    },
    [getEmailCampaignData.rejected.type]: (state) => {
      state.isFetching = false;
    },
  },
});

export const getCampaignIsFetchingSelector = createTypedSelector(
  (state) => state.campaign.isFetching
);

export const getCampaignUuidSelector = createTypedSelector(
  (state) => state.campaign.campaignUuid
);

export const getCurrentCampaignSelector = createTypedSelector(
  (state) => state.campaign.currentCampaign
);

export const getCampaignsListSelector = createTypedSelector(
  (state) => state.campaign.campaignsList
);

export const getLinkedCampaignsSelector = createTypedSelector(
  (state) => state.campaign.linkedCampaigns
);

export const getEmailBannerS3UrlSelector = createTypedSelector(
  (state) => state.campaign.emailBannerUrl
);

export const getCampaignNumbersSelector = createTypedSelector(
  (state) => state.campaign.campaignNumbers
);

export const getEmailCampaignDataSelector = createTypedSelector(
  (state) => state.campaign.campaignEmailData
);

export const getCampaignNameSelector = createTypedSelector(
  (state) => state.campaign.campaignName
);

export const getCampaignTypeSelector = createTypedSelector(
  (state) => state.campaign.campaignType
);

export const getCampaignEmailsSelector = createTypedSelector(
  (state) => state.campaign.campaignEmails
);

export const {
  setCampaignUuid,
  setCampaignName,
  setCampaignType,
  resetCampaignDetails,
} = campaignSlice.actions;

export default campaignSlice.reducer;
