import React, { useEffect } from "react";
import { setAnalyticsTab } from "store/reducers/Analytics/analytics";
import SentimentAnalysis from "./SentimentContainer";
import Wordcloud from "./WordCloud";
import { useAppDispatch } from "hooks/redux";

const AnalyticsSentiments = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setAnalyticsTab(2));
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <SentimentAnalysis />
      {/* <Wordcloud /> */}
    </div>
  );
};

export default AnalyticsSentiments;
