import React from 'react'
import { Link, LinkProps } from 'react-router-dom'

interface OwnLinkProps extends LinkProps {
  disabled?: boolean
}

const TopNavigatorLink: React.FC<OwnLinkProps> = ({
  className = '',
  children,
  disabled,
  ...rest
}) => {
  const classNameDisabled = disabled ? 'disabled_link' : ''

  return (
    <Link
      className={`top_navigation_link ${className} ${classNameDisabled}`}
      {...rest}
    >
      {children}
    </Link>
  )
}

export default TopNavigatorLink
