import { useAppSelector } from "hooks/redux";
import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import LineBreak from "components/LineBreak";
import { getPurchaseDetailListSelector } from "store/reducers/Analytics/analytics";
import LongText from "components/LongText/LongText";
import ReactTimeAgo from "react-time-ago";
import moment from "moment";

const PurchaseDetailTable = () => {
  const channel = useAppSelector(getPurchaseDetailListSelector);

  return (
    <>
      {/* <h4>User journey</h4> */}
      <LineBreak />
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>
                {" "}
                <p className="bold_heading_table">Username</p>
              </TableCell>
              <TableCell align="center">
                {" "}
                <p className="bold_heading_table">Date</p>
              </TableCell>
              <TableCell align="center">
                {" "}
                <p className="bold_heading_table">Amount</p>
              </TableCell>

              <TableCell align="center">
                {" "}
                <p className="bold_heading_table">Status</p>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!!channel &&
              channel.map((channel, idx) => {
                return (
                  <TableRow
                    key={idx}
                    //   onClick={() => handleUserActions(row)}
                    className="table_cell_hover"
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {<LongText title={channel.userName} cutoff={16} /> || "-"}
                    </TableCell>
                    <TableCell align="center" component="th" scope="row">
                      {
                        <ReactTimeAgo
                          date={
                            new Date(
                              moment
                                .utc(channel.createdDate)
                                .local()
                                .format("YYYY-MM-DD HH:mm:ss")
                            )
                          }
                          locale="en-GB"
                        />
                      }{" "}
                    </TableCell>
                    <TableCell align="center" component="th" scope="row">
                      <p>$ {channel.transactionAmount.toFixed(2)}</p>
                    </TableCell>
                    <TableCell align="center" component="th" scope="row">
                      {(
                        <LongText
                          title={
                            channel.transactionStatus === "ordered"
                              ? "Pending"
                              : "Success"
                          }
                          cutoff={16}
                        />
                      ) || "-"}
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      {/* {userActions && userActions.totalPages > 1 && (
        <PageNumbers
          totalPages={userActions?.totalPages}
          initialPage={0}
          handleChange={userActions}
        />
      )} */}
    </>
  );
};

export default PurchaseDetailTable;
