import { useQuery } from "hooks/useQuery";
import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

const REDIRECT = "rxurx";
const REF = "ref";

const RedirectPage = () => {
  const searchParams = useQuery();

  const redirect = searchParams.get(REDIRECT);
  const ref = searchParams.get(REF);

  useEffect(() => {
    if (redirect) {
      document.location.href = redirect;
    }
  }, [redirect, ref]);

  return <></>;
};

export default RedirectPage;
