import { useAppDispatch, useAppSelector } from "hooks/redux";
import { toastError } from "hooks/useToastify";
import React, { useEffect } from "react";
import {
  razorPaySub,
  razorPaySubVerify,
} from "store/async-actions/Subscriptions/subscriptions";
import { getHiveSelector } from "store/reducers/HiveDetails/hiveDetails";
import {
  getPlanDetailsSelector,
  getRazorPaySubOrderSelector,
} from "store/reducers/Subscriptions/subscriptions";

const RazorPayPlan = () => {
  const dispatch = useAppDispatch();
  const createOrderResp = useAppSelector(getRazorPaySubOrderSelector);

  const hive = useAppSelector(getHiveSelector);
  const currentPlan = useAppSelector(getPlanDetailsSelector);

  useEffect(() => {
    hive &&
      currentPlan &&
      dispatch(
        razorPaySub({
          organizationId: hive?.communityId || 0,
          organizationName: hive?.communityName || "",
          organizationUuid: hive?.communityUuid || "",
          planUuid: currentPlan?.planUuid || "",
        })
      );
  }, [dispatch, currentPlan]);

  function loadScript(src: string) {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }

  async function displayRazorPay() {
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );
    if (!res) {
      alert("Razorpay SDK failed to load. Are you online?");
      return;
    }
    const options = {
      // key: "rzp_test_j2sDfnlDqZgcNo",
      key: "rzp_live_wHpMkB5GN12X7J",
      //   amount: "108",
      amount: createOrderResp!.planAmount * 100,
      currency: createOrderResp!.currency,
      name: hive?.communityName,
      description: `${currentPlan?.planName}`,
      subscriptionId: createOrderResp!.razorPaySubscriptionId,
      handler: function (response: {
        razorpay_payment_id: string;
        razorpay_subscription_id: string;
        razorpay_signature: string;
      }) {
        dispatch(
          razorPaySubVerify({
            paymentId: response.razorpay_payment_id,
            subscriptionId: response.razorpay_subscription_id,
            signature: response.razorpay_signature,
          })
        );
      },
      theme: {
        color: "#61dafb",
      },
    };

    const paymentObject = new (window as any).Razorpay(options);
    paymentObject.on(
      "payment.failed",
      function (response: {
        error: {
          code: any;
          description: any;
          source: any;
          step: any;
          reason: any;
          metadata: { order_id: any; payment_id: any };
        };
      }) {
        // alert(response.error.code);
        // alert(response.error.description);
        // alert(response.error.source);
        // alert(response.error.step);
        alert(response.error.reason);
        // alert(response.error.metadata.order_id);
        // alert(response.error.metadata.payment_id);
      }
    );
    paymentObject.open();
  }

  const handleContinue = () => {
    createOrderResp?.razorPaySubscriptionId
      ? displayRazorPay()
      : toastError("Sorry there was an error while processing your request");
  };

  return (
    <div onClick={handleContinue} className="primaryBtn plan_buyBtn">
      Continue
    </div>
  );
};

export default RazorPayPlan;
