import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import PageLayout from "components/PageLayout/PageLayout";
import IslandLayout from "components/IslandLayout/IslandLayout";
import CreateCampaign from "./CreateCampaign";
import CreateSMSInfo from "./CreateSMSInfo";
import AddNumbers from "./AddNumbers";
import { useNavigate } from "react-router-dom";
import CampaignCreated from "./components/CampaignCreated";
import { useAppSelector } from "hooks/redux";
import { getCampaignTypeSelector } from "store/reducers/Campaign/campaign";
import CreateEmailInfo from "./CreateEmailInfo";
import AddEmails from "./AddEmails";

const steps = ["Select campaign settings", "Customize", "Create a campaign"];

export default function CampaignStepper() {
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set<number>());

  const isStepOptional = (step: number) => {
    return step === 1;
  };

  const isStepSkipped = (step: number) => {
    return skipped.has(step);
  };

  const navigate = useNavigate();

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    activeStep === 0 && navigate("/campaigns");
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const type = useAppSelector(getCampaignTypeSelector);

  return (
    <PageLayout sideMenu={true}>
      <IslandLayout>
        <Box sx={{ width: "100%", padding: "20px" }}>
          <Stepper activeStep={activeStep}>
            {steps.map((label, index) => {
              const stepProps: { completed?: boolean } = {};
              const labelProps: {
                optional?: React.ReactNode;
              } = {};

              if (isStepSkipped(index)) {
                stepProps.completed = false;
              }
              return (
                <Step key={label} {...stepProps}>
                  <StepLabel {...labelProps}>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
          {activeStep === steps.length ? (
            <React.Fragment>
              <CampaignCreated />
            </React.Fragment>
          ) : (
            <React.Fragment>
              <Box sx={{ mt: 2, mb: 1 }}>
                {activeStep === 0 ? (
                  <CreateCampaign
                    handleBack={handleBack}
                    handleNext={handleNext}
                  />
                ) : activeStep === 1 ? (
                  <>
                    {type === "sms" ? (
                      <CreateSMSInfo
                        handleBack={handleBack}
                        handleNext={handleNext}
                      />
                    ) : (
                      <CreateEmailInfo
                        handleBack={handleBack}
                        handleNext={handleNext}
                      />
                    )}
                  </>
                ) : activeStep === 2 ? (
                  <>
                    {type === "sms" ? (
                      <AddNumbers
                        handleBack={handleBack}
                        handleNext={handleNext}
                      />
                    ) : (
                      <AddEmails
                        handleBack={handleBack}
                        handleNext={handleNext}
                      />
                    )}
                  </>
                ) : (
                  <CampaignCreated />
                )}
              </Box>
            </React.Fragment>
          )}
        </Box>
      </IslandLayout>
    </PageLayout>
  );
}
