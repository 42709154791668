import AnalyticsCapsule from "components/AnalyticsCapsule";
import LineBreak from "components/LineBreak";
import {
  ArrowRightBlackSVG,
  CampaignIconSVG,
  StoryIconSVG,
} from "components/SVG/SVG";
import React from "react";
import { useNavigate } from "react-router-dom";

const AllLinkFeatures = () => {
  const navigate = useNavigate();

  const handleCTS = () => {
    navigate("/linkfeatures/campaign-to-story");
  };

  return (
    <>
      <LineBreak />
      <div onClick={handleCTS} className="w-cts cursor-pointer">
        <AnalyticsCapsule>
          <div className="">
            <div className="link_icons_wrapper">
              <CampaignIconSVG />
              <ArrowRightBlackSVG />
              <StoryIconSVG />
            </div>
            <LineBreak />
            <h2>Link your campaign to a story</h2>
          </div>
        </AnalyticsCapsule>{" "}
      </div>
    </>
  );
};

export default AllLinkFeatures;
