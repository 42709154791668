import AnalyticsCapsule from "components/AnalyticsCapsule";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import React, { useEffect } from "react";
import ReactTimeAgo from "react-time-ago";
import { getVisitorsData } from "store/async-actions/Analytics/analyticsActions";
import {
  getFromDateSelector,
  getHiveRevenueGraphSelector,
  getToDateSelector,
  getVisitorsSelector,
} from "store/reducers/Analytics/analytics";
import { getHiveUuid } from "store/reducers/HiveDetails/hiveDetails";
import { VictoryBar, VictoryChart, VictoryStack, VictoryAxis } from "victory";
import moment from "moment";
import dayjs from "dayjs";

const RevenueGraph = () => {
  const dispatch = useAppDispatch();
  const hiveUuid = useAppSelector(getHiveUuid);

  const fromDate = useAppSelector(getFromDateSelector);
  const toDate = useAppSelector(getToDateSelector);

  const revenueData = useAppSelector(getHiveRevenueGraphSelector);

  useEffect(() => {
    dispatch(
      getVisitorsData({
        organizationUuid: hiveUuid,
        fromDate: fromDate,
        toDate: toDate,
      })
    );
  }, [hiveUuid, dispatch, fromDate, toDate]);

  return (
    <AnalyticsCapsule>
      {revenueData && revenueData.length > 0 ? (
        <div className="tags visitors_graph_limit">
          <h3>Revenue</h3>

          <VictoryChart domainPadding={10}>
            <VictoryAxis
              tickFormat={(date) => moment(date).format("MMM D")}
              style={{
                tickLabels: { fontSize: 8 },
              }}
              tickCount={3}
            />
            <VictoryAxis
              dependentAxis
              style={{
                tickLabels: { fontSize: 8 },
              }}
              tickCount={7}
            />
            <VictoryStack>
              <VictoryBar
                cornerRadius={3}
                style={{ data: { fill: "#41C667", width: "10" } }}
                data={revenueData}
                x="date"
                y="transactionsAmount"
              />
            </VictoryStack>
          </VictoryChart>
        </div>
      ) : (
        <h4>
          Graphical representation not available for the selected date range
        </h4>
      )}
    </AnalyticsCapsule>
  );
};

export default RevenueGraph;
