import { CircularProgress } from "@mui/material";
import Buttons from "components/Buttons";
import IslandLayout from "components/IslandLayout";
import LineBreak from "components/LineBreak";
import PageLayout from "components/PageLayout";
import PageNumbers from "components/PageNumbers";
import { UsersSVG, VideosSVG } from "components/SVG/SVG";
import SortVideo from "components/SortVideo/SortVideo";
import VideoItem from "components/VideoItem/VideoItem";
import VideoTabs from "components/VideoTabs/VideoTabs";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getAnalyticsChannelMetrics } from "store/async-actions/Analytics/analyticsActions";
import {
  getPendingVideoList,
  getVideoList,
} from "store/async-actions/Videos/videosActions";
import { getChannelDetailsMetricsSelector } from "store/reducers/Analytics/analytics";
import {
  channelObjSelector,
  channelPropSelector,
  getChannelAmountSelector,
} from "store/reducers/ChannelDetails/channelDetails";
import {
  getActiveChannelIdSelector,
  getActiveChannelUuidSelector,
  getVideosCurrentPageSelector,
  setCurrentVideosPage,
  videosTabSelector,
} from "store/reducers/ChannelDetails/channelList";
import childComponents, {
  getChannelComponents,
  getVideoTabs,
} from "store/reducers/ChildComponents/childComponents";
import {
  getTotalVideosSelector,
  reducedVideoListSelector,
  videoListLoadingSelector,
  videoListSelector,
} from "store/reducers/Videos/videoList";
import {
  setContentUploadResp,
  setS3Done,
  videoUpdatedSelector,
} from "store/reducers/Videos/videos";

const ChannelPage = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const channelObj = useAppSelector(channelObjSelector);
  const channelProps = useAppSelector(channelPropSelector);
  const channelBtns = useAppSelector(getChannelComponents);
  const channelUuid = useAppSelector(getActiveChannelUuidSelector);

  const amount = useAppSelector(getChannelAmountSelector);

  const isFetching = useAppSelector(videoListLoadingSelector);
  const videoListResp = useAppSelector(videoListSelector);
  const videoList = useAppSelector(reducedVideoListSelector);
  const videoTabs = useAppSelector(getVideoTabs);
  const currentVideoTab = useAppSelector(videosTabSelector);

  const pageNo = useAppSelector(getVideosCurrentPageSelector);

  useEffect(() => {
    dispatch(
      getPendingVideoList({
        channelUuid: channelUuid!,
        page: 0,
        noOfRecords: 10,
        status: "Pending",
      })
    );
  }, [dispatch, channelUuid]);

  useEffect(() => {
    dispatch(setS3Done(false));
    dispatch(setContentUploadResp(undefined));
    dispatch(
      getVideoList({
        channelUuid: channelUuid!,
        page: pageNo,
        noOfRecords: 12,
        status:
          !!videoTabs &&
          videoTabs[currentVideoTab] &&
          videoTabs[currentVideoTab].componentName
            ? videoTabs[currentVideoTab].componentName === "Videos"
              ? "Ready"
              : videoTabs[currentVideoTab].componentName
            : "Ready",
      })
    );
  }, [channelUuid, dispatch]);

  const videoStatusUpdated = useAppSelector(videoUpdatedSelector);

  const channelMetrics = useAppSelector(getChannelDetailsMetricsSelector);

  useEffect(() => {
    videoStatusUpdated &&
      !!videoTabs &&
      videoTabs.length > 0 &&
      videoTabs[currentVideoTab] &&
      videoTabs[currentVideoTab].componentName &&
      dispatch(
        getVideoList({
          channelUuid: channelUuid!,
          page: pageNo,
          noOfRecords: 12,
          status:
            videoTabs[currentVideoTab].componentName === "Videos"
              ? "Ready"
              : videoTabs[currentVideoTab].componentName,
        })
      );
  }, [videoStatusUpdated, videoTabs]);

  const handleChange = (passedPageNo: number) => {
    dispatch(setCurrentVideosPage(passedPageNo));
    !!videoTabs &&
      videoTabs.length > 0 &&
      videoTabs[currentVideoTab] &&
      videoTabs[currentVideoTab].componentName &&
      dispatch(
        getVideoList({
          channelUuid: channelUuid!,
          page: passedPageNo,
          noOfRecords: 12,
          status:
            videoTabs[currentVideoTab].componentName === "Videos"
              ? "Ready"
              : videoTabs[currentVideoTab].componentName,
        })
      );
  };

  const channelId = useAppSelector(getActiveChannelIdSelector);

  useEffect(() => {
    dispatch(
      getAnalyticsChannelMetrics({
        channelId: channelId,
      })
    );
  }, [dispatch, channelId]);

  const totalVideos = useAppSelector(getTotalVideosSelector);

  return (
    <PageLayout sideMenu={true}>
      <div className="channel_page_container">
        <IslandLayout>
          <div className="channel_content">
            <div className="channel_header">
              <img
                className="channel_page_logo"
                src={
                  channelProps?.webLogo ||
                  "https://veehivestage-images.s3.us-east-2.amazonaws.com/channelImages/defaultChannelLogo.jpg"
                }
                alt=""
              />
              <div className="channel_info">
                <div>
                  <h4>{channelObj?.channelName}</h4>
                  <p className="small_text">
                    {channelProps?.channelTier === "INVITE" ||
                    channelProps?.channelTier === "PRIVATE_PAID"
                      ? "Private"
                      : "Public"}
                  </p>
                </div>
                <div>
                  <p>{channelObj?.description}</p>
                  <div className="channel_metrics_container">
                    <div className="channel_metric_wrapper">
                      <VideosSVG />
                      <p className="text-sm">
                        {channelMetrics?.totalVideos || 0}
                      </p>
                    </div>
                    <div className="channel_metric_wrapper">
                      <UsersSVG />
                      <p className="text-sm">
                        {channelMetrics?.totalMembers || 0}
                      </p>
                    </div>
                  </div>
                </div>
                {!!channelBtns && (
                  <div className="channel_btns">
                    {channelBtns.map((button, id) => {
                      return (
                        <Buttons button={button} key={id}>
                          {button.componentType === "icon" ? (
                            <img
                              src={button.componentIcon}
                              alt=""
                              width="20px"
                            />
                          ) : (
                            button.componentName
                          )}
                        </Buttons>
                      );
                    })}
                  </div>
                )}
              </div>
            </div>
            {+amount > 0 && (
              <div className="channel_amount_wr">$ {+amount}</div>
            )}
          </div>
        </IslandLayout>
        <LineBreak />
        <IslandLayout>
          <div className="channel_content">
            <VideoTabs />
            {isFetching ? (
              <CircularProgress size={30} color="inherit" />
            ) : (
              <>
                {videoList && videoList.length > 0 ? (
                  <>
                    <div className="title_and_limit video_content_header">
                      <h3>
                        {totalVideos || 0}{" "}
                        {totalVideos === 1 ? "Video" : "Videos"}
                      </h3>
                      {!!channelBtns.length && <SortVideo />}
                    </div>
                    <div className="channel_videos">
                      {videoList.map((video, idx) => {
                        return <VideoItem key={idx} videoItem={video} />;
                      })}
                      {videoListResp?.totalPages &&
                        videoListResp?.totalPages > 1 && (
                          <div className="pagination_container">
                            <PageNumbers
                              totalPages={videoListResp?.totalPages || 0}
                              initialPage={pageNo}
                              handleChange={handleChange}
                            />
                          </div>
                        )}
                    </div>
                  </>
                ) : (
                  <div className="no_videos_container">
                    {videoTabs.length > 0 && (
                      <>
                        <h3>No Videos found</h3>
                        <p className="">
                          Here's where you'll find all the videos that have been{" "}
                          {videoTabs[currentVideoTab].componentName === "Videos"
                            ? `approved`
                            : videoTabs[
                                currentVideoTab
                              ].componentName.toLowerCase()}
                          . Take a look and make sure everything's in order.
                        </p>
                      </>
                    )}
                  </div>
                )}
              </>
            )}
          </div>
        </IslandLayout>
      </div>
    </PageLayout>
  );
};

export default ChannelPage;
