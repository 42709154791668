import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  ChannelItemModel,
  ChannelObject,
  ChannelProperties,
} from "api/models/Channel/channelDetails";
import {
  editChannelDetails,
  editVideoDetails,
  getChannelDetails,
} from "store/async-actions/Channels/channels";

import { createTypedSelector } from "store/utils";

const initialState: {
  channelDetails: ChannelItemModel | null;
  channelObj: ChannelObject | null;
  channelProps: ChannelProperties | null;
  activeTab: string;
  isFetching: boolean;
  isPaid: boolean;
  isAds: boolean;
  isPrivate: boolean;
  channelAmount: string;
  videoDuration: number;
  channelTabs: string[];
  channelEdited: boolean;
  videoEdited: boolean;
  isEditingVideo: boolean;
} = {
  videoEdited: false,
  isEditingVideo: false,
  channelDetails: null,
  channelObj: null,
  channelProps: null,
  activeTab: "Videos",
  isFetching: false,
  isPaid: false,
  isAds: false,
  isPrivate: false,
  channelAmount: "",
  videoDuration: 0,
  channelTabs: ["About", "Members", "Settings"],
  channelEdited: false,
};

const channelDetailsSlice = createSlice({
  name: "channelDetails",
  initialState,
  reducers: {
    setActiveTab(state, action: PayloadAction<string>) {
      state.activeTab = action.payload;
    },
    setVideoDuration: (state, action: PayloadAction<number>) => {
      state.videoDuration = action.payload;
    },
    setIsPaid: (state, action: PayloadAction<boolean>) => {
      state.isPaid = action.payload;
      if (!action.payload) state.channelAmount = "0";
    },
    setIsAds: (state, action: PayloadAction<boolean>) => {
      state.isAds = action.payload;
    },
    setIsEdited: (state, action: PayloadAction<boolean>) => {
      state.channelEdited = action.payload;
    },
    setIsVideoEdited: (state, action: PayloadAction<boolean>) => {
      state.videoEdited = action.payload;
    },
    setIsPrivate: (state, action: PayloadAction<boolean>) => {
      state.isPrivate = action.payload;
    },
    setIsEditingVideo: (state, action: PayloadAction<boolean>) => {
      state.isEditingVideo = action.payload;
    },
    setChannelAmount: (state, action: PayloadAction<string>) => {
      state.channelAmount = action.payload;
    },
  },
  extraReducers: {
    [getChannelDetails.pending.type]: (state) => {
      state.isFetching = true;
    },
    [getChannelDetails.fulfilled.type]: (
      state,
      action: PayloadAction<ChannelItemModel>
    ) => {
      state.channelDetails = null;
      state.channelObj = null;
      state.channelProps = null;
      state.channelDetails = action.payload;
      state.channelObj = action.payload.objChannel;
      state.channelProps = action.payload.objChannelProperties;
      state.channelAmount = action.payload.objChannelPayment.amount.toString();
      state.isPaid = action.payload.objChannelPayment.amount > 0;
      state.isAds = action.payload.objChannelProperties.advertisment;
      state.videoDuration = action.payload.objChannelProperties.seconds;
      state.isPrivate =
        action.payload.objChannelProperties.channelTier === "PRIVATE_PAID" ||
        action.payload.objChannelProperties.channelTier === "INVITE";
      state.isFetching = false;
    },
    [getChannelDetails.rejected.type]: (state) => {
      state.isFetching = false;
    },

    //Edit Channel Details
    [editChannelDetails.pending.type]: (state) => {
      state.isFetching = true;
      state.channelEdited = false;
    },
    [editChannelDetails.fulfilled.type]: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.channelEdited = true;
      state.isFetching = false;
    },
    [editChannelDetails.rejected.type]: (state) => {
      state.channelEdited = false;
      state.isFetching = false;
    },

    //Edit Video Details
    [editVideoDetails.pending.type]: (state) => {
      state.isEditingVideo = true;
      state.videoEdited = false;
    },
    [editVideoDetails.fulfilled.type]: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.videoEdited = true;
      state.isEditingVideo = false;
    },
    [editVideoDetails.rejected.type]: (state) => {
      state.videoEdited = false;
      state.isEditingVideo = false;
    },
  },
});

export const {
  setIsPrivate,
  setChannelAmount,
  setIsAds,
  setIsPaid,
  setVideoDuration,
  setIsEdited,
  setIsEditingVideo,
  setIsVideoEdited,
} = channelDetailsSlice.actions;

export const getChannelAmountSelector = createTypedSelector(
  (state) => state.channelDetails.channelAmount
);
export const getChannelIsEditedSelector = createTypedSelector(
  (state) => state.channelDetails.channelEdited
);

export const getChannelTabsSelector = createTypedSelector(
  (state) => state.channelDetails.channelTabs
);

export const getVideoEditedSelector = createTypedSelector(
  (state) => state.channelDetails.videoEdited
);

export const getIsEditingVideoSelector = createTypedSelector(
  (state) => state.channelDetails.isEditingVideo
);

export const getEditChannelIsAdsSelector = createTypedSelector(
  (state) => state.channelDetails.isAds
);

export const getEditChannelIsPrivateSelector = createTypedSelector(
  (state) => state.channelDetails.isPrivate
);

export const getEditChannelVideoDurationSelector = createTypedSelector(
  (state) => state.channelDetails.videoDuration
);

export const getEditChannelIsPaidSelector = createTypedSelector(
  (state) => state.channelDetails.isPaid
);

export const getChannelDetailsSelector = createTypedSelector(
  (state) => state.channelDetails.channelDetails
);

export const channelPropSelector = createTypedSelector(
  (state) => state.channelDetails.channelProps
);

export const channelObjSelector = createTypedSelector(
  (state) => state.channelDetails.channelObj
);

export const channelEditIsLoadingSelector = createTypedSelector(
  (state) => state.channelDetails.isFetching
);

export const getActiveVideosTabSelector = createTypedSelector(
  (state) => state.channelDetails.activeTab
);

export default channelDetailsSlice.reducer;
