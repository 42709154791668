import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import Switch from "@mui/material/Switch";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import BackButton from "components/BackButton";
import Loader from "components/common/Loader";
import ContactInput from "components/ContactInput";
import LineBreak from "components/LineBreak/LineBreak";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  emailAdminInvite,
  emailMemberInvite,
  getChannelList,
  phoneAdminInvite,
  phoneMemberInvite,
} from "store/async-actions/Hive/hivePage";
import { getHiveSelector } from "store/reducers/HiveDetails/hiveDetails";
import {
  getPhoneUsersSelector,
  hiveMembersLoadingSelector,
  inviteSentSelector,
  inviteToChannelsList,
  setInvitedMember,
  updatePhoneUsers,
} from "store/reducers/HiveDetails/hiveSettings";
import { isMemberViewSelector } from "store/reducers/Profile/profile";

const AddContact = () => {
  const inviteToChannels = useAppSelector(inviteToChannelsList);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const hiveDetails = useAppSelector(getHiveSelector);

  const isLoading = useAppSelector(hiveMembersLoadingSelector);

  const [emails, setEmails] = useState<string[]>([]);

  const [phoneNos, setPhoneNos] = useState<string[]>([]);

  // const phoneUsers = useAppSelector(getPhoneUsersSelector);

  const handlePhoneNumbersChange = (nos: string[]) => {
    setPhoneNos(nos);
    // nos.map((item) => {
    //   dispatch(updatePhoneUsers({ mobileNo: item }));
    // });
  };

  const [selectedTab, setSelectedTab] = useState(0);
  const tabs = ["Email", "Phone"];

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  const [adminInvite, setAdminInvite] = useState(false);
  const [skipAlert, setSkipAlert] = useState(false);

  const handleAdminInvite = () => {
    setAdminInvite((prevState) => !prevState);
  };

  const isMember = useAppSelector(isMemberViewSelector);

  const handleSkipAlert = () => {
    setSkipAlert((prevState) => !prevState);
  };
  const isInvited = useAppSelector(inviteSentSelector);

  useEffect(() => {
    isInvited && navigate("/home/settings/members");
    dispatch(
      getChannelList({
        hiveId: hiveDetails?.communityId!,
        isMemberView: isMember,
      })
    );
  }, [isInvited]);

  useEffect(() => {
    if (inviteToChannels.length < 1) {
      navigate("/home/settings/members");
      dispatch(setInvitedMember(false));
    }
  }, [inviteToChannels]);

  const handleSendInvite = () => {
    const emailUsers: {
      email: string;
    }[] = [];

    const phoneUsers: {
      mobileNo: string;
    }[] = [];

    emails.map((item) => {
      emailUsers.push({ email: item });
    });

    phoneNos.map((item) => {
      phoneUsers.push({ mobileNo: item });
    });

    const phoneData = {
      users: phoneUsers,
      channels: inviteToChannels,
      communityId: hiveDetails?.communityId!,
      skipEmails: skipAlert,
    };

    const emailData = {
      users: emailUsers,
      channels: inviteToChannels,
      communityId: hiveDetails?.communityId!,
      skipEmails: skipAlert,
    };

    if (selectedTab === 0) {
      if (adminInvite) {
        dispatch(emailAdminInvite(emailData));
      } else {
        dispatch(emailMemberInvite(emailData));
      }
    } else {
      if (adminInvite) {
        dispatch(phoneAdminInvite(phoneData));
      } else {
        dispatch(phoneMemberInvite(phoneData));
      }
    }
  };

  return (
    <div className="add_contact_container">
      <h3>Invite Members</h3>
      <LineBreak />
      <div className="back_button_spacing">
        <BackButton to="/home/settings/members/invite" />
      </div>
      <ContactInput
        tabs={tabs}
        handleChange={handleChange}
        emails={emails}
        setEmails={setEmails}
        phoneNos={phoneNos}
        setPhoneNos={handlePhoneNumbersChange}
        selectedTab={selectedTab}
      />
      <div className="members_invite_toggles">
        <div className="member_setting_container">
          <Switch checked={adminInvite} onChange={handleAdminInvite} />
          <p>Invite as admin - Default access to all public channels.</p>
        </div>
        <div className="member_setting_container">
          <Switch checked={!skipAlert} onChange={handleSkipAlert} />
          <p>Send notifications - Send invite notification as email or sms.</p>
        </div>
      </div>

      {!!emails.length || !!phoneNos.length ? (
        <div onClick={handleSendInvite} className="nextBtn primaryBtn">
          Send Invites
        </div>
      ) : (
        <div className="nextBtn disabledBtn">Send Invites</div>
      )}

      <Dialog open={isLoading}>
        <div className="loader_padding">
          <CircularProgress size={30} color="inherit" />
        </div>
      </Dialog>
    </div>
  );
};

export default AddContact;
