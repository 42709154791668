import BaseApi from "api/base";
import { AxiosInstance, AxiosPromise } from "axios";

import { BaseResponse } from "../../models/base";
import {
  CardDetails,
  CurrentPlanItem,
  PlanModel,
  RazorPaySubReq,
  RazorPaySubVerifyReq,
} from "api/models/Subscriptions/subscriptions";
import { RazorPaySubOrder } from "api/models/Channel/cartModel";

export class SubscriptionsApi extends BaseApi {
  getAllPlans(data: {
    organizationUuid: string;
  }): AxiosPromise<BaseResponse<PlanModel>> {
    return this.request({
      url: `${this.url}get/plans`,
      method: "POST",
      data,
    });
  }

  getCurrentPlan(data: {
    organizationId: number;
  }): AxiosPromise<BaseResponse<CurrentPlanItem>> {
    return this.request({
      url: `${this.url}get/current/plan`,
      method: "POST",
      data,
    });
  }

  changeStripePlan(data: {
    organizationUuid: string;
    planUuid: string;
  }): AxiosPromise<BaseResponse<boolean>> {
    return this.request({
      url: `${this.url}stripe/change`,
      method: "POST",
      data,
    });
  }

  changeRazorPlan(data: {
    organizationUuid: string;
    planUuid: string;
  }): AxiosPromise<BaseResponse<boolean>> {
    return this.request({
      url: `${this.url}razorpay/change`,
      method: "POST",
      data,
    });
  }

  getPlanDetails(data: {
    planUuid: string;
  }): AxiosPromise<BaseResponse<CurrentPlanItem>> {
    return this.request({
      url: `${this.url}get/plan/details`,
      method: "POST",
      data,
    });
  }

  getStripeCard(data: {
    organizationUuid: string;
  }): AxiosPromise<BaseResponse<CardDetails>> {
    return this.request({
      url: `/payment/stripe/org/card/details`,
      method: "POST",
      data,
    });
  }

  cancelSubscription(data: {
    organizationUuid: string;
    notes: string;
  }): AxiosPromise<BaseResponse<boolean>> {
    return this.request({
      url: `${this.url}cancel`,
      method: "POST",
      data,
    });
  }

  stripePurchase(data: {
    planUuid: string;
  }): AxiosPromise<BaseResponse<CurrentPlanItem>> {
    return this.request({
      url: `/payment/stripe/subscribe`,
      method: "POST",
      data,
    });
  }

  razorPaySub(
    data: RazorPaySubReq
  ): AxiosPromise<BaseResponse<RazorPaySubOrder>> {
    return this.request({
      url: `/payment/razorpay/subscribe`,
      method: "POST",
      data,
    });
  }

  razorPaySubVerify(
    data: RazorPaySubVerifyReq
  ): AxiosPromise<BaseResponse<boolean>> {
    return this.request({
      url: `/payment/razorpay/subscribe/verify`,
      method: "POST",
      data,
    });
  }
}

export default function subscriptionsApi(request: AxiosInstance) {
  return new SubscriptionsApi({
    request,
    url: `/webApp/subscription/`,
  });
}
