import { useEffect, useRef, useCallback } from "react";

import {
  useAppDispatch,
  useAppSelector,
  useAppShallowSelector,
} from "hooks/redux";
import { getChatMessages } from "store/async-actions/Chat/chat";
import {
  ChatItem,
  getChatListSelector,
  getChatMessagesLenghtSelector,
  getIsFetchingChatMessagesSelector,
  setChatPageNo,
  setChatRoomId,
} from "store/reducers/Chat/chat";
import { profileUserIdSelector } from "store/reducers/Profile/profile";

const LOAD_LIMIT = 30;

interface UseChatMessagesReturn {
  loadMoreMessageList: () => void;
  chatList: ChatItem[];
  isLoading: boolean;
}

export const useChatMessages = (roomId: string): UseChatMessagesReturn => {
  const page = useRef<number>(0);
  const hasAllLoaded = useRef<boolean>(false);
  const dispatch = useAppDispatch();
  const profileId = useAppSelector(profileUserIdSelector);
  const chatList = useAppShallowSelector(getChatListSelector);
  const chatMessagesLenght = useAppSelector(getChatMessagesLenghtSelector);
  const isLoading = useAppSelector(getIsFetchingChatMessagesSelector);

  useEffect(() => {
    dispatch(setChatPageNo(page.current));
    dispatch(setChatRoomId(roomId));
  }, [page, roomId, dispatch]);

  const loadMoreMessageList = useCallback(() => {
    if (chatMessagesLenght < LOAD_LIMIT - 1 || isLoading) return;
    if (profileId && !hasAllLoaded.current) {
      dispatch(
        getChatMessages({
          chatRoomId: roomId,
          userId: profileId,
          page: page.current + 1,
          limit: LOAD_LIMIT,
        })
      )
        .unwrap()
        .then((res) => {
          if (res.length) {
            page.current = page.current + 1;
          } else {
            hasAllLoaded.current = true;
          }
        });
    }
  }, [chatMessagesLenght, dispatch, isLoading, profileId, roomId]);

  useEffect(() => {
    if (roomId && profileId && !page.current) {
      dispatch(
        getChatMessages({
          chatRoomId: roomId,
          userId: profileId,
          page: page.current,
          limit: LOAD_LIMIT,
        })
      );
    }
    return () => {
      page.current = 0;
    };
  }, [dispatch, roomId, profileId]);

  return { loadMoreMessageList, chatList, isLoading };
};
