import React, { useEffect } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import {
  getActiveChannelUuidSelector,
  getActiveTabSelector,
  setActiveTab,
} from "store/reducers/ChannelDetails/channelList";
import { useNavigate } from "react-router-dom";
import { getChannelTabsSelector } from "store/reducers/ChannelDetails/channelDetails";

const ChannelTabs = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const channelUuid = useAppSelector(getActiveChannelUuidSelector);
  const tabs = useAppSelector(getChannelTabsSelector);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    dispatch(setActiveTab(newValue));
    navigate(`/channels/${channelUuid}/${tabs[newValue].toLowerCase()}`);
  };

  const selectedTab = useAppSelector(getActiveTabSelector);

  return (
    <Tabs value={selectedTab} onChange={handleChange} aria-label="channel tabs">
      {tabs.map((tab, idx) => {
        return (
          <Tab
            style={{
              width: "33%",
              height: "20px",
              fontFamily: "IBM Plex Sans Condensed",
              fontStyle: "normal",
              fontWeight: 600,
              fontSize: "14px",
              lineHeight: "140%",
              textAlign: "center",
              letterSpacing: "0.02em",
              color: "#1C1B1F",
            }}
            label={tab}
          />
        );
      })}
      {/* <Tab label="About" />
      <Tab label="Members" />
      <Tab label="Settings" /> */}
    </Tabs>
  );
};

export default ChannelTabs;
