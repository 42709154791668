import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  ChatBotChatroomMessagesModel,
  ChatBotChatroomsModel,
} from "api/models/Chat/chat";
import {
  ContactInfoModel,
  PublicHivesItem,
  SocialLinksItem,
} from "api/models/Hive/hiveDetails";
import {
  MembersItemModel,
  MembersModel,
  MembersRespModel,
} from "api/models/Hive/hiveMembers";
import { UploadContentItem } from "api/models/Videos/uploadContent";
import { toastInfo } from "hooks/useToastify";
import {
  getAllHiveMembers,
  getChatbotChatroomMessages,
  getChatbotChatrooms,
} from "store/async-actions/Hive/hivePage";
import {
  emailAdminInvite,
  emailMemberInvite,
  getContactInfo,
  getHiveMembers,
  getPublicHives,
  getSocialLinks,
  phoneAdminInvite,
  phoneMemberInvite,
  saveContactInfo,
  saveSocialLinks,
} from "store/async-actions/Hive/hivePage";
import { createTypedSelector } from "store/utils";

const initialState: {
  invited: boolean;
  isFetching: boolean;
  hiveMembersList: MembersItemModel[] | undefined;
  hiveMembersPageNo: number;
  hiveMembersTotalPages: number;
  hiveSettingsTabs: string[];
  hiveSettingsTabIndex: number;
  invitingToChannels: string[];
  socialLinksResp: SocialLinksItem[];
  publicHivesResp: PublicHivesItem[];
  socialLinksRoutes: {
    [key: string]: string | undefined;
    linkedinLink: string;
    facebookLink: string;
    twitterLink: string;
    instagramLink: string;
    whatsappLink: string;
  };
  saveSocials: boolean;
  saveContact: boolean;
  hiveContactInfo: ContactInfoModel | undefined;
  emails: string[];
  phoneNos: string[];
  phoneUsers: {
    mobileNo: string;
    userName?: string;
    userOrigin?: string;
  }[];
  membersResp: MembersRespModel | undefined;
  chatbotMessages: ChatBotChatroomMessagesModel[];
  chatbotChatRooms: ChatBotChatroomsModel | undefined;
  chatRoomId: string;
  membersExportData: string[][];
} = {
  phoneUsers: [],
  emails: [],
  phoneNos: [],
  hiveContactInfo: undefined,
  socialLinksRoutes: {
    linkedinLink: "",
    facebookLink: "",
    twitterLink: "",
    instagramLink: "",
    whatsappLink: "",
  },
  saveSocials: false,
  saveContact: false,
  socialLinksResp: [],
  isFetching: false,
  hiveMembersList: undefined,
  hiveMembersPageNo: 0,
  hiveMembersTotalPages: 0,
  hiveSettingsTabs: ["About", "Media", "Members", "Channels"],
  hiveSettingsTabIndex: 0,
  invitingToChannels: [],
  invited: false,
  publicHivesResp: [],
  membersResp: undefined,
  chatbotMessages: [],
  chatbotChatRooms: undefined,
  chatRoomId: "",
  membersExportData: [],
};

const hiveSettingsSlice = createSlice({
  name: "hiveSettings",
  initialState,
  reducers: {
    setHiveTab: (state, action: PayloadAction<number>) => {
      state.hiveSettingsTabIndex = action.payload;
    },
    setEmails: (state, action: PayloadAction<string[]>) => {
      state.emails = action.payload;
    },
    setPhoneNos: (state, action: PayloadAction<string[]>) => {
      state.phoneNos = action.payload;
    },
    setPageNo: (state, action: PayloadAction<number>) => {
      state.hiveMembersPageNo = action.payload;
    },
    setInvitingToChannel: (state, action: PayloadAction<string>) => {
      state.invitingToChannels.push(action.payload);
    },
    setChatBotChatRoomId: (state, action: PayloadAction<string>) => {
      state.chatRoomId = action.payload;
    },
    removeInviteToChannel: (state, action: PayloadAction<string>) => {
      state.invitingToChannels = state.invitingToChannels.filter(
        (item) => item !== action.payload
      );
    },
    setInvitedMember: (state, action: PayloadAction<boolean>) => {
      state.invited = action.payload;
    },
    setSingleInviteToChannel: (state, action: PayloadAction<string>) => {
      state.invitingToChannels = [action.payload];
    },
    updatePhoneUsers: (
      state,
      action: PayloadAction<{
        mobileNo: string;
        userName?: string;
        userOrigin?: string;
      }>
    ) => {
      if (
        state.phoneUsers.findIndex((user) => user === action.payload) === -1
      ) {
        state.phoneUsers.push(action.payload);
        console.log(state.phoneUsers.length);
      }
    },
    clearPhoneUsers: (state) => {
      state.phoneUsers = [];
    },
  },
  extraReducers: {
    //GET HIVE MEMBERS
    [getHiveMembers.pending.type]: (state) => {
      state.isFetching = true;
    },
    [getHiveMembers.fulfilled.type]: (
      state,
      action: PayloadAction<MembersModel>
    ) => {
      state.hiveMembersList = action.payload.content;
      state.hiveMembersTotalPages = action.payload.totalPages;
      state.isFetching = false;
    },
    [getHiveMembers.rejected.type]: (state) => {
      state.isFetching = false;
    },
    //GET ALL HIVE MEMBERS
    [getAllHiveMembers.pending.type]: (state) => {
      state.isFetching = true;
    },
    [getAllHiveMembers.fulfilled.type]: (
      state,
      action: PayloadAction<MembersModel>
    ) => {
      state.membersExportData.push(["Username", "Contact"]);

      action.payload.content.map((member, idx) => {
        state.membersExportData.push([
          member.userName || "N/A",
          member.userContactDetails || "N/A",
        ]);
      });

      state.isFetching = false;
    },
    [getAllHiveMembers.rejected.type]: (state) => {
      state.isFetching = false;
    },

    //Email Member invite
    [emailMemberInvite.pending.type]: (state) => {
      state.isFetching = true;
      state.invited = false;
    },
    [emailMemberInvite.fulfilled.type]: (
      state,
      action: PayloadAction<MembersRespModel>
    ) => {
      state.invited = true;
      state.membersResp = action.payload;
      if (action.payload.exists > 0) {
        toastInfo(`User aldready exists`);
      }
      state.isFetching = false;
      state.invitingToChannels = [];
    },
    [emailMemberInvite.rejected.type]: (state) => {
      state.isFetching = false;
      state.invited = false;
      state.invitingToChannels = [];
    },

    //Email Admin INvite
    [emailAdminInvite.pending.type]: (state) => {
      state.isFetching = true;
    },
    [emailAdminInvite.fulfilled.type]: (
      state,
      action: PayloadAction<MembersRespModel>
    ) => {
      state.invited = true;
      state.membersResp = action.payload;
      if (action.payload.exists > 0) {
        toastInfo(`User aldready exists`);
      }
      state.invitingToChannels = [];
      state.isFetching = false;
    },
    [emailAdminInvite.rejected.type]: (state) => {
      state.isFetching = false;
      state.invited = false;
      state.invitingToChannels = [];
    },

    //Phone member invite
    [phoneMemberInvite.pending.type]: (state) => {
      state.isFetching = true;
    },
    [phoneMemberInvite.fulfilled.type]: (
      state,
      action: PayloadAction<MembersRespModel>
    ) => {
      state.invited = true;
      state.membersResp = action.payload;
      if (action.payload.exists > 0) {
        toastInfo(`User aldready exists`);
      }
      state.invitingToChannels = [];
      state.isFetching = false;
    },
    [phoneMemberInvite.rejected.type]: (state) => {
      state.isFetching = false;
      state.invited = false;
      state.invitingToChannels = [];
    },

    //Phone Admin Invite
    [phoneAdminInvite.pending.type]: (state) => {
      state.isFetching = true;
    },
    [phoneAdminInvite.fulfilled.type]: (
      state,
      action: PayloadAction<MembersRespModel>
    ) => {
      state.invited = true;
      state.membersResp = action.payload;
      if (action.payload.exists > 0) {
        toastInfo(`User aldready exists`);
      }
      state.isFetching = false;
      state.invitingToChannels = [];
    },
    [phoneAdminInvite.rejected.type]: (state) => {
      state.isFetching = false;
      state.invited = false;
      state.invitingToChannels = [];
    },

    //Social Links
    [getSocialLinks.pending.type]: (state) => {
      state.isFetching = true;
    },
    [getSocialLinks.fulfilled.type]: (
      state,
      action: PayloadAction<SocialLinksItem[]>
    ) => {
      state.socialLinksResp = action.payload;
      action.payload.map((data, idx) => {
        state.socialLinksRoutes[data.componentCode] = data.route;
      });
      state.isFetching = false;
    },
    [getSocialLinks.rejected.type]: (state) => {
      state.isFetching = false;
    },

    //Chatbot Chatrooms
    [getChatbotChatrooms.pending.type]: (state) => {
      state.isFetching = true;
    },
    [getChatbotChatrooms.fulfilled.type]: (
      state,
      action: PayloadAction<ChatBotChatroomsModel>
    ) => {
      state.chatbotChatRooms = action.payload;

      state.isFetching = false;
    },
    [getChatbotChatrooms.rejected.type]: (state) => {
      state.isFetching = false;
    },

    //Chatbot Chatroom Messages
    [getChatbotChatroomMessages.pending.type]: (state) => {
      state.isFetching = true;
    },
    [getChatbotChatroomMessages.fulfilled.type]: (
      state,
      action: PayloadAction<ChatBotChatroomMessagesModel[]>
    ) => {
      action.payload.map((chat, idx) => {
        state.chatbotMessages.push({
          request_message: chat.response_message,
          response_message: chat.response_message,
          good_response: chat.good_response === false ? false : true,
          raw_response: chat.raw_response,
          message_intent: chat.message_intent,
          api_type: chat.api_type,
          chat_room_id: chat.chat_room_id,
          date_created: chat.date_created,
          organization_uuid: chat.organization_uuid,
          is_active: chat.is_active,
        });
      });
      state.chatbotMessages = action.payload;

      state.isFetching = false;
    },
    [getChatbotChatroomMessages.rejected.type]: (state) => {
      state.isFetching = false;
    },

    // Get Public Hives
    [getPublicHives.pending.type]: (state) => {},
    [getPublicHives.fulfilled.type]: (
      state,
      action: PayloadAction<PublicHivesItem[]>
    ) => {
      state.publicHivesResp = action.payload;
    },
    [getPublicHives.rejected.type]: (state) => {},

    // Get Contact Info
    [getContactInfo.pending.type]: (state) => {
      state.isFetching = true;
    },
    [getContactInfo.fulfilled.type]: (
      state,
      action: PayloadAction<ContactInfoModel>
    ) => {
      state.hiveContactInfo = action.payload;
      state.isFetching = false;
    },
    [getContactInfo.rejected.type]: (state) => {
      state.isFetching = false;
    },

    // Save Social Links
    [saveSocialLinks.pending.type]: (state) => {
      state.isFetching = true;
    },
    [saveSocialLinks.fulfilled.type]: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.saveSocials = action.payload;
      state.isFetching = false;
    },
    [saveSocialLinks.rejected.type]: (state) => {
      state.isFetching = false;
    },

    // Save Contact Info
    [saveContactInfo.pending.type]: (state) => {
      state.isFetching = true;
    },
    [saveContactInfo.fulfilled.type]: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.saveContact = action.payload;
      state.isFetching = false;
    },
    [saveContactInfo.rejected.type]: (state) => {
      state.isFetching = false;
    },
  },
});

export const inviteSentSelector = createTypedSelector(
  (state) => state.hiveSettings.invited
);

export const {
  setHiveTab,
  setPageNo,
  setInvitingToChannel,
  removeInviteToChannel,
  setInvitedMember,
  setSingleInviteToChannel,
  setEmails,
  setPhoneNos,
  updatePhoneUsers,
  setChatBotChatRoomId,
} = hiveSettingsSlice.actions;

export const getHiveContactSelector = createTypedSelector(
  (state) => state.hiveSettings.hiveContactInfo
);

export const getChatBotChatroomIdSelector = createTypedSelector(
  (state) => state.hiveSettings.chatRoomId
);

export const getPhoneUsersSelector = createTypedSelector(
  (state) => state.hiveSettings.phoneUsers
);

export const getEmailsSelector = createTypedSelector(
  (state) => state.hiveSettings.emails
);

export const getPhoneNosSelector = createTypedSelector(
  (state) => state.hiveSettings.phoneNos
);

export const hiveTabsListSelector = createTypedSelector(
  (state) => state.hiveSettings.hiveSettingsTabs
);

export const getSocialRoutesSelector = createTypedSelector(
  (state) => state.hiveSettings.socialLinksRoutes
);

export const getSocialLinksSelector = createTypedSelector(
  (state) => state.hiveSettings.socialLinksResp
);

export const hiveTabIndexSelector = createTypedSelector(
  (state) => state.hiveSettings.hiveSettingsTabIndex
);

export const inviteToChannelsList = createTypedSelector(
  (state) => state.hiveSettings.invitingToChannels
);

export const getHiveMembersPageNo = createTypedSelector(
  (state) => state.hiveSettings.hiveMembersPageNo
);

export const hiveMembersSelector = createTypedSelector(
  (state) => state.hiveSettings.hiveMembersList
);

export const getExportMembersSelector = createTypedSelector(
  (state) => state.hiveSettings.membersExportData
);

export const totalMembersPagesSelector = createTypedSelector(
  (state) => state.hiveSettings.hiveMembersTotalPages
);

export const hiveMembersLoadingSelector = createTypedSelector(
  (state) => state.hiveSettings.isFetching
);

export const publicHivesSelector = createTypedSelector(
  (state) => state.hiveSettings.publicHivesResp
);

export const chatbotChatroomsSelector = createTypedSelector(
  (state) => state.hiveSettings.chatbotChatRooms
);

export const chatbotChatroomMessagesSelector = createTypedSelector(
  (state) => state.hiveSettings.chatbotMessages
);

export default hiveSettingsSlice.reducer;
