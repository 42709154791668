import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SpotlightItem } from "api/models/Videos/uploadContent";
import { VideoListItem, VideoListModel } from "api/models/Videos/videoList";
import {
  getPendingVideoList,
  getSpotlight,
  getSpotlightVids,
  getVideoDetails,
  getVideoList,
} from "store/async-actions/Videos/videosActions";
import { createTypedSelector } from "store/utils";

interface VideoListState {
  videoListResp: VideoListModel | undefined;
  videoList: VideoListItem[];
  isFetching: boolean;
  isFetchingSpotlight: boolean;
  currentVideo: VideoListItem | undefined;
  currentVideoIndex: number;
  spotlightResp: SpotlightItem[];
  spotlightVidsResp: VideoListModel | undefined;
  totalVideos: number;
  pendingVideos: VideoListModel | null;
}

const initialState: VideoListState = {
  currentVideoIndex: 0,
  videoList: [],
  videoListResp: undefined,
  isFetching: false,
  isFetchingSpotlight: false,
  currentVideo: undefined,
  spotlightResp: [],
  spotlightVidsResp: undefined,
  totalVideos: 0,
  pendingVideos: null,
};

export const videoListSlice = createSlice({
  name: "videoList",
  initialState,
  reducers: {
    setCurrentVideo: (state, action: PayloadAction<VideoListItem>) => {
      state.currentVideo = action.payload;
      !!state.videoList &&
        state.videoList.map((video, idx) => {
          if (video.videoUuid === state.currentVideo?.videoUuid) {
            state.currentVideoIndex = idx;
          }
        });
    },
    setNextVideo: (state) => {
      // for (var i = 0; i < state.videoList.length; i++) {
      //   if (state.videoList[i].videoUuid === state.currentVideo?.videoUuid) {
      //     state.currentVideo = state.videoList[i + 1];
      //     break;
      //   }
      // }
      state.currentVideo = state.videoList[state.currentVideoIndex + 1];
      state.currentVideoIndex += 1;
    },
    setPrevVideo: (state) => {
      state.currentVideo = state.videoList[state.currentVideoIndex - 1];
      state.currentVideoIndex -= 1;
    },
  },
  extraReducers: {
    [getVideoList.pending.type]: (state) => {
      state.isFetching = true;
    },
    [getVideoList.fulfilled.type]: (
      state,
      action: PayloadAction<VideoListModel>
    ) => {
      state.videoList = [];
      if (action.payload) {
        state.videoListResp = action.payload;
        state.videoList = action.payload.videoList;
        state.totalVideos = action.payload.noOfElements;
      }
      state.isFetching = false;
    },
    [getVideoList.rejected.type]: (state) => {
      state.isFetching = false;
    },
    [getVideoList.pending.type]: (state) => {
      //todo
    },
    [getVideoList.rejected.type]: (state) => {
      //todo
    },

    //Get pending videos
    [getPendingVideoList.pending.type]: (state) => {},
    [getPendingVideoList.fulfilled.type]: (
      state,
      action: PayloadAction<VideoListModel>
    ) => {
      state.pendingVideos = action.payload;
    },
    [getPendingVideoList.rejected.type]: (state) => {},

    // Get Spotlight

    [getSpotlight.pending.type]: (state) => {
      state.isFetchingSpotlight = true;
    },
    [getSpotlight.fulfilled.type]: (
      state,
      action: PayloadAction<SpotlightItem[]>
    ) => {
      state.spotlightResp = action.payload;
      state.isFetchingSpotlight = false;
    },
    [getSpotlight.rejected.type]: (state) => {
      state.isFetchingSpotlight = false;
    },

    // Get Spotlight Videos

    [getSpotlightVids.pending.type]: (state) => {
      state.isFetchingSpotlight = true;
    },
    [getSpotlightVids.fulfilled.type]: (
      state,
      action: PayloadAction<VideoListModel>
    ) => {
      state.spotlightVidsResp = action.payload;
      state.isFetchingSpotlight = false;
    },
    [getSpotlightVids.rejected.type]: (state) => {
      state.isFetchingSpotlight = false;
    },

    //GET VIDEO DETAILS
    [getVideoDetails.pending.type]: (state) => {
      state.isFetching = false;
    },
    [getVideoDetails.fulfilled.type]: (
      state,
      action: PayloadAction<VideoListItem>
    ) => {
      state.currentVideo = action.payload;
      state.isFetching = false;
    },
    [getVideoDetails.rejected.type]: (state) => {
      state.isFetching = false;
    },
  },
});

export const { setCurrentVideo, setNextVideo, setPrevVideo } =
  videoListSlice.actions;

export const videoListSelector = createTypedSelector(
  (state) => state.videoList.videoListResp
);

export const getSpotlightVidsSelector = createTypedSelector(
  (state) => state.videoList.spotlightVidsResp
);

export const getTotalVideosSelector = createTypedSelector(
  (state) => state.videoList.totalVideos
);

export const getTotalPendingVideosSelector = createTypedSelector(
  (state) => state.videoList.pendingVideos
);

export const getSpotlightSelector = createTypedSelector(
  (state) => state.videoList.spotlightResp
);

export const getIsSpotlightIsFetchingSelector = createTypedSelector(
  (state) => state.videoList.isFetchingSpotlight
);

export const reducedVideoListSelector = createTypedSelector(
  (state) => state.videoList.videoList
);

export const videoListLoadingSelector = createTypedSelector(
  (state) => state.videoList.isFetching
);

export const currentVideoSelector = createTypedSelector(
  (state) => state.videoList.currentVideo
);

export const getCurrentVideoIndex = createTypedSelector(
  (state) => state.videoList.currentVideoIndex
);

export default videoListSlice.reducer;
