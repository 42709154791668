import LineBreak from "components/LineBreak/LineBreak";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  changeStripePlan,
  getStripeCard,
} from "store/async-actions/Subscriptions/subscriptions";
import { getHiveUuid } from "store/reducers/HiveDetails/hiveDetails";
import {
  getPlanDetailsSelector,
  getStripeCardSelector,
} from "store/reducers/Subscriptions/subscriptions";

const StripeChangePlan = () => {
  const dispatch = useAppDispatch();
  const currentPlan = useAppSelector(getPlanDetailsSelector);
  const hiveUuid = useAppSelector(getHiveUuid);

  const navigate = useNavigate();

  const handleChange = () => {
    dispatch(
      changeStripePlan({
        organizationUuid: hiveUuid,
        planUuid: currentPlan?.planUuid || "",
      })
    );
    navigate("/plans");
  };

  useEffect(() => {
    dispatch(
      getStripeCard({
        organizationUuid: hiveUuid,
      })
    );
  }, [dispatch, hiveUuid]);

  const cardDetails = useAppSelector(getStripeCardSelector);

  return (
    <div className="stripe_change_plan_wrapper">
      <div className="card_details_item_item">
        <div className="p_pay_card_text_wrapper">
          <img
            src={
              cardDetails?.brand === "Visa"
                ? "https://images.veehive.ai/webApp/Visa+Card.png"
                : "https://images.veehive.ai/webApp/Mastercard+New.png"
            }
            alt="payment-card"
            className="p_pay_card"
          />
          <p>
            {cardDetails?.brand.toUpperCase()} ending in {cardDetails?.last4}
          </p>
        </div>
        <div className="text-sm">
          {cardDetails?.expMonth}/{cardDetails?.expYear}
        </div>
      </div>
      <div>
        <div onClick={handleChange} className="primaryBtn plan_buyBtn">
          Switch to {currentPlan?.planName}
        </div>
        {/* <p className="text-xs text-center">
          *Saved card will be used for any additional charges
        </p> */}
      </div>
    </div>
  );
};

export default StripeChangePlan;
