import { CircularProgress } from "@mui/material";
import FileDrop from "components/FileDrop";
import ImageDropzone from "components/ImageDropzone";
import IslandLayout from "components/IslandLayout/IslandLayout";
import LineBreak from "components/LineBreak";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import React, { useEffect, useState } from "react";
import ReactPlayer from "react-player";
import { useNavigate } from "react-router-dom";
import {
  setBannerUrlChanged,
  setUrlChanged,
  setBannerUploaded,
  getBannerFileUrlSelector,
  getLogoFileUrlSelector,
  setLogoFile,
} from "store/reducers/CreateChannel/createChannel";
import {
  getCurrentEventSelector,
  getEventIsLoadingSelector,
  setCurrentEvent,
} from "store/reducers/HiveDetails/events";
import { getHiveUuid } from "store/reducers/HiveDetails/hiveDetails";
import {
  isUploadingSelector,
  setVideoFile,
  uploadFileSelector,
} from "store/reducers/Videos/videos";
import moment from "moment";
import {
  editEvent,
  uploadEventVideo,
} from "store/async-actions/Hive/eventsActions";

const EventInfo = () => {
  const dispatch = useAppDispatch();

  const currentEvent = useAppSelector(getCurrentEventSelector);

  const hiveUuid = useAppSelector(getHiveUuid);

  const uploadedFile = useAppSelector(uploadFileSelector);

  const isLoading = useAppSelector(getEventIsLoadingSelector);

  const isUploading = useAppSelector(isUploadingSelector);

  const [changeIntro, setChangeIntro] = useState(false);

  const handleChangeIntro = () => {
    setChangeIntro((prevState) => !prevState);
    dispatch(setVideoFile(null));
  };

  const navigate = useNavigate();

  useEffect(() => {
    if (!!currentEvent) {
      dispatch(setBannerUrlChanged(false));
      dispatch(setUrlChanged(false));
      dispatch(setBannerUploaded(false));
    } else {
      navigate("/events");
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const bannerUrl = useAppSelector(getBannerFileUrlSelector);
  const fileUrl = useAppSelector(getLogoFileUrlSelector);

  const handleFinish = () => {
    dispatch(
      editEvent({
        eventUuid: currentEvent?.eventUuid || "",
        organizationUuid: hiveUuid,
        eventBanner: bannerUrl,
        eventThumbnail: fileUrl,
      })
    );
    navigate("/events");
  };

  const handleSkip = () => {
    navigate("/events");
  };

  return (
    <IslandLayout>
      <div className="ce_about_wrapper">
        <h2>{currentEvent?.eventName}</h2>
        <p className="event_location_view_wrapper">
          {currentEvent?.eventMedium === "online" ? "Online" : "In-Person"}{" "}
          {"("}
          {moment.utc(currentEvent?.eventStartDate).local().format("LLL")}
          {" - "}
          {moment.utc(currentEvent?.eventEndDate).local().format("LLL")}
          {")"}
        </p>

        <LineBreak />
        <div className="title_and_limit">
          <h3>Event Banner</h3>
        </div>
        <ImageDropzone file="banner" />
        <LineBreak />
        <div className="title_and_limit">
          <h3>Event Thumbnail</h3>
        </div>
        <ImageDropzone file="channel" />
        <LineBreak />
        <>
          <div className="intro_video_edit_header">
            <h3>Event Video</h3>

            {uploadedFile && (
              <p onClick={handleChangeIntro} className="link text-sm">
                {`${changeIntro ? "Cancel" : "Change Video"}`}
              </p>
            )}
          </div>

          {currentEvent?.eventVideo ? (
            <div className="intro_video_edit_wrapper">
              <ReactPlayer
                width="270px"
                height="480px"
                url={currentEvent.eventVideo}
                controls
                style={{
                  overflow: "hidden",
                  background: "black",
                }}
              />
            </div>
          ) : uploadedFile ? (
            <div className="intro_video_edit_wrapper">
              <ReactPlayer
                width="270px"
                height="480px"
                url={URL.createObjectURL(uploadedFile)}
                controls
                style={{
                  overflow: "hidden",
                  background: "black",
                }}
              />
            </div>
          ) : (
            <FileDrop uploadWhenFinished={true} />
          )}
        </>
        <LineBreak />
        <>
          {isLoading || isUploading ? (
            <div className="nextBtn primaryBtn">
              <CircularProgress size={20} color="inherit" />
            </div>
          ) : (
            <>
              {!!bannerUrl || !!fileUrl || !!uploadedFile ? (
                <div onClick={handleFinish} className="nextBtn primaryBtn">
                  Finish
                </div>
              ) : (
                <div onClick={handleSkip} className="nextBtn primaryBtn">
                  {" "}
                  Skip
                </div>
              )}
            </>
          )}
        </>
      </div>
    </IslandLayout>
  );
};

export default EventInfo;
