import { TextField } from "@mui/material";
import AiFileDrop from "components/AiFileDrop/AiFileDrop";
import BackButton from "components/BackButton/BackButton";
import LineBreak from "components/LineBreak/LineBreak";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { createTeaser, pdfToVideo } from "store/async-actions/Ai/ai";
import {
  getUploadedAiFileSelector,
  setOpenDownloads,
  uploadAiFile,
} from "store/reducers/Ai/ai";
import { getHiveUuid } from "store/reducers/HiveDetails/hiveDetails";
import { profileUserIdSelector } from "store/reducers/Profile/profile";

const PdfToVideo = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(uploadAiFile(null));
  }, [dispatch]);

  const userId = useAppSelector(profileUserIdSelector);

  const uploadedFile = useAppSelector(getUploadedAiFileSelector);

  const hiveUuid = useAppSelector(getHiveUuid);

  const navigate = useNavigate();

  const handleCreate = () => {
    const formData = new FormData();
    const obj = {
      userid: userId,
      summarize: false,
      is_bionic: false,
      organizationUuid: hiveUuid,
    };
    formData.append("json", JSON.stringify(obj));

    formData.append("document", uploadedFile || "");
    formData.append("organizationUuid", hiveUuid);

    dispatch(pdfToVideo({ formData, userId }));
    navigate("..");
    dispatch(setOpenDownloads(true));
  };

  const handleRemove = () => {
    dispatch(uploadAiFile(null));
  };

  return (
    <div className="ttv_container">
      <BackButton />
      <LineBreak />
      <h4 className="tags">Upload PDF</h4>
      <LineBreak />
      {!!uploadedFile && (
        <div>
          <p onClick={handleRemove} className="link text_right">
            Remove
          </p>
          <LineBreak />
        </div>
      )}
      <AiFileDrop mandatory={true} fileType="pdf" size={10} />
      <LineBreak />

      {uploadedFile ? (
        <div onClick={handleCreate} className="nextBtn primaryBtn">
          Create
        </div>
      ) : (
        <div className="nextBtn disabledBtn">Create</div>
      )}
    </div>
  );
};

export default PdfToVideo;
