import { CircularProgress, FormControl, MenuItem, Select } from "@mui/material";
import BackButton from "components/BackButton";
import IslandLayout from "components/IslandLayout/IslandLayout";
import LineBreak from "components/LineBreak";
import PageLayout from "components/PageLayout/PageLayout";
import { BackBtnSVG, CSVDownloadSVG } from "components/SVG/SVG";
import TokenInput from "react-customize-token-input";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import React, { ChangeEvent, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  addCampaignEmails,
  addCampaignNumbers,
  createCampaign,
  editCampaign,
  editCampaignWithoutCurrent,
  getCampaignEmails,
  getCampaignNumbers,
  getCurrentCampaign,
  getEmailCampaignData,
  getLinkedCampaigns,
} from "store/async-actions/Campaign/campaign";
import {
  getCampaignEmailsSelector,
  getCampaignIsFetchingSelector,
  getCampaignNumbersSelector,
  getCampaignUuidSelector,
  getCurrentCampaignSelector,
  getEmailBannerS3UrlSelector,
  getEmailCampaignDataSelector,
  setCampaignUuid,
} from "store/reducers/Campaign/campaign";
import { getHiveUuid } from "store/reducers/HiveDetails/hiveDetails";
import { TextField } from "@mui/material";
import { toastSuccess } from "hooks/useToastify";
import CSVImport from "components/CSVImport/CSVImport";

import { CSVLink } from "react-csv";
import {
  getCSVErrorMessageSelector,
  getCSVSuccessMessageSelector,
  getImportCountSelector,
} from "store/reducers/HiveDetails/hiveActivities";
import {
  getEmailsSelector,
  getPhoneUsersSelector,
} from "store/reducers/HiveDetails/hiveSettings";
import EmailTemplate from "./components/EmailTemplate";
import { ReactMultiEmail } from "react-multi-email";
import { getEmailBannerFileUrlSelector } from "store/reducers/CreateChannel/createChannel";
import EmailTemplate2 from "./components/EmailTemplate2";

const csvPhoneData = [
  ["firstname", "lastname", "mobileNo", "userName", "userOrigin"],
  ["John", "Doe", "+971501234567", "itsjohn", "ACME Corp."],
  ["Jane", "Doe", "+918451942268", "itsjane", "ACME Dev."],
  ["Veehive", "", "+971558843692", "letsveehive", "Veehive FZ LLC."],
];

const csvEmailData = [
  ["firstname", "lastname", "email"],
  ["John", "Doe", "john@doe.com"],
  ["Jane", "Doe", "janedoe@smthing.co"],
  ["veehive", "", "contact@veehive.ai"],
];

const EditCampaign = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const currentCampaign = useAppSelector(getCurrentCampaignSelector);

  const [name, setName] = useState(currentCampaign?.campaignName || "");
  const [type, setType] = useState(currentCampaign?.campaignType || "sms");

  const isFetching = useAppSelector(getCampaignIsFetchingSelector);

  const hiveUuid = useAppSelector(getHiveUuid);
  const dispatch = useAppDispatch();
  const campaignUuid = useAppSelector(getCampaignUuidSelector);

  const handleTypeChange = (e: any) => {
    setType(e.target.value);
  };

  const banner = useAppSelector(getEmailBannerS3UrlSelector);

  const handleEditCampaign = () => {
    dispatch(
      editCampaignWithoutCurrent({
        campaignUuid: campaignUuid,
        campaignName: name,
        smsMessage: message,
        campaignType:
          currentCampaign?.campaignType === "email" ? "EMAIL" : "SMS",
        greetingMessage: emailDetails.greeting,
        heading: emailDetails.heading,
        para1: emailDetails.para1,
        para2: emailDetails.para2,
        para3: emailDetails.para3,
        signOff: emailDetails.signOff,
        signatureName: emailDetails.signOffName,
        signatureDesignation: emailDetails.signOffDesignation,
        subject: emailDetails.subject,
        ctaEnabled: addCta,
        ctaName: emailDetails?.ctaName,
        ctaLink: emailDetails?.ctaLink,
        template: template,
        headerImage: banner || "",
        copyrightName: emailDetails.copyrightName,
        storyCta: addStory ? emailDetails.storyCta : "",
        facebookLink: emailDetails.facebookLink,
        whatsappLink: emailDetails.whatsappLink,
        instagramLink: emailDetails.instagramLink,
      })
    );

    if (currentCampaign?.campaignType === "sms") {
      dispatch(
        addCampaignNumbers({
          smsNumbersList: phoneNos,
          campaignUuid: campaignUuid,
        })
      );
    } else {
      dispatch(
        addCampaignEmails({
          emailList: emails,
          campaignUuid: campaignUuid,
        })
      );
    }
    toastSuccess("Saved Campaign Details");
  };

  const [message, setMessage] = useState("");

  useEffect(() => {
    dispatch(
      getCurrentCampaign({
        campaignUuid: campaignUuid,
      })
    );
    dispatch(
      getCampaignNumbers({
        campaignUuid: campaignUuid,
      })
    );
    dispatch(
      getCampaignEmails({
        campaignUuid: campaignUuid,
      })
    );
    dispatch(
      getEmailCampaignData({
        campaignUuid: campaignUuid,
        organizationUuid: hiveUuid,
      })
    );
  }, [campaignUuid]);

  const emailsList = useAppSelector(getCampaignEmailsSelector);

  const [emails, setEmails] = useState<string[]>(emailsList);

  const campaignNumbers = useAppSelector(getCampaignNumbersSelector);
  const [phoneNos, setPhoneNos] = useState<string[]>(campaignNumbers);

  const handlePhoneNumbersChange = (nos: string[]) => {
    setPhoneNos(nos);
  };

  const handleGetTokenDisplayLabel = (tokenValue: any, tokenMeta: any) => {
    // Could return any react node
    return <div className="rounded-full">{`${tokenValue}`}</div>;
  };

  const csvErrorMessage = useAppSelector(getCSVErrorMessageSelector);
  const setCSVSuccessMessage = useAppSelector(getCSVSuccessMessageSelector);
  const importCount = useAppSelector(getImportCountSelector);

  const handleTokenValueValidate = (e: string) => {
    const number = /^-?\d+$/;
    if (e.startsWith("+")) {
      if (number.test(e.slice(1))) {
        if (e.length > 9 && e.length < 15) {
          return null;
        } else {
          return true;
        }
      } else {
        return true;
      }
    } else {
      return true;
    }
  };

  useEffect(() => {
    if (!!currentCampaign) {
      setName(currentCampaign?.campaignName);
      setType(currentCampaign?.campaignType);
      setMessage(currentCampaign.smsMessage || "");
    }
    setPhoneNos(campaignNumbers);
    setEmails(emailsList);
  }, [currentCampaign, campaignNumbers, emailsList]);

  const emailData = useAppSelector(getEmailCampaignDataSelector);

  const [template, setTemplate] = useState(emailData?.template || 1);

  const [emailDetails, setEmailDetails] = useState({
    greeting: emailData?.greetingMessage || "",
    heading: emailData?.heading || "",
    para1: emailData?.para1 || "",
    para2: emailData?.para2 || "",
    para3: emailData?.para3 || "",
    signOff: emailData?.signOff || "",
    signOffName: emailData?.signatureName || "",
    signOffDesignation: emailData?.signatureDesignation || "",
    subject: emailData?.subject || "",
    ctaEnabled: emailData?.ctaEnabled || false,
    ctaName: emailData?.ctaName || "",
    ctaLink: emailData?.ctaLink || "",
    storyCta: emailData?.storyCta || "",
    template: emailData?.template || 1,
    copyrightName: emailData?.copyrightName || "YourCompanyName 2023",
    facebookLink: emailData?.facebookLink || "",
    whatsappLink: emailData?.whatsappLink || "",
    instagramLink: emailData?.instagramLink || "",
    headerImage:
      emailData?.headerImage ||
      "https://images.veehive.ai/email/EMAIL+CAMPAIGN.png",
  });

  const [addCta, setAddCta] = useState(emailData?.ctaEnabled || false);
  const [addStory, setAddStory] = useState(false);

  useEffect(() => {
    setEmailDetails({
      heading: emailData?.heading || "",
      greeting: emailData?.greetingMessage || "",
      para1: emailData?.para1 || "",
      para2: emailData?.para2 || "",
      para3: emailData?.para3 || "",
      signOff: emailData?.signOff || "",
      signOffName: emailData?.signatureName || "",
      signOffDesignation: emailData?.signatureDesignation || "",
      subject: emailData?.subject || "",
      ctaEnabled: emailData?.ctaEnabled || false,
      ctaName: emailData?.ctaName || "",
      ctaLink: emailData?.ctaLink || "",
      storyCta: emailData?.storyCta || "",
      template: emailData?.template || 1,
      copyrightName: emailData?.copyrightName || "YourCompanyName 2023",
      facebookLink: emailData?.facebookLink || "",
      whatsappLink: emailData?.whatsappLink || "",
      instagramLink: emailData?.instagramLink || "",
      headerImage:
        emailData?.headerImage ||
        "https://images.veehive.ai/email/EMAIL+CAMPAIGN.png",
    });
    if (!!emailData?.storyCta) {
      setAddStory(true);
    }
    setTemplate(emailData?.template || 1);
    setAddCta(emailData?.ctaEnabled || false);
  }, [dispatch, emailData]);

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setEmailDetails((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleTemplateChange = (e: any) => {
    setTemplate(e.target.value);
  };

  return (
    <PageLayout sideMenu={true}>
      <IslandLayout>
        <div className="page_padding">
          <h2>
            Edit your{" "}
            {currentCampaign?.campaignType === "sms" ? "SMS " : "Email "}
            Campaign
          </h2>
          <LineBreak />
          <div className="backBtn_spacing">
            <BackButton to="/campaigns" />
          </div>
          <LineBreak />
          <div className="title_and_limit">
            <h4>Campaign Name</h4>
            <div className="character_limit text-sm">{name.length}/27</div>
          </div>
          <LineBreak />
          <input
            value={name}
            onChange={(e) => {
              setName(e.target.value.slice(0, 27));
            }}
            placeholder="Give a name for your campaign"
            className="input_border text_padding input_width_full"
          />

          <LineBreak />
          {currentCampaign?.campaignType === "email" && (
            <div className="flex items-end gap-x-2">
              <TextField
                className="half_width"
                variant="standard"
                onChange={handleChange}
                value={emailDetails.subject}
                name="subject"
                label="Subject"
                placeholder="Subject"
              />
              <FormControl className="half_width">
                <Select
                  className=""
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Template"
                  name="template"
                  variant="standard"
                  onChange={handleTemplateChange}
                  value={template}
                >
                  <MenuItem value={1}>Template 1</MenuItem>
                  <MenuItem value={2}>Template 2</MenuItem>
                </Select>
              </FormControl>
            </div>
          )}
          <LineBreak />
          <h4>
            Edit your{" "}
            {currentCampaign?.campaignType === "sms" ? "message" : "email"}
          </h4>
          <LineBreak />
          <div>
            {currentCampaign?.campaignType === "sms" ? (
              <TextField
                multiline={true}
                minRows={4}
                placeholder="Enter your message Here"
                fullWidth
                value={message}
                variant="outlined"
                label="Message"
                onChange={(e) => setMessage(e.target.value.slice(0, 300))}
              />
            ) : (
              <>
                {template === 1 ? (
                  <EmailTemplate
                    handleChange={handleChange}
                    emailDetails={emailDetails}
                    addCta={addCta}
                    setAddCta={setAddCta}
                  />
                ) : (
                  <EmailTemplate2
                    handleChange={handleChange}
                    emailDetails={emailDetails}
                    addCta={addCta}
                    setAddCta={setAddCta}
                    addStory={addStory}
                    setAddStory={setAddStory}
                  />
                )}
              </>
            )}
          </div>
          <LineBreak />
          <h4>
            Edit{" "}
            {currentCampaign?.campaignType === "sms"
              ? "Phone Numbers"
              : "Emails"}
          </h4>
          <div className="csv_import_container_wrapper">
            <CSVImport
              setPhoneNos={setPhoneNos}
              selectedTab={currentCampaign?.campaignType === "sms" ? 1 : 0}
              phoneNos={phoneNos}
              emails={emails}
              setEmails={setEmails}
            />

            <CSVLink
              filename={
                currentCampaign?.campaignType === "sms"
                  ? "phone_format"
                  : "email_format"
              }
              data={
                currentCampaign?.campaignType === "sms"
                  ? csvPhoneData
                  : csvEmailData
              }
            >
              <CSVDownloadSVG />
            </CSVLink>
          </div>
          <LineBreak />
          <span className="error_message">{csvErrorMessage}</span>
          <span className="success_message">{setCSVSuccessMessage}</span>
          <div className="">
            {currentCampaign?.campaignType === "sms" ? (
              <TokenInput
                separators={[",", " "]}
                tokenValues={phoneNos}
                onTokenValuesChange={setPhoneNos}
                className=""
                onTokenValueValidate={handleTokenValueValidate}
                onGetTokenDisplayLabel={handleGetTokenDisplayLabel}
                placeholder="ex: +91234567891"
              />
            ) : (
              <div className="react_multi_email_container">
                <ReactMultiEmail
                  placeholder="ex: john@example.com; jane@example.com;"
                  className="text-sm"
                  emails={emails}
                  onChange={setEmails}
                  getLabel={(
                    email: string,
                    index: number,
                    removeEmail: (index: number) => void
                  ) => {
                    return (
                      <div
                        // data-tag
                        key={index}
                        className="multi_email_tag"
                      >
                        <p>{email}</p>
                        <span
                          data-tag-handle
                          onClick={() => {
                            removeEmail(index);
                          }}
                          className="cursor-pointer"
                        >
                          ×
                        </span>
                      </div>
                    );
                  }}
                />
              </div>
            )}
          </div>
          <>
            <div className="campaign_nextBtn_container">
              {name.length > 0 ? (
                <div
                  onClick={handleEditCampaign}
                  className="primaryBtn campaign_nextBtn"
                >
                  {isFetching ? (
                    <CircularProgress size={20} color="inherit" />
                  ) : (
                    "Save"
                  )}
                </div>
              ) : (
                <div className="campaign_nextBtn disabledBtn">Save</div>
              )}
            </div>
          </>
        </div>
      </IslandLayout>
    </PageLayout>
  );
};

export default EditCampaign;
