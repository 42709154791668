import { Divider } from "@mui/material";
import {
  GoogleDriveSVG,
  GooglePhotosSVG,
  OneDriveSVG,
} from "components/SVG/SVG";
import React from "react";
import { useGoogleLogin } from "@react-oauth/google";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import {
  getGoogleDriveConnectedSelector,
  setContentCurrentStep,
  setGoogleConnected,
  setGoogleResp,
} from "store/reducers/ContentMgr/contentMgr";

const ContentIntegrate = () => {
  const dispatch = useAppDispatch();

  const googleLogin = useGoogleLogin({
    onSuccess: (tokenResponse) => {
      dispatch(setGoogleConnected(true));
      dispatch(setGoogleResp(tokenResponse));
    },
  });

  const driveConnected = useAppSelector(getGoogleDriveConnectedSelector);

  const handleNext = () => {
    dispatch(setContentCurrentStep(2));
  };

  return (
    <div className="content_int_container">
      <div className="integration_item_wrapper">
        <div className="integration_content_display">
          <GoogleDriveSVG />
          <div>
            <h3>Google Drive</h3>
            <p className="text-sm tags">Login with google</p>
          </div>
        </div>
        {driveConnected ? (
          <div onClick={() => googleLogin()} className="tertiaryBtn">
            Connected
          </div>
        ) : (
          <div onClick={() => googleLogin()} className="primaryBtn">
            Connect
          </div>
        )}
      </div>
      <Divider />
      <div className="integration_item_wrapper">
        <div className="integration_content_display">
          <OneDriveSVG />
          <div>
            <h3>One Drive</h3>
            <p className="text-sm tags">Login to onedrive</p>
          </div>
        </div>
        <div className="primaryBtn">Connect</div>
      </div>
      <Divider />
      <div className="integration_item_wrapper">
        <div className="integration_content_display">
          <GooglePhotosSVG />
          <div>
            <h3>Google Photos</h3>
            <p className="text-sm tags">Login with google</p>
          </div>
        </div>
        <div className="primaryBtn">Connect</div>
      </div>
      {driveConnected && (
        <div onClick={handleNext} className="full_zz nextMgrBtn secondaryBtn">
          Next
        </div>
      )}
    </div>
  );
};

export default ContentIntegrate;
