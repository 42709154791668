import React, { useEffect, useState } from "react";
import {
  getFromDateSelector,
  getToDateSelector,
  setAnalyticsTab,
} from "store/reducers/Analytics/analytics";
import MetricsContainer from "./MetricsContainer";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import {
  getHiveRevenue,
  getHiveRevenueGraph,
  getPurchaseChannelList,
  getRedirectLinkList,
  getRedirectLocationList,
  getRedirectOriginList,
} from "store/async-actions/Analytics/analyticsActions";
import { getHiveSelector } from "store/reducers/HiveDetails/hiveDetails";
import ChannelMetricsContainer from "./ChannelMetricsContainer";
import LineBreak from "components/LineBreak/LineBreak";
import RevenueGraph from "./RevenueGraph";
import DateSelector from "components/DateSelector/DateSelector";
import ChannelListTable from "./ChannelListTable";
import { redirect } from "react-router-dom";
import RedirectLinksTable from "./RedirectLinksTable";
import RedirectOriginsTable from "./RedirectOriginsTable";
import RedirectLocationsTable from "./RedirectLocationsTable";

const AnalyticsLinks = () => {
  const dispatch = useAppDispatch();

  const hiveDetails = useAppSelector(getHiveSelector);
  const fromDate = useAppSelector(getFromDateSelector);
  const toDate = useAppSelector(getToDateSelector);

  const [step, setStep] = useState(0);

  useEffect(() => {
    dispatch(setAnalyticsTab(3));

    if (hiveDetails) {
      dispatch(
        getRedirectLinkList({
          organizationUuid: hiveDetails.communityUuid,
        })
      );
      dispatch(
        getRedirectOriginList({
          organizationUuid: hiveDetails.communityUuid,
          redirect: "https://hello.com",
        })
      );
      dispatch(
        getRedirectLocationList({
          organizationUuid: hiveDetails.communityUuid,
          redirect: "https://hello.com",
          origin: "newRx",
        })
      );
    }

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, hiveDetails, fromDate, toDate]);

  return (
    <>
      <LineBreak />
      {step === 0 ? (
        <RedirectLinksTable setStep={setStep} />
      ) : step === 1 ? (
        <RedirectOriginsTable setStep={setStep} />
      ) : (
        <RedirectLocationsTable setStep={setStep} />
      )}
      <LineBreak />
    </>
  );
};

export default AnalyticsLinks;
