import React, { useEffect } from "react";

const Mobile = () => {
  useEffect(() => {
    window.location.reload();
  }, []);

  return <></>;
};

export default Mobile;
