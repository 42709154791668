import { ChevronRightSVG } from "components/SVG/SVG";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import React, { useEffect } from "react";
import { getPublicHives } from "store/async-actions/Hive/hivePage";
import { getHiveUuid } from "store/reducers/HiveDetails/hiveDetails";
import { publicHivesSelector } from "store/reducers/HiveDetails/hiveSettings";

const PublicHives = () => {
  const publicHives = useAppSelector(publicHivesSelector);
  const hiveUuid = useAppSelector(getHiveUuid);

  const dispatch = useAppDispatch();

  useEffect(() => {
    hiveUuid && dispatch(getPublicHives({ organizationUuid: hiveUuid }));
  }, [hiveUuid, dispatch]);

  const launchUserHive = (domain: string) => {
    window.open(
      `https://${domain}.vhive.org/${
        localStorage.getItem("isLoggedIn") === "yes"
          ? `?login=${localStorage.getItem("@jwtToken")}`
          : "home"
      }`,
      "_blank"
    );
  };

  return (
    <div className="public_hive_container scrollable-y">
      <h3 className="text-sm public_hive_heading">Suggested Hives</h3>
      {publicHives.map((data, idx) => {
        return (
          <div
            key={idx}
            onClick={() => {
              launchUserHive(data.communitySubDomain);
            }}
            className="public_hive_wrapper pointer"
          >
            <div className="public_hive_spacing_content">
              <div className="public_hive_details">
                <img src={data.organizationLogo} className="hive_logo" alt="" />
                <h3 className="text-sm">{data.organizationName}</h3>
              </div>
              <ChevronRightSVG />
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default PublicHives;
