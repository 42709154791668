import BaseApi from "api/base";
import { FeedModel, FeedRequestModel } from "api/models/Feed/Feed";
import { AxiosInstance, AxiosPromise } from "axios";

import { BaseResponse } from "../../models/base";
import {
  BasicFormReq,
  CreateFormReq,
  EditFormReq,
  FormModel,
  FormResponseModel,
} from "api/models/Forms/forms";
import { AttendeeFormDetailsReq } from "api/models/Hive/events";

export class FormsApi extends BaseApi {
  createForm(data: CreateFormReq): AxiosPromise<BaseResponse<FormModel>> {
    return this.request({
      url: `${this.url}/forms/create`,
      method: "POST",
      data,
    });
  }

  editForm(data: EditFormReq): AxiosPromise<BaseResponse<boolean>> {
    return this.request({
      url: `${this.url}/forms/edit`,
      method: "POST",
      data,
    });
  }

  getForms(data: {
    organizationUuid: string;
  }): AxiosPromise<BaseResponse<FormModel[]>> {
    return this.request({
      url: `${this.url}/forms/get/list`,
      method: "POST",
      data,
    });
  }

  getFormDetails(data: {
    formUuid: string;
  }): AxiosPromise<BaseResponse<FormModel>> {
    return this.request({
      url: `${this.url}/noAuth/forms/get`,
      method: "POST",
      data,
    });
  }

  submitForm(data: {
    formUuid: string;
    formList: AttendeeFormDetailsReq[];
  }): AxiosPromise<BaseResponse<boolean>> {
    return this.request({
      url: `${this.url}/noAuth/forms/create/fill`,
      method: "POST",
      data,
    });
  }

  deleteForm(data: { formUuid: string }): AxiosPromise<BaseResponse<boolean>> {
    return this.request({
      url: `${this.url}/forms/delete`,
      method: "POST",
      data,
    });
  }

  getFormResponses(
    data: BasicFormReq
  ): AxiosPromise<BaseResponse<FormResponseModel[]>> {
    return this.request({
      url: `${this.url}/forms/get/list/fill`,
      method: "POST",
      data,
    });
  }
}

export default function formsApi(request: AxiosInstance) {
  return new FormsApi({
    request,
    url: `/webApp`,
  });
}
