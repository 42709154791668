import Headers from "components/TextStyles/components/Headers";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import React, { useEffect } from "react";
import { getSocialLinks } from "store/async-actions/Hive/hivePage";
import { getHiveUuid } from "store/reducers/HiveDetails/hiveDetails";
import { getSocialLinksSelector } from "store/reducers/HiveDetails/hiveSettings";

const SocialLinks = () => {
  const handleSocialClick = (route: string, go: boolean) => {
    !!route && go && window.open(route, "_blank");
  };

  const dispatch = useAppDispatch();
  const socialLinks = useAppSelector(getSocialLinksSelector);
  const hiveUuid = useAppSelector(getHiveUuid);

  useEffect(() => {
    hiveUuid && dispatch(getSocialLinks({ organizationUuid: hiveUuid }));
  }, [hiveUuid, dispatch]);

  return (
    <div className="social_icons_container">
      <h4>On the web</h4>
      <div className="social_icons_wrapper">
        {socialLinks.map((data, idx) => {
          return (
            <img
              key={idx}
              src={data.icon}
              className={`social_icon ${
                data.isActive && data.route.length > 1
                  ? ""
                  : "inactive_social_icon"
              }`}
              alt="social"
              onClick={() => handleSocialClick(data.route, data.isActive)}
            />
          );
        })}
      </div>
    </div>
  );
};

export default SocialLinks;
