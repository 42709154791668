import React from 'react'
import IconButton, { IconButtonProps } from '@mui/material/IconButton'

import EditPenIcon from 'components/common/svg/EditPenIcon'

interface EditFieldButtonProps extends IconButtonProps {}

const EditFieldButton: React.FC<EditFieldButtonProps> = (props) => {
  return (
    <IconButton className="edit_button" {...props}>
      <EditPenIcon />
    </IconButton>
  )
}

export default EditFieldButton
